import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { MinusCircleIcon } from 'images'

import QualifierDropdown from './QualifierDropdown'
import { useRangeLocations, useRangeLocationsDispatch } from './RangeLocationsContextProvider'
import { actions, getPreferredRangeUnit } from './rangeLocationsReducer'
import RadiusInput from './RadiusInput'

function Location({ location }) {
  const {
    data: { key, name, region, type: locationType },
    qualifier,
    radius,
  } = location
  const dispatch = useRangeLocationsDispatch()

  const displayName = useMemo(() => region && region !== name ? `${name}, ${region}` : name, [name, region])

  const setQualifier = useCallback(
    (qualifier) => {
      dispatch({ type: actions.setQualifier, payload: { locationKey: key, qualifier } })
    },
    [key, dispatch],
  )

  const setRadius = useCallback(
    (radius) => {
      dispatch({
        type: actions.setLocationRadius,
        payload: { locationKey: key, radius: { value: radius, unit: getPreferredRangeUnit() } },
      })
    },
    [key, dispatch],
  )

  const removeLocation = useCallback(() => {
    dispatch({ type: actions.removeLocation, payload: { locationKey: key } })
  }, [key, dispatch])

  return (
    <div className="range-locations_summary__location">
      <div className="range-locations_summary__location__inner">
        <QualifierDropdown
          className="range-locations_summary__location__qualifier"
          value={qualifier}
          onChange={(value) => setQualifier(value)}
        />
        <span className="range-locations_summary__location-name">{displayName}</span>
        {locationType === 'city' &&
          <RadiusInput defaultValue={radius.value} unit={radius.unit} onChange={setRadius} />
        }
      </div>
      <i title="remove" className="range-locations_summary__location-remove" onClick={removeLocation}>
        <MinusCircleIcon />
      </i>
    </div>
  )
}

Location.propTypes = {
  location: PropTypes.shape({
    data: PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      region: PropTypes.string,
    }),
    qualifier: PropTypes.string.isRequired,
    radius: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.oneOf(['miles', 'kilometers']),
    }),
  }),
}

function Country({ countryName, locations }) {
  return (
    <div className="range-locations_summary__country">
      <h6 className="range-locations_summary__country__name">{countryName}</h6>
      {locations.map((location) => (
        <Location key={location.data.key} location={location} />
      ))}
    </div>
  )
}

Country.propTypes = {
  countryName: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
}

export default function LocationsSummary() {
  const { locations } = useRangeLocations()

  const countries = locations.reduce((acc, location) => {
    const countryName = location.data.country_name
    const country = acc.find((country) => country.countryName === countryName)

    if (country) {
      country.locations.push(location)
    } else {
      acc.push({ countryName, locations: [location] })
    }

    return acc
  }, [])

  return (
    <div className={cn('range-locations_summary', { 'range-locations_summary--empty': !locations.length })}>
      {countries.map((country) => (
        <Country key={country.countryName} {...country} />
      ))}
    </div>
  )
}

import React, { useCallback, useContext, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Row from '../Row'
import CustomerIds from './CustomerIds'
import { actions } from '../customerSegmentation'
import { CustomerSegmentationContext, CustomerSegmentationDispatchContext } from '../CustomerSegmentationContext'
import InfoBox from 'components/Shared/InfoBox'
import { SecondaryButton } from 'components/Shared/Button'

import { GreenTickIcon } from 'images'

export default function CustomerList() {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const { customerIds: { qualifier, value: customerIds } } = useContext(CustomerSegmentationContext)
  const dispatch = useContext(CustomerSegmentationDispatchContext)

  const onActionChange = useCallback(
    (newAction) => {
      dispatch({ type: actions.setCustomerIds, payload: { qualifier: newAction }})
    },
    [dispatch],
  )

  const reset = useCallback(() => {
    dispatch({ type: actions.resetCustomerIds, payload: { qualifier: null } })
  }, [dispatch])

  const onCustomerIdsChange = useCallback(
    (customerIDs) => {
      if (!customerIDs) {
        dispatch({ type: actions.resetCustomerIds })
        return
      }

      dispatch({ type: actions.setCustomerIds, payload: { value: customerIDs } })
    },
    [dispatch],
  )

  return (
    <div className="customer-list">
      <InfoBox content="Tips.PackageBuilder.CustomerList">
        <h6 className="d-inline-block">{t('PackageBuilder.customerSegmentation.customerList')}</h6>
      </InfoBox>
      <Row>
        <Form.Check
          custom
          type="radio"
          label={t('PackageBuilder.customerSegmentation.exclude')}
          inline
          name="customer-list"
          id="exclude"
          checked={qualifier === 'exclude'}
          onClick={() => qualifier === 'exclude' ? reset() : onActionChange('exclude')}
        />
        <Form.Check
          custom
          type="radio"
          label={t('PackageBuilder.customerSegmentation.include')}
          inline
          name="customer-list"
          id="include"
          checked={qualifier === 'include'}
          onClick={() => qualifier === 'include' ? reset() : onActionChange('include')}
        />
        <SecondaryButton
          text={t('PackageBuilder.customerSegmentation.customerIds')}
          color="navy"
          size="small"
          fullWidth={false}
          type="button"
          disabled={!qualifier}
          onClick={(e) => {
            e.preventDefault()
            setModalOpen(true)
          }}
        />
        {customerIds.length > 0 && (
          <div className="d-flex align-items-center gap-1">
            <GreenTickIcon width={18} />
            <p>{t('PackageBuilder.customerSegmentation.customerMatching.successMsg')}</p>
          </div>
        )}
      </Row>
      {modalOpen && <CustomerIds open={modalOpen} setOpen={setModalOpen} onDone={onCustomerIdsChange} />}
    </div>
  )
}

import PropTypes from 'prop-types'
import React, { useState, useReducer } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { initStateObject, objectReducer, FAIL } from '../../reducers/default'
import { resetUserPassword, resetUserPasswordConfirm } from '../../api/auth'
import { checkIfPasswordIsSecureEnough } from '../../utils/helpers'

const initialState = {
  email: '',
  confirmCode: '',
  password: '',
  confirmPassword: '',
  error: '',
  user: null,
  confirmed: false,
  isLoading: false,
  email_has_been_sent: false,
}

/**
 * Reset password form for the application (connected to Cognito AWS in the Backend).
 * @Tags( cognito, api )
 * @Inputs( email, confirmCode, password, confirmPassword )
 * @Endpoints( /auth/initiate_forgot_password, /auth/confirm_forgot_password )
 * @ApiLogic( ../../api/auth )
 */
const ResetPasswordForm = ({ setSubtitle }) => {
  const { t } = useTranslation()
  const [state, setState] = useState(initialState)
  const [formStatus, setFormStatus] = useReducer(objectReducer, initStateObject)
  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value })
  }

  const validateForm = () => {
    return state.email.length > 0
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      resetUserPassword(setFormStatus, state)
    } catch (e) {
      console.log(e)
    }
  }

  const validateFormReset = () => {
    return state.confirmCode.length > 0 && state.password.length > 0 && state.password === state.confirmPassword
  }

  const handleReset = async (event) => {
    event.preventDefault()
    try {
      if (checkIfPasswordIsSecureEnough(state.password).status === false) {
        setFormStatus({ type: FAIL, payload: t(checkIfPasswordIsSecureEnough(state.password).message) })
        return
      }
      await resetUserPasswordConfirm(setFormStatus, state)
    } catch (e) {
      console.log(e)
      setFormStatus({ type: FAIL, payload: t('common.errors.onUpload') })
    }
  }

  const renderEmailForm = () => {
    const { error, email } = state
    return (
      <form onSubmit={handleSubmit}>
        {formStatus.error && formStatus.message && (
          <p data-testid="errorForm" className="alert alert-danger">
            {t(formStatus.message)}
          </p>
        )}
        {error && <p className="alert alert-danger">{error}</p>}
        <div className="form-group">
          <input
            data-cy="email-input-cy"
            type="email"
            className="form-control s-50"
            id="email"
            value={email}
            onChange={(e) => handleChange(e)}
            placeholder={t('common.email')}
          />
        </div>
        <button
          data-cy="forgot-password-submit-button-cy"
          type="submit"
          style={{ width: '100%' }}
          className="bt bt-navy s-50"
          disabled={!validateForm()}
        >
          {t('ForgotPassword.form.btn')}
        </button>
      </form>
    )
  }

  const renderConfirmationForm = () => {
    const { error, confirmCode, password, confirmPassword } = state
    return (
      <form onSubmit={handleReset} autoComplete="off">
        {formStatus.error && formStatus.message && <p className="alert alert-danger">{t(formStatus.message)}</p>}
        {error && <p className="alert alert-danger">{error}</p>}
        <div className="form-group">
          <input
            type="tel"
            className="form-control s-50"
            id="confirmCode"
            name="confirmCode"
            placeholder={t('ForgotPassword.form.confirmCode')}
            value={confirmCode}
            autoComplete="confirm-code"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control s-50"
            id="password"
            name="password"
            placeholder={t('common.newPassword')}
            value={password}
            autoComplete="new-password"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control s-50"
            id="confirmPassword"
            name="confirmPassword"
            placeholder={t('common.confirmPassword')}
            value={confirmPassword}
            autoComplete="off"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <button type="submit" className="bt bt-navy s-50 w-100" disabled={!validateFormReset()}>
          {t('ForgotPassword.form.btn')}
        </button>
      </form>
    )
  }

  const renderSuccess = () => {
    setSubtitle('')
    return (
      <div className="success">
        <p className="alert alert-primary text-center">{t('ForgotPassword.form.success')}</p>
        <Link to="/login" className="bt bt-navy s-50 w-100">
          {t('common.logIn')}
        </Link>
      </div>
    )
  }

  return (
    <div className="signup-form">
      {!formStatus.content.email_has_been_sent && renderEmailForm()}
      {formStatus.content.email_has_been_sent && !formStatus.content.confirmed && renderConfirmationForm()}
      {formStatus.content.email_has_been_sent && formStatus.content.confirmed && renderSuccess()}
    </div>
  )
}

ResetPasswordForm.propTypes = {
  /**
   *  Callback function to change subtitle in header in the last step */
  setSubtitle: PropTypes.func,
}

export default ResetPasswordForm

import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory } from 'react-router-dom'

import EventFormWrapper from 'components/EventEditor/EventFormWrapper'
import PrimaryButton, { TransparentBgButton } from 'components/Shared/Button'
import ErrorMessage from 'components/Shared/ErrorMessage'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import HowItWorks from 'components/Shared/HowItWorks'
import { useReducerAsync } from 'hooks/useReducerAsync'
import { actions, asyncActionHandlers, initialState, reducer } from './itc'
import { useToast } from 'components/Shared/AppToast/ToastProvider'

export default function EventForm({ eventId }) {
  const { t } = useTranslation()
  const { addNotification } = useToast()
  const [eventLoading, setEventLoading] = useState(true)
  const originalForm = useRef(null)
  const form = useRef(null)
  const history = useHistory()
  const [{ loading, error, success }, dispatch] = useReducerAsync(
    reducer,
    initialState,
    asyncActionHandlers,
  )

  const [viewWidth, setViewWidth] = useState(window.innerWidth)
  const handleResize = () => setViewWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const instructionsList = useMemo(
    () => [
      t('Recommendations.OpenCampaigns.itc.howItWorks.details.1'),
      t('Recommendations.OpenCampaigns.itc.howItWorks.details.2'),
    ],
    [t],
  )

  useEffect(() => {
    // upon a successful submit, navigate back, add a notification
    if (success) {
      addNotification({
        message: t('Toasts.Campaigns.itcTriggered.message'),
        description: t('Toasts.Campaigns.itcTriggered.description'),
      })
      history.goBack()
    }
  }, [success, addNotification, history, t])

  if (viewWidth > 1000) {
    return <Redirect to={`/campaigns?drawer-open=true&event-id=${eventId}&itc=true`} />
  }

  return (
    <div
      className={cn('simulation-page event-itc-form', {
        'event-itc-form--loading': eventLoading,
      })}
    >
      <div className="event-itc-form_container">
        <div className="event-itc-form_hiw">
          <h1>{t('Recommendations.OpenCampaigns.itc.title')}</h1>
          <HowItWorks instructionsList={instructionsList} disclaimer="Recommendations.OpenCampaigns.itc.howItWorks.disclaimer" />
        </div>
        <div
          className={cn('event-itc-form_form', {
            'event-itc-form_form--loading': eventLoading,
          })}
        >
          <div className="event-itc-form_form__container">
            <h2>Event editor</h2>
            <EventFormWrapper
              eid={eventId}
              showHowItWorks={false}
              showSessionTimer={false}
              setLoading={setEventLoading}
              onFormChanged={(f) => {
                form.current = f
                if (!originalForm.current) {
                  originalForm.current = f
                }
              }}
              renderFormButtons={() => null}
            />
          </div>
          <div className="event-itc-form_buttons">
            {error && <ErrorMessage className="mb-3" danger>{t(error)}</ErrorMessage>}
            <div className="event-itc-form_buttons__container">
              <TransparentBgButton
                className="bt"
                onClick={() => history.goBack()}
                text={t('Simulation.form.cancel')}
                fullWidth={false}
                color="navy"
              />
              {loading ? (
                <LoadingSpinner title={t('common.sending')} portalBackground={true} />
              ) : (
                <PrimaryButton
                  type="button"
                  classNames="simulation-btn"
                  onClick={() => dispatch({
                    type: actions.submitEventAndRegenerateAsync,
                    payload: { eventId, eventPayload: form.current, originalForm: originalForm.current},
                  })}
                  text={t('Simulation.form.btn')}
                  disabled={loading}
                  fullWidth={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

EventForm.propTypes = {
  eventId: PropTypes.string.isRequired,
}

import React from 'react'
import Dropzone from 'react-dropzone'

import plusIcon from 'images/icons/plus-circle.svg'
import xlsxIcon from '../../../images/icons/xlsx.svg'
import removeIcon from '../../../images/icons/remove-icon.svg'
import { useTranslation } from 'react-i18next'

const AcceptedFile = ({ clearFile, file }) => {
  return (
    <>
      <div className="accepted-file_wrapper accepted-file">
        <div className="accepted-file_remove-icon_bg"></div>
        <img onClick={clearFile} className="accepted-file_remove-icon" src={removeIcon} alt={''} />
        <img className="accepted-file_icon" src={xlsxIcon} alt={''} />
      </div>
      <p className="mt-3">{file[0].name}</p>
    </>
  )
}

const DropZone = ({ onDropFile, file, clearFile }) => {
  const { t } = useTranslation()

  return (
    <div>
      <p>{t('common.uploadFile')}</p>
      <Dropzone onDrop={(acceptedFiles) => onDropFile(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section className="bulk-uploads_drop-zone upload-box">
            <div {...getRootProps()}>
              <input id="bulk-uploader-dropzone" accept=".xlsx, .xls" {...getInputProps()} />
              {file ? (
                <AcceptedFile file={file} clearFile={clearFile} />
              ) : (
                <div className="position-relative d-inline-flex justify-content-center align-items-center flex-column upload-box_add">
                  <img src={plusIcon} alt="" />
                  <span className="add-file">{t('common.add')}</span>
                </div>
              )}
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  )
}

export default DropZone

import React from 'react'
import PropTypes from 'prop-types'

export default function Badge({ text, classNames, children, style = {} }) {
  return (
    <div className={`app-badge ${classNames}`} style={style}>
      {!!text && <span className="app-badge__text">{text}</span>}
      {children}
    </div>
  )
}

Badge.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classNames: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
}

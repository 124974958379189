import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import PropTypes, { object } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { snakeCaseToPascalCase } from '../../../utils/helpers'
import MediaUpload from './Media/MediaUpload'
import { AssetClass } from 'api/models'
import { fetchAudiences } from 'api/integrations'
import { arrayReducer, initStateArray } from 'reducers/default'
import Targeting from './Edit/RunningCampaign/Targeting'
import { Keywords } from './Edit/CampaignForm/Keywords'


export default function RunningCampaign({
  eventId,
  tc,
  tc_run_id,
  keywords,
  creative,
  targeting,
  integrationDetails,
  tasteCluster,
  audienceId,
}) {
  const { t } = useTranslation()
  const [audiences, dispatchAudiencesAction] = useReducer(arrayReducer, initStateArray)

  const retrieveAudiences = useCallback(() => {
    const adAccountId = (integrationDetails || []).find((x) => x.asset_class === AssetClass.ad_account)?.asset_id
    fetchAudiences(dispatchAudiencesAction, adAccountId, 'custom')
  }, [integrationDetails])

  const audienceNames = useMemo(() => {
    if (audienceId) {
      let audienceIds = audienceId.split(',')
      return audienceIds.map((audience_id) => {
        const audience = audiences.items.length && audiences.items.find((audience) => audience.id === audience_id)
        return audience ? audience.name : ''
      })
    } else return []
  }, [audiences, audienceId])

  useEffect(() => {
    retrieveAudiences()
  }, [retrieveAudiences])

  if (!creative) return null

  return (
    <div className="recommendation__details__running">
      <Keywords
        campaign={{ tc, tc_run_id }}
        eventId={eventId}
        isCampaignRunning
        keywords={keywords}
        className="mb-4"
      />
      {creative.body ? (
        <div className="recommendation__details__running__section">
          <p className="recommendation__details__running__section__title">
            {t('Recommendations.campaign.published.body')}
          </p>
          {creative.body.map((body, index) => (
            <p key={index} className="recommendation__details__running__section__body">
              {body}
            </p>
          ))}
        </div>
      ) : null}

      {creative.headline ? (
        <div className="recommendation__details__running__section">
          <p className="recommendation__details__running__section__title">
            {t('Recommendations.campaign.published.headline')}
          </p>
          {creative.headline.map((headline, index) => (
            <p key={index} className="recommendation__details__running__section__body">
              {headline}
            </p>
          ))}
        </div>
      ) : null}

      {Array.isArray(creative.media) && (
        <div className="recommendation__details__running__section row recommendation__details__running__media">
          <div className="col-12">
            <p className="recommendation__details__running__section__title">
              {t('Recommendations.campaign.published.media')}
            </p>
          </div>
          <div className="col-12">
            <MediaUpload onChange={() => {}} onError={() => {}} initialMedia={creative.media} readonly />
          </div>
        </div>
      )}

      {creative.destination_url ? (
        <div className="recommendation__details__running__section">
          <p className="recommendation__details__running__section__title">
            {t('Recommendations.campaign.published.destinationUrl')}
          </p>
          {creative.destination_url.map((destination_url, index) => (
            <p key={index} className="recommendation__details__running__section__body">
              {destination_url}
            </p>
          ))}
        </div>
      ) : null}

      {creative.call_to_action ? (
        <div className="recommendation__details__running__section">
          <p className="recommendation__details__running__section__title">
            {t('Recommendations.campaign.published.callToAction')}
          </p>
          <p className="recommendation__details__running__section__body">
            {t([
              `Recommendations.campaign.setup.call_to_action.${creative.call_to_action}`,
              snakeCaseToPascalCase(creative.call_to_action),
            ])}
          </p>
        </div>
      ) : null}

      {audienceId ? (
        <div className="recommendation__details__running__section">
          <p className="recommendation__details__running__section__title">
            {t('Recommendations.campaign.published.audience')}
          </p>
          {audienceNames.map((name, index) => {
            return (
              <p key={index} className="recommendation__details__running__section__body">
                {name}
              </p>
            )
          })}
        </div>
      ) : (
        <div className="recommendation__details__running__section">
          <p className="recommendation__details__running__section__title">
            {t('Recommendations.campaign.published.audience')}
          </p>
          <p className="recommendation__details__running__section__body">{tasteCluster}</p>
        </div>
      )}

      <Targeting tc={tc} tc_run_id={tc_run_id} targeting={targeting} />

      {creative.description ? (
        <div className="recommendation__details__running__section">
          <p className="recommendation__details__running__section__title">
            {t('Recommendations.campaign.published.description')}
          </p>
          <p className="recommendation__details__running__section__body">{creative.description}</p>
        </div>
      ) : null}
    </div>
  )
}

RunningCampaign.propTypes = {
  eventId: PropTypes.string.isRequired,
  tc: PropTypes.string.isRequired,
  tc_run_id: PropTypes.number.isRequired,
  keywords: PropTypes.array.isRequired,
  creative: PropTypes.object.isRequired,
  targeting: PropTypes.array.isRequired,
  integrationDetails: PropTypes.arrayOf(object),
  tasteCluster: PropTypes.string,
  audienceId: PropTypes.string,
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Searchbar from 'components/Shared/Searchbar'
import EmptyView from 'components/Shared/EmptyView'

function Row({ title, onChange, checked }) {
  return (
    <div className="multi-select_dropdown-menu_row">
      <label className="multi-select_dropdown-menu_custom-checkbox">
        <input onChange={() => onChange(!checked)} checked={checked} type="checkbox" />
        <span>{title}</span>
      </label>
    </div>
  )
}

Row.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function MultiSelect({ items, searchLabel, selectedIds, onChange }) {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')

  const filteredItems = !searchTerm
    ? items
    : items.filter((x) => x.title.toLowerCase().search(escapeRegExp(searchTerm.toLowerCase())) > -1)

  return (
    <div className="multi-select_dropdown-menu">
      <div className="multi-select_dropdown-menu_searchbar_wrapper">
        <h6
          className="mb-2"
          style={{
            color: '#5c5c5c',
            fontWeight: '400',
          }}
        >
          {t('Filters.multiSelect.filterBy')}
        </h6>
        <header className="d-flex ai-b">
          <Searchbar
            handleChange={setSearchTerm}
            placeholder={searchLabel}
            ariaLabel={searchLabel}
            postfixView={<i className="icon fas fa-search" style={{ color: '#ccc' }}></i>}
          />
        </header>
      </div>
      <div className="multi-select_dropdown-menu_inner">
        {
          filteredItems.length === 0 &&
            !!searchTerm && <EmptyView>Empty result :(</EmptyView> // FIXME: Normal Empty message // FIXME: Normal Empty message
        }
        {filteredItems.length === 0 && !searchTerm && <p className="warning-red">{t('Filters.emptySearch')}</p>}
        {filteredItems.length !== 0 &&
          filteredItems.map((item) => (
            <Row
              key={item.id}
              title={item.title}
              checked={selectedIds.includes(item.id)}
              onChange={(checked) => onChange(item.id, checked)}
            />
          ))}
      </div>
    </div>
  )
}

MultiSelect.propTypes = {
  items: PropTypes.array.isRequired,
  selectedIds: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  searchLabel: PropTypes.string.isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'

const Topic = ({ disabled, keyword, index, charLimit = 30 }) => {
  return (
    <li key={index} className="campaign-form__tips__tag-wrapper">
      <div className={`campaign-form__tips__tag ${disabled ? 'disabled' : ''}`}>
        <div className="campaign-form__tips__tag__caption">{keyword}</div>
      </div>
      {!keyword || keyword.length <= charLimit ? null : (
        <span className="campaign-form__tips__tagTooltip">{keyword}</span>
      )}
    </li>
  )
}

Topic.propTypes = {
  keyword: PropTypes.string,
  disabled: PropTypes.bool,
  index: PropTypes.number,
  charLimit: PropTypes.number,
  ref: PropTypes.element,
}

export default Topic

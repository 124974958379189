import { hashObjectValues } from 'utils/helpers'
import { augmentIncomingMedia } from './campaign'
import { TARGETING_MODES } from 'components/Recommendations/Campaign/Edit/CampaignForm/Targeting/targeting'

const disabledVariationsIntegrityCacheKey = (eid) => `sp-hash-${eid}`

export const generativeCreativeField = Object.freeze({
  headlines: 'headlines',
  bodies: 'bodies',
})

export function parseSetupProcess(data, eventId, setCreativeHashes = false) {
  if (setCreativeHashes) {
    initDisabledVariationsCache(eventId, data.creatives)
  }

  const targeting = []
  if (data.targeting) {
    for (const tcTargeting of data.targeting) {
      if ([TARGETING_MODES.postalCodes, TARGETING_MODES.rangeLocations].includes(tcTargeting.targeting_mode)) {
        targeting.push({
          tc: tcTargeting.tc,
          tc_run_id: tcTargeting.tc_run_id,
          targetingMode: tcTargeting.targeting_mode,
          postalCodes: tcTargeting['postal-codes'],
          rangeLocations: tcTargeting['range-locations'],
        })
      } else {
        targeting.push({
          tc: tcTargeting.tc,
          tc_run_id: tcTargeting.tc_run_id,
          targetingMode: TARGETING_MODES.fdTargeting,
        })
      }
    }
  }

  const result = {
    ...data,
    creatives: (data.creatives ?? []).map((x) => ({
      ...x,
      media: augmentIncomingMedia(x.media),
      excluded_variations: x.excluded_variations ?? [],
    })),
    targeting,
    empty: false,
    eid: eventId,
  }

  return result
}

export function clearDisabledVariationsCache(eid) {
  localStorage.removeItem(disabledVariationsIntegrityCacheKey(eid))
}

/**
 * Checks the integrity of disabled variations for a given event ID (eid) and creatives.
 * Optionally, it can compare the integrity hashes with the original hashes and the current disabled variations. This
 * is used while editing campaigns, where autosave is disabled, and the updated disabled variations are kept separately.
 * It will return false if a disabled variation hash that exists in the original hashes is different from the current hash.
 *
 * @param {string} eid - The event ID.
 * @param {Array<Object>} creatives - An array of creative objects.
 * @param {Object|null} originalHashes - The original integrity hashes (optional, default: null).
 * @param {Object|null} currentDisabledVariations - The current disabled variations (optional, default: null).
 * @returns {boolean} - Returns true if the disabled variations are intact, false otherwise.
 */
export function checkDisabledVariationsIntegrity(
  eid,
  creatives,
  originalHashes = null,
  currentDisabledVariations = null,
) {
  const disabledVariations = getDisabledVariationsFromCreatives(creatives)
  const integrityHashes = originalHashes ?? getDisabledVariationsIntegrityCache(eid)

  for (const tc of Object.keys(disabledVariations)) {
    const tcCreative = [creatives].flat().find((c) => c.tc === tc)
    for (const variation of disabledVariations[tc]) {
      if (currentDisabledVariations && !currentDisabledVariations[tc]?.includes(variation)) {
        // Disabled variation was removed
        continue
      }
      const [headlineIndex, bodyIndex, mediaIndex, destinationUrlIndex] = variation.split('').map(Number)
      const headline = tcCreative.headline[headlineIndex - 1]
      const body = tcCreative.body[bodyIndex - 1]
      const media = tcCreative.media[mediaIndex - 1]
      const destinationUrl = tcCreative.destination_url[destinationUrlIndex - 1]

      const hash = hashObjectValues({
        headline,
        body,
        media: media.url,
        destinationUrl,
      })

      const originalHash = integrityHashes[tc].find((x) => x[variation])

      // no original hash found means this disabled variation is new
      if (originalHash && hash !== originalHash[variation]) {
        return false
      }
    }
  }

  return true
}

export function setVariationHashCache(
  eid,
  creatives,
  disabledVariations = getDisabledVariationsFromCreatives(creatives),
) {
  const integrityHashes = getCreativesDisabledVariationHashes(creatives, disabledVariations)

  localStorage.setItem(disabledVariationsIntegrityCacheKey(eid), JSON.stringify(integrityHashes))
}

/**
 * Calculates the integrity hashes for disabled variations of creatives.
 *
 * @param {Array|Object} creatives - The creatives to calculate the hashes for.
 * @param {Object} disabledVariations - The disabled variations for each creative.
 * @returns {Object} - The integrity hashes for disabled variations of creatives.
 */
export function getCreativesDisabledVariationHashes(
  creatives,
  disabledVariations = getDisabledVariationsFromCreatives(creatives),
) {
  const _creatives = [creatives].flat()
  const integrityHashes = _creatives.reduce((acc, c) => {
    acc[c.tc] = []
    return acc
  }, {})

  try {
    for (const tc of Object.keys(disabledVariations)) {
      const tcCreative = _creatives.find((c) => c.tc === tc)
      for (const variation of disabledVariations[tc]) {
        const [headlineIndex, bodyIndex, mediaIndex, destinationUrlIndex] = variation.split('').map(Number)
        const headline = tcCreative.headline[headlineIndex - 1]
        const body = tcCreative.body[bodyIndex - 1]
        const media = tcCreative.media[mediaIndex - 1]
        const destinationUrl = tcCreative.destination_url[destinationUrlIndex - 1]

        const hash = hashObjectValues({
          headline,
          body,
          media: media.url,
          destinationUrl,
        })

        integrityHashes[tcCreative.tc].push({
          [variation]: hash,
        })
      }
    }
  } catch (error) {
    console.error('Failed to init disabled variations cache', error)
  }
  return integrityHashes
}

export function getDisabledVariationsFromCreatives(creatives) {
  return [creatives].flat().reduce((acc, c) => {
    acc[c.tc] = c.excluded_variations || []
    return acc
  }, {})
}

const initDisabledVariationsCache = (eventId, creatives, setIntegrityHashes = true) => {
  const disabledVariations = [creatives].flat().reduce((acc, c) => {
    acc[c.tc] = c.excluded_variations || []
    return acc
  }, {})

  if (!setIntegrityHashes) {
    return
  }

  setVariationHashCache(eventId, creatives, disabledVariations)
}

function getDisabledVariationsIntegrityCache(eid) {
  return JSON.parse(localStorage.getItem(disabledVariationsIntegrityCacheKey(eid)) ?? '{}')
}

import i18n from 'i18next'
import moment from 'moment'
import 'moment/locale/de'

import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const Languages = ['en', 'de', 'fr']

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: false,
    whitelist: Languages,

    keySeparator: '.',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ['translation', 'translation.prisma'],
    // default namespace is set using the I18nextProvider
    fallbackNS: 'translation',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    }
  })

i18n.on('languageChanged', function (lng) {
  moment.locale(lng)
  const localeStrings = {
    en: 'en-US',
    de: 'de-DE',
    fr: 'fr-FR',
  }
  localStorage.setItem('locale', localeStrings[lng] ?? 'de-DE')
})

export default i18n

import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Accordion, Card } from 'react-bootstrap'
import classNames from 'classnames'

import CampaignHeader from 'components/Recommendations/Campaign/CampaignHeader'
import Menu from 'components/Shared/Menu'
import { CampaignEditor } from 'components/Recommendations/Campaign/Edit'
import {
  AssetClass,
  Platform,
  campaignKeywords,
  checkDisabledVariationsIntegrity,
  getCreativesDisabledVariationHashes,
  isCampaignOpen,
  isCampaignRunning,
  prepareCreativePayload,
  tcDisplayName,
} from 'api/models'
import RunningCampaign from 'components/Recommendations/Campaign/RunningCampaign'
import { useToast } from 'components/Shared/AppToast/ToastProvider'

import moreDots from 'images/icons/more_horiz.svg'
import share from 'images/icons/share.svg'
import edit from 'images/icons/edit.svg'
import { SecondaryButton, TransparentBgButton } from 'components/Shared/Button'
import AdSuggestions from '../Summary/AdSuggestions'
import { ArrowDownThreeIcon, CancelIcon } from 'images'
import { getAdSuggestions, getMediaDimensions } from 'utils/helpers'
import { initStateObject, objectReducer } from 'reducers/default'
import { fetchPageInfos } from 'api/integrations'
import PortalModal from 'components/Shared/PortalModal'
import { isEqual } from 'lodash'
import { useReducerAsync } from 'hooks/useReducerAsync'
import { actions, asyncActionHandlers, initialState, reducer } from './runningCampaignsReducer'
import { SetupProvider } from 'components/Recommendations/Campaign/Edit/CampaignSetupProvider'
import { fileStatus } from 'components/Recommendations/Campaign/Media/media'
import { offsetVariationsToHandleRemovedCreativeField } from '../Summary/adSuggestionsReducer'
import { TargetingContextProvider } from 'components/Recommendations/Campaign/Edit/CampaignForm/Targeting/TargetingContext'

function getCampaignTcLabelStatus(campaign) {
  if (isCampaignRunning(campaign)) return null
}

const RunningCampaigns = ({
  event,
  campaigns = [],
  setSharedTc,
  editedTc,
  setEditedTc,
  initialGoal,
  refetchCampaigns,
  isFutureEvent,
}) => {
  const { t } = useTranslation()
  const { addNotification } = useToast()

  const [currentTab, setCurrentTab] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [activeKey, setActiveKey] = useState(null)
  const [activeTcMenu, setActiveTcMenu] = useState(null)
  const [adSuggestions, setAdSuggestions] = useState({})
  const [fbPageInfo, setFbPageInfo] = useReducer(objectReducer, initStateObject)
  const [disabledVariationsPromptOpen, setDisabledVariationsPromptOpen] = useState(false)
  const currentCampaignSetupRef = useRef(null)
  const creativeHashesRef = useRef(null)
  const [{ currentCreative, currentTargeting, currentDisabledVariations, loading, success, error }, dispatch] =
    useReducerAsync(reducer, initialState, asyncActionHandlers)
  const [disabledVariationsWarningModalOpen, setDisabledVariationsWarningModalOpen] = useState(false)

  useEffect(() => {
    const getInfo = async () => {
      const meta_profile = [].find(
        (integration_detail) =>
          integration_detail.asset_class === AssetClass.profile && integration_detail.platform === Platform.facebook,
      )
      await fetchPageInfos(setFbPageInfo, meta_profile?.asset_id)
    }

    getInfo()
  }, [])

  useEffect(() => {
    // handle remote edit success
    if (success) {
      addNotification({
        message: t('Toasts.Campaigns.campaignEdited.message'),
        description: t('Toasts.Campaigns.campaignEdited.description'),
      })
      setEditedTc(null)
      refetchCampaigns()
      currentCampaignSetupRef.current = null
      creativeHashesRef.current = null
    }
  }, [success, addNotification, t, refetchCampaigns, setEditedTc])

  const calculateAdSuggestions = useCallback(
    (campaign) => {
      const constructCreative = async () => {
        const creative = campaign.latest_campaign_setup.creative
        if (creative) {
          const media = await Promise.all(
            creative.media.map(async function (media) {
              const mediaDimensions = await getMediaDimensions(media)
              return {
                ...media,
                height: mediaDimensions.height,
                width: mediaDimensions.width,
                ratio: mediaDimensions.height / mediaDimensions.width,
              }
            }),
          )

          const newCreative = {
            ...creative,
            media,
            tc: campaign.tc,
          }

          const [regular, reels] = getAdSuggestions([newCreative], [fbPageInfo], t)
          setAdSuggestions({ regular, reels })
        }
      }
      constructCreative()
    },
    [fbPageInfo, t],
  )

  const onModalClose = (_, confirmed = false) => {
    // handle ad preview modal close
    const close = () => {
      setModalOpen(false)
      setAdSuggestions({})
      setCurrentTab(0)
    }

    if (!editedTc) {
      return close()
    }

    if (confirmed) {
      dispatch({
        type: actions.setCurrentCreative,
        payload: {
          ...currentCreative,
          excluded_variations: currentDisabledVariations,
        },
      })
      return close()
    }

    if (!isEqual(currentCreative.excluded_variations, currentDisabledVariations)) {
      setDisabledVariationsPromptOpen(true)
    } else {
      close()
    }
  }

  const onEditCampaignClicked = useCallback(
    (e, index, campaign) => {
      e.stopPropagation()
      if (!activeKey || index !== Number(activeKey)) setActiveKey(index.toString())

      currentCampaignSetupRef.current = {
        tc: campaign.tc,
        tc_run_id: campaign.tc_run_id,
        audience_id: campaign.latest_campaign_setup.audience_id,
        creative: {
          audience_id: campaign.latest_campaign_setup.audience_id,
          tc: campaign.tc,
          tc_run_id: campaign.tc_run_id,
          ...campaign.latest_campaign_setup.creative,
        },
        targeting: campaign.latest_campaign_setup.targeting,
        integration_details: campaign.latest_campaign_setup.integration_details,
      }
      creativeHashesRef.current = getCreativesDisabledVariationHashes([currentCampaignSetupRef.current.creative])
      setEditedTc(campaign.tc)
      dispatch({ type: actions.init, payload: { eventId: event.id, campaign } })
    },
    [activeKey, event.id, setEditedTc, dispatch],
  )

  const submitCampaignSetup = useCallback(
    (forceSubmit = false) => {
      if (!currentCreative && !currentTargeting) return
      let creativePayload = {}

      if (currentCreative) {
        const disabledVariationsChanged = !checkDisabledVariationsIntegrity(
          event.id,
          currentCreative,
          creativeHashesRef.current,
          {
            [editedTc]: currentDisabledVariations,
          },
        )
        if (disabledVariationsChanged && forceSubmit !== true) {
          setDisabledVariationsWarningModalOpen(true)
          return
        }

        creativePayload = prepareCreativePayload(currentCreative ?? currentCampaignSetupRef.current.creative)
      }

      const payload = {
        creative: creativePayload,
        targeting: currentTargeting ?? currentCampaignSetupRef.current.targeting,
      }

      dispatch({ type: actions.updateCampaignSetupAsync, payload: { eventId: event.id, ...payload } })
    },
    [currentCreative, currentTargeting, event.id, dispatch, currentDisabledVariations, editedTc],
  )

  const Items = (
    <>
      {campaigns.map((campaign, index) => {
        return (
          <Accordion key={`${campaign.tc}/${campaign.tc_run_id}`} activeKey={activeKey?.toString()} className="p-0">
            <Card
              className={classNames({
                campaign: true,
                active: activeKey && Number(activeKey) === index,
                'campaign--bodiless': isCampaignOpen(campaign),
              })}
            >
              <Accordion.Toggle eventKey={index.toString()}>
                <Card.Header as={Card.Header} className="pt-3">
                  <CampaignHeader
                    campaign={campaign}
                    headerStatus={getCampaignTcLabelStatus(campaign)}
                    onClick={() => {
                      if (isCampaignOpen(campaign)) return

                      setActiveKey(activeKey && index === Number(activeKey) ? null : index.toString())
                    }}
                    showActiveBadge={isFutureEvent}
                  >
                    <>
                      {isFutureEvent && (
                        <div className="recommendation__preview_ads summary_item_button">
                          <SecondaryButton
                            text={t('Recommendations.summary.previewAds')}
                            color="navy"
                            size="small"
                            disabled={currentCreative?.media.some((x) => x.status !== fileStatus.success)}
                            onClick={() => {
                              const cmp =
                                campaign.tc === editedTc
                                  ? {
                                    ...campaign,
                                    latest_campaign_setup: {
                                      ...campaign.latest_campaign_setup,
                                      creative: currentCreative,
                                    },
                                  }
                                  : campaign
                              calculateAdSuggestions(cmp)
                              if (!editedTc) {
                                dispatch({ type: actions.init, payload: { eventId: event.id, campaign } })
                              }
                              setModalOpen(true)
                            }}
                          />
                        </div>
                      )}
                      {isFutureEvent && (
                        <div className="recommendation__more-dots_wrapper" onClick={(e) => e.stopPropagation()}>
                          <img
                            style={{ width: 24 }}
                            src={moreDots}
                            alt="Campaign Options"
                            onClick={() => setActiveTcMenu(campaign.tc)}
                          />
                        </div>
                      )}
                      <div
                        className="recommendation__chevron"
                        onClick={() => setActiveKey(activeKey && index === Number(activeKey) ? null : index.toString())}
                      ></div>
                    </>
                    {isFutureEvent && (
                      <Menu
                        options={[
                          {
                            id: 'share',
                            title: t('common.share'),
                            optionType: 'button',
                            clickHandler: () => setSharedTc(campaign.tc),
                            icon: share,
                          },
                          {
                            id: 'edit',
                            title: t('common.edit'),
                            disabled: editedTc === campaign.tc,
                            hidden: !isCampaignRunning(campaign, { validateNotEnded: true }),
                            optionType: 'button',
                            clickHandler: (e) => onEditCampaignClicked(e, index, campaign),
                            icon: edit,
                          },
                        ]}
                        toggleMenu={() => setActiveTcMenu(null)}
                        open={activeTcMenu === campaign.tc}
                      />
                    )}
                  </CampaignHeader>
                </Card.Header>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={index.toString()}>
                <Card.Body className="pt-0">
                  <hr className="divider" />
                  {editedTc === campaign.tc ? (
                    <SetupProvider setup={currentCampaignSetupRef.current}>
                      <TargetingContextProvider>
                        <CampaignEditor
                          eventId={event.id}
                          eventDate={event.date}
                          campaign={campaign}
                          goal={initialGoal}
                          onTargetingChanged={(targeting) => {
                            dispatch({ type: actions.setCurrentTargeting, payload: targeting })
                          }}
                          onChange={(creative, removedField = null) => {
                            if (removedField) {
                              const disabledVariations = offsetVariationsToHandleRemovedCreativeField(
                                removedField,
                                currentDisabledVariations,
                              )
                              dispatch({ type: actions.setCurrentDisabledVariations, payload: disabledVariations })
                            }
                            dispatch({
                              type: actions.setCurrentCreative,
                              payload: {
                                tc: campaign.tc,
                                tc_run_id: campaign.tc_run_id,
                                ...creative,
                                excluded_variations: currentCreative.excluded_variations,
                              },
                            })
                          }}
                          onCancel={() => {
                            setEditedTc(null)
                            currentCampaignSetupRef.current = null
                            creativeHashesRef.current = null
                            dispatch({ type: actions.init, payload: { eventId: event.id } })
                          }}
                          upstreamLoading={loading}
                          upstreamError={error}
                          onUpdate={submitCampaignSetup}
                        />
                      </TargetingContextProvider>
                    </SetupProvider>
                  ) : (
                    <RunningCampaign
                      eventId={event.id}
                      tc={campaign.tc}
                      tc_run_id={campaign.tc_run_id}
                      keywords={campaignKeywords(campaign).headline}
                      integrationDetails={campaign.latest_campaign_setup.integration_details}
                      tasteCluster={t('Recommendations.campaign.recommendationTitle', {
                        tc: tcDisplayName(campaign),
                      })}
                      creative={campaign.latest_campaign_setup.creative}
                      targeting={campaign.latest_campaign_setup.targeting}
                      audienceId={campaign.latest_campaign_setup.audience_id}
                    />
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )
      })}
    </>
  )

  return (
    <>
      {Items}
      <PortalModal
        isOpen={
          modalOpen && !disabledVariationsPromptOpen && ['undefined', 'null'].indexOf(currentDisabledVariations) === -1
        }
        className="full-height"
        onClickOutside={onModalClose}
      >
        <div className="summary_modal" style={{ height: '85vh' }}>
          <div className="summary_modal_disclaimer">
            <div>
              <h4>{t('Recommendations.summary.previewDisclaimer.title')}</h4>
              <div>{t('Recommendations.summary.previewDisclaimer.description1')}</div>
              <div className="mt-3">{t('Recommendations.summary.previewDisclaimer.description2')}</div>
            </div>
            <div>
              <button type="button" onClick={onModalClose} className="summary_close-btn_wrapper">
                <CancelIcon width={14} className="summary_close-btn_icon" />
              </button>
            </div>
          </div>
          <div className="summary_modal_down-arrow">
            <ArrowDownThreeIcon
              width={20}
              onClick={() => {
                window.location = '#ad-suggestions'
              }}
              className="c-hover"
            ></ArrowDownThreeIcon>
          </div>
          <div className="summary_modal_content" id="ad-suggestions">
            <AdSuggestions
              adSuggestionsRegular={adSuggestions.regular}
              adSuggestionsReels={adSuggestions.reels}
              disabledVariations={{ [currentCreative?.tc]: currentDisabledVariations }}
              onDisabledVariationsChange={(disabledVariations) => {
                dispatch({
                  type: actions.setCurrentDisabledVariations,
                  payload: disabledVariations[currentCreative?.tc],
                })
              }}
              currentTab={currentTab}
              isReadonly={!editedTc}
              setCurrentTab={(tab) => {
                setCurrentTab(tab)
                window.location = '#ad-suggestions'
              }}
            />
          </div>
        </div>
      </PortalModal>

      <PortalModal isOpen={disabledVariationsPromptOpen} onClickOutside={() => setDisabledVariationsPromptOpen(false)}>
        <div className="content compact text-center" style={{ maxWidth: '420px' }}>
          <h1 className="autofill_warning-header">{t('Recommendations.summary.confirmChanges')}</h1>
          <p className="autofill_warning-msg">{t('Recommendations.summary.confirmChangesDescription')}</p>
          <div className="footer">
            <TransparentBgButton
              text={t('common.cancel')}
              onClick={(e) => {
                e.stopPropagation()
                dispatch({ type: actions.init, payload: { eventId: event.id } })
                setDisabledVariationsPromptOpen(false)
              }}
              color="navy"
            />
            <SecondaryButton
              color="orange"
              text={t('common.confirm')}
              onClick={(e) => {
                e.stopPropagation()
                setDisabledVariationsPromptOpen(false)
                onModalClose(null, true)
              }}
              type="button"
              disabledTitle=""
            />
          </div>
        </div>
      </PortalModal>
      <PortalModal
        isOpen={disabledVariationsWarningModalOpen}
        onClickOutside={() => setDisabledVariationsWarningModalOpen(false)}
      >
        <div className="content compact text-center" style={{ maxWidth: '420px' }}>
          <h1 className="autofill_warning-header">{t('Recommendations.summary.adVariationsRemainDisabled')}</h1>
          <p className="autofill_warning-msg">{t('Recommendations.summary.adVariationsRemainDisabledDescription')}</p>
          <div className="footer">
            <TransparentBgButton
              text={t('common.cancel')}
              onClick={() => {
                setDisabledVariationsWarningModalOpen(false)
              }}
              color="navy"
            />
            <SecondaryButton
              color="orange"
              text={t('common.proceed')}
              onClick={(e) => {
                e.stopPropagation()
                setDisabledVariationsWarningModalOpen(false)
                submitCampaignSetup(true)
              }}
              type="button"
              disabledTitle=""
            />
          </div>
        </div>
      </PortalModal>
    </>
  )
}

RunningCampaigns.propTypes = {
  event: PropTypes.object,
  campaigns: PropTypes.arrayOf(Object),
  setSharedTc: PropTypes.func,
  setEditedTc: PropTypes.func,
  editedTc: PropTypes.string,
  initialGoal: PropTypes.string,
  refetchCampaigns: PropTypes.func,
  isFutureEvent: PropTypes.bool,
}

export default RunningCampaigns

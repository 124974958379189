import * as Sentry from '@sentry/react'

import { api, makeApiRequest } from './_init'
import { LOADING, UPDATE, OVERWRITE, FAIL } from '../reducers/default'
import { parseSetupProcess } from './models'

export async function fetchSetupProcess(dispatch, eventId) {
  dispatch({ type: LOADING })

  if (eventId) {
    try {
      const result = await api.get(`/setup_processes/${eventId}`)
      dispatch({ type: UPDATE, payload: parseSetupProcess(result.data, eventId, true) })
    } catch (error) {
      if (error.response?.status === 404) dispatch({ type: OVERWRITE, payload: { empty: true } })
      else dispatch({ type: FAIL })
    }
  }
}

export async function createSetupProcess(eventId, payload) {
  if (eventId) {
    try {
      const result = await api.post(`/setup_processes/${eventId}`, payload)
      return {
        setup: parseSetupProcess(result.data, eventId),
      }
    } catch (error) {
      return {
        error: 'common.errors.inServerResponse',
      }
    }
  }
}

export async function updateSetupProcess(
  dispatch,
  eventId,
  { total_budget, goal, creatives, targeting, conversion_id, integration_details, start_date, end_date },
  { defaultCta, defaultDestinationUrl } = {},
) {
  dispatch({ type: LOADING })

  if (eventId) {
    try {
      if (defaultCta) {
        creatives = creatives.map((creative) => {
          if (!creative.call_to_action) {
            return { ...creative, call_to_action: defaultCta }
          }
          return creative
        })
      }

      if (defaultDestinationUrl) {
        creatives = creatives.map((creative) => {
          if (!creative.destination_url) {
            return { ...creative, destination_url: [defaultDestinationUrl] }
          }
          return creative
        })
      }

      const result = await api.put(`/setup_processes/${eventId}`, {
        total_budget,
        goal,
        creatives,
        targeting,
        conversion_id,
        integration_details,
        start_date,
        end_date,
      })
      dispatch({ type: OVERWRITE, payload: parseSetupProcess(result.data, eventId, false) })
    } catch (error) {
      dispatch({ type: FAIL, payload: 'common.errors.inServerResponse' })
    }
  }
}

export const deleteSetupProcess = async (dispatch, eventId, cancel = true) => {
  dispatch({ type: LOADING })
  if (eventId) {
    try {
      await api.delete(`/setup_processes/${eventId}?cancel=${cancel}`)
      dispatch({ type: OVERWRITE, payload: { cancelled: true } })
    } catch (error) {
      dispatch({ type: FAIL, payload: 'common.errors.inServerResponse' })
    }
  }
}

export const boostSetupProcess = async (dispatch, eventId) => {
  dispatch({ type: LOADING })
  if (eventId) {
    try {
      await api.post(`/setup_processes/${eventId}/boost`)
      dispatch({ type: OVERWRITE, payload: {} })
    } catch (error) {
      dispatch({ type: FAIL, payload: 'common.errors.inServerResponse' })
    }
  }
}

const languageMap = Object.freeze({
  en: 'English',
  de: 'German',
  fr: 'French',
  pt: 'Portuguese',
  fi: 'Finnish',
  sv: 'Swedish',
  es: 'Spanish',
  it: 'Italian',
  nl: 'Dutch',
  pl: 'Polish',
})

export async function fetchGeneratedCreativesAsync(
  { eventId, tc, tcRunId, creativeFieldName, language, requiredNumberOfCreatives = 3 },
  controller = null,
) {
  const params = {
    event_id: eventId,
    tc,
    tc_run_id: tcRunId,
    creatives_type: creativeFieldName,
    required_number_of_creatives: requiredNumberOfCreatives,
    language: languageMap[language],
  }

  try {
    const result = await api.get('/integrations/openai/gpt-4o/generate_creatives', {
      params,
      signal: controller?.signal,
    })

    return {
      results: result.data,
      success: result.status === 200,
    }
  } catch (e) {
    Sentry.captureException(e)
    return { success: false }
  }
}

const setupProcessApi = {
  updateAsync: async (eventId, payload) => {
    const { data, error, status } = await makeApiRequest(api, 'put', `/setup_processes/${eventId}`, payload)
    if (status === 200) {
      return { data: parseSetupProcess(data, eventId, false), error, status }
    }

    return { error, status }
  },
}

export default setupProcessApi

import React, { useEffect, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import store from '../../store'
import { SecondaryButton } from 'components/Shared/Button'
import InfoBox from 'components/Shared/InfoBox'
import { renewToken } from 'api/auth'
import { LOGOUT } from 'reducers/user'

const getInitialTimeDifference = () => {
  const expiresInSec = store.getState()?.user?.expiresInSec - 1
  const minutes = Math.floor(expiresInSec / 60)
  const seconds = expiresInSec - minutes * 60

  return { minutes, seconds }
}

const SessionTimer = ({ classes = '' }) => {
  const { t } = useTranslation()
  const [timeDifference, setTimeDifference] = useState(getInitialTimeDifference())
  const [isRefreshed, setIsRefreshed] = useState(false)
  const refreshToken = store.getState()?.user?.refreshToken

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining()
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    const initialRefresh = async () => {
      return await renewToken(store.dispatch, refreshToken)
    }

    initialRefresh()
  }, [refreshToken])

  const setTimeRemaining = () => {
    let startTime = moment()
    let tokenExpirationDate = moment(store.getState()?.user?.tokenExpirationDate)
    let diff = moment.duration(tokenExpirationDate.diff(startTime))

    if (diff.asMinutes() < 0 && diff.asSeconds() < 0) {
      store.dispatch({ type: LOGOUT })
      return
    }

    setTimeDifference({
      minutes: parseInt(diff.asMinutes()) % 60,
      seconds: parseInt(diff.asSeconds()) % 60,
    })
  }

  const refresh = async () => {
    setIsRefreshed(false)
    await renewToken(store.dispatch, refreshToken)
    setIsRefreshed(true)
  }

  return (
    <div className={`session-timer card card-default mb-0 ${classes}`}>
      <div className="card-header pt-0">
        <div className="d-flex align-items-baseline gap-2 flex-wrap mb-2">
          <div className="d-flex gap-2 flex-wrap">
            <InfoBox
              classNames="d-inline"
              title={t('SessionTimer.tooltipTitle')}
              body={t('SessionTimer.tooltipDescription')}
            >
              {t('SessionTimer.message')}
            </InfoBox>
          </div>
          <span className={`session-timer_time ${isRefreshed ? 'fadein' : ''}`}>
            {timeDifference.minutes} {t('SessionTimer.minute')} {timeDifference.seconds} {t('SessionTimer.second')}
          </span>
          <SecondaryButton text={`${t('SessionTimer.refreshBtn')}`} size="small" onClick={refresh} fullWidth={false} />
        </div>
      </div>
    </div>
  )
}

SessionTimer.propTypes = {
  classes: PropTypes.string,
}

export default SessionTimer

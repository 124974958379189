import PropTypes from 'prop-types'
import React, { useReducer, useEffect, useState, useCallback } from 'react'
import Helmet from 'react-helmet'
import { Redirect, useHistory, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Layout from '../components/Layout'

import { MoreHorizIcon, EditIcon, EventGreyIcon, RemoveIcon } from 'images'
import bin from 'images/icons/bin.svg'

import { initStateObject, objectReducer } from '../reducers/default'
import { fetchEvent, updateEventStatus } from '../api/events'
import { EventStatsProvider } from 'components/Event/Details/EventStatsProvider'
import EventDetailsMobile from 'components/Event/Details/EventDetailsMobile'
import EventAttributionModelContextProvider from 'domain/events/attributionModel/EventAttributionModelContextProvider'
import { ButtonItem, SeparatorItem, SwitchItem } from 'components/Shared/Menu'
import ArchivePrompt from 'components/Recommendations/CampaignStatusModals/ArchivePrompt'
import PortalModal from 'components/Shared/PortalModal'
// import { DEFAULT_EVENT_CATEGORY } from '../utils/data'

const RightMenu = ({ onClick }) => {
  return (
    <div className="events-insights-page_mobile-right-menu">
      <MoreHorizIcon width={23} onClick={() => onClick((prev) => !prev)} />
    </div>
  )
}

RightMenu.propTypes = {
  onClick: PropTypes.func,
}

const ExpandItem = ({ children, t }) => {
  const [isOpen, setIsOpen] = useState(false)
  const selectedStyle = isOpen ? 'selected' : ''
  const arrowIcon = isOpen ? 'up' : 'down'

  return (
    <div
      className={`dropdown-list_item ${selectedStyle}`}
      onClick={() => setIsOpen((prev) => !prev)}
    >
      <div className={`dropdown-list_item_bg ${selectedStyle}`} />
      <div className="dropdown-list_item_header">
        <div className="dropdown-list_item_header_left">
          <span>
            <EventGreyIcon width={20} height={16} />
          </span>
          <span>{t('Event.salesEdit.title')}</span>
        </div>
        <i className={`icon fas fa-chevron-${arrowIcon}`}></i>
      </div>
      {isOpen && (
        <div className={'dropdown-list_item_expanded'} onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      )}
    </div>
  )
}

ExpandItem.propTypes = {
  children: PropTypes.node,
  t: PropTypes.func,
}

/**
 * Chosen event page (by event id passed in the link).
 * It displays benchmark of the event (calculated and predicted) and charts with: sales trend, ticket categories, ticket type, top 5 taste clusters.
 * @Tags( page, event, layout, logged-users, api )
 * @SuggestedTags( event )
 * @Endpoints( /events/ )
 * @ApiLogic( ../api/events )
 * @Routes( /events/130 )
 */
const Event = ({ match }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { eventId } = match.params
  const [event, dispatch] = useReducer(objectReducer, initStateObject)
  const [openList, setOpenList] = useState(false)
  const [frontendStatus, setFrontendStatus] = useState(event.frontend_status)
  const [isArchivePromptOpen, setIsArchivePromptOpen] = useState(false)

  useEffect(() => {
    fetchEvent(dispatch, eventId)
  }, [eventId])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991) {
        history.push(`/events/?drawer-open=true&event-id=${eventId}`)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [history, eventId])

  const archiveEvent = useCallback(() => {
    updateEventStatus(event.id, 'HIDDEN')
      .then((result) => {
        if (result.success) {
          setFrontendStatus('HIDDEN')
          setIsArchivePromptOpen(false)
          history.push(window.location.pathname)
        } else {
          console.error('Failed to update frontent status')
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [event.id, history])

  if (event.error) {
    console.warn(event.message)
    return (
      <Redirect
        to={{
          pathname: '/404',
          state: { reason: 'EVENT404', message: t('Error404.PageTitleReason.event404') },
        }}
      />
    )
  }

  return (
    <Layout
      loggedIn
      title={
        event.content.title
          ? t('Event.PageTitle', { name: event.content.title })
          : t('Event.PageTitleNoName')
      }
      mobileTopBar={{
        back: true,
        centerTitle: event.content.title,
        rightMenu: <RightMenu onClick={setOpenList} />,
        addHistoryRoute: '/events',
        historyCallback: () => {},
      }}
    >
      <Helmet bodyAttributes={{ class: 'events-insights-page mobile-bg-white' }} />
      <EventAttributionModelContextProvider eventId={eventId}>
        <EventStatsProvider eventId={eventId}>
          <main data-testid="events-insights-page">
            {openList && (
              <div>
                <div className="backdrop" onClick={() => setOpenList(false)} />
                <div className="dropdown-list">
                  <span className="close-mobile" onClick={() => setOpenList(false)}>
                    <RemoveIcon width={35} height={35} />
                  </span>
                  <Link to={`/events/edit/${eventId}`} className="dropdown-list_item_header">
                    <div className="dropdown-list_item_header_left">
                      <span>
                        <EditIcon width={20} height={16} />
                      </span>
                      <span>{t('Event.edit')}</span>
                    </div>
                  </Link>
                  {
                    <ExpandItem t={t}>
                      <Link
                        to={`/events/${eventId}/update-sales/sales_summary`}
                        className="dropdown-list_item_expanded_child"
                      >
                        {t('Event.dotsMenu.manualUpload')}
                      </Link>
                      <Link
                        to={`/events/${eventId}/update-sales/document_upload`}
                        className="dropdown-list_item_expanded_child"
                      >
                        {t('Event.dotsMenu.fileUpload')}
                      </Link>
                    </ExpandItem>
                  }
                  {event.frontend_status !== 'HIDDEN' && (
                    <>
                      <SwitchItem
                        item={{
                          title: t('Recommendations.campaignOptions.eventFlagged'),
                          onClick: () => {
                            const newStatus = frontendStatus === 'FLAGGED' ? 'DEFAULT' : 'FLAGGED'
                            updateEventStatus(event.id, newStatus)
                              .then((result) => {
                                if (result.success) {
                                  setFrontendStatus(newStatus)
                                } else {
                                  console.error('Failed to update frontent status')
                                }
                              })
                              .catch((error) => {
                                console.error(error)
                              })
                          },
                          optionType: 'switch',
                          checked: frontendStatus === 'FLAGGED',
                        }}
                      />
                      <SeparatorItem />
                      <ButtonItem
                        item={{
                          title: t('Recommendations.campaignOptions.eventArchive'),
                          clickHandler: () => setIsArchivePromptOpen(true),
                          optionType: 'button',
                          icon: bin,
                          variant: 'danger',
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="container-fluid hide-scroll-bar">
              <EventDetailsMobile event={event} eventId={eventId} />
            </div>
          </main>
        </EventStatsProvider>
      </EventAttributionModelContextProvider>
      <PortalModal
        isOpen={isArchivePromptOpen}
        onClickOutside={() => setIsArchivePromptOpen(false)}
      >
        <ArchivePrompt
          eventTitle={event.title}
          onCancel={() => setIsArchivePromptOpen(false)}
          onConfirm={archiveEvent}
        />
      </PortalModal>
    </Layout>
  )
}

Event.propTypes = {
  /**
   * Link match with event ID
   */
  match: PropTypes.object.isRequired,
}

export default Event

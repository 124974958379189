import React, { useLayoutEffect, useRef } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

function getTextWidth(text, font) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  context.font = font
  const width = context.measureText(text).width
  return width
}

export default function NameInput({ nameInputRef, handlePackageName, setPackageName, packageName }) {
  const labelRef = useRef(null)

  // This helps the nameInput have a predefined width so that the ellipsis would show and edit icon would stick to text.
  // It calculates the width of the nameInput to match the text width. Ellipsis show up when max width is reached.
  useLayoutEffect(() => {
    const child = nameInputRef?.current
    const parent = labelRef?.current

    if (!child || !parent) return

    const textWidth = getTextWidth(child?.value || '', getComputedStyle(child).font)

    if (parent.offsetWidth > child.offsetWidth) {
      child.style.width = textWidth + 'px'
    }
  }, [nameInputRef, packageName])

  return (
    <div data-testid="package-builder-package-name-input" className="package-builder-page_name-select_wrapper">
      <label ref={labelRef} onClick={() => nameInputRef.current.focus()}>
        <span className="input-container">
          <input
            data-cy="package-name-input"
            data-testid="package-builder-package-name-input-element"
            ref={nameInputRef}
            className="package-builder-page_name-select_text"
            value={packageName}
            onChange={(e) => handlePackageName(e.target.value)}
            onBlur={() => (!packageName ? setPackageName(`Package-${moment().format('DD-MM-YYYY:hh')}`) : null)}
          />
        </span>
        <span>
          <i className="icon-pencil" />
        </span>
      </label>
    </div>
  )
}

NameInput.propTypes = {
  nameInputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  handlePackageName: PropTypes.func,
  setPackageName: PropTypes.func,
  packageName: PropTypes.string,
}

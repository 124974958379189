import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { TickWrappedIcon, DonutIcon, ClockIcon, CategoryIcon } from 'images'
import { getEventMarketingCampaignState } from 'api/models'
import { snakeCaseToPascalCase } from 'utils/helpers'

export default function EventRowPrisma({ event, classNames }) {
  const { t } = useTranslation()
  const { hasMarketingCampaign, hasActiveCampaign } = useMemo(
    () => getEventMarketingCampaignState(event),
    [event],
  )

  const isProduct = event.category === 'product'
  const shouldHideCategory = event.category === 'uncategorized'

  return (
    <>
      <div className="event-row_left-content_title_small">{event.title}</div>
      <div className={cn('event-row_left-content_wrapper', classNames)}>
        <div className="event-row_left-content_img">
          <DonutIcon />
        </div>
        <div className="event-row_left-content_title-and-date_wrapper">
          <div className="event-row_left-content_title-and-date_title">{event.title}</div>
          <div className="event-row_left-content_title-and-date_date_wrapper d-flex">
            <span className="event-row_left-content_title-and-date_date_time">
              <span>
                <ClockIcon className="mr-1" height={12} width={17} />
              </span>
              {!shouldHideCategory && <span className="mr-1">
                {t(`Recommendations.eventRow.${isProduct ? 'productDateLabel' : 'eventDateLabel'}`)}
              </span>}
              <time dateTime={moment.utc(event.date).format('DD.MM.YYYY')}>
                {moment.utc(event.date).format('DD.MM.YYYY')}
              </time>
            </span>
            {!shouldHideCategory && <span className="event-row_left-content_title-and-date_date_time">
              <span>
                <CategoryIcon className="mr-1" height={12} width={17} />
              </span>
              <span className="mr-1">{t('Recommendations.eventRow.categoryLabel')}</span>
              <span>
                {t(`Recommendations.eventRow.categories.${event.category}`, {
                  defaultValue: snakeCaseToPascalCase(event.category ?? ''),
                })}
              </span>
            </span>}
            {hasMarketingCampaign && (
              <span
                className={cn(
                  'event-row_left-content_title-and-date_date_active-campaign-indicator_wrapper',
                  {
                    green: hasActiveCampaign,
                    red: !hasActiveCampaign,
                  },
                )}
              >
                <span>
                  <TickWrappedIcon width={13} />
                </span>
                <span className="event-row_left-content_title-and-date_date_active-campaign-indicator_text">
                  {hasActiveCampaign
                    ? t('Recommendations.campaignBadge.active')
                    : t('Recommendations.campaignBadge.inactive')}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

EventRowPrisma.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
    campaign_stop_time: PropTypes.string,
    campaign_status: PropTypes.string,
    category: PropTypes.string,
  }),
  classNames: PropTypes.string,
}

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useReducerAsync } from 'hooks/useReducerAsync'
import { actions, asyncActionHandlers, initialState, reducer } from './itc'
import useIsFirstRender from 'hooks/useFirstRender'

export const ItcStatusContext = React.createContext()
export const ItcStatusDispatchContext = React.createContext()

export const useItcStatus = () => {
  const context = React.useContext(ItcStatusContext)
  if (context === undefined) {
    throw new Error('useItcStatus must be used within a ItcStatusContextProvider')
  }
  return context
}

export const useItcStatusDispatch = () => {
  const context = React.useContext(ItcStatusDispatchContext)
  if (context === undefined) {
    throw new Error('useItcStatusDispatch must be used within a ItcStatusContextProvider')
  }
  return context
}

export const ItcStatusContextProvider = ({ eventId, children }) => {
  const isFirstRender = useIsFirstRender()
  const [state, dispatch] =
    useReducerAsync(reducer, initialState, asyncActionHandlers)

  useEffect(() => {
    // fetch itc status on first render
    if (isFirstRender) {
      dispatch({ type: actions.fetchStatusAsync, payload: { eventId } })
    }
  }, [isFirstRender, dispatch, eventId])

  return (
    <ItcStatusContext.Provider value={state}
    >
      <ItcStatusDispatchContext.Provider value={dispatch}>{children}</ItcStatusDispatchContext.Provider>
    </ItcStatusContext.Provider>
  )
}

ItcStatusContextProvider.propTypes = {
  eventId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

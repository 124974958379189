import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { ClockIcon } from 'images'

export default function EventCardPrisma({ event }) {
  return (
    <div className="event-row_left-content_title-and-date_wrapper m-0">
      <div className="event-row_left-content_title-and-date_title d-block">{event.title}</div>
      <div className="event-row_left-content_title-and-date_date_wrapper d-flex">
        <span className="event-row_left-content_title-and-date_date_time">
          <span>
            <ClockIcon className="mr-1" height={12} width={17} />
          </span>
          <time dateTime={moment.utc(event.start_date_time).format('DD.MM.YYYY')}>
            {moment.utc(event.start_date_time).format('DD.MM.YYYY')}
          </time>
        </span>
      </div>
    </div>
  )
}

EventCardPrisma.propTypes = {
  event: PropTypes.object,
}

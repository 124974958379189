import React, { useEffect, useState } from 'react'
import { Accordion, Card, Form } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import CustomerList from './CustomerList'
import PurchaseDate from './PurchaseDate'
import PriceSensitivity from './PriceSensitivity'
import TicketCategories from './TicketCategories'

export default function CustomerSegmentation() {
  const { t } = useTranslation()
  const location = useLocation()
  const [activeKey, setActiveKey] = useState(null)

  const collapseOpen = location.search.includes('#customer-segmentation')

  useEffect(() => {
    // scroll to the accordion when anchor is present in the URL
    if (collapseOpen) {
      setActiveKey('0')
      const element = document.getElementById('customer-segmentation')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [collapseOpen])

  return (
    <Accordion id="customer-segmentation" className="customer-segmentation" defaultActiveKey={collapseOpen ? '0' : ''}>
      <Accordion.Toggle
        className={cn('accordion-header', { active: activeKey === '0' })}
        as={Card.Header}
        eventKey="0"
        onClick={() => setActiveKey((activeKey && activeKey === '0') || '0')}
      >
        <h5 className="customer-segmentation__header">{t('PackageBuilder.customerSegmentation.title')}</h5>
        <div className={cn('accordion_arrow', { active: activeKey === '0' })} />
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card className="filters">
          <Card.Body>
            <Form className="d-flex flex-column gap-5">
              <CustomerList />
              <PurchaseDate />
              <PriceSensitivity />
              <TicketCategories />
            </Form>
          </Card.Body>
        </Card>
      </Accordion.Collapse>
    </Accordion>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { SecondaryButton } from 'components/Shared/Button'

export default function RegenerateAudienceModalContent() {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className="yes-no-prompt">
      <div className="yes-no-prompt_content">
        <h1>{t('PackageBuilder.regenerateAudienceModal.headline')}</h1>
        <p>{t('PackageBuilder.regenerateAudienceModal.description')}</p>
      </div>
      <div className="yes-no-prompt_footer">
        <SecondaryButton
          className="bt"
          text={t('PackageBuilder.regenerateAudienceModal.btnConfirm')}
          fullWidth={false}
          onClick={() => history.replace('/package-builder')}
        />
      </div>
    </div>
  )
}

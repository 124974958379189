import { useCallback, useEffect, useReducer, useState } from 'react'
import { throttle } from 'lodash'

import { arrayReducer, initStateArray } from '../reducers/default'

export function usePagedList(fetchPage, { params = null, initialPage = 1, containerSelector = null, pageLimit = 50 }) {
  const [page, setPage] = useState({ number: initialPage, loading: false })
  const [list, dispatch] = useReducer(arrayReducer, initStateArray)

  useEffect(() => {
    const controller = new AbortController()
    fetchPage(dispatch, params, { page: page.number, page_limit: pageLimit }, controller)

    return () => controller.abort()
  }, [params, page, fetchPage, pageLimit])

  useEffect(() => {
    const controller = new AbortController()

    const loadNextPage = () => {
      const fetchNewPageAsync = async (pageNumber) => {
        await fetchPage(dispatch, params, { page: pageNumber, page_limit: pageLimit }, controller)
        setPage((page) => ({ ...page, loading: false }))
      }
      if (!page.loading) {
        const pageNumber = page.number + 1
        setPage({ number: pageNumber, loading: true })
        fetchNewPageAsync(pageNumber)
      }
    }

    const container = containerSelector ? document.querySelector(containerSelector) : null
    const handleScroll = () => {
      if (list.maxPage > page.number && !page.loading) {
        if (container) {
          const bottomEdgeOfWrapper = container.getBoundingClientRect().bottom
          const scrolled = window.innerHeight + window.scrollY
          const pageHeight = document.body.scrollHeight
          if (scrolled >= pageHeight || scrolled >= bottomEdgeOfWrapper) {
            loadNextPage()
          }
        } else if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
          loadNextPage()
        }
      }
    }
    handleScroll()
    const throttledScroll = throttle(handleScroll, 1000)

    window.addEventListener('scroll', throttledScroll)
    return () => {
      window.removeEventListener('scroll', throttledScroll)
      controller.abort()
    }
  }, [list.maxPage, page, params, containerSelector, fetchPage, pageLimit])

  const refetch = useCallback(() => {
    setPage({ number: initialPage, loading: false })
  }, [initialPage])

  return { list, page, refetch }
}

import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import ErrorFallback from 'components/Shared/ErrorFallback'
import AttributionModel from './AttributionModel'
import Billing from './Billing'

export default function SystemSettings() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <>
        <div className="settings-page__section">
          <AttributionModel />
        </div>
        <div className="settings-page__section">
          <Billing />
        </div>
      </>
    </ErrorBoundary>
  )
}

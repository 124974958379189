import React, { createContext } from 'react'
import PropTypes from 'prop-types'

import { initialState, fileUploadReducer, asyncActionHandlers } from './files'
import { useReducerAsync } from 'hooks/useReducerAsync'

export const UploadFilesContext = createContext(null)
export const UploadFilesDispatchContext = createContext(null)

export function UploadFilesProvider({ children }) {
  const [state, dispatch] = useReducerAsync(fileUploadReducer, initialState, asyncActionHandlers)

  return (
    <UploadFilesContext.Provider value={state}>
      <UploadFilesDispatchContext.Provider value={dispatch}>{children}</UploadFilesDispatchContext.Provider>
    </UploadFilesContext.Provider>
  )
}

UploadFilesProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

import * as Sentry from '@sentry/react'

import { api } from '../_init'

const metaAuthorizationApi = {
  postAuthorizationCode: async (code) => {
    return await makeApiRequest('post', '/integrations/connect/facebook/authorize', code)
  },
  postAccessToken: async (accessToken) => {
    return await makeApiRequest('post', '/integrations/connect/facebook/token', { access_token: accessToken })
  },
  getStatus: async () => {
    return await makeApiRequest('get', '/integrations/connect/facebook/status')
  },
  listAssets: async () => {
    return await makeApiRequest('get', '/integrations/connect/facebook/assets')
  },
  getAssetTaskStatus: async (taskId) => {
    return await makeApiRequest('get', `/integrations/connect/facebook/task-status/${taskId}`)
  },
  toggleAsset: async (assetId, enabled) => {
    return await makeApiRequest('put', `/integrations/connect/facebook/assets/${assetId}`, { enabled })
  },
  deactivate: async (tokenType) => {
    return await makeApiRequest('delete', '/integrations/connect/facebook/token', {
      token_type: tokenType.toUpperCase(),
    })
  },
  getPartnerToken: async (code, redirectUri) => {
    return await makeApiRequest('post', `/vendor/partners/tokens?code=${code}&redirect_uri=${redirectUri}`)
  },
}

async function makeApiRequest(method, endpoint, requestData, reportError = true) {
  try {
    const headers = {}
    const partnerSession = JSON.parse(sessionStorage.getItem('partner-session') || '{}')
    if ('access_token' in partnerSession) {
      headers['X-Partner-Token'] = partnerSession['access_token']
    }

    const response =
      method === 'delete'
        ? await api.delete(endpoint, { data: requestData, headers })
        : method === 'get'
          ? await api.get(endpoint, { headers })
          : await api[method](endpoint, requestData, { headers })

    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.data.message)
    }

    if (process.env.NODE_ENV === 'development') {
      console.log(`[${method.toUpperCase()}] ${endpoint}`, response.data)
    }

    return {
      data: response.data,
    }
  } catch (error) {
    console.error(error)
    if (reportError) Sentry.captureException(error)
    return { error }
  }
}

export default metaAuthorizationApi

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { SecondaryButton, TransparentBgButton } from 'components/Shared/Button'
import { Trans, useTranslation } from 'react-i18next'

const LoadingAnimation = () => {
  return (
    <div className="package-builder-page_loading-screen snippet" data-title=".dot-shuttle">
      <div className="package-builder-page_loading-screen stage filter-contrast">
        <div className="package-builder-page_loading-screen dot-falling"></div>
      </div>
    </div>
  )
}

const HighlightedPackagesList = ({ highlightedPackages }) => {
  return (
    <div className="highlighted-list">
      {highlightedPackages &&
        highlightedPackages.length > 0 &&
        highlightedPackages.map((eachPackage) => {
          return (
            <div key={eachPackage.id} className="highlighted-list-item">
              <span>{eachPackage.title}</span>
            </div>
          )
        })}
    </div>
  )
}

HighlightedPackagesList.propTypes = {
  highlightedPackages: PropTypes.array,
}

export const MergeContent = ({ updating, setModalType, setModalOpen, handleMerge, highlightedPackages }) => {
  const { t } = useTranslation()
  const [canMerge, setCanMerge] = useState(false)

  useEffect(() => {
    const hasPreviouslyMergedPackages = highlightedPackages.find((p) => p.merge_id)

    if (!hasPreviouslyMergedPackages) {
      setCanMerge(true)
    }
  }, [setCanMerge, highlightedPackages])

  return (
    <div className="merge-content">
      <div className="modal_inner_header_wrapper">
        <h2 className="modal_inner_header_title m-0">{t('PackageBuilder.mergeModal.title')}</h2>
      </div>

      {updating ? (
        <>
          <LoadingAnimation />
          <strong className="mt-4">{t('PackageBuilder.mergeModal.merging')}</strong>
        </>
      ) : canMerge ? (
        <>
          <div>
            <div>
              <p>{t('PackageBuilder.mergeModal.check.1')}</p>
              <p>{t('PackageBuilder.mergeModal.check.2')}</p>
            </div>
            <HighlightedPackagesList highlightedPackages={highlightedPackages} />
          </div>
          <div className="mt-4" style={{ display: 'flex', justifyContent: 'center' }}>
            <TransparentBgButton
              onClick={() => {
                setModalOpen(false)
                setModalType(null)
              }}
              text={'Cancel'}
              color="navy"
            />
            <div style={{ marginLeft: 12 }}>
              <SecondaryButton onClick={handleMerge} text={'Merge'} color={'orange'} />
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <p>
              <Trans i18nKey="PackageBuilder.mergeModal.alreadyMerged.1">
                A package you have selected is <strong>already merged</strong>
              </Trans>
            </p>
            <br />
            <p>{t('PackageBuilder.mergeModal.alreadyMerged.2')}</p>
            <br />
            <HighlightedPackagesList highlightedPackages={highlightedPackages.filter((p) => p.merge_id)} />
          </div>
          <div className="mt-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SecondaryButton
              onClick={() => {
                setModalOpen(false)
                setModalType(null)
              }}
              text={t('PackageBuilder.mergeModal.cancel')}
              color="navy"
            />
          </div>
        </>
      )}
    </div>
  )
}

MergeContent.propTypes = {
  updating: PropTypes.bool,
  setModalType: PropTypes.func,
  setModalOpen: PropTypes.func,
  handleMerge: PropTypes.func,
  highlightedPackages: PropTypes.array,
}

export const DeleteContent = ({ updating, setModalType, setModalOpen, handleDelete, highlightedPackages }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      {updating ? (
        <>
          <LoadingAnimation />
          <strong className="mt-4">Deleting</strong>
        </>
      ) : (
        <>
          <div>
            <p>
              Are you sure you want to <strong>delete</strong> these packages?
            </p>
            <HighlightedPackagesList highlightedPackages={highlightedPackages} />
          </div>
          <div className="mt-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SecondaryButton
              onClick={() => {
                setModalOpen(false)
                setModalType(null)
              }}
              text={'Cancel'}
              color="navy"
            />
            <div style={{ marginLeft: 12 }}>
              <SecondaryButton text={'Delete'} color={'orange'} onClick={handleDelete} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

DeleteContent.propTypes = {
  updating: PropTypes.bool,
  setModalType: PropTypes.func,
  setModalOpen: PropTypes.func,
  handleDelete: PropTypes.func,
  highlightedPackages: PropTypes.array,
}

export default MergeContent

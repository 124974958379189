import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { actions, asyncActionHandlers, initialState, reducer } from './campaignStatusReducer'

import { useReducerAsync } from 'hooks/useReducerAsync'
import useIsFirstRender from 'hooks/useFirstRender'

export const CampaignMenuContext = React.createContext()
export const CampaignMenuDispatchContext = React.createContext()

export const useCampaignMenu = () => {
  const context = React.useContext(CampaignMenuContext)
  if (context === undefined) {
    throw new Error('useCampaignMenu must be used within a CampaignMenuContextProvider')
  }
  return context
}

export const useCampaignMenuDispatch = () => {
  const context = React.useContext(CampaignMenuDispatchContext)
  if (context === undefined) {
    throw new Error('useCampaignMenuDispatch must be used within a CampaignMenuContextProvider')
  }
  return context
}

export const CampaignMenuContextProvider = ({ event, children }) => {
  const isFirstRender = useIsFirstRender()
  const [state, dispatch] = useReducerAsync(reducer, initialState(event), asyncActionHandlers)

  useEffect(() => {
    // fetch itc status on first render
    if (isFirstRender) {
      dispatch({ type: actions.fetchStatusTaskStateAsync, payload: { eventId: event.id } })
    }
  }, [isFirstRender, dispatch, event.id])

  useEffect(() => {
    if (event) {
      dispatch({ type: actions.resetMarketingCampaignStatus, payload: { event } })
    }
  }, [event, dispatch])

  return (
    <CampaignMenuContext.Provider value={state}>
      <CampaignMenuDispatchContext.Provider value={dispatch}>{children}</CampaignMenuDispatchContext.Provider>
    </CampaignMenuContext.Provider>
  )
}

CampaignMenuContextProvider.propTypes = {
  event: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { Trans } from 'react-i18next'

import { CloudUpload } from 'images'

const DropzoneContent = (files) => {
  return (
    <div className={`d-flex gap-4 ${files.length ? 'justify-content-center align-items-center' : 'flex-column'}`}>
      <div>
        <CloudUpload width={60} />
      </div>
      <div className="upload-dropzone_text">
        <Trans i18nKey="Uploads.description">
          _ <span>Select</span> or place a file here.
        </Trans>
      </div>
    </div>
  )
}

const UploadDropzone = ({ onDropFile, files = [] }) => {
  return (
    <div className="mt-4">
      <Dropzone onDrop={(acceptedFiles) => onDropFile(acceptedFiles)} multiple>
        {({ getRootProps, getInputProps }) => (
          <section className={`${files.length ? 'upload-dropzone files-added' : 'upload-dropzone'} upload-box p-4`}>
            <div {...getRootProps()}>
              <input id="upload-dropzone" {...getInputProps()} />
              {DropzoneContent(files)}
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  )
}

UploadDropzone.propTypes = {
  onDropFile: PropTypes.func,
  files: PropTypes.array,
}

export default UploadDropzone

import { uniqBy } from 'utils/helpers'

export const postalCodeSelectStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
  }),
  input: (provided) => ({
    ...provided,
    width: '100%',
    backgroundColor: 'transparent',
  }),
}

export const createSelectOption = (label) => ({
  label,
  value: label.trim(),
})

export const optionToValue = (option) => (typeof option === 'object' && option.value ? option.value.trim() : option?.trim())

export const dedupPostalCodes = (zips) => uniqBy(zips, 'value')

import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export const Row = ({ event, removeEvent, showRemoveButton = true, testid, cypresdId }) => {
  return (
    <div data-cy={cypresdId} data-testid={testid} className="package-builder-page_table-section-row">
      <h6 className="package-builder-page_table-section-row-title">{event.title}</h6>
      <p className="package-builder-page_table-section-row-date">{moment.utc(event.date).format('DD.MM.YYYY HH:mm')}</p>
      <p className="package-builder-page_table-section-row-location">{event.location}</p>
      <p onClick={() => removeEvent(event.eid)} className="package-builder-page_table-section-row-cancel">
        {showRemoveButton ? '✕' : ''}
      </p>
    </div>
  )
}

Row.propTypes = {
  event: PropTypes.object,
  removeEvent: PropTypes.func,
  showRemoveButton: PropTypes.bool,
  testid: PropTypes.string,
  cypresdId: PropTypes.string,
}

const Table = ({ selectedEvents, setSelectedEvents }) => {
  const { t } = useTranslation()
  const removeEvent = (eventId) => {
    const eventsToUpdate = selectedEvents.filter((e) => e.eid !== eventId)
    setSelectedEvents(eventsToUpdate)

    // local storage stuff
    const currentLocalState = JSON.parse(localStorage.getItem('packageBuilderState'))
    if (eventsToUpdate.length) {
      currentLocalState.listItems = eventsToUpdate
    } else {
      currentLocalState.listItems = []
    }
    localStorage.setItem('packageBuilderState', JSON.stringify(currentLocalState))
  }

  return (
    <div
      data-testid="package-builder-selected-events-table"
      className="package-builder-page_table-section-table-container"
    >
      <div className="package-builder-page_table-section-header">
        <strong className="package-builder-page_table-section-row-title">
          {t('PackageBuilder.selectedEvents.title')}
        </strong>
        <strong className="package-builder-page_table-section-row-date">
          {t('PackageBuilder.selectedEvents.date')}
        </strong>
        <strong className="package-builder-page_table-section-row-location">
          {t('PackageBuilder.selectedEvents.location')}
        </strong>
        <strong className="package-builder-page_table-section-row-cancel"></strong>
      </div>
      {selectedEvents && selectedEvents.length > 0
        ? selectedEvents.map((event, index) => {
          return (
            <Row
              cypresdId={`selected-events-table-row-${index + 1}`}
              testid={`package-builder-selected-events-table-row-${index}`}
              key={`table-row-${event.eid}`}
              event={event}
              removeEvent={removeEvent}
            />
          )
        })
        : null}
      {selectedEvents && selectedEvents.length === 0 && !selectedEvents.loading ? (
        <div className="package-builder-page_table-section_empty-message">
          <p data-testid="package-builder-selected-events-table-row-empty-message">
            {t('PackageBuilder.noEventsSelected')}
          </p>
        </div>
      ) : null}
    </div>
  )
}

Table.propTypes = {
  selectedEvents: PropTypes.array,
  setSelectedEvents: PropTypes.func,
}

export default Table

import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

import { DEFAULT_EVENT_FILTERS, filterReducer } from './eventFilters'

export const EventFilterContext = createContext(null)
export const EventFilterDispatchContext = createContext(null)

export function EventFilterProvider({ children }) {
  const [filters, dispatchFiltersAction] = useReducer(filterReducer, DEFAULT_EVENT_FILTERS)

  return (
    <EventFilterContext.Provider value={filters}>
      <EventFilterDispatchContext.Provider value={dispatchFiltersAction}>
        {children}
      </EventFilterDispatchContext.Provider>
    </EventFilterContext.Provider>
  )
}

EventFilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

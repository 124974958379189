import React, { useEffect, useMemo, useReducer, useState } from 'react'
import moment from 'moment'
import PropTypes, { object, string } from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'

import { fetchPackage } from 'api/packageBuilder'
import { initStateObject, objectReducer } from 'reducers/default'
import InfoBox from 'components/Shared/InfoBox'
import Fade from 'components/Shared/Wrappers/Fade'
import CustomerSegmentationSection from '../CreateSteps/PreviewCustomerSegmentation'
import CustomerIds from '../CreateSteps/CustomerSegmentation/CustomerList/CustomerIds'
import { CustomerSegmentationContext } from '../CreateSteps/CustomerSegmentation/CustomerSegmentationContext'
import { remoteSettingsToState } from '../CreateSteps/CustomerSegmentation/customerSegmentation'

const MergedPackageRow = ({ id }) => {
  const [previousPackage, dispatch] = useReducer(objectReducer, initStateObject)

  useEffect(() => {
    fetchPackage(dispatch, id)
  }, [id])

  return (
    <div className="package-builder-page_build_columns_row_wrapper">
      <p>{previousPackage.content.title}</p>
    </div>
  )
}

MergedPackageRow.propTypes = {
  id: PropTypes.string,
}

export default function BuildResults({
  selectedEvents,
  packageName,
  isMerged = false,
  mergedPackages = [],
  targetMode,
  remoteCustomerSegmentation,
  isExpired = false,
}) {
  const { t } = useTranslation()
  const [customerModalOpen, setCustomerModalOpen] = useState(false)

  const displayTargetMode = () => {
    if (targetMode === 'selective_mode') return t('PackageBuilder.modes.selectiveMode')

    if (targetMode === 'inclusive_mode') return t('PackageBuilder.modes.inclusiveMode')
    return ''
  }

  const customerSegmentation = useMemo(() => {
    return remoteSettingsToState(remoteCustomerSegmentation)
  }, [remoteCustomerSegmentation])

  return (
    <Fade transitionDuration={1000} delay={0}>
      <div data-cy="third-step-screen">
        <span className="d-flex flex-row gap-3 hide-mobile mb-4">
          <h3 className="package-builder-page_sub-headlines">{packageName}</h3>
          {isMerged ? <span className="merged-badge ml-2">merged</span> : null}
          {isExpired ? <span className="expired-badge">{t('PackageBuilder.status.expired')}</span> : null}
        </span>
        <Container fluid className="mt-3 px-0">
          <Row className="package-builder-page_build_columns_wrapper">
            <Col className="col-12 col-md-8 col-lg-5 col-wrapper">
              <div className="col-content">
                <div className="package-builder-page_build_columns_header">
                  <h5>
                    <strong className="package-builder-page_sub-headlines">
                      {t('PackageBuilder.packageBuilderResults.events')}
                    </strong>
                  </h5>
                </div>
                <div data-cy="third-step-selected-events-list" style={{ marginTop: 10 }}>
                  {selectedEvents && selectedEvents.length > 0
                    ? selectedEvents.map((event, idx) => {
                      return (
                        // we only want the rows after the first (idx === 0) to have extra margin when Merges is displayed
                        <div
                          key={event.id}
                          className={`package-builder-page_build_columns_row_wrapper ${
                            isMerged && idx > 0 ? 'mt-2' : ''
                          }`}
                        >
                          <p>{event.title}</p>
                          <time className="package-builder-page_build_columns_row date">
                            {moment.utc(event.start_date_time).format('DD.MM.YYYY HH:mm')}
                          </time>
                        </div>
                      )
                    })
                    : null}
                </div>
              </div>
            </Col>
            <Col
              data-cy="third-step-variations-number"
              className={`col-12 col-md-4 col-lg-3 col-wrapper ${isMerged ? 'center-col' : ''}`}
            >
              <div className="col-content">
                <div className="package-builder-page_build_columns_header">
                  <h5>
                    <strong className="package-builder-page_sub-headlines">{t('PackageBuilder.targetMode')}</strong>
                  </h5>
                </div>
                <div className="package-builder-page_build_columns_row_wrapper" style={{ marginTop: 10 }}>
                  <p>{displayTargetMode()}</p>
                </div>
              </div>
            </Col>
            {customerSegmentation && (
              <Col className="col-12 col-lg-4 col-wrapper" data-cy="third-step-variations-number">
                <div className="col-content">
                  <div className="package-builder-page_build_columns_header">
                    <h5>
                      <strong className="package-builder-page_sub-headlines">
                        {t('PackageBuilder.customerSegmentation.title')}
                      </strong>
                    </h5>
                  </div>
                  <div className="package-builder-page_build_columns_row_wrapper" style={{ marginTop: 10 }}>
                    <CustomerSegmentationContext.Provider value={customerSegmentation}>
                      <CustomerSegmentationSection setCustomerModalOpen={setCustomerModalOpen} />
                      <CustomerIds open={customerModalOpen} setOpen={setCustomerModalOpen} readOnly />
                    </CustomerSegmentationContext.Provider>
                  </div>
                </div>
              </Col>
            )}
            {isMerged ? (
              <Col className={`col-12 col-lg-${customerSegmentation ? '12' : '4'} col-wrapper`}>
                <div className="col-content">
                  <InfoBox classNames="d-flex align-items-baseline" content={'Tips.PackageBuilder.MergedPackageList'}>
                    <h5>
                      <strong className="package-builder-page_sub-headlines">
                        {t('PackageBuilder.packageBuilderResults.merges.title')}
                      </strong>
                    </h5>
                  </InfoBox>
                  <p className="mt-2">
                    <Trans i18nKey="PackageBuilder.packageBuilderResults.merges.description">
                      This package has been merged with the following packages to find the <strong>Ultimate Fit</strong>{' '}
                      for audiences.
                    </Trans>
                  </p>
                  <div className="mt-2">
                    {mergedPackages && mergedPackages.length > 0
                      ? mergedPackages.map((mPack) => {
                        return <MergedPackageRow key={mPack} id={mPack} />
                      })
                      : null}
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </Container>
      </div>
    </Fade>
  )
}

BuildResults.propTypes = {
  selectedEvents: PropTypes.arrayOf(object),
  packageName: PropTypes.string,
  isMerged: PropTypes.bool,
  mergedPackages: PropTypes.arrayOf(string),
  targetMode: PropTypes.string,
  isExpired: PropTypes.bool,
  remoteCustomerSegmentation: PropTypes.object,
}

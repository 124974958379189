import React from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'

import Layout from 'components/Layout'

import smoothscroll from 'smoothscroll-polyfill'
import CreateStepper from 'components/PackageBuilder/CreateSteps'
smoothscroll.polyfill()

/**
 * Page for a users to create a new Package.
 * creates a payload for a put request executed in final step and redirects to /package-builder/results/<packageId>
 * @Tags( page, packageBuilder, create, layout, logged-users, api )
 * @SuggestedTags( packageBuilder )
 * @Endpoints( /package-builder/create )
 * @ApiLogic( ../api/package-builder/create/ )
 */

const PackageBuilder = () => {
  const { t } = useTranslation()

  return (
    <Layout
      loggedIn
      title={t('PackageBuilder.title')}
      mobileTopBar={{ back: true, centerTitle: t('PackageBuilder.title') }}
    >
      <Helmet bodyAttributes={{ class: 'package-builder mobile-bg-white' }} />
      <main data-cy="package-builder-create-screen" id="package-builder-create-page" className="pb-4">
        <CreateStepper />
      </main>
    </Layout>
  )
}

export default PackageBuilder

import React, { useEffect, useState, createRef, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import moment from 'moment'

import { createPackage } from 'api/packageBuilder'

import SelectEvents from './SelectEvents'
import SelectTargetingMode from './SelectTargetingMode'
import Preview from './Preview'
import NameInput from 'components/PackageBuilder/components/NameInput'
import ProgressBar from 'components/PackageBuilder/components/ProgressBar'
import LoadingSpinner, { SpinnerSize } from 'components/Shared/LoadingSpinner'
import Button, { TransparentBgButton } from 'components/Shared/Button'

import smoothscroll from 'smoothscroll-polyfill'
import CustomerSegmentation from './CustomerSegmentation'
import {
  CustomerSegmentationContext,
  CustomerSegmentationProvider,
} from './CustomerSegmentation/CustomerSegmentationContext'
smoothscroll.polyfill()

function getFilters(segmentationState) {
  const filters = {}
  if (segmentationState.customerIds.value.length) {
    filters[segmentationState.customerIds.qualifier === 'exclude' ? 'excluded_customers' : 'included_customers'] =
      segmentationState.customerIds.value
  }
  if (segmentationState.excludedPriceRange.value) {
    filters[
      segmentationState.excludedPriceRange.qualifier === 'below'
        ? 'exclude_buying_behavior_below'
        : 'exclude_buying_behavior_above'
    ] = Number(segmentationState.excludedPriceRange.value)
  }
  if (segmentationState.excludedPurchaseDate.value) {
    const dateOnlyValue = moment(segmentationState.excludedPurchaseDate.value).format('YYYY-MM-DD')
    filters[
      segmentationState.excludedPurchaseDate.qualifier === 'before'
        ? 'exclude_old_purchases_before'
        : 'exclude_recent_purchases_after'
    ] = dateOnlyValue
  }
  if (segmentationState.excludedTicketCategories.length) {
    filters.exclude_ticket_categories = segmentationState.excludedTicketCategories
  }
  return filters
}

function CreateStepperContent() {
  const { t } = useTranslation()

  const nameInputRef = createRef()
  const buttonRef = createRef()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [selectedEvents, setSelectedEvents] = useState([])
  const [selectedTargetMode, setSelectedTargetMode] = useState('')
  const [accordionOpen, setAccordionOpen] = useState(true)
  const [currentStep, setCurrentStep] = useState(1)
  const [currentCompletedStep, setCurrentCompletedStep] = useState(1)
  const [packageName, setPackageName] = useState(`Package-${moment().format('DD-MM-YYYY:hh')}`)

  const [redirectId, setRedirectId] = useState(null)

  const segmentationState = useContext(CustomerSegmentationContext)

  const setStep = useCallback(
    (step, { anchor } = {}) => {
      setCurrentStep(step)
      if (anchor) {
        history.push({ search: `#${anchor}` })
      } else {
        history.push({ search: '' })
      }
    },
    [history],
  )

  useEffect(() => {
    // check if a user has previous results in local storage and show them if they have
    const parsedLocalState = JSON.parse(localStorage.getItem('packageBuilderState'))

    if (!parsedLocalState) {
      const initialState = {
        listItems: [],
        targetMode: {},
        variationsNumber: 3,
        currentStep: 1,
        currentCompletedStep: 1,
        name: `Package-${moment().format('DD-MM-YYYY:hh')}`,
        customerSegmentation: {},
      }
      localStorage.setItem('packageBuilderState', JSON.stringify(initialState))
    } else {
      if (parsedLocalState.listItems && parsedLocalState.listItems.length) {
        setSelectedEvents(parsedLocalState.listItems)
      }

      setPackageName(parsedLocalState.name ?? `Package-${moment().format('DD-MM-YYYY:hh')}`)

      setCurrentStep(Number(parsedLocalState.currentCompletedStep))

      setCurrentCompletedStep(Number(parsedLocalState.currentCompletedStep))

      setSelectedTargetMode(parsedLocalState.targetMode)
    }
    return () => {
      // cd unmount
    }
  }, [])

  const handlePackageName = (name) => {
    setPackageName(name)

    const parsedLocalState = JSON.parse(localStorage.getItem('packageBuilderState'))

    parsedLocalState.name = name

    localStorage.setItem('packageBuilderState', JSON.stringify(parsedLocalState))
  }

  const updateStep = (newStep) => {
    const parsedLocalState = JSON.parse(localStorage.getItem('packageBuilderState'))

    setCurrentStep(newStep)
    parsedLocalState.currentStep = newStep
    localStorage.setItem('packageBuilderState', JSON.stringify(parsedLocalState))
  }

  const moveStepForward = () => {
    history.push({ search: '' })
    const parsedLocalState = JSON.parse(localStorage.getItem('packageBuilderState'))

    updateStep(currentStep < 3 ? currentStep + 1 : currentStep)
    setCurrentCompletedStep(currentCompletedStep < 3 ? currentCompletedStep + 1 : 3)

    parsedLocalState.currentCompletedStep = currentCompletedStep < 3 ? currentCompletedStep + 1 : 3
    if (segmentationState) {
      parsedLocalState.customerSegmentation = segmentationState
    }
    localStorage.setItem('packageBuilderState', JSON.stringify(parsedLocalState))
  }

  const moveStepBackward = () => {
    if (currentStep === 1) {
      const initialState = {
        listItems: [],
        variationsNumber: 3,
        currentStep: 1,
        currentCompletedStep: 1,
        name: `Package-${moment().format('DD-MM-YYYY:hh')}`,
        customerSegmentation: {},
      }
      localStorage.setItem('packageBuilderState', JSON.stringify(initialState))
      history.replace('/package-builder')
      return
    }

    history.push({ search: '' })

    updateStep(currentStep > 1 ? currentStep - 1 : 1)
    setCurrentCompletedStep(currentCompletedStep > 1 ? currentCompletedStep - 1 : 1)
    const parsedLocalState = JSON.parse(localStorage.getItem('packageBuilderState'))
    parsedLocalState.currentCompletedStep = currentCompletedStep > 1 ? currentCompletedStep - 1 : 1
    localStorage.setItem('packageBuilderState', JSON.stringify(parsedLocalState))
  }

  const submitFormAndStartOver = async () => {
    setLoading(true)
    try {
      const eventIds = selectedEvents.map((event) => event.id)

      const res = await createPackage({
        num_packages: 3,
        n_top_events: 3,
        event_ids: eventIds,
        package_title: packageName,
        targeting_mode: selectedTargetMode,
        filters: getFilters(segmentationState),
      })

      if (res.success) {
        const initialState = {
          listItems: [],
          variationsNumber: 3,
          currentStep: 1,
          currentCompletedStep: 1,
          name: `Package-${moment().format('DD-MM-YYYY:hh')}`,
          customerSegmentation: {},
        }
        localStorage.setItem('packageBuilderState', JSON.stringify(initialState))

        const { id: packageId } = res.payload

        console.log(`Package created with id: ${packageId}`)

        setRedirectId(packageId)
      }
    } catch (error) {
      console.error({ error })
    } finally {
      setLoading(false)
    }
  }

  if (redirectId) {
    return <Redirect to={`/package-builder/results/${redirectId}`} />
  }

  const isNextDisabled =
    (currentStep === 1 && selectedEvents.length === 0) || (currentStep === 2 && !selectedTargetMode)

  return (
    <div style={{ minHeight: '80vh', paddingBottom: '6em' }} className="create-stepper container-fluid hide-scroll-bar">
      <NameInput
        packageName={packageName}
        handlePackageName={handlePackageName}
        nameInputRef={nameInputRef}
        setPackageName={setPackageName}
      />
      <div className="mt-5">
        <ProgressBar
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          currentCompletedStep={currentCompletedStep}
        />
      </div>
      {loading && <LoadingSpinner portalBackground={false} size={SpinnerSize.LARGE} />}
      <div className={loading ? 'd-none' : ''}>
        {currentStep === 1 && (
          <Card className="package-builder-page_outer-wrapper mt-2">
            <SelectEvents
              selectedEvents={selectedEvents}
              setSelectedEvents={setSelectedEvents}
              accordionOpen={accordionOpen}
              setAccordionOpen={setAccordionOpen}
              updateStep={updateStep}
              buttonRef={buttonRef}
            />
          </Card>
        )}
        {currentStep === 2 ? (
          <div className="d-flex flex-column align-items-center gap-2">
            <Card className="package-builder-page_outer-wrapper mt-2">
              <SelectTargetingMode
                selectedEventsNumber={selectedEvents.length}
                updateStep={updateStep}
                setTargetMode={setSelectedTargetMode}
                targetMode={selectedTargetMode}
              />
            </Card>
            <Card className="package-builder-page_outer-wrapper customer-segmentation-card mt-2 w-100 p-0">
              <CustomerSegmentation />
            </Card>
          </div>
        ) : null}
        {currentStep === 3 ? (
          <Card className="package-builder-page_outer-wrapper mt-2 mb-4">
            <Preview
              selectedEvents={selectedEvents}
              packageName={packageName}
              setCurrentStep={setStep}
              targetMode={selectedTargetMode}
            />
          </Card>
        ) : null}
      </div>
      <div className="package-builder-page_submit-btn" ref={buttonRef}>
        <TransparentBgButton
          className="bt"
          light
          onClick={moveStepBackward}
          text={t('common.back')}
          fullWidth={false}
          color="navy"
        />
        {currentStep === 3 && !loading ? (
          <Button
            cypressId="build-package-button"
            text={t('PackageBuilder.buildPackage')}
            onClick={submitFormAndStartOver}
            disabled={currentStep !== 3 || selectedEvents.length === 0}
          />
        ) : (
          <Button
            cypressId="next-step-button"
            text={t('PackageBuilder.nextStep')}
            disabled={isNextDisabled}
            onClick={moveStepForward}
            hidden={currentStep === 3}
          />
        )}
      </div>
    </div>
  )
}

export default function CreateStepper() {
  return (
    <CustomerSegmentationProvider>
      <CreateStepperContent />
    </CustomerSegmentationProvider>
  )
}

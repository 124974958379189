import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export default function Switch({ label, checked, disabled, onClick, classNames = '' }) {
  return (
    <div className={classnames({ switch: true, disabled: disabled, [classNames]: true })}>
      <div className="switch_label">{label}</div>
      <div className={classnames({ switch_control: true, checked: checked, disabled: disabled })} onClick={onClick} />
    </div>
  )
}

Switch.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  classNames: PropTypes.string,
}

import React, { useCallback, useContext } from 'react'
import DatePicker from 'react-datepicker'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'

import Row from './Row'
import { CustomerSegmentationContext, CustomerSegmentationDispatchContext } from './CustomerSegmentationContext'
import { actions } from './customerSegmentation'
import InfoBox from 'components/Shared/InfoBox'

import calenderIcon from 'images/icons/calender.svg'

export default function PurchaseDate() {
  const { t } = useTranslation()
  const { excludedPurchaseDate: { qualifier, value } } = useContext(CustomerSegmentationContext)
  const dispatch = useContext(CustomerSegmentationDispatchContext)

  const onQualifierChange = useCallback((qualifier) => {
    dispatch({ type: actions.setExcludedPurchaseDate, payload: { qualifier } })
  }, [dispatch])

  const onDateChange = useCallback((date) => {
    dispatch({ type: actions.setExcludedPurchaseDate, payload: { value: date } })
  }, [dispatch])

  const reset = useCallback(() => {
    dispatch({ type: actions.setExcludedPurchaseDate, payload: { qualifier: null, value: null } })
  }, [dispatch])

  return (
    <div>
      <InfoBox content="Tips.PackageBuilder.PurchaseDate">
        <h6 className="d-inline-block">{t('PackageBuilder.customerSegmentation.purchaseDateExclusion')}</h6>
      </InfoBox>
      <Row>
        <Form.Check
          custom
          type="radio"
          label={t('PackageBuilder.customerSegmentation.before')}
          inline
          name="purchase-date"
          id="purchase-date-before"
          checked={qualifier === 'before'}
          onClick={() => qualifier === 'before' ? reset() : onQualifierChange('before')}
        />
        <Form.Check
          custom
          type="radio"
          label={t('PackageBuilder.customerSegmentation.after')}
          inline
          name="purchase-date"
          id="purchase-date-after"
          checked={qualifier === 'after'}
          onClick={() => qualifier === 'after' ? reset() : onQualifierChange('after')}
        />
        <div className="fd-datepicker purchase-date aligned-input">
          <DatePicker
            name="date"
            selected={value ? new Date(value) : null}
            separatePlaceholder={true}
            placeholderText={t('common.date')}
            onChange={onDateChange}
            dateFormat="dd.MM.yyyy"
            locale={i18n.language}
            required
            autoComplete="off"
            style={{ border: 'none' }}
            selectsStart
            className="pt-2"
            disabled={!qualifier}
          />
          <img className="fd-datepicker__icon" onClick={(e) => e.stopPropagation()} src={calenderIcon} alt="" />
        </div>
      </Row>
    </div>
  )
}

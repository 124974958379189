import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const VARIATIONS_THRESHOLD = 50

export default function VariationIndicator({ totalHeadlines, totalBodies, totalMediaFiles }) {
  const { t } = useTranslation()

  const totalVariations = totalHeadlines * totalBodies * totalMediaFiles
  const progress = Math.min((totalVariations / VARIATIONS_THRESHOLD) * 100, 100)
  const msg =
    progress < 75
      ? 'Recommendations.campaign.setup.variationIndicator.insufficientScore'
      : progress < 96
        ? 'Recommendations.campaign.setup.variationIndicator.sufficientScore'
        : 'Recommendations.campaign.setup.variationIndicator.perfectScore'

  return (
    <div className="variation-indicator">
      <div className="variation-indicator_wrapper">
        <div className="variation-indicator_progress" style={{ width: `${progress}%` }}></div>
      </div>
      <div className="variation-indicator_msg">{t(msg, { threshold: VARIATIONS_THRESHOLD })}</div>
    </div>
  )
}

VariationIndicator.propTypes = {
  totalHeadlines: PropTypes.number.isRequired,
  totalBodies: PropTypes.number.isRequired,
  totalMediaFiles: PropTypes.number.isRequired,
}

import * as Sentry from '@sentry/react'

import { notificationSettingsApi } from 'api/notifications'

export const actions = Object.freeze({
  loading: 'LOADING',
  error: 'ERROR',
  success: 'SUCCESS',

  fetchSettingsAsync: 'FETCH_SETTINGS_ASYNC',
  updateSettingAsync: 'UPDATE_SETTING_ASYNC',
  patchSettingsAsync: 'PATCH_SETTINGS_ASYNC',
})

export const initialState = {
  settings: [],
  loading: false,
  error: null,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.loading:
      return {
        ...state,
        loading: !state.loading,
      }
    case actions.error:
      console.trace(action.payload)
      return {
        ...state,
        error: action.payload,
      }
    case actions.success:
      return {
        ...state,
        settings: action.payload ?? [],
      }
    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}

export const asyncActionHandlers = Object.freeze({
  [actions.fetchSettingsAsync]:
    ({ dispatch }) =>
      async () => {
        dispatch({ type: actions.loading })
        try {
          const settings = await notificationSettingsApi.getAll()
          dispatch({ type: actions.success, payload: settings })
        } catch (error) {
          console.error(error)
          Sentry.captureException(error)
          dispatch({ type: actions.error, payload: error })
        } finally {
          dispatch({ type: actions.loading })
        }
      },
  [actions.updateSettingAsync]:
    ({ dispatch, getState }) =>
      async (action) => {
        dispatch({ type: actions.loading })
        try {
          const state = getState()
          const setting = state.settings.find((s) => s.notification_topic === action.payload.notification_topic)
          const index = state.settings.indexOf(setting)
          if (!setting) {
            throw new Error('Could not find setting')
          }

          const newSetting = { ...setting, is_email_enabled: !setting.is_email_enabled }
          await notificationSettingsApi.update(newSetting)
          const settings = [...state.settings]
          settings[index] = newSetting

          dispatch({ type: actions.success, payload: settings })
        } catch (error) {
          console.error(error)
          Sentry.captureException(error)
          dispatch({ type: actions.error, payload: error })
        } finally {
          dispatch({ type: actions.loading })
        }
      },
  [actions.patchSettingsAsync]:
    ({ dispatch, getState }) =>
      async () => {
        dispatch({ type: actions.loading })
        try {
          let settings = getState().settings
          const isEmailEnabled = settings.some((x) => !x.is_email_enabled)

          await notificationSettingsApi.updateAll({ is_email_enabled: isEmailEnabled })
          settings = await notificationSettingsApi.getAll()
          dispatch({ type: actions.success, payload: settings })
        } catch (error) {
          console.error(error)
          Sentry.captureException(error)
          dispatch({ type: actions.error, payload: error })
        } finally {
          dispatch({ type: actions.loading })
        }
      },
})

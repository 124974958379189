import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import throttle from 'lodash/throttle'

import { ReactComponent as InfoIcon } from '../../images/icons/info.svg'
import { useDebounce } from 'hooks'

const getUniqueTooltipId = () => {
  return `tooltip-${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}`
}

/**
 * InfoBox tooltip.
 * @Tags(shared, presentational)
 */
const InfoBox = ({
  children,
  title,
  body,
  content,
  classNames,
  tooltipClassNames,
  iconClassNames,
  placement = 'top',
  withAnchor = true,
  showBody = true,
  trigger = ['hover', 'focus'],
  enabled = true,
  renderContent = null,
  delay,
}) => {
  const tooltipId = useMemo(() => getUniqueTooltipId(), [])
  const { t } = useTranslation()
  const reff = useRef(null)
  const popupRef = useRef(null)
  const [isInside, setIsInside] = useState(false)
  const [show, setShow] = useState(false)
  const debouncedShow = useDebounce(show, delay)
  const [tooltipPlacement, setTooltipPlacement] = useState(placement)

  const internalTitle = title || (content ? t(`${content}.title`) : '')
  let internalBody = body
  if (!internalBody && showBody) {
    internalBody = t(`${content}.body`)
  }

  const tooltip = (
    <Tooltip
      id={tooltipId}
      className={`infobox-tooltip infobox-tooltip-${tooltipPlacement} ${tooltipClassNames ? tooltipClassNames : ''}`}
      placement={tooltipPlacement}
      ref={popupRef}
    >
      {internalTitle && <h5>{internalTitle}</h5>}
      {showBody && internalBody}
    </Tooltip>
  )

  useEffect(() => {
    const handleScroll = () => {
      const isMobile = window.innerWidth < 420
      const largeTooltip = internalBody ? internalBody.length > 160 : false
      const threshold = isMobile ? (largeTooltip ? 280 : 240) : largeTooltip ? 200 : 148
      if (placement === 'top') {
        const topEdge = reff.current ? reff.current.getBoundingClientRect().top : 0
        topEdge <= threshold ? setTooltipPlacement('bottom') : setTooltipPlacement(placement)
      }
    }
    handleScroll()
    const throttledScroll = throttle(handleScroll, 600)

    window.addEventListener('scroll', throttledScroll)
    return () => window.removeEventListener('scroll', throttledScroll)
  })

  useEffect(() => {
    const checkCursorPosition = (e) => {
      const tooltipElem = document.querySelector(`#${tooltipId}`)
      if (!tooltipElem) return

      const rect = tooltipElem.getBoundingClientRect()
      const { clientX, clientY } = e
      const offset = 10
      const inside =
        clientX >= rect.left - offset &&
        clientX <= rect.right + offset &&
        clientY >= rect.top - offset &&
        clientY <= rect.bottom + offset

      setIsInside(inside)
    }

    window.addEventListener('mousemove', checkCursorPosition)

    return () => {
      window.removeEventListener('mousemove', checkCursorPosition)
    }
  }, [tooltipId])

  useEffect(() => {
    if (isInside) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [isInside])

  const delayedOnToggle = (nextShow, delay = 0) => {
    if (isInside) {
      setTimeout(() => {
        delayedOnToggle(nextShow, delay)
      }, delay)
    } else {
      setShow(nextShow)
    }
  }

  const render = useCallback(
    (children) => {
      if (renderContent) {
        return renderContent(children)
      }

      return withAnchor ? children : <span>{children}</span>
    },
    [renderContent, withAnchor],
  )

  if (!enabled) return children

  if (!title && !body && !content) return children

  // TODO: Use an error boundary here to catch errors caused
  // by the Overlay components upon changing placement.

  if (!withAnchor) {
    return (
      <div className={`infobox ${classNames ? classNames : ''}`} style={{ whiteSpace: 'nowrap' }} ref={reff}>
        <OverlayTrigger
          trigger={trigger}
          overlay={tooltip}
          placement={tooltipPlacement}
          delay={delay}
          show={debouncedShow}
          onToggle={delayedOnToggle}
        >
          {render(children)}
        </OverlayTrigger>
      </div>
    )
  }

  return (
    <div className={`infobox ${classNames ? classNames : ''}`} style={{ whiteSpace: 'nowrap' }} ref={reff}>
      {render(children)}
      <OverlayTrigger
        trigger={trigger}
        overlay={tooltip}
        placement={tooltipPlacement}
        delay={delay}
        show={debouncedShow}
        onToggle={delayedOnToggle}
        ref={popupRef}
      >
        <div className={`infobox__icon d-inline-block ml-1 ${iconClassNames ? iconClassNames : ''}`}>
          <InfoIcon />
        </div>
      </OverlayTrigger>
    </div>
  )
}

InfoBox.propTypes = {
  /**
   * HTML lub string content
   */
  children: PropTypes.node,
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.string,
  placement: PropTypes.string,
  classNames: PropTypes.string,
  tooltipClassNames: PropTypes.string,
  iconClassNames: PropTypes.string,
  withAnchor: PropTypes.bool,
  showBody: PropTypes.bool,
  trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  enabled: PropTypes.bool,
  renderContent: PropTypes.func,
  delay: PropTypes.number,
}

InfoBox.defaultProps = {
  placement: 'top',
  withAnchor: true,
  showBody: true,
  trigger: ['hover', 'focus'],
  enabled: true,
  delay: 200,
}

export const ControlledInfoBox = ({ show, children, ...props }) => {
  return show ? <InfoBox {...props}>{children}</InfoBox> : children
}

ControlledInfoBox.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default InfoBox

import React, { useState, useEffect, useReducer, useCallback } from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Card } from 'react-bootstrap'
import PropTypes from 'prop-types'

import Layout from 'components/Layout'

import { initStateObject, objectReducer } from '../reducers/default'
import { fetchPackage, checkPackageStatus } from '../api/packageBuilder'
import VariationsList from 'components/PackageBuilder/Variations/VariationsList'
import PackageList from 'components/PackageBuilder/Results/BuildResults'
import PackageBuildingLoadingScreen from 'components/PackageBuilder/Results/LoadingScreen'
import PackageBuildingFailedScreen from 'components/PackageBuilder/Results/FailedScreen'
import LoadingSpinner, { SpinnerSize } from 'components/Shared/LoadingSpinner'
import { capitalizeString } from 'utils/helpers'

import SideDrawer from 'components/PackageBuilder/components/SideDrawer'

import mockData from '../mock/packageBuilderResultsV2.json'
import { useHistory } from 'react-router-dom'
import { TransparentBgButton } from 'components/Shared/Button'
import TipDialogueBox from 'components/Shared/TipDialogueBox'
import Vignette from 'components/Shared/Vignette'
import { demoAccounts } from '../constants'

const demoEventsList = [
  {
    id: 1233,
    title: 'David Williams @ Munchen',
  },
  {
    id: 12733,
    title: 'Ricky Gervais live 2022',
  },
  {
    id: 12833,
    title: 'Jerry Seinfeld live Xmas',
  },
]

/**
 * Chosen package-builder page (by packageId passed in the link).
 * It displays results of a built package (calculated in /package-builder/create).
 * @Tags( page, packageBuilder, results, layout, logged-users, api )
 * @SuggestedTags( packageBuilder )
 * @Endpoints( /package-builder/packages/<packageId> )
 * @ApiLogic( ../api/package-builder/packages/<packageId> )
 * @Routes( /package-builder/results/<packageId> )
 */

// INITIALIZING = "INITIALIZING"
// INPROGRESS = "INPROGRESS"
// DONE = "DONE"
// FAILED = "FAILED"

const PackageBuilderResults = ({ match }) => {
  const { packageId } = match.params
  const { t } = useTranslation()
  const history = useHistory()

  const [status, setStatus] = useState(null)
  const [loading, setLoading] = useState(true)
  const [packageReady, setPackageReady] = useState(false)
  const [variations, dispatch] = useReducer(objectReducer, initStateObject)

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [activeContent, setActiveContent] = useState(null)
  const [currentUrl, setCurrentUrl] = useState(window.location.href)

  const partnerDetails = localStorage.getItem('partnerDetails')
  const isDemoAccount = partnerDetails && demoAccounts.includes(JSON.parse(partnerDetails).clientId)

  useEffect(() => {
    // api call to check for package results with packageId
    if (!isDemoAccount) {
      fetchPackage(dispatch, packageId)
    }
  }, [packageId, isDemoAccount])

  const checkStatusAndReFetch = useCallback(async () => {
    try {
      const { success, packageStatus } = await checkPackageStatus(packageId)
      if (success && (packageStatus === 'INITIALIZING' || packageStatus === 'INPROGRESS')) {
        setTimeout(checkStatusAndReFetch, 5000)
        return
      }

      if (packageStatus === 'FAILED') {
        setStatus('FAILED')
        return
      }

      if (packageStatus === 'DONE') {
        setPackageReady(true)
        fetchPackage(dispatch, packageId)
        return
      }
    } catch (error) {
      console.log({ error })
    }
  }, [packageId])

  useEffect(() => {
    if (packageReady) {
      setLoading(false)
      return
    }

    if (variations.loading) {
      setLoading(true)
      return
    }

    if (variations.content.status === 'DONE') {
      setPackageReady(true)
      setStatus(variations.content.status)
      setLoading(false)
      return
    }

    if (variations.success && variations.content && variations.content.status) {
      setLoading(false)
      setStatus(variations.content.status)
      checkStatusAndReFetch()
    }
  }, [variations, packageId, checkStatusAndReFetch, packageReady])

  useEffect(() => {
    const pageUrl = window.location.href
    const hrefPathname = pageUrl.slice(pageUrl.lastIndexOf('/') + 1, pageUrl.length)

    if (!isDemoAccount && variations.content && variations.content.content) {
      variations.content.content.forEach((vartiationContent) => {
        if (vartiationContent.title.toLowerCase().replaceAll(' ', '-') === hrefPathname) {
          setActiveContent(vartiationContent)
          setDrawerOpen(true)
        }
      })
    }
  }, [currentUrl, variations, isDemoAccount])

  const handleDrawOpen = (active, url, activeContent) => {
    if (isDemoAccount) {
      setDrawerOpen(active)
      setActiveContent(activeContent)
      return
    }

    if (active) {
      window.history.replaceState(null, capitalizeString(url), `/package-builder/results/${packageId}/${url}`)
      setCurrentUrl(window.location.href)
    } else {
      window.history.replaceState(null, 'Pacakge Builder Results', `/package-builder/results/${packageId}`)
      setCurrentUrl(window.location.href)
      setActiveContent(null)
      setDrawerOpen(false)
    }
  }

  return (
    <Layout
      loggedIn
      title={t('PackageBuilder.title')}
      mobileTopBar={{ back: true, centerTitle: t('PackageBuilder.title') }}
    >
      <Helmet bodyAttributes={{ class: 'package-builder mobile-bg-white' }} />
      <div
        id="package-builder-results"
        data-testid="package-builder-results-screen"
        data-cy="package-builder-results-screen"
        className="package-builder-page"
      >
        <div className="container-fluid no-padding-sm hide-scroll-bar">
          <h4 className="main-title hide-mobile">{t('PackageBuilder.packageBuilderResults.packageOverview')}</h4>
          <div className="m-3">
            {/* loading -- fetching package & status from API */}
            {variations.loading ? <LoadingSpinner portalBackground={false} size={SpinnerSize.LARGE} /> : null}

            {/* package is currently in the flow for being created in the DS / backend */}
            {!isDemoAccount &&
            !loading &&
            variations.success &&
            (status === 'INITIALIZING' || status === 'INPROGRESS') ? (
              <PackageBuildingLoadingScreen title={variations.content.title} />
            ) : null}

            {!isDemoAccount && !variations.loading && (!variations.success || status === 'FAILED') ? (
              <PackageBuildingFailedScreen title={variations.content.title} />
            ) : null}

            {/* package is done and results can viewed */}
            {/* {!loading && ((variations.success && status === "DONE") || isDemoAccount) ? */}
            {isDemoAccount || (!loading && variations.success && (status === 'DONE' || status === 'EXPIRED')) ? (
              <>
                {(mockData.status === 'EXPIRED' || status === 'EXPIRED') && (
                  <div className="m-0">
                    <TipDialogueBox
                      title={t('PackageBuilder.expiredWarning.title')}
                      text={t('PackageBuilder.expiredWarning.content')}
                      variant="regular"
                    />
                  </div>
                )}
                <Card className="package-builder-page_results">
                  <span className="d-flex flex-row gap-3 hide-desktop">
                    <h3 className="package-builder-page_sub-headlines">
                      {isDemoAccount ? t('PackageBuilder.packageBuilderResults.demoResults') : variations.content.title}
                    </h3>
                    {(!!mockData.merge_id || !!variations.content.merge_id) && (
                      <span className="merged-badge ml-1">merged</span>
                    )}
                    {(mockData.status === 'Expired' || status === 'EXPIRED') && (
                      <span className="expired-badge">{t('PackageBuilder.status.expired')}</span>
                    )}
                  </span>
                  <PackageList
                    variationsNumber={isDemoAccount ? 3 : variations.content.num_packages}
                    selectedEvents={isDemoAccount ? demoEventsList : variations.content?.events}
                    packageName={
                      isDemoAccount ? t('PackageBuilder.packageBuilderResults.demoResults') : variations.content.title
                    }
                    isMerged={isDemoAccount ? !!mockData.merge_id : !!variations.content.merge_id}
                    mergedPackages={
                      isDemoAccount && mockData.merge_id
                        ? mockData.merged_packages
                        : variations.content && variations.content.merge_id
                          ? variations.content.merge_id.split('_')
                          : []
                    }
                    targetMode={isDemoAccount ? '' : variations.content?.targeting_mode}
                    isExpired={isDemoAccount ? mockData.status === 'EXPIRED' : variations.content.status === 'EXPIRED'}
                    remoteCustomerSegmentation={variations.content?.filter_settings}
                  />
                </Card>

                <div>
                  <VariationsList
                    setDrawerOpen={handleDrawOpen}
                    setActiveContent={setActiveContent}
                    variationResults={isDemoAccount ? mockData.content : variations.content.content}
                  />
                </div>

                <TransparentBgButton
                  className="bt"
                  onClick={() => history.replace('/package-builder')}
                  text={t('common.back')}
                  fullWidth={false}
                  color="navy"
                  light
                />
                <SideDrawer
                  cypressId="package-builder-results-side-drawer"
                  drawerOpen={drawerOpen}
                  setDrawerOpen={handleDrawOpen}
                  packageId={isDemoAccount ? 'demo-1' : packageId}
                  packageName={
                    isDemoAccount ? t('PackageBuilder.packageBuilderResults.demoResults') : variations.content.title
                  }
                  packageContents={activeContent}
                  packageStatus={variations.content?.status}
                />
                <Vignette visible={drawerOpen} fullScreen />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  )
}

PackageBuilderResults.propTypes = {
  match: PropTypes.object,
}

export default PackageBuilderResults

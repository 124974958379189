import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Menu from 'components/Shared/Menu'
import moreDots from 'images/icons/more_horiz.svg'

export default function EventHeaderMenuWrapper({ options }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="col-auto">
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <div
          className="event-header_more-dots_wrapper"
          onClick={(e) => e.stopPropagation()}
          style={{ borderRight: 'none', position: 'relative' }}
        >
          <div className="event-header_more-dots-button c-hover">
            <img style={{ width: 32 }} src={moreDots} alt="Campaign options" onClick={() => setIsOpen(true)} />
          </div>
          <Menu open={isOpen} toggleMenu={setIsOpen} options={options} classNames="dots-menu_wrapper--expandable" />
        </div>
      </div>
    </div>
  )
}

EventHeaderMenuWrapper.propTypes = {
  options: PropTypes.array.isRequired,
  renderMenu: PropTypes.func,
}
import axios from 'axios'

import { crURL } from './_init'
import { getAuthorizationHeader } from './_token'
import store from 'store'

export const uploadFile = async (file, partnerProduct, onFileUpload = null) => {
  const partnerId = store.getState()?.user?.partnerId

  try {
    let bodyFormData = new FormData()
    bodyFormData.append('file', file)

    const response = await axios({
      method: 'post',
      url: `${crURL}events/bulk_upload?event_type=${partnerProduct}`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: getAuthorizationHeader(),
        'X-Preferred-Partner-Id': partnerId,
      },
      onUploadProgress: (progressEvent) =>
        onFileUpload ? onFileUpload(Math.round((progressEvent.loaded * 100) / progressEvent.total)) : null,
    })
      .then((response) => {
        if (response.status === 200) {
          if (Array.isArray(response.data)) {
            if (response.data.length > 0 && response.data[0] === 400) {
              return {
                success: false,
                error: true,
                errorMsgs: response.data[1].flatMap((errorObj) => Object.keys(errorObj)),
              }
            }
          }
          return {
            success: true,
            error: false,
          }
        } else {
          return {
            success: false,
            error: true,
            errorMsgs: ['common.errors.inServerResponse'],
          }
        }
      })
      .catch((e) => {
        const errorArr = e.response?.data?.detail ?? []
        const errors = errorArr.length ? errorArr.map((error) => error.name) : ['common.errors.inServerResponse']

        return {
          success: false,
          error: true,
          errorMsgs: errors,
        }
      })

    return response
  } catch (error) {
    return {
      success: false,
      error: true,
      errorMsgs: ['common.errors.inServerResponse'],
    }
  }
}

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { registerLocale } from 'react-datepicker'
import moment from 'moment'
import de from 'date-fns/locale/de'
import { Card, Row, Col } from 'react-bootstrap'

import KeyFactsForm from '../../Simulation/SimulationForm/KeyFactsFormPrisma'
import { eventCategories } from '../../Simulation/constants'

import DropDown from '../../Shared/DropDown'
import Input from '../../Shared/Input'
import { useHistory } from 'react-router-dom'
import Textarea from 'components/Shared/Textarea'
import FormButtons from './FormButtons'
import minusIcon from 'images/icons/minus-circle.svg'
import Switch from 'components/Shared/Controls/Switch'
import useHasPermission, { Permissions } from 'hooks/useHasPermission'

registerLocale('de', de)

const formatData = (name, description, fields, tz, t) => {
  let ERROR = false
  let errMsg = t('common.errors.fillRequired')
  const resp = fields.map((field) => {
    let { date, category } = field
    if (!date || !name || !category) ERROR = true

    return {
      name,
      description,
      date,
      category,
      tz,
      status: 'RUNNING',
    }
  })

  if (ERROR) return { error: true, errMsg, resp: null }
  return { error: false, resp }
}

/**
 * Set simulation form with datapicker.
 * @Tags( simulation, api )
 * @Inputs( eventName, Date, Time, Venue, Hall, Capacity, Price, Artists, Tracks, Categories )
 * @NpmPackages( react-datepicker, date-fns )
 */
const EventEditorFormPrisma = ({
  event,
  category,
  postUpdatedEvent,
  renderFormButtons,
  onFormChanged = null,
  onCategoryChanged = null,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [mounted, setMounted] = useState(false)
  const [fieldsChanged, setFieldsChanged] = useState(false)
  const firstRender = useRef(true)
  const hasLookoutAccess = useHasPermission(Permissions.lookout)
  const cancelRedirectUrl = `/${hasLookoutAccess ? 'events' : 'campaigns'}?drawer-open=true&event-id=${event.id}`

  const [error, setError] = useState('')
  const [tz, setTz] = useState('')

  const [simName, setSimName] = useState(event.title)
  const [description, setDescription] = useState(event.description)
  const [fields, setFields] = useState([
    {
      date: event.date || '',
    },
  ])

  useEffect(() => {
    // if onFormChanged is passed, call it with the current form data
    if (!onFormChanged) return

    const fieldsWithCategory = fields.map((eachField) => {
      return {
        category,
        ...eachField,
      }
    })

    const { error, resp: formPayload } = formatData(simName, description, fieldsWithCategory, tz, t)
    if (error) {
      return
    }

    onFormChanged(formPayload[0])
  }, [fields, category, simName, description, onFormChanged, t, tz])

  const removeField = (index) => {
    setFields(fields.filter((item, i) => index !== i))
  }

  const updateInput = (e, idx) => {
    const { name, value } = e.target

    if (name === 'name') {
      setSimName(value)
    } else if (name === 'description') {
      setDescription(value)
    } else {
      const currentFields = [...fields]
      currentFields[idx][name] = value
      setFields(currentFields)
    }
  }

  const updateDate = (date, idx) => {
    const formattedDate = date ? moment(date).format('yyyy-MM-DD') : ''
    const formattedTime = date ? moment(date).format('HH:mm') : ''
    const formattedTimezone = date ? Intl.DateTimeFormat().resolvedOptions().timeZone : ''

    const updatedFields = [...fields]
    updatedFields[idx].time = formattedTime
    updatedFields[idx].date = formattedDate

    setFields(updatedFields)
    setTz(formattedTimezone)
  }

  const submitForm = (e) => {
    e.preventDefault()
    setLoading(true)
    setError('')

    const fieldsWithCategory = fields.map((eachField) => {
      return {
        category,
        ...eachField,
      }
    })

    const {
      error,
      errMsg,
      resp: formPayload,
    } = formatData(simName, description, fieldsWithCategory, tz, t)
    if (error) {
      setError(errMsg)
      setLoading(false)
      return
    }
    const sendFormAsync = async () => {
      try {
        await postUpdatedEvent(formPayload[0])
      } catch (error) {
        setError(error.message)
      }
    }
    sendFormAsync()
    setLoading(false)
  }

  const onCancelClick = () => {
    if (fieldsChanged) {
      return
    }
    history.replace(cancelRedirectUrl)
  }

  useEffect(() => {
    // Determine if fields were changed in any way while skipping the page's first render.
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    setFieldsChanged(true)
  }, [fields, category, simName])

  useEffect(() => {
    if (!mounted) {
      // const formatedDate = event.date ? moment(event.date).format('DD.MM.YYYY') : ''
      // const formatedTime = event.start ? moment(event.start).format('HH:mm') : ''
      const formatedTimezone = event.date ? Intl.DateTimeFormat().resolvedOptions().timeZone : ''

      setTz(formatedTimezone)
      setMounted(true)
    }
  }, [event.date, mounted, setMounted, setTz])

  return (
    <>
      <form className="simulation-form" onSubmit={(e) => e.preventDefault()} noValidate>
        <>
          <div className="simulation-form__section simulation-form__key-facts">
            <Card className="card card-default mb-0">
              <div className="card-header">
                <h2 className="card-title-default">{t('Simulation.nameInputTitle')}</h2>
              </div>
              <Card.Body>
                <div className="simulation-form_header-wrap">
                  <div className="requiredInputBox w-100">
                    <Input
                      placeholder={t('Simulation.form.eventName')}
                      separatePlaceholder={true}
                      value={simName}
                      name="name"
                      onChange={(e) => updateInput(e)}
                      required
                    />
                  </div>
                </div>
                <Textarea
                  classNames="mb-0 mt-3 mt-md-0"
                  placeholder={t('Simulation.form.description')}
                  separatePlaceholder
                  value={description ?? ''}
                  name="description"
                  onChange={(e) => updateInput(e)}
                  maxLength={2500}
                  showMax
                  rows={5}
                />
                <div>
                  <Row className="mt-3">
                    <Col sm={12} md={5} lg={4} className="mb-3 mb-md-0">
                      <KeyFactsForm
                        key={`f-${0}`}
                        updateDate={updateDate}
                        fields={fields[0]}
                        updateInput={updateInput}
                        index={0}
                        removeField={removeField}
                      />
                    </Col>
                    <Col sm={12} md={7} lg={8}>
                      <div className="d-flex align-items-center justify-content-between gap-4">
                        <div className="d-flex align-items-center ml-auto">
                          <Switch
                            label={t('common.events')}
                            checked={false}
                            onClick={() => onCategoryChanged(eventCategories[0].value)}
                          />
                        </div>
                        <div className="requiredInputBox category ml-0">
                          <DropDown
                            onChange={() => {}}
                            value={null}
                            options={[]}
                            placeholder={t('Simulation.form.category')}
                            classNames="simulation-form_sim-category-dropdown"
                            disabled
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </div>

          {fields.length > 1 && (
            <div className="simulation-form__section simulation-form__key-facts">
              <Card className="card-default mb-0">
                <div className="card-header d-flex">
                  <h2 className="card-title-default">{t('Simulation.dateAndLocationFormTitle')}</h2>
                </div>
                <Card.Body className="pb-1">
                  <Row>
                    {fields.slice(1).map((f, idx) => (
                      <Col sm={12} md={6} lg={6} key={`f-${idx}`} className="mb-3">
                        <div className="d-flex align-items-center">
                          <KeyFactsForm
                            updateDate={updateDate}
                            fields={f}
                            updateInput={updateInput}
                            index={idx + 1}
                            removeField={removeField}
                            className="w-100"
                          />
                          <div className="minus-icon">
                            <img
                              src={minusIcon}
                              onClick={() => removeField(idx + 1)}
                              alt="removeIcon"
                            />
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Card.Body>
              </Card>
            </div>
          )}

          {renderFormButtons ? (
            renderFormButtons(submitForm, onCancelClick, fields, loading, error)
          ) : (
            <FormButtons
              submitForm={submitForm}
              onCancelClick={onCancelClick}
              loading={loading}
              error={error}
            />
          )}
        </>
      </form>
    </>
  )
}

EventEditorFormPrisma.propTypes = {
  event: PropTypes.object.isRequired,
  category: PropTypes.string.isRequired,
  postUpdatedEvent: PropTypes.func,
  renderFormButtons: PropTypes.func,
  onFormChanged: PropTypes.func,
  onCategoryChanged: PropTypes.func,
}

export default EventEditorFormPrisma

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Switch from '../../Shared/Controls/Switch'
import { filterReducer } from './eventFilters'

export function PastEventsFilter({ state, dispatch, submit, className }) {
  const [active, setActive] = useState(state.includePastEvents)
  const { t } = useTranslation()

  useEffect(() => {
    setActive(state.includePastEvents)
  }, [state])

  return (
    <div className={`past-events-filter d-flex ${className ? className : ''}`}>
      <Switch
        label={t('Events.searchFilters.pastEvents')}
        classNames="past-events-filter_switch"
        checked={active}
        onClick={() => {
          const newStatus = !active
          setActive(newStatus)
          const action = { type: 'PAST_EVENTS', payload: newStatus }
          const newFilters = filterReducer(state, action)
          dispatch(action)
          submit(newFilters)
        }}
      />
    </div>
  )
}

PastEventsFilter.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  className: PropTypes.string,
}

import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '../../Shared/Button'

export default function CampaignOptimizationModal({ onCancel, onConfirm, status = 'activate' }) {
  const { t } = useTranslation()

  return (
    <div className="yes-no-prompt">
      <div className="yes-no-prompt_content">
        <h1>{t(`Recommendations.campaignOptimizationModal.${status}.title`)}</h1>
        <p>{t(`Recommendations.campaignOptimizationModal.${status}.description`)}</p>
      </div>
      <div className="yes-no-prompt_footer">
        <SecondaryButton
          text={t(`Recommendations.campaignOptimizationModal.${status}.cancel`)}
          onClick={onCancel}
          color="navy"
        />
        <SecondaryButton
          className="bt"
          text={t(`Recommendations.campaignOptimizationModal.${status}.confirm`)}
          onClick={onConfirm}
        />
      </div>
    </div>
  )
}

CampaignOptimizationModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from '@react-pdf/renderer'

import { numberWithThousandsSeparators } from 'utils/helpers'
import { translate } from './util'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'

const pdfStyles = StyleSheet.create({
  wrapper: {
    padding: '0px 32px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '16px',
  },
  elementTitle: {
    fontSize: '12px',
  },
  element: {
    marginRight: '16px',
  },
  elementValueWrapper: {
    display: 'flex',
    flexDirection: 'row',
    margin: '6px 0px',
    backgroundColor: '#f5f6fa',
    borderRadius: '8px',
  },
  elementSingle: {
    backgroundColor: 'rgb(247, 252, 245)',
    border: '1px solid rgb(123, 195, 85)',
    padding: '10px 15px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  elementSeries: {
    padding: '10px 15px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  elementValue: {
    fontSize: '13px',
  },
  elementSubtitle: {
    fontSize: '10px',
    marginTop: '6px',
  },
})

const EventStatsPDFDetails = ({ eventStats, eventSeriesStats, currency }) => {
  const { t } = useTranslation()
  const hasPrismaAccess = hasPrismaAccessTier()

  return (
    <View style={pdfStyles.wrapper}>
      <View style={pdfStyles.row}>
        <View style={pdfStyles.element}>
          <Text style={pdfStyles.elementTitle}>{translate(t, 'Dashboard.eventSeriesStats.purchases.title', hasPrismaAccess)}</Text>
          <View style={pdfStyles.elementValueWrapper}>
            <View style={pdfStyles.elementSingle}>
              <Text style={pdfStyles.elementValue}>
                {numberWithThousandsSeparators(eventStats?.total_transactions)}
              </Text>
              <Text style={pdfStyles.elementSubtitle}>{translate(t, 'Dashboard.eventSeriesStats.singleEvent', hasPrismaAccess)}</Text>
            </View>
            <View style={pdfStyles.elementSeries}>
              <Text style={pdfStyles.elementValue}>
                {numberWithThousandsSeparators(eventSeriesStats?.total_transactions)}
              </Text>
              <Text style={pdfStyles.elementSubtitle}>{translate(t, 'Dashboard.eventSeriesStats.eventSeries', hasPrismaAccess)}</Text>
            </View>
          </View>
        </View>

        <View>
          <Text style={pdfStyles.elementTitle}>{translate(t, 'Dashboard.eventSeriesStats.adSpendPerTicket.title', hasPrismaAccess)}</Text>
          <View style={pdfStyles.elementValueWrapper}>
            <View style={pdfStyles.elementSingle}>
              <Text style={pdfStyles.elementValue}>
                {numberWithThousandsSeparators(eventStats?.cpo, { currency, maxFractionDigits: 0 })}
              </Text>
              <Text style={pdfStyles.elementSubtitle}>{translate(t, 'Dashboard.eventSeriesStats.singleEvent', hasPrismaAccess)}</Text>
            </View>
            <View style={pdfStyles.elementSeries}>
              <Text style={pdfStyles.elementValue}>
                {numberWithThousandsSeparators(eventSeriesStats?.cpo, { currency, maxFractionDigits: 0 })}
              </Text>
              <Text style={pdfStyles.elementSubtitle}>{translate(t, 'Dashboard.eventSeriesStats.eventSeries', hasPrismaAccess)}</Text>
            </View>
          </View>
        </View>
      </View>

      <View style={pdfStyles.row}>
        <View style={pdfStyles.element}>
          <Text style={pdfStyles.elementTitle}>{translate(t, 'Dashboard.eventSeriesStats.revenue.title', hasPrismaAccess)}</Text>
          <View style={pdfStyles.elementValueWrapper}>
            <View style={pdfStyles.elementSingle}>
              <Text style={pdfStyles.elementValue}>
                {numberWithThousandsSeparators(eventStats?.total_revenue, { currency, maxFractionDigits: 0 })}
              </Text>
              <Text style={pdfStyles.elementSubtitle}>{translate(t, 'Dashboard.eventSeriesStats.singleEvent', hasPrismaAccess)}</Text>
            </View>
            <View style={pdfStyles.elementSeries}>
              <Text style={pdfStyles.elementValue}>
                {numberWithThousandsSeparators(eventSeriesStats?.total_revenue, { currency, maxFractionDigits: 0 })}
              </Text>
              <Text style={pdfStyles.elementSubtitle}>{translate(t, 'Dashboard.eventSeriesStats.eventSeries', hasPrismaAccess)}</Text>
            </View>
          </View>
        </View>

        <View>
          <Text style={pdfStyles.elementTitle}>{translate(t, 'Dashboard.eventSeriesStats.roi.title', hasPrismaAccess)}</Text>
          <View style={pdfStyles.elementValueWrapper}>
            <View style={pdfStyles.elementSingle}>
              <Text style={pdfStyles.elementValue}>
                {numberWithThousandsSeparators(eventStats?.roas, { maxFractionDigits: 0, suffix: '%' })}
              </Text>
              <Text style={pdfStyles.elementSubtitle}>{translate(t, 'Dashboard.eventSeriesStats.singleEvent', hasPrismaAccess)}</Text>
            </View>
            <View style={pdfStyles.elementSeries}>
              <Text style={pdfStyles.elementValue}>
                {numberWithThousandsSeparators(eventSeriesStats?.roas, { maxFractionDigits: 0, suffix: '%' })}
              </Text>
              <Text style={pdfStyles.elementSubtitle}>{translate(t, 'Dashboard.eventSeriesStats.eventSeries', hasPrismaAccess)}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

EventStatsPDFDetails.propTypes = {
  eventStats: PropTypes.object,
  eventSeriesStats: PropTypes.object,
  currency: PropTypes.string,
}

export default EventStatsPDFDetails

import React from 'react'
import Toast from 'react-bootstrap/Toast'

import Portal from '../Portal'
import { ToastContainer } from './ToastContainer'
import { useToast } from './ToastProvider'
import { ToastBody } from 'react-bootstrap'
import { ToastHeader } from './ToastHeader'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

export default function AppToastContainer() {
  const { notifications, removeNotification } = useToast()
  const items = notifications.map((notification) => ({
    ...notification,
    nodeRef: React.createRef(null),
  }))

  return (
    <Portal wrapperId="app-toast">
      <ToastContainer position="bottom-start">
        <TransitionGroup className="toasts">
          {items.map((notification) => (
            <CSSTransition key={notification.id} nodeRef={notification.nodeRef} timeout={500} classNames="toast">
              <Toast
                onClose={() => removeNotification(notification.id)}
                show
                delay={60000}
                autohide
                ref={notification.nodeRef}
              >
                <ToastHeader variant={notification.variant} onClose={() => removeNotification(notification.id)}>
                  {notification.message}
                </ToastHeader>
                <ToastBody>{notification.description}</ToastBody>
              </Toast>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </ToastContainer>
    </Portal>
  )
}

import React from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'

import Layout from '../components/Layout'
import Header from '../components/Authentication/Header'
import Footer from '../components/Authentication/Footer'
import LoginForm from '../components/Cognito/LoginForm'
import bgImg from '../images/login-desktop-bg.png'

/**
 * It displays form to reset the recovery the password.
 * @Tags( page, layout, unlogged-users )
 * @SuggestedTags( cognito )
 * @Routes( /login )
 */
const Login = () => {
  const { t } = useTranslation()

  return (
    <Layout title={t('Login.PageTitle')}>
      <Helmet bodyAttributes={{ class: 'authentication-page login-page' }} />
      <div>
        <Header backTo="/" />
        <main className="login-form" data-cy="login-form-cy">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg">
                <div className="login-content">
                  <LoginForm />
                  <Footer />
                </div>
              </div>
              <div
                className="col-lg-auto d-none d-lg-block px-lg-0 image-col"
                style={{ backgroundImage: `url('${bgImg}')` }}
              ></div>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  )
}

export default Login

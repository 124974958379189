import React, { useCallback, useState } from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import Layout from '../components/Layout'
import Header from '../components/Authentication/Header'
import FormHeader from '../components/Authentication/FormHeader'
import Footer from '../components/Authentication/Footer'

import SetPasswordForm from '../components/Cognito/SetPasswordForm'

import bgImg from '../images/login-desktop-bg.png'
import { getQueryParam } from 'utils/helpers'

/**
 * "Set permanent password" - page template.
 * It displays form to set user password.
 * @Tags( page, layout, unlogged-users )
 * @SuggestedTags( cognito )
 * @Routes( /set-password )
 */
const SetPassword = () => {
  const { t } = useTranslation()
  const [success, setSuccess] = useState(false)

  const username = getQueryParam('username')
  const temporaryPassword = decodeURI(getQueryParam('tp'))
  const onSuccess = useCallback(() => {
    setSuccess(true)
  }, [])

  if (!username || !temporaryPassword) {
    return <Redirect to={{ pathname: '/login' }} />
  }

  return (
    <Layout title={t('SetPassword.PageTitle')}>
      <Helmet bodyAttributes={{ class: 'authentication-page set-password-page' }} />
      <div>
        <Header backTo="/login" />
        <main data-cy="set-password-form-cy">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg">
                <div className="set-password-content">
                  {!success && <FormHeader title={t('SetPassword.PageTitle')} subtitle={t('SetPassword.form.title')} />}
                  {success && <FormHeader title={t('SetPassword.SuccessPageTitle')} />}
                  <SetPasswordForm username={username} temporaryPassword={temporaryPassword} onSuccess={onSuccess} />
                  <Footer />
                </div>
              </div>
              <div
                className="col-lg-auto d-none d-lg-block px-lg-0 image-col"
                style={{ backgroundImage: `url('${bgImg}')` }}
              ></div>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  )
}

export default SetPassword

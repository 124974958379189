import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary } from 'react-error-boundary'

import EventHeader from 'components/Event/Details/EventHeader'
import { EventCampaigns } from '../EventCampaigns'
import AutosaveStatus from './Edit/AutosaveStatus'
import { EventStatsProvider } from 'components/Event/Details/EventStatsProvider'
import CampaignSetupProvider from './Edit/CampaignSetupProvider'
import ErrorFallback from 'components/Shared/ErrorFallback'
import ErrorMessage from 'components/Shared/ErrorMessage'
import CampaignMenu from './CampaignMenu/CampaignMenu'
import { useCampaignMenu } from './CampaignMenu/CampaignMenuContext'
import EventAttributionModelContextProvider from 'domain/events/attributionModel/EventAttributionModelContextProvider'

export default function Recommendation({ event }) {
  const { t } = useTranslation()

  const { error: campaignMenuError } = useCampaignMenu()

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <EventAttributionModelContextProvider eventId={event.id}>
        <EventStatsProvider eventId={event.id}>
          <CampaignSetupProvider eventId={event.id}>
            <>
              <EventHeader
                classes="event-header_recommendation-drawer-padding"
                event={event}
                showActiveBadge={false}
                renderMenu={() => <CampaignMenu event={event} isWave />}
              >
                <AutosaveStatus />
              </EventHeader>
              {campaignMenuError && (
                <ErrorMessage classNames="recommendations-page_error" danger>
                  {t(campaignMenuError)}
                </ErrorMessage>
              )}
              <EventCampaigns eventId={event.id} />
            </>
          </CampaignSetupProvider>
        </EventStatsProvider>
      </EventAttributionModelContextProvider>
    </ErrorBoundary>
  )
}

Recommendation.propTypes = {
  event: PropTypes.object.isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { CloudUpload } from 'images'
import InfoBox from 'components/Shared/InfoBox'

const DropzoneContent = (media) => {
  return (
    <div className={`d-flex align-items-center justify-content-center ${media.length ? 'flex-column gap-2' : 'gap-4'}`}>
      <div>
        <CloudUpload width={media.length ? 40 : 50} />
      </div>
      <div className="media-dropzone_text">
        <Trans i18nKey="Recommendations.campaign.setup.media.drop">
          _ <span>Select</span> or place an image or video here.
        </Trans>
      </div>
    </div>
  )
}

const MediaDropzone = ({ getRootProps, getInputProps, media = [], isMobileSize, canAddInternal }) => {
  const { t } = useTranslation()
  const cn = classnames({
    'p-4': true,
    'media-dropzone': true,
    'files-added': media.length,
    'mobile-view': isMobileSize,
    disabled: !canAddInternal,
  })

  const canShowInfobox = canAddInternal ? 'none' : ['hover', 'focus']

  return (
    <section className={cn}>
      <InfoBox
        title={t('Tips.Recommendations.Limit.title')}
        body={t('Tips.Recommendations.Limit.media')}
        withAnchor={false}
        trigger={canShowInfobox}
        classNames="text-wrap"
      >
        <div
          {...getRootProps({
            onClick: (event) => !canAddInternal && event.stopPropagation(),
          })}
        >
          <input id="upload-dropzone" {...getInputProps()} />
          {DropzoneContent(media)}
        </div>
      </InfoBox>
    </section>
  )
}

MediaDropzone.propTypes = {
  getRootProps: PropTypes.func,
  getInputProps: PropTypes.func,
  media: PropTypes.array,
  isMobileSize: PropTypes.bool,
  canAddInternal: PropTypes.bool,
}

export default MediaDropzone

import React, { useState, useRef, createRef, useEffect, useCallback, useMemo } from 'react'
import { CSVLink } from 'react-csv'
import { useReactToPrint } from 'react-to-print'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import { fetchCustomerCSV, regenerateAudience } from 'api/packageBuilder'
import MobileTopBar from 'components/Shared/MobileTopBar'
import { Topics } from '../Variations/VariationsCard'
import LoadingSpinner, { SpinnerSize } from 'components/Shared/LoadingSpinner'
import { DownloadButton, SecondaryButton } from 'components/Shared/Button'
import cancelled from 'images/icons/cancelled.svg'

import PortalModal from 'components/Shared/PortalModal'
import RegenerateAudienceModalContent from '../Modals/RegenrateAudienceModalContent'
import { useOutsideCheckClick } from 'hooks/UseOnClickOutside'

import { numberWithThousandsSeparators } from 'utils/helpers'

import smoothscroll from 'smoothscroll-polyfill'
import { Card, Accordion } from 'react-bootstrap'
import InfoBox from 'components/Shared/InfoBox'
import EventCard from './ExpandableEventCard'

import description from 'images/icons/campaign.svg'
import { demoAccounts } from '../../../constants'
import useHasPermission, { Permissions } from 'hooks/useHasPermission'

smoothscroll.polyfill()

const AccordionInfoBox = ({ title, subTitle }) => {
  return (
    <div className="package-builder-page_side-drawer_accordion-content_stat-box_wrapper">
      <p className="package-builder-page_side-drawer_accordion-content_stat-box_title">{title}</p>
      <p className="package-builder-page_side-drawer_accordion-content_stat-box_sub-title">{subTitle}</p>
    </div>
  )
}

AccordionInfoBox.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
}

const ContentBody = ({ event, t }) => (
  <>
    <div className="package-builder-page_side-drawer_accordion-content_row with-border d-flex ">
      <AccordionInfoBox
        title={t('PackageBuilder.packageBuilderResults.topEvents.dateTime')}
        subTitle={moment.utc(event.start_date_time).format('DD.MM.YYYY HH:mm')}
      />
      <AccordionInfoBox title={t('PackageBuilder.packageBuilderResults.topEvents.venue')} subTitle={event.hall_name} />
    </div>
    <div className="package-builder-page_side-drawer_accordion-content_row pb-4">
      <h6 className="package-builder-page_side-drawer_each-row_title mt-2">
        {t('PackageBuilder.packageBuilderResults.topics')}
      </h6>
      <Topics keywords={event.keywords} key={`${event.title}-side-drawer-`} limit={event.keywords.length} />
    </div>
  </>
)

ContentBody.propTypes = {
  event: PropTypes.object,
  t: PropTypes.func,
}

const CustomerStatCard = ({ title, value, isMoney, className = '' }) => {
  const userCurrency = localStorage ? localStorage.getItem('userCurrency') : '€'
  const numberOptions = isMoney ? { maxFractionDigits: 0, currency: userCurrency } : { maxFractionDigits: 0 }

  return (
    <div className={`events-insights-page_statistics_row_wrapper mb-0 ${className}`}>
      <div className={'events-insights-page_statistics_row_icon'}>
        <img src={description} alt="description" style={{ height: '18px', width: '18px' }} />
      </div>
      <div className="events-insights-page_statistics_row_text_wrapper">
        <p className="events-insights-page_statistics_row_text_value">
          <strong>{numberWithThousandsSeparators(value, numberOptions)}</strong>
        </p>
        <p className="events-insights-page_statistics_row_text_title">{title}</p>
      </div>
    </div>
  )
}

CustomerStatCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  isMoney: PropTypes.bool,
  className: PropTypes.string,
}

const Drawer = ({ drawerOpen, setDrawerOpen, packageContents, packageId, packageName, cypressId, packageStatus }) => {
  const drawerRef = useRef(null)
  const { t } = useTranslation()
  const history = useHistory()
  const csvRef = createRef()

  const [loading, setLoading] = useState(false)
  const [customerCSV, setCustomerCSV] = useState('empty csv')
  const [mounted, setMounted] = useState(false)
  const [fileName, setFileName] = useState(`${packageName}-${packageId}-customer-ids.csv`)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeKey, setActiveKey] = useState(null)
  const hasBackhaulAccess = useHasPermission(Permissions.backhaul)

  const dispatch = useDispatch()

  const partnerDetails = localStorage.getItem('partnerDetails')
  const isDemoAccount = partnerDetails && demoAccounts.includes(JSON.parse(partnerDetails).clientId)

  useOutsideCheckClick(drawerRef, setDrawerOpen, drawerOpen)

  const handlePrint = useReactToPrint({
    content: () => drawerRef.current,
  })

  const topEvents = useMemo(() => {
    const events = []
    const contentsArray = Object.keys(packageContents || {})

    for (let i = 0; i < contentsArray.length; i++) {
      if (contentsArray[i].includes('top_event')) {
        events.push(packageContents[contentsArray[i]])
      }
    }

    return events
  }, [packageContents])

  const downloadCSV = useCallback(async (id, title, contentTitle) => {
    setLoading(true)
    try {
      const res = await fetchCustomerCSV(id, contentTitle)
      if (res.success) {
        setFileName(`${title}-${id}-${contentTitle}-customer-ids.csv`)
        setCustomerCSV(res.payload)
        setMounted(true)
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (packageContents && packageContents.title) {
      downloadCSV(packageId, packageName, packageContents.title)
    }
  }, [packageContents, downloadCSV, mounted, setFileName, packageId, packageName])

  const handleAudienceRegeneration = () => {
    regenerateAudience(packageId, dispatch)

    setIsModalOpen(true)
  }

  return (
    <div
      data-cy={cypressId}
      data-testid="package-builder-results-side-drawer"
      ref={drawerRef}
      className={`package-builder-page_side-drawer_container ${drawerOpen ? 'active' : ''}`}
    >
      {/* component will render before contents is set so this is REALLY necessary */}
      <div className="hide-desktop">
        <MobileTopBar
          back={true}
          centerTitle={packageName}
          addHistoryRoute={`/package-builder/results/${packageId}/`}
          historyCallback={() => setDrawerOpen(false, `/package-builder/results/${packageId}/`)}
        />
      </div>
      {packageContents ? (
        <div className="package-builder-page_side-drawer_wrapper">
          <div data-testid="drawer-header-content" className="package-builder-page_side-drawer_header_wrapper">
            <h3 data-testid="drawer-header-title" className="package-builder-page_side-drawer_header_title">
              {packageContents.title}
              <span title={'Print Page'} onClick={handlePrint} className="c-pointer">
                <i style={{ marginLeft: '0.8em', fontSize: 18 }} className="icon-printer"></i>
              </span>
            </h3>
            <button
              data-cy="drawer-close-button"
              data-testid="drawer-close-button"
              onClick={() => setDrawerOpen(false, `/package-builder/results/${packageId}/`)}
              className="package-builder-page_side-drawer_header_close-btn_wrapper hide-mobile"
            >
              <img
                src={cancelled}
                alt="close-menu"
                className="package-builder-page_side-drawer_header_close-btn_icon"
              />
            </button>
          </div>

          <div className="package-builder-page_side-drawer_each-row_wrapper with-border">
            <div className="package-builder-page_side-drawer_each-row_title">
              {t('PackageBuilder.packageBuilderResults.topics')}
            </div>
            <div className="package-builder-page_side-drawer_each-row_details">
              <Topics
                keywords={packageContents.campaign.keywords}
                key={`${packageContents.title}-side-drawer-`}
                limit={packageContents.campaign.keywords.length}
              />
            </div>
          </div>
          <div className="package-builder-page_side-drawer_each-row_wrapper with-border">
            <h5 className="package-builder-page_side-drawer_each-row_title">
              {t('PackageBuilder.packageBuilderResults.customerStats.title')}
            </h5>
            <div className="package-builder-page_side-drawer_stat-card_row">
              <div className="col p-0">
                <CustomerStatCard
                  isMoney
                  title={t('PackageBuilder.packageBuilderResults.customerStats.orderValue')}
                  value={packageContents.median_basket_size}
                />
              </div>
              <div className="col p-0">
                <CustomerStatCard
                  isMoney
                  title={t('PackageBuilder.packageBuilderResults.customerStats.ticketPrice')}
                  value={packageContents.median_ticket_price}
                />
              </div>
              <div className="col p-0">
                <CustomerStatCard
                  title={t('PackageBuilder.packageBuilderResults.customerStats.yearlyVisits')}
                  value={packageContents.median_num_events_per_year}
                />
              </div>
              <div className="col p-0">
                <CustomerStatCard
                  title={t('PackageBuilder.packageBuilderResults.customerStats.daysAdvancePurchased')}
                  value={packageContents.median_days_upfront}
                />
              </div>
            </div>
          </div>

          <div className="package-builder-page_side-drawer_events-list">
            <Accordion defaultActiveKey={null}>
              <Card className="events-card">
                <Accordion.Toggle eventKey="0">
                  <Card.Header
                    as={Card.Header}
                    className={classNames({
                      'events-card_header pt-3': true,
                      open: activeKey === '0',
                    })}
                    onClick={() => setActiveKey(activeKey && activeKey === '0' ? null : '0')}
                  >
                    <InfoBox withAnchor={false}>{t('PackageBuilder.packageBuilderResults.topEvents.title')}</InfoBox>
                    <div className="align-self-center ml-auto">
                      <div className={`accordion-arrow ${activeKey === '0' ? 'open' : ''}`} />
                    </div>
                  </Card.Header>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className="d-flex flex-column p-0">
                      {topEvents.map((event) => {
                        return <EventCard event={event} t={t} key={event.event_id} />
                      })}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>

          {packageStatus === 'EXPIRED' ? (
            <div data-testid="download-button-wrapper" className="package-builder-page_side-drawer_download-btn">
              <SecondaryButton text={t('PackageBuilder.regenerateAudienceBtn')} onClick={handleAudienceRegeneration} />
            </div>
          ) : (
            <div data-testid="download-button-wrapper" className="package-builder-page_side-drawer_download-btn">
              {customerCSV && hasBackhaulAccess ? (
                <div data-testid="download-button" data-cy="pdf-download-button-parent">
                  <CSVLink
                    data={customerCSV}
                    headers={[]}
                    filename={fileName}
                    ref={csvRef}
                    target="_blank"
                    disabled={loading}
                  >
                    <DownloadButton
                      cypressId="pdf-download-button"
                      disabled={loading || isDemoAccount}
                      text={
                        loading ? (
                          <LoadingSpinner
                            style={{ padding: 0 }}
                            portalBackground={false}
                            size={SpinnerSize.SMALL}
                            showText={true}
                            title={'downloading CSV'}
                          />
                        ) : (
                          t('PackageBuilder.packageBuilderResults.downloadBtn')
                        )
                      }
                    />
                  </CSVLink>
                </div>
              ) : null}
            </div>
          )}
        </div>
      ) : null}

      <PortalModal isOpen={isModalOpen} onClickOutside={() => history.replace('/package-builder')}>
        <RegenerateAudienceModalContent />
      </PortalModal>
    </div>
  )
}

Drawer.propTypes = {
  drawerOpen: PropTypes.bool,
  setDrawerOpen: PropTypes.func,
  packageContents: PropTypes.object,
  packageId: PropTypes.string || PropTypes.number,
  packageName: PropTypes.string,
  cypressId: PropTypes.string,
  packageStatus: PropTypes.string,
}

export default Drawer

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useReducerAsync } from 'hooks/useReducerAsync'
import { asyncActionHandlers, initialState, reducer } from './rangeLocationsReducer'
import { useTargeting } from '../TargetingContext'

export const RangeLocationsContext = React.createContext()
export const RangeLocationsDispatchContext = React.createContext()

export const useRangeLocations = () => {
  const context = React.useContext(RangeLocationsContext)
  if (context === undefined) {
    throw new Error('useRangeLocations must be used within a RangeLocationsContextProvider')
  }
  return context
}

export const useRangeLocationsDispatch = () => {
  const context = React.useContext(RangeLocationsDispatchContext)
  if (context === undefined) {
    throw new Error('useRangeLocationsDispatch must be used within a RangeLocationsContextProvider')
  }
  return context
}

export default function RangeLocationsContextProvider({ tc, children }) {
  const { value: targeting } = useTargeting()
  const tcTargeting = useMemo(() => targeting.find((x) => x.tc === tc) ?? { tc }, [targeting, tc])
  const [state, dispatch] = useReducerAsync(reducer, initialState(tcTargeting), asyncActionHandlers)

  return (
    <RangeLocationsContext.Provider value={state}>
      <RangeLocationsDispatchContext.Provider value={dispatch}>{children}</RangeLocationsDispatchContext.Provider>
    </RangeLocationsContext.Provider>
  )
}

RangeLocationsContextProvider.propTypes = {
  tc: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Field from './Field'

export function DescriptionField({ form, isCampaignPublished, onChange }) {
  const { t } = useTranslation()
  const description = form.description ?? ''

  return (
    <Field
      fieldName="description"
      maxLength={250}
      value={description}
      onChange={onChange}
      footer={
        isCampaignPublished ? null : (
          <div className="campaign-form__tips--nokeywords">
            <p className="ml-3">{t('Recommendations.campaign.setup.description.description')}</p>
          </div>
        )
      }
      maxLengthClassNamesFn={(length) =>
        length > 0 && length <= 200 ? 'green' : length > 200 && length <= 240 ? 'orange' : length > 240 ? 'red' : ''
      }
    />
  )
}
DescriptionField.propTypes = {
  form: PropTypes.object.isRequired,
  isCampaignPublished: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

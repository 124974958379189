import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Accordion, Card } from 'react-bootstrap'

import CampaignHeader, { tcLabelOptions } from 'components/Recommendations/Campaign/CampaignHeader'
import { CampaignEditor } from 'components/Recommendations/Campaign/Edit'
import TipDialogueBox from 'components/Shared/TipDialogueBox'
import { SecondaryButton, TransparentBgButton } from 'components/Shared/Button'

const Ads = ({
  event,
  campaigns,
  canShowSummary,
  onCreativeSaved,
  onAdCreationComplete,
  setShowCancelSetupModal,
  onBack,
  onTargetingChanged,
}) => {
  const { t } = useTranslation()
  const [activeKey, setActiveKey] = useState('0')
  const [isLoading, setNextDisabled] = useState(false)

  return (
    <div id="ads">
      <div id="campaigns-container">
        <TipDialogueBox text={t('Recommendations.addCreativeTextTDB')} />
        <br />
        <br />
        <Accordion defaultActiveKey="" activeKey={activeKey?.toString()}>
          {campaigns.map((campaign, index) => (
            <Card className="campaign" key={index}>
              <Accordion.Toggle as={Card.Header} eventKey={index.toString()} className="pt-3">
                <CampaignHeader
                  campaign={campaign}
                  headerStatus={tcLabelOptions.pending}
                  onClick={() => setActiveKey(activeKey && index === Number(activeKey) ? null : index.toString())}
                />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={index.toString()}>
                <Card.Body className="pt-0">
                  <hr className="divider" />
                  <CampaignEditor
                    eventId={event.id}
                    eventDate={event.date}
                    campaign={campaign}
                    onAfterSave={onCreativeSaved}
                    onTargetingChanged={onTargetingChanged}
                    setLoading={setNextDisabled}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </div>

      <div
        id="setup-btn-container"
        className="recommendation-setup-action-buttons d-flex justify-content-between align-items-center"
      >
        <div className="d-flex justify-content-between w-100">
          <TransparentBgButton
            className="bt"
            onClick={() => onBack()}
            text={t('Recommendations.btnBack')}
            fullWidth={false}
            color="navy"
          />
          <div className="d-flex gap-3">
            <TransparentBgButton
              text={t('Recommendations.btnCancelSetup')}
              color="navy"
              onClick={(e) => {
                e.preventDefault()
                setShowCancelSetupModal(true)
              }}
            />
            <SecondaryButton
              disabled={!canShowSummary || isLoading}
              className="bt"
              onClick={onAdCreationComplete}
              text={t('Recommendations.btnNext')}
              fullWidth={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Ads.propTypes = {
  event: PropTypes.object.isRequired,
  campaigns: PropTypes.array.isRequired,
  onCreativeSaved: PropTypes.func.isRequired,
  onAdCreationComplete: PropTypes.func.isRequired,
  setShowCancelSetupModal: PropTypes.func.isRequired,
  canShowSummary: PropTypes.bool.isRequired,
  onBack: PropTypes.func,
  onTargetingChanged: PropTypes.func.isRequired,
}

export default Ads

import { TOGGLE_FEATURE } from '../reducers/user'
import featuresWhiteList from './featuresWhitelist.json'

export const TOGGLE_FEATURES_IN_CONSOLE = {
  envName: 'REACT_APP_TOGGLE_FEATURES_IN_CONSOLE',
  apiName: 'features.console.toggle',
}

export const SHOW_SIMULATION = {
  envName: 'REACT_APP_SHOW_SIMULATION_LINK',
  apiName: 'event.simulation',
}
export const EDIT_CREATIVE = {
  envName: 'REACT_APP_SHOW_EDIT_CREATIVE',
  apiName: 'campaign.composer.edit',
}

export const SHOW_NOTIFICATION = {
  envName: 'REACT_APP_SHOW_NOTIFICATION',
  apiName: 'notifications',
}
export const SHOW_PACKAGE_BUILDER = {
  envName: 'REACT_APP_SHOW_PACKAGE_BUILDER',
  apiName: 'package-builder',
}

export const isFeatureFlagOn = (userFeatures = {}, feature = {}) => {
  const envState = process.env[feature.envName] === 'true'
  if (envState) {
    return true
  }
  if (userFeatures && userFeatures[feature.apiName] === true) {
    return true
  }
  return false
}

export const toggleFeatureFlag = (dispatch, feature = {}) => {
  const envState = process.env[feature.envName] === 'true'
  if (envState) {
    console.info('This feature is turned on in .env file update the state there to turn it off.')
    return
  }
  dispatch({ type: TOGGLE_FEATURE, payload: feature.apiName })
  return
}

export const hasFeatureAccess = (featureTitle, email) => {
  if (!featuresWhiteList[featureTitle]) return false

  // gives access to any feature for DEVs regardless of status || checks auth
  if (
    process.env.NODE_ENV === 'development' || // you're a dev
    (email.includes('@future-demand.com') && window.location.href.includes('.stg.')) || // we are in the STG env and you are a dev
    featuresWhiteList[featureTitle].includes(email) // you're email is specifically whitelisted for this feature
  ) {
    return true
  } else {
    return false
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import DropDown from 'components/Shared/DropDown'

const locationFormQualifierOptions = [
  {
    text: 'Recommendations.geoTargeting.rangeLocations.qualifierOptions.include',
    value: 'include',
  },
  {
    text: 'Recommendations.geoTargeting.rangeLocations.qualifierOptions.exclude',
    value: 'exclude',
  },
]

export default function QualifierDropdown({ className = '', value, onChange }) {
  return (
    <DropDown
      classNames={cn('range-locations__qualifier', className)}
      options={locationFormQualifierOptions}
      value={value}
      onChange={onChange}
    />
  )
}

QualifierDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}

import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { hasPrismaAccessTier } from 'hooks/useHasPermission'
import EventEditorFormDefault from './EventEditorFormDefault'
import EventEditorFormPrisma from './EventEditorFormPrisma'

export default function EventFrom(props) {
  const [category, setCategory] = useState(props.event.category)
  const hasPrismaAccess = hasPrismaAccessTier()
  const showProductForm = useMemo(
    () => hasPrismaAccess && (!category || category === 'product'),
    [category, hasPrismaAccess],
  )

  return showProductForm ? (
    <EventEditorFormPrisma {...props} onCategoryChanged={setCategory} category={category} />
  ) : (
    <EventEditorFormDefault
      {...props}
      onCategoryChanged={setCategory}
      category={category}
    />
  )
}

EventFrom.propTypes = {
  event: PropTypes.object,
  postUpdatedEvent: PropTypes.func,
  renderFormButtons: PropTypes.func,
  onFormChanged: PropTypes.func,
}

import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import PrimaryButton, { TransparentBgButton } from 'components/Shared/Button'
import { useOutsideCheckClick } from 'hooks/UseOnClickOutside'

function DropDown({ onSubmit, onReset, alignRight, children }) {
  const { t } = useTranslation()

  return (
    <div
      className={classnames({
        'multi-select_dropdown-menu_container': true,
        right: alignRight,
        'p-3': true,
      })}
    >
      {children}
      <section className="multi-select_dropdown-menu_buttons-wrapper gap-2">
        <TransparentBgButton text={t('Filters.multiSelect.reset')} onClick={onReset} color="navy" />
        <PrimaryButton text={t('Filters.multiSelect.apply')} onClick={onSubmit} />
      </section>
    </div>
  )
}

DropDown.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  alignRight: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export function DropDownFilter({ title, onSubmit, onReset, alignRight = false, isActive = false, children }) {
  const [dropdownActive, setDropdownActive] = useState(false)
  const menuRef = useRef(null)
  const isToggleActive = dropdownActive || isActive

  useOutsideCheckClick(menuRef, setDropdownActive, dropdownActive, () =>
    window.scrollTo({ top: 0, behavior: 'smooth' }),
  )

  return (
    <span ref={menuRef} className="multi-select-each-item_wrapper">
      <div
        className={classnames({
          'multi-select-each-item_each': true,
          active: isToggleActive,
        })}
        title={title}
        onClick={() => setDropdownActive(!dropdownActive)}
      >
        <p className="multi-select-each-item_title">{title}</p>
        <span
          className={classnames({
            'multi-select_toggle-btn': true,
            active: isToggleActive,
          })}
        >
          <div
            className={classnames({
              'multi-select_toggle-btn-each-line': true,
              active: isToggleActive,
            })}
          />
          <div
            className={classnames({
              'multi-select_toggle-btn-each-line': true,
              active: isToggleActive,
            })}
          />
          <div
            className={classnames({
              'multi-select_toggle-btn-each-line': true,
              active: isToggleActive,
            })}
          />
        </span>
      </div>
      {dropdownActive ? (
        <DropDown
          onSubmit={() => {
            onSubmit()
            setDropdownActive(false)
          }}
          onReset={() => {
            onReset()
            setDropdownActive(false)
          }}
          alignRight={alignRight}
        >
          {children}
        </DropDown>
      ) : null}
    </span>
  )
}

DropDownFilter.propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  alignRight: PropTypes.bool,
  children: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
}

import { backhaulApi } from 'api/packageBuilder'

export const actions = {
  setCustomerIds: 'setCustomerIds',
  setMatched: 'setMatched',
  setMatching: 'setMatchedLoading',
  resetCustomerIds: 'resetCustomerIds',
  partiallyResetCustomerIds: 'partiallyResetCustomerIds',
  setExcludedPriceRange: 'setExcludedPriceRange',
  setExcludedPurchaseDate: 'setExcludedPurchase',
  setExcludedTicketCategories: 'setExcludedTicketCategories',

  matchAsync: 'matchAsync',
}

const _initialState = {
  customerIds: {
    qualifier: null,
    value: [],
    unmatchedIds: [],
    loading: false,
    success: null,
    serverError: false,
  },
  excludedPriceRange: {
    qualifier: null,
    value: null,
  },
  excludedPurchaseDate: {
    qualifier: null,
    value: null,
  },
  excludedTicketCategories: ['free'],
}

export const remoteSettingsToState = (remoteSettings) => {
  if (!remoteSettings) return null
  
  const state = { ..._initialState }
  if (remoteSettings.excluded_customers || remoteSettings.included_customers) {
    state.customerIds = {
      ...state.customerIds,
      qualifier: remoteSettings.excluded_customers ? 'exclude' : 'include',
      value: remoteSettings.excluded_customers || remoteSettings.included_customers,
    }
  }

  if (remoteSettings.exclude_recent_purchases_after || remoteSettings.exclude_old_purchases_before) {
    state.excludedPurchaseDate = {
      ...state.excludedPurchaseDate,
      qualifier: remoteSettings.exclude_recent_purchases_after ? 'after' : 'before',
      value: remoteSettings.exclude_recent_purchases_after || remoteSettings.exclude_old_purchases_before,
    }
  }

  if (remoteSettings.exclude_buying_behavior_below || remoteSettings.exclude_buying_behavior_above) {
    state.excludedPriceRange = {
      ...state.excludedPriceRange,
      qualifier: remoteSettings.exclude_buying_behavior_below ? 'below' : 'above',
      value: remoteSettings.exclude_buying_behavior_below || remoteSettings.exclude_buying_behavior_above,
    }
  }

  state.excludedTicketCategories = remoteSettings.exclude_ticket_categories || []

  if (!hasCustomerSegmentation(state)) return null

  return state
}

export const hasCustomerSegmentation = (state) => {
  return (
    state.customerIds.value.length > 0 ||
    state.excludedPriceRange.value ||
    state.excludedPurchaseDate.value ||
    state.excludedTicketCategories.length > 0
  )
}

export const initialState = (initialData = _initialState) => {
  return {
    customerIds: {
      ..._initialState.customerIds,
      ...initialData.customerIds,
    },
    excludedPriceRange: {
      ..._initialState.excludedPriceRange,
      ...initialData.excludedPriceRange,
    },
    excludedPurchaseDate: {
      ..._initialState.excludedPurchaseDate,
      ...initialData.excludedPurchaseDate,
    },
    excludedTicketCategories: initialData.excludedTicketCategories || [..._initialState.excludedTicketCategories],
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.setCustomerIds:
      return { ...state, customerIds: { ...state.customerIds, ...action.payload } }
    case actions.resetCustomerIds:
      return {
        ...state,
        customerIds: { ...initialState().customerIds, qualifier: state.customerIds.qualifier, ...(action.payload ?? {}) },
      }
    case actions.partiallyResetCustomerIds: {
      const value = action.payload?.value || state.customerIds.value

      return {
        ...state,
        customerIds: {
          ...state.customerIds,
          value,
          unmatchedIds: [],
          qualifier: state.customerIds.qualifier,
          success: null,
          serverError: false,
        },
      }
    }
    case actions.setMatched:
      return { ...state, customerIds: { ...state.customerIds, loading: false, ...action.payload } }
    case actions.setMatching:
      return { ...state, customerIds: { ...state.customerIds, success: null, loading: true } }
    case actions.setExcludedPriceRange:
      return { ...state, excludedPriceRange: { ...state.excludedPriceRange, ...action.payload } }
    case actions.setExcludedPurchaseDate:
      return { ...state, excludedPurchaseDate: { ...state.excludedPurchaseDate, ...action.payload } }
    case actions.setExcludedTicketCategories:
      return { ...state, excludedTicketCategories: action.payload }
    default:
      return state
  }
}

export const asyncActionHandlers = {
  [actions.matchAsync]:
  ({ dispatch }) =>
    async (action) => {
      const { ids } = action.payload
      dispatch({ type: actions.setMatching })

      const { data, error } = await backhaulApi.matchCustomers(ids)
      if (error) console.error('Error matching customers', error)

      dispatch({
        type: actions.setMatched,
        payload: { success: data?.length === 0 && !error, unmatchedIds: data ?? [], serverError: !!error },
      })
    },
}

import { eventApi } from 'api/events'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'
import store from '../../../store'

export const ATTRIBUTION_MODELS = Object.freeze({
  fd: 'FD',
  meta: 'META',
  external: 'EXTERNAL',
})

export const actions = Object.freeze({
  setEventAttributionModel: 'SET_EVENT_ATTRIBUTION_MODEL',
  setLoading: 'SET_LOADING',
  setError: 'SET_ERROR',
  fetchEventAttributionModelAsync: 'GET_EVENT_ATTRIBUTION_MODEL_ASYNC',
  updateEventAttributionModelAsync: 'UPDATE_EVENT_ATTRIBUTION_MODEL_ASYNC',
})

export const getDefaultAttribtionModel = () => {
  const user = store.getState().user
  const hasPrismaAccess = hasPrismaAccessTier(user.accessLevel)

  const cachedAm = localStorage.getItem('attributionModel')
  if (!cachedAm) {
    return hasPrismaAccess ? ATTRIBUTION_MODELS.meta : ATTRIBUTION_MODELS.fd
  }

  if (hasPrismaAccess) {
    return cachedAm === ATTRIBUTION_MODELS.fd ? ATTRIBUTION_MODELS.meta : cachedAm
  }

  return cachedAm
}

export const initialState = {
  loading: false,
  error: null,
  attributionModel: getDefaultAttribtionModel(),
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.setLoading:
      return { ...state, loading: action.payload }
    case actions.setError:
      return { ...state, error: action.payload, loading: false }
    case actions.setEventAttributionModel:
      return { ...state, attributionModel: action.payload, loading: false }
    default:
      return state
  }
}

export const asyncActionHandlers = {
  [actions.fetchEventAttributionModelAsync]:
    ({ dispatch }) =>
      async (action) => {
        dispatch({ type: actions.setLoading, payload: true })
        const { eventId } = action.payload
        const response = await eventApi.fetchEventAttributionModel(eventId)
        if (response.error) {
          dispatch({ type: actions.setError, payload: response.error })
        } else {
          dispatch({ type: actions.setEventAttributionModel, payload: response.data })
        }
      },
  [actions.updateEventAttributionModelAsync]:
    ({ dispatch }) =>
      async (action) => {
        dispatch({ type: actions.setLoading, payload: true })
        const { eventId, attributionModel } = action.payload
        const response = await eventApi.updateEventAttributionModel(eventId, attributionModel)
        if (response.error) {
          dispatch({ type: actions.setError, payload: response.error })
        } else {
          dispatch({ type: actions.setEventAttributionModel, payload: attributionModel })
        }
      },
}

import React from 'react'
import PropTypes from 'prop-types'
import Fade from './Wrappers/Fade'
import Portal from './Portal'
import { CancelIcon } from 'images'

export default function PortalModal({
  wrapperId='app-portal',
  children,
  isOpen,
  wrapperClassName,
  className,
  title = null,
  onClickOutside = () => {}
}) {

  if (!isOpen) return null


  return (
    <Portal wrapperId={wrapperId}>
      <div className={`portal-modal_outer_wrapper ${wrapperClassName}`} onClick={onClickOutside}>
        <div className="portal-modal_outer_wrapper_trap" onClick={(e) => e.stopPropagation()}>
          <Fade transitionDuration={500} delay={0}>
            <div className={`portal-modal_inner_wrapper w-100 ${className}`}>
              <div className="portal-modal_inner_content_wrapper" id="portal-modal-content">
                {title && (
                  <div className="d-flex justify-content-between gap-4 m-4">
                    <h3 className="event-editor_search-view_title m-0">{title}</h3>
                    <button type="button" onClick={onClickOutside} className="event-editor_modal-content_btn_wrapper">
                      <CancelIcon width={16} />
                    </button>
                  </div>
                )}
                {children}
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </Portal>
  )
}

PortalModal.propTypes = {
  wrapperId: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClickOutside: PropTypes.func,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.node,
  scrollContainerSelector: PropTypes.string,
}

import React from 'react'
import PropTypes, { object } from 'prop-types'
import { useTranslation } from 'react-i18next'
import Input from 'components/Shared/Input'
import minusIcon from 'images/icons/minus-circle.svg'

const SetList = ({ removeSetlist, setFields, setlists, setlist, setlistIndex }) => {
  const { t } = useTranslation()

  const updateArtistName = (e) => {
    const setlistsUpdated = [...setlists]
    setlistsUpdated[setlistIndex].artist_name = e.target.value
    setFields(setlistsUpdated)
  }

  const updateTitle = (e) => {
    const setlistsUpdated = [...setlists]
    setlistsUpdated[setlistIndex].track = e.target.value
    setFields(setlistsUpdated)
  }

  return (
    <div className="lineup-wrapper">
      <div className="row lineup">
        <div className="col-md-6">
          <Input
            separatePlaceholder={true}
            value={setlist.artist_name}
            name={setlist.artist_name}
            onChange={(artist) => updateArtistName(artist)}
            placeholder={t('Simulation.form.composer') + ' ' + (setlistIndex + 1)}
            required
            wrapperClass="m-0"
          />
        </div>
        <div className="col-md-6">
          <Input
            separatePlaceholder={true}
            placeholder={t('Simulation.form.title') + ' ' + (setlistIndex + 1)}
            value={setlist.track}
            name={setlist.track}
            onChange={(e) => updateTitle(e)}
            required
            wrapperClass="m-0"
          />
        </div>
      </div>
      <div className="minus-icon">
        <img src={minusIcon} onClick={() => removeSetlist(setlistIndex)} alt="removeIcon" />
      </div>
    </div>
  )
}

SetList.propTypes = {
  removeSetlist: PropTypes.func,
  setFields: PropTypes.func,
  fields: PropTypes.object,
  setlist: PropTypes.object,
  setlists: PropTypes.arrayOf(object),
  setlistIndex: PropTypes.number,
}

export default SetList

import { api } from './_init'
import { OVERWRITE, FAIL, LOADING } from '../reducers/default'
import { UPDATE_NOTIFICATION } from '../reducers/user'

const formatApiMessage = (message) => {
  return {
    id: message.id,
    partner_id: message.partner_id,
    title: message.title,
    content: message.content,
    read: message.read,
    url: message.url,
    date_created: message.date_created,
    date_last_updated: message.date_last_updated,
  }
}

export const fetchMessages = async (dispatch, settings = {}) => {
  dispatch({ type: LOADING })
  const { page = 1, limit = 10 } = settings
  try {
    api
      .get(`/messages/partner/?limit=${limit}&page=${page}`)
      .then((result) => {
        if (result.status === 200) {
          // console.log('Message data fetched')
          const response = {
            total: result.data.total,
            limit: result.data.limit,
            page: result.data.page,
            pages: result.data.pages,
            nextPage: result.data.nextPage,
            prevPage: result.data.prevPage,
            items: result.data.items.map((message) => formatApiMessage(message)),
          }
          dispatch({ type: OVERWRITE, payload: response })
        } else {
          console.error(result.status ? result.status : result)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  } catch (error) {
    console.error(error)
    dispatch({ type: FAIL, payload: 'Error fetching data.' })
  }
}

export const markMessagesAsRead = async (mesasgeID) => {
  try {
    api
      .put(`/messages/${mesasgeID}/markasread`)
      .then((result) => {
        if (result.status !== 200) {
          console.error(result.status ? result.status : result)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  } catch (error) {
    console.error(error)
  }
}

export const checkNewMessages = async (globalDispatch) => {
  try {
    api
      .get('/messages/totalunread')
      .then((result) => {
        if (result.status === 200) {
          // console.log('Check unread messages: '+ result.data.total_unread)
          const totalUnread = result.data.total_unread ? result.data.total_unread : 0
          notificationUpdate(globalDispatch, totalUnread)
        } else {
          console.error(result.status ? result.status : result)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  } catch (error) {
    console.error(error)
  }
}

export const notificationUpdate = (globalDispatch, notification) => {
  globalDispatch({
    type: UPDATE_NOTIFICATION,
    payload: notification,
  })
}

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'

/**
 * A custom accordion component. Supports multiple open panels.
 * 
 * 
 * Usage:
 * 
 * ```jsx
 * <FDAccordion
 *  defaultActiveKeys={[0]}
 *  onChange={(activePanels) => console.log(activePanels)}
 * >
 *  <FDAccordion.Item>
 *   <FDAccordion.Toggle>Toggle 1</FDAccordion.Toggle>
 *    <FDAccordion.Body>Body 1</FDAccordion.Body>
 *  </FDAccordion.Item>
 *  <FDAccordion.Item>
 *    <FDAccordion.Toggle>Toggle 2</FDAccordion.Toggle>
 *    <FDAccordion.Body>Body 2</FDAccordion.Body>
 *  </FDAccordion.Item>
 * </FDAccordion>
 * ```
 * @component
 **/
const FDAccordion = ({ children, defaultActiveKeys = [], onChange }) => {
  const [activePanels, setActivePanels] = useState(defaultActiveKeys)

  useEffect(() => {
    setActivePanels(defaultActiveKeys)
  }, [defaultActiveKeys])

  const togglePanel = (index) => {
    if (activePanels.includes(index)) {
      const newActivePanels = activePanels.filter((i) => i !== index)
      setActivePanels(newActivePanels)
      if (onChange) {
        onChange(newActivePanels)
      }
    } else {
      const newActivePanels = [...activePanels, index]
      setActivePanels(newActivePanels)
      if (onChange) {
        onChange(newActivePanels)
      }
    }
  }

  return React.Children.map(children, (child, index) => {
    if (child.type === AccordionItem) {
      return React.cloneElement(child, {
        isActive: activePanels.includes(index),
        toggle: () => togglePanel(index),
      })
    }
    return child
  })
}

FDAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  defaultActiveKeys: PropTypes.arrayOf(PropTypes.number),
}

const AccordionItem = ({ isActive, toggle, children }) => {
  return (
    <div className={`fd-accordion-item ${isActive ? 'active' : ''}`}>
      {React.Children.map(children, (child) => {
        if (child.type === AccordionToggle || child.type === AccordionBody) {
          return React.cloneElement(child, { isActive, toggle })
        }
        return React.cloneElement(child, { isActive })
      })}
    </div>
  )
}

AccordionItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

const AccordionToggle = ({ toggle, isActive, children }) => {
  return (
    <button onClick={toggle} className={`fd-accordion-header ${isActive ? 'active' : ''}`}>
      {children}
    </button>
  )
}

AccordionToggle.propTypes = {
  toggle: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

const AccordionBody = ({ isActive, children }) => (
  <CSSTransition in={isActive} timeout={300} classNames="fd-accordion-body" unmountOnExit>
    <div className="fd-accordion-body">
      {children}
    </div>
  </CSSTransition>
)

AccordionBody.propTypes = {
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

FDAccordion.Item = AccordionItem
FDAccordion.Toggle = AccordionToggle
FDAccordion.Body = AccordionBody

export default FDAccordion

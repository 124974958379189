import React, { useState, useRef, useReducer, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import PropTypes from 'prop-types'

import { useOutsideCheckClick } from 'hooks/UseOnClickOutside'
import { initStateArray, arrayReducer } from 'reducers/default'
import { fetchEvents } from 'api/events'
import Searchbar from 'components/Shared/Searchbar'
import searchLogo from 'images/icons/search_blue.svg'

import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()
const pageInit = { number: 1, loading: false }

const DropDownMenuRow = ({ event, handleClick, cypressId }) => {
  return (
    <div
      title={event.title}
      data-cy={cypressId}
      className="package-builder-page_search-section search-drop-down-menu-row"
      onClick={() => handleClick(event)}
    >
      <p className="title ellipsis">{event.title}</p>
      <p className="date">{moment.utc(event.date).format('DD.MM.YYYY HH:mm')}</p>
    </div>
  )
}

DropDownMenuRow.propTypes = {
  event: PropTypes.object,
  handleClick: PropTypes.func,
  cypressId: PropTypes.string,
}

export const DropDownMenu = ({ events, page, loadNextPage, handleClick, dropdownActive, setDropdownActive }) => {
  const menuRef = useRef(null)
  useOutsideCheckClick(menuRef, setDropdownActive, dropdownActive)

  useEffect(() => {
    const handleScroll = () => {
      const menuDiv = menuRef.current
      if (
        menuDiv.scrollHeight - menuDiv.scrollTop === menuDiv.clientHeight &&
        events.maxPage > page.number &&
        !page.loading
      ) {
        loadNextPage()
      }
    }

    const div = menuRef.current

    if (div) {
      div.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll)
      }
    }
  }, [events.page, events.maxPage, page.loading, page.number, loadNextPage])

  return (
    <div
      data-cy="search-events-drop-down-menu"
      data-testid={'search_input_drop-down_menu'}
      ref={menuRef}
      className={`package-builder-page_search-section search-drop-down-menu-wrapper ${dropdownActive ? 'open' : ''}`}
    >
      {events.items && events.items.length > 0
        ? events.items.map((event, idx) => {
          return (
            <DropDownMenuRow
              data-testid="search_input_drop-down_menu_row"
              cypressId={`event-search-row-${idx + 1}`}
              handleClick={handleClick}
              event={event}
              key={`drop-down-event${event.eid}`}
            />
          )
        })
        : null}

      {dropdownActive && !events.loading && events.total === 0 && (
        <p
          data-cy="empty-list-warning"
          className="package-builder-page_search-section search-drop-down-menu-error-message"
        >
          {' '}
          no results matches your search
        </p>
      )}
    </div>
  )
}

DropDownMenu.propTypes = {
  events: PropTypes.object,
  page: PropTypes.object,
  loadNextPage: PropTypes.func,
  handleClick: PropTypes.func,
  dropdownActive: PropTypes.bool,
  setDropdownActive: PropTypes.func,
}

const DropdownSearch = ({ selectedEvents, setSelectedEvents, clearSearchQuery, setClearSearchQuery }) => {
  const { t } = useTranslation()
  const [dropdownActive, setDropdownActive] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const [search, setSearch] = useState('')

  const [searchEventsResults, dispatch] = useReducer(arrayReducer, initStateArray)
  const [page, setPage] = useState({ ...pageInit })

  const loadNextPage = useCallback(() => {
    if (page.loading) return

    const pageNumber = page.number + 1
    setPage({ number: pageNumber, loading: true })
    fetchEvents(
      (action) => {
        dispatch(action)
        setPage((page) => ({ ...page, loading: false }))
      },
      { search: search, page: pageNumber },
    )
  }, [page, search])

  useEffect(() => {
    setIsMounted(true)
    if (isMounted) {
      fetchEvents(dispatch, { search: search, since: moment().format('DD-MM-YYYY') })
    }

    return () => {
      setIsMounted(false)
    }
  }, [search, isMounted])

  useEffect(() => {
    setPage({ ...pageInit })
  }, [search])

  const handleClick = (newEvent) => {
    const alreadyExists = selectedEvents.find((item) => item.eid === newEvent.eid)
    if (!alreadyExists) {
      const newEventsList = [...selectedEvents, newEvent]
      setSelectedEvents(newEventsList)

      window.scrollTo({ top: document.getElementById('root').offsetHeight, behavior: 'smooth' })

      // setting the list items of a user
      const currentLocalState = JSON.parse(localStorage.getItem('packageBuilderState'))
      currentLocalState.listItems = newEventsList
      localStorage.setItem('packageBuilderState', JSON.stringify(currentLocalState))
    }
    setDropdownActive(false)
  }

  return (
    <>
      {isMounted ? (
        <div data-testid="package-builder-page_search-bar" className="package-builder-page_search-section wrapper">
          <Searchbar
            defaultValue={search}
            handleChange={(e) => {
              setDropdownActive(true)
              setSearch(e)
            }}
            placeholder={t('Events.search.placeholder')}
            ariaLabel={t('Events.search.ariaLabel')}
            postfixView={<img src={searchLogo} className="searchbar-comp_icon" alt="Search Events" />}
            clearSearchQuery={clearSearchQuery}
            setClearSearchQuery={setClearSearchQuery}
          />
          {/* if the searchbar is being used && has at least one letter in it, the dropdown becomes active */}
          <DropDownMenu
            dropdownActive={search.length > 0 && dropdownActive}
            handleClick={handleClick}
            events={searchEventsResults}
            selectedEvents={selectedEvents}
            page={page}
            loadNextPage={loadNextPage}
            setDropdownActive={setDropdownActive}
          />
        </div>
      ) : null}
    </>
  )
}

DropdownSearch.propTypes = {
  selectedEvents: PropTypes.array,
  setSelectedEvents: PropTypes.func,
  clearSearchQuery: PropTypes.bool,
  setClearSearchQuery: PropTypes.func,
}

export default DropdownSearch

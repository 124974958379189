import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Buttons
 * @Tags( shared, presentational, buttons, CTA )
 */

// TODO: refactor this
import { DemandPredictionIcon, LookoutIcon, SalesBoostIcon, WaveIcon } from 'images'

const eventRowButtonMap = {
  SALES_BOOST: {
    icon: <SalesBoostIcon width={26} />,
  },
  DEMAND_PREDICTION: {
    icon: <DemandPredictionIcon width={26} />,
  },
  LOOKOUT: {
    icon: <LookoutIcon width={26} />,
  },
  WAVES: {
    icon: <WaveIcon width={26} />,
  },
}

const PrimaryButton = ({
  onClick = () => { },
  text,
  showIcon,
  linkTo = null,
  disabled = false,
  hidden = false,
  cypressId = 'button',
  fullWidth = true,
}) => {
  if (linkTo && !disabled) {
    if (linkTo.startsWith('http') || linkTo.startsWith('www')) {
      return (
        <a href={linkTo} target="_blank" rel="noopener noreferrer">
          <button
            title={disabled ? 'You are not authorized to perform this action. Please contact your admin' : text}
            data-cy={cypressId}
            style={hidden ? { display: 'none' } : {}}
            disabled={disabled}
            className={`bt bt-orange s-38 ${fullWidth ? 'w-100' : ''}`}
          >
            <span>{text}</span>
            {showIcon ? <i className="icon-arrow-right ml-3"></i> : <></>}
          </button>
        </a>
      )
    }

    return (
      <Link to={linkTo}>
        <button
          title={disabled ? 'You are not authorized to perform this action. Please contact your admin' : text}
          data-cy={cypressId}
          style={hidden ? { display: 'none' } : {}}
          disabled={disabled}
          className={`bt bt-orange s-38 ${fullWidth ? 'w-100' : ''}`}
        >
          <span>{text}</span>
          {showIcon ? <i className="icon-arrow-right ml-3"></i> : <></>}
        </button>
      </Link>
    )
  }

  return (
    <button
      title={disabled ? 'You are not authorized to perform this action. Please contact your admin' : text}
      data-cy={cypressId}
      style={hidden ? { display: 'none' } : {}}
      disabled={disabled}
      onClick={onClick}
      className={`bt bt-orange s-38 ${fullWidth ? 'w-100' : ''}`}
    >
      <span>{text}</span>
      {showIcon ? <i className="icon-arrow-right ml-3"></i> : <></>}
    </button>
  )
}
export const ButtonWithExternalHref = ({
  fullWidth = true,
  text,
  showIcon,
  isBackArrow = false,
  externalUrl,
  disabled = false,
  color = 'orange',
  cypressId = 'button',
  onClick,
}) => {
  return (
    <a href={externalUrl} target="_blank" rel="noopener noreferrer">
      <button
        onClick={onClick}
        title={disabled ? 'You are not authorized to perform this action. Please contact your admin' : text}
        data-cy={cypressId}
        disabled={disabled}
        className={`bt ${color === 'orange' ? 'bt-orange' : 'bt-navy'} s-38 ${fullWidth ? 'w-100' : ''}`}
      >
        {showIcon && isBackArrow ? <i className="icon-arrow-left mr-3"></i> : <></>}
        <span style={{ whiteSpace: 'nowrap' }}>{text}</span>
        {showIcon && !isBackArrow ? <i className="icon-arrow-right ml-3"></i> : <></>}
      </button>
    </a>
  )
}
export const SecondaryButton = ({
  onClick = () => { },
  additionalComponent = null,
  fullWidth = true,
  text,
  disabledTitle,
  showIcon,
  children,
  isBackArrow = false,
  linkTo = null,
  disabled = false,
  color = 'orange',
  cypressId = 'button',
  size = 'normal',
  classNames = '',
  onHover = () => { },
}) => {
  const sizes = {
    small: 's-24',
    normal: 's-38',
    big: 's-50',
  }

  if (disabledTitle === null || disabledTitle === undefined)
    disabledTitle = 'You are not authorized to perform this action. Please contact your admin'

  if (linkTo && !disabled) {
    if (linkTo.startsWith('http') || linkTo.startsWith('www')) {
      return (
        <a href={linkTo} target="_blank" rel="noopener noreferrer" className={classNames}>
          <button
            onMouseEnter={() => onHover(true)}
            onMouseLeave={() => onHover(false)}
            title={disabled ? disabledTitle : text}
            data-cy={cypressId}
            onClick={onClick}
            disabled={disabled}
            className={`bt ${color === 'orange' ? 'bt-orange' : 'bt-navy'} ${sizes[size]} ${fullWidth ? 'w-100' : ''
            }`}
          >
            {showIcon && isBackArrow ? <i className="icon-arrow-left mr-3"></i> : <></>}
            {children ? <>{children}</> : <span style={{ whiteSpace: 'nowrap' }}>{text}</span>}
            {additionalComponent && <div className="ml-3">{additionalComponent}</div>}
            {showIcon && !isBackArrow ? <i className="icon-arrow-right ml-3"></i> : <></>}
          </button>
        </a>
      )
    }
    return (
      <Link to={linkTo} className={classNames}>
        <button
          onMouseEnter={() => onHover(true)}
          onMouseLeave={() => onHover(false)}
          title={disabled ? disabledTitle : text}
          data-cy={cypressId}
          onClick={onClick}
          disabled={disabled}
          className={`bt ${color === 'orange' ? 'bt-orange' : 'bt-navy'} ${sizes[size]} ${fullWidth ? 'w-100' : ''}`}
        >
          {showIcon && isBackArrow ? <i className="icon-arrow-left mr-3"></i> : <></>}
          {children ? <>{children}</> : <span style={{ whiteSpace: 'nowrap' }}>{text}</span>}
          {additionalComponent && <div className="ml-3">{additionalComponent}</div>}
          {showIcon && !isBackArrow ? <i className="icon-arrow-right ml-3"></i> : <></>}
        </button>
      </Link>
    )
  }
  return (
    <button
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
      title={disabled ? disabledTitle : text}
      data-cy={cypressId}
      onClick={onClick}
      disabled={disabled}
      className={`${classNames} bt ${color === 'orange' ? 'bt-orange' : 'bt-navy'} ${sizes[size]} ${fullWidth ? 'w-100' : ''}`}
    >
      {showIcon && isBackArrow ? <i className="icon-arrow-left mr-3"></i> : <></>}
      {children ? <>{children}</> : <span style={{ whiteSpace: 'nowrap' }}>{text}</span>}
      {additionalComponent && <div className="ml-3">{additionalComponent}</div>}
      {showIcon && !isBackArrow ? <i className="icon-arrow-right ml-3"></i> : <></>}
    </button>
  )
}

export const FullWidthButton = ({
  extraStyles,
  onClick = () => { },
  additionalComponent = null,
  text,
  showIcon,
  isBackArrow = false,
  linkTo = null,
  disabled = false,
  color = 'orange',
  cypressId = 'full-w-button',
}) => {
  if (linkTo && !disabled) {
    return (
      <Link to={linkTo} style={{ width: '100%' }}>
        <button
          style={{ width: '100%', ...extraStyles }}
          title={disabled ? 'You are not authorized to perform this action. Please contact your admin' : text}
          data-cy={cypressId}
          onClick={onClick}
          disabled={disabled}
          className={`bt ${color === 'orange' ? 'bt-orange' : 'bt-navy'} s-38`}
        >
          {showIcon && isBackArrow ? <i className="icon-arrow-left mr-3"></i> : <></>}
          <span>{text}</span>
          {additionalComponent && <div className="ml-3">{additionalComponent}</div>}
          {showIcon && !isBackArrow ? <i className="icon-arrow-right ml-3"></i> : <></>}
        </button>
      </Link>
    )
  }
  return (
    <button
      style={{ width: '100%', ...extraStyles }}
      title={disabled ? 'You are not authorized to perform this action. Please contact your admin' : text}
      data-cy={cypressId}
      onClick={onClick}
      disabled={disabled}
      className={`bt ${color === 'orange' ? 'bt-orange' : 'bt-navy'} s-38`}
    >
      {showIcon && isBackArrow ? <i className="icon-arrow-left mr-3"></i> : <></>}
      <span>{text}</span>
      {additionalComponent && <div className="ml-3">{additionalComponent}</div>}
      {showIcon && !isBackArrow ? <i className="icon-arrow-right ml-3"></i> : <></>}
    </button>
  )
}

export const DownloadButton = ({
  onClick = () => { },
  text,
  showIcon,
  disabled = false,
  color = 'orange',
  cypressId = 'button',
}) => {
  return (
    <button
      title={disabled ? 'You are not authorized to perform this action. Please contact your admin' : text}
      data-cy={cypressId}
      onClick={onClick}
      disabled={disabled}
      className={`bt ${color === 'orange' ? 'bt-orange' : 'bt-navy'} s-38 w-100`}
    >
      <span>{text}</span>
      {showIcon ? <i style={{ marginLeft: '1em' }} className="icon-arrow-down2"></i> : <></>}
    </button>
  )
}

export const IconButton = ({
  iconType,
  linkTo,
  onClick = () => { },
  showIcon,
  disabled = false,
  color = 'orange',
  extraStyles = {},
  cypressId = 'button',
}) => {
  if (linkTo && !disabled) {
    return (
      <Link to={linkTo}>
        <button
          title={disabled ? 'You are not authorized to perform this action. Please contact your admin' : ''}
          style={{ padding: '8px', ...extraStyles }}
          onClick={onClick}
          disabled={disabled}
          className={`bt ${color === 'orange' ? 'bt-orange' : 'bt-navy'} s-24 `}
        >
          <i style={{ fontSize: 18 }} className={`icon-${iconType}`}></i>
          {showIcon ? <i style={{ marginLeft: 18, fontSize: 10 }} className="icon-arrow-right"></i> : <></>}
        </button>
      </Link>
    )
  }
  return (
    <button
      title={disabled ? 'You are not authorized to perform this action. Please contact your admin' : ''}
      data-cy={cypressId}
      style={{ padding: '8px', ...extraStyles }}
      onClick={onClick}
      disabled={disabled}
      className={`bt ${color === 'orange' ? 'bt-orange' : 'bt-navy'} s-24 `}
    >
      <i style={{ fontSize: 18 }} className={`icon-${iconType}`}></i>
      {showIcon ? <i style={{ marginLeft: 18, fontSize: 10 }} className="icon-arrow-right"></i> : <></>}
    </button>
  )
}
export const EventRowButton = ({
  type = 'DEMAND_PREDICTION',
  linkTo,
  onClick = () => { },
  showIcon,
  disabled = false,
  extraStyles = {},
  cypressId = 'button',
  classNames = '',
}) => {
  if (linkTo && !disabled) {
    return (
      <Link to={linkTo}>
        <button
          title={disabled ? 'You are not authorized to perform this action. Please contact your admin' : ''}
          data-cy={cypressId}
          style={{ boxSizing: 'border-box', ...extraStyles }}
          onClick={onClick}
          disabled={disabled}
          className={`bttn ${classNames}`}
        >
          <span className="content">
            {eventRowButtonMap[type].icon}
            {showIcon ? <i style={{ marginLeft: 13, fontSize: 13 }} className="icon-arrow-right"></i> : <></>}
          </span>
          <span className="background" />
        </button>
      </Link>
    )
  }
  return (
    <button
      title={disabled ? 'You are not authorized to perform this action. Please contact your admin' : ''}
      data-cy={cypressId}
      style={{ boxSizing: 'border-box', ...extraStyles }}
      onClick={onClick}
      disabled={disabled}
      className={`bttn ${classNames}`}
    >
      <span className="content">
        {eventRowButtonMap[type].icon}
        {showIcon ? <i style={{ marginLeft: 13, fontSize: 13 }} className="icon-arrow-right"></i> : <></>}
      </span>
      <span className="background" />
    </button>
  )
}

export const DashedBorderedButton = ({
  iconType,
  text,
  linkTo,
  onClick = () => { },
  showIcon,
  disabled = false,
  color = 'orange',
  extraStyles = {},
  cypressId = 'button',
}) => {
  const defaultStyles = { padding: '8px' }

  if (linkTo && !disabled) {
    return (
      <Link to={linkTo}>
        <button
          title={disabled ? 'You are not authorized to perform this action. Please contact your admin' : text}
          style={{ ...defaultStyles, ...extraStyles }}
          onClick={onClick}
          disabled={disabled}
          className={`bt dashed ${color === 'orange' ? 'orange' : 'navy'} `}
        >
          <i style={{ fontSize: 18 }} className={`icon-${iconType}`}></i>
          <span>{text}</span>
          {showIcon ? <i style={{ marginLeft: 18, fontSize: 10 }} className="icon-arrow-right"></i> : <></>}
        </button>
      </Link>
    )
  }
  return (
    <button
      title={disabled ? 'You are not authorized to perform this action. Please contact your admin' : text}
      data-cy={cypressId}
      style={{ ...defaultStyles, ...extraStyles }}
      onClick={onClick}
      className={`bt dashed ${color === 'orange' ? 'orange' : 'navy'} s-24`}
    >
      <i style={{ fontSize: 18 }} className={`icon-${iconType}`}></i>
      <span>{text}</span>
      {showIcon ? <i style={{ marginLeft: 18, fontSize: 10 }} className="icon-arrow-right"></i> : <></>}
    </button>
  )
}
export const TransparentBgButton = ({
  classNames,
  text,
  linkTo,
  isBackArrow = false,
  onClick = () => { },
  showIcon,
  disabled = false,
  color = 'orange',
  extraStyles = {},
  cypressId = 'button',
  size = 'normal',
  light = false,
}) => {
  const sizes = {
    small: 's-24',
    normal: 's-38',
    big: 's-50',
  }

  if (linkTo && !disabled) {
    return (
      <Link to={linkTo}>
        <button
          title={disabled ? 'You are not authorized to perform this action. Please contact your admin' : text}
          style={{ ...extraStyles }}
          onClick={onClick}
          disabled={disabled}
          className={`bt hollow ${color === 'orange' ? 'orange' : 'navy'} ${sizes[size]} ${light ? 'hollow--light' : ''}`}
        >
          {showIcon && isBackArrow ? (
            <i style={{ marginRight: 18, fontSize: 10 }} className="icon-arrow-left"></i>
          ) : (
            <></>
          )}
          <span>{text}</span>
          {showIcon && !isBackArrow ? (
            <i style={{ marginLeft: 18, fontSize: 10 }} className="icon-arrow-right"></i>
          ) : (
            <></>
          )}
        </button>
      </Link>
    )
  }
  return (
    <button
      title={disabled ? 'You are not authorized to perform this action. Please contact your admin' : text}
      data-cy={cypressId}
      style={{ ...extraStyles }}
      onClick={onClick}
      className={`bt hollow ${color === 'orange' ? 'orange' : 'navy'} ${sizes[size]} ${classNames} ${light ? 'hollow--light' : ''}`}
    >
      {showIcon && isBackArrow ? <i style={{ marginRight: 18, fontSize: 10 }} className="icon-arrow-left"></i> : <></>}
      <span>{text}</span>
      {showIcon && !isBackArrow ? <i style={{ marginLeft: 18, fontSize: 10 }} className="icon-arrow-right"></i> : <></>}
    </button>
  )
}

export default PrimaryButton

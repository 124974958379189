import React, { createContext, useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { initStateObject, objectReducer } from 'reducers/default'
import { fetchCampaignResults, fetchEventFbInsights } from 'api/integrations'

export const EventFbInsightsContext = createContext(null)
export const EventFbInsightsDispatchContext = createContext(null)
export const EventCampaignResultsContext = createContext(null)
export const EventCampaignResultsDispatchContext = createContext(null)
export const CampaignStatsContext = createContext(null)

export function EventStatsProvider({ eventId, children }) {
  const [fbInsights, dispatchFbInsights] = useReducer(objectReducer, initStateObject)
  const [campaignResults, dispatchCampaignResults] = useReducer(objectReducer, initStateObject)
  const [hasCampaignStats, setHasCampaignStats] = useState(null)

  useEffect(() => {
    if (eventId) {
      fetchCampaignResults(dispatchCampaignResults, eventId)
      fetchEventFbInsights(dispatchFbInsights, eventId)
    }
  }, [eventId])

  useEffect(() => {
    const fbInsightsValues = Object.values(fbInsights.content)
    const eventResultsValues = Object.values(campaignResults.content?.event_results || {})
    const seriesResultsValues = Object.values(campaignResults.content?.series_results || {})
    let isFilled = campaignResults.error && fbInsights.error ? false : null

    if (fbInsightsValues.length || (eventResultsValues.length && seriesResultsValues.length)) {
      const isEventStats = eventResultsValues.some((el) => el)
      const isEventSeriesStats = seriesResultsValues.some((el) => el)
      const impressions = fbInsights.content?.impressions
      const clicks = fbInsights.content?.clicks
      const adSpend = fbInsights.content?.ad_spend
      isFilled = isEventStats || isEventSeriesStats || !!impressions || !!clicks || !!adSpend
    }
    setHasCampaignStats(isFilled)
  }, [fbInsights, campaignResults])

  return (
    <EventFbInsightsContext.Provider value={fbInsights.content}>
      <EventFbInsightsDispatchContext.Provider value={dispatchFbInsights}>
        <EventCampaignResultsContext.Provider value={campaignResults}>
          <EventCampaignResultsDispatchContext.Provider value={dispatchCampaignResults}>
            <CampaignStatsContext.Provider value={hasCampaignStats}>{children}</CampaignStatsContext.Provider>
          </EventCampaignResultsDispatchContext.Provider>
        </EventCampaignResultsContext.Provider>
      </EventFbInsightsDispatchContext.Provider>
    </EventFbInsightsContext.Provider>
  )
}

EventStatsProvider.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
}

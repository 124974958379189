import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * Box used in EventBenchmarks component. Compares two numbers, change color if main number is bigger that second number.
 * @Tags( event, presentational )
 */
const PredictionBox = ({ main, second, type }) => {
  const { t } = useTranslation()
  const itemClass = main.number >= second.number ? 'green' : 'red'
  return (
    <div className="prediction-box">
      <div className="row flex-nowrap">
        <div className="col">
          <div className={`prediction-box__data prediction-box__main prediction-box__main--${itemClass}`}>
            <span className="prediction-box__data__content">
              <strong className="prediction-box__total">{main.number.toFixed(1)}%</strong>
              <span className="prediction-box__label">{main.title}</span>
            </span>
            <div className="prediction-box__arrow"></div>
          </div>
        </div>
        <div className="col" style={{ paddingLeft: 6 }}>
          <div className="prediction-box__data prediction-box__second">
            <span className="prediction-box__data__content">
              <strong className="prediction-box__total">{second.number.toFixed(1)}%</strong>
              <span className="prediction-box__label text">{t(`Event.benchmarks.${type}.right`)}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export const PredictionDifferenceBox = ({ difference }) => {
  const { t } = useTranslation()
  const itemClass = difference.number > 0 ? 'green' : 'red'
  const positiveValue = difference.number > 0

  return (
    <div className="prediction-box-small">
      <div className={'prediction-box-small_wrapper'}>
        <div className={'prediction-box-small_inner-content'}>
          <div className="d-flex" style={{ alignItems: 'center' }}>
            <div
              style={{
                transitionDuration: '0.2s',
                marginBottom: 8,
                transform: positiveValue ? 'rotate(180deg)' : 'rotate(0)',
              }}
              className={`events-insights-page__status_menu_arrow ${positiveValue ? 'open' : ''} ${itemClass}`}
            />
            <strong className="prediction-box-small_total" style={{ fontSize: 20, marginLeft: '12px' }}>
              {(difference.number * 100).toFixed(1)}%{' '}
            </strong>
          </div>
          <p className="prediction-box-small__benchmark" style={{ marginTop: 0 }}>
            {t(`Event.benchmarks.${difference.number > 0 ? 'above' : 'below'}`)}
          </p>
        </div>
      </div>
      {/* <div className={`prediction-box-small_arrow ${itemClass}`}> 
    </div>
      */}
    </div>
  )
}

PredictionBox.propTypes = {
  /**
   * Left side (green or red) with arrow
   */
  main: PropTypes.shape({
    title: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
  }).isRequired,
  /**
   * Right side
   */
  second: PropTypes.shape({
    title: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
  }).isRequired,
  type: PropTypes.oneOf(['prediction', 'now']),
}

PredictionBox.defaultProps = {}

export default PredictionBox

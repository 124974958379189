import PropTypes from 'prop-types'
import React from 'react'

/**
 * Input radio
 * @Tags( shared, presentational, input )
 */
const CustomRadio = ({ onChange, checked, checkedId, disabled = false, label = '' }) => {
  return (
    <label className="custom-radio">
      <input
        className="custom-radio__input"
        id={checkedId}
        type="radio"
        onChange={(e) => onChange(e)}
        checked={checked}
        disabled={disabled}
      />
      <span className="custom-radio__box">
        <span className="custom-radio__second_box"></span>
      </span>
      <span className="custom-radio__label">{label}</span>
    </label>
  )
}

CustomRadio.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  /**
   * Optional id for radio, can be switched with label[for]
   * */
  checkedId: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
}

export default CustomRadio

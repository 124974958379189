import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function ToastContainer({ bsPrefix, position, containerPosition, className = 'toast-container', ...props }) {
  return (
    <div
      {...props}
      className={classNames(
        bsPrefix,
        position && positionClasses[position],
        containerPosition && `position-${containerPosition}`,
        className,
      )}
    />
  )
}

const propTypes = {
  /**
   * @default 'toast-container'
   */
  bsPrefix: PropTypes.string,

  /**
   * Where the toasts will be placed within the container.
   */
  position: PropTypes.oneOf([
    'top-start',
    'top-center',
    'top-end',
    'middle-start',
    'middle-center',
    'middle-end',
    'bottom-start',
    'bottom-center',
    'bottom-end',
  ]),
  className: PropTypes.string,
  containerPosition: PropTypes.string,
}

const positionClasses = {
  'top-start': 'top-0 start-0',
  'top-center': 'top-0 start-50 translate-middle-x',
  'top-end': 'top-0 end-0',
  'middle-start': 'top-50 start-0 translate-middle-y',
  'middle-center': 'top-50 start-50 translate-middle',
  'middle-end': 'top-50 end-0 translate-middle-y',
  'bottom-start': 'bottom-0 start-0',
  'bottom-center': 'bottom-0 start-50 translate-middle-x',
  'bottom-end': 'bottom-0 end-0',
}

ToastContainer.displayName = 'ToastContainer'
ToastContainer.propTypes = propTypes

import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../images/logos/primary_navy.svg'

/**
 * Header above the form for Login, Forgot Password and Registration pages
 * @Tags( presentational )
 */
const FormHeader = ({ title, subtitle }) => {
  return (
    <>
      <Link to="/" className="brand-logo hide-mobile">
        <img className="img-fluid" src={logo} alt="future demand" />
      </Link>
      <h1 className="mx-auto">{title}</h1>
      <h2 className="mx-auto">{subtitle}</h2>
    </>
  )
}

FormHeader.propTypes = {
  /** Below the logo */
  title: PropTypes.string,
  /** Below the logo */
  subtitle: PropTypes.string,
}

FormHeader.defaultProps = {}

export default FormHeader

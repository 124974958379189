import { FAIL, LOADING, OVERWRITE, STALE } from 'reducers/default'

export const creativeActions = {
  headlineUpdate: 'HEADLINE_UPDATE',
  bodyUpdate: 'BODIES_UPDATE',
  bodyHeadlineUpdate: 'BODY_HEADLINE_UPDATE',
  urlUpdate: 'URL_UPDATE',
  mediaUpdate: 'MEDIA_UPDATE',
  descriptionUpdate: 'DESCRIPTION_UPDATE',
  callToActionUpdate: 'CTA_UPDATE',
  audienceUpdate: 'AUDIENCE_UPDATE',
  cocreateLanguageUpdate: 'COCREATE_LANGUAGE_UPDATE',
  idle: 'IDLE',
}

export function creativeFormReducer(state, action) {
  switch (action.type) {
    case LOADING:
      return {
        success: false,
        loading: true,
        error: false,
        stale: state.stale,
        content: state.content,
      }
    case OVERWRITE:
      return {
        success: true,
        loading: false,
        error: false,
        stale: false,
        content: { ...action.payload },
      }
    case creativeActions.idle:
      return {
        success: true,
        loading: false,
        error: false,
        stale: false,
        content: action.payload ?? state.content,
      }
    case FAIL:
      return {
        success: false,
        loading: false,
        error: true,
        stale: false,
        message: action.payload ? action.payload : 'common.genericError',
        content: state.content,
      }
    case STALE:
      return {
        ...state,
        stale: true,
      }
    case creativeActions.headlineUpdate: {
      let headline = [...state.content.headline]
      if (action.payload === null) headline.splice(action.index, 1)
      else if (typeof action.index !== 'undefined' && action.index != null) headline[action.index] = action.payload
      else headline = action.payload

      return {
        ...state,
        content: { ...state.content, headline },
      }
    }
    case creativeActions.bodyUpdate: {
      let body = [...state.content.body]
      if (action.payload === null) body.splice(action.index, 1)
      else if (typeof action.index !== 'undefined' && action.index != null) body[action.index] = action.payload
      else body = action.payload

      return {
        ...state,
        content: { ...state.content, body },
      }
    }
    case creativeActions.bodyHeadlineUpdate: {
      let body = action.payload?.body ?? [...state.content.body]
      let headline = action.payload?.headline ?? [...state.content.headline]

      return {
        ...state,
        content: { ...state.content, body, headline },
      }
    }
    case creativeActions.urlUpdate: {
      const destination_url = [...state.content.destination_url]
      if (action.payload === null) destination_url.splice(action.index, 1)
      else destination_url[action.index] = action.payload

      return {
        ...state,
        content: { ...state.content, destination_url },
      }
    }
    case creativeActions.mediaUpdate: {
      return {
        ...state,
        content: { ...state.content, media: action.payload },
      }
    }
    case creativeActions.descriptionUpdate: {
      return {
        ...state,
        content: { ...state.content, description: action.payload },
      }
    }
    case creativeActions.callToActionUpdate: {
      return {
        ...state,
        content: { ...state.content, call_to_action: action.payload },
      }
    }
    case creativeActions.audienceUpdate: {
      return {
        ...state,
        content: { ...state.content, audience_id: action.payload },
      }
    }
    case creativeActions.cocreateLanguageUpdate: {
      return {
        ...state,
        content: { ...state.content, language: action.payload },
      }
    }
    default:
      return state
  }
}

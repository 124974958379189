import PropTypes from 'prop-types'
import { useCallback, useReducer, useState } from 'react'
import { useEffect } from 'react'
import { fetchGroupedVenues } from '../../../../api/events'
import { arrayReducer, initStateArray } from '../../../../reducers/default'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'

export function LocationFilter({ children, state, dispatch, submit }) {
  const [locationsState, dispatchLocationsAction] = useReducer(arrayReducer, initStateArray)
  const hasPrismaAccess = hasPrismaAccessTier()

  const [rendered, setRenderedInternal] = useState(false)

  const selectedCities = state.cities
  const selectedVenuesAndHalls = state.venuesAndHalls

  const setRendered = useCallback(() => {
    if (!rendered) setRenderedInternal(true)
  }, [rendered])

  useEffect(() => {
    const controller = new AbortController()
    if (rendered && !locationsState.success && !locationsState.loading) {
      fetchGroupedVenues(dispatchLocationsAction, controller, { selectedCities, selectedVenuesAndHalls })
    }

    return () => controller.abort()
  }, [rendered, locationsState, selectedCities, selectedVenuesAndHalls])

  if (hasPrismaAccess) {
    return null
  }

  const onSelectedCitiesChange = (selectedCities) => {
    if (selectedCities.length === 0) {
      dispatch({ type: 'CITIES_CANCEL' })
      submit({ ...state, cities: [] })
    } else {
      dispatch({ type: 'CITIES', payload: selectedCities })
      submit({ ...state, cities: selectedCities })
    }
  }

  const onSelectedVenuesAndHallsChange = (selectedVenuesAndHalls) => {
    if (selectedVenuesAndHalls.length === 0) {
      dispatch({ type: 'VENUESANDHALLS_CANCEL' })
      submit({ ...state, venuesAndHalls: [] })
    } else {
      dispatch({ type: 'VENUESANDHALLS', payload: selectedVenuesAndHalls })
      submit({ ...state, venuesAndHalls: selectedVenuesAndHalls })
    }
  }


  return children(
    locationsState,
    [selectedCities, onSelectedCitiesChange],
    [selectedVenuesAndHalls, onSelectedVenuesAndHallsChange],
    setRendered,
  )
}

LocationFilter.propTypes = {
  children: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
}

import React, { createContext, useMemo, useReducer, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { buildSetupPayload } from 'api/models'
import { fetchSetupProcess, updateSetupProcess } from 'api/setupProcesses'
import { FAIL, initStateObject, objectReducer, OVERWRITE } from 'reducers/default'
import { TargetingContextProvider } from './CampaignForm/Targeting/TargetingContext'

export const SetupContext = createContext(null)
export const SetupDispatchContext = createContext(null)
export const CreativesContext = createContext(null)
export const CreativesDispatchContext = createContext(null)
export const RetryDispatchContext = createContext(null)
export const CurrentStepContext = createContext(null)
export const CurrentStepDispatchContext = createContext(null)
export const AvailableTasteClustersContext = createContext(null)

export const actions = Object.freeze({
  setupLoaded: 'SETUP_LOADED',
  failedRetry: 'FAILED_RETRY',
  failedUpdate: 'FAILED_UPDATE',
  successfulUpdate: 'SUCCESSFUL_UPDATE',
  loading: 'LOADING',
  resetSaved: 'RESET_SAVED',
})

const initialState = {
  loading: false,
  failedCreatives: [],
  hasFailures: false,
  hasSaved: false,
  lastUpdatedAt: null,
}

function creativesReducer(state, action) {
  switch (action.type) {
    case actions.loading:
      return { ...state, loading: true }
    case actions.failedRetry: {
      return {
        ...state,
        loading: false,
        hasFailures: true,
        hasSaved: true,
      }
    }
    case actions.failedUpdate: {
      return {
        ...state,
        failedCreatives: [...state.failedCreatives.filter((x) => x.tc !== action.payload.tc), action.payload.creative],
        loading: false,
        hasFailures: true,
        hasSaved: true,
      }
    }
    case actions.successfulUpdate: {
      return {
        ...state,
        failedCreatives: [],
        lastUpdatedAt: action.payload,
        loading: false,
        hasFailures: false,
        hasSaved: true,
      }
    }
    case actions.setupLoaded: {
      return {
        ...state,
        setupProcess: action.payload,
        loading: false,
      }
    }
    case actions.resetSaved: {
      return {
        ...state,
        hasSaved: false,
      }
    }
    default:
      throw new Error(`Unknown action type ${action.type}`)
  }
}

/**
 * Provides the setup process from either a setup process, or a published campaign setup, to
 * the children components.
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export function SetupProvider({ setup, children }) {
  const _setup = useMemo(() => {
    if (!setup) return null
    if ('creatives' in setup) return setup
    if ('creative' in setup) return { ...setup, creatives: [{
      tc: setup.tc,
      tc_run_id: setup.tc_run_id,
      ...setup.creative,
    }] }
    return null
  }, [setup])

  return <SetupContext.Provider value={_setup}>{children}</SetupContext.Provider>
}

SetupProvider.propTypes = {
  setup: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

/**
 * Provides the context for available taste clusters.
 * For now, it's mainly used to keep track of the open taste clusters.
 *
 * @component
 */
function AvailableTasteClustersContextProvider({ children }) {
  const openTCs = useState([])

  return (
    <AvailableTasteClustersContext.Provider value={{ openTCs }}>
      {children}
    </AvailableTasteClustersContext.Provider>
  )
}

AvailableTasteClustersContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default function CampaignSetupProvider({ eventId, children }) {
  const [state, dispatch] = useReducer(creativesReducer, initialState)
  const [setupProcessState, dispatchSetupAction] = useReducer(objectReducer, initStateObject)
  const [currentStepState, dispatchCurrentStep] = useReducer(objectReducer, {
    ...initStateObject,
    content: {
      currentStep: 1,
      currentCompletedStep: null,
    },
  })

  useEffect(() => {
    fetchSetupProcess(dispatchSetupAction, eventId)
  }, [eventId])

  const resubmit = () => {
    const setup = setupProcessState.content
    const tcsToRetry = state.failedCreatives.map((x) => x.tc)
    const existingCreatives = setup.creatives.filter((x) => tcsToRetry.every((tc) => tc !== x.tc))
    const payload = buildSetupPayload(
      { ...setup, creatives: [...existingCreatives, ...state.failedCreatives] },
      { setupOnly: true },
    )

    dispatch({ type: actions.loading })

    updateSetupProcess(
      (action) => {
        if (action.type === FAIL) {
          dispatch({ type: actions.failedRetry })
        } else if (action.type === OVERWRITE) {
          dispatchSetupAction({ type: action.type, payload: action.payload })
          dispatch({ type: actions.successfulUpdate, payload: new Date() })
        }
      },
      eventId,
      payload,
    )
  }

  return (
    <CurrentStepContext.Provider value={currentStepState.content}>
      <AvailableTasteClustersContextProvider>
        <CurrentStepDispatchContext.Provider value={dispatchCurrentStep}>
          <SetupContext.Provider
            value={Object.keys(setupProcessState.content).length === 0 ? null : setupProcessState.content}
          >
            <SetupDispatchContext.Provider value={dispatchSetupAction}>
              <TargetingContextProvider>
                <CreativesContext.Provider value={state}>
                  <CreativesDispatchContext.Provider value={dispatch}>
                    <RetryDispatchContext.Provider value={resubmit}>{children}</RetryDispatchContext.Provider>
                  </CreativesDispatchContext.Provider>
                </CreativesContext.Provider>
              </TargetingContextProvider>
            </SetupDispatchContext.Provider>
          </SetupContext.Provider>
        </CurrentStepDispatchContext.Provider>
      </AvailableTasteClustersContextProvider>
    </CurrentStepContext.Provider>
  )
}

CampaignSetupProvider.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.node.isRequired,
}

export function useSetupDispatch() {
  const context = React.useContext(SetupDispatchContext)
  if (context === undefined) {
    throw new Error('useSetupDispatch must be used within a SetupProvider')
  }
  return context
}

import React from 'react'

import moment from 'moment'

const EventSearchRow = ({ title, location, date, handleClick }) => {
  return (
    <div onClick={handleClick} className={'event-editor_event-row_wrapper c-hover'}>
      <div className="event-editor_event-row_img">
        <i className="icon-events" />
      </div>
      <div className="event-editor_event-row_title-and-date_wrapper">
        <div className="event-editor_event-row_title-and-date_title">{title}</div>
        <div className="event-editor_event-row_title-and-date_date_wrapper">
          <span className="event-editor_event-row_title-and-date_date_location">
            <i className="icon-location" style={{ marginRight: 4 }} />
            {location}
          </span>
          <time
            className="event-editor_event-row_title-and-date_date_time"
            dateTime={moment.utc(date).format('DD.MM.YYYY HH:mm')}
          >
            <i className="icon-clock" style={{ marginRight: 4 }} />
            {moment.utc(date).format('DD.MM.YYYY HH:mm')}
          </time>
        </div>
      </div>
    </div>
  )
}

export default EventSearchRow

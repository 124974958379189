import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Shared/Button'

import { Card } from 'react-bootstrap'
import { Trans } from 'react-i18next'

const FailedScreen = ({ title }) => {
  return (
    <Card className="package-builder-page_loading-screen_wrapper card card-default w-100">
      <div className="package-builder-page_loading-screen_inner">
        <Trans i18nKey="PackageBuilder.failed">
          Your Package <strong>{{ title }}</strong> failed to build.
        </Trans>
        <br />
        <Button text={'Retry'} linkTo="/package-builder/create" className="w-100" />
        <br />
        <br />
      </div>
    </Card>
  )
}

FailedScreen.propTypes = {
  title: PropTypes.string,
}

export default FailedScreen

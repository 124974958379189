import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Fade from 'components/Shared/Wrappers/Fade'
import { SecondaryButton } from 'components/Shared/Button'
import InfoBox from 'components/Shared/InfoBox'

import EventHeader from './EventHeader'
import EventBenchmarks from './EventBenchmarks'
import SalesTrendChart from './SalesTrendChart'
import TicketCategories from './TicketCategories'
import TicketTypeChart from './TicketTypeChart'
import EventStatistics from './EventStatistics'
import { useFetchCampaigns } from 'hooks/useFetchCampaigns'
import { CampaignStatsContext } from './EventStatsProvider'
import { initStateObject, objectReducer } from 'reducers/default'
import { getEventSeries } from 'api/events'
import { TransactionsChart } from './TransactionsChart'
import useHasPermission, { Permissions } from 'hooks/useHasPermission'

const EventDetailsMobile = ({ event, eventId }) => {
  const { t } = useTranslation()
  const hasWaveAccess = useHasPermission(Permissions.wave)
  const [showTicketCategories, setShowTicketCategories] = useState(true)
  const [showTicketType, setShowTicketType] = useState(true)
  const [showTransactionGraph, setShowTransactionGraph] = useState(false)
  const [eventSeries, dispatchEventSeries] = useReducer(objectReducer, initStateObject)

  //Handles tab changes
  const [currentTab, setCurrentTab] = useState(0)
  const [compMounted, setCompMounted] = useState(false)
  const hasCampaignStats = useContext(CampaignStatsContext)

  const { eventSeriesCampaigns } = useFetchCampaigns(event.content)

  // looks at the url search params to see if tab is included
  // uses it if it is else ignore and use the default tab
  useEffect(() => {
    if (!compMounted) {
      const urlParams = new URL(window.location.href)
      const tab = urlParams.searchParams.get('tab') || '0'

      setCurrentTab(tab)
      setCompMounted(true)
    }
  }, [setCurrentTab, setCompMounted, compMounted])

  const handleTabChange = (idx) => {
    if (!hasCampaignStats && idx === '1') return

    setCurrentTab(idx)
    if (window.innerWidth >= 991) {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('tab', idx)
      window.history.pushState(null, '', window.location.pathname + '?' + searchParams.toString())
    } else {
      const newUrl = `${window.location.origin}/events/${eventId}/?tab=${idx}`
      window.history.pushState({ path: newUrl }, '', newUrl)
    }
  }

  useEffect(() => {
    if (eventId) {
      getEventSeries(dispatchEventSeries, eventId)

      if (hasCampaignStats === false && currentTab === '1') setCurrentTab('0')
    }
  }, [eventId, currentTab, hasCampaignStats, dispatchEventSeries])

  return (
    <Fade transitionDuration={1000} delay={0}>
      <Tab.Container defaultActiveKey="0" activeKey={currentTab}>
        <div className="row hide-desktop mobile-tabs-wrapper">
          <Nav className="nav nav-tabs mobile-tabs">
            <Nav.Item>
              <Nav.Link onClick={() => handleTabChange('0')} eventKey="0">
                {t('Event.mobileTabs.details')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={`${event.content?.has_active_campaigns ? '' : 'disabled'}`}>
              <Nav.Link onClick={() => handleTabChange('1')} eventKey="1">
                {t('Event.mobileTabs.campaigns')}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Tab.Content className="row flex-nowrap">
          <Tab.Pane eventKey="0" className="tab-pane tab-pane-mobile col-12 col-lg flex-grow-1 pd-desktop-left">
            <div data-testid="event-header" className="insights-header card card-default">
              <div className="card-body">
                <EventHeader event={event.content} />

                <div className="events-insights-page_cta_row">
                  <div className="w-100">
                    <SecondaryButton
                      disabled={!hasWaveAccess}
                      text={t('Event.btnCampaignRecommendations')}
                      linkTo={`/campaigns/${event.content.eid}`}
                      fullWidth
                      extraStyles={{ height: '38px', marginRight: '1em', padding: '0 1em', background: '#F58562' }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card tabs-component_main-content_section">
              <InfoBox content="Tips.Events.SalesTrend">
                <h2 className="card-title-default d-inline-block m-0">{t('Tips.Events.SalesTrend.title')}</h2>
              </InfoBox>
              {!event.loading && <EventBenchmarks eventId={eventId} />}
              <div className="mt-4">
                <SalesTrendChart title={t('Event.cards.salesTrend')} eventId={eventId} />
              </div>
            </div>
            <div
              className={`${showTicketCategories || showTicketType ? 'tabs-component_main-content_section' : ''} events-insights-page_page_stats`}
            >
              <TicketCategories
                className="card card-ticket-type events-insights-page_dash_small_card"
                eventId={eventId}
                title={t('Event.cards.ticketCategories')}
                setShowCategories={setShowTicketCategories}
              />
              <TicketTypeChart
                className="card card-ticket-type events-insights-page_dash_small_card"
                eventId={eventId}
                title={t('Event.cards.ticketType')}
                setShowCategories={setShowTicketType}
              />
            </div>
            <div className={`${showTransactionGraph ? 'tabs-component_main-content_section' : ''}`}>
              <TransactionsChart eventId={eventId} setShowGraph={setShowTransactionGraph} />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="1" className="tab-pane tab-pane-mobile col-12 col-lg-auto pd-desktop-right">
            <div className="card card-default events-insights-page_statistics_container">
              <EventStatistics
                event={event.content}
                eventSeries={eventSeries.content}
                campaigns={eventSeriesCampaigns}
              />
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Fade>
  )
}

EventDetailsMobile.propTypes = {
  event: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
}

export default EventDetailsMobile

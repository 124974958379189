import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import Layout from '../components/Layout'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import EmailNotificationSettings from 'components/Settings/Notifications/EmailNotificationSettings.jsx'
import PersonalSettings from 'components/Settings/Personal/PersonalSettings'
import MetaAccess from 'components/Settings/Meta'
import SystemSettings from 'components/Settings/SystemSettings'

import Tabs from 'components/Shared/Tabs'

const ItemWrapper = ({ children, cardClassNames = 'card card-default settings-page__card' }) => {
  return (
    <div className="d-flex flex-column w-100 gap-3 mb-5">
      <div className="settings-page__container">
        <div className={cardClassNames}>{children}</div>
      </div>
    </div>
  )
}

ItemWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  cardClassNames: PropTypes.string,
}

/**
 * Default page for settings.
 * @Tags( page, settings, layout, logged-users )
 * @SuggestedTags( settings )
 * @Routes( /settings )
 */
const Settings = ({ fetchAndSetProfile }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const [currentTab, setCurrentTab] = useState(0)

  useEffect(() => {
    fetchAndSetProfile(dispatch, i18n)
  }, [dispatch, fetchAndSetProfile, i18n])

  const tabsData = [
    {
      title: t('Settings.PersonalSettings'),
      component: (
        <ItemWrapper>
          <PersonalSettings />
        </ItemWrapper>
      ),
    },
    {
      title: t('Settings.NotificationSettings.title'),
      component: (
        <ItemWrapper>
          <EmailNotificationSettings />
        </ItemWrapper>
      ),
    },
    {
      title: t('Settings.MetaAccess.title'),
      component: (
        <ItemWrapper cardClassNames="meta">
          <MetaAccess />
        </ItemWrapper>
      ),
    },
    {
      title: t('Settings.system.title'),
      component: (
        <ItemWrapper>
          <SystemSettings />
        </ItemWrapper>
      ),
    },
  ]

  const handleTabChange = (idx) => {
    setCurrentTab(idx)
  }

  return (
    <Layout
      loggedIn
      title={t('Settings.PageTitle')}
      mobileTopBar={{
        centerTitle: t('Settings.MobileTitle'),
        backToDash: true,
      }}
    >
      <Helmet
        bodyAttributes={{
          class: 'settings-page',
        }}
      />
      <main>
        <div className="container-fluid pb-5">
          <div className="p-3">
            <div>
              <h3 className="main-title hide-mobile">{t('Settings.MainTitle')}</h3>
            </div>
            <Tabs tabsData={tabsData} currentTab={currentTab} handleTabChange={handleTabChange} />
          </div>
        </div>
      </main>
    </Layout>
  )
}

Settings.propTypes = {
  fetchAndSetProfile: PropTypes.func,
}

export default Settings

import React, { useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import { NotificationContext, NotificationDispatchContext, actions } from './notifications'

import CustomCheck from 'components/Shared/CustomCheck'
import { getEnumMemberName } from 'utils/helpers'
import { ClockIcon, DonutIcon } from 'images'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'

function Topic({ topic }) {
  return <span className="notification-item__topic">{getEnumMemberName(topic).toLowerCase()}</span>
}

Topic.propTypes = {
  topic: PropTypes.string.isRequired,
}

function ReadControl({ notification, selectionModeEnabled, dispatch }) {
  const { t } = useTranslation()

  return (
    <label
      aria-disabled={selectionModeEnabled}
      className={`read-check ${selectionModeEnabled ? 'disabled' : ''}`}
      onClick={(e) => {
        e.stopPropagation()
        dispatch({
          type: actions.updateNotificationAsync,
          payload: { notificationId: notification.id, isRead: !notification.is_read },
        })

        return false
      }}
    >
      <span className="read-check__label">
        {t(`Notifications.actionBar.${notification.is_read ? 'markAsUnread' : 'markAsRead'}`)}
      </span>
      <input
        className="read-check__input"
        id={`read-check-${notification.id}`}
        type="checkbox"
        checked={!notification.is_read}
        disabled
      />
      <span className="read-check__box">
        <span
          className={`read-check__box--inner ${notification.is_read ? '' : 'read-check__box--inner--checked'}`}
        />
      </span>
    </label>
  )
}

ReadControl.propTypes = {
  notification: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  selectionModeEnabled: PropTypes.bool.isRequired,
}

function NotificationThumb({ notificationId, onSelect }) {
  const { selectionMode } = useContext(NotificationContext)
  const hasPrismaAccess = hasPrismaAccessTier()

  if (selectionMode.enabled) {
    return (
      <div className="notification-item_thumb transparent">
        <CustomCheck
          checked={selectionMode.selectedIds.includes(notificationId)}
          onChange={onSelect}
          classNames="custom-check--navy"
        />
      </div>
    )
  }

  return (
    <div className="notification-item_thumb">
      {hasPrismaAccess ? <DonutIcon /> : <i className="icon-events" />}
    </div>
  )
}

NotificationThumb.propTypes = {
  notificationId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
}

function NotificationItemContent({ notification }) {
  const state = useContext(NotificationContext)
  const dispatch = useContext(NotificationDispatchContext)
  const { event_category } = notification.template_data || {}

  const timestampFormat = useMemo(
    () => (event_category === 'product' ? 'DD.MM.YYYY' : 'DD.MM.YYYY, HH:mm'),
    [event_category],
  )

  const handleSelect = (e) => {
    e.stopPropagation()
    if (!state.selectionMode.enabled) return

    dispatch({ type: actions.selectOne, payload: notification.id })
  }

  return (
    <>
      <NotificationThumb notificationId={notification.id} onSelect={handleSelect} />
      <div className="notification-item__content">
        <div className="notification-item__details">
          <div className="d-flex align-items-center">
            <span className="notification-item__title">
              <Markdown rehypePlugins={[rehypeRaw]}>{notification.title}</Markdown>
            </span>
            <Topic topic={notification.notification_topic} />
          </div>
          <span className="notification-item__timestamp">
            <ClockIcon className="mr-1" height={12} />
            <time dateTime={moment(notification.date_created).format(timestampFormat)}>
              {moment(notification.date_created).format(timestampFormat)}
            </time>
          </span>
        </div>
        <div className="notification-item__meta">
          <Topic topic={notification.notification_topic} />
          <ReadControl
            notification={notification}
            dispatch={dispatch}
            selectionModeEnabled={state.selectionMode.enabled}
          />
        </div>
      </div>
    </>
  )
}

NotificationItemContent.propTypes = {
  notification: PropTypes.object.isRequired,
}

function NotificationItem({ notification, isActive }) {
  const state = useContext(NotificationContext)
  const [viewWidth, setViewWidth] = useState(window.innerWidth)
  const handleResize = () => setViewWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const pageUrl = `${viewWidth < 976 ? '/notifications/' : '?drawer-open=true&notification-id='}${notification.id}`

  if (state.selectionMode.enabled) {
    return (
      <div className={`notification-item ${isActive ? 'active' : ''}`}>
        <NotificationItemContent notification={notification} isActive={isActive} />
      </div>
    )
  }

  return (
    <Link
      to={state.selectionMode.enabled ? '#' : pageUrl}
      className={`notification-item ${isActive ? 'active' : ''}`}
    >
      <NotificationItemContent notification={notification} isActive={isActive} />
    </Link>
  )
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
}

export default NotificationItem

import { api } from './_init'
import axios from 'axios'

export const initiateMultipartUpload = async (data, configData = {}) => {
  const config = {
    headers: { ...(configData.upload_type && { 'X-Upload-Type': configData.upload_type }) },
    cancelToken: configData.cancelToken,
  }

  try {
    const result = await api.post('/storage/multipart-upload', data, config)
    if (result.status === 200) {
      return {
        success: true,
        error: false,
        content: result.data,
      }
    } else {
      return {
        success: false,
        error: true,
        message: 'common.errors.inServerResponse',
        content: {},
      }
    }
  } catch (error) {
    if (axios.isCancel(error)) return { success: null, error: null, content: {} }

    return {
      success: false,
      error: true,
      content: error,
    }
  }
}

export const createPresignedUrl = async (data, configData = {}) => {
  const config = {
    headers: { ...(configData.upload_type && { 'X-Upload-Type': configData.upload_type }) },
    cancelToken: configData.cancelToken,
  }

  try {
    const result = await api.post('/storage/presigned-url', data, config)
    if (result.status === 200) {
      return {
        success: true,
        error: false,
        content: result.data,
      }
    } else {
      return {
        success: false,
        error: true,
        message: 'common.errors.inServerResponse',
        content: {},
      }
    }
  } catch (error) {
    if (axios.isCancel(error)) return { success: null, error: null, content: {} }

    return {
      success: false,
      error: true,
      content: error,
    }
  }
}

export const callPresignedUrl = async (presigned_url, file, configData = {}, onUpload = null) => {
  presigned_url = presigned_url.replace('http://', 'https://')

  if (!(file instanceof File || file instanceof Blob)) return { success: false, error: true, content: {} }

  const config = {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    cancelToken: configData.cancelToken,
  }

  try {
    const result = await axios.put(presigned_url, file, {
      ...config,
      onUploadProgress: (progressEvent) =>
        onUpload ? onUpload(Math.round((progressEvent.loaded * 100) / progressEvent.total)) : null,
    })

    if (result.status === 200) {
      return {
        success: true,
        error: false,
        content: { etag: result.headers.etag },
      }
    } else {
      return {
        success: false,
        error: true,
        message: 'common.errors.inServerResponse',
        content: {},
      }
    }
  } catch (error) {
    if (axios.isCancel(error)) return { success: null, error: null, content: {} }

    return {
      success: false,
      error: true,
      content: error,
    }
  }
}

export const completeMultipartUpload = async (data, configData = {}) => {
  const config = {
    headers: { ...(configData.upload_type && { 'X-Upload-Type': configData.upload_type }) },
    cancelToken: configData.cancelToken,
  }

  try {
    const result = await api.put('/storage/multipart-upload', data, config)
    if (result.status === 200 || result.status === 204) {
      return {
        success: true,
        error: false,
        content: result.data,
      }
    } else {
      return {
        success: false,
        error: true,
        message: 'common.errors.inServerResponse',
        content: {},
      }
    }
  } catch (error) {
    if (axios.isCancel(error)) return { success: null, error: null, content: {} }

    return {
      success: false,
      error: true,
      content: error,
    }
  }
}

export const validateUploadId = async (data, configData = {}) => {
  const config = {
    headers: { ...(configData.upload_type && { 'X-Upload-Type': configData.upload_type }) },
    cancelToken: configData.cancelToken,
  }

  try {
    const result = await api.get('/storage/multipart-upload', { ...config, params: data })
    if (result.status === 200) {
      return {
        success: true,
        error: false,
        content: result.data,
      }
    } else {
      return {
        success: false,
        error: true,
        message: 'common.errors.inServerResponse',
        content: {},
      }
    }
  } catch (error) {
    if (axios.isCancel(error)) return { success: null, error: null, content: {} }

    return {
      success: false,
      error: true,
      content: error,
    }
  }
}

export const abortMultipartUpload = async (data, configData = {}) => {
  const config = {
    headers: { ...(configData.upload_type && { 'X-Upload-Type': configData.upload_type }) },
    cancelToken: configData.cancelToken,
  }

  try {
    const result = await api.delete('/storage/multipart-upload', { data, config })
    if (result.status === 204) {
      return {
        success: true,
        error: false,
        content: {},
      }
    } else {
      return {
        success: false,
        error: true,
        message: 'common.errors.inServerResponse',
        content: {},
      }
    }
  } catch (error) {
    return {
      success: false,
      error: true,
      content: error,
    }
  }
}

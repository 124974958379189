import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import DropdownSearch from '../components/DropdownSearch'
import SelectedEventsTable from '../components/SelectedEventsTable'
import Fade from 'components/Shared/Wrappers/Fade'
import { SecondaryButton } from 'components/Shared/Button'

const SelectEvents = ({ selectedEvents, setSelectedEvents, accordionOpen, setAccordionOpen }) => {
  const { t } = useTranslation()
  const [clearSearchQuery, setClearSearchQuery] = useState(false)

  const handleClearResults = () => {
    setSelectedEvents([])
    setClearSearchQuery(true)
    const localState = JSON.parse(localStorage.getItem('packageBuilderState'))
    localState.listItems = []
    localState.targetMode = ''
    localStorage.setItem('packageBuilderState', JSON.stringify(localState))
  }

  return (
    <Fade transitionDuration={1000} delay={0}>
      <div data-cy="first-step-screen" data-testid="package-builder-select-events">
        <div onClick={() => setAccordionOpen(!accordionOpen)} className="package-builder-page_row-wrapper">
          <h5 className="package-builder-page_title">
            {t(
              selectedEvents?.length
                ? 'PackageBuilder.steps.selectEventsWithSelectedEvents'
                : 'PackageBuilder.steps.selectEvents',
              { selectedEvents: selectedEvents?.length },
            )}
          </h5>
        </div>
        <p className="package-builder-page_description">{t('PackageBuilder.searchEvents')}</p>
        <br />
        <div className="package-builder-page_row-wrapper mt-4">
          <DropdownSearch
            setSelectedEvents={setSelectedEvents}
            selectedEvents={selectedEvents}
            setClearSearchQuery={setClearSearchQuery}
            clearSearchQuery={clearSearchQuery}
          />

          <div className="package-builder-page_btns-right">
            <SecondaryButton
              color="navy"
              size="normal"
              onClick={handleClearResults}
              text={t('PackageBuilder.clearEvents')}
            />
          </div>
        </div>
      </div>

      <div className={`package-builder-page_accordion_wrapper ${accordionOpen ? 'open' : ''}`}>
        <div className="package-builder-page_accordion_content">
          <SelectedEventsTable selectedEvents={selectedEvents} setSelectedEvents={setSelectedEvents} />
        </div>
      </div>
    </Fade>
  )
}

SelectEvents.propTypes = {
  selectedEvents: PropTypes.array,
  setSelectedEvents: PropTypes.func,
  accordionOpen: PropTypes.bool,
  setAccordionOpen: PropTypes.func,
}

export default SelectEvents

import campaignSetupApi from 'api/campaigns'
import { clearDisabledVariationsCache, prepareTargetingPayload, setVariationHashCache } from 'api/models'

export const actions = {
  init: 'init',
  setCurrentCreative: 'SET_CURRENT_CREATIVE',
  setCurrentTargeting: 'SET_CURRENT_TARGETING',
  setCurrentDisabledVariations: 'SET_CURRENT_DISABLED_VARIATIONS',
  updateCampaignSetupAsync: 'UPDATE_CAMPAIGN_SETUP_ASYNC',
  setLoading: 'SET_LOADING',
  setSuccess: 'SET_SUCCESS',
  setError: 'SET_ERROR',
}

export const initialState = {
  currentCreative: null,
  currentTargeting: null,
  currentDisabledVariations: [],
  loading: false,
  success: false,
  error: null,
}

export function reducer(state, action) {
  switch (action.type) {
    case actions.init: {
      const { eventId, campaign } = (action.payload || {})
      if (!campaign) {
        clearDisabledVariationsCache(eventId)
        return { ...initialState }
      }

      const disabledVariations = campaign.latest_campaign_setup.creative.excluded_variations
      setVariationHashCache(eventId, campaign.latest_campaign_setup.creative, disabledVariations)

      return {
        ...initialState,
        currentCreative: {
          tc: campaign.tc,
          tc_run_id: campaign.tc_run_id,
          ...campaign.latest_campaign_setup.creative,
        },
        currentDisabledVariations: disabledVariations,
      }
    }
    case actions.setCurrentCreative: {
      return {
        ...state,
        currentCreative: action.payload,
      }
    }
    case actions.setCurrentTargeting: {
      return {
        ...state,
        currentTargeting: action.payload,
      }
    }
    case actions.setCurrentDisabledVariations: {
      return {
        ...state,
        currentDisabledVariations: action.payload,
      }
    }
    case actions.setLoading:
      return {
        ...state,
        loading: true,
      }
    case actions.setSuccess: {
      const { eventId } = action.payload
      clearDisabledVariationsCache(eventId)
      return {
        ...initialState,
        success: true,
      }
    }
    case actions.setError: {
      const { error } = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }
    default:
      throw new Error(`Unknown action type ${action.type}`)
  }
}

export const asyncActionHandlers = {
  [actions.updateCampaignSetupAsync]: ({ dispatch, getState }) => async (action) => {
    const { currentDisabledVariations } = getState()

    const { eventId, creative: creativePayload, targeting } = action.payload

    try {
      dispatch({ type: actions.setLoading })
      const { error } = await campaignSetupApi.updateAsync(eventId, {
        tc: creativePayload.tc,
        tc_run_id: creativePayload.tc_run_id,
        audience_id: creativePayload.audience_id,
        creatives: {
          ...creativePayload,
          excluded_variations: currentDisabledVariations,
        },
        targeting: prepareTargetingPayload(targeting)[0],
      })

      dispatch({
        type: error ? actions.setError : actions.setSuccess,
        payload: { eventId, error: 'common.errors.inServerResponse' },
      })
    } catch (error) {
      console.error(error)
      dispatch({ type: actions.setError, payload: 'common.errors.inServerResponse' })
    }
  },
}

import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes, { object } from 'prop-types'

import useLongPress from 'hooks/UseLongPress'

import { CancelIcon, ErrorIcon, GreenTickIcon, AlarmIcon, MergeRedIcon } from 'images'

import { useTranslation } from 'react-i18next'
import { Table } from 'react-bootstrap'

const iconDict = {
  failed: <CancelIcon width={24} height={20} />,
  done: <GreenTickIcon width={24} />,
  initializing: <AlarmIcon width={24} />,
  inprogress: <AlarmIcon width={24} />,
  expired: <ErrorIcon width={24} />,
}

const EachEvent = ({ events }) => {
  return (
    <div className="package-builder-page_previous-packages_table_events_bubbles_wrapper">
      <div className="package-builder-page_previous-packages_table_events_bubbles_each">{events[0].title}</div>
      {events.length > 2 ? (
        <div className="package-builder-page_previous-packages_table_events_bubbles_more">
          {`+ ${events.length - 1}`}
        </div>
      ) : null}
    </div>
  )
}

EachEvent.propTypes = {
  events: PropTypes.arrayOf(object),
}

const PreviousPackageRow = ({
  previousPackage,
  cypressId,
  highlightedPackages = [],
  handleSelect,
  utilityModeActive,
  setUtilityModeActive,
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const onLongPress = (e) => {
    if (!utilityModeActive) {
      setUtilityModeActive(true)
      handleSelect(previousPackage, e)
    }
  }

  const handleRowClick = () => {
    history.push(`/package-builder/results/${previousPackage.id}`)
  }

  const defaultOptions = {
    shouldPreventDefault: false,
    delay: 500,
  }

  const longPressEvent = useLongPress(onLongPress, () => {}, defaultOptions)

  if (utilityModeActive) {
    return (
      <tr
        id={cypressId}
        data-cy={cypressId}
        onClick={(e) => handleSelect(previousPackage, e)}
        className={`${
          highlightedPackages && highlightedPackages.includes(previousPackage) ? 'selected' : ''
        } hover-color`}
      >
        <td>
          <label data-cy={`${cypressId}-checkbox`} className="package-builder-page_custom-checkbox">
            <input
              disabled
              onChange={(e) => e.stopPropagation()}
              checked={highlightedPackages && highlightedPackages.indexOf(previousPackage) > -1}
              type="checkbox"
            />
            <span onClick={(e) => handleSelect(previousPackage, e)}></span>
          </label>
        </td>
        <td>
          <p className="package-builder-page_previous-packages_table_title">{previousPackage.title}</p>
        </td>
        <td className="package-builder-page_previous-packages_table_merged-badge">
          <p>
            <div className="badge hide-mobile">{previousPackage.merge_id ? 'merged' : ''}</div>
            {previousPackage.merge_id ? <MergeRedIcon width={20} className="hide-desktop icon" /> : null}
          </p>
        </td>
        <td className="package-builder-page_previous-packages_table_customer-number">
          <p>{previousPackage.n_customers}</p>
        </td>
        <td className="package-builder-page_previous-packages_table_events">
          {previousPackage.events.length > 0 ? (
            <EachEvent events={previousPackage.events} />
          ) : (
            <p className="pl-3">No events found</p>
          )}
        </td>
        <td
          className={`package-builder-page_previous-packages_table_status icon ${previousPackage.status.toLowerCase()}`}
        >
          <div className="hide-desktop">{iconDict[previousPackage.status.toLowerCase()]}</div>
          <div className="hide-mobile">
            {t([`PackageBuilder.status.${previousPackage.status.toLowerCase()}`, previousPackage.status.toLowerCase()])}
          </div>
        </td>
      </tr>
    )
  }

  return (
    <tr
      {...longPressEvent}
      className={`${
        highlightedPackages && highlightedPackages.includes(previousPackage) ? 'selected' : ''
      } hover-color`}
      onClick={handleRowClick}
    >
      <td>
        <label
          onClick={(e) => handleSelect(previousPackage, e)}
          data-cy={`${cypressId}-checkbox`}
          className="package-builder-page_custom-checkbox"
        >
          <input
            onChange={(e) => e.stopPropagation()}
            checked={highlightedPackages && highlightedPackages.indexOf(previousPackage) > -1}
            type="checkbox"
          />
          <span></span>
        </label>
      </td>
      <td>
        <p className="package-builder-page_previous-packages_table_title">{previousPackage.title}</p>
      </td>
      <td className="package-builder-page_previous-packages_table_merged-badge">
        <p>
          <div className="badge hide-mobile">{previousPackage.merge_id ? 'merged' : ''}</div>
          {previousPackage.merge_id ? <MergeRedIcon width={20} className="hide-desktop icon" /> : null}
        </p>
      </td>
      <td className="package-builder-page_previous-packages_table_customer-number">
        <p>{previousPackage.n_customers}</p>
      </td>

      <td className="package-builder-page_previous-packages_table_events">
        {previousPackage.events && previousPackage.events.length > 0 ? (
          <EachEvent events={previousPackage.events} />
        ) : (
          <p className="pl-3">No events found</p>
        )}
      </td>
      <td
        className={`package-builder-page_previous-packages_table_status icon ${previousPackage.status.toLowerCase()}`}
      >
        <div className="hide-desktop">{iconDict[previousPackage.status.toLowerCase()]}</div>
        <div className="hide-mobile">
          {t([`PackageBuilder.status.${previousPackage.status.toLowerCase()}`, previousPackage.status.toLowerCase()])}
        </div>
      </td>
    </tr>
  )
}

PreviousPackageRow.propTypes = {
  previousPackage: PropTypes.object,
  cypressId: PropTypes.string,
  highlightedPackages: PropTypes.arrayOf(object),
  handleSelect: PropTypes.func,
  utilityModeActive: PropTypes.bool,
  setUtilityModeActive: PropTypes.func,
}

const PreviousPackagesList = ({
  previousPackages,
  highlightedPackages = [],
  handleSelect,
  utilityModeActive,
  setUtilityModeActive,
  previousPackageStats,
  setHighlightedPackages,
}) => {
  const { t } = useTranslation()
  return (
    <Table className="package-builder-page_previous-packages_table">
      <thead>
        <tr>
          <th style={{ width: 30 }}></th>
          <th>
            <strong className="package-builder-page_previous-packages_table_title">{t('common.title')}</strong>
          </th>
          <th className="package-builder-page_previous-packages_table_merged-badge">
            <strong title={t('PackageBuilder.merged')}>{t('PackageBuilder.merged')}</strong>
          </th>
          <th className="package-builder-page_previous-packages_table_customer-number">
            <strong>{t('PackageBuilder.customers')}</strong>
          </th>
          <th className="package-builder-page_previous-packages_table_events">
            <strong>{t('PackageBuilder.events')}</strong>
          </th>
          <th className="package-builder-page_previous-packages_table_status">
            <strong>{t('common.status')}</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        {previousPackages && previousPackages.length > 0
          ? previousPackages.map((previousPackage, idx) => {
            return (
              <PreviousPackageRow
                key={`package_table_${previousPackage.id}`}
                cypressId={`previous-package-row-${idx + 1}`}
                previousPackage={previousPackage}
                highlightedPackages={highlightedPackages}
                handleSelect={handleSelect}
                utilityModeActive={utilityModeActive}
                setUtilityModeActive={setUtilityModeActive}
                setHighlightedPackages={setHighlightedPackages}
                previousPackageStats={previousPackageStats}
              />
            )
          })
          : null}
      </tbody>
    </Table>
  )
}

PreviousPackagesList.propTypes = {
  previousPackages: PropTypes.arrayOf(object),
  highlightedPackages: PropTypes.arrayOf(object),
  handleSelect: PropTypes.func,
  utilityModeActive: PropTypes.bool,
  setUtilityModeActive: PropTypes.func,
  previousPackageStats: PropTypes.object,
  setHighlightedPackages: PropTypes.func,
}

export default PreviousPackagesList

import React from 'react'
import PropTypes from 'prop-types'

/**
 * Empty state view.
 * @Tags(shared, presentational)
 */
const EmptyView = ({ children }) => {
  return <div className={'alert alert-secondary'}>{typeof children === 'string' ? <p>{children}</p> : children}</div>
}

EmptyView.propTypes = {
  /**
   * HTML lub string content
   */
  children: PropTypes.node.isRequired,
  danger: PropTypes.bool,
}

export default EmptyView

import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { LOGOUT } from 'reducers/user'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { MAIN_NAV, MAIN_NAV_BOTTOM, MAIN_NAV_MIDDLE } from '../../utils/data'
import { LogoWhiteIcon, ExpandedLogoWhiteIcon } from '../../images'
import { LayoutContext } from '../../App'
import MainNavModalItem from './MainNavModalItem'
import Badge from './Badge.jsx'
import { NotificationContext } from 'components/Notifications/notifications'

import help from 'images/navbar/app_help.svg'

import i18n from 'i18n'
import { hasPermission } from 'hooks/useHasPermission'

const ToggleSidebarBtn = () => (
  <LayoutContext.Consumer>
    {({ sidebarExpanded, setSidebarExpanded }) => (
      <button
        className="position-absolute bg-white shadow-sm rounded-circle d-flex justify-content-center align-items-center"
        style={{
          top: '4rem',
          left: sidebarExpanded ? '14.3rem' : '4.2rem',
          height: '24px',
          width: '24px',
          zIndex: 300,
        }}
        onClick={() => setSidebarExpanded(!sidebarExpanded)}
      >
        <i
          style={{
            marginRight: sidebarExpanded ? '0.125rem' : '-0.125rem',
            fontSize: '0.8rem',
            fontWeight: 'bold',
            color: '#021633',
          }}
          className={`fas fa-chevron-${sidebarExpanded ? 'left' : 'right'}`}
        ></i>
      </button>
    )}
  </LayoutContext.Consumer>
)

function NotificationsNavItem({ item, layout }) {
  const { t } = useTranslation()
  const notificationState = useContext(NotificationContext)

  const badgeText = notificationState.stats?.unread
    ? Math.min(99, notificationState.stats?.unread)
    : null

  return (
    <NavLinkItem item={item} t={t}>
      {layout.sidebarExpanded ? (
        <div
          className={`d-flex justify-content-start ${item.flexAlignClass ? item.flexAlignClass : 'align-items-center'}`}
        >
          <Badge text={badgeText} style={{ ...item.style }}>
            <span style={{ marginTop: 2 }} title={t(`Menu.${item.key}`)}>
              {item.icon}
            </span>
          </Badge>
          <h5 className="main-nav__link__text">{t(`Menu.${item.key}`)}</h5>
        </div>
      ) : (
        <Badge text={badgeText} classNames="ml-auto mr-auto">
          <span
            style={{ ...item.style, marginTop: 2, width: 22, marginRight: 0 }}
            className={'d-flex justify-content-center w-100'}
            title={t(`Menu.${item.key}`)}
          >
            {item.icon}
          </span>
        </Badge>
      )}
    </NavLinkItem>
  )
}

NotificationsNavItem.propTypes = {
  item: PropTypes.object.isRequired,
  layout: PropTypes.object.isRequired,
}

const NavLinkItem = ({ item, t, children }) => {
  return (
    <li key={item.key} id={`main-nav-item-${item.key}`} className="main-nav__item white-icon mb-0">
      {item.path ? (
        <NavLink
          to={item.path}
          onClick={item.onClick ?? null}
          exact={item.path === '/' ? true : false}
          title={item.title}
          className="main-nav__link"
          activeClassName="main-nav__link--active"
          aria-label={t(`shared.menu.${item.key}`)}
        >
          {children}
        </NavLink>
      ) : (
        <button
          onClick={item.onClick}
          title={item.title}
          className="main-nav__link w-100"
          aria-label={t(`shared.menu.${item.key}`)}
        >
          {children}
        </button>
      )}
    </li>
  )
}

NavLinkItem.propTypes = {
  item: PropTypes.object,
  t: PropTypes.func,
  children: PropTypes.node.isRequired,
}

/**
 * Main navigation for desktop.
 * @Tags( shared, desktop, presentational )
 */
const MainNav = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const layout = useContext(LayoutContext)

  const displayNavBarItem = (item) => {
    if (item?.navType === 'modal') {
      return <MainNavModalItem key={item.key} item={item} layout={layout} t={t} />
    }

    if (item.key === 'notifications') {
      return <NotificationsNavItem key={item.key} item={item} layout={layout} />
    }

    return (
      <NavLinkItem key={item.key} item={item} t={t}>
        {layout.sidebarExpanded ? (
          <div
            className={`d-flex justify-content-start ${item.flexAlignClass ? item.flexAlignClass : 'align-items-center'}`}
          >
            <span style={{ ...item.style }} title={t(`Menu.${item.key}`)}>
              {item.icon}
            </span>
            <h5 className="main-nav__link__text">{t(`Menu.${item.key}`)}</h5>
          </div>
        ) : (
          <span className={'d-flex justify-content-center w-100'} title={t(`Menu.${item.key}`)}>
            {item.icon}
          </span>
        )}
      </NavLinkItem>
    )
  }

  return (
    <nav className={`main-nav main-nav-${layout.sidebarExpanded ? 'expanded' : 'collapsed'}`}>
      <ul role="navigation">
        <li className="main-nav__item main-nav__logo">
          <NavLink
            to="/"
            className={`main-nav__link ${layout.sidebarExpanded ? 'main-nav__link_expanded' : ''} brand-logo`}
          >
            <>
              <ExpandedLogoWhiteIcon
                className={layout.sidebarExpanded ? 'pl-3' : 'd-none'}
                height="31"
                alt="fd"
              />
              <LogoWhiteIcon
                className={
                  layout.sidebarExpanded ? 'd-none' : 'd-flex justify-content-center w-100'
                }
                height="32"
                alt="fd"
              />
            </>
          </NavLink>
        </li>

        {MAIN_NAV.map((item) =>
          hasPermission(item.permissionName) ? displayNavBarItem(item) : null,
        )}

        <hr className="my-4 mx-2 bg-white" />

        {MAIN_NAV_MIDDLE.map((item) => displayNavBarItem(item))}

        <div className="mt-auto mb-3">
          <hr className="my-4 mx-2 bg-white" />

          <li className="main-nav__item white-icon mb-0">
            <a
              href={`https://support.future-demand.com/${i18n.language === 'en' ? '' : `${i18n.language}/`}`}
              target="_blank"
              rel="noopener noreferrer"
              className="main-nav__link"
              aria-label={t('shared.menu.support')}
            >
              {layout.sidebarExpanded ? (
                <div className="d-flex justify-content-start align-items-center">
                  <span title={t('shared.menu.support')}>
                    <img
                      style={{
                        width: '1.6rem',
                        marginLeft: -3,
                        marginRight: layout.sidebarExpanded ? 21 : 0,
                      }}
                      src={help}
                      alt={t('shared.menu.support')}
                    />
                  </span>
                  <h5 className="main-nav__link__text">{t('shared.menu.support')}</h5>
                </div>
              ) : (
                <span
                  className="d-flex justify-content-center w-100"
                  title={t('shared.menu.support')}
                >
                  <img
                    style={{
                      width: '1.6rem',
                      marginLeft: -3,
                      marginRight: layout.sidebarExpanded ? 21 : 0,
                    }}
                    src={help}
                    alt={t('shared.menu.support')}
                  />
                  <h5
                    style={{ fontSize: '1.1rem' }}
                    className={`text-white mb-0 ${layout.sidebarExpanded ? 'd-block' : 'd-none'}`}
                  >
                    {t('shared.menu.support')}
                  </h5>
                </span>
              )}
            </a>
          </li>

          {MAIN_NAV_BOTTOM.map((item) =>
            displayNavBarItem(
              item.key === 'logout'
                ?
                {
                  ...item,
                  style: { ...item.style, marginLeft: layout.sidebarExpanded ? 3 : 0 },
                  onClick: () => dispatch({ type: LOGOUT }),
                }
                : item,
            ),
          )}
        </div>
      </ul>
      <ToggleSidebarBtn />
    </nav>
  )
}

MainNav.propTypes = {}

MainNav.defaultProps = {}

export default MainNav

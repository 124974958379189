import { NavLink } from 'react-router-dom'
import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { MOBILE_NAV, MOBILE_NAVE_BOTTOM, MOBILE_NAVE_MIDDLE } from '../../utils/data'

import help from 'images/navbar/app_help.svg'
import { NotificationContext } from 'components/Notifications/notifications'
import Badge from './Badge'
import { LOGOUT } from 'reducers/user'
import { hasPermission } from 'hooks/useHasPermission'

function NotificationsNavItem({ item }) {
  const { t } = useTranslation()
  const notificationState = useContext(NotificationContext)

  const badgeText = notificationState.stats?.unread
    ? Math.min(99, notificationState.stats?.unread)
    : null

  return (
    <li key={item.key} id={`mobile-nav-item-${item.key}`}>
      <NavLink
        to={item.path}
        exact={item.path === '/' ? true : false}
        className={`mobile-nav__link ${item.icon}`}
        activeClassName="mobile-nav__link--active"
      >
        <span className="nav-icon white-icon" style={{ ...item.style }}>
          <Badge text={badgeText} style={{ ...item.style }}>
            <span style={{ marginTop: 2 }} title={t(`Menu.${item.key}`)}>
              {item.icon}
            </span>
          </Badge>
        </span>
        <span className="nav-icon--active" title={t(`Menu.${item.key}`)} style={{ ...item.style }}>
          <Badge text={badgeText} style={{ ...item.style }}>
            {item.icon}
          </Badge>
        </span>
        <span
          style={{ marginLeft: 12, fontSize: 16, marginTop: 2 }}
          className="mobile-nav__link__label"
        >
          {t(`shared.menu.${item.key}`)}
        </span>
      </NavLink>
    </li>
  )
}

NotificationsNavItem.propTypes = {
  item: PropTypes.object.isRequired,
}

/**
 * Main navigation for mobile.
 * @Tags( layout, shared, mobile, presentational )
 */
const MobileNav = () => {
  const { t } = useTranslation()
  const [menuActive, setMenuActive] = useState(false)
  const dispatch = useDispatch()

  const toggleMenu = () => {
    function disableScroll() {
      document.body.style.overflowY = 'hidden'
      document.body.style.top = `-${window.scrollY}px`
    }

    function enableScroll() {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      document.body.style.overflowY = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }

    if (menuActive === false) {
      disableScroll()
    } else {
      enableScroll()
    }
    setMenuActive(!menuActive)
  }

  return (
    <>
      <nav id="mobile-navbar" className={`mobile-nav ${menuActive ? 'mobile-nav--active' : ''}`}>
        <div className="mobile-nav__content" onClick={() => toggleMenu()}>
          <ul role="navigation">
            {MOBILE_NAV.map((item) => {
              const hasAccess = hasPermission(item.permissionName)
              return hasAccess ? (
                <li key={item.key} id={`mobile-nav-item-${item.key}`}>
                  <NavLink
                    to={item.path}
                    exact={item.path === '/' ? true : false}
                    className={`mobile-nav__link ${item.icon}`}
                    activeClassName="mobile-nav__link--active"
                  >
                    <span className="nav-icon white-icon" style={{ ...item.style }}>
                      {item.icon}
                    </span>
                    <span className="nav-icon--active" style={{ ...item.style }}>
                      {item.icon}
                    </span>
                    <span
                      style={{ marginLeft: 12, fontSize: 16, marginTop: 2 }}
                      className="mobile-nav__link__label"
                    >
                      {t(`shared.menu.${item.key}`)}
                    </span>
                    {item.isPreview ? (
                      <span
                        style={{ fontSize: 10, marginLeft: 8, height: 18, marginTop: 0 }}
                        className="preview-badge"
                      >
                        {t('common.earlyPreview')}
                      </span>
                    ) : null}
                  </NavLink>
                </li>
              ) : null
            })}
            <hr className="my-4 mx-2 bg-white"></hr>
            {MOBILE_NAVE_MIDDLE.map((item) =>
              hasPermission(item.permissionName) ? (
                item.key === 'notifications' ? (
                  <NotificationsNavItem key={item.key} item={item} />
                ) : (
                  <li key={item.key} id={`mobile-nav-item-${item.key}`}>
                    <NavLink
                      to={item.path}
                      exact={item.path === '/' ? true : false}
                      className={`mobile-nav__link ${item.icon}`}
                      activeClassName="mobile-nav__link--active"
                    >
                      <span className="nav-icon white-icon" style={{ ...item.style }}>
                        {item.icon}
                      </span>
                      <span className="nav-icon--active" style={{ ...item.style }}>
                        {item.icon}
                      </span>
                      <span
                        style={{ marginLeft: 12, fontSize: 16, marginTop: 2 }}
                        className="mobile-nav__link__label"
                      >
                        {t(`shared.menu.${item.key}`)}
                      </span>
                      {item.isPreview ? (
                        <span
                          style={{ fontSize: 10, marginLeft: 8, height: 18, marginTop: 0 }}
                          className="preview-badge"
                        >
                          {t('common.earlyPreview')}
                        </span>
                      ) : null}
                    </NavLink>
                  </li>
                )
              ) : null,
            )}
            <hr className="my-4 mx-2 bg-white"></hr>
            <li id="mobile-nav-item-help">
              <a
                href="https://support.future-demand.com"
                target="_blank"
                rel="noopener noreferrer"
                className="mobile-nav__link"
                aria-label={t('shared.menu.support')}
              >
                <span className="nav-icon white-icon">
                  <img style={{ width: '1.4rem' }} src={help} alt={t('shared.menu.support')} />
                </span>
                <span
                  style={{ marginLeft: 12, fontSize: 16, marginTop: 2 }}
                  className="mobile-nav__link__label"
                >
                  {t('shared.menu.support')}
                </span>
              </a>
            </li>
            {MOBILE_NAVE_BOTTOM.map((item) =>
              item.key !== 'logout' ? (
                <li key={item.key} id={`mobile-nav-item-${item.key}`}>
                  <NavLink
                    to={item.path}
                    exact={item.path === '/' ? true : false}
                    className={`mobile-nav__link ${item.icon}`}
                    activeClassName="mobile-nav__link--active"
                  >
                    <span className="nav-icon white-icon" style={{ ...item.style }}>
                      {item.icon}
                    </span>
                    <span className="nav-icon--active" style={{ ...item.style }}>
                      {item.icon}
                    </span>
                    <span
                      style={{ marginLeft: 12, fontSize: 16, marginTop: 2 }}
                      className="mobile-nav__link__label"
                    >
                      {t(`shared.menu.${item.key}`)}
                    </span>
                  </NavLink>
                </li>
              ) : (
                <li key={item.key} id={`mobile-nav-item-${item.key}`}>
                  <button
                    onClick={() => dispatch({ type: LOGOUT })}
                    title={item.title}
                    className="mobile-nav__link w-100"
                    aria-label={t(`shared.menu.${item.key}`)}
                  >
                    <span className="nav-icon white-icon" style={{ ...item.style }}>
                      {item.icon}
                    </span>
                    <span
                      style={{ marginLeft: 10, fontSize: 16, marginTop: 2 }}
                      className="mobile-nav__link__label"
                    >
                      {t('Menu.logout')}
                    </span>
                  </button>
                </li>
              ),
            )}
          </ul>
          <div className="mobile-nav__menuIcon-wrapper">
            <div className="mobile-nav__menuIcon">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        {/* {newNotifications > 0 && isFeatureFlagOn(features, SHOW_NOTIFICATION)
            && <Link className="mobile-nav__notifications" to="/messages">
              {t('shared.user.message', {count: newNotifications})}
            </Link>} */}
        <div className="mobile-nav__bg" onClick={() => toggleMenu()}></div>
      </nav>
    </>
  )
}

MobileNav.propTypes = {}

MobileNav.defaultProps = {}

export default MobileNav

import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'

import { SalesEditor } from 'components/Event/SalesEdit'
import Layout from 'components/Layout'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { fetchEvent } from 'api/events'
import { initStateObject, objectReducer } from 'reducers/default'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import { useToast } from 'components/Shared/AppToast/ToastProvider'
import { ClockIcon, PinIcon, VenueIcon } from 'images'
import { formatEventSubheadlineLocation } from 'utils/helpers'

const UpdateSales = ({ match }) => {
  const { mode, eventId } = match.params
  const { t } = useTranslation()
  const [event, dispatch] = useReducer(objectReducer, initStateObject)
  const history = useHistory()
  const { addNotification } = useToast()

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 991) {
        history.push(`/events?event-id=${eventId}&drawer-open=true&update-sales=${mode}`)
      } else history.push(`/events/${eventId}/update-sales/${mode}`)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [history, eventId, mode])

  useEffect(() => {
    fetchEvent(dispatch, eventId)
  }, [eventId])

  //hides the mobile-nav bar when viewing update sales page
  useEffect(() => {
    const mobileNav = document.getElementById('mobile-navbar')
    if (mobileNav?.style) {
      mobileNav.style.display = 'none'
    }

    return () => {
      const mobileNav = document.getElementById('mobile-navbar')
      if (mobileNav?.style) {
        mobileNav.style.display = 'block'
      }
    }
  }, [])

  return (
    <Layout
      loggedIn
      title={t('Event.salesEdit.title')}
      mobileTopBar={{
        back: true,
        centerTitle: t('Event.salesEdit.title'),
        addHistoryRoute: `/events/${eventId}`,
        historyCallback: () => {},
      }}
    >
      <Helmet bodyAttributes={{ class: 'simulation-page events-insights-page' }} />
      <main className="no-bottom-nav">
        <div className="d-flex flex-column p-3 gap-3">
          <div className="sales-edit_selector">
            <div className="sales-edit_wrapper_event-title">{event.content.title}</div>
            <div className="sales-edit_wrapper_event-date-venue">
              <span>
                <ClockIcon height={12} width={17} />
              </span>
              <span>{moment(event.content.date).format('YY.MM.DD HH:mm')}</span>
            </div>
            <div className="sales-edit_wrapper_event-date-venue">
              <span>
                <PinIcon height={12} width={17} />
              </span>
              <span>
                {formatEventSubheadlineLocation({
                  city: event.content.city,
                })}
              </span>
            </div>
            <div className="sales-edit_wrapper_event-date-venue">
              <span>
                <VenueIcon height={12} width={17} />
              </span>
              <span>
                {formatEventSubheadlineLocation({
                  hall: event.content.hall_name,
                  venue: event.content.venue_name,
                })}
              </span>
            </div>
          </div>
          <SalesEditor
            mode={mode.toUpperCase()}
            eventId={eventId}
            onSuccess={() => {
              history.replace(`/events/${eventId}`)
              addNotification({
                message: t('Toasts.Sales.salesUploaded.message'),
                description: t('Toasts.Sales.salesUploaded.description'),
              })
            }}
            onClose={() => history.replace(`/events/${eventId}`)}
          />
        </div>
      </main>
    </Layout>
  )
}

UpdateSales.propTypes = {
  match: PropTypes.object,
}

export default UpdateSales

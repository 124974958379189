import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import PropTypes, { string } from 'prop-types'
import { useHistory } from 'react-router-dom'
import { orderBy } from 'lodash'

import { UPDATE_PARTNER } from 'reducers/user'
import { fetchProfileGroups } from 'api/auth'
import { arrayReducer, initStateArray } from 'reducers/default'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import { useSelector } from 'react-redux'
import { fetchPartnerDetails } from 'api/partner'

const PartnerSelect = ({ cognitoGroups, dispatch, setSelectedPartner }) => {
  const history = useHistory()
  const multiPartnerDisplay = useSelector((state) => state.user).multi_partner_view
  const defaultGroups = useMemo(() => {
    return cognitoGroups.map((group) => {
      return { [multiPartnerDisplay]: group, id: group }
    })
  }, [cognitoGroups, multiPartnerDisplay])

  const [partners, dispatchPartners] = useReducer(arrayReducer, initStateArray)

  useEffect(() => {
    fetchProfileGroups(dispatchPartners, defaultGroups)
  }, [defaultGroups])

  const selectPartner = useCallback((partner) => {
    setSelectedPartner(partner)
    localStorage.setItem('clientId', partner)
    fetchPartnerDetails(() => {})
    dispatch({ type: UPDATE_PARTNER, payload: partner })
  }, [setSelectedPartner, dispatch])

  useEffect(() => {
    if (partners.items?.length === 1) {
      selectPartner(partners.items[0].id)
      history.push('/')
    }
  }, [partners.items, selectPartner, history])

  return (
    <div className="partner-select_wrapper">
      {partners.loading && <LoadingSpinner className="p-3" />}
      {partners.items?.length > 1 &&
        orderBy(partners.items, multiPartnerDisplay).map((partner) => {
          if (partner[multiPartnerDisplay])
            return (
              <div
                key={partner[multiPartnerDisplay]}
                className="partner-select_item"
                onClick={() => selectPartner(partner.id)}
              >
                <span>{partner[multiPartnerDisplay]}</span>
                <i className="fas fa-chevron-right" />
              </div>
            )
          return null
        })}
    </div>
  )
}

PartnerSelect.propTypes = {
  cognitoGroups: PropTypes.arrayOf(string),
  setSelectedPartner: PropTypes.func,
  dispatch: PropTypes.func,
}

export default PartnerSelect

import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import HowItWorks from 'components/Shared/HowItWorks'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'

/**
 * Set campaign form with dropzone.
 * @Tags( simulation )
 * @Inputs( event name, date )
 */
const SimulationFormHeader = ({ eventCategory }) => {
  const { t } = useTranslation()
  const hasPrismaAccess = hasPrismaAccessTier()

  const instructionsList = [t('Simulation.description.1'), t('Simulation.description.3')]
  if (!hasPrismaAccess || (eventCategory && eventCategory !== 'product')) {
    instructionsList.splice(1, 0, t('Simulation.description.2'))
  }

  return (
    <header className="simulation-form__header">
      <div className="pb-3 flex-grow-1">
        <HowItWorks instructionsList={instructionsList} />
      </div>
    </header>
  )
}

SimulationFormHeader.propTypes = {
  eventCategory: PropTypes.string.isRequired,
}

export default SimulationFormHeader

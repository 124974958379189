import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { SecondaryButton, TransparentBgButton } from '../../Shared/Button'

export default function CancelSalesEditModalContent({ onCancel, onConfirm }) {
  const { t } = useTranslation()

  return (
    <div className="cancel-sales-edit">
      <div className="cancel-sales-edit_content">
        <h1>{t('Event.salesEdit.cancel.cta')}</h1>
        <p>{t('Event.salesEdit.cancel.body')}</p>
      </div>
      <div className="cancel-sales-edit_footer">
        <TransparentBgButton text={t('common.dontCancel')} onClick={onCancel} color="navy" />
        <SecondaryButton className="bt" text={t('common.yesCancel')} fullWidth={false} onClick={onConfirm} />
      </div>
    </div>
  )
}

CancelSalesEditModalContent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

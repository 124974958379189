import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { SimpleSuccessModal } from '../../Shared/Modal'
import { useTranslation } from 'react-i18next'

export default function EventList({ events = [], rowRender }) {
  const [showSalesEditSuccessModal, setShowSalesEditSuccessModal] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      {events?.length > 0
        ? events.map((event) => {
          return rowRender(event)
        })
        : null}
      {showSalesEditSuccessModal && (
        <SimpleSuccessModal
          closeCallBack={() => setShowSalesEditSuccessModal(false)}
          onClickOutside={() => setShowSalesEditSuccessModal(false)}
          text={t('Event.salesEdit.success')}
        />
      )}
    </>
  )
}

EventList.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowRender: PropTypes.func.isRequired,
}

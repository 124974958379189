import React from 'react'
import PropTypes from 'prop-types'

function CampaignRuntime({ startDate, endDate }) {
  return (
    <div className="running-time">
      {startDate && endDate ? (
        <>
          <time dateTime={startDate}>
            {' '}
            {/* FIXME: Inconsistent time parsing & formatting? */}
            {startDate}
          </time>
          &nbsp;-
          <time dateTime={endDate}>
            {' '}
            {/* FIXME: Inconsistent time parsing & formatting? */}
            {endDate}
          </time>
        </>
      ) : null}
    </div>
  )
}

CampaignRuntime.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
}

export default CampaignRuntime

import { api, makeApiRequest } from './_init'
import { LOADING, LOADING_PAGE, UPDATE, OVERWRITE, FAIL } from '../reducers/default'
import { augmentIncomingMedia, rankCampaignList } from './models'
import { TARGETING_MODES } from 'components/Recommendations/Campaign/Edit/CampaignForm/Targeting/targeting'
import { isObjectEmpty } from 'utils/helpers'

const formatApiCampaign = (campaign) => {
  const latestCampaignSetup = campaign.latest_campaign_setup ?? {}
  if (Array.isArray(latestCampaignSetup.creative.media)) {
    latestCampaignSetup.creative.media = augmentIncomingMedia(latestCampaignSetup.creative.media)
  }
  latestCampaignSetup.creative = {
    ...latestCampaignSetup.creative,
    excluded_variations: latestCampaignSetup.creative.excluded_variations ?? [],
  }

  if (!latestCampaignSetup.targeting.targeting_mode) {
    // campaign was already published without targeting
    latestCampaignSetup.targeting = [
      {
        tc: campaign.tc,
        tc_run_id: Number(campaign.tc_run_id),
        targetingMode: TARGETING_MODES.fdTargeting,
      },
    ]
  } else {
    const incomingTargeting = latestCampaignSetup.targeting
    if (incomingTargeting) {
      latestCampaignSetup.targeting.targetingMode = incomingTargeting.targeting_mode

      if (!latestCampaignSetup.targeting.targetingMode) {
        latestCampaignSetup.targeting.targetingMode = !isObjectEmpty(incomingTargeting['postal-codes']?.countries)
          ? TARGETING_MODES.postalCodes
          : !isObjectEmpty(incomingTargeting['range-locations']?.included) ||
              !isObjectEmpty(incomingTargeting['range-locations']?.excluded)
            ? TARGETING_MODES.rangeLocations
            : TARGETING_MODES.fdTargeting
      }

      latestCampaignSetup.targeting.postalCodes = latestCampaignSetup.targeting['postal-codes']
      latestCampaignSetup.targeting.rangeLocations = latestCampaignSetup.targeting['range-locations']

      latestCampaignSetup.targeting = [latestCampaignSetup.targeting]
    }
  }

  return {
    alias: campaign.alias,
    id: campaign.id,
    cid: `${campaign.tc_run_id}_${campaign.eid}_${campaign.tc.replace(/\s+/g, '-')}`,
    tc_run_id: Number(campaign.tc_run_id),
    eid: campaign.eid,
    channel: campaign.channel,
    cr_start_days_upfront: campaign.cr_start_days_upfront,
    cr_end_days_upfront: campaign.cr_end_days_upfront,
    event: campaign.event
      ? {
        start_date_time: campaign.event.start_date_time,
      }
      : {},
    run_time: campaign.run_time,
    tc_title: campaign.tc,
    tc: campaign.tc,
    tc_size: campaign.tc_size,
    ad_budget: campaign.budget,
    currency: campaign.currency,
    buying_power: campaign.buying_power,
    attractiveness: campaign.attractiveness,
    potential: campaign.potential,
    rank: campaign.rank,
    set_up_date: campaign.set_up_date,
    status: campaign.set_up_date ? 'running' : campaign.status,
    credits: campaign.credits,
    fb_adset_end_time: campaign.fb_adset_end_time,
    published: campaign.published,
    initial_set_up_date: campaign.initial_set_up_date,
    creative: campaign.creative
      ? {
        body: campaign.creative.body,
        headline: campaign.creative.headline,
        media: augmentIncomingMedia(campaign.creative.media),
        destination_url: campaign.creative.destination_url,
        description: campaign.creative.description,
        integrations: campaign.creative.integrations ? campaign.creative.integrations : [],
      }
      : {},
    creativeSuggestion: campaign.suggested_creative
      ? {
        body: campaign.suggested_creative.body ? campaign.suggested_creative.body : [],
        headline: campaign.suggested_creative.headline ? campaign.suggested_creative.headline : [],
        media: campaign.suggested_creative.media
          ? campaign.suggested_creative.media.map((media) => media.description)
          : [],
        description: campaign.suggested_creative.description,
      }
      : {},
    latest_campaign_setup: latestCampaignSetup,
  }
}

export async function fetchCampaigns(dispatch, event_id, settings = {}, onlyActive = false, controller = null) {
  if (!event_id) {
    console.log('event id missing in fetch for fetchCampaigns')
    return
  }

  const { page = 1, page_limit = 50 } = settings
  dispatch({ type: page > 1 ? LOADING_PAGE : LOADING })
  try {
    const PAGE_LIMIT = page_limit > 0 ? page_limit : 50
    const params = {
      page: page,
      limit: PAGE_LIMIT,
    }

    const result = await api.get(`/events/${event_id}/campaigns`, { params: params, signal: controller?.signal })
    const response = {
      total: result.data.total,
      maxPage: Math.ceil(result.data.total / PAGE_LIMIT),
      page: page,
      items: rankCampaignList(result.data.items.map((campaign) => formatApiCampaign(campaign))),
    }

    // filters only running campaigns from unused ones
    if (onlyActive) {
      response.items = response.items.filter((campaign) => campaign.status === 'running')
    }

    dispatch({ type: page > 1 ? UPDATE : OVERWRITE, payload: response })
  } catch (error) {
    dispatch({ type: FAIL, payload: 'Error fetching data.' })
  }
}

export async function fetchCampaignCtasAsync(dispatch, goal, hasPixel, controller = null) {
  dispatch({ type: LOADING })
  try {
    const goalParts = goal.split('.')
    const result = await api.get('/setup_processes/call_to_action/', {
      params: { goal: goalParts[goalParts.length - 1], has_pixel: hasPixel },
      signal: controller?.signal,
    })
    dispatch({
      type: OVERWRITE,
      payload: {
        total: result.data?.length ?? 0,
        page: 0,
        maxPage: result.data?.length ?? 0,
        items: result.data,
      },
    })
  } catch (error) {
    dispatch({ type: FAIL, payload: 'common.errors.inServerResponse' })
  }
}

export async function campaignsSetup(
  dispatch,
  eventId,
  {
    tc,
    tc_run_id,
    headline,
    body,
    media,
    destination_url,
    description,
    call_to_action,
    audience_id,
    excluded_variations,
  },
) {
  dispatch({ type: LOADING })
  try {
    const result = await api.post('/campaigns_setup', {
      eid: eventId,
      tc,
      tc_run_id,
      audience_id,
      creatives: {
        headline,
        body,
        media,
        destination_url,
        description,
        call_to_action,
        excluded_variations,
      },
    })

    if (result.status === 204) {
      dispatch({ type: UPDATE, payload: 'Set up' })
    } else {
      dispatch({ type: FAIL, payload: 'common.errors.inServerResponse' })
    }
  } catch (error) {
    dispatch({ type: FAIL })
  }
}

export const getUrlMetaTags = async (url) => {
  try {
    const result = await api.get(`/campaigns/meta_tags?url=${url}`)
    if (result.status !== 200) {
      return { error: true, success: false, payload: 'common.errors.inServerResponse' }
    }

    const parsedPayload = await JSON.parse(result.data.tags)
    if (parsedPayload.error) {
      return { error: true, success: false, payload: 'Website blocks crawlers or is down', message: parsedPayload }
    }

    return {
      error: false,
      success: true,
      tags: JSON.parse(result.data.tags),
    }
  } catch (error) {
    console.log('error in catch block ', error)
    return { error: true, success: false, payload: 'common.errors.inServerResponse' }
  }
}

const campaignSetupApi = {
  updateAsync: async (eventId, payload) => {
    return await makeApiRequest(api, 'post', '/campaigns_setup', {
      eid: eventId,
      ...payload,
    })
  },
}

export default campaignSetupApi

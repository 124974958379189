import * as XLSX from 'xlsx'

export const formatSheetFromArrays = (csvObject) => {
  const errorList = []

  const eventsTotal = []
  if (!csvObject.Events?.length) {
    throw new Error('File is empty or invalid.')
  }

  for (let i = 1; i < csvObject.Events.length; i++) {
    let ROW_ERROR = false

    const errorObject = {
      event: {
        message: '',
      },

      lineup: {
        message: [],
      },

      setlist: {
        message: [],
      },
    }

    const lineupArray = []
    const setlistArray = []

    if (!csvObject.Events[i][1]) {
      errorObject.event.message += `${csvObject.Events[i][0]} missing title `
      ROW_ERROR = true
    }
    if (!csvObject.Events[i][2]) {
      errorObject.event.message += `${csvObject.Events[i][0]} missing category `
      ROW_ERROR = true
    }
    if (!csvObject.Events[i][5]) {
      errorObject.event.message += `${csvObject.Events[i][0]} missing city `
      ROW_ERROR = true
    }
    if (!csvObject.Events[i][6]) {
      errorObject.event.message += `${csvObject.Events[i][0]} missing venue `
      ROW_ERROR = true
    }

    csvObject['Lineup Artists'].forEach((eachLineupItem) => {
      if (csvObject.Events[i][0] === eachLineupItem[0]) {
        const artistName = eachLineupItem[1]
        const role = eachLineupItem[2]
        if (!artistName || !role) {
          errorObject.lineup.message.push(
            `Event ${csvObject.Events[i][0]} missing lineup information artist ${artistName} with the role of ${role}`,
          )
          ROW_ERROR = true
        } else {
          lineupArray.push({
            artistName: artistName,
            role: role,
          })
        }
      }
    })

    csvObject['Setlist Works'].forEach((eachSetListItem) => {
      if (csvObject.Events[i][0] === eachSetListItem[0]) {
        const composer = eachSetListItem[1]
        const title = eachSetListItem[2]

        if (!composer || !title) {
          errorObject.lineup.message.push(
            `Event ${csvObject.Events[i][0]} missing lineup information composer ${composer} with the work of ${title}`,
          )

          ROW_ERROR = true
        } else {
          setlistArray.push({
            composer: composer,
            title: title,
          })
        }
      }
    })

    if (ROW_ERROR) {
      errorList.push(errorObject)
      continue
    }

    const id = csvObject.Events[i][0]
    const title = csvObject.Events[i][1]
    const category = csvObject.Events[i][2]
    const price = csvObject.Events[i][5]
    const city = csvObject.Events[i][6]
    const venue = csvObject.Events[i][7]
    const hall = csvObject.Events[i][8]
    const capacity = csvObject.Events[i][9]

    const date = (id.match(/\d{2}\.\d{2}\.\d{2}/) || id.match(/\d{2}\/\d{2}\/\d{2}/))[0]
    const time = id.match(/\d{2}:\d{2}/)[0]

    const eventObject = {
      id,
      title,
      category: category,
      date,
      time,
      price,
      city,
      venue,
      hall,
      capacity,

      lineup: lineupArray,
      setlist: setlistArray,
    }

    eventsTotal.push(eventObject)
  }

  return {
    errors: errorList,
    events: eventsTotal,
  }
}

export function delocalizeSheetAndHeaders(workbook, filename) {
  const guessLocale = () => {
    if (workbook.SheetNames.includes('Anweisungen') || workbook.SheetNames.includes('Kampagne')) {
      return 'de'
    }
    if (workbook.SheetNames.includes('Événements') || workbook.SheetNames.includes('Campagnes')) {
      return 'fr'
    }
    return 'en'
  }

  if (!workbook.SheetNames.length) {
    throw new Error('Workbook is empty or invalid.')
  }

  const locale = guessLocale()

  console.debug('detected locale', locale)

  const sheetMapEn = {
    Events: 'Events',
    Campaign: 'Events',
    'Lineup Artists': 'Lineup Artists',
    'Setlist Works': 'Setlist Works',
  }

  const sheetMapFr = {
    Événements: 'Events',
    Campagnes: 'Events',
    Artistes: 'Lineup Artists',
    Programme: 'Setlist Works',
  }

  const sheetMapDe = {
    Veranstaltung: 'Events',
    Kampagne: 'Events',
    Künstler: 'Lineup Artists',
    Programm: 'Setlist Works',
  }

  const headerRanges = {
    Events: 'A1:K1',
    'Lineup Artists': 'A1:C1',
    'Setlist Works': 'A1:C1',
  }

  const headerMapEn = {
    Events: {
      'Event_Identifier': 'Event_Identifier',
      'Campaign_Identifier': 'Event_Identifier',
      'Event title*': 'Event title*',
      'Title*': 'Event title*',
      'Event description': 'Event description',
      'Campaign description': 'Event description',
      'Event category*': 'Event category*',
      'Campaign category*': 'Event category*',
      'Date*': 'Date*',
      'End Date*': 'Date*',
      'Time*': 'Time*',
      'Time': 'Time*',
      'Avg. price*': 'Avg. price*',
      'Avg. price': 'Avg. price*',
      'City*': 'City*',
      'City': 'City*',
      'Venue*': 'Venue*',
      'Venue': 'Venue*',
      'Hall*': 'Hall*',
      'Hall': 'Hall*',
      'Venue Capacity*': 'Venue Capacity*',
      'Venue Capacity': 'Venue Capacity*',
    },
    'Lineup Artists': {
      'Event Identifier': 'Event Identifier',
      'Campaign_Identifier': 'Event_Identifier',
      'Artist name': 'Artist name',
      'Role/Type': 'Role/Type',
    },
    'Setlist Works': {
      'Event Identifier': 'Event Identifier',
      'Campaign_Identifier': 'Event_Identifier',
      'Author/Composer': 'Author/Composer',
      'Work Title': 'Work Title',
    },
  }

  const headerMapFr = {
    Events: {
      'Événement Identificateur': 'Event_Identifier',
      'Campagnes Identificateur': 'Event_Identifier',
      "Titre de l'événement*": 'Event title*',
      'Titre*': 'Event title*',
      "Description de l'événement": 'Event description',
      'Description de la campagne': 'Event description',
      "Catégorie de l'événement*": 'Event category*',
      'Catégorie de la campagne*': 'Event category*',
      'Date*': 'Date*',
      "L'heure*": 'Time*',
      "L'heure": 'Time*',
      'Prix moyen*': 'Avg. price*',
      'Prix moyen': 'Avg. price*',
      'Ville*': 'City*',
      'Ville': 'City*',
      'Lieu*': 'Venue*',
      'Lieu': 'Venue*',
      'Salle*': 'Hall*',
      'Salle': 'Hall*',
      'Capacité de lieu*': 'Venue Capacity*',
      'Capacité de lieu': 'Venue Capacity*',
    },
    'Lineup Artists': {
      'Événement Identificateur': 'Event Identifier',
      'Campagnes Identificateur': 'Event_Identifier',
      "Nom de l'artiste": 'Artist name',
      Type: 'Role/Type',
    },
    'Setlist Works': {
      'Événement Identificateur': 'Event Identifier',
      'Campagnes Identificateur': 'Event_Identifier',
      Auteur: 'Author/Composer',
      'Titre de travail': 'Work Title',
    },
  }

  const headerMapDe = {
    Events: {
      'Veranstaltung Identifikator': 'Event_Identifier',
      'Kampagne Identifikator': 'Event_Identifier',
      'Veranstaltungstitel*': 'Event title*',
      'Titel*': 'Event title*',
      'Beschreibung der Veranstaltung': 'Event description',
      'Beschreibung der Kampagne': 'Event description',
      'Veranstaltungskategorie*': 'Event category*',
      'Kategorie der Kampagne*': 'Event category*',
      'Datum*': 'Date*',
      'Zeit*': 'Time*',
      'Zeit': 'Time*',
      'Durchschnittlicher Preis*': 'Avg. price*',
      'Durchschnittlicher Preis': 'Avg. price*',
      'Stadt*': 'City*',
      'Stadt': 'City*',
      'Veranstaltungsstätte*': 'Venue*',
      'Stätte': 'Venue*',
      'Saal*': 'Hall*',
      'Saal': 'Hall*',
      'Platzkapazität*': 'Venue Capacity*',
      'Platzkapazität': 'Venue Capacity*',
    },
    'Lineup Artists': {
      'Veranstaltung Identifikator': 'Event Identifier',
      'Kampagne Identifikator': 'Event_Identifier',
      'Name Künstler:in': 'Artist name',
      Typ: 'Role/Type',
    },
    'Setlist Works': {
      'Veranstaltung Identifikator': 'Event Identifier',
      'Kampagne Identifikator': 'Event_Identifier',
      'Author:in': 'Author/Composer',
      'Werk des/der Author:in': 'Work Title',
    },
  }

  const languageMaps = {
    'en': [sheetMapEn, headerMapEn],
    'fr': [sheetMapFr, headerMapFr],
    'de': [sheetMapDe, headerMapDe],
  }
  const [sheetMap, headerMap] = languageMaps[locale]
  console.log('sheetMap', languageMaps, sheetMap, headerMap, locale)

  for (const sheetName of workbook.SheetNames) {
    console.debug('-------------------')
    console.debug('sheetName', sheetName)
    const sheet = workbook.Sheets[sheetName]
    const stdSheetName = sheetMap[sheetName]
    if (!stdSheetName) {
      console.debug('sheetName not found in map', sheetName)
      // delete the sheet
      delete workbook.Sheets[sheetName]
      workbook.SheetNames = workbook.SheetNames.filter((name) => name !== sheetName)
      continue
    }

    console.debug('stdSheetName', stdSheetName)

    const headerRowCells = XLSX.utils.decode_range(headerRanges[stdSheetName])

    // rename first row columns
    const sheetHeaders = headerMap[stdSheetName]
    for (let i = headerRowCells.s.c; i <= headerRowCells.e.c; i++) {
      const cellAddress = XLSX.utils.encode_cell({ r: headerRowCells.s.r, c: i })
      console.debug('cellAddress', cellAddress  , sheet[cellAddress]?.v, sheetHeaders, stdSheetName, headerMap)
      if (sheetHeaders[sheet[cellAddress]?.v]) {
        console.debug('found', sheet[cellAddress].v, sheetHeaders[sheet[cellAddress].v])
        sheet[cellAddress].v = sheetHeaders[sheet[cellAddress].v]
      } else {
        console.debug('not found', sheet[cellAddress].v)
      }
    }

    // rename the sheet
    sheet.name = stdSheetName
    workbook.Sheets[stdSheetName] = sheet
    workbook.SheetNames = workbook.SheetNames.map((name) => (name === sheetName ? stdSheetName : name))
  }

  const u8 = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' })
  const parts = [u8] // `Blob` constructor expects this
  // const blob = new Blob(parts, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  // const url = URL.createObjectURL(blob)
  // const a = document.createElement('a')
  // a.href = url
  // a.download = filename
  // a.click()

  return {
    file: new File(parts, filename, {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
    newWb: workbook,
  }
}

import React from 'react'
import PropTypes from 'prop-types'

import merge from 'images/icons/merge.svg'

const UtilityButton = ({ isActive, canMerge, handleClick, selectAll, isAllSelected = false }) => {
  return (
    <nav daya-cy="utlity-buttons-component" className="package-builder-page_utility-buttons_wrapper hide-mobile">
      <button
        style={{ paddingRight: 0, paddingLeft: 4 }}
        className="package-builder-page_utility-buttons_btn-wrapper active"
      >
        <label style={{ marginTop: 16 }} className="package-builder-page_custom-checkbox">
          <input onChange={(e) => selectAll(e)} checked={isAllSelected} type="checkbox" />
          <span />
        </label>
      </button>
      <span className="divider">|</span>
      {isActive ? (
        <>
          <button
            title={'Merge selected packages'}
            disabled={!isActive && !canMerge}
            onClick={() => handleClick('MERGE')}
            className={`package-builder-page_utility-buttons_btn-wrapper ${isActive ? 'active' : ''}`}
          >
            <img
              style={{ height: 15 }}
              src={merge}
              alt="merge all"
              className={`package-builder-page_utility-buttons_icon ${isActive && canMerge ? 'selectable' : ''}`}
            />
          </button>
        </>
      ) : null}
    </nav>
  )
}

UtilityButton.propTypes = {
  isActive: PropTypes.bool,
  canMerge: PropTypes.bool,
  handleClick: PropTypes.func,
  selectAll: PropTypes.func,
  isAllSelected: PropTypes.bool,
}

export default UtilityButton

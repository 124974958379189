// A function which ensures that the border radius of chart columns is zero on the X-axis side,
// and has slightly curved edges on the other end
// whether the column was on the positive or negative side of the Y-axis
export const highchartsRadiusFunction = (Highcharts) => {
  Highcharts.wrap(Highcharts.seriesTypes.column.prototype, 'translate', function (proceed) {
    proceed.apply(this, [].slice.call(arguments, 1))

    const options = this.options
    const topMargin = options.topMargin || 0
    const bottomMargin = options.bottomMargin || 0

    Highcharts.each(this.points, function (point) {
      const w = point.shapeArgs.width
      const h = point.shapeArgs.height
      const x = point.shapeArgs.x
      const y = point.shapeArgs.y

      var radiusTopLeft = Highcharts.relativeLength(options.borderRadiusTopLeft || 0, w)
      var radiusTopRight = Highcharts.relativeLength(options.borderRadiusTopRight || 0, w)
      var radiusBottomRight = Highcharts.relativeLength(options.borderRadiusBottomRight || 0, w)
      var radiusBottomLeft = Highcharts.relativeLength(options.borderRadiusBottomLeft || 0, w)

      const maxR = Math.min(w, h) / 2

      radiusTopLeft = radiusTopLeft > maxR ? maxR : radiusTopLeft
      radiusTopRight = radiusTopRight > maxR ? maxR : radiusTopRight
      radiusBottomRight = radiusBottomRight > maxR ? maxR : radiusBottomRight
      radiusBottomLeft = radiusBottomLeft > maxR ? maxR : radiusBottomLeft

      point.shapeType = 'path'
      if (point.y < 0) {
        point.shapeArgs = {
          d: [
            'M',
            x,
            y + topMargin,
            'L',
            x + w,
            y + topMargin,
            'L',
            x + w,
            y + h - radiusTopRight,
            'C',
            x + w,
            y + h - radiusTopRight / 2,
            x + w - radiusTopRight / 2,
            y + h,
            x + w - radiusTopRight,
            y + h,
            'L',
            x + radiusTopLeft,
            y + h,
            'C',
            x + radiusTopLeft / 2,
            y + h,
            x,
            y + h - radiusTopLeft / 2,
            x,
            y + h - radiusTopLeft,
            'Z',
          ],
        }
      } else {
        point.shapeArgs = {
          d: [
            'M',
            x + radiusTopLeft,
            y + topMargin,
            'L',
            x + w - radiusTopRight,
            y + topMargin,
            'C',
            x + w - radiusTopRight / 2,
            y,
            x + w,
            y + radiusTopRight / 2,
            x + w,
            y + radiusTopRight,
            'L',
            x + w,
            y + h - radiusBottomRight,
            'C',
            x + w,
            y + h - radiusTopRight / 2,
            x + w - radiusTopRight / 2,
            y + h,
            x + w - radiusBottomRight,
            y + h + bottomMargin,
            'L',
            x + radiusBottomLeft,
            y + h + bottomMargin,
            'C',
            x + radiusBottomLeft / 2,
            y + h,
            x,
            y + h - radiusBottomLeft / 2,
            x,
            y + h - radiusBottomLeft,
            'L',
            x,
            y + radiusTopLeft,
            'C',
            x,
            y + radiusTopLeft / 2,
            x + radiusTopLeft / 2,
            y,
            x + radiusTopLeft,
            y,
            'Z',
          ],
        }
      }
    })
  })
}

import CustomRadio from '../Shared/CustomRadio'
import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { fetchEventCampaignsExpectedValue } from '../../api/events'
import { initStateObject, objectReducer } from '../../reducers/default'

const CampaignGoalRadio = ({ checked, goalTitle, budget, goal, onGoalSelected, eventId, disabled }) => {
  const { t } = useTranslation()
  const [campaignsExpectedValue, setCampaignsExpectedValue] = useReducer(objectReducer, initStateObject)

  useEffect(() => {
    const controller = new AbortController()
    fetchEventCampaignsExpectedValue(setCampaignsExpectedValue, eventId, goal, budget, controller)

    return () => controller.abort()
  }, [budget, eventId, goal])

  return (
    <div
      className={`event-header-goal ${disabled ? 'event-header-goal_disabled' : ''} rounded ${checked ? 'active' : ''}`}
      onClick={() => !disabled && onGoalSelected(goal)}
      title={`${goalTitle} ${t('Recommendations.campaignGoal.expectedResult')} - ${campaignsExpectedValue.message}`}
    >
      <CustomRadio
        className="event-header-goal"
        checked={checked}
        onChange={() => onGoalSelected(goal)}
        disabled={disabled}
      />
      <p className="title">{goalTitle}</p>
      {campaignsExpectedValue && (campaignsExpectedValue.loading || campaignsExpectedValue.error) && (
        <div className="expected-result-number"> - </div>
      )}
      {campaignsExpectedValue && !campaignsExpectedValue.loading && !campaignsExpectedValue.error && (
        <span className="expected-result-number">{campaignsExpectedValue.message}</span>
      )}
    </div>
  )
}

CampaignGoalRadio.propTypes = {
  disabled: PropTypes.bool.isRequired,
}

export default CampaignGoalRadio

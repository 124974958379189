import { useState, useEffect } from 'react'

function useRedirectOnResize(threshold) {
  const [viewWidth, setViewWidth] = useState(window.innerWidth)

  const handleResize = () => setViewWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const urlParams = new URL(window.location.href)
  const drawerOpen = urlParams.searchParams.get('drawer-open')

  return viewWidth < threshold && drawerOpen
}

export default useRedirectOnResize

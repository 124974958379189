import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { UploadFilesContext, UploadFilesDispatchContext } from 'components/Uploads/UploadFilesProvider'
import { actions } from 'components/Uploads/files'
import Modal from 'components/Shared/Modal'
import Uploads from 'pages/uploads'
import { useToast } from 'components/Shared/AppToast/ToastProvider'

const UploadModal = ({ setShowModal }) => {
  const { t } = useTranslation()
  const state = useContext(UploadFilesContext)
  const dispatch = useContext(UploadFilesDispatchContext)
  const { addNotification } = useToast()

  const onCloseModal = () => {
    setShowModal(false)
    dispatch({ type: actions.resetFilesAsync })
  }

  useEffect(() => {
    if (state?.success) {
      addNotification({
        message: t('Toasts.Dropzone.fileUploaded.message'),
        description: t('Toasts.Dropzone.fileUploaded.description'),
      })
      dispatch({ type: actions.resetFiles })
      setShowModal(false)
    }
  }, [state.success, addNotification, t, dispatch, setShowModal])

  return (
    <Modal
      title={t('Uploads.title')}
      fullWidth={true}
      hideHeader={true}
      offWhiteBg={true}
      contentClasses="w-60"
      closeCallback={() => onCloseModal()}
      onClickOutside={() => onCloseModal()}
      mainContent={<Uploads view="modal" />}
    />
  )
}

UploadModal.propTypes = {
  setShowModal: PropTypes.func,
}

export default UploadModal

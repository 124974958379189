import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import logo from '../../images/logos/primary_navy.svg'
import backToHomePage from '../../images/icons/back-to-home-page.svg'
import backToHomeDesktop from '../../images/icons/back-to-home-page-desktop.svg'
import { LOGOUT } from 'reducers/user'

/**
 * Header in the nav of Login, Forgot Password and Registration pages
 * @Tags( presentational )
 */
const Header = ({ backTo }) => {
  const dispatch = useDispatch()
  const user = useSelector((store) => store.user)

  const backButtonEnabled = !/^(\/$|\/login)/.test(window.location.pathname) || user.isLogged

  return (
    <header className="page-header">
      {backButtonEnabled && (
        <Link to={backTo} className="btn-back" onClick={() => dispatch({ type: LOGOUT })}>
          <img className="d-lg-none" src={backToHomePage} alt="" />
          <img className="d-none d-lg-block" src={backToHomeDesktop} alt="" />
        </Link>
      )}
      <Link to="/" className="brand-logo d-lg-none">
        <img src={logo} alt="brand logo" />
      </Link>
    </header>
  )
}

Header.propTypes = {
  /**
   * Path of back arrow link
   * */
  backTo: PropTypes.string.isRequired,
}

export default Header

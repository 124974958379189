import './wdyr'
import React, { Suspense, useState, createContext } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { createBrowserHistory } from 'history'
import { I18nextProvider } from 'react-i18next'
import { ErrorBoundary } from 'react-error-boundary'

import PrivateRoute from './components/Router/PrivateRoute'
import './styles/app.scss'

import Dashboard from './pages/Dashboard'
import Events from './pages/Events'
import Event from './pages/Event'
import EventEditor from './pages/EventEditor'
import PackageBuilder from './pages/PackageBuilder'
import PackageBuilderCreate from './pages/PackageBuilderCreate'
import PackageBuilderResults from './pages/PackageBuilderResults'
import Recommendations from './pages/Recommendations'
import Recommendation from './pages/Recommendation'
import Simulation from './pages/Simulation'
import Settings from './pages/Settings'
import Notifications from 'pages/Notifications/Notifications'
import Notification from 'pages/Notifications/Notification'
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import SetPassword from './pages/SetPassword'
import Registration from './pages/Registration'
import Error404 from './pages/Error404'
import UpdateDetailsPage from './pages/UpdateDetailsPage'
import AddMultipleEvents from './pages/AddMultipleEvents'
import { FacebookLoginCallback, ThirdPartyFBLogin } from 'pages/Meta'
import Uploads from 'pages/uploads'
import UpdateSales from 'pages/UpdateSales'
// import ErrorMaintenance from './pages/ErrorMaintenence'

import LoadingApp from './components/Shared/LoadingApp'
import ErrorFallback from './components/Shared/ErrorFallback'

import store from './store'
import i18n from './i18n'
import { fetchUserProfile } from './api/auth'
import constants from './constants'

import ScrollToTop from './components/Shared/ScrollToTop'
import { EventFilterProvider } from './components/Events/Filters'
import { UploadFilesProvider } from 'components/Uploads/UploadFilesProvider'
import { NotificationContextProvider } from 'components/Notifications/NotificationContextProvider.jsx'
import { ToastProvider } from 'components/Shared/AppToast/ToastProvider'
import ItcEventForm from 'pages/Recommendations/ItcEventForm'

import { hasPrismaAccessTier, Permissions } from 'hooks/useHasPermission'

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route)
const history = createBrowserHistory()

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    dsn: 'https://f06be94a9980497a99b3bb82a043d518@o4504121807994880.ingest.sentry.io/4504179666059264',
    integrations: [
      new BrowserTracing(),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    release: process.env.REACT_APP_SENTRY_RELEASE,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: constants.SENTRY_SAMPLING_RATE,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'production',
    initialScope: {
      user: {
        id: store.getState().user?.id,
        partnerId: store.getState().user?.partner_id,
      },
    },
  })
}

store.subscribe(() => {
  localStorage.setItem('reduxState', JSON.stringify(store.getState()))
})

export const LayoutContext = createContext({
  sidebarExpanded: false,
  setSidebarExpanded: () => {},
})

function Pages({ fetchAndSetProfile }) {
  return (
    <Switch>
      <PrivateRoute path="/" exact component={Dashboard} permissionName={Permissions.dashboard} />
      <PrivateRoute
        path="/events"
        exact={true}
        component={Events}
        permissionName={Permissions.lookout}
      />
      <PrivateRoute
        path="/events/:eventId"
        exact
        component={Event}
        permissionName={Permissions.lookout}
      />
      <PrivateRoute
        path="/events/:eventId/update-sales/:mode"
        exact={true}
        component={UpdateSales}
        permissionName={Permissions.sales}
      />
      <PrivateRoute
        path="/events/edit/:eid"
        exact
        component={EventEditor}
        permissionName={Permissions.simulation}
      />
      <PrivateRoute
        path="/campaigns/"
        exact
        component={Recommendations}
        permissionName={Permissions.wave}
      />
      <PrivateRoute
        path="/campaigns/:id/itc"
        component={ItcEventForm}
        permissionName={Permissions.wave}
      />
      <PrivateRoute
        path="/campaigns/:id"
        exact
        component={Recommendation}
        permissionName={Permissions.wave}
      />
      <PrivateRoute
        path="/settings"
        component={Settings}
        permissionName={Permissions.settings}
        fetchAndSetProfile={fetchAndSetProfile}
      />
      <PrivateRoute path="/notifications" exact component={Notifications} />
      <PrivateRoute path="/notifications/:id" exact component={Notification} />
      <PrivateRoute
        path="/simulation"
        component={Simulation}
        permissionName={Permissions.simulation}
      />
      <PrivateRoute
        path="/add-multi-events"
        component={AddMultipleEvents}
        permissionName={Permissions.simulation}
      />
      <PrivateRoute path="/fileUpload" component={Uploads} />
      <PrivateRoute
        path="/package-builder"
        exact={true}
        component={PackageBuilder}
        featureName="package-builder"
        permissionName={Permissions.backhaul}
      />
      <PrivateRoute
        path="/package-builder/create"
        exact={true}
        component={PackageBuilderCreate}
        featureName="package-builder"
        permissionName={Permissions.backhaul}
      />
      <PrivateRoute
        path="/package-builder/results/:packageId"
        component={PackageBuilderResults}
        featureName="package-builder"
        permissionName={Permissions.backhaul}
      />
      <SentryRoute path="/update-user/:partnerId" exact component={UpdateDetailsPage} />
      <SentryRoute path="/forgot-password" component={ForgotPassword} />
      <SentryRoute path="/setpassword" component={SetPassword} />
      <SentryRoute path="/login" component={Login} />
      <SentryRoute path="/partners/:partnerId/register" component={Registration} />
      <SentryRoute path="/fb-callback" component={FacebookLoginCallback} />
      <SentryRoute path="/:language/meta" component={ThirdPartyFBLogin} />
      {/* <Route path='/' component={ErrorMaintenance} /> */}
      <SentryRoute component={Error404} />
    </Switch>
  )
}

Pages.propTypes = {
  fetchAndSetProfile: PropTypes.func.isRequired,
}

function AppContents() {
  const [sidebarExpanded, setSidebarExpanded] = useState(false)
  const hasPrismaAccess = hasPrismaAccessTier()

  const fetchAndSetProfile = async (profileDispatch, translation) => {
    await fetchUserProfile(profileDispatch, translation)
  }

  return (
    <I18nextProvider i18n={i18n} defaultNS={hasPrismaAccess ? 'translation.prisma' : 'translation'}>
      <NotificationContextProvider>
        <LayoutContext.Provider value={{ sidebarExpanded, setSidebarExpanded }}>
          <ToastProvider>
            <EventFilterProvider>
              <UploadFilesProvider>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Router>
                    <ScrollToTop />
                    <Pages fetchAndSetProfile={fetchAndSetProfile} />
                  </Router>
                </ErrorBoundary>
              </UploadFilesProvider>
            </EventFilterProvider>
          </ToastProvider>
        </LayoutContext.Provider>
      </NotificationContextProvider>
    </I18nextProvider>
  )
}

/**
 * Root component of the application
 */
function App() {
  return (
    <Suspense fallback={<LoadingApp />}>
      <Provider store={store}>
        <AppContents />
      </Provider>
    </Suspense>
  )
}

export default App

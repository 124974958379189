import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const StatusRow = ({ checked, title, setStatus }) => {
  const { t } = useTranslation()

  const titleString = `Events.searchFilters.DropDownMenu.Status.${title.toLowerCase()}`

  return (
    <div>
      <label className="event-control-panel_dropdown-menu_custom-checkbox">
        <input onChange={() => setStatus(title)} type="checkbox" checked={checked} />
        <span>{t(titleString)}</span>
      </label>
    </div>
  )
}

StatusRow.propTypes = {
  checked: PropTypes.bool,
  title: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
}

export default function StatusFilter({ state, dispatch, submit }) {
  const selectedStatuses = state.status.value

  const handleSelectedStatus = useCallback(
    (newStatus) => {
      if (!state.status.isDefault && selectedStatuses.includes(newStatus)) {
        const newStatuses = selectedStatuses.filter((x) => x !== newStatus)

        if (newStatuses.length === 0) {
          dispatch({ type: 'STATUS_CANCEL' })
          submit({ ...state, status: { ...state.status, value: [] } })
        } else {
          dispatch({ type: 'STATUS', payload: newStatuses })
          submit({ ...state, status: { ...state.status, value: newStatuses } })
        }
      } else {
        dispatch({ type: 'STATUS', payload: [newStatus] })
        submit({ ...state, status: { ...state.status, value: [newStatus] } })
      }
    },
    [selectedStatuses, state, dispatch, submit],
  )

  return (
    <div className="event-control-panel_dropdown-menu_checkbox-wrapper">
      <StatusRow
        setStatus={handleSelectedStatus}
        checked={!state.status.isDefault && selectedStatuses.includes('FLAGGED')}
        imgSrc={'FLAGGED'}
        title={'FLAGGED'}
      />
      <StatusRow
        setStatus={handleSelectedStatus}
        checked={!state.status.isDefault && selectedStatuses.includes('HIDDEN')}
        imgSrc={'HIDDEN'}
        title={'HIDDEN'}
      />
    </div>
  )
}

StatusFilter.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
}

import { eventApi } from 'api/events'
import { domainEventApi } from 'api/simulation'
import { isEqual } from 'lodash'

export const actions = Object.freeze({
  submitEventAndRegenerateAsync: 'SUBMIT_EVENT_AND_REGENERATE_ASYNC',
  regenerateItcAsync: 'REGENERATE_ITC_ASYNC',
  fetchStatusAsync: 'FETCH_STATUS_ASYNC',
  setSuccess: 'SET_SUCCESS',
  setError: 'SET_ERROR',
  setLoading: 'SET_LOADING',
})

export const initialState = {
  loading: false,
  error: null,
  status: null,
  mappedStatus: null,
  success: false,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.setLoading:
      return { ...state, loading: action.payload }
    case actions.setSuccess: {
      const status = action.payload
      const mappedStatus = mapItcDagStatus(status)
      return { ...state, status, mappedStatus, success: true, loading: false }
    }
    case actions.setError:
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}

export const asyncActionHandlers = {
  [actions.submitEventAndRegenerateAsync]: ({ dispatch }) => async (action) => {
    dispatch({ type: actions.setLoading, payload: true })
    const { eventId, eventPayload, originalForm } = action.payload
    if (originalForm) {
      const formWithoutTz = { ...eventPayload }
      const originalFormWithoutTz = { ...originalForm }
      delete formWithoutTz.tz
      delete originalFormWithoutTz.tz

      if (!isEqual(formWithoutTz, originalFormWithoutTz)) {
        const eventResult = await domainEventApi.updateEventAsync(eventId, eventPayload, true)

        if (eventResult.error) {
          dispatch({ type: actions.setError, payload: eventResult.error })
          return
        }
      }
    } else {
      const eventResult = await domainEventApi.updateEventAsync(eventId, eventPayload, true)
      
      if (eventResult.error) {
        dispatch({ type: actions.setError, payload: eventResult.error })
        return
      }
    }

    const { data, error, status } = await eventApi.triggerItcGenerator(eventId)
    if (error || status != 200 || !('dag_run_id' in data)) { // eslint-disable-line
      dispatch({ type: actions.setError, payload: error ?? 'common.errors.inServerResponse' })
      return
    }

    dispatch({
      type: actions.setSuccess, payload: {
        status: data.status,
        dag_run_id: data.dag_run_id,
      }
    })
  },
  [actions.regenerateItcAsync]: ({ dispatch }) => async (action) => {
    dispatch({ type: actions.setLoading, payload: true })
    const { eventId } = action.payload
    const { data, error, status } = await eventApi.triggerItcGenerator(eventId)
    if (error || status != 200 || !('dag_run_id' in data)) { // eslint-disable-line
      dispatch({ type: actions.setError, payload: error ?? 'common.errors.inServerResponse' })
      return
    }

    dispatch({
      type: actions.setSuccess, payload: {
        status: data.status,
        dag_run_id: data.dag_run_id,
      }
    })
  },
  [actions.fetchStatusAsync]: ({ dispatch }) => async (action) => {
    dispatch({ type: actions.setLoading, payload: true })
    const { eventId } = action.payload
    const { data, error, status } = await eventApi.getItcGeneratorStatus(eventId)
    if (status == 404) { // eslint-disable-line
      dispatch({
        type: actions.setSuccess, payload: {
          status: 'not_found',
          dag_run_id: null,
        }
      })
      return
    }

    if (error || status != 200) { // eslint-disable-line
      dispatch({ type: actions.setError, payload: error ?? 'common.errors.inServerResponse' })
      return
    }

    dispatch({ type: actions.setSuccess, payload: data })
  },
}

export const mapItcDagStatus = (itcStatus) => {
  if (!itcStatus) return 'not_found'
  if (['queued', 'running'].includes(itcStatus.status)) return 'running'

  return itcStatus.status // 'success' or 'failed' or the augmented status 'not_found'
}

import { api, cr_api, makeApiRequest } from './_init'
import { LOADING, UPDATE, FAIL } from '../reducers/default'

export const createPackage = async (payload) => {
  try {
    const result = await api.post('/package_builder/packages', {
      num_packages: payload['num_packages'],
      n_top_events: payload['n_top_events'],
      event_ids: payload['event_ids'],
      package_title: payload['package_title'],
      targeting_mode: payload['targeting_mode'],
      filters: payload['filters'],
    })

    return { success: true, error: false, content: '', status: 200, payload: result.data }
  } catch (error) {
    console.log({ error })
    return { error: true, errorMessage: error, success: false }
  }
}

export const fetchPackage = async (dispatch, packageId) => {
  dispatch({ type: LOADING })
  if (packageId) {
    try {
      const { data, status } = await api.get(`/package_builder/packages/${packageId}`)

      if (status && status === 200) {
        dispatch({ type: UPDATE, payload: data })
      }
    } catch (error) {
      dispatch({ type: FAIL })
    }
  }
}

export const checkPackageStatus = async (packageId) => {
  if (packageId) {
    try {
      const res = await api.get(`/package_builder/packages/${packageId}`)
      if (res.status && res.status === 200 && res.data) {
        return { success: true, packageStatus: res.data.status }
      }
    } catch (error) {
      return {
        error: true,
        errorMessage: error,
        success: false,
      }
    }
  }
}

export const fetchPackages = async (dispatch) => {
  dispatch({ type: LOADING })
  try {
    const { data, status } = await api.get('/package_builder/packages/')
    if (status && status === 200 && data) {
      dispatch({ type: UPDATE, payload: data })
    }
  } catch (error) {
    dispatch({ type: FAIL })
  }
}

export const mergePackages = async (ids) => {
  let idsParams = ''
  if (ids && ids.length > 0) {
    ids.forEach((param, idx) => {
      if (idx > 0) {
        idsParams += `&packages_ids=${param}`
      } else {
        idsParams += `?packages_ids=${param}`
      }
    })
  }

  try {
    const { data, status } = await api.post(`/package_builder/packages/merge${idsParams}`)
    if (status && status === 200) {
      return {
        success: true,
        error: false,
        message: data,
        content: data,
      }
    }
  } catch (error) {
    return {
      success: false,
      error: true,
      message: error,
      content: error,
    }
  }
}

export const fetchCustomerCSV = async (id, tc) => {
  try {
    const result = await api.get(`/package_builder/packages/${id}/${tc}/customer_list`)

    return {
      success: true,
      error: false,
      content: '',
      status: 200,
      payload: result.data,
    }
  } catch (error) {
    console.log({ error })
    return { error: true, errorMessage: error, success: false }
  }
}

export const regenerateAudience = async (packageId, dispatch) => {
  dispatch({ type: LOADING })
  if (packageId) {
    try {
      const { data, status } = await api.post(`/package_builder/packages/${packageId}/regenerate`)
      if (status && status === 200) {
        dispatch({ type: UPDATE, payload: data })
      }
    } catch (error) {
      dispatch({ type: FAIL })
    }
  }
}

export const backhaulApi = {
  matchCustomers: async (ids) => {
    return await makeApiRequest(cr_api, 'post', '/customers/', ids)
  },
}

import React, {
  useEffect,
  useMemo,
  useRef,
  useContext,
  useCallback,
  useReducer,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'

import {
  AssetClass,
  buildSetupPayload,
  campaignKeywords,
  generativeCreativeField,
  isCampaignOpen,
  isCampaignPublished,
  isCampaignRunning,
  tcDisplayName,
  validateCreative,
} from 'api/models'

import {
  actions as creativesDispatchActions,
  SetupContext,
  CreativesDispatchContext,
} from './CampaignSetupProvider'
import { allFilesUploaded, fileStatus } from '../Media/media'
import Field, { ArrayField } from './CampaignForm/Field'

import Textarea from 'components/Shared/Textarea'
import InfoBox from 'components/Shared/InfoBox'
import ErrorMessage from 'components/Shared/ErrorMessage'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import DropDown from 'components/Shared/DropDown'

import {
  arrayReducer,
  FAIL,
  initStateArray,
  initStateObject,
  objectReducer,
  OVERWRITE,
} from '../../../../reducers/default'
import constants from '../../../../constants'
import { snakeCaseToPascalCase } from '../../../../utils/helpers'
import { creativeFormReducer, creativeActions } from './CampaignForm/creativeFormReducer'
import { updateSetupProcess } from '../../../../api/setupProcesses'
import { fetchCampaignCtasAsync } from '../../../../api/campaigns'
import AutoFill from './AutoFill'
import { SecondaryButton, TransparentBgButton } from 'components/Shared/Button'
import { getUserReports } from 'api/userReports'
import MediaUpload from '../Media/MediaUpload'
import CoCreate from './CoCreate'
import SearchDropdownMultiSelect from './SearchDropdownMultiSelect'
import { fetchAudiences } from 'api/integrations'
import { getErrorText } from './adAccountHelper'
import {
  hasEnabledVariations,
  offsetVariationsToHandleRemovedCreativeFields,
} from 'components/Recommendations/EventCampaigns/Steps/Setup/Summary/adSuggestionsReducer'
import PortalModal from 'components/Shared/PortalModal'
import Targeting from './CampaignForm/Targeting'
import { Keywords } from './CampaignForm/Keywords'
import { DescriptionField } from './CampaignForm/DescriptionField'
import { parseCreatives, getRemovedField } from './CampaignForm/utils'
import { validateLocationTargeting } from './CampaignForm/Targeting/targeting'
import useHasPermission, { hasPrismaAccessTier, Permissions } from 'hooks/useHasPermission'

export default function CampaignForm({
  eventId,
  eventDate,
  campaign,
  goal,
  onAfterSave,
  onCancel,
  onChange,
  onUpdate,
  upstreamError,
  upstreamLoading,
  onTargetingChanged,
  setLoading = () => {},
}) {
  const { t } = useTranslation()
  const autosaveTimeout = useRef(null)

  const internalSetup = useContext(SetupContext)
  const dispatchCreativesAction = useContext(CreativesDispatchContext)

  const hasCustomAudience = useMemo(() => {
    const tcCreative = internalSetup.creatives.find((x) => x.tc === campaign.tc)
    return !!tcCreative?.audience_id
  }, [internalSetup, campaign.tc])

  const partnerDetails =
    localStorage.getItem('partnerDetails') && JSON.parse(localStorage.getItem('partnerDetails'))
  const isITCRecommendations = ['ITC', 'ITC_v2'].includes(
    partnerDetails && partnerDetails.campaignRecommendationsModel,
  )

  const errorRef = useRef(null)

  const [ctaOptions, dispatchCtaOptionsAction] = useReducer(arrayReducer, initStateArray)

  const [userReports, userReportsDispatch] = useReducer(objectReducer, initStateObject)
  const [audiences, dispatchAudiencesAction] = useReducer(arrayReducer, initStateArray)
  const [isAudiencesFetched, setIsAudiencesFetched] = useState(false)
  const [disabledVariationsWarningModalOpen, setDisabledVariationsWarningModalOpen] =
    useState(false)
  const currentPublishedTargeting = useRef(internalSetup.targeting)
  const hasPrismaAccess = hasPrismaAccessTier()
  const hasWaveAccess = useHasPermission(Permissions.wave)

  const originalCreative = useMemo(() => {
    return parseCreatives(internalSetup, campaign.tc, eventDate, hasPrismaAccess)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalSetup, campaign.tc, eventDate])

  const [form, dispatchFormAction] = useReducer(creativeFormReducer, {
    ...initStateObject,
    content: originalCreative,
  })

  useEffect(() => {
    getUserReports(userReportsDispatch, eventId, {
      reportType: 'WRONG_TOPIC_RECOMMENDATION',
      tc: campaign.tc,
      tc_run_id: campaign.tc_run_id,
    })
  }, [eventId, campaign])

  const reportedKeywords = useMemo(() => {
    if (userReports.content?.items) {
      return userReports.content?.items.map((item) => item.keyword)
    }
  }, [userReports])

  useEffect(() => {
    const controller = new AbortController()
    const hasPixel = internalSetup.integration_details.some(
      (x) => x.asset_class === AssetClass.pixel,
    )
    fetchCampaignCtasAsync(
      dispatchCtaOptionsAction,
      internalSetup.goal ?? goal,
      hasPixel,
      controller,
    )

    return () => controller.abort()
  }, [internalSetup.goal, goal, internalSetup.integration_details])

  useEffect(() => {
    dispatchFormAction({ type: OVERWRITE, payload: originalCreative })
  }, [originalCreative])

  const keywords = useMemo(() => campaignKeywords(campaign), [campaign])

  useEffect(() => {
    if ((form.error || upstreamError) && errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [form.error, errorRef, upstreamError])

  // when the component mounts this sets mobile nav to display none
  // the setup started buttons were not showing above the navbar on some browser/ devices
  useEffect(() => {
    const mobileNav = document.getElementById('mobile-navbar')
    if (mobileNav?.style) {
      mobileNav.style.display = 'none'
    }

    return () => {
      const mobileNav = document.getElementById('mobile-navbar')
      if (mobileNav?.style) {
        mobileNav.style.display = 'block'
      }
    }
  }, [])

  const isRunning = isCampaignRunning(campaign, { validateNotEnded: true })
  const isPublished = isCampaignPublished(campaign)
  const isOpen = isCampaignOpen(campaign)

  const onAfterSaveInternal = useCallback(
    (formContent, defaults) => {
      if (onAfterSave) onAfterSave(formContent, defaults)
    },
    [onAfterSave],
  )

  const submit = useCallback(
    (formContent, e = null, removedFields = [], action = {}) => {
      if (e) e.preventDefault()
      if (!formContent) {
        return
      }

      if (removedFields.length > 0) {
        formContent.excluded_variations = offsetVariationsToHandleRemovedCreativeFields(
          removedFields,
          internalSetup.creatives,
          campaign.tc,
        )
      }

      const isRunning = isCampaignRunning(campaign, { validateNotEnded: true })
      if (isCampaignOpen(campaign)) {
        const setupPayload = {
          ...internalSetup,
          creatives: [...internalSetup.creatives.filter((x) => x.tc !== campaign.tc), formContent],
        }
        const options = {
          setupOnly: !isRunning,
          defaultCreatives: { description: formContent.description },
        }
        const payload = buildSetupPayload(setupPayload, options)
        dispatchCreativesAction({ type: creativesDispatchActions.loading })

        const defaults = {
          defaultCta: action.type === creativeActions.callToActionUpdate ? action.payload : null,
          defaultDestinationUrl: action.type === creativeActions.urlUpdate ? action.payload : null,
        }
        updateSetupProcess(
          (action) => {
            if (action.type === FAIL) {
              dispatchCreativesAction({
                type: creativesDispatchActions.failedUpdate,
                payload: { tc: campaign.tc, setup: internalSetup, creative: formContent },
              })
              dispatchFormAction({ type: FAIL, payload: action.payload })
            } else if (action.type === OVERWRITE) {
              dispatchCreativesAction({
                type: creativesDispatchActions.successfulUpdate,
                payload: new Date(),
              })
              dispatchFormAction({ type: creativeActions.idle })
              onAfterSaveInternal(formContent, defaults)
            } else dispatchFormAction(action)
          },
          eventId,
          payload,
          defaults,
        )
      }
    },
    [eventId, campaign, internalSetup, onAfterSaveInternal, dispatchCreativesAction],
  )

  const onChangeInternal = useCallback(
    (action, form, creatives, isRunning) => {
      dispatchFormAction(action)
      const newFields = creativeFormReducer(form, action).content
      const removedField = getRemovedField(form.content, newFields, action)
      if (onChange) {
        onChange(newFields, removedField)
      }

      if (isRunning) {
        return
      }

      setLoading(true)
      if (autosaveTimeout.current) clearTimeout(autosaveTimeout.current)

      autosaveTimeout.current = setTimeout(() => {
        if (
          !isEqual(
            newFields,
            creatives.find((x) => x.tc === campaign.tc),
          ) &&
          newFields.media.filter((file) => file.status === fileStatus.uploading).length === 0 &&
          hasWaveAccess
        ) {
          submit(newFields, null, removedField ? [removedField] : [], action)
        }
        setLoading(false)
      }, 1000)
    },
    [campaign.tc, submit, onChange, setLoading, hasWaveAccess],
  )

  const retrieveAudiences = useCallback(() => {
    const adAccountId = (internalSetup.integration_details || []).find(
      (x) => x.asset_class === AssetClass.ad_account,
    )?.asset_id

    if (!isAudiencesFetched && adAccountId) {
      fetchAudiences(dispatchAudiencesAction, adAccountId, 'custom')
      setIsAudiencesFetched(true)
    }
  }, [internalSetup.integration_details, isAudiencesFetched])

  useEffect(() => {
    retrieveAudiences()

    return () => clearTimeout(autosaveTimeout)
  }, [retrieveAudiences])

  return (
    <>
      <form
        className="campaign-form width-100"
        onSubmit={submit}
        data-testid="campaign-form-test-id"
      >
        <div className="d-flex flex-column justify-content-center">
          <Keywords
            eventId={eventId}
            setupProcessId={internalSetup?.id}
            campaign={campaign}
            isCampaignRunning={isRunning}
            reportedKeywords={reportedKeywords}
            keywords={keywords.headline}
            className="mb-0"
          />
          {(isOpen || isRunning) && (
            <CoCreate
              eventId={eventId}
              tc={form.content.tc}
              tc_run_id={form.content.tc_run_id}
              fields={{ bodies: form.content.body, headlines: form.content.headline }}
              promptForConfirmation={form.content.body.filter(Boolean).length > 0}
              onChange={(generatedContent) => {
                onChangeInternal(
                  { type: creativeActions.bodyHeadlineUpdate, payload: generatedContent },
                  form,
                  internalSetup.creatives,
                  isRunning,
                )
              }}
              language={form.content.language}
              onLanguageChanged={(lang) => {
                onChangeInternal(
                  { type: creativeActions.cocreateLanguageUpdate, payload: lang },
                  form,
                  internalSetup.creatives,
                  isRunning,
                )
              }}
              className={`${(isITCRecommendations && isOpen) || isRunning ? 'with-border' : ''}`}
            />
          )}
        </div>

        <div ref={errorRef} data-testid="campaign-form-error-banner">
          {(form.error || upstreamError) && (
            <ErrorMessage danger>{t(form.message || upstreamError)}</ErrorMessage>
          )}
        </div>

        <ArrayField
          as={Textarea}
          fieldName="body"
          form={form.content}
          onChange={(payload, index) =>
            onChangeInternal(
              { type: creativeActions.bodyUpdate, index, payload },
              form,
              internalSetup.creatives,
              isRunning,
            )
          }
          isAddingEnabled={!isPublished}
          inputChildren={(index) =>
            index === 0 && (isOpen || isRunning) ? (
              <AutoFill
                eventId={eventId}
                tc={form.content.tc}
                tc_run_id={form.content.tc_run_id}
                language={form.content.language}
                creativeFieldName={generativeCreativeField.bodies}
                fields={form.content.body}
                promptForConfirmation={form.content.body.filter(Boolean).length > 0}
                onChange={(generatedContent) => {
                  onChangeInternal(
                    { type: creativeActions.bodyUpdate, payload: generatedContent },
                    form,
                    internalSetup.creatives,
                    isRunning,
                  )
                }}
              />
            ) : null
          }
          restFn={(index) => ({
            wrapperClass:
              index === 0 && (isOpen || isRunning) ? 'generative-input align-bottom' : '',
            inputClass: index === 0 ? 'textarea-autofill' : '',
          })}
          maxLength={280}
          maxLengthClassNamesFn={(length) =>
            length > 0 && length <= 200
              ? 'green'
              : length > 200 && length <= 250
                ? 'orange'
                : length > 250
                  ? 'red'
                  : ''
          }
          tooltip={{
            title: t('Recommendations.campaign.setup.body.title'),
            body: (
              <div style={{ fontSize: '.875rem', fontWeight: 500 }}>
                <p className="pb-2">{t('Tips.Recommendations.CreativeComposer.Body.1')}</p>
                <p>{t('Tips.Recommendations.CreativeComposer.Body.2')}</p>
              </div>
            ),
          }}
        >
          {isITCRecommendations ? null : (
            <Keywords
              eventId={eventId}
              setupProcessId={internalSetup?.id}
              campaign={campaign}
              reportedKeywords={reportedKeywords}
              keywords={keywords.body}
            />
          )}
        </ArrayField>

        <ArrayField
          fieldName="headline"
          form={form.content}
          onChange={(payload, index) =>
            onChangeInternal(
              { type: creativeActions.headlineUpdate, index, payload },
              form,
              internalSetup.creatives,
              isRunning,
            )
          }
          isAddingEnabled={!isPublished}
          inputChildren={(index) =>
            index === 0 && (isOpen || isRunning) ? (
              <AutoFill
                eventId={eventId}
                tc={form.content.tc}
                tc_run_id={form.content.tc_run_id}
                language={form.content.language}
                creativeFieldName={generativeCreativeField.headlines}
                fields={form.content.headline}
                promptForConfirmation={form.content.headline.filter(Boolean).length > 0}
                onChange={(generatedContent) => {
                  onChangeInternal(
                    { type: creativeActions.headlineUpdate, payload: generatedContent },
                    form,
                    internalSetup.creatives,
                    isRunning,
                  )
                }}
              />
            ) : null
          }
          restFn={(index) => ({
            wrapperClass: index === 0 && (isOpen || isRunning) ? 'generative-input' : '',
            inputClass: index === 0 ? 'input-autofill' : '',
          })}
          maxLength={80}
          maxLengthClassNamesFn={(length) =>
            length > 0 && length <= 40
              ? 'green'
              : length > 40 && length <= 60
                ? 'orange'
                : length > 60
                  ? 'red'
                  : ''
          }
          tooltip={{
            title: t('Recommendations.campaign.setup.headline.title'),
            body: (
              <div style={{ fontSize: '.875rem', fontWeight: 500 }}>
                <p className="pb-1">{t('Tips.Recommendations.CreativeComposer.Headline.1')}</p>
                <p>{t('Tips.Recommendations.CreativeComposer.Headline.2')}</p>
              </div>
            ),
          }}
        >
          {isITCRecommendations ? null : (
            <Keywords
              eventId={eventId}
              setupProcessId={internalSetup?.id}
              campaign={campaign}
              reportedKeywords={reportedKeywords}
              keywords={keywords.headline}
              className="first"
            />
          )}
        </ArrayField>

        <div className="mb-5 creative-composer-section-wrapper creative-composer-section-wrapper__media">
          <InfoBox title="Media" body={t('Recommendations.campaign.setup.media.info')}>
            <label className="fs-18 d-inline-block">Media</label>
          </InfoBox>
          {!isITCRecommendations && (
            <Keywords
              eventId={eventId}
              setupProcessId={internalSetup?.id}
              campaign={campaign}
              isCampaignRunning={isRunning}
              reportedKeywords={reportedKeywords}
              keywords={keywords.media}
              className="last"
            />
          )}
          <MediaUpload
            initialMedia={form.content.media}
            onChange={(files, removedIndex = null) => {
              if (setLoading) setLoading(files.some((file) => file.status === fileStatus.uploading))

              onChangeInternal(
                { type: creativeActions.mediaUpdate, payload: files, index: removedIndex },
                form,
                internalSetup.creatives,
                isRunning,
              )
            }}
            maxItems={10}
            canAdd={!isPublished}
            canRemove={!isPublished && (!isRunning || form.content.media.length > 1)}
            canReplace={isPublished || (isRunning && form.content.media.length === 1)}
          />
        </div>

        <ArrayField
          fieldName="destination_url"
          form={form.content}
          minCount={1}
          onChange={(payload, index) =>
            onChangeInternal(
              { type: creativeActions.urlUpdate, index, payload },
              form,
              internalSetup.creatives,
              isRunning,
            )
          }
          isAddingEnabled={!isRunning}
          isRemovingEnabled={!isRunning}
          removeBtnClassNames="input__remove--r"
          maxLength={250}
          maxLengthClassNamesFn={(length) =>
            length > 0 && length <= 200
              ? 'green'
              : length > 200 && length <= 240
                ? 'orange'
                : length > 240
                  ? 'red'
                  : ''
          }
          validator={(val) => {
            return {
              isValid: val && constants.URL_VALIDATION_REGEX.test(val),
              errorMsg: t('common.errors.wrongUrlFormat'),
            }
          }}
          showPositiveFeedback={!isRunning && !isPublished}
          placeholder="https://www.your-website.com"
          validateOnBlur
          tooltip={{
            title: t('Tips.Recommendations.CreativeComposer.DestinationUrl.title'),
            body: (
              <ol
                style={{
                  listStyle: 'decimal',
                  paddingLeft: '1.4rem',
                  fontSize: '.875rem',
                  fontWeight: 500,
                }}
              >
                <li>{t('Tips.Recommendations.CreativeComposer.DestinationUrl.1')}</li>
                <li>{t('Tips.Recommendations.CreativeComposer.DestinationUrl.2')}</li>
                <li>{t('Tips.Recommendations.CreativeComposer.DestinationUrl.3')}</li>
              </ol>
            ),
          }}
          wrapperClass="input-cell--input-padding-max"
        />

        <div className="creative-composer-section-wrapper mb-5">
          <Field
            fieldName="call_to_action"
            as={DropDown}
            tooltip={{ content: 'Tips.Recommendations.CreativeComposer.CallToAction' }}
            value={form.content.call_to_action ?? ''}
            options={ctaOptions.items.map((x) => ({ value: x }))}
            onChange={(payload) =>
              onChangeInternal(
                { type: creativeActions.callToActionUpdate, payload },
                form,
                internalSetup.creatives,
                isRunning,
              )
            }
            required={false} // For the dropdown. Otherwise a '*' is added when value is empty.
            classNames="cta-dropdown"
            disabled={isPublished}
            renderItem={(item) => {
              const option =
                ctaOptions.items.find((x) => x === item?.value) ?? ctaOptions.items.find(() => true) // fallback first cta option

              return option
                ? t([
                    `Recommendations.campaign.setup.call_to_action.${option}`,
                    snakeCaseToPascalCase(option),
                  ])
                : '_'
            }}
          />
        </div>

        <div className="creative-composer-section-wrapper mb-5">
          <Field
            fieldName="audience"
            as={SearchDropdownMultiSelect}
            tooltip={{ content: 'Tips.Recommendations.CreativeComposer.Audience' }}
            value={form.content?.audience_id}
            defaultValue={t('Recommendations.campaign.recommendationTitle', {
              tc: tcDisplayName(campaign),
            })}
            onChange={(payload, index) =>
              onChangeInternal(
                { type: creativeActions.audienceUpdate, index, payload: payload },
                form,
                internalSetup.creatives,
                isRunning,
              )
            }
            isRequired={false}
            disabled={isPublished}
            options={audiences.items}
            error={audiences.error ? getErrorText(audiences.message) : null}
            loading={audiences.loading}
            onErrorBtnClick={retrieveAudiences}
            dropdownClasses="audience"
          />
        </div>

        <div className="creative-composer-section-wrapper mb-5">
          <Targeting
            tc={campaign.tc}
            tc_run_id={campaign.tc_run_id}
            setup={internalSetup}
            autoSave={isCampaignOpen(campaign)}
            onChange={(targteing) => {
              currentPublishedTargeting.current = targteing
              if (onTargetingChanged) onTargetingChanged(targteing)
            }}
            // Note: this only disables the dropdown.
            // If postal-codes, or other editable items are selected,
            // the user can still edit them.
            disabled={isPublished}
            showValidationErrors={!isOpen}
          />
        </div>

        <div>
          <DescriptionField
            form={form.content}
            isCampaignPublished={isPublished}
            onChange={(payload) =>
              onChangeInternal(
                { type: creativeActions.descriptionUpdate, payload },
                form,
                internalSetup.creatives,
                isRunning,
              )
            }
          />
        </div>

        <div className="my-3">
          {form.loading || upstreamLoading ? (
            <LoadingSpinner data-testid="loading-spinner" title={t('common.sending')} />
          ) : (
            <div className="creative-composer-controls-container">
              {isRunning ? (
                <>
                  <TransparentBgButton
                    text={t('common.cancel')}
                    onClick={() => {
                      dispatchFormAction({
                        type: OVERWRITE,
                        payload: { ...initStateObject, content: originalCreative },
                      })
                      if (onCancel) onCancel()
                    }}
                    color="navy"
                  />
                  <SecondaryButton
                    data-testid="save-campaign-form-btn"
                    text={t('common.update')}
                    onClick={(e) => {
                      e.preventDefault()
                      onUpdate()
                    }}
                    color="navy"
                    disabled={
                      form.loading ||
                      upstreamLoading ||
                      !hasWaveAccess ||
                      !allFilesUploaded(form.content.media) ||
                      !validateCreative(form.content, { setupOnly: false }).valid ||
                      !hasEnabledVariations(internalSetup.creatives) ||
                      !validateLocationTargeting(
                        currentPublishedTargeting.current,
                        campaign.tc,
                        campaign.tc_run_id,
                        hasCustomAudience,
                      ).valid
                    }
                  />
                </>
              ) : null}
            </div>
          )}
        </div>
      </form>
      <PortalModal
        isOpen={disabledVariationsWarningModalOpen}
        onClickOutside={() => setDisabledVariationsWarningModalOpen(false)}
      >
        <div className="content compact text-center" style={{ maxWidth: '420px' }}>
          <h1 className="autofill_warning-header">
            {t('Recommendations.summary.adVariationsRemainDisabled')}
          </h1>
          <p className="autofill_warning-msg">
            {t('Recommendations.summary.adVariationsRemainDisabledDescription')}
          </p>
          <div className="footer">
            <TransparentBgButton
              text={t('common.cancel')}
              onClick={() => {
                setDisabledVariationsWarningModalOpen(false)
              }}
              color="navy"
            />
            <SecondaryButton
              color="orange"
              text={t('common.proceed')}
              onClick={(e) => {
                setDisabledVariationsWarningModalOpen(false)
                submit(form.content, e)
              }}
              type="button"
              disabledTitle=""
            />
          </div>
        </div>
      </PortalModal>
    </>
  )
}

CampaignForm.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  eventDate: PropTypes.string.isRequired,
  campaign: PropTypes.object.isRequired,
  goal: PropTypes.string,
  onAfterSave: PropTypes.func,
  onCancel: PropTypes.func,
  setLoading: PropTypes.func,
  onChange: PropTypes.func,
  onUpdate: PropTypes.func,
  onTargetingChanged: PropTypes.func,
  upstreamError: PropTypes.string,
  upstreamLoading: PropTypes.bool,
}

import React from 'react'
import PropTypes from 'prop-types'

import PortalModal from 'components/Shared/PortalModal'
import EventForm from './EventForm'

export default function EventDialog({ eventId, isOpen, onClose }) {

  return (
    <PortalModal
      isOpen={isOpen}
      wrapperClassName="event-itc-portal"
      className="full-height"
      onClickOutside={() => {}}
    >
      {isOpen && <EventForm eventId={eventId} onClose={onClose} />}
    </PortalModal>
  )
}

EventDialog.propTypes = {
  eventId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

import React, { useState, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import Input from 'components/Shared/Input'
import { updateUserProfile } from 'api/auth'
import { initStateObject, objectReducer } from 'reducers/default'
import { ConnectedIcon, EnvelopeIcon } from 'images'

/**
 * Email settings
 * @Tags( settings, email )
 * @NpmPackages( react-i18next )
 */
const Email = () => {
  const { t } = useTranslation()
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [state, setState] = useState(user.email)
  const [editMode, setEditMode] = useState(false)
  const [formStatus, setFormStatus] = useReducer(objectReducer, initStateObject)

  const handleSubmit = (e) => {
    e.preventDefault()
    updateUserProfile(dispatch, { ...user.profile, email: state }, setFormStatus)
    setEditMode(false)
  }

  const handleChange = (e) => {
    setState(e.target.value)
  }

  const handleEdit = () => {
    setEditMode(true)
  }

  const VerifieldBox = () => {
    return (
      <span className="card-settings-account__value__verified">
        <ConnectedIcon width={10} height={10} />
        {t('Settings.email.email_verified')}
      </span>
    )
  }

  return (
    <>
      {formStatus.error && formStatus.message && <p className="alert alert-danger">{t(formStatus.message)}</p>}
      {/* {formStatus.success && <p className="alert alert-info">{t(formStatus.message)}</p>} */}
      <form onSubmit={handleSubmit} className="settings-page__sectionBody">
        <div className="settings-page__sectionColumn w-100">
          <div className="settings-page__sectionIcon">
            <EnvelopeIcon width={17} />
          </div>
          <div className="settings-page__sectionWrapper">
            <div className="settings-page__sectionState">
              <h3 className="settings-page__sectionTitle">{t('Settings.email.title')}</h3>
              <div className="settings-page__sectionValueContainer">
                {editMode ? (
                  <Input
                    value={state}
                    name="name"
                    required={true}
                    onChange={handleChange}
                    inputClass="settings-page__sectionInput"
                  />
                ) : (
                  <p className="settings-page__sectionValue">
                    {state} {!editMode && user.email_verified && <VerifieldBox />}
                  </p>
                )}
              </div>
            </div>
            <div className="settings-page__sectionColumn justify-content-end">
              <div className="settings-page__sectionEdit">
                {editMode ? (
                  <button style={{ minWidth: 105 }} type="submit" className="btn">
                    {t('Settings.btnActiveMode')}
                  </button>
                ) : (
                  <div onClick={handleEdit} className="btn">
                    {t('Settings.btn')}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

Email.propTypes = {}

Email.defaultProps = {}

export default Email

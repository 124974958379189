import React, {  } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import EventCardDefault from './EventCardDefault'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'
import EventCardPrisma from './EventCardPrisma'

const EventCard = ({ event }) => {
  const hasPrismaAccess = hasPrismaAccessTier()
  const history = useHistory()

  const redirectUrl = () => {
    if (window.innerWidth > 991) {
      history.replace(`/events/?drawer-open=true&event-id=${event.id}&tab=1`)
    } else history.replace(`/events/${event.id}`)
    window.location.reload()
  }

  return (
    <div className="event-card" key={event.id} onClick={redirectUrl}>
      {hasPrismaAccess ? <EventCardPrisma event={event} /> : <EventCardDefault event={event} />}
    </div>
  )
}

EventCard.propTypes = {
  event: PropTypes.object,
}

export default EventCard

import {
  abortMultipartUpload,
  callPresignedUrl,
  completeMultipartUpload,
  createPresignedUrl,
  initiateMultipartUpload,
  validateUploadId,
} from 'api/storage'
import axios from 'axios'
import i18n from '../../i18n'

const PART_SIZE = 20 * 1000 * 1000 //20MB

export const fileStatus = Object.freeze({
  new: 'NEW',
  uploading: 'UPLOADING',
  success: 'SUCCESS',
  uploadFailed: 'UPLOAD_FAILED',
})

export const initialState = {
  files: [],
  isLoading: false,
  success: null,
  error: null,
  errorMessage: '',
}

export const actions = Object.freeze({
  addFile: 'ADD_FILE',
  removeFile: 'REMOVE_FILE',
  resetFiles: 'RESET_FILES',
  rejectFiles: 'REJECT_FILES',
  editFileInfo: 'EDIT_FILE_INFO',
  updateUploadProgress: 'UPDATE_UPLOAD_PROGRESS',
  fileUploadInitiate: 'FILE_UPLOAD_INITIATE',
  fileUploadFail: 'FILE_UPLOAD_FAIL',
  fileUploadSuccess: 'FILE_UPLOAD_SUCCESS',
  uploadComplete: 'UPLOAD_COMPLETE',

  addFileAsync: 'ADD_FILE_ASYNC',
  uploadFilesAsync: 'UPLOAD_FILES_ASYNC',
  uploadCompleteAsync: 'UPLOAD_COMPLETE_ASYNC',
  removeFileAsync: 'REMOVE_FILE_ASYNC',
  resetFilesAsync: 'RESET_FILES_ASYNC',
})

export function fileUploadReducer(state, action) {
  switch (action.type) {
    case actions.addFile: {
      return {
        ...state,
        files: [...state.files, { ...action.payload?.file, status: fileStatus.new }],
        success: null,
        error: null,
        errorMessage: '',
      }
    }
    case actions.removeFile: {
      const newFiles = state.files.filter((file) => file.name !== action.payload?.name)
      const error = newFiles.some((file) => file.error)

      return {
        ...state,
        files: newFiles,
        error: error,
        errorMessage: error ? state.errorMessage : '',
      }
    }
    case actions.resetFiles:
      return {
        ...state,
        files: [],
        error: null,
        errorMessage: '',
        isLoading: false,
        success: null,
      }
    case actions.rejectFiles:
      return {
        ...state,
        errorMessage: action.payload,
      }
    case actions.editFileInfo: {
      const editedFiles = constructEditObject(state.files, action.payload)

      return {
        ...state,
        files: editedFiles,
      }
    }
    case actions.updateUploadProgress: {
      const newFiles = state.files.map((file) => {
        if (file.name === action.payload.name) return { ...file, ...action.payload }

        return file
      })
      return {
        ...state,
        files: newFiles,
      }
    }
    case actions.fileUploadInitiate: {
      const newFiles = state.files.map((file) => {
        if (file.name === action.payload.name) {
          return { ...file, ...action.payload, status: fileStatus.uploading, error: '' }
        }
        return file
      })

      return {
        ...state,
        files: newFiles,
        isLoading: true,
      }
    }
    case actions.fileUploadFail: {
      const newFiles = state.files.map((file) => {
        if (file.name === action.payload.name) {
          let newFile = file
          if (action.payload?.parts) newFile = { ...file, ...action.payload }

          return {
            ...newFile,
            status: fileStatus.uploadFailed,
            error: true,
            errorMessage: i18n.t('Uploads.errors.fileUploadFailed'),
          }
        }
        return file
      })
      return {
        ...state,
        files: newFiles,
        isLoading: false,
        success: false,
        errorMessage: i18n.t('Uploads.errors.uploadFailed'),
        error: true,
      }
    }
    case actions.fileUploadSuccess: {
      const newFiles = state.files.map((file) => {
        if (file.name === action.payload.name) {
          return { ...file, status: fileStatus.success, error: '' }
        }
        return file
      })

      return {
        ...state,
        files: newFiles,
      }
    }
    case actions.uploadComplete: {
      return {
        ...state,
        isLoading: false,
        success: action.payload.isSuccessful,
        error: !action.payload.isSuccessful,
      }
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}

export const asyncActionHandlers = {
  [actions.addFileAsync]:
    ({ dispatch, getState }) =>
      async (action) => {
        const { files } = getState()
        const errorMessage = validateNewFile(files, action.payload.file)

        if (!errorMessage) {
          const newFileObj = await constructFileObject(action.payload.file)

          dispatch({ type: actions.addFile, payload: { file: newFileObj } })
        } else {
          dispatch({ type: actions.rejectFiles, payload: errorMessage })
        }
      },
  [actions.uploadFilesAsync]:
    ({ dispatch, getState }) =>
      async () => {
        const { files } = getState()
        await Promise.all(files.map((file) => uploadFile(dispatch, file)))

        dispatch({ type: actions.uploadCompleteAsync })
      },
  [actions.uploadCompleteAsync]:
    ({ dispatch, getState }) =>
      async () => {
        const { files } = getState()
        const isSuccessful = files.length && files.every((file) => file.status === fileStatus.success)

        if (isSuccessful) dispatch({ type: actions.resetFiles })

        dispatch({ type: actions.uploadComplete, payload: { isSuccessful } })
      },
  [actions.removeFileAsync]:
    ({ dispatch }) =>
      async (action) => {
        const file = action.payload
        abortUpload(file)
        dispatch({ type: actions.removeFile, payload: action.payload })
      },
  [actions.resetFilesAsync]:
    ({ dispatch, getState }) =>
      async () => {
        const { files } = getState()
        files.forEach((file) => abortUpload(file))
        dispatch({ type: actions.resetFiles })
      },
}

const abortUpload = (file) => {
  if (file.status === fileStatus.uploading) {
    file.cancelTokenSource.cancel()
    abortMultipartUpload({
      upload_id: file.uploadId,
      object_key: file.objectKey,
    })
  }
}

const uploadFile = async (dispatch, file) => {
  if (file.status === fileStatus.new) {
    await uploadNewFile(dispatch, file)
  } else if (file.status === fileStatus.uploadFailed) {
    const {
      content: { isValid },
    } = await validateUploadId(
      {
        upload_id: file.uploadId,
        object_key: file.objectKey,
        expected_total_parts: file.expectedTotalParts,
      },
      { cancelToken: file.cancelTokenSource.token },
    )
    if (!isValid) await uploadNewFile(dispatch, file)
    else {
      const presignedUrls = await getPresignedUrls(dispatch, file)
      await uploadParts(dispatch, file, presignedUrls)
    }
  }
}

const uploadNewFile = async (dispatch, file) => {
  const initialUploadData = await initiateUpload(dispatch, file)

  if (!initialUploadData) return

  const newFile = { ...file, ...initialUploadData }
  const presignedUrls = await getPresignedUrls(dispatch, newFile)

  await uploadParts(dispatch, newFile, presignedUrls)
}

const initiateUpload = async (dispatch, file) => {
  let { content: initialUploadData, error } = await initiateMultipartUpload(
    {
      object_name: `${file.category}/${file.name}`,
      content_type: file.type,
    },
    { cancelToken: file.cancelTokenSource.token },
  )

  if (error) {
    dispatch({ type: actions.fileUploadFail, payload: { name: file.name } })
    return
  }

  initialUploadData = {
    uploadId: initialUploadData.upload_id,
    objectKey: initialUploadData.object_key,
  }

  dispatch({ type: actions.fileUploadInitiate, payload: { ...initialUploadData, name: file.name } })

  return initialUploadData
}

const getPresignedUrls = async (dispatch, file) => {
  const { content: presignedUrls, error } = await createPresignedUrl(
    {
      upload_id: file.uploadId,
      object_key: file.objectKey,
      start_part_number: file.totalUploadedParts + 1,
      total_parts: Object.keys(file.parts).length,
      total_uploaded_parts: file.totalUploadedParts,
    },
    { cancelToken: file.cancelTokenSource.token },
  )

  if (error) {
    dispatch({ type: actions.fileUploadFail, payload: { name: file.name } })
    return
  }

  return presignedUrls
}

const uploadParts = async (dispatch, file, presignedUrls) => {
  let totalUploadedParts = file.totalUploadedParts
  let expectedTotalParts = file.expectedTotalParts
  let partsData = file.partsData
  let parts = file.parts

  if (!presignedUrls) return

  for (let [index, part] of Object.entries(parts)) {
    const presignedUrl = presignedUrls[index] || ''
    const {
      content: partUploadResult,
      success,
      error,
    } = await callPresignedUrl(presignedUrl, part, { cancelToken: file.cancelTokenSource.token }, (progress) => {
      dispatch({ type: actions.editFileInfo, payload: { name: file.name, progress } })
    })

    if (success) {
      partsData = [...partsData, { part_number: Number(index) + 1, etag: partUploadResult.etag }]
      totalUploadedParts = totalUploadedParts + 1
      delete parts[index]

      dispatch({ type: actions.editFileInfo, payload: { name: file.name, partsData, totalUploadedParts } })
    }

    if (error) {
      dispatch({ type: actions.fileUploadFail, payload: { name: file.name, parts } })
    }
  }

  if (totalUploadedParts !== expectedTotalParts) return

  const { success: uploadCompleted, error: uploadCompletionFailed } = await completeMultipartUpload(
    {
      upload_id: file.uploadId,
      object_key: file.objectKey,
      parts: partsData,
    },
    { cancelToken: file.cancelTokenSource.token },
  )

  if (uploadCompleted) {
    dispatch({ type: actions.fileUploadSuccess, payload: { name: file.name } })
  }
  if (uploadCompletionFailed) {
    dispatch({ type: actions.fileUploadFail, payload: { name: file.name } })
  }
}

const constructFileObject = async (file) => {
  let [parts, expectedTotalParts] = await splitFileIntoParts(file)

  return {
    file,
    path: file.path,
    name: file.name,
    type: file.type,
    size: file.size,
    category: '',
    error: null,
    errorMessage: '',
    parts: parts,
    partsData: [],
    expectedTotalParts: expectedTotalParts,
    totalUploadedParts: 0,
    uploadId: null,
    objectKey: null,
    cancelTokenSource: axios.CancelToken.source(),
    progress: 0,
  }
}

const constructEditObject = (files, payload) => {
  return files.map((file) => {
    if (file.name === payload.name && payload.progress) {
      const progress = Math.round((1 / file.expectedTotalParts) * (file.totalUploadedParts * 100 + payload.progress))
      payload = { ...payload, progress }
    }
    if (file.name === payload.name) return { ...file, ...payload }

    return file
  })
}

const splitFileIntoParts = async (file) => {
  const totalParts = Math.ceil(file.size / PART_SIZE, PART_SIZE)
  let parts = {}

  for (const index of Array(totalParts).keys()) {
    const offset = index * PART_SIZE
    const filePart = file.slice(offset, offset + PART_SIZE)

    parts = { ...parts, [index]: filePart }
  }

  return [parts, totalParts]
}

const validateNewFile = (existingFiles, file) => {
  const fileExists = existingFiles.some((existingFile) => existingFile.name === file.name)

  if (fileExists) {
    return i18n.t('Uploads.errors.fileExists')
  }

  return ''
}

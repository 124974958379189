import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import SelectAdPlatformForm from 'components/Recommendations/Campaign/SelectAdPlatformForm'
import { SecondaryButton, TransparentBgButton } from 'components/Shared/Button'

const Accounts = ({
  event,
  canCreateAds,
  onBack,
  onNext,
  onIntegrationsChanged,
  onConversionGoalChanged,
  defaultIntegrations,
  setShowCancelSetupModal,
  eventGoal,
  defaultConversionId,
  previousIntegrations,
}) => {
  const { t } = useTranslation()

  return (
    <div id="accounts">
      <SelectAdPlatformForm
        eventGoal={eventGoal}
        defaultIntegrations={defaultIntegrations}
        previousIntegrations={previousIntegrations}
        isDisabled={event.has_active_campaigns}
        onChange={onIntegrationsChanged}
        defaultConversionId={defaultConversionId}
        onConversionGoalChanged={onConversionGoalChanged}
      />
      <div
        id="setup-btn-container"
        className="recommendation-setup-action-buttons d-flex justify-content-between align-items-center"
      >
        <div className="d-flex justify-content-between w-100">
          <TransparentBgButton
            className="bt"
            onClick={() => onBack()}
            text={t('Recommendations.btnBack')}
            fullWidth={false}
            color="navy"
          />
          <div className="d-flex gap-3">
            <TransparentBgButton
              text={t('Recommendations.btnCancelSetup')}
              onClick={() => setShowCancelSetupModal(true)}
              color="navy"
            />
            <SecondaryButton
              disabled={!canCreateAds}
              className="bt"
              onClick={() => onNext()}
              text={t('Recommendations.btnNext')}
              fullWidth={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Accounts.propTypes = {
  event: PropTypes.object.isRequired,
  defaultIntegrations: PropTypes.array.isRequired,
  canCreateAds: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onIntegrationsChanged: PropTypes.func.isRequired,
  setShowCancelSetupModal: PropTypes.func.isRequired,
  eventGoal: PropTypes.string,
  defaultConversionId: PropTypes.string,
  onConversionGoalChanged: PropTypes.func,
  previousIntegrations: PropTypes.array,
}

export default Accounts

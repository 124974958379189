import React from 'react'
import PropTypes from 'prop-types'
import FallingDots from 'components/Shared/FallingDots'

const EventStatisticsItem = ({ title, renderIcon, iconClass, value, color = '', loading = null }) => {
  const Image = () => {
    return renderIcon ? renderIcon() : iconClass ? <i className={iconClass}></i> : null
  }

  return (
    <div className={'events-insights-page_statistics_row_wrapper'}>
      <div className={`events-insights-page_statistics_row_icon ${color}`}>
        <Image />
      </div>
      <div className="events-insights-page_statistics_row_text_wrapper">
        <p className="events-insights-page_statistics_row_text_value">
          {loading ? <FallingDots classNames="stats-loader" /> : <strong>{value}</strong>}
        </p>
        <p className="events-insights-page_statistics_row_text_title">{title}</p>
      </div>
    </div>
  )
}

EventStatisticsItem.propTypes = {
  title: PropTypes.string,
  renderIcon: PropTypes.func,
  iconClass: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  loading: PropTypes.bool,
}
export default EventStatisticsItem

import React from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Layout from 'components/Layout'
import EventForm from 'components/Recommendations/Itc/EventFormMobile'

const ItcEventForm = ({ match }) => {
  const { t } = useTranslation()
  const eventId = match.params.id

  return (
    <Layout
      loggedIn
      title={t('Recommendations.MainTitle')}
      mobileTopBar={{
        back: true,
        centerTitle: t('Recommendations.MainTitle'),
        addHistoryRoute: `/campaigns/?drawer-open=true&event-id=${eventId}`,
        historyCallback: () => {},
      }}
    >
      <Helmet
        bodyAttributes={{
          class: 'itc-event-page',
        }}
      />
      <main>
        <div className="container-fluid">
          <EventForm eventId={eventId} />
        </div>
      </main>
    </Layout>
  )
}
ItcEventForm.propTypes = {
  match: PropTypes.object,
}

export default ItcEventForm

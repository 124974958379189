import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Searchbar from '../../../Shared/Searchbar'
import EmptyView from '../../../Shared/EmptyView'

function Row({ title, onChange, isSelected }) {
  return (
    <div className="event-control-panel_dropdown-menu_location_row">
      <label className="event-control-panel_dropdown-menu_custom-checkbox">
        <input onChange={onChange} checked={isSelected} type="checkbox" />
        <span>{title}</span>
      </label>
    </div>
  )
}

Row.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function Selector({ items, searchLabel, selectedIds, onChange }) {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')

  const filteredItems = !searchTerm
    ? items
    : items.filter((x) => x.title.toLowerCase().search(escapeRegExp(searchTerm.toLowerCase())) > -1)

  return (
    <div className="event-control-panel_dropdown-menu_location_container">
      <div className="event-control-panel_dropdown-menu_location_searchbar_wrapper">
        <header className="d-flex ai-b">
          <Searchbar
            handleChange={setSearchTerm}
            placeholder={searchLabel}
            ariaLabel={t('Events.search.ariaLabel')}
            postfixView={<i className="icon fas fa-search" style={{ color: '#ccc' }}></i>}
          />
        </header>
      </div>
      <div className="event-control-panel_dropdown-menu_location_inner">
        {
          filteredItems.length === 0 &&
            !!searchTerm && <EmptyView>Empty result :(</EmptyView> // FIXME: Normal Empty message // FIXME: Normal Empty message
        }
        {filteredItems.length === 0 && !searchTerm && (
          <p className="warning-red">{t('Events.searchFilters.emptySearch')}</p>
        )}
        {filteredItems.length !== 0 &&
          filteredItems.map((item) => (
            <Row
              key={item.id}
              title={item.title}
              isSelected={selectedIds.includes(item.id)}
              onChange={() => onChange(item.id)}
            />
          ))}
      </div>
    </div>
  )
}

Selector.propTypes = {
  items: PropTypes.array.isRequired,
  selectedIds: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  searchLabel: PropTypes.string.isRequired,
}

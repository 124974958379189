import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { ClockIcon, TickWrappedIcon } from 'images'
import { getEventMarketingCampaignState } from 'api/models'
import { useTranslation } from 'react-i18next'

export default function EventHeaderPrisma({
  event,
  showActiveBadge = true,
  children = null,
  renderMenu,
}) {
  const{ t } = useTranslation()
  const { hasMarketingCampaign, hasActiveCampaign } = useMemo(() => getEventMarketingCampaignState(event), [event])

  if (!event) return null
  return (
    <>
      <div className="row">
        <div className="col">
          <h1 className="event-title" title={event.title}>
            {event.title}
          </h1>
          <p className="insights-header__text d-flex">
            <span className="mr-2">
              <span>
                <ClockIcon className="mr-1" height={12} width={12} />
              </span>
              <time dateTime={moment.utc(event.date).format('DD.MM.YYYY')}>
                {' '}
                {moment.utc(event.date).format('DD.MM.YYYY')}{' '}
              </time>
            </span>
          </p>
          {children}
          {showActiveBadge && hasMarketingCampaign && (
            <span
              className={`event-row_left-content_title-and-date_date_active-campaign-indicator_wrapper ${hasActiveCampaign ? 'green' : 'red'}`}
              style={{ marginLeft: 0, height: '20px' }}
            >
              <span>
                <TickWrappedIcon width={12} />
              </span>
              <span className="event-row_left-content_title-and-date_date_active-campaign-indicator_text">
                {hasActiveCampaign
                  ? t('Recommendations.campaignBadge.active')
                  : t('Recommendations.campaignBadge.inactive')}
              </span>
            </span>
          )}
          <div className="mt-2" style={{ color: '#616060', textAlign: 'left' }}>
            {event.eid && event.eid.includes('-sim-') ? (
              <>
                <div className="mb-1">{t('Event.manual')}</div>
                <br />
              </>
            ) : (
              <br />
            )}
          </div>
        </div>
      </div>
      {renderMenu && renderMenu()}
    </>
  )
}

EventHeaderPrisma.propTypes = {
  event: PropTypes.object.isRequired,
  menuOptions: PropTypes.array,
  showActiveBadge: PropTypes.bool,
  children: PropTypes.node,
  renderMenu: PropTypes.func,
}

import React, { useCallback, useContext, useMemo } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import Row from './Row'
import { actions } from './customerSegmentation'
import { CustomerSegmentationContext, CustomerSegmentationDispatchContext } from './CustomerSegmentationContext'
import InfoBox from 'components/Shared/InfoBox'
import Input from 'components/Shared/Input'

export default function PriceSensitivity() {
  const { t } = useTranslation()
  const currency = useMemo(() => localStorage.getItem('userCurrency') || 'EUR', [])
  const { excludedPriceRange: { qualifier, value } } = useContext(CustomerSegmentationContext)
  const dispatch = useContext(CustomerSegmentationDispatchContext)
  const [inputError, setInputError] = React.useState('')
  const inputRef = React.useRef()

  const onQualifierChange = useCallback((qualifier) => {
    dispatch({ type: actions.setExcludedPriceRange, payload: { qualifier } })
  }, [dispatch])

  const onPriceChange = useCallback((price) => {
    dispatch({ type: actions.setExcludedPriceRange, payload: { value: price } })
  }, [dispatch])

  const reset = useCallback(() => {
    if (inputRef.current) inputRef.current.value = ''
    dispatch({ type: actions.setExcludedPriceRange, payload: { qualifier: null, value: null } })
  }, [dispatch])

  return (
    <div>
      <InfoBox content="Tips.PackageBuilder.PriceSensitivity">
        <h6 className="d-inline-block">{t('PackageBuilder.customerSegmentation.priceSensitivityExclusion')}</h6>
      </InfoBox>
      <Row>
        <Form.Check
          custom
          type="radio"
          label={t('PackageBuilder.customerSegmentation.below')}
          inline
          name="price-sensitivity"
          id="price-sensitivity-below"
          checked={qualifier === 'below'}
          onClick={() => qualifier === 'below' ? reset() : onQualifierChange('below')}
        />
        <Form.Check
          custom
          type="radio"
          label={t('PackageBuilder.customerSegmentation.above')}
          inline
          name="price-sensitivity"
          id="price-sensitivity-above"
          checked={qualifier === 'above'}
          onClick={() => qualifier === 'above' ? reset() : onQualifierChange('above')}
        />
        <div className="d-flex align-items-center gap-2 aligned-input">
          <Input
            inputRef={inputRef}
            wrapperClass="price-sensitivity mb-0 w-100"
            inputClass="pt-2"
            placeholder={t('PackageBuilder.customerSegmentation.price')}
            type="number"
            min={0}
            name="price"
            errorBelow
            value={value}
            onChange={(e) => onPriceChange(e.target.value)}
            disabled={!qualifier}
            validator={(val) => {
              return {
                isValid: val >= 0,
                errorMsg: t('PackageBuilder.customerSegmentation.validationMsgs.negativePrice'),
              }
            }}
            setError={setInputError}
          />
          <span>{currency}</span>
        </div>
      </Row>
      {inputError && <div className="mt-2 warning-red">{inputError}</div>}
    </div>
  )
}

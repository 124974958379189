import * as Sentry from '@sentry/react'
import jwtDecode from 'jwt-decode'
import { api } from './_init'
import { LOADING, FAIL, UPDATE, OVERWRITE, FAIL_FALLBACK } from '../reducers/default'
import { UPDATE_TOKEN, LOGIN, UPDATE_FEATURES, UPDATE_PROFILE, UPDATE_PERMISSIONS } from '../reducers/user'
import { changeLanguage, returnErrorMessage } from '../utils/helpers'
import store from 'store'

export const authenticateUser = async (dispatch, fields, localDispatch) => {
  dispatch({ type: LOADING })
  try {
    api
      .post('/auth', { username: fields.username, password: fields.password, new_password: fields.newPassword })
      .then((result) => {
        if (result.data.AccessToken) {
          const expiresInSec = result.data.ExpiresIn

          const userData = jwtDecode(result.data.IdToken)

          const cognitoGroups = userData['cognito:groups'] ?? []

          const testGroupsArr = [...cognitoGroups]
          const accessLevel = testGroupsArr.find((group) => group.includes('webapp-access-group'))
          const isDisabledAccount = testGroupsArr.some((group) => group.includes('account-disabled'))
          const isExternalUser = testGroupsArr.some((group) => group.includes('external-user'))

          if (isDisabledAccount || isExternalUser) {
            const errorMessage = {
              response: { data: { message: 'You are not authorised to login. Please contact an admin.' } },
              displayTextOnly: true,
            }

            localDispatch({ type: FAIL, payload: returnErrorMessage(errorMessage) })
            return
          }

          dispatch({
            type: LOGIN,
            payload: {
              id: userData.sub,
              tokenType: result.data.TokenType,
              accessToken: result.data.AccessToken,
              idToken: result.data.IdToken,
              refreshToken: result.data.RefreshToken,
              expiresInSec: expiresInSec,
              name: userData.name,
              email: userData.email,
              email_verified: userData.email_verified,
              accessLevel: accessLevel || 'webapp-access-group-1',
              isAdmin: cognitoGroups && cognitoGroups.includes('fd-admin'),
              isBackOfficeAdmin: cognitoGroups && cognitoGroups.includes('back-office-admin'),
              cognitoGroups: cognitoGroups && cognitoGroups.filter((group) => group.match(/(partner-id-.+|fd\d+)/)),
              multi_partner_view: userData['custom:multi_partner_view'] ?? 'name',
            },
          })
        } else {
          localDispatch({ type: FAIL, payload: returnErrorMessage() })
        }
      })
      .catch((error) => {
        localDispatch({
          type: FAIL,
          payload:
            error.response?.data?.message === 'error.auth.inactive' ? 'error.auth.inactive' : returnErrorMessage(error),
        })
      })
  } catch (error) {
    localDispatch({ type: FAIL, payload: returnErrorMessage(error) })
  }
}

export const resetUserPassword = async (dispatch, fields) => {
  dispatch({ type: LOADING })
  try {
    api
      .post('/auth/initiate_forgot_password', { email: fields.email })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: UPDATE,
            payload: {
              message: result.data,
              email_has_been_sent: true,
            },
          })
        } else {
          dispatch({ type: FAIL, payload: returnErrorMessage() })
        }
      })
      .catch((error) => {
        dispatch({ type: FAIL, payload: returnErrorMessage(error) })
      })
  } catch (error) {
    dispatch({ type: FAIL, payload: returnErrorMessage(error) })
  }
}

export const resetUserPasswordConfirm = async (dispatch, fields) => {
  dispatch({ type: LOADING })
  try {
    api
      .post('/auth/confirm_forgot_password', {
        email: fields.email,
        conf_code: fields.confirmCode,
        new_password: fields.password,
      })
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: UPDATE,
            payload: {
              message: result.data,
              confirmed: true,
            },
          })
        } else {
          dispatch({ type: FAIL, payload: returnErrorMessage() })
        }
      })
      .catch((error) => {
        dispatch({ type: FAIL, payload: returnErrorMessage(error) })
      })
  } catch (error) {
    dispatch({ type: FAIL, payload: returnErrorMessage(error) })
  }
}

let skip = false
export const fetchFeatures = async (dispatch) => {
  if (skip) {
    return
  }
  skip = true

  try {
    api.get('/features').then((result) => {
      if (result.data) {
        dispatch({
          type: UPDATE_FEATURES,
          payload: result.data,
        })
      }
    })
  } catch (e) {
    console.error(returnErrorMessage(e))
  }
  return
}

export const fetchPermissions = async (dispatch) => {
  try {
    api.get('/permissions').then((result) => {
      dispatch({
        type: UPDATE_PERMISSIONS,
        payload: result.data ?? [],
      })
    })
  } catch (e) {
    console.error(returnErrorMessage(e))
  }
  return
}

export const fetchUserProfile = async (dispatch, i18n) => {
  try {
    api.get('/profile').then((result) => {
      if (result.data) {
        dispatch({
          type: UPDATE_PROFILE,
          payload: {
            email: result.data.email,
            email_verified: result.data.email_verified,
            zoneinfo: result.data.zoneinfo,
            locale: result.data.locale,
            name: result.data.name,
            language: result.data.language,
            camp_notice_period: result.data.camp_notice_period,
            family_name: result.data.family_name,
            given_name: result.data.given_name,
            multi_partner_view: result.data.multi_partner_view,
          },
        })
        if (result.data && result.data.language) {
          changeLanguage(i18n, result.data.language)
        }
      }
    })
  } catch (e) {
    console.error(returnErrorMessage(e))
  }
  return
}

export const fetchProfileGroups = async (dispatch, defaultGroups) => {
  const cognitoGroups = store.getState().user?.cognitoGroups
  const config = {
    headers: { 'X-Preferred-Partner-Id': cognitoGroups[Math.floor(Math.random() * cognitoGroups.length)] },
  }
  dispatch({ type: LOADING })
  try {
    const result = await api.get('/profile/my-groups', config)
    if (result.status === 200) dispatch({ type: OVERWRITE, payload: result.data?.data ?? [] })
  } catch (e) {
    dispatch({ type: FAIL_FALLBACK, payload: { message: returnErrorMessage(e), items: defaultGroups } })
  }
}

export const updateUserProfile = async (dispatch, userProfile, localDispatch) => {
  try {
    api
      .put('/profile', userProfile)
      .then((result) => {
        if (result.status === 200) {
          dispatch({
            type: UPDATE_PROFILE,
            payload: userProfile,
          })
          localDispatch({ type: UPDATE, payload: 'common.settingsUpdate' })
        } else {
          localDispatch({ type: FAIL, payload: returnErrorMessage() })
        }
      })
      .catch((error) => {
        localDispatch({ type: FAIL, payload: returnErrorMessage(error) })
        console.error(error.response)
      })
  } catch (error) {
    localDispatch({ type: FAIL, payload: returnErrorMessage(error) })
  }
}

export const renewToken = async (dispatch, refreshToken) => {
  try {
    const result = await api.post('/auth/refresh_token', { token: refreshToken })
    if (result.data.AccessToken) {
      dispatch({
        type: UPDATE_TOKEN,
        payload: {
          tokenType: result.data.TokenType,
          accessToken: result.data.AccessToken,
          idToken: result.data.IdToken,
        },
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export const updateUser = async (fields) => {
  try {
    const result = await api.post(`/auth/partners/${fields.partner_id}/update_users`, {
      email: fields.email,
      password: fields.password,
      name: fields.name,
      partner_id: fields.partner_id,
    })

    if (result.data.success) {
      return { success: true, error: false }
    } else {
      return { success: false, error: true }
    }
  } catch (error) {
    return { error }
  }
}
export const newUser = async (dispatch, fields) => {
  dispatch({ type: LOADING })
  try {
    api
      .post(`/auth/partners/${fields.partner_id}/users`, {
        email: fields.email,
        password: fields.password,
        given_name: fields.given_name,
        family_name: fields.family_name,
        partner_id: fields.partner_id,
      })
      .then((result) => {
        if (result.status === 200 || result.status === 201) {
          dispatch({
            type: UPDATE,
            payload: {
              emailSent: true,
              confirmed: false,
            },
          })
        } else {
          Sentry.captureEvent({
            message: 'User registration failed',
            level: 'error',
            extra: {
              ...result,
              raw: JSON.stringify(result),
            },
          })
          dispatch({ type: FAIL, payload: returnErrorMessage() })
        }
      })
      .catch((error) => {
        Sentry.captureException(error)
        dispatch({ type: FAIL, payload: returnErrorMessage(error) })
      })
  } catch (error) {
    dispatch({ type: FAIL, payload: returnErrorMessage(error) })
  }
}

export const newUserConfirm = async (dispatch, fields) => {
  dispatch({ type: LOADING })
  try {
    api
      .post('/auth/partners/users/confirm', {
        email: fields.email,
        conf_code: fields.conf_code,
      })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: UPDATE, payload: { confirmed: true } })
        } else {
          dispatch({ type: FAIL, payload: returnErrorMessage() })
        }
      })
      .catch((error) => {
        dispatch({ type: FAIL, payload: returnErrorMessage(error) })
      })
  } catch (error) {
    dispatch({ type: FAIL, payload: returnErrorMessage(error) })
  }
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Fade from 'components/Shared/Wrappers/Fade'
import { Card } from 'react-bootstrap'
import { GreenTickIcon } from 'images'

const SelectTargetingMode = ({ setTargetMode, targetMode }) => {
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState({
    selective_mode: false,
    inclusive_mode: false,
  })

  const handleShowMore = (e, mode = null) => {
    e.stopPropagation()
    if (mode)
      setShowMore((prevState) => {
        return {
          ...prevState,
          [mode]: !prevState[mode],
        }
      })
  }

  const handleSelect = (mode = null) => {
    let payload = {}

    if (targetMode?.value !== 'selective_mode' && mode === 'selective_mode') payload = 'selective_mode'
    if (targetMode?.value !== 'inclusive_mode' && mode === 'inclusive_mode') payload = 'inclusive_mode'

    // setting targetMode of a user
    const currentLocalState = JSON.parse(localStorage.getItem('packageBuilderState'))
    currentLocalState.targetMode = payload
    localStorage.setItem('packageBuilderState', JSON.stringify(currentLocalState))
    setTargetMode(payload)
  }

  const displayMoreLess = (mode) => {
    return mode ? ` ${t('PackageBuilder.modes.less')}` : t('PackageBuilder.modes.more')
  }

  return (
    <Fade transitionDuration={1000} delay={0}>
      <div data-cy="second-step-screen">
        <h5 className="package-builder-page_title">{t('PackageBuilder.steps.selectTargetingMode')}</h5>
        <p className="package-builder-page_description">
          {t('PackageBuilder.steps.selectTargetingModeDescription')}
          <br />
        </p>
        <br />
        <div className="target-mode">
          <Card
            className={`target-mode_card ${targetMode === 'selective_mode' ? 'selected' : ''}`}
            onClick={() => handleSelect('selective_mode')}
          >
            <Card.Header>
              <span>{t('PackageBuilder.modes.selectiveMode')}</span>
              {targetMode === 'selective_mode' && <GreenTickIcon width={18} />}
            </Card.Header>
            <Card.Body>
              <span className={`${showMore.selective_mode ? 'text' : 'text_clipped'}`}>
                {t('PackageBuilder.modes.selectiveModeDescription')}
              </span>
              <span className="more" onClick={(e) => handleShowMore(e, 'selective_mode')}>
                {displayMoreLess(showMore.selective_mode)}
              </span>
            </Card.Body>
          </Card>

          <Card
            className={`target-mode_card ${targetMode === 'inclusive_mode' ? 'selected' : ''}`}
            onClick={() => handleSelect('inclusive_mode')}
          >
            <Card.Header>
              <span>{t('PackageBuilder.modes.inclusiveMode')}</span>
              {targetMode === 'inclusive_mode' && <GreenTickIcon width={18} />}
            </Card.Header>
            <Card.Body>
              <span className={`${showMore.inclusive_mode ? 'text' : 'text_clipped'}`}>
                {t('PackageBuilder.modes.inclusiveModeDescription')}
              </span>
              <span className="more" onClick={(e) => handleShowMore(e, 'inclusive_mode')}>
                {displayMoreLess(showMore.inclusive_mode)}
              </span>
            </Card.Body>
          </Card>
        </div>
      </div>
    </Fade>
  )
}

SelectTargetingMode.propTypes = {
  setTargetMode: PropTypes.func,
  targetMode: PropTypes.string,
}

export default SelectTargetingMode

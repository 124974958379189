import React from 'react'
import { useTranslation } from 'react-i18next'

import { WorldIcon } from 'images'
import { SecondaryButton } from 'components/Shared/Button'

export default function Billing() {
  const { t } = useTranslation()

  return (
    <>
      <div className="settings-page__sectionBody">
        <div className="settings-page__sectionColumn w-100">
          <div className="settings-page__sectionIcon">
            <WorldIcon width={17} />
          </div>
          <div className="settings-page__sectionWrapper">
            <div className="settings-page__sectionState flex-wrap">
              <h3 className="settings-page__sectionTitle">{t('Settings.system.billing.title')}</h3>
              <p className="settings-page__sectionValue">{t('Settings.system.billing.description')}</p>
            </div>
            <SecondaryButton
              color="navy"
              text={t('Settings.system.billing.button')}
              linkTo="https://pay.future-demand.com/p/login/3cs4hwa477Uu8AEfYY"
              classNames="settings-page_system__billing-btn"
            />
          </div>
        </div>
      </div>
    </>
  )
}

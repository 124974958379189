import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { formatEventSubheadlineLocation } from 'utils/helpers'
import { ClockIcon, PinIcon, VenueIcon } from 'images'

export default function EventCardDefault({ event }) {
  return (
    <div className="event-row_left-content_title-and-date_wrapper m-0">
      <div className="event-row_left-content_title-and-date_title d-block">{event.title}</div>
      <div className="event-row_left-content_title-and-date_date_wrapper d-flex">
        <span className="event-row_left-content_title-and-date_date_time">
          <span>
            <ClockIcon className="mr-1" height={12} width={17} />
          </span>
          <time dateTime={moment.utc(event.start_date_time).format('DD.MM.YYYY, HH:mm')}>
            {moment.utc(event.start_date_time).format('DD.MM.YYYY, HH:mm')}
          </time>
        </span>
        <span className="event-row_left-content_title-and-date_date_location">
          <span>
            <PinIcon className="mr-1" height={12} width={17} />
          </span>
          <span>
            {formatEventSubheadlineLocation({
              city: event.city,
            })}
          </span>
        </span>
        <span className="event-row_left-content_title-and-date_date_location">
          <span>
            <VenueIcon className="mr-1" height={12} width={17} />
          </span>
          <span>
            {formatEventSubheadlineLocation({
              hall: event.hall_name,
              venue: event.venue_name,
            })}
          </span>
        </span>
      </div>
    </div>
  )
}

EventCardDefault.propTypes = {
  event: PropTypes.object,
}

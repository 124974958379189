import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { DEFAULT_EVENT_FILTERS } from './eventFilters'

function SourceRow({ checked, title, setStatus, value }) {
  return (
    <div>
      <label className="event-control-panel_dropdown-menu_custom-checkbox">
        <input
          onChange={() => setStatus(checked ? DEFAULT_EVENT_FILTERS.source : value)}
          type="checkbox"
          checked={checked}
        />
        <span className="capitalize">{title}</span>
      </label>
    </div>
  )
}

SourceRow.propTypes = {
  checked: PropTypes.bool,
  title: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
  value: PropTypes.bool,
}

export default function SourceFilter({ state, dispatch, submit }) {
  const { t } = useTranslation()
  const isSimulated = state.source

  const setIsSimulated = (value) => {
    dispatch({ type: 'SOURCE', payload: value ?? null })
    submit({ ...state, source: value ?? null })
  }

  return (
    <div className="event-control-panel_dropdown-menu_checkbox-wrapper">
      <SourceRow setStatus={setIsSimulated} checked={isSimulated === true} title={t('Manual')} value={true} />
      <SourceRow setStatus={setIsSimulated} checked={isSimulated === false} title={'API'} value={false} />
    </div>
  )
}

SourceFilter.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
}

import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Row from './Row'
import { CustomerSegmentationContext, CustomerSegmentationDispatchContext } from './CustomerSegmentationContext'
import InfoBox from 'components/Shared/InfoBox'
import { FdSelect, OptionWithCheckbox } from 'components/Shared/ReactSelect'
import { ticketTypesOptions } from 'api/models'
import { actions } from './customerSegmentation'

export default function TicketCategories() {
  const { t } = useTranslation()
  const dispatch = useContext(CustomerSegmentationDispatchContext)
  const state = useContext(CustomerSegmentationContext)

  const options = useMemo(
    () =>
      ticketTypesOptions
        .filter((x) => !['cancelled', 'reserved'].includes(x.value))
        .map((type) => ({ value: type.value, label: t(type.text) })),
    [t],
  )

  const onChange = (selectedOptions) => {
    dispatch({ type: actions.setExcludedTicketCategories, payload: selectedOptions.map((option) => option.value) })
  }

  const selectedOptions = useMemo(
    () => options.filter((option) => state.excludedTicketCategories.includes(option.value)),
    [options, state.excludedTicketCategories],
  )

  return (
    <div>
      <InfoBox content="Tips.PackageBuilder.TicketCategory">
        <h6 className="d-inline-block">{t('PackageBuilder.customerSegmentation.ticketCategoryExclusion')}</h6>
      </InfoBox>
      <Row>
        <FdSelect
          name="ticket-categories"
          className="ticket-categories-select"
          placeholder={t('PackageBuilder.customerSegmentation.selectTicketCategory')}
          isMulti
          options={options}
          defaultValue={selectedOptions}
          blurInputOnSelect={false}
          closeMenuOnSelect={false}
          isClearable={false}
          menuPlacement="top"
          onChange={onChange}
          hideSelectedOptions={false}
          components={{
            Option: OptionWithCheckbox(),
          }}
        />
      </Row>
    </div>
  )
}

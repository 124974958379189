import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'

import { Card, Accordion } from 'react-bootstrap'
import { formatEventSubheadlineLocation } from 'utils/helpers'
import { Topics } from '../Variations/VariationsCard'

import clock from 'images/icons/clock.svg'
import pin from 'images/icons/pin.svg'

const EventCard = ({ event, t }) => {
  const [activeKey, setActiveKey] = useState(null)

  return (
    <Accordion defaultActiveKey={null}>
      <Card>
        <Accordion.Toggle eventKey="0">
          <Card.Header
            as={Card.Header}
            className={classNames({
              'events-card_header pt-3': true,
            })}
            onClick={() => setActiveKey(activeKey && activeKey === '0' ? null : '0')}
          >
            <div className="event-row_wrapper">
              <div className="title">{event.title}</div>
              {activeKey === '0' && (
                <div className="event-row_additional-info">
                  <span className="event-row_date-time">
                    <img src={clock} alt="clock-icon" />
                    <time dateTime={moment.utc(event.start_date_time).format('DD.MM.YYYY, HH:mm')}>
                      {moment.utc(event.start_date_time).format('DD.MM.YYYY, HH:mm')}
                    </time>
                  </span>
                  <span className="event-row_location">
                    <img src={pin} alt="venue-icon" />
                    <span>
                      {formatEventSubheadlineLocation({
                        hall: event.hall_name,
                        venue: event.venue_name,
                      })}
                    </span>
                  </span>
                </div>
              )}
            </div>
            <div className="align-self-center ml-auto">
              <div className={`accordion-arrow ${activeKey === '0' ? 'open' : ''}`} />
            </div>
          </Card.Header>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="pt-0 pb-2">
            <>
              <hr className="divider mt-0 mb-3" />
              <div className="p-0 pb-1">
                <div className="events-card_topic-title">{t('PackageBuilder.packageBuilderResults.topics')}</div>
                <div>
                  <Topics keywords={event.keywords} key={`${event.title}-side-drawer-`} limit={event.keywords.length} />
                </div>
              </div>
            </>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}

EventCard.propTypes = {
  event: PropTypes.object,
  t: PropTypes.func,
}

export default EventCard

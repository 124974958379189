import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import Field from 'components/Recommendations/Campaign/Edit/CampaignForm/Field'
import DropDown from 'components/Shared/DropDown'
import { useTranslation } from 'react-i18next'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'

import PostalCodes from './PostalCodes'
import RangeLocations from './Range'
import { useTargeting, useTargetingDispatch } from './TargetingContext'
import {
  TARGETING_MODES,
  actions,
  getDefaultTargetingMode,
  getLocationTargetingModeOptions,
  validateLocationTargeting,
} from './targeting'
import { SetupContext } from '../../CampaignSetupProvider'

export function GeoTargetingField({ tc, tc_run_id, disabled, onChange, showValidationErrors }) {
  const { t } = useTranslation()
  const hasPrismaAccess = hasPrismaAccessTier()
  const [isOpen, setIsOpen] = React.useState(false)

  const dispatch = useTargetingDispatch()
  const { value: targeting } = useTargeting()
  const tcTargeting = useMemo(() => targeting.find((x) => x.tc === tc) ?? { tc }, [targeting, tc])
  const { targetingMode, postalCodes, rangeLocations } = tcTargeting

  const setup = useContext(SetupContext)
  const hasCustomAudience = useMemo(() => {
    const tcCreative = setup.creatives.find((x) => x.tc === tc)
    return !!tcCreative?.audience_id
  }, [setup, tc])

  const options = useMemo(
    () => getLocationTargetingModeOptions(hasPrismaAccess, hasCustomAudience),
    [hasPrismaAccess, hasCustomAudience],
  )
  const currentTargetingMode = targetingMode ?? getDefaultTargetingMode(hasPrismaAccess, hasCustomAudience)

  const onModeChange = useCallback(
    (mode) => {
      dispatch({ type: actions.setLocationTargetingMode, payload: { tc, tc_run_id, targetingMode: mode } })

      const payload = {
        targetingMode: mode,
      }

      if (mode === TARGETING_MODES.postalCodes) {
        payload.postalCodes = postalCodes
      } else if (mode === TARGETING_MODES.rangeLocations) {
        payload.rangeLocations = rangeLocations
      }

      onChange(payload)
    },
    [dispatch, onChange, postalCodes, rangeLocations, tc, tc_run_id],
  )

  const onPostalCodesChange = useCallback(
    ({ countries }) => {
      if (!countries) {
        dispatch({ type: actions.resetLocation, payload: { tc, tc_run_id } })
        onChange({ targetingMode: currentTargetingMode, postalCodes: {} })
        return
      }

      dispatch({
        type: actions.setLocationPostalCodes,
        payload: { tc, tc_run_id, postalCodes: { countries } },
      })
      onChange({ targetingMode: currentTargetingMode, postalCodes: { countries } })
    },
    [dispatch, onChange, currentTargetingMode, tc, tc_run_id],
  )

  const onRangeLocationsChange = useCallback(
    ({ rangeLocations }) => {
      dispatch({
        type: actions.setRangeLocations,
        payload: { tc, tc_run_id, rangeLocations },
      })
      onChange({ targetingMode: currentTargetingMode, rangeLocations: { ...rangeLocations } })
    },
    [dispatch, onChange, currentTargetingMode, tc, tc_run_id],
  )

  useEffect(() => {
    // If targeting mode is fdTargeting (or not set, which defaults to fdTargeting) and the option is not available, set the default targeting mode
    // This happens when a custom audience is selected, which removes the fdTargeting option
    // from the dropdown.
    if (
      (!targetingMode || targetingMode === TARGETING_MODES.fdTargeting) &&
      !options.find((x) => x.value === TARGETING_MODES.fdTargeting)
    ) {
      onModeChange(getDefaultTargetingMode(hasPrismaAccess, hasCustomAudience))
    }
  }, [targetingMode, hasCustomAudience, hasPrismaAccess, onModeChange, options])

  const validationError = useMemo(() => {
    if (!showValidationErrors) return null
    const { valid, code } = validateLocationTargeting(targeting, tc, tc_run_id, hasCustomAudience)
    if (valid || !code) return null

    return t(`Recommendations.geoTargeting.validationMsgs.${code}`)
  }, [showValidationErrors, targeting, tc, tc_run_id, t, hasCustomAudience])

  return (
    <div className="creative-composer-section-wrapper geo-targeting-field">
      <Field
        fieldName="geo_targeting"
        as={DropDown}
        value={currentTargetingMode}
        disabledText={t(`Recommendations.geoTargeting.options.${currentTargetingMode}`)}
        onChange={onModeChange}
        required={false}
        disabled={disabled || options.length === 1}
        options={options}
        classNames="geo-targeting-dropdown"
        inputWhenDisabled
        tooltip={{
          title: t('Tips.Recommendations.CreativeComposer.GeoTargeting.title'),
          body: (
            <div className="d-flex flex-column gap-2" style={{ fontSize: '.875rem', fontWeight: 500 }}>
              <p>{t('Tips.Recommendations.CreativeComposer.GeoTargeting.1')}</p>
            </div>
          ),
        }}
        errorMessage={validationError}
      />
      <PostalCodes tc={tc} onChange={onPostalCodesChange} isOpen={isOpen} setIsOpen={setIsOpen} />
      <RangeLocations tc={tc} onChange={onRangeLocationsChange} isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  )
}

GeoTargetingField.propTypes = {
  tc: PropTypes.string.isRequired,
  tc_run_id: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  showValidationErrors: PropTypes.bool,
}

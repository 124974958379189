import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDistance } from 'date-fns'
import { de, enUS } from 'date-fns/locale'

import { CreativesContext, RetryDispatchContext } from './CampaignSetupProvider'

export default function AutosaveStatus() {
  const { t, i18n } = useTranslation()
  const creativesState = useContext(CreativesContext)
  const dispatchRetry = useContext(RetryDispatchContext)
  const [currentDate, setCurrentDate] = useState(Date.now())

  useEffect(() => {
    const interval = setInterval(() => setCurrentDate(Date.now()), 10000)

    return () => clearInterval(interval)
  }, [])

  if (!creativesState.hasSaved) return null

  return (
    <div className="autosave-status">
      {creativesState.hasFailures ? (
        <div className="autosave-error">
          <p>
            {t('Recommendations.campaign.setup.autoSave.fail')}
            &nbsp;&nbsp;
            <button disabled={creativesState.loading} onClick={dispatchRetry}>
              {t('Recommendations.campaign.setup.autoSave.retry')}
            </button>
          </p>
        </div>
      ) : (
        <p className="autosave-success">
          {t('Recommendations.campaign.setup.autoSave.success', {
            distance: formatDistance(creativesState.lastUpdatedAt, currentDate, {
              addSuffix: true,
              locale: i18n.language === 'de' ? de : enUS,
            }),
          })}
        </p>
      )}
    </div>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

const TipDialogueBox = ({ title, text, variant, showBorder = false, compact = false, active = false, onClick }) => {
  if (!title && !text) {
    return null
  }

  return (
    <article
      className={`tip-box_dialogue_wrapper tip-box_dialogue_wrapper--${variant} ${showBorder ? 'tip-box_dialogue_wrapper--with-border' : ''} ${compact ? 'tip-box_dialogue_wrapper--compact' : ''} ${active ? 'tip-box_dialogue_wrapper--active' : ''}`}
      onClick={onClick}
    >
      {title && (
        <div className="tip-box_dialogue_title_wrapper">
          <h4 className="tip-box_dialogue_title_content">{title}</h4>
        </div>
      )}
      {text && <p className="tip-box_dialogue_text">{text}</p>}
    </article>
  )
}

TipDialogueBox.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
  showBorder: PropTypes.bool,
  compact: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func,
}

TipDialogueBox.defaultProps = {
  variant: 'warning',
  showBorder: false,
  compact: false,
  active: false,
}

export default TipDialogueBox

import PropTypes from 'prop-types'
import React from 'react'

import logo from '../../images/logos/primary_navy.svg'

/**
 * Loading indicator
 * @Tags( shared, presentational )
 */
const LoadingApp = () => {
  return (
    <div className="loading-spinner-window">
      <header className="loading-spinner-window__header">
        <img className="img-fluid" src={logo} alt="" />
      </header>

      <div className="loading-spinner">
        <span className="loading-spinner__circle loading-spinner__circle--poral-bg">
          <span className="loading-spinner__circle__line loading-spinner__circle__line--1"></span>
          <span className="loading-spinner__circle__line loading-spinner__circle__line--2"></span>
          <span className="loading-spinner__circle__line loading-spinner__circle__line--3"></span>
        </span>
        <span className="loading-spinner__title">Loading...</span>
      </div>
    </div>
  )
}

LoadingApp.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  /**
   *  It sets spinner background to portal background.
   */
  portalBackground: PropTypes.bool,
}

LoadingApp.defaultProps = {
  title: 'Loading...',
  portalBackground: false,
}

export default LoadingApp

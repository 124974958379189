import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { fetchCampaigns } from 'api/campaigns'
import { usePagedList } from 'hooks/usePagedList'
import { FAIL, initStateObject, objectReducer, OVERWRITE, UPDATE } from 'reducers/default'
import {
  AssetClass,
  buildSetupPayload,
  clearDisabledVariationsCache,
  isCampaignRunning,
  validateCreative,
  validateIntegrations,
} from 'api/models'
import { boostSetupProcess, deleteSetupProcess, updateSetupProcess } from 'api/setupProcesses'
import PortalModal from 'components/Shared/PortalModal'
import CancelSetupModalContent from '../../CancelSetupModalContent'
import ErrorMessage from 'components/Shared/ErrorMessage'
import { Accounts, Ads, Summary } from '..'
import { allFilesUploaded } from 'components/Recommendations/Campaign/Media/media'
import { orderBy, throttle } from 'lodash'
import { deleteUserReports } from 'api/userReports'
import { SetupContext, SetupDispatchContext } from 'components/Recommendations/Campaign/Edit/CampaignSetupProvider'
import { validateLocationTargeting } from 'components/Recommendations/Campaign/Edit/CampaignForm/Targeting/targeting'
import { useTargetingDispatch } from 'components/Recommendations/Campaign/Edit/CampaignForm/Targeting/TargetingContext'
import { actions as targetingActions } from 'components/Recommendations/Campaign/Edit/CampaignForm/Targeting/targeting'
import useHasPermission, { Permissions } from 'hooks/useHasPermission'

const SetupWrapper = ({
  event,
  onSetupCancelled,
  onSetupComplete,
  onNext,
  onBack,
  child,
  setCampaigns,
  initialGoal,
}) => {
  const setupProcess = useContext(SetupContext)
  const { t } = useTranslation()
  const [showCancelSetupModal, setShowCancelSetupModal] = useState(false)
  const dispatchSetupAction = useContext(SetupDispatchContext)
  const dispatchTargetingAction = useTargetingDispatch()
  const hasWaveAccess = useHasPermission(Permissions.wave)

  const eventGoal = useMemo(() => {
    if (!setupProcess?.goal) return initialGoal

    const goalParts = setupProcess.goal.split('.')
    return goalParts[goalParts.length - 1]
  }, [setupProcess, initialGoal])

  const fetchCampaignsPage = useCallback((dispatch, eventId, page, controller) => {
    fetchCampaigns(dispatch, eventId, page, null, controller)
  }, [])

  const { list: allCampaigns } = usePagedList(fetchCampaignsPage, {
    params: event.id,
    containerSelector: '#campaigns-container',
  })

  const [boostState, dispatchBoostAction] = useReducer(objectReducer, initStateObject)
  const [internalSetupProcessState, dispatchInternalSetupAction] = useReducer(objectReducer, {
    ...initStateObject,
    content: {
      ...setupProcess,
      creativesValid: setupProcess?.creatives?.every((x) => validateCreative(x, { setupOnly: false }).valid),
      integrationsValid: validateIntegrations(setupProcess.integration_details),
      conversion_id: '',
    },
  })

  const latestSelectedCampaign = useMemo(() => {
    if ((allCampaigns?.items ?? []).length) {
      const runningCampaigns = allCampaigns.items.filter((campaign) => isCampaignRunning(campaign))
      const orderedCampaigns = orderBy(runningCampaigns, ['initial_set_up_date'], 'desc')
      return orderedCampaigns.length ? orderedCampaigns[0] : {}
    }
    return {}
  }, [allCampaigns])

  const defaultConversionId = useMemo(() => {
    return latestSelectedCampaign?.latest_campaign_setup?.conversion_id
      ? latestSelectedCampaign?.latest_campaign_setup?.conversion_id
      : setupProcess?.conversion_id
  }, [latestSelectedCampaign, setupProcess])

  const previousIntegrations = useMemo(() => {
    const latestIntegrationDetails = latestSelectedCampaign?.latest_campaign_setup?.integration_details ?? []
    return latestIntegrationDetails.length
      ? latestIntegrationDetails.filter((x) => x.asset_class !== AssetClass.profile)
      : []
  }, [latestSelectedCampaign])

  useEffect(() => {
    if (boostState.success) {
      onNext()
      onSetupComplete()
      clearDisabledVariationsCache(event.id)
    }
  }, [boostState.success, onNext, onSetupComplete, event.id])

  useEffect(() => {
    setCampaigns(allCampaigns.items)
  }, [allCampaigns, setCampaigns])

  useEffect(() => {
    const sideDrawerComponent = document.getElementById('side-drawer-content')

    const handleScroll = () => {
      const buttonContainerElem = document.getElementById('setup-btn-container')
      if (!buttonContainerElem) return
      const extraOffset = 20
      if (
        sideDrawerComponent.offsetHeight + sideDrawerComponent.scrollTop + extraOffset >=
        sideDrawerComponent.scrollHeight
      )
        // remove box shadow when scrolled to bottom
        buttonContainerElem.style.boxShadow = 'none'
      else {
        buttonContainerElem.style.boxShadow = '1px -3px 8px rgba(0, 0, 0, 0.5)'
      }
    }
    const throttledScroll = throttle(handleScroll, 200)

    if (sideDrawerComponent) sideDrawerComponent.addEventListener('scroll', throttledScroll)

    return () => {
      if (sideDrawerComponent) sideDrawerComponent.removeEventListener('scroll', throttledScroll)
    }
  }, [])

  const cancelSetup = async () => {
    dispatchTargetingAction({ type: targetingActions.reset })
    await deleteUserReports(dispatchInternalSetupAction, { setup_process_ids: [setupProcess?.id] })
    deleteSetupProcess((action) => {
      dispatchInternalSetupAction(action)
      if (action.type === OVERWRITE) onSetupCancelled()
    }, event.id)
    clearDisabledVariationsCache(event.id)
  }

  const onCreativeSaved = useCallback(
    (tc, creativeForm, { defaultCta, defaultDestinationUrl } = {}) => {
      const process = { ...internalSetupProcessState.content }
      const index = process.creatives.findIndex((x) => x.tc === tc)
      const existingCreative = process.creatives[index]
      process.creatives[index] = { ...existingCreative, ...creativeForm }
      process.creativesValid = process.creatives.every((x) => validateCreative(x, { setupOnly: false }).valid)

      if (defaultCta) {
        for (const creative of process.creatives.filter((x) => !x.call_to_action)) {
          creative.call_to_action = defaultCta
        }
      }

      if (defaultDestinationUrl) {
        for (const creative of process.creatives.filter((x) => !x.destination_url)) {
          creative.destination_url = [defaultDestinationUrl]
        }
      }

      dispatchInternalSetupAction({ type: OVERWRITE, payload: process })
      dispatchSetupAction({ type: OVERWRITE, payload: process })
    },
    [internalSetupProcessState.content, dispatchSetupAction],
  )

  const onDisabledVariationsChanged = useCallback(
    (disabledVariations) => {
      const process = { ...internalSetupProcessState.content }
      for (const [key, value] of Object.entries(disabledVariations)) {
        const creative = process.creatives.find((x) => x.tc === key)
        if (creative) creative.excluded_variations = value
      }
      dispatchInternalSetupAction({ type: OVERWRITE, payload: process })
    },
    [internalSetupProcessState.content],
  )

  const onIntegrationsChanged = useCallback(
    (selectedIntegrations) => {
      if (Object.keys(internalSetupProcessState.content).length) {
        const process = { ...internalSetupProcessState.content }
        process.integration_details = selectedIntegrations
        process.integrationsValid = validateIntegrations(selectedIntegrations)
        process.conversion_id = defaultConversionId ?? ''
        dispatchInternalSetupAction({ type: OVERWRITE, payload: process })
        updateSetupProcess(
          dispatchSetupAction,
          event.id,
          buildSetupPayload(process),
        )
      }
    },
    [internalSetupProcessState.content, dispatchSetupAction, event.id, defaultConversionId],
  )

  const onConversionGoalChanged = useCallback(
    (conversionId) => {
      if (Object.keys(internalSetupProcessState.content).length) {
        const process = { ...internalSetupProcessState.content }
        process.conversion_id = conversionId ?? ''
        dispatchInternalSetupAction({ type: UPDATE, payload: process })
        updateSetupProcess(
          dispatchSetupAction,
          event.id,
          buildSetupPayload(process),
        )
      }
    },
    [internalSetupProcessState.content, dispatchSetupAction, event.id],
  )

  const onTargetingChanged = useCallback(
    (targeting) => {
      if (Object.keys(internalSetupProcessState.content).length) {
        const process = { ...internalSetupProcessState.content }
        process.targeting = targeting
        dispatchInternalSetupAction({ type: UPDATE, payload: process })
      }
    },
    [internalSetupProcessState.content],
  )

  const boost = () => {
    const payload = buildSetupPayload(internalSetupProcessState.content, {
      setupOnly: false,
    })

    updateSetupProcess(
      (action) => {
        if (action.type === FAIL) dispatchInternalSetupAction({ type: FAIL, payload: action.payload })
        else if (action.type === OVERWRITE) {
          dispatchInternalSetupAction({
            type: action.type,
            payload: {
              ...action.payload,
              creativesValid: internalSetupProcessState.content.creativesValid,
              integrationsValid: internalSetupProcessState.content.integrationsValid,
            },
          })

          boostSetupProcess(dispatchBoostAction, event.id)
        } else {
          dispatchInternalSetupAction(action)
        }
      },
      event.id,
      payload,
    )
  }

  const onAdCreationComplete = () => {
    const payload = buildSetupPayload(internalSetupProcessState.content)

    updateSetupProcess(
      (action) => {
        if (action.type === OVERWRITE) {
          dispatchInternalSetupAction({
            type: action.type,
            payload: { ...internalSetupProcessState.content, ...action.payload },
          })
          onNext()
        } else {
          dispatchInternalSetupAction(action)
        }
      },
      event.id,
      payload,
    )
  }

  if (!setupProcess?.creatives?.length) {
    throw new Error('SetupProcess is missing or invalid. Cannot proceed.')
  }

  const selectedTcs = new Set(setupProcess?.creatives?.map((c) => c.tc))
  const campaigns = allCampaigns.items.filter((campaign) => selectedTcs.has(campaign.tc))

  const hasCustomAudience = useCallback(
    (tc) => {
      const tcCreative = internalSetupProcessState.content.creatives.find((x) => x.tc === tc)
      return !!tcCreative?.audience_id
    },
    [internalSetupProcessState.content.creatives],
  )
  const targeting = internalSetupProcessState.content.targeting ?? []
  const targetingValid = targeting.every(
    (x) => validateLocationTargeting(targeting, x.tc, x.tc_run_id, hasCustomAudience(x.tc)).valid,
  )

  if (internalSetupProcessState.content.cancelled) return null

  const canCreateAds =
    hasWaveAccess &&
    internalSetupProcessState.content.integrationsValid &&
    !boostState.loading

  const canShowSummary =
    hasWaveAccess &&
    internalSetupProcessState.content.creativesValid &&
    internalSetupProcessState.content.creatives.every((c) => allFilesUploaded(c.media)) &&
    internalSetupProcessState.content.integrationsValid &&
    targetingValid &&
    !boostState.loading

  const defaultIntegrations = internalSetupProcessState.content.integration_details ?? []

  const renderElements = () => {
    switch (child) {
      case 'Accounts':
        return (
          <Accounts
            event={event}
            eventGoal={eventGoal}
            defaultConversionId={defaultConversionId}
            onIntegrationsChanged={onIntegrationsChanged}
            setShowCancelSetupModal={setShowCancelSetupModal}
            defaultIntegrations={defaultIntegrations}
            previousIntegrations={previousIntegrations}
            onConversionGoalChanged={onConversionGoalChanged}
            canCreateAds={canCreateAds}
            onBack={onBack}
            onNext={onNext}
          />
        )
      case 'Ads':
        return (
          <Ads
            event={event}
            campaigns={campaigns}
            onCreativeSaved={onCreativeSaved}
            setShowCancelSetupModal={setShowCancelSetupModal}
            onAdCreationComplete={onAdCreationComplete}
            canShowSummary={canShowSummary}
            onBack={onBack}
            onTargetingChanged={onTargetingChanged}
          />
        )
      case 'Summary':
        return (
          <Summary
            campaigns={campaigns}
            boost={boost}
            onBack={onBack}
            setShowCancelSetupModal={setShowCancelSetupModal}
            totalBudget={internalSetupProcessState?.content?.total_budget}
            creatives={internalSetupProcessState?.content?.creatives}
            runtimeStartDate={setupProcess.start_date}
            runtimeEndDate={setupProcess.end_date}
            integrationDetails={setupProcess?.integration_details}
            canShowSummary={canShowSummary}
            onDisabledVariationsChange={onDisabledVariationsChanged}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      {internalSetupProcessState.error && <ErrorMessage danger>{t(internalSetupProcessState.message)}</ErrorMessage>}
      {boostState.error && <ErrorMessage danger>{t(boostState.message)}</ErrorMessage>}
      {renderElements()}
      <PortalModal isOpen={showCancelSetupModal} onClickOutside={() => setShowCancelSetupModal(false)}>
        <CancelSetupModalContent onCancel={() => setShowCancelSetupModal(false)} onConfirm={() => cancelSetup()} />
      </PortalModal>
    </>
  )
}

SetupWrapper.propTypes = {
  event: PropTypes.object.isRequired,
  step: PropTypes.number.isRequired,
  initialBudget: PropTypes.number.isRequired,
  onSetupCancelled: PropTypes.func.isRequired,
  onSetupComplete: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  setCampaigns: PropTypes.func.isRequired,
  child: PropTypes.string.isRequired,
  initialGoal: PropTypes.string,
}

export default SetupWrapper

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withRouter, useHistory } from 'react-router'
import React from 'react'

import { useSelector } from 'react-redux'
// import { isFeatureFlagOn, SHOW_NOTIFICATION } from '../../utils/flag-feature'

/**
 * Mobile top bar dynamic content.
 * @Tags( shared, layout, mobile, presentational )
 */
const MobileTopBar = ({
  back,
  backToDash = false,
  center,
  centerTitle,
  hideRight,
  addHistoryRoute = null,
  historyCallback = null,
  rightMenu = null,
}) => {
  const history = useHistory()
  const { goBack } = history

  const user = useSelector((state) => state.user)
  // const features = useSelector( store => store.user.features )

  const handleBack = () => {
    if (addHistoryRoute) {
      history.push(addHistoryRoute)
      historyCallback()
    } else {
      goBack()
    }
  }

  const renderRightCol = () => {
    if (user.isLogged) {
      return <div className="user-nav-mobile">{rightMenu}</div>
    } else {
      return (
        <Link to="/login">
          <i className="icon-user" />
        </Link>
      )
    }
  }

  return (
    <header className="mobile-top-bar">
      <div className="mobile-top-bar__left">
        {back && (
          <button onClick={handleBack}>
            <i className="icon-caret-left" />
          </button>
        )}
        {backToDash && (
          <Link to="/">
            <i className="icon-caret-left" />
          </Link>
        )}
      </div>
      <div className="mobile-top-bar__center">
        {centerTitle && <div className="mobile-top-bar__title">{centerTitle}</div>}
        {center}
      </div>
      <div className="mobile-top-bar__right">{!hideRight && renderRightCol()}</div>
    </header>
  )
}

MobileTopBar.propTypes = {
  /**
   * If true show back button
   */
  back: PropTypes.bool,
  backToDash: PropTypes.bool,
  /**
   * Optional HTML insead of centerTitle content
   */
  center: PropTypes.node,
  centerTitle: PropTypes.string,
  hideRight: PropTypes.bool,
  addHistoryRoute: PropTypes.string,
  historyCallback: PropTypes.func,
  rightMenu: PropTypes.element,
}

export default withRouter(MobileTopBar)

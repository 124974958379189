import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { usePopper } from 'react-popper'
import PropTypes from 'prop-types'

import Topic from 'components/Shared/Topic'
import { SecondaryButton } from 'components/Shared/Button'
import Textarea from 'components/Shared/Textarea'
import { CancelIcon } from 'images'
import { useTranslation } from 'react-i18next'
import { createUserReport } from 'api/userReports'
import { boolReducer, initStateBool } from 'reducers/default'
import InfoBox from 'components/Shared/InfoBox'
import Portal from 'components/Shared/Portal'

const INITIAL_SECONDS = 10

const ThanksPopup = ({
  onSend,
  onExit,
  onCommentChange,
  comment,
  popperElement,
  setPopperElement,
  setArrowElement,
  attributes,
  styles,
}) => {
  const { t } = useTranslation()
  const [seconds, setSeconds] = useState(INITIAL_SECONDS)

  useEffect(() => {
    if (!comment) {
      setSeconds(INITIAL_SECONDS)
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1)
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [comment])

  useEffect(() => {
    if (seconds === 0) onExit()
  }, [seconds, onExit])

  useEffect(() => {
    function handleClickOutside(event) {
      if (popperElement && !popperElement?.contains(event.target)) {
        onExit()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onExit, popperElement])

  return (
    <Portal wrapperId="thanks-overlay">
      <div ref={setPopperElement} className="thanks p-3" style={styles.popper} {...attributes}>
        <div className="d-flex justify-content-between align-items-center pb-1">
          <h5>{t('Recommendations.campaign.setup.reportTopicRecommendations.thanks')}</h5>
          <div className="d-flex align-items-center gap-2">
            {
              <span className={`thanks_counter ${comment ? 'fadeout' : 'fadein'}`}>
                {seconds} {t('SessionTimer.second')}
              </span>
            }
            <button onClick={onExit} className="thanks_close-btn-wrapper">
              <CancelIcon height={12} className="modal_inner_header_close-btn_icon" />
            </button>
          </div>
        </div>
        <p className="pb-2">{t('Recommendations.campaign.setup.reportTopicRecommendations.description')}</p>
        <Textarea
          value={comment}
          onChange={(e) => onCommentChange(e)}
          placeholder={t('Recommendations.campaign.setup.reportTopicRecommendations.addComment')}
          cols={50}
        />
        <div className="text-right">
          <SecondaryButton
            disabled={!comment}
            text={t('common.send')}
            size="small"
            classNames="px-3 py-2"
            onClick={onSend}
          />
        </div>
        <div className="arrow" data-popper-arrow ref={setArrowElement} style={styles.arrow} />
      </div>
    </Portal>
  )
}

ThanksPopup.propTypes = {
  onSend: PropTypes.func,
  onExit: PropTypes.func,
  onCommentChange: PropTypes.func,
  comment: PropTypes.string,
  popperElement: PropTypes.any,
  setPopperElement: PropTypes.func,
  setArrowElement: PropTypes.func,
  styles: PropTypes.object,
  attributes: PropTypes.object,
}

const ReportableTopic = ({ topic, index, eventId, setupProcessId, campaign, reportsEnabled = true, charLimit = 30 }) => {
  const { t } = useTranslation()
  const [reportingState, reportTopicDispatch] = useReducer(boolReducer, initStateBool)
  const [showPopup, setShowPopup] = useState(false)
  const [comment, setComment] = useState('')
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)
  const isTopicDisabled = useMemo(() => reportingState.success || topic.is_reported, [topic, reportingState])
  const tooltipBody = useMemo(() => {
    if (showPopup) return null

    if (isTopicDisabled) return t('Recommendations.campaign.setup.reportTopicRecommendations.alreadyReported')

    return t('Recommendations.campaign.setup.reportTopicRecommendations.reportTooltip')
  }, [t, isTopicDisabled, showPopup])

  const modifiers = useMemo(
    () => [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: ({ placement }) => {
            if (placement === 'top') {
              return [0, 20]
            }

            return [0, 10]
          },
        },
      },
    ],
    [arrowElement],
  )

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    modifiers,
  })

  const handleCommentChange = (event) => {
    setComment(event.target.value)
  }

  const handleReportTopic = async (withComment = false) => {
    const payload = {
      description: withComment && comment ? comment : '_',
      report_type: 'WRONG_TOPIC_RECOMMENDATION',
      event_id: eventId,
      tc: campaign.tc,
      tc_run_id: campaign.tc_run_id,
      keyword: topic.keyword,
      setup_process_id: setupProcessId,
    }

    if (showPopup) {
      setShowPopup(false)
      await createUserReport(reportTopicDispatch, payload)
      setComment('')
    }
  }

  return (
    <span style={{ position: 'relative' }}>
      {reportsEnabled && <InfoBox body={tooltipBody} withAnchor={false} tooltipClassNames="topic-tooltip">
        <span className="c-hover" onClick={() => !isTopicDisabled && reportsEnabled && setShowPopup(true)} ref={setReferenceElement}>
          <Topic disabled={isTopicDisabled} keyword={topic.keyword} index={index} charLimit={charLimit} />
        </span>
      </InfoBox>}
      {!reportsEnabled && <Topic disabled={isTopicDisabled} keyword={topic.keyword} index={index} charLimit={charLimit} />}
      {showPopup && (
        <ThanksPopup
          onSend={() => handleReportTopic(true)}
          onExit={() => handleReportTopic()}
          onCommentChange={handleCommentChange}
          comment={comment}
          popperElement={popperElement}
          setPopperElement={setPopperElement}
          setArrowElement={setArrowElement}
          attributes={attributes.popper}
          styles={styles}
        />
      )}
    </span>
  )
}

ReportableTopic.propTypes = {
  topic: PropTypes.object,
  index: PropTypes.number,
  charLimit: PropTypes.number,
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setupProcessId: PropTypes.number,
  campaign: PropTypes.object,
  reportsEnabled: PropTypes.bool,
}

export default ReportableTopic

import PropTypes from 'prop-types'
import React from 'react'

import MobileTopBar from './MobileTopBar'
import MainNav from './MainNav'
import MobileNav from './MobileNav'

/**
 * Shared - Global Header for logged users
 * @Tags( layout, presentational )
 */
const Header = ({ mobileTopBar }) => (
  <>
    <MobileTopBar {...mobileTopBar} />
    <MainNav />
    <MobileNav />
  </>
)

Header.propTypes = {
  /**
   * Parameters passed to MobileTopBar component
   */
  mobileTopBar: PropTypes.object,
}

Header.defaultProps = {}

export default Header

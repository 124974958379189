import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import { actions, asyncActionHandlers, initialState, reducer } from './emailNotificationSettings'

import { SecondaryButton } from 'components/Shared/Button'
import ErrorFallback from 'components/Shared/ErrorFallback'
import useIsFirstRender from 'hooks/useFirstRender'
import { useReducerAsync } from 'hooks/useReducerAsync'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'

const EmailNotificationSettings = () => {
  const { t } = useTranslation()
  const [state, dispatch] = useReducerAsync(reducer, initialState, asyncActionHandlers)
  const firstRender = useIsFirstRender()
  const hasPrismaAccess = hasPrismaAccessTier()

  useEffect(() => {
    if (firstRender) dispatch({ type: actions.fetchSettingsAsync })
  }, [firstRender, dispatch])

  const handleToggle = (setting) => {
    dispatch({ type: actions.updateSettingAsync, payload: setting })
  }

  const handleToggleAll = () => {
    dispatch({ type: actions.patchSettingsAsync })
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="settings-page__section__header">
        <div className="action-bar">
          <p className="subtitle">{t('Settings.NotificationSettings.subtitle')}</p>
          <div className="controls">
            <SecondaryButton
              text={t('Settings.NotificationSettings.selectAll')}
              fullWidth={false}
              onClick={() => handleToggleAll()}
              disabledTitle={t('Settings.NotificationSettings.selectAll')}
              disabled={
                state.loading ||
                !state.settings.length ||
                state.settings.every((setting) => setting.is_email_enabled)
              }
              size="small"
            />
          </div>
        </div>
      </div>
      {state.error && (
        <div className="settings-page__section">
          <p className="warning-red">
            {t([state.error?.message ?? '', 'common.errors.inServerResponse'])}
          </p>
        </div>
      )}
      <>
        {state.settings
          .filter((x) => !hasPrismaAccess || x.notification_topic.toLowerCase() !== 'event')
          .map((setting) => (
            <div className="settings-page__section" key={setting.notification_topic}>
              <div className="settings-page__sectionBody">
                <div className="settings-page__sectionWrapper nowrap">
                  <div className="settings-page__sectionState position-relative">
                    {setting.notification_topic.charAt(0).toUpperCase() +
                      setting.notification_topic.slice(1).toLowerCase()}
                  </div>
                  <div className="settings-page__sectionColumn">
                    <label className="custom-check notification-setting custom-check--navy">
                      <div>
                        <input
                          className="custom-check__input"
                          id={setting.notification_topic}
                          type="checkbox"
                          onChange={() => handleToggle(setting)}
                          checked={setting.is_email_enabled}
                        />
                        <span className="custom-check__box"></span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </>
    </ErrorBoundary>
  )
}

export default EmailNotificationSettings

import React, { createContext } from 'react'
import PropTypes from 'prop-types'

import metaAuthorizationApi from 'api/connect/meta'
import { useReducerAsync } from 'hooks/useReducerAsync'
import { getAssetKey } from './util'

export const ConnectContext = createContext(null)

export const actions = {
  LOADING: 'LOADING',
  SET_STATUS: 'SET_STATUS',
  SET_ASSETS: 'SET_ASSETS',
  SET_ERROR: 'SET_ERROR',
  SET_WARNING: 'SET_WARNING',

  GET_STATUS: 'GET_STATUS',
  GET_ASSETS: 'GET_ASSETS',
  TOGGLE_ASSET: 'TOGGLE_ASSET',

  POST_ACCESS_TOKEN: 'POST_ACCESS_TOKEN',
  POST_AUTHORIZATION_CODE: 'POST_AUTHORIZATION_CODE',
  DEACTIVATE: 'DEACTIVATE',
}

export const initialState = {
  loading: false,
  error: null,
  warning: null,
  assets: [],
  status: {
    has_valid_user_token: false,
    has_valid_system_user_token: false,
  },
  assetWizardMode: false,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.LOADING:
      return { ...state, loading: true, warning: null }
    case actions.SET_ERROR:
      return { ...state, loading: false, error: action.payload, warning: null }
    case actions.SET_WARNING:
      return { ...state, loading: false, warning: action.payload }
    case actions.SET_STATUS: {
      const { data, error } = action.payload
      return {
        ...state,
        status: data ?? initialState.status,
        error: error ?? state.error,
      }
    }
    case actions.SET_ASSETS: {
      const { data, error } = action.payload
      const assets = data?.map((asset) => ({ ...asset, key: getAssetKey(asset) })) ?? []
      return { ...state, loading: false, assets: assets, error: error ?? state.error, warning: null }
    }
    default:
      return state
  }
}

export const asyncActionHandlers = {
  [actions.GET_STATUS]:
    ({ dispatch }) =>
      async () => {
        const result = await metaAuthorizationApi.getStatus()
        dispatch({ type: actions.SET_STATUS, payload: result })
      },
  [actions.GET_ASSETS]:
    ({ dispatch }) =>
      async () => {
        dispatch({ type: actions.LOADING })
        const result = await metaAuthorizationApi.listAssets()
        dispatch({ type: actions.SET_ASSETS, payload: result })
      },
  [actions.TOGGLE_ASSET]:
    ({ dispatch }) =>
      async (action) => {
        const { assetId, enabled } = action.payload
        dispatch({ type: actions.LOADING })
        await metaAuthorizationApi.toggleAsset(assetId, enabled)
        const result = await metaAuthorizationApi.listAssets()
        dispatch({ type: actions.SET_ASSETS, payload: result })
      },
  [actions.POST_ACCESS_TOKEN]:
    ({ dispatch }) =>
      async (action) => {
        dispatch({ type: actions.LOADING })
        const { error } = await metaAuthorizationApi.postAccessToken(action.payload.accessToken)
        if (error) {
          dispatch({ type: actions.SET_ERROR, payload: error })
          return
        }

        dispatch({ type: actions.GET_STATUS })
        dispatch({ type: actions.GET_ASSETS })
      },
  [actions.POST_AUTHORIZATION_CODE]:
    ({ dispatch }) =>
      async (action) => {
        dispatch({ type: actions.LOADING })
        const { error } = await metaAuthorizationApi.postAuthorizationCode(action.payload)
        if (error) {
          dispatch({ type: actions.SET_ERROR, payload: error })
          return
        }

        dispatch({ type: actions.GET_STATUS })
        dispatch({ type: actions.GET_ASSETS })
      },
  [actions.DEACTIVATE]:
    ({ dispatch }) =>
      async (action) => {
        const tokenType = action.payload.tokenType
        dispatch({ type: actions.LOADING })
        if (tokenType) {
          await metaAuthorizationApi.deactivate(tokenType)
        } else {
          await metaAuthorizationApi.deactivate('user')
          await metaAuthorizationApi.deactivate('system')
        }
        dispatch({ type: actions.GET_STATUS })
        dispatch({ type: actions.GET_ASSETS })
      },
}

export function dispatchGetAssets(dispatch) {
  dispatch({ type: actions.GET_ASSETS })
}

export function dispatchPostAccessToken(dispatch, accessToken) {
  dispatch({ type: actions.POST_ACCESS_TOKEN, payload: { accessToken } })
}

export function dispatchPostAuthCode(dispatch, code, redirectUri) {
  dispatch({ type: actions.POST_AUTHORIZATION_CODE, payload: { code, redirect_uri: redirectUri } })
}

export function dispatchSetError(dispatch, error) {
  dispatch({ type: actions.SET_ERROR, payload: error })
}

export function dispatchSetWarning(dispatch, warning) {
  dispatch({ type: actions.SET_WARNING, payload: warning })
}

export function dispatchToggleAsset(dispatch, assetId, enabled) {
  dispatch({ type: actions.TOGGLE_ASSET, payload: { assetId, enabled } })
}

export function dispatchDeactivate(dispatch, tokenType = null) {
  dispatch({ type: actions.DEACTIVATE, payload: { tokenType } })
}

export const ConnectContextProvider = ({ children }) => {
  const [state, dispatch] = useReducerAsync(reducer, initialState, asyncActionHandlers)

  return (
    <ConnectContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </ConnectContext.Provider>
  )
}

ConnectContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Badge, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { isCampaignRunning, tcDisplayName } from '../../../api/models'

export const tcLabelOptions = {
  selected: 'selected',
  select: 'select',
  selectDisabled: 'select-disabled',
  saved: 'saved',
  pending: 'pending',
  ignored: 'ignored',
}

function TasteClusterStatus({ status, onClick = null }) {
  const { t } = useTranslation()

  if (!status) {
    return null
  }

  return (
    <Button
      onClick={onClick}
      disabled={[tcLabelOptions.selectDisabled, tcLabelOptions.pending, tcLabelOptions.ignored].some(
        (x) => x === status,
      )}
      className={classNames({
        'campaign-header_tc-status': true,
        [status]: true,
        clickable: !!onClick,
      })}
    >
      <>{t(`Recommendations.campaign.${status}`)}</>
    </Button>
  )
}

TasteClusterStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(tcLabelOptions)),
  onClick: PropTypes.func,
}

/**
 * Campaign header.
 * @Tags( recommendations, campaigns )
 */
export default function CampaignHeader({
  campaign,
  headerStatus,
  onTcLabelClick,
  children,
  onClick = null,
  className = '',
  showActiveBadge = true,
}) {
  const { t } = useTranslation()

  const isRunning = isCampaignRunning(campaign, { validateNotEnded: true })

  return (
    <div className={`campaign-header ${className}`}>
      <div className="campaign-header_toggle btn btn-link" onClick={onClick}>
        <div className="campaign-header_toggle__title display-flex w-100">
          <div className="campaign-header_toggle__title__wrapper">
            <span
              className={classNames({
                title: true,
                lonely: !isRunning,
              })}
              title={tcDisplayName(campaign)}
            >
              {t('Recommendations.campaign.recommendationTitle', {
                tc: tcDisplayName(campaign),
              })}
            </span>
            {showActiveBadge && isRunning && <Badge className="active">{t('Recommendations.campaign.active')}</Badge>}
          </div>
        </div>
      </div>
      <TasteClusterStatus status={headerStatus} onClick={onTcLabelClick} />
      {children}
    </div>
  )
}

CampaignHeader.propTypes = {
  campaign: PropTypes.object.isRequired,
  headerStatus: PropTypes.oneOf(Object.values(tcLabelOptions)),
  onClick: PropTypes.func,
  children: PropTypes.node,
  onTcLabelClick: PropTypes.func,
  className: PropTypes.string,
  showActiveBadge: PropTypes.bool,
}

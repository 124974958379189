import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

/**
 * Loading indicator
 * @Tags( shared, presentational )
 */
const LoadingSpinner = ({
  title = '',
  className = '',
  portalBackground = true,
  showText = true,
  size = SpinnerSize.LARGE,
  grayscale = false,
}) => {
  const { t } = useTranslation()
  const text = title ? title : t('common.loading')

  const cn = classNames('loading-spinner', className, {
    'loading-spinner--small': size === SpinnerSize.SMALL,
    'loading-spinner--grayscale': grayscale,
  })

  return (
    <div data-testid="loading-spinner" className={cn}>
      <span className={`loading-spinner__circle ${portalBackground ? 'loading-spinner__circle--poral-bg' : ''} `}>
        <span className="loading-spinner__circle__line loading-spinner__circle__line--1"></span>
        <span className="loading-spinner__circle__line loading-spinner__circle__line--2"></span>
        <span className="loading-spinner__circle__line loading-spinner__circle__line--3"></span>
      </span>
      {text && showText && <span className="loading-spinner__title">{text}</span>}
    </div>
  )
}

export const SpinnerSize = {
  MINI: 'mini',
  SMALL: 'small',
  LARGE: 'large',
}

LoadingSpinner.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  /**
   *  It sets spinner background to portal background.
   */
  portalBackground: PropTypes.bool,
  showText: PropTypes.bool,
  size: PropTypes.string,
  grayscale: PropTypes.bool,
}

LoadingSpinner.defaultProps = {
  title: 'Loading...',
  portalBackground: false,
  showText: true,
}

export default LoadingSpinner

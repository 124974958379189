import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import PropTypes, { number, string } from 'prop-types'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import { initStateObject, objectReducer } from 'reducers/default'
import { fetchDailyRevenue } from 'api/charts'
import InfoBox from 'components/Shared/InfoBox'
import { camelCase, orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { highchartsRadiusFunction } from '../utils'
import i18n from '../../../../i18n'
import { drawPlotLineIcons, filterManualAdjustments } from './utils'

highchartsRadiusFunction(Highcharts)

const formatTooltip = (chartData) => {
  if (!chartData) return

  let sumParts = {}
  let groupData = []
  const groupIndex = chartData.index
  const groupMap = chartData.series.groupMap
  const allData = chartData.series.userOptions.data
  if (groupMap) groupData = [...allData].splice(groupMap[groupIndex]?.start, groupMap[groupIndex]?.length)
  else groupData = [allData[groupIndex]]

  groupData.forEach((day) => {
    Object.keys(day.parts).forEach((key) => {
      if (sumParts[key]) sumParts[key] = sumParts[key] + day.parts[key]
      else sumParts[key] = day.parts[key]
    })
  })

  const parts = Object.keys(sumParts)
    .map((key) => {
      const value = Math.abs(Number(sumParts[key]).toFixed(1))
      const ticketType = i18n.t(`Event.ticketType.${camelCase(key.replaceAll('_', ' '))}`)

      return `<h5>${ticketType} - <strong class="value">${value}</strong></h5>`
    })
    .join('')

  return `<div class="highcharts-popup-content">${parts}<br><p><span class="date">${chartData.key}</span></p></div>`
}

const calculateDateRange = (chart) => {
  if (!chart.xAxis) return

  const selectTwoWeeks = () => {
    const day = 24 * 3600 * 1000 // One day in milliseconds
    const xAxis = chart.xAxis[0]
    const nonFillerData = orderBy(
      chart.data,
      ['x'],
      ['asc'],
    )
    const nonFakeMin = nonFillerData[0].x
    const nonFakeMax = nonFillerData[nonFillerData.length - 1].x
    const min = nonFakeMin
    let max = nonFakeMax
    // eslint-disable-next-line no-mixed-operators
    const twoWeeksEarlier = max - day * 14
    if (twoWeeksEarlier < min) {
      xAxis.setExtremes(min, max)
    } else {
      xAxis.setExtremes(twoWeeksEarlier, max)
    }
  }

  const selectTwoMonths = () => {
    const day = 24 * 3600 * 1000 // One day in milliseconds
    const xAxis = chart.xAxis[0]
    const nonFillerData = orderBy(
      chart.data,
      ['x'],
      ['asc'],
    )
    const nonFakeMin = nonFillerData[0].x
    const nonFakeMax = nonFillerData[nonFillerData.length - 1].x
    const min = nonFakeMin
    let max = nonFakeMax
    // eslint-disable-next-line no-mixed-operators
    const twoMonthsEarlier = max - day * 56
    if (twoMonthsEarlier < min) {
      xAxis.setExtremes(min, max)
    } else {
      xAxis.setExtremes(twoMonthsEarlier, max)
    }
  }

  const selectAll = () => {
    const xAxis = chart.xAxis[0]
    let min = xAxis.dataMin
    let max = xAxis.dataMax
    xAxis.setExtremes(min, max)
  }

  let twoWeeks = document.getElementById('twoWeeks')
  let twoMonths = document.getElementById('twoMonths')
  let all = document.getElementById('all')

  twoWeeks.addEventListener('click', selectTwoWeeks)
  twoMonths.addEventListener('click', selectTwoMonths)
  all.addEventListener('click', selectAll)

  selectTwoMonths()
}

// Function to space out the ticks on X-axis in such a way that the dates
// do not overlap or get squished (extend to multiple lines)
// const getTickPositions = (xAxis) => {
//   const processedXData = xAxis.series[0].processedXData
//   // Calculates how many date labels the X-axis can display,
//   // the 85 is based on the maximum width of text for label
//   // given the font of the label.
//   const maxNumOfLabels = Math.ceil(xAxis.width / 85)
//   // To know how many ticks to skip
//   const step = processedXData.length > maxNumOfLabels ? Math.ceil(processedXData.length / maxNumOfLabels) : 1
//   let ticks = []

//   for (let i = 0; i < processedXData.length; i += step) {
//     ticks.push(processedXData[i])
//   }

//   return ticks
// }

const TransactionsChart = ({ eventId, setShowGraph }) => {
  const [chartData, dispatch] = useReducer(objectReducer, initStateObject)
  const [typeFilter, setTypeFilter] = useState('tickets')
  const [rangeFilter, setRangeFilter] = useState('twoMonths')

  const [chartOptions, setChartOptions] = useState({
    chart: {
      renderTo: 'container',
      ignoreHiddenSeries: false,
      type: 'column',
      styledMode: false,
      alignTicks: false,
      style: {
        fontFamily: 'TTCommons',
      },
      events: {
        load: function () {
          var chart = this

          setTimeout(() => {
            calculateDateRange(chart)
            chart.reflow()
          }, 1000)
        },
        render: function () {
          let chart = this
          drawPlotLineIcons(chart, chart.manualAdjustments)
        },
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      minRange: 1,
      maxPadding: 0.05,
      alignTicks: false,
      labels: {
        padding: 30,
        staggerLines: 1,
        // rotation: [-45],
        // step: 0,
      },
      dateTimeLabelFormats: {
        day: '%b %e, %Y',
        week: '%b %e, %Y',
        month: '%b, %Y',
        year: '%Y',
      },
      plotLines: [],
    },
    yAxis: {
      opposite: false,
    },
    rangeSelector: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      split: false,
      headerFormat: null,
      dateTimeLabelFormats: {
        day: '%b %e, %Y',
        week: '%b %e, %Y',
        month: '%b, %Y',
        year: '%Y',
      },
      pointFormatter: function () {
        return formatTooltip(this)
      },
    },
    series: [
      {
        name: 'Sales',
        data: [],
        color: '#1f5274',
        groupPixelWidth: 40, // Specific column width
        groupPadding: 0.2, // Padding between groups of columns
        pointPadding: 0.2, // Padding between columns
        maxPointWidth: 33,
        groupAll: false,
        borderRadiusTopLeft: '25%',
        borderRadiusTopRight: '25%',
        zoneAxis: 'x',
        dataGrouping: {
          approximation: 'sum', // You can choose the aggregation method (e.g., 'average', 'sum', 'ohlc', etc.)
          enabled: true,
          units: [
            ['month', [1]],
            ['year', [1]],
          ],
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            chart: {
              height: 300,
            },
          },
        },
      ],
    },
  })

  const chartRef = useRef(null)
  const userCurrency = localStorage.getItem('userCurrency') || 'EUR'
  const { t } = useTranslation()

  const handleDisplayData = useCallback(
    (type = 'tickets') => {
      const manualAdjustments = filterManualAdjustments(chartData.content.manualAdjustments, type, rangeFilter)
      if (chartRef.current) {
        delete chartRef.current.manualAdjustments
        delete chartRef.current.data
        chartRef.current.manualAdjustments = manualAdjustments
        chartRef.current.data = chartData.content.dailySummary?.[type]
      }

      setTypeFilter(type)
      setChartOptions((prev) => {
        // Offset the plot lines to the nearest data point in a grouped series
        const minChartX = chartRef.current?.series[0].groupedData ? chartRef.current.series[0].groupedData[0].x : 0
        const maxChartX = chartRef.current?.series[0].groupedData ? chartRef.current.series[0].groupedData[chartRef.current.series[0].groupedData.length - 1].x : Infinity
        const plotLines = manualAdjustments.map((adjustment) => {
          return {
            value: Math.min(maxChartX, Math.max(minChartX, adjustment.x)),
            dashStyle: 'dash',
            width: 1,
            id: adjustment.id,
          }
        })

        return {
          ...prev,
          xAxis: {
            ...prev.xAxis,
            plotLines,
          },
          series: [
            {
              ...prev.series[0],
              data: chartData.content.dailySummary?.[type],
            },
          ],
        }
      })
    },
    [chartData.content.dailySummary, chartData.content.manualAdjustments, rangeFilter],
  )

  useEffect(() => {
    fetchDailyRevenue(dispatch, eventId, userCurrency)
  }, [userCurrency, eventId])

  useEffect(() => {
    handleDisplayData(typeFilter)
  }, [handleDisplayData, typeFilter])

  useEffect(() => {
    setShowGraph(!!chartData.content?.hasData)
  }, [setShowGraph, chartData])

  const chartCallback = (chart) => {
    chartRef.current = chart
  }

  if (!chartData.content?.hasData) return null

  return (
    <div data-testid="transactions-chart" id="transactions-chart" className="transactions-chart">
      <InfoBox
        title={t('Tips.Events.SalesOverview.title')}
        body={
          <>
            <p>{t('Tips.Events.SalesOverview.body')}</p>
            <p>{t('Tips.Events.SalesOverview.manualAdjustments')}</p>
          </>
        }
      >
        <h2 className="card-title-default d-inline-block m-0">{t('Tips.Events.SalesOverview.title')}</h2>
      </InfoBox>
      <div style={{ position: 'relative' }}>
        <div className="transactions-chart_filters">
          <div className="transactions-chart_filters_items">
            <div
              className={`${typeFilter === 'revenue' ? 'selected' : ''}`}
              onClick={() => handleDisplayData('revenue')}
            >
              <span>{t('Event.transactionGraph.types.revenue')}</span>
            </div>
            <div
              className={`${typeFilter === 'tickets' ? 'selected' : ''}`}
              onClick={() => handleDisplayData('tickets')}
            >
              <span>{t('Event.transactionGraph.types.tickets')}</span>
            </div>
          </div>

          <div className="transactions-chart_filters_items">
            <div
              id="twoWeeks"
              className={`${rangeFilter === 'twoWeeks' ? 'selected' : ''}`}
              onClick={() => setRangeFilter('twoWeeks')}
            >
              <span>{t('common.range.time.twoWeeks')}</span>
            </div>
            <div
              id="twoMonths"
              className={`${rangeFilter === 'twoMonths' ? 'selected' : ''} middle`}
              onClick={() => setRangeFilter('twoMonths')}
            >
              <span>{t('common.range.time.eightWeeks')}</span>
            </div>
            <div
              id="all"
              className={`${rangeFilter === 'all' ? 'selected' : ''}`}
              onClick={() => setRangeFilter('all')}
            >
              <span>{t('common.range.time.all')}</span>
            </div>
          </div>
        </div>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'stockChart'}
          options={chartOptions}
          callback={chartCallback}
        />
      </div>
    </div>
  )
}

TransactionsChart.propTypes = {
  eventId: PropTypes.oneOfType([string, number]).isRequired,
  setShowGraph: PropTypes.func,
}

export default TransactionsChart

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card } from 'react-bootstrap'
import moment from 'moment'

import CustomerSegmentationSection from './PreviewCustomerSegmentation'
import CustomerIds from './CustomerSegmentation/CustomerList/CustomerIds'
import { SecondaryButton } from 'components/Shared/Button'
import Fade from 'components/Shared/Wrappers/Fade'

function PreviewCard({ title, step, setCurrentStep, children, className }) {
  const { t } = useTranslation()

  return (
    <Card className={`card--transparent card--with-border card--compact ${className ?? ''}`}>
      <Card.Header>
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="customer-segmentation__header">{t(title)}</h5>
          <SecondaryButton
            fullWidth={false}
            classNames="px-4"
            size="small"
            color="navy"
            text={t('common.edit')}
            onClick={() => setCurrentStep(step)}
          />
        </div>
      </Card.Header>
      <Card.Body>{children}</Card.Body>
    </Card>
  )
}

PreviewCard.propTypes = {
  title: PropTypes.string,
  step: PropTypes.number,
  setCurrentStep: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default function Preview({ setCurrentStep, selectedEvents, targetMode }) {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <Fade className="package-builder_preview" transitionDuration={1000} delay={0}>
      <Card className="package-builder-page_outer-wrapper m-0 p-0">
        <h5 className="mb-3">
          <strong>{t('PackageBuilder.summary')}</strong>
        </h5>
        <div className="d-flex flex-column gap-4">
          <PreviewCard className="package-builder_preview_events-card" title="PackageBuilder.packageBuilderResults.events" step={1} setCurrentStep={setCurrentStep}>
            <div className="package-builder_preview_events">
              {selectedEvents && selectedEvents.length > 0
                ? selectedEvents.map((event) => (
                  <>
                    <div sm="auto" className="item title">
                      {event.title}
                    </div>
                    <div className="item date">
                      <time className="date">{moment.utc(event.start_date_time).format('DD.MM.YYYY HH:mm')}</time>
                    </div>
                  </>
                ))
                : null}
            </div>
          </PreviewCard>
          <PreviewCard title="PackageBuilder.targetMode" step={2} setCurrentStep={setCurrentStep}>
            {targetMode === 'selective_mode'
              ? t('PackageBuilder.modes.selectiveMode')
              : t('PackageBuilder.modes.inclusiveMode')}
          </PreviewCard>
          <PreviewCard
            title="PackageBuilder.customerSegmentation.title"
            step={2}
            setCurrentStep={(step) => setCurrentStep(step, { anchor: 'customer-segmentation' })}
          >
            <CustomerSegmentationSection setCustomerModalOpen={setModalOpen} />
          </PreviewCard>
        </div>
      </Card>
      <CustomerIds open={modalOpen} setOpen={setModalOpen} readOnly />
    </Fade>
  )
}

Preview.propTypes = {
  setCurrentStep: PropTypes.func,
  selectedEvents: PropTypes.array,
  targetMode: PropTypes.string,
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import cn from 'classnames'
import { isEqual } from 'lodash'

import { Prompt } from './Prompt'
import { optionToValue, dedupPostalCodes, createSelectOption, postalCodeSelectStyles } from './util'
import { FdCreatableSelect } from 'components/Shared/ReactSelect'
import { SecondaryButton, TransparentBgButton } from 'components/Shared/Button'
import Portal from 'components/Shared/Portal'
import Modal from 'components/Shared/Modal'
import LoadingSpinner, { SpinnerSize } from 'components/Shared/LoadingSpinner'

import { GreenTickIcon } from 'images'

import countries from './countries.json'
import SearchableDropDown from 'components/Shared/DropDown/SearchableDropDown'
import { useReducerAsync } from 'hooks/useReducerAsync'
import {
  actions,
  asyncActionHandlers,
  getCountryPostalCodeNames,
  getCountryPostalCodes,
  hasValidState,
  initialState,
  reducer,
  totalCountries,
} from './postalCodesReducer'
import { useTargeting } from '../TargetingContext'
import PostalCodesSummary from './PostalCodesSummary'

const countryOptions = Object.keys(countries).map((countryKey) => ({
  value: countryKey,
  text: countries[countryKey].name,
}))

const promptSources = Object.freeze({
  BACK_CLICK: 'back_click',
  MODAL_CLOSE: 'modal_close',
})

export default function PostalCodes({ tc, isOpen, readOnly, setOpen, onDone }) {
  const { t } = useTranslation()
  const selectRef = useRef(null)

  const { value: targeting } = useTargeting()
  const [currentCountry, setCurrentCountry] = useState(null)
  const [editedCountry, setEditedCountry] = useState(null)

  const tcTargeting = useMemo(() => targeting.find((x) => x.tc === tc) ?? { tc }, [targeting, tc])
  const [state, dispatch] = useReducerAsync(reducer, initialState(tcTargeting), asyncActionHandlers)
  const {
    countries,
    unmatchedPostalCodes,
    loading: matchingLoading,
    success: matchingSuccess,
    serverError: matchingServerError,
  } = state

  const [summaryOpen, setSummaryOpen] = useState(() => hasValidState(state))

  const currentCountryPostalCodeNames = useMemo(
    () => getCountryPostalCodeNames(countries, currentCountry),
    [currentCountry, countries],
  )

  const [postalCodeOptions, setPostalCodeOptions] = useState(currentCountryPostalCodeNames)
  const [inputValue, setInputValue] = useState('')
  const [showPrompt, setShowPrompt] = useState(null)
  // ref that saves current country on clearAll
  // is used to restore the state when user clicks on back button
  const restoreRef = useRef({ currentCountry })

  const successfulMatch = useMemo(
    () => matchingSuccess === true && !unmatchedPostalCodes.length,
    [matchingSuccess, unmatchedPostalCodes.length],
  )
  const partiallyMatched = useMemo(
    () => matchingSuccess === false && unmatchedPostalCodes.length > 0,
    [matchingSuccess, unmatchedPostalCodes.length],
  )
  const hasMatched = successfulMatch || partiallyMatched
  const isReadonly = readOnly || partiallyMatched

  const reset = useCallback(
    ({ openSummary = false, data = {}, close = false } = {}) => {
      setCurrentCountry(null)
      setEditedCountry(null)
      setPostalCodeOptions([])

      if (data) {
        dispatch({ type: actions.reset, payload: data })
      }

      if (openSummary) {
        setSummaryOpen(true)
      }

      if (close) {
        setOpen(false)
      }
    },
    [dispatch, setOpen],
  )

  const finishSuccessfully = useCallback(() => {
    reset({ openSummary: true })
  }, [reset])

  useEffect(() => {
    // after a fully successful match, close the modal after 3 second
    let timeoutId
    if (successfulMatch && !timeoutId) {
      timeoutId = setTimeout(() => {
        finishSuccessfully()
      }, 3000)
    }

    return () => clearTimeout(timeoutId)
  }, [successfulMatch, finishSuccessfully])

  useEffect(() => {
    // set currentCountry to editedCountry
    if (editedCountry) {
      setCurrentCountry(editedCountry)
    }
  }, [editedCountry])

  const handleContainerClick = () => {
    if (selectRef.current) selectRef.current.focus()
  }

  const handleKeyDown = (event) => {
    if (isReadonly) return

    /* eslint-disable quotes */
    if (!!inputValue && [',', 'Enter', ';'].includes(event.key)) {
      setPostalCodeOptions((prev) => dedupPostalCodes([...prev, createSelectOption(inputValue)]))
      setInputValue('')
      event.preventDefault()
    }
  }

  const handlePaste = (event) => {
    if (isReadonly) return

    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('Text')
    const pastedValues = pastedData.split(/[,\n\r;]+/).filter(Boolean)

    setPostalCodeOptions((prev) => dedupPostalCodes([...prev, ...pastedValues.map(createSelectOption)]))

    event.preventDefault()
  }

  const handleDownloadUnmatched = (e) => {
    const csv = ['zip_code, country', ...unmatchedPostalCodes.map((zip) => `${zip}, ${currentCountry}`)].join('\n')
    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
    hiddenElement.target = '_blank'
    hiddenElement.download = 'unmatched_zips.csv'
    hiddenElement.click()
    e.preventDefault()
  }

  const handleClearAll = (e) => {
    e.preventDefault()
    restoreRef.current = { currentCountry }
    dispatch({ type: actions.clearForm })
    setPostalCodeOptions([])
    setCurrentCountry(null)
  }

  const handleMatchPostalCodes = (e) => {
    dispatch({
      type: actions.matchAsync,
      payload: {
        countryKey: currentCountry,
        postalCodes: postalCodeOptions.map(optionToValue),
      },
    })
    e.preventDefault()
  }

  const title = useMemo(() => {
    if (!hasMatched || !partiallyMatched) {
      return t('Recommendations.geoTargeting.postalCodes.title')
    }

    const countMatched = postalCodeOptions.length - unmatchedPostalCodes.length
    if (countMatched === 0) {
      return t('Recommendations.geoTargeting.postalCodes.matching.nonMatchedTitle')
    }

    return t('Recommendations.geoTargeting.postalCodes.matching.success.partialSuccessTitle', {
      countMatched,
    })
  }, [partiallyMatched, t, hasMatched, unmatchedPostalCodes.length, postalCodeOptions.length])

  const description = useMemo(() => {
    if (hasMatched && partiallyMatched) {
      const countMatched = postalCodeOptions.length - unmatchedPostalCodes.length
      return t('Recommendations.geoTargeting.postalCodes.matching.success.partialSuccessDesc1', {
        countMatched,
      })
    }

    if (summaryOpen) {
      return t('Recommendations.geoTargeting.postalCodes.matching.success.description')
    }

    return t('Recommendations.geoTargeting.postalCodes.description')
  }, [hasMatched, partiallyMatched, t, unmatchedPostalCodes.length, postalCodeOptions.length, summaryOpen])
  const hasAddedOptionsThatAreNotMatchedYet =
    currentCountry &&
    postalCodeOptions.some((option) => currentCountry && !currentCountryPostalCodeNames.includes(option.value))
  const hasClearedAllOptionsAfterMatch =
    editedCountry && (!currentCountry || (!postalCodeOptions.length && currentCountryPostalCodeNames.length > 0))
  const hasDeletedSomeOptionsAfterMatch =
    !hasClearedAllOptionsAfterMatch &&
    postalCodeOptions.length < currentCountryPostalCodeNames.length + unmatchedPostalCodes.length
  const hasUnsavedChanges = hasAddedOptionsThatAreNotMatchedYet || hasClearedAllOptionsAfterMatch || hasDeletedSomeOptionsAfterMatch

  const handleEditModeBack = (e) => {
    e.preventDefault()
    if (hasUnsavedChanges) {
      setShowPrompt({ source: promptSources.BACK_CLICK })
      return
    }

    onDone({ countries })
    setEditedCountry(null)
    setSummaryOpen(true)
  }

  const onClose = useCallback(() => {
    if (partiallyMatched) {
      // Force the user to click on the finish button
      return
    }
    if (hasUnsavedChanges) {
      setShowPrompt({ source: promptSources.MODAL_CLOSE })
      return
    }

    onDone({ countries })
    setOpen(false)
  }, [
    hasUnsavedChanges,
    setOpen,
    partiallyMatched,
    countries,
    onDone,
  ])

  const showBackBtn = useMemo(() => {
    return hasValidState(state) && !successfulMatch && !matchingLoading && !summaryOpen &&
      (totalCountries(countries) > 1 || !hasClearedAllOptionsAfterMatch)
  }, [state, summaryOpen, countries, hasClearedAllOptionsAfterMatch, successfulMatch, matchingLoading])

  const syncCountries = useCallback(() => {
    // combines the postal codes from the current country from reducer state, with the postal codes from the
    // internal state, which may have been modified by the user.

    const currentCountryZips = getCountryPostalCodes(countries, currentCountry)
    const removedItems = currentCountryZips.filter((zip) => !postalCodeOptions.map(optionToValue).includes(zip.name))

    const newPostalCodes = currentCountryZips.filter((zip) => removedItems.every((item) => item.name !== zip.name))

    const countryKey = editedCountry ?? currentCountry
    const newCountries = { ...countries, [countryKey]: newPostalCodes }
    return newCountries
  }, [countries, currentCountry, postalCodeOptions, editedCountry])

  return (
    <div className="postal-codes_outer">
      {isOpen && !showPrompt && (
        <Portal wrapperId="app-portal">
          <Modal
            closeCallback={onClose}
            closeDisabled={matchingLoading}
            hideCloseBtn={partiallyMatched}
            title={title}
            headerRenderer={({ title, className }) => <h4 className={cn(className, 'mt-3 px-md-2 mr-3')}>{title}</h4>}
            offWhiteBg
            size="lg"
            fullWidth={false}
            onClickOutside={onClose}
            mainContent={
              <div className="postal-codes">
                <div className="postal-codes_content">
                  <p className="my-3 fs-16">{description}</p>
                  {partiallyMatched && (
                    <>
                      <p className="error-msg">
                        {t('Recommendations.geoTargeting.postalCodes.matching.success.partialSuccessDesc2', {
                          countTotal: postalCodeOptions.length,
                          countUnmatched: unmatchedPostalCodes.length,
                        })}
                      </p>
                      <p className="fs-16 txt-header">
                        {t('Recommendations.geoTargeting.postalCodes.matching.success.unmatchedZips')}
                      </p>
                    </>
                  )}
                  {!summaryOpen && (
                    <>
                      <SearchableDropDown
                        options={countryOptions}
                        onChange={(value) => {
                          setCurrentCountry(value)
                          setPostalCodeOptions(getCountryPostalCodeNames(countries, value).map(createSelectOption))
                        }}
                        placeholder={t('Recommendations.geoTargeting.postalCodes.country')}
                        classNames="postal-codes__country"
                        searchPlaceholder={t('Recommendations.geoTargeting.postalCodes.searchCountry')}
                        disabled={isReadonly || hasMatched || matchingLoading}
                        value={currentCountry}
                      />
                      <div
                        className="postal-codes_select-container"
                        onClick={handleContainerClick}
                        onPaste={handlePaste}
                      >
                        <FdCreatableSelect
                          ref={selectRef}
                          name="postal-codes"
                          id="PostalCodes"
                          className="postal-codes_select"
                          isDisabled={
                            isReadonly || hasMatched || matchingLoading || !currentCountry
                          }
                          isReadOnly={isReadonly}
                          isMulti
                          isClearable={false}
                          inputValue={inputValue}
                          value={partiallyMatched ? unmatchedPostalCodes.map(createSelectOption) : postalCodeOptions}
                          onChange={(newValue) => setPostalCodeOptions(dedupPostalCodes(newValue))}
                          onInputChange={(newValue) => !isReadonly && setInputValue(newValue)}
                          onPaste={handlePaste}
                          onKeyDown={handleKeyDown}
                          placeholder={
                            hasMatched ? null : t('Recommendations.geoTargeting.postalCodes.matching.example')
                          }
                          menuIsOpen={false}
                          blurInputOnSelect={false}
                          components={{
                            DropdownIndicator: null,
                          }}
                          styles={postalCodeSelectStyles}
                        />
                      </div>
                    </>
                  )}
                  {!summaryOpen && (!hasMatched || successfulMatch) && (
                    <div className="d-flex justify-content-between mt-3 gap-3">
                      {matchingLoading ? (
                        <div className="loading-container">
                          <LoadingSpinner showText={false} size={SpinnerSize.MINI} grayscale />
                          <Trans i18nKey="PackageBuilder.customerSegmentation.customerMatching.loadingMsg">
                            Loading...
                            <br />
                            Please wait while we are processing.
                          </Trans>
                        </div>
                      ) : successfulMatch ? (
                        <div className="success-container">
                          <GreenTickIcon width={18} />
                          <p>{t('Recommendations.geoTargeting.postalCodes.matching.success.successMsg')}</p>
                        </div>
                      ) : matchingServerError ? (
                        <p className="error-msg error-msg--small">
                          {t('Recommendations.geoTargeting.postalCodes.matching.serverError', {
                            countTotal: postalCodeOptions.length,
                            countUnmatched: unmatchedPostalCodes.length,
                          })}
                        </p>
                      ) : (
                        <SecondaryButton
                          text={t('common.clearAll')}
                          color="navy"
                          onClick={handleClearAll}
                          type="button"
                          disabled={!postalCodeOptions.length}
                        />
                      )}
                      <div className="d-flex align-items-center gap-1">
                        {/* hide back when form is cleared and no other countries exist to go back to */}
                        {showBackBtn && (
                          <TransparentBgButton
                            text={t('common.back')}
                            color="navy"
                            onClick={handleEditModeBack}
                            type="button"
                            light
                          />
                        )}
                        <SecondaryButton
                          text={t('Recommendations.geoTargeting.postalCodes.matching.match')}
                          classNames="align-self-baseline"
                          color="orange"
                          disabled={
                            !postalCodeOptions.length ||
                            isEqual(
                              postalCodeOptions.map(optionToValue),
                              currentCountryPostalCodeNames.map(optionToValue),
                            ) ||
                            matchingLoading ||
                            successfulMatch ||
                            !currentCountry
                          }
                          type="button"
                          onClick={handleMatchPostalCodes}
                        />
                      </div>
                    </div>
                  )}
                  {!summaryOpen && partiallyMatched && (
                    <div className="d-flex justify-content-between flex-wrap gap-1 mt-3">
                      <SecondaryButton
                        text={t('Recommendations.geoTargeting.postalCodes.matching.success.downloadUnmatched')}
                        color="orange"
                        onClick={handleDownloadUnmatched}
                        type="button"
                        disabled={!postalCodeOptions.length}
                      />
                      <SecondaryButton
                        text={t('common.next')}
                        color="orange"
                        disabled={!postalCodeOptions.length || matchingLoading}
                        type="button"
                        onClick={(e) => {
                          e.preventDefault()

                          const newCountries = syncCountries()
                          reset({
                            data: { countries: newCountries },
                            openSummary: true,
                          })
                        }}
                      />
                    </div>
                  )}
                  {summaryOpen && (
                    <>
                      <PostalCodesSummary
                        countries={state.countries}
                        onEditClicked={(countryCode) => {
                          setSummaryOpen(false)
                          setEditedCountry(countryCode)
                          setCurrentCountry(countryCode)
                          const countryZips = getCountryPostalCodeNames(countries, countryCode)
                          const newPostalCodeOptions = countryZips.map(createSelectOption)
                          setPostalCodeOptions(newPostalCodeOptions)
                        }}
                      />
                      <div className="d-flex justify-content-end mt-3">
                        <SecondaryButton
                          text={t('Recommendations.geoTargeting.postalCodes.matching.add')}
                          color="orange"
                          type="button"
                          onClick={() => {
                            reset([])
                            setSummaryOpen(false)
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            }
          />
        </Portal>
      )}
      <Prompt
        isOpen={!!showPrompt}
        forClearing={hasClearedAllOptionsAfterMatch}
        forUnmatchedWithNoExistingPostalCodes={
          hasAddedOptionsThatAreNotMatchedYet && !currentCountryPostalCodeNames.length
        }
        forUnmatched={hasAddedOptionsThatAreNotMatchedYet}
        forDeleted={hasDeletedSomeOptionsAfterMatch}
        onYes={() => {
          setShowPrompt(null)
          const newCountries = syncCountries()

          const isBackClick = showPrompt.source === promptSources.BACK_CLICK
          reset({
            data: { countries: newCountries },
            openSummary: isBackClick,
            close: !isBackClick,
          })

          if (!isBackClick) {
            onDone({ countries: newCountries })
          }
        }}
        onNo={() => {
          // restore the postal code options, location recency and current country
          const { currentCountry } = restoreRef.current
          setCurrentCountry(currentCountry)
          setPostalCodeOptions(getCountryPostalCodeNames(countries, currentCountry).map(createSelectOption))
          setShowPrompt(null)
        }}
      />
    </div>
  )
}

PostalCodes.propTypes = {
  tc: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  onDone: PropTypes.func,
  readOnly: PropTypes.bool,
}

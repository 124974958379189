import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Toast from 'react-bootstrap/Toast'

import { CheckIcon, ErrorIcon } from 'images'
import cancelled from 'images/icons/cancelled.svg'

export function ToastHeader({ children, onClose, variant = 'success', className = 'text-success', ...props }) {
  return (
    <Toast.Header {...props} className={classNames('toast-header', className, variant)} closeButton={false}>
      <span className="toast-header-icon">
        {variant === 'success' && <CheckIcon width={10} height={10} />}
        {variant === 'error' && <ErrorIcon width={16} height={16} />}
      </span>
      <div className="toast-header-content">{children}</div>
      <button type="button" onClick={onClose} className="toast-header-close-btn">
        <img src={cancelled} alt="Close Alert" className="toast-header-close-btn_icon" />
      </button>
    </Toast.Header>
  )
}

ToastHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['success', 'error']),
  className: PropTypes.string,
}

import React, { useEffect, useState } from 'react'
import PropTypes, { string } from 'prop-types'
import { useTranslation } from 'react-i18next'

import PortalModal from '../../../Shared/PortalModal'
import { SecondaryButton, TransparentBgButton } from '../../../Shared/Button'
import { fetchGeneratedCreativesAsync } from '../../../../api/setupProcesses'
import { generativeCreativeField } from '../../../../api/models'
import i18n from '../../../../i18n'
import FallingDots from '../../../Shared/FallingDots'

import { LightBulbOutlineIcon } from 'images'

export default function AutoFill({
  eventId,
  tc,
  tc_run_id,
  language,
  creativeFieldName,
  fields,
  onChange,
  promptForConfirmation,
}) {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  language = language || i18n.language

  const abortController = new AbortController()
  const { t } = useTranslation()

  useEffect(() => {
    return () => abortController.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFeedbackDialogOpen(loading || error)
  }, [loading, error])

  if (!tc || typeof tc_run_id === undefined || tc_run_id === null) return null

  const onConfirmed = async () => {
    setLoading(true)
    setError(false)
    setConfirmDialogOpen(false)

    const result = await fetchGeneratedCreativesAsync(
      {
        eventId,
        tc,
        tcRunId: tc_run_id,
        creativeFieldName,
        ...(fields?.length ? { requiredNumberOfCreatives: fields.length } : {}),
        language,
      },
      abortController,
    )

    setLoading(false)
    if (!result.success) {
      setError(true)
    } else {
      onChange(Object.values(result.results))
      setConfirmDialogOpen(false)
    }
  }

  return (
    <>
      <button
        className="bt autofill_btn small"
        color="orange"
        disabled={!promptForConfirmation && loading}
        onClick={() => {
          if (promptForConfirmation) setConfirmDialogOpen(true)
          else onConfirmed()
        }}
        type="button"
      >
        <LightBulbOutlineIcon height={14} />
      </button>
      <PortalModal isOpen={confirmDialogOpen} onClickOutside={() => setConfirmDialogOpen(false)}>
        <div className="content compact text-center" style={{ maxWidth: '400px' }}>
          <h1 className="autofill_warning-header">{t('Recommendations.campaign.setup.autofill.warningTitle')}</h1>
          <p className="autofill_warning-msg">{t('Recommendations.campaign.setup.autofill.warningMsg')}</p>
          <div className="footer">
            <TransparentBgButton text={t('common.cancel')} onClick={() => setConfirmDialogOpen(false)} color="navy" />
            <SecondaryButton
              color="orange"
              text={t('common.proceed')}
              onClick={onConfirmed}
              type="button"
              disabled={loading}
              disabledTitle=""
            />
          </div>
        </div>
      </PortalModal>
      <PortalModal isOpen={feedbackDialogOpen} onClickOutside={() => !loading && setFeedbackDialogOpen(false)}>
        <div className="content compact pt-0 text-center" style={{ maxWidth: '400px' }}>
          {error ? (
            <>
              {/* embedded the svg directly to git rid of layout shifts */}
              {/* warning-circle.svg */}
              <svg
                width="46"
                height="46"
                className="mt-4 mb-1"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M56 28C56 35.4261 53.05 42.548 47.799 47.799C42.548 53.05 35.4261 56 28 56C20.5739 56 13.452 53.05 8.20101 47.799C2.94999 42.548 0 35.4261 0 28C0 20.5739 2.94999 13.452 8.20101 8.20101C13.452 2.94999 20.5739 0 28 0C35.4261 0 42.548 2.94999 47.799 8.20101C53.05 13.452 56 20.5739 56 28ZM28 14C27.5576 14.0003 27.1202 14.0932 26.7159 14.2727C26.3116 14.4523 25.9495 14.7146 25.6527 15.0427C25.356 15.3708 25.1312 15.7574 24.993 16.1776C24.8547 16.5978 24.806 17.0423 24.85 17.4825L26.075 29.757C26.1162 30.2392 26.3368 30.6884 26.6933 31.0157C27.0497 31.3431 27.516 31.5247 28 31.5247C28.484 31.5247 28.9503 31.3431 29.3067 31.0157C29.6632 30.6884 29.8838 30.2392 29.925 29.757L31.15 17.4825C31.194 17.0423 31.1453 16.5978 31.007 16.1776C30.8688 15.7574 30.644 15.3708 30.3473 15.0427C30.0505 14.7146 29.6884 14.4523 29.2841 14.2727C28.8798 14.0932 28.4424 14.0003 28 14ZM28.007 35C27.0787 35 26.1885 35.3688 25.5321 36.0251C24.8757 36.6815 24.507 37.5717 24.507 38.5C24.507 39.4283 24.8757 40.3185 25.5321 40.9749C26.1885 41.6312 27.0787 42 28.007 42C28.9353 42 29.8255 41.6312 30.4819 40.9749C31.1383 40.3185 31.507 39.4283 31.507 38.5C31.507 37.5717 31.1383 36.6815 30.4819 36.0251C29.8255 35.3688 28.9353 35 28.007 35Z"
                  fill="#1F5274"
                />
              </svg>

              <div className="text-center mt-3">
                <h1 className="autofill_warning-header">{t('common.whoops')}!</h1>
                <p className="autofill_warning-msg mb-4">{t('common.requestError')}</p>
              </div>
              <SecondaryButton
                color="navy"
                disabled={loading}
                onClick={() => {
                  onConfirmed()
                }}
                type="button"
                text={t('common.tryAgain')}
              />
            </>
          ) : (
            // loading
            <>
              <FallingDots classNames="autofill_falling-dots" />
              <div className="text-center">
                <p className="body-txt light larger mb-1">
                  {t('Recommendations.campaign.setup.autofill.loadingTitle')}
                </p>
                <p className="body-txt light larger mb-2">{t('Recommendations.campaign.setup.autofill.loadingMsg')}</p>
              </div>
            </>
          )}
        </div>
      </PortalModal>
    </>
  )
}

AutoFill.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tc: PropTypes.string,
  tc_run_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  language: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  creativeFieldName: PropTypes.oneOf(Object.values(generativeCreativeField)),
  fields: PropTypes.arrayOf(string),
  promptForConfirmation: PropTypes.bool,
}

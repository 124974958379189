import React from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary } from 'react-error-boundary'

import { salesEditMode } from './salesEditMode'
import SalesSummary from './SalesSummary'
import { SalesDocumentUpload } from './DocumentUpload/SalesDocumentUpload.jsx'
import ErrorFallback from '../../Shared/ErrorFallback'

function SalesEditorContent({ eventId, eventDate, onSuccess, onClose, mode }) {
  switch (mode) {
    case salesEditMode.documentUpload:
      return <SalesDocumentUpload eventId={eventId} onSuccess={onSuccess} onClose={onClose} />
    case salesEditMode.salesSummary:
      return <SalesSummary eventId={eventId} eventDate={eventDate} onSuccess={onSuccess} onClose={onClose} />
    default:
      throw new Error(`Unknown mode: ${mode}`)
  }
}

SalesEditorContent.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  eventDate: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(Object.values(salesEditMode)).isRequired,
}

export function SalesEditor(props) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <SalesEditorContent {...props} />
    </ErrorBoundary>
  )
}

SalesEditor.propTypes = SalesEditorContent.propTypes

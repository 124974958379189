import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary } from 'react-error-boundary'

import Asset from './Asset'
import { ConnectContext, dispatchSetWarning } from './connect.js'
import { assetCategories } from './util'
import ErrorFallback from 'components/Shared/ErrorFallback'

export default function Assets({ assetCategory, tokenType, onToggleAsset, onAddAsset, disabled = false }) {
  const { t } = useTranslation()

  const {
    state: { assets: allAssets, status },
    dispatch,
  } = useContext(ConnectContext)

  if (tokenType === 'user' && !status.has_valid_user_token) {
    return null
  }

  if (tokenType === 'system' && !status.has_valid_system_user_token) {
    return null
  }

  const assets = allAssets.filter(
    (asset) =>
      (!assetCategory || asset.key === assetCategory.key) &&
      // User token type: show only user assets, or unbundled assets
      ((tokenType === 'user' && (asset.user_token_id || !asset.system_token_id)) ||
        // System token type: show only system assets
        (tokenType === 'system' && !!asset.system_token_id)),
  )

  const sortedAssets = assets.sort((a, b) => {
    const indexA = assetCategories.find((category) => category.key === a.key)?.order
    const indexB = assetCategories.find((category) => category.key === b.key)?.order

    return indexA - indexB
  })

  const assetsToRender = assetCategories
    .map((x) => x.key)
    .reduce((acc, category) => {
      if (assetCategory && category !== assetCategory.key) {
        return acc
      }

      if (!acc[category]) {
        acc[category] = []
      }

      const categoryAssets = sortedAssets
        .filter((asset) => asset.key === category)
        .sort((a, b) => {
          if (a.date_created === b.date_created) {
            return a.asset_label.localeCompare(b.asset_label)
          }

          return new Date(a.date_created) - new Date(b.date_created)
        })
      acc[category].push(...categoryAssets)

      return acc
    }, {})

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {Object.keys(assetsToRender).map((assetKey, index) => (
        <React.Fragment key={assetKey}>
          <h3 className="meta_assets-category__title settings-page__section border-0 mb-0 pb-3">
            {t(`Settings.MetaAccess.Assets.${assetKey}.title`)}
          </h3>
          {assetsToRender[assetKey].map((asset) => (
            <div className="settings-page__section meta_assets_asset border-0" key={asset.id}>
              <Asset
                asset={asset}
                onToggle={(assetId, enabled) => {
                  const required = assetCategories.find((x) => x.key === assetKey)?.required
                  const assets = assetsToRender[assetKey]
                  if (required && assets.filter((x) => x.is_enabled).length === 1 && !enabled) {
                    dispatchSetWarning(dispatch, t('Settings.MetaAccess.atLeastOnePageIsRequired'))
                    return
                  }

                  onToggleAsset(assetId, enabled)
                }}
                disabled={disabled}
              />
            </div>
          ))}
          <div className="settings-page__section border-0 pt-3">
            <button
              type="button"
              className="meta_assets_add-asset-btn txt-btn"
              aria-label="Add Asset"
              onClick={() => onAddAsset(assetKey)}
            >
              <span>+</span> {t(`Settings.MetaAccess.Assets.${assetKey}.addAction`)}
            </button>
          </div>
          {index !== Object.keys(assetsToRender).length - 1 && <div className="meta_assets_category-separator" />}
        </React.Fragment>
      ))}
    </ErrorBoundary>
  )
}

Assets.propTypes = {
  assetCategory: PropTypes.object,
  tokenType: PropTypes.oneOf(['user', 'system']).isRequired,
  onToggleAsset: PropTypes.func.isRequired,
  onAddAsset: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

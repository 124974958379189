import React, { forwardRef } from 'react'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import cn from 'classnames'

import { RemoveIcon, SearchIcon } from 'images'
import CustomCheck from './CustomCheck'

export const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <div className={cn('dropdown-indicator', { 'dropdown-indicator--open': props.selectProps.menuIsOpen })} />
      </components.DropdownIndicator>
    )
  )
}

DropdownIndicator.propTypes = components.DropdownIndicator.propTypes

export const SearchDropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <SearchIcon width={14} height={14} />
      </components.DropdownIndicator>
    )
  )
}

DropdownIndicator.propTypes = components.DropdownIndicator.propTypes

export const MultiValueRemove = (props) => {
  if (props.selectProps.isReadOnly) {
    return null
  }

  return (
    components.MultiValueRemove && (
      <components.MultiValueRemove {...props}>
        <span className="multi-value-remove">
          <RemoveIcon width={14} style={{ fill: '#bdbdbd' }} />
        </span>
      </components.MultiValueRemove>
    )
  )
}

MultiValueRemove.propTypes = components.MultiValueRemove.propTypes

export const OptionWithCheckbox = () => (props) => {
  return (
    <components.Option {...props} className="option-with-checkbox">
      <CustomCheck
        checked={props.isSelected}
        label={props.label}
        onChange={() => null}
        classNames="custom-check--navy"
      />
    </components.Option>
  )
}

OptionWithCheckbox.propTypes = components.Option.propTypes

export const FdSelect = forwardRef((props, ref) => (
  <Select
    {...props}
    ref={ref}
    components={{
      DropdownIndicator,
      IndicatorSeparator: null,
      MultiValueRemove,
      ...props.components,
    }}
    className={cn('fd-select', props.className)}
    classNamePrefix="fd-select"
  />
))

FdSelect.displayName = 'FdSelect'
FdSelect.propTypes = Select.propTypes

export const FdCreatableSelect = forwardRef((props, ref) => (
  <CreatableSelect
    {...props}
    ref={ref}
    components={{
      DropdownIndicator,
      MultiValueRemove,
      IndicatorSeparator: null,
      ...props.components,
    }}
    className={cn('fd-select', props.className)}
    classNamePrefix="fd-select"
  />
))

FdCreatableSelect.displayName = 'FdCreatableSelect'
FdCreatableSelect.propTypes = CreatableSelect.propTypes

import PropTypes from 'prop-types'
import React, { useState } from 'react'

/**
 * Input check
 * @Tags( shared, presentational, input )
 */
const CustomCheck = ({
  onChange,
  checked,
  checkedId,
  required = false,
  disabled = false,
  label = '',
  indeterminate = false,
  classNames = '',
}) => {
  const [indeterminateStatus, setIndeterminateStatus] = useState(indeterminate)

  const handleChange = (e) => {
    setIndeterminateStatus(false)
    onChange(e)
  }

  return (
    <label data-testid="custom-check" className={`custom-check ${classNames}`}>
      <input
        className="custom-check__input"
        id={checkedId}
        type="checkbox"
        onChange={(e) => handleChange(e)}
        checked={checked}
        disabled={disabled}
        required={required}
      />
      <span className={`custom-check__box ${indeterminateStatus ? 'custom-check__box--indeterminate' : ''}`}></span>
      <span className="custom-check__label">{label}</span>
    </label>
  )
}

CustomCheck.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  /**
   * Optional id for check, can be switched with label[for]
   * */
  checkedId: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  /**
   * Third state it was on mockups
   */
  indeterminate: PropTypes.bool,
  label: PropTypes.string,
}

export default CustomCheck

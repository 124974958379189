import { camelCase, orderBy } from 'lodash'

import i18n from '../../../../i18n'
import { isObjectEmpty } from 'utils/helpers'

const blue = '#6790a6'
const darkBlue = '#1F5274'
const diameter = 14
const radius = diameter / 2

export const PLOTLINE_BACKDROP_ICON_PATH = [
  'M',
  '7',
  '0',
  'C',
  '3.15',
  '0',
  '0',
  '3.15',
  '0',
  '7',
  'C',
  '0',
  '10.85',
  '3.15',
  '14',
  '7',
  '14',
  'C',
  '10.85',
  '14',
  '14',
  '10.85',
  '14',
  '7',
  'C',
  '14',
  '3.15',
  '10.85',
  '0',
  '7',
  '0',
  'Z',
]

export const PLOTLINE_ICON_PATH = [
  'M',
  '7',
  '0',
  'C',
  '3.15',
  '0',
  '0',
  '3.15',
  '0',
  '7',
  'C',
  '0',
  '10.85',
  '3.15',
  '14',
  '7',
  '14',
  'C',
  '10.85',
  '14',
  '14',
  '10.85',
  '14',
  '7',
  'C',
  '14',
  '3.15',
  '10.85',
  '0',
  '7',
  '0',
  'Z',
  'M',
  '7.875',
  '10.5',
  'H',
  '6.125',
  'V',
  '6.125',
  'H',
  '7.875',
  'V',
  '10.5',
  'Z',
  'M',
  '7',
  '5.25',
  'C',
  '6.475',
  '5.25',
  '6.125',
  '4.9',
  '6.125',
  '4.375',
  'C',
  '6.125',
  '3.85',
  '6.475',
  '3.5',
  '7',
  '3.5',
  'C',
  '7.525',
  '3.5',
  '7.875',
  '3.85',
  '7.875',
  '4.375',
  'C',
  '7.875',
  '4.9',
  '7.525',
  '5.25',
  '7',
  '5.25',
  'Z',
]

// A circle with a transparent fill, used to make the icon clickable
export const PLOTLINE_ICONS_TRANSPARENT_PATH = [
  'M',
  '7',
  '0',
  'C',
  '3.15',
  '0',
  '0',
  '3.15',
  '0',
  '7',
  'C',
  '0',
  '10.85',
  '3.15',
  '14',
  '7',
  '14',
  'C',
  '10.85',
  '14',
  '14',
  '10.85',
  '14',
  '7',
  'C',
  '14',
  '3.15',
  '10.85',
  '0',
  '7',
  '0',
  'Z',
]

export const drawPlotLineIcons = (chart, manualAdjustments) => {
  if (!chart || !chart.xAxis || !chart.xAxis[0].plotLinesAndBands || !manualAdjustments) return
  if (chart.icons)
    chart.icons.forEach((icon) => {
      if (!isObjectEmpty(icon) && icon) icon.destroy()
    })

  chart.icons = []
  const plotlines = chart.xAxis[0].plotLinesAndBands
  const relevantManualAdjustments = manualAdjustments.filter((adjustment) =>
    plotlines.some((plotline) => plotline.options.id === adjustment.id),
  )

  const buildAndPositionIcon = (icon, prevIcons, x, y, fill) => {
    // stagger the icons if they overlap
    if (prevIcons.length > 0) {
      const overlappingIcons = prevIcons.filter((prevIcon) => prevIcon.x + diameter >= x)
      const minY = Math.min(...overlappingIcons.map((prevIcon) => prevIcon.y))
      const maxY = Math.max(...overlappingIcons.map((prevIcon) => prevIcon.y))
      if (minY < diameter) {
        y = maxY + diameter
      }
    }

    const iconElement = chart.renderer.path(icon).attr({ fill, zIndex: 9999 }).add()
    iconElement.x = x
    iconElement.y = y
    iconElement.translate(x, y)

    if (!isObjectEmpty(iconElement)) {
      chart.icons.push(iconElement)
    }

    return iconElement
  }

  let icons = []

  for (let i = 0; i < plotlines.length; i++) {
    const plotLine = plotlines[i].svgElem
    const plotLinePos = plotLine.getBBox()
    const x = plotLinePos.x - radius
    let y = plotLinePos.y - radius - 1
    const prevIcons = icons.slice(0, i)

    buildAndPositionIcon(PLOTLINE_BACKDROP_ICON_PATH, prevIcons, x, y, '#fff')
    const icon = buildAndPositionIcon(PLOTLINE_ICON_PATH, prevIcons, x, y, blue)
    icons.push(icon)

    const transparentIcon = buildAndPositionIcon(
      PLOTLINE_ICONS_TRANSPARENT_PATH,
      prevIcons,
      x,
      y,
      '#0000',
    )

    transparentIcon.on('mouseover', function () {
      try {
        !isObjectEmpty(chart.customTooltip) && chart.customTooltip && chart.customTooltip.destroy()
      } catch (e) {
        console.error(e)
        chart.customTooltip = null
      }

      icon.attr({ fill: darkBlue })
      const line = chart.xAxis[0].plotLinesAndBands[i]
      const adjustment = relevantManualAdjustments.find((adjustment) => adjustment.id === line.options.id)
      if (!adjustment) return

      buildAdjustmentLabel(plotLinePos, icon, adjustment, chart)

      chart.customTooltip.show()
    })

    transparentIcon.on('mouseout', function () {
      icon.attr({ fill: blue })
      !isObjectEmpty(chart.customTooltip) && chart.customTooltip && chart.customTooltip.destroy()
    })
  }

  icons = null
}

export function filterManualAdjustments(manualAdjustments, type, rangeFilter) {
  if (!manualAdjustments) return []

  let filteredManualAdjustments = manualAdjustments[type]

  if (!filteredManualAdjustments) return []

  if (rangeFilter !== 'all') {
    const today = new Date()
    const twoWeeksAgo = new Date(today)
    twoWeeksAgo.setDate(today.getDate() - 14)
    const twoMonthsAgo = new Date(today)
    twoMonthsAgo.setDate(today.getDate() - 56)

    filteredManualAdjustments = filteredManualAdjustments.filter((adjustment) => {
      const date = new Date(adjustment.x)
      if (rangeFilter === 'twoWeeks') return date >= twoWeeksAgo && date <= today
      if (rangeFilter === 'twoMonths') return date >= twoMonthsAgo && date <= today
      return false
    })
  }

  return orderBy(filteredManualAdjustments, ['x'], ['asc'])
}

function buildAdjustmentLabel(plotLinePos, icon, adjustment, chart) {
  const categoryTpl = '<h5>%s - <strong style="value">%s</strong></h5>'
  const iconBoundingBox = icon.getBBox()
  let labelX = plotLinePos.x + iconBoundingBox.x
  const locale = i18n.language

  const formattedDate = formatAdjustmentDate(locale, adjustment)
  const labelHtml = getLabelHtmlContent(adjustment, categoryTpl, formattedDate)
  chart.customTooltip = chart.renderer
    .label(labelHtml, labelX, plotLinePos.y + icon.y + 10, 'rect', 0, 0, true, false, 'custom-label')
    .add()

  positionLabel(chart, labelX, plotLinePos)
}

function positionLabel(chart, labelX, plotLinePos) {
  // center the label on the icon
  let tooltipWidth = chart.customTooltip.getBBox().width
  // eslint-disable-next-line no-mixed-operators
  chart.customTooltip.attr({ x: labelX - tooltipWidth / 2 })

  // reposition the label if it goes out of the chart
  tooltipWidth = chart.customTooltip.getBBox().width
  const chartWidth = chart.chartWidth
  if (labelX + tooltipWidth > chartWidth) {
    const extraOffset = plotLinePos.x + 16 < chartWidth ? 16 : 0
    chart.customTooltip.attr({
      x: plotLinePos.x - tooltipWidth + extraOffset,
      class: 'highcharts-label highcharts-custom-label highcharts-custom-label-right',
    })
  }

  // add the highcharts-custom-label-left if half the first half of the label is out of the chart
  // eslint-disable-next-line no-mixed-operators
  if (labelX - tooltipWidth / 2 < 0) {
    chart.customTooltip.attr({
      x: plotLinePos.x - diameter,
      class: 'highcharts-label highcharts-custom-label highcharts-custom-label-left',
    })
  }
}

function getLabelHtmlContent(adjustment, categoryTpl, formattedDate) {
  const categories = Object.keys(adjustment.parts).reduce((acc, key) => {
    const value = Number(adjustment.parts[key]).toFixed(1)
    const ticketType = i18n.t(`Event.ticketType.${camelCase(key.replaceAll('_', ' '))}`)
    return acc + categoryTpl.replace('%s', ticketType).replace('%s', value)
  }, '')
  const localizedManualUpdate = i18n.t('Event.transactionGraph.manualUpdate')
  const labelHtml = '<div class="custom-label-content">' +
    `<h5>${localizedManualUpdate}:</h5>` +
    categories +
    '</br>' +
    `<p><span class="date">${formattedDate}</span></p>` +
    '</div>'
  return labelHtml
}

function formatAdjustmentDate(locale, adjustment) {
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }).format(new Date(adjustment.x))
}

import React from 'react'
import LanguageSwitcher from '../../components/Shared/LanguageSwitcher'
/**
 * Footer in the Login, Forgot Password and Registration pages
 * @Tags( presentational )
 */
const Footer = () => {
  return (
    <table className="footer">
      <tbody>
        <tr>
          <td>
            <LanguageSwitcher />
          </td>
          <td>
            <a
              href="https://www.future-demand.com/imprint"
              className="footer__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Imprint
            </a>
          </td>
          <td>
            <a
              href="https://www.future-demand.com/privacy"
              className="footer__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy policy
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default Footer

import { createContext, useCallback, useContext, useEffect, useReducer } from 'react'

import { notificationsApi } from 'api/notifications'
import { FAIL, LOADING, OVERWRITE, objectReducer } from 'reducers/default'
import { initStateObject } from 'reducers/default'
import { returnErrorMessage } from 'utils/helpers'

export const actions = Object.freeze({
  loading: 'LOADING',
  error: 'ERROR',
  success: 'SUCCESS',

  toggleSelectionMode: 'TOGGLE_SELECTION_MODE',
  selectAll: 'SELECT_ALL',
  selectOne: 'SELECT_ONE',
  updateSuccess: 'UPDATE_SUCCESS',
  updateError: 'UPDATE_ERROR',
  updateLoading: 'UPDATE_LOADING',
  updateRead: 'UPDATE_READ',

  setFilters: 'SET_FILTERS',
  setActiveNotificationId: 'SET_ACTIVE_NOTIFICATION_ID',

  setStats: 'SET_STATS',

  fetchNotificationsPageAsync: 'FETCH_NOTIFICATIONS_ASYNC',
  updateNotificationAsync: 'UPDATE_NOTIFICATION_ASYNC',
  patchNotificationsAsync: 'PATCH_NOTIFICATIONS_ASYNC',
  fetchStatsAsync: 'FETCH_STATS_ASYNC',
})

export const initialState = {
  notifications: {
    items: [],
    total: 0,
    maxPage: 0,
    page: 0,
    loading: false,
    error: null,
    pageToFetch: 1,
  },
  filters: {
    notificationTopics: null,
    unreadOnly: false,
  },
  selectionMode: {
    enabled: false,
    selectedIds: [],
    loading: false,
    error: null,
  },
  activeNotificationId: null,
  stats: {
    total: 0,
    unread: 0,
  },
}

export const NotificationContext = createContext(initialState)
export const NotificationDispatchContext = createContext(null)

function getErrorMessage(error) {
  if (error.response?.data?.message) return error.response.data.message

  return error.message ?? 'common.errors.inServerResponse'
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.loading:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: true,
        },
      }
    case actions.error:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: false,
          error: getErrorMessage(action.payload),
        },
      }
    case actions.success:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: false,
          error: null,
          items:
            action.payload.page === 1
              ? action.payload.items
              : action.payload.page === state.notifications.page
                ? state.notifications.items
                : [...state.notifications.items, ...action.payload.items],
          page: action.payload.page,
          pageToFetch:
            action.payload.page === action.payload.maxPage
              ? state.notifications.pageToFetch
              : state.notifications.pageToFetch + 1,
          maxPage: action.payload.maxPage,
          total: action.payload.total,
        },
      }
    case actions.toggleSelectionMode:
      return {
        ...state,
        selectionMode: {
          ...state.selectionMode,
          enabled: !state.selectionMode.enabled,
          selectedIds: [],
        },
      }
    case actions.selectAll:
      return {
        ...state,
        selectionMode: {
          ...state.selectionMode,
          selectedIds: state.notifications.items.map((x) => x.id),
        },
      }
    case actions.selectOne:
      return {
        ...state,
        selectionMode: {
          ...state.selectionMode,
          selectedIds: state.selectionMode.selectedIds.includes(action.payload)
            ? state.selectionMode.selectedIds.filter((x) => x !== action.payload)
            : [...state.selectionMode.selectedIds, action.payload],
        },
      }
    case actions.updateSuccess:
      return {
        ...state,
        selectionMode: {
          enabled: false,
          selectedIds: [],
          loading: false,
          error: null,
        },
      }
    case actions.updateError:
      return {
        ...state,
        selectionMode: {
          ...state.selectionMode,
          loading: false,
          error: getErrorMessage(action.payload),
        },
      }
    case actions.updateLoading:
      return {
        ...state,
        selectionMode: {
          ...state.selectionMode,
          loading: true,
        },
      }
    case actions.setFilters:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }
    case actions.updateRead: {
      const { notificationIds, isRead } = action.payload
      const notifications = state.notifications.items.map((x) => ({
        ...x,
        is_read: notificationIds?.length ? (notificationIds.includes(x.id) ? isRead : x.is_read) : isRead,
      }))

      return {
        ...state,
        notifications: {
          ...state.notifications,
          items: notifications,
        },
      }
    }
    case actions.setActiveNotificationId:
      return {
        ...state,
        activeNotificationId: action.payload,
      }
    case actions.setStats:
      return {
        ...state,
        stats: { ...action.payload },
      }
    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}

export const asyncActionHandlers = Object.freeze({
  [actions.fetchNotificationsPageAsync]:
    ({ dispatch, getState }) =>
      async (action) => {
        dispatch({ type: actions.loading })
        try {
          const state = getState()
          const payload = action.payload ?? {}
          const filters = payload.filters ?? {}
          if (filters) {
            dispatch({ type: actions.setFilters, payload: filters })
          }
          const notifications = await notificationsApi.getAll({
            page: payload.page ?? state.notifications.page + 1,
            notificationTopics: filters.notificationTopics ?? state.filters.notificationTopics,
            unreadOnly: filters.unreadOnly ?? state.filters.unreadOnly,
          })
          dispatch({ type: actions.success, payload: notifications })
        } catch (error) {
          console.error(error)
          dispatch({ type: actions.error, payload: error })
        }
      },
  [actions.updateNotificationAsync]:
    ({ dispatch }) =>
      async (action) => {
        dispatch({ type: actions.updateLoading })
        try {
          const { notificationId, isRead } = action.payload
          await notificationsApi.markAsRead({ notificationId, isRead })
          dispatch({ type: actions.updateRead, payload: { notificationIds: [notificationId], isRead } })
          dispatch({ type: actions.updateSuccess })
          try {
            const stats = await notificationsApi.getStats()
            dispatch({ type: actions.setStats, payload: stats })
          } catch (error) {
            console.error(error)
          }
        } catch (error) {
          console.error(error)
          dispatch({ type: actions.updateError, payload: error })
        }
      },
  [actions.patchNotificationsAsync]:
    ({ dispatch, getState }) =>
      async () => {
        dispatch({ type: actions.updateLoading })
        try {
          const {
            selectionMode: { selectedIds },
          } = getState()
          await notificationsApi.updateAll({ notificationIds: selectedIds || null })
          dispatch({ type: actions.updateRead, payload: { notificationIds: selectedIds || null, isRead: true } })
          dispatch({ type: actions.updateSuccess })

          try {
            const stats = await notificationsApi.getStats()
            dispatch({ type: actions.setStats, payload: stats })
          } catch (error) {
            console.error(error)
          }
        } catch (error) {
          console.error(error)
          dispatch({ type: actions.updateError, payload: error })
        }
      },
  [actions.fetchStatsAsync]:
    ({ dispatch }) =>
      async () => {
        try {
          const stats = await notificationsApi.getStats()
          dispatch({ type: actions.setStats, payload: stats })
        } catch (error) {
          console.error(error)
        }
      },
})

export function useNotification(id) {
  const [notificationState, dispatchNotificationAction] = useReducer(objectReducer, initStateObject)
  const dispatch = useContext(NotificationDispatchContext)

  const fetchNotification = useCallback(async () => {
    if (!id) return

    try {
      dispatchNotificationAction({ type: LOADING })
      const notification = await notificationsApi.get({ notificationId: id })
      dispatchNotificationAction({ type: OVERWRITE, payload: notification })

      if (!notification?.is_read) {
        dispatch({ type: actions.updateNotificationAsync, payload: { notificationId: id, isRead: true } })
      }
    } catch (error) {
      dispatchNotificationAction({ type: FAIL, payload: returnErrorMessage(error) })
      console.error(error)
    }
  }, [id, dispatch])

  useEffect(() => {
    fetchNotification()
  }, [fetchNotification])

  return {
    state: notificationState,
    dispatch: dispatchNotificationAction,
  }
}

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'

const defaultStyle = (duration) => ({
  transition: `all ${duration}ms ease-in-out`,
  transform: `translateY(${20}px)`,
  opacity: 0,
})

const transitionStyles = {
  entering: { opacity: 1, transform: 'translateY(0px)' },
  entered: { opacity: 1, transform: 'translateY(0px)' },
  exiting: { opacity: 0, transform: 'translateY(20px)' },
  exited: { opacity: 0, transform: 'translateY(20px)' },
}

export default function Fade({ children, className="", transitionDuration = 400, delay = 200 }) {
  const nodeRef = useRef(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setTimeout(() => setMounted(true), delay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Transition nodeRef={nodeRef} in={mounted} timeout={transitionDuration}>
      {(state) => (
        <div
          ref={nodeRef}
          style={{
            ...defaultStyle(delay),
            ...transitionStyles[state],
          }}
          className={className}
        >
          {children}
        </div>
      )}
    </Transition>
  )
}

Fade.propTypes = {
  in: PropTypes.bool,
  children: PropTypes.node.isRequired,
  transitionDuration: PropTypes.number,
  delay: PropTypes.number,
  className: PropTypes.string,
}

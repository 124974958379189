import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Searchbar from '../../Shared/Searchbar'
import EventSearchRow from './EventSearchRow'
import searchLogo from '../../../images/icons/search_blue.svg'
import { formatEventSubheadlineLocation } from 'utils/helpers'
import { CancelIcon } from 'images'
import LoadingSpinner from 'components/Shared/LoadingSpinner'

const SearchEventList = ({
  search,
  handleKeywordChange,
  filteredEvents,
  allEvents,
  handleSelectCopyEvent,
  closeModal,
}) => {
  const { t } = useTranslation()
  const [showAllEvents, setShowAllEvents] = useState(true)

  const shouldShowAllEvents = showAllEvents || !search

  const displayEvents = () => {
    if (allEvents.loading || filteredEvents.loading) return <LoadingSpinner className="mt-4 p-3" />
    else {
      return (
        <div>
          <div className="mt-4">
            {shouldShowAllEvents && <h5 className="event-editor_search-view_sub-title">{search}</h5>}
            {search &&
              filteredEvents &&
              filteredEvents.items &&
              filteredEvents.items.length > 0 &&
              filteredEvents.items.map((eachEvent) => {
                return (
                  <EventSearchRow
                    title={eachEvent.title}
                    location={formatEventSubheadlineLocation({
                      city: eachEvent.city,
                      venue: eachEvent.venue,
                      hall: eachEvent.hall,
                    })}
                    date={eachEvent.date}
                    handleClick={() => handleSelectCopyEvent(eachEvent.id)}
                    key={`filtered-event-${eachEvent.id}`}
                  />
                )
              })}
          </div>

          {shouldShowAllEvents && (
            <div className="mt-4">
              <h5 className="event-editor_search-view_sub-title">{t('EventEditor.allEvents')}</h5>
              {allEvents &&
                allEvents.items &&
                allEvents.items.length > 0 &&
                allEvents.items.map((eachEvent) => {
                  return (
                    <EventSearchRow
                      key={`all-event-${eachEvent.id}`}
                      title={eachEvent.title}
                      location={formatEventSubheadlineLocation({
                        city: eachEvent.city,
                      })}
                      date={eachEvent.date}
                      handleClick={() => handleSelectCopyEvent(eachEvent.id)}
                    />
                  )
                })}
            </div>
          )}
        </div>
      )
    }
  }

  return (
    <div className="event-editor_search-view_wrapper">
      <div className="d-flex justify-content-between gap-4">
        <h3 className="event-editor_search-view_title m-0">{t('EventEditor.searchEvent')}</h3>
        <button type="button" onClick={() => closeModal(false)} className="event-editor_modal-content_btn_wrapper">
          <CancelIcon width={16} />
        </button>
      </div>
      <div className="mt-4">
        <Searchbar
          defaultValue={search}
          handleChange={(q) => {
            setShowAllEvents(false)
            handleKeywordChange(q)
          }}
          autoFocus
          postfixView={<img src={searchLogo} className="searchbar-comp_icon" alt="Search" />}
        />
      </div>

      {displayEvents()}
    </div>
  )
}

SearchEventList.propTypes = {
  search: PropTypes.string,
  handleKeywordChange: PropTypes.func,
  filteredEvents: PropTypes.object,
  allEvents: PropTypes.object,
  handleSelectCopyEvent: PropTypes.func,
  closeModal: PropTypes.func,
}

export default SearchEventList

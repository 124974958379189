import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { SecondaryButton } from '../../Shared/Button'

const CopyModalContent = ({
  setSetlists,
  setLineups,
  currentSetlists,
  currentLineups,
  closeModalAndShowNotification,
  newSetlists,
  newLineups,
}) => {
  const { t } = useTranslation()
  const onAppend = () => {
    setSetlists([...currentSetlists, ...newSetlists])
    setLineups([...currentLineups, ...newLineups])
    closeModalAndShowNotification()
    return
  }

  const onOverwrite = () => {
    if (newSetlists.length > 0) {
      setSetlists(newSetlists)
    }

    if (newLineups.length > 0) {
      setLineups(newLineups)
    }

    closeModalAndShowNotification()
    return
  }

  return (
    <div className="event-editor_copy-modal_confirmation-modal_wrapper">
      <h3 className="event-editor_search-view_title m-0">{t('EventEditor.confirmYourAction')}</h3>
      <p className="event-editor_copy-modal_confirmation-modal_sub-text">
        <Trans i18nKey="EventEditor.overwritePrompt">
          To ensure your choice is handled correctly, <br /> please confirm whether you would like to overwrite the
          existing data or append to it. <br /> Your choice will be applied accordingly.
        </Trans>
      </p>
      <div className="event-editor_copy-modal_confirmation-modal_buttons-wrapper">
        <SecondaryButton text={t('common.overwrite')} onClick={onOverwrite} />
        <SecondaryButton text={t('common.append')} onClick={onAppend} />
      </div>
    </div>
  )
}

export default CopyModalContent

import React, { createContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useReducerAsync } from 'hooks/useReducerAsync'
import { actions, reducer, initialState, asyncActionHandlers } from './eventAttributionModelReducer'

export const AttributionModelContext = createContext()

export default function EventAttributionModelContextProvider({ eventId, children }) {
  const [{ attributionModel, loading, error }, dispatch] = useReducerAsync(reducer, initialState, asyncActionHandlers)

  useEffect(() => {
    dispatch({ type: actions.fetchEventAttributionModelAsync, payload: { eventId } })
  }
  , [eventId, dispatch])

  return (
    <AttributionModelContext.Provider value={{ attributionModel, loading, error, dispatch }}>
      {children}
    </AttributionModelContext.Provider>
  )
}

EventAttributionModelContextProvider.propTypes = {
  eventId: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export const useAttributionModel = () => {
  const context = React.useContext(AttributionModelContext)
  if (context === undefined) {
    throw new Error('useAttributionModel must be used within a AttributionModelContextProvider')
  }
  return context
}

export const useAttributionModelDispatch = () => {
  const { dispatch } = useAttributionModel()
  return dispatch
}

import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { isEqual } from 'lodash'

import Portal from 'components/Shared/Portal'
import Modal from 'components/Shared/Modal'
import LocationForm from './LocationForm'
import RangeLocationsActions from './RangeLocationsActions'
import LocationsSummary from './LocationsSummary'
import { useTargeting } from '../TargetingContext'
import { useRangeLocations, useRangeLocationsDispatch } from './RangeLocationsContextProvider'
import { Prompt } from './Prompt'
import { actions, getRadiusVariations, initialState } from './rangeLocationsReducer'

export default function RangeLocations({ tc, isOpen, setOpen, onSave }) {
  const { t } = useTranslation()
  const [showPrompt, setShowPrompt] = useState(null)
  const { value: targeting } = useTargeting()
  const { locations } = useRangeLocations()
  const dispatch = useRangeLocationsDispatch()
  const { hasUnsavedChanges, hasUnsavedChangesWithExistingData, hasDeleted } = useMemo(() => {
    const tcTargeting = targeting.find((x) => x.tc === tc)
    if (!tcTargeting) {
      return {
        hasDeleted: false,
        hasUnsavedChangesWithExistingData: false,
        hasUnsavedChanges: false,
      }
    }

    const savedLocationKeys = initialState(tcTargeting).locations.map(
      (x) => `${x.data.key}-${getRadiusVariations(x.radius)?.mile_range}`,
    )
    const currentLocationKeys = locations.map((x) => `${x.data.key}-${getRadiusVariations(x.radius)?.mile_range}`)

    const hasDeleted = savedLocationKeys.length > currentLocationKeys.length
    const hasUnsavedChanges = !isEqual(savedLocationKeys, currentLocationKeys)
    const hasUnsavedChangesWithExistingData = hasUnsavedChanges && savedLocationKeys.length > 0

    return { hasUnsavedChanges, hasUnsavedChangesWithExistingData, hasDeleted }
  }, [locations, targeting, tc])

  const onClose = useCallback(() => {
    if (hasUnsavedChanges) {
      setShowPrompt(true)
    } else {
      setOpen(false)
    }
  }, [setOpen, hasUnsavedChanges])

  const saveAndClose = useCallback(
    (locations) => {
      onSave(locations)
      setOpen(false)
    },
    [onSave, setOpen],
  )

  return (
    <div className="range-locations">
      {isOpen && !showPrompt && (
        <Portal wrapperId="app-portal">
          <Modal
            closeCallback={onClose}
            title={t('Recommendations.geoTargeting.rangeLocations.title')}
            headerRenderer={({ title, className }) => <h4 className={cn(className, 'mt-3 mr-3')}>{title}</h4>}
            offWhiteBg
            size="lg"
            fullWidth={false}
            onClickOutside={onClose}
            mainContent={
              <div className="range-locations">
                <div className="range-locations_content">
                  <p className="my-3 fs-16">{t('Recommendations.geoTargeting.rangeLocations.description')}</p>
                  <LocationForm />
                  <LocationsSummary />
                  <RangeLocationsActions onSave={() => saveAndClose()} />
                </div>
              </div>
            }
          />
        </Portal>
      )}
      <Prompt
        isOpen={!!showPrompt}
        forDeleted={hasDeleted}
        forUnsavedChangesWithExistingData={hasUnsavedChangesWithExistingData}
        onYes={() => {
          setShowPrompt(null)
          const tcTargeting = targeting.find((x) => x.tc === tc) ?? { tc }
          const { locations: savedLocations } = initialState(tcTargeting)
          const locationsToKeep = savedLocations.filter((x) => locations.some((y) => y.data.key === x.data.key))
          dispatch({ type: actions.reset, payload: { tcTargeting, locations: locationsToKeep } })
          saveAndClose(locationsToKeep)
        }}
        onNo={() => {
          setShowPrompt(null)

          // restore locations from targeting, and add them to the state
          const tcTargeting = targeting.find((x) => x.tc === tc) ?? { tc }
          const { locations: savedLocations } = initialState(tcTargeting)
          // restore saved locations that do not exist in the current state
          const missingLocations = savedLocations.filter((x) => !locations.some((y) => y.data.key === x.data.key))
          if (missingLocations.length > 0) {
            dispatch({ type: actions.reset, payload: { tcTargeting, locations: [...locations, ...missingLocations] } })
          }
        }}
      />
    </div>
  )
}

RangeLocations.propTypes = {
  tc: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func,
}

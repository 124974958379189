import PropTypes from 'prop-types'
import React, { useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import PredictionBox from '../../Shared/PredictionBox'

import { initStateObject, objectReducer } from '../../../reducers/default'
import { fetchEventBenchmark } from '../../../api/events'
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary'
import ErrorFallback from '../../Shared/ErrorFallback'

/**
 * Two benchmark Prediction boxes on event page under header.
 * @Tags( event, api )
 * @Endpoints( /events/[eid]/benchmark )
 * @ApiLogic( ../../api/events )
 */
export default function EventBenchmarks({ eventId }) {
  const { t } = useTranslation()
  const [benchmark, dispatch] = useReducer(objectReducer, initStateObject)
  const handleError = useErrorHandler()
  if (benchmark.error) handleError({ userFriendlyMessage: benchmark.message })

  useEffect(() => {
    fetchEventBenchmark(dispatch, eventId)
  }, [eventId])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={`row events-benchmarks gap-3 ${benchmark.success ? '' : 'no-results'}`}>
        {benchmark.content.today && (
          <div className="events-benchmarks__item col">
            <PredictionBox
              main={{
                title: t('Event.benchmarks.now.left'),
                number: benchmark.content.today.value,
              }}
              second={{
                title: t('Event.benchmarks.now.right'),
                number: benchmark.content.today.benchmark,
              }}
              type="now"
            />
          </div>
        )}
        {benchmark.content.prediction && (
          <div className="events-benchmarks__item col">
            <PredictionBox
              main={{
                title: t('Event.benchmarks.prediction.left'),
                number: benchmark.content.prediction.value,
              }}
              second={{
                title: t('Event.benchmarks.prediction.right'),
                number: benchmark.content.prediction.benchmark,
              }}
              type="prediction"
            />
          </div>
        )}
      </div>
    </ErrorBoundary>
  )
}

EventBenchmarks.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

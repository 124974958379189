import React, {  } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'


import ErrorMessage from '../../Shared/ErrorMessage'
import LoadingSpinner from '../../Shared/LoadingSpinner'
import PrimaryButton, { TransparentBgButton } from '../../Shared/Button'

export default function FormButtons({ submitForm, onCancelClick, loading, error }) {
  const { t } = useTranslation()

  return (
    <div className="row mb-md-5 mb-lg-0">
      <div className="col-12 mb-md-5">
        <div className="d-flex flex-row justify-content-between mb-md-4">
          <div className="text-left">
            <TransparentBgButton
              className="bt"
              onClick={onCancelClick}
              text={t('Simulation.form.cancel')}
              fullWidth={false}
              color="navy"
            />
          </div>
          <div className="text-right">
            {loading ? (
              <LoadingSpinner title={t('common.sending')} portalBackground={true} />
            ) : (
              <PrimaryButton
                type="button"
                classNames="simulation-btn"
                onClick={submitForm}
                text={t('Simulation.form.btn')}
                disabled={loading}
              />
            )}
          </div>
        </div>
        {error && <ErrorMessage danger>{t(error)}</ErrorMessage>}
      </div>
    </div>
  )
}

FormButtons.propTypes = {
  submitForm: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

import { read, utils } from 'xlsx'
import * as Sentry from '@sentry/react'
import { isNumber } from 'lodash'

import { ticketTypesDisplayNames } from 'api/models'
import { BULK_UPLOAD_MIN_REVENUE, BULK_UPLOAD_MIN_TICKETS_SOLD } from '../utils'
import { uploadSalesDocument } from 'api/events'

export const VALID_MIME_TYPES = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

const errors = Object.freeze({
  fileRequired: 'FILE_REQUIRED',
  invalidFileFormat: 'INVALID_FILE_FORMAT',
  invalidFileData: 'INVALID_FILE_DATA',
  noSalesRecords: 'NO_SALES_RECORDS',
  fileNotUniqueOrInvalid: 'FILE_NOT_UNIQUE_OR_INVALID',
  fileNotUnique: 'FILE_NOT_UNIQUE',
})

const remoteErrors = Object.freeze([
  'error.upload_sales_from_excel.invalid_ticket_type',
  'error.upload_sales_from_excel.invalid_transaction_date',
  'error.upload_sales_from_excel.invalid_transaction_count',
  'error.upload_sales_from_excel.invalid_total_revenue',
  'error.upload_sales_from_excel.already_exist',
  'error.upload_sales_from_excel.empty_file',
  'error.upload_sales_from_excel.invalid_schema',
  'error.upload_sales_from_excel.required_fields_missing',
  'error.upload_sales_from_excel.invalid_file_type',
])

const cells = Object.freeze({
  'Date*': {
    index: 0,
    validator: () => {
      return true // Backend validation is more accurate
    },
  },
  'Tickets*': {
    index: 1,
    validator: (value) => {
      return Number.isInteger(value) && value >= BULK_UPLOAD_MIN_TICKETS_SOLD
    },
  },
  'Revenue*': {
    index: 2,
    validator: (value) => {
      return isNumber(value) && value >= BULK_UPLOAD_MIN_REVENUE
    },
  },
  'Ticket Type': {
    index: 3,
    validator: (value) => {
      return !value || ticketTypesDisplayNames.some((x) => x.toLowerCase() === value.toLowerCase())
    },
  },
})

const isHeaderRowValid = (headerRow) => {
  return (
    headerRow?.length >= 4 &&
    headerRow.slice(0, 4).every((cell, index) => {
      return cells[cell]?.index === index
    })
  )
}

const isRowValid = (row, headerRow) => {
  return row.every((cellContent, index) => {
    if (index >= headerRow.length) return true // ignore extra cells
    const cellName = headerRow[index] // takes care of validating cell index

    return cells[cellName].validator(cellContent)
  })
}

async function validateAsync(file) {
  let totalEntries = 0

  try {
    if (!file) return { totalEntries, error: errors.fileRequired }

    if (VALID_MIME_TYPES.every((x) => x !== file.type)) {
      return { totalEntries, error: errors.invalidFileFormat }
    }

    const buffer = await file.arrayBuffer()
    const workbook = read(buffer)

    for (const sheetName of workbook.SheetNames) {
      const workbookSheet = workbook.Workbook.Sheets.find((x) => x.name === sheetName)
      if (workbookSheet.Hidden || sheetName === 'Instructions') continue

      const sheet = workbook.Sheets[sheetName]
      const sheetMatrix = utils.sheet_to_json(sheet, { header: 1, blankrows: false })
      const headerRow = sheetMatrix[0]

      if (!isHeaderRowValid(headerRow)) return { totalEntries, error: errors.invalidFileFormat }

      totalEntries += sheetMatrix.slice(1).length

      if (sheetMatrix.slice(1).some((row) => !isRowValid(row, headerRow)))
        return { totalEntries, error: errors.invalidFileData }
    }

    if (totalEntries === 0) return { totalEntries, error: errors.noSalesRecords }

    return { totalEntries }
  } catch (e) {
    Sentry.captureException(e)
    return { totalEntries, error: errors.invalidFileFormat }
  }
}

export const initialState = {
  valid: null,
  loading: false,
  file: null,
  error: null,
  uploaded: false,
}

export const actions = Object.freeze({
  addOrReplaceFile: 'ADD_OR_REPLACE_FILE',
  upload: 'UPLOAD',

  fileAdded: 'FILE_ADDED',
  fileUploaded: 'FILE_UPLOADED',
  failedToUpload: 'FAILED_TO_UPLOAD',
  removeFile: 'REMOVE_FILE',
  loading: 'LOADING',
})

export function reducer(state, action) {
  switch (action.type) {
    case actions.fileAdded:
      return { ...state, ...action.payload }
    case actions.removeFile:
      return { ...state, file: null, valid: null, errors: [] }
    case actions.loading:
      return { ...state, loading: true }
    case actions.fileUploaded:
      return { ...state, ...action.payload, uploaded: true, loading: false }
    case actions.failedToUpload:
      return { ...state, ...action.payload, uploaded: false, loading: false }
    default:
      throw new Error(`Unknown action type ${action.type}`)
  }
}

export const asyncActionHandlers = {
  [actions.addOrReplaceFile]:
    ({ dispatch, getState }) =>
      async (action) => {
        const file = action.payload
        const { error, totalEntries } = await validateAsync(file)
        const state = getState()

        const payload = {
          ...state,
          file,
          totalEntries,
          error,
          valid: !error,
        }

        dispatch({ type: actions.fileAdded, payload })
      },
  [actions.upload]:
    ({ dispatch, getState }) =>
      async (action) => {
        const state = getState()
        const { eventId } = action.payload
        const file = state.file

        if (!file) throw new Error('Missing file')

        dispatch({ type: actions.loading })
        let { success, error_code, error_subcode } = await uploadSalesDocument(eventId, file)

        if (error_code && remoteErrors.every((x) => x !== error_code)) {
          error_code = errors.fileNotUniqueOrInvalid
        }

        if (error_subcode) {
          error_code = `${error_code}.${error_subcode}`
        }

        const payload = {
          error: error_code,
          valid: success,
        }

        if (success) dispatch({ type: actions.fileUploaded, payload })
        else dispatch({ type: actions.failedToUpload, payload })
      },
}

import React, { useEffect, useState, useReducer, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { fetchClientSocialPlatforms, fetchConversionGoals } from '../../../api/integrations'
import { initStateArray, arrayReducer, OVERWRITE } from '../../../reducers/default'
import classNames from 'classnames'

import { useOutsideCheckClick } from '../../../hooks/UseOnClickOutside'

import TipDialogueBox from '../../Shared/TipDialogueBox'

import LoadingSpinner, { SpinnerSize } from '../../Shared/LoadingSpinner'
import InfoBox from '../../Shared/InfoBox'
import { AssetClass, CampaignGoal, DEFAULT_CAMPAIGN_GOAL, Platform } from '../../../api/models'
import SearchDropdown from './Edit/SearchDropdown'
import { getErrorText } from './Edit/adAccountHelper'
import { RemoveIcon } from 'images'

function PlatformSelectMenu({
  handleClick,
  integrations,
  required = false,
  subHeadlineText,
  removeInteg,
  selectedIntegration,
  selectText,
  disabled,
}) {
  const autoSelected = integrations.length === 1 && required
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const menuRef = useRef(null)

  useOutsideCheckClick(menuRef, setOpen, open)

  const handleMenuClick = () => {
    !disabled && setOpen(!open)
  }

  return (
    <>
      {!!subHeadlineText && <strong className="select-ad-platform_select-menu_select-label">{subHeadlineText}</strong>}
      <div
        onClick={handleMenuClick}
        ref={menuRef}
        className={classNames({ 'select-ad-platform_select-menu_wrapper': true, open })}
      >
        <div
          className={classNames({
            'select-ad-platform_select-menu_input': true,
            open,
            selected: autoSelected || selectedIntegration?.id ? 'selected' : '',
            disabled,
          })}
        >
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="selected-value" style={{ color: autoSelected ? '#3b3b3b' : '#5C5C5C' }}>
              {selectedIntegration?.asset_label || selectText}
            </div>

            {!required && selectedIntegration?.id && !disabled && (
              <div className="remove">
                <RemoveIcon width={14} onClick={(e) => removeInteg(e, selectedIntegration.id)} />
              </div>
            )}
          </div>
          {!disabled && (
            <div
              style={{
                transitionDuration: '0.2s',
                marginLeft: !autoSelected && integrations.length > 1 ? 'unset' : 'auto',
                transform: open ? 'rotate(180deg)' : 'rotate(0)',
              }}
              className={classNames({ 'events-insights-page__status_menu_arrow': true, open })}
            />
          )}
        </div>
        <div className={classNames({ 'select-ad-platform_select-menu_drop-menu_wrapper': true, open })}>
          {/* we have auto selected the only choice available and there are no others remaining */}
          {integrations.length === 1 && selectedIntegration?.id && (
            <div className="select-ad-platform_select-menu_drop-menu_row_wrapper">
              <div style={{ color: '#5C5C5C', width: '100%', textAlign: 'center' }}>{t('common.noMoreFields')}</div>
            </div>
          )}

          {/* there are no other choices remaining - show an empty select list */}
          {integrations.length === 0 && (
            <div className="select-ad-platform_select-menu_drop-menu_row_wrapper">
              <div style={{ color: '#5C5C5C', width: '100%', textAlign: 'center' }}>{t('common.noMoreFields')}</div>
            </div>
          )}

          {/* we have many integration choices available - allow the user to choose from a list */}
          {integrations &&
            integrations.length > 0 &&
            integrations.map((eachIntegration, index) => {
              if (selectedIntegration?.id === eachIntegration.id) return null
              return (
                <div
                  className={'select-ad-platform_select-menu_drop-menu_row_wrapper'}
                  key={index}
                  onClick={(e) => {
                    handleClick(e, eachIntegration.id)
                    setOpen(false)
                  }}
                >
                  <div>{eachIntegration.asset_label}</div>
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}

PlatformSelectMenu.propTypes = {
  handleClick: PropTypes.func.isRequired,
  integrations: PropTypes.array.isRequired,
  required: PropTypes.bool,
  subHeadlineText: PropTypes.string,
  removeInteg: PropTypes.func,
  selectedIntegration: PropTypes.object,
  selectText: PropTypes.string,
  disabled: PropTypes.bool,
}

const SelectAdPlatformForm = ({
  defaultIntegrations = [],
  previousIntegrations = [],
  onChange,
  isDisabled,
  eventGoal = DEFAULT_CAMPAIGN_GOAL,
  defaultConversionId,
  onConversionGoalChanged,
}) => {
  const { t } = useTranslation()
  const [partnerIntegrations, dispatchPartnerIntegrationsAction] = useReducer(arrayReducer, initStateArray)
  const [selectedIntegrations, dispatchSelectedIntegrationsAction] = useReducer(arrayReducer, {
    ...initStateArray,
    items: defaultIntegrations,
  })
  const [conversionGoals, dispatchConversionGoalsAction] = useReducer(arrayReducer, initStateArray)

  const retrieveConversionGoals = useCallback(() => {
    const adAccountId = selectedIntegrations.items.find((x) => x.asset_class === AssetClass.ad_account)?.asset_id
    if (adAccountId) fetchConversionGoals(dispatchConversionGoalsAction, adAccountId)
  }, [selectedIntegrations])

  useEffect(() => {
    fetchClientSocialPlatforms(dispatchPartnerIntegrationsAction)
  }, [])

  useEffect(() => {
    retrieveConversionGoals()
  }, [retrieveConversionGoals])

  useEffect(() => {
    let integrations = previousIntegrations.length ? previousIntegrations : defaultIntegrations
    const defaultAdAccount = partnerIntegrations.items.find((x) => x.asset_class === AssetClass.ad_account)

    // Select an ad_account by default
    if (defaultAdAccount?.id && !selectedIntegrations.items.some((x) => x.asset_class === AssetClass.ad_account)) {
      integrations = [...integrations, defaultAdAccount]
    }

    dispatchSelectedIntegrationsAction({ type: OVERWRITE, payload: integrations })
    onChange(integrations)
    // eslint-disable-next-line
  }, [defaultIntegrations, previousIntegrations, partnerIntegrations.items])

  useEffect(() => {
    let integrations = defaultIntegrations
    const defaultFacebook = partnerIntegrations.items.filter(
      (x) => x.asset_class === AssetClass.profile && x.platform === 'facebook',
    )
    const defaultInstagram = partnerIntegrations.items.filter(
      (x) => x.asset_class === AssetClass.profile && x.platform === 'instagram',
    )

    // Select a facebook profile by default
    if (
      defaultFacebook.length === 1 &&
      !selectedIntegrations.items.some((x) => x.asset_class === AssetClass.profile && x.platform === 'facebook')
    )
      integrations = [...integrations, defaultFacebook[0]]
    // Select an instagram profile by default
    if (
      defaultInstagram.length === 1 &&
      !selectedIntegrations.items.some((x) => x.asset_class === AssetClass.profile && x.platform === 'instagram')
    )
      integrations = [...integrations, defaultInstagram[0]]

    dispatchSelectedIntegrationsAction({ type: OVERWRITE, payload: integrations })
    onChange(integrations)
    // eslint-disable-next-line
  }, [defaultIntegrations, partnerIntegrations.items])

  const onIntegrationClicked = (id) => {
    const integration = partnerIntegrations.items.find((x) => x.id === id)
    if (!integration?.asset_class)
      throw new Error(
        `Selected integration id was invalid or, or the corresponding integration does not have a valid asset_class: ${id}`,
      )

    const assetClass = integration.asset_class
    const platform = integration.platform

    const newIntegrations = [
      ...selectedIntegrations.items.filter((x) => x.asset_class !== assetClass || x.platform !== platform),
      integration,
    ]

    dispatchSelectedIntegrationsAction({ type: OVERWRITE, payload: newIntegrations })
    onChange(newIntegrations)
  }

  const onIntegrationRemoved = (e, id) => {
    e.preventDefault()
    e.stopPropagation()

    const newIntegrations = selectedIntegrations.items.filter((x) => x.id !== id)

    dispatchSelectedIntegrationsAction({ type: OVERWRITE, payload: newIntegrations })
    onChange(newIntegrations)
  }

  const isPixel =
    (selectedIntegrations.items || []).some((el) => el.asset_class === AssetClass.pixel) &&
    eventGoal !== CampaignGoal.visibility

  return (
    <div>
      {partnerIntegrations.loading ? (
        <LoadingSpinner size={SpinnerSize.LARGE} />
      ) : (
        <form className="select-ad-platform_wrapper">
          <TipDialogueBox title={'Social media'} text={t('Recommendations.addSocialTextTDB')} />
          {partnerIntegrations.error && <p className="warning-red">{partnerIntegrations.message}</p>}
          <div className="select-ad-platform_section">
            <InfoBox
              content="Tips.Recommendations.SelectPlatform"
              placement="bottom"
              classNames="display-flex whitespace"
            >
              <h5 className="select-ad-platform_section_title">{t('Recommendations.socialPublishAccountTitle')}</h5>
            </InfoBox>
            <PlatformSelectMenu
              handleClick={(_, id) => onIntegrationClicked(id)}
              removeInteg={(e, id) => onIntegrationRemoved(e, id)}
              integrations={partnerIntegrations.items.filter(
                (x) => x.asset_class === AssetClass.profile && x.platform === Platform.facebook,
              )}
              subHeadlineText={'Facebook / Meta'}
              selectedIntegration={selectedIntegrations.items.find(
                (x) => x.asset_class === AssetClass.profile && x.platform === Platform.facebook,
              )}
              selectText={t('Recommendations.campaign.selectPubAccount')}
            />
            <div className="mt-4">
              <PlatformSelectMenu
                handleClick={(_, id) => onIntegrationClicked(id)}
                removeInteg={(e, id) => onIntegrationRemoved(e, id)}
                integrations={partnerIntegrations.items.filter(
                  (x) => x.asset_class === AssetClass.profile && x.platform === Platform.instagram,
                )}
                subHeadlineText={'Instagram'}
                selectedIntegration={selectedIntegrations.items.find(
                  (x) => x.asset_class === AssetClass.profile && x.platform === Platform.instagram,
                )}
                selectText={t('Recommendations.campaign.selectPubAccount')}
              />
            </div>
          </div>

          <div className="select-ad-platform_section">
            <h5 className="select-ad-platform_section_title">
              {t('Recommendations.socialAdvertAccountTitle')}
              <span className="warning-red">*</span>
            </h5>
            <PlatformSelectMenu
              handleClick={(_, id) => onIntegrationClicked(id)}
              removeInteg={(e, id) => onIntegrationRemoved(e, id)}
              integrations={partnerIntegrations.items.filter((x) => x.asset_class === AssetClass.ad_account)}
              subHeadlineText={null}
              required
              selectedIntegration={selectedIntegrations.items.find((x) => x.asset_class === AssetClass.ad_account)}
              selectText={t('Recommendations.campaign.selectAdAccount')}
              disabled={isDisabled}
            />
          </div>

          <div className="select-ad-platform_section">
            <h5 className="select-ad-platform_section_title">Pixel</h5>
            <PlatformSelectMenu
              handleClick={(_, id) => onIntegrationClicked(id)}
              removeInteg={(e, id) => onIntegrationRemoved(e, id)}
              integrations={partnerIntegrations.items.filter((x) => x.asset_class === AssetClass.pixel)}
              subHeadlineText={null}
              selectedIntegration={selectedIntegrations.items.find((x) => x.asset_class === AssetClass.pixel)}
              selectText={t('Recommendations.campaign.selectPixelAccount')}
              required={false}
              disabled={isDisabled}
            />

            {isPixel && (
              <div className="mt-3">
                <InfoBox content="Tips.Recommendations.ConversionGoal">
                  <label className="select-ad-platform_select-menu_select-label d-inline-block mb-3">
                    {t('Tips.Recommendations.ConversionGoal.title')}
                  </label>
                </InfoBox>
                <SearchDropdown
                  defaultValue={t(`Recommendations.campaign.conversionGoals.${eventGoal}`)}
                  placeholder={t('Recommendations.campaign.searchConversionGoals')}
                  options={conversionGoals.items}
                  value={conversionGoals.items.find((el) => el.id === defaultConversionId)}
                  onChange={(item) => onConversionGoalChanged(item?.id)}
                  removeSelectedItem={() => onConversionGoalChanged(null)}
                  onErrorBtnClick={retrieveConversionGoals}
                  error={conversionGoals.error ? getErrorText(conversionGoals.message) : null}
                  loading={conversionGoals.loading}
                  disabled={isDisabled}
                />
              </div>
            )}
          </div>
        </form>
      )}
    </div>
  )
}

SelectAdPlatformForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  defaultIntegrations: PropTypes.array,
  eventGoal: PropTypes.string,
  defaultConversionId: PropTypes.string,
  onConversionGoalChanged: PropTypes.func,
  previousIntegrations: PropTypes.array,
}

export default SelectAdPlatformForm

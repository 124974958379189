import React, { useState, useEffect, useReducer } from 'react'
import PropTypes, { object } from 'prop-types'

import { fetchEvents, fetchCREvent } from '../../../api/events'

import SearchEventList from './SearchEventList'
import SelectLineupsSetlists from './SelectLineupsSetlists'

import { initStateObject, objectReducer, initStateArray, arrayReducer } from '../../../reducers/default'

const Content = ({
  currentLineups,
  setLineups,
  currentSetlists,
  setSetlists,
  eventTitle,
  closeModalAndShowNotification,
  closeModal,
}) => {
  const [crEventId, setCrEventId] = useState(false)
  const [crEvent, crEventDispatch] = useReducer(objectReducer, initStateObject)
  const [filteredEvents, filteredEventsDispatch] = useReducer(arrayReducer, initStateArray)
  const [events, eventsDispatch] = useReducer(arrayReducer, initStateArray)

  const [queryWord, setQueryWord] = useState(eventTitle)

  const [screenView, setScreenView] = useState('search')

  const handleSelectCopyEvent = (eventId) => {
    setCrEventId(eventId)
    setScreenView('copy')
  }
  const handleBackClick = () => {
    setCrEventId(null)
    setScreenView('search')
  }

  useEffect(() => {
    if (queryWord) {
      fetchEvents(filteredEventsDispatch, {
        search: queryWord,
      })
    }
    // else {
    //     fetchEvents(filteredEventsDispatch, {
    //         search: ''
    //     })
    // }
  }, [queryWord])

  useEffect(() => {
    fetchEvents(eventsDispatch)
  }, [])

  useEffect(() => {
    if (crEventId) {
      fetchCREvent(crEventDispatch, crEventId)
    }
  }, [crEventId])

  return (
    <div className="event-editor_modal-content_wrapper">
      {screenView === 'search' ? (
        <SearchEventList
          search={queryWord}
          handleKeywordChange={setQueryWord}
          filteredEvents={filteredEvents}
          allEvents={events}
          handleSelectCopyEvent={handleSelectCopyEvent}
          closeModal={closeModal}
        />
      ) : screenView === 'copy' && crEvent && crEvent.message ? (
        <SelectLineupsSetlists
          closeModalAndShowNotification={closeModalAndShowNotification}
          onClickBack={handleBackClick}
          // the event that will be copied to
          currentLineups={currentLineups}
          currentSetlists={currentSetlists}
          setLineups={setLineups}
          setSetlists={setSetlists}
          // the event that will be copied from
          crEventSetlists={crEvent.message && crEvent.message.setlists_raw}
          crEventLineups={crEvent.message.lineups_raw}
          crEvent={crEvent.message}
          closeModal={closeModal}
        />
      ) : null}
    </div>
  )
}

Content.propTypes = {
  currentLineups: PropTypes.arrayOf(object),
  setLineups: PropTypes.func,
  currentSetlists: PropTypes.arrayOf(object),
  setSetlists: PropTypes.func,
  eventTitle: PropTypes.string,
  closeModalAndShowNotification: PropTypes.func,
  closeModal: PropTypes.func,
}

export default Content

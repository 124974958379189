const badRequestErrors = Object.freeze([
  'error.connect.facebook.ad_account_not_found',
  'error.connect.facebook.api_error',
  'error.connect.facebook.invalid_token',
  'error.connect.facebook.rate_limit',
  'error.connect.facebook.graph_api_error',
])

const permissionErrors = Object.freeze(['error.connect.facebook.missing_permissions'])

export const getErrorText = (error) => {
  if (error && badRequestErrors.some((x) => x === error)) return 'Recommendations.campaign.errors.metaIssues'
  if (error && permissionErrors.some((x) => x === error)) return 'Recommendations.campaign.errors.permissions'
}

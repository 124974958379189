import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import LoadingSpinner, { SpinnerSize } from './LoadingSpinner'
import greenCheck from '../../images/icons/green-check.svg'

/**
 * Textarea input.
 * @Tags( shared, presentational )
 */
const Textarea = ({
  id = null,
  name,
  value,
  required = false,
  onChange,
  placeholder = '',
  wrapperClass = '',
  inputClass = '',
  maxLength,
  maxLengthClassNamesFn = () => '',
  showMax,
  showPositiveFeedback = false,
  children,
  loading = false,
  showLoadingSpinner = false,
  inputRef = null,
  cols = null,
  rows = null,
  classNames = null,
}) => {
  const handleChange = (e) => {
    onChange(e)
  }

  const contentLength = value?.length ?? 0

  const wrapperClassNames = cn(classNames, wrapperClass, 'form-group', 'input-cell', 'input-cell--textarea', {
    'input-cell--showMax': showMax,
    'input-cell--showSpinner': showLoadingSpinner,
  })

  const inputClassNames = cn('form-control', inputClass)

  const maxLengthClassNames = cn('input-cell_info textarea', {
    'input-cell__max--warning': maxLength - contentLength < 6,
    [`input-cell__max--${maxLengthClassNamesFn(contentLength)}`]: !!maxLengthClassNamesFn,
  })

  return (
    <div className={wrapperClassNames}>
      <textarea
        id={id}
        data-testid={id}
        className={inputClassNames}
        type="text"
        value={value}
        name={name ? name : ''}
        placeholder={placeholder}
        required={required}
        onChange={handleChange}
        maxLength={maxLength ? maxLength : 250}
        ref={inputRef}
        cols={cols}
        rows={rows}
      />

      <div className={maxLengthClassNames}>
        {(!loading || !showLoadingSpinner) && showMax && (
          <span>
            {contentLength}/{maxLength}
          </span>
        )}
        {showPositiveFeedback && (
          <img src={greenCheck} alt="valid" className={cn({ 'input-cell_info__valid': true, visible: !!value })} />
        )}
      </div>

      {loading && showLoadingSpinner && (
        <LoadingSpinner className="input-cell__spinner" showText={false} size={SpinnerSize.small} />
      )}
      {children}
    </div>
  )
}

Textarea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  maxLengthClassNamesFn: PropTypes.func,
  showMax: PropTypes.bool,
  loading: PropTypes.bool,
  showLoadingSpinner: PropTypes.bool,
  inputClass: PropTypes.string,
  wrapperClass: PropTypes.string,
  showPositiveFeedback: PropTypes.bool,
  required: PropTypes.bool,
  children: PropTypes.node,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLTextAreaElement) }),
  ]),
  rows: PropTypes.number,
  cols: PropTypes.number,
  classNames: PropTypes.string,
}

export default Textarea

import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { OVERWRITE } from 'reducers/default'
import LoadingSpinner, { SpinnerSize } from 'components/Shared/LoadingSpinner'

import { GeoTargetingField } from './GeoTargetingField'
import { useTargeting, useTargetingDispatch } from './TargetingContext'
import { TARGETING_MODES, actions, isLocationTargetingEmpty, validateLocationTargeting } from './targeting'
import { useSetupDispatch } from '../../CampaignSetupProvider'

import greenCheck from 'images/icons/check-green1.svg'

/**
 * Builds the targeting payload based on the setup, targeting mode, source targeting, source payload, and custom audience.
 * It initializes all tcs without targeting or with invalid targeting with the new valid targeting of the source tc.
 *
 * @param {Object} setup - The setup object.
 * @param {string} targetingMode - The targeting mode.
 * @param {Object} srcTcTargeting - The source targeting object.
 * @param {Array} srcPayload - The source payload array.
 * @param {boolean} hasCustomAudience - Indicates whether there is a custom audience.
 * @returns {Array} - The resulting targeting payload.
 */
function buildTargetingPayload(setup, targetingMode, srcTcTargeting, srcPayload, hasCustomAudience) {
  const tcs = setup.creatives.map((x) => ({ tc: x.tc, tc_run_id: x.tc_run_id }))
  const resultPayload = []

  for (const tasteCluster of tcs) {
    if (tasteCluster.tc === srcTcTargeting.tc) {
      resultPayload.push(srcTcTargeting)
      continue
    }

    const { postalCodes: { countries } = {}, rangeLocations: { included, excluded } = {} } = srcTcTargeting
    const currentTcTargeting = srcPayload.find((y) => y.tc === tasteCluster.tc)
    if (!currentTcTargeting ||
      !currentTcTargeting.targetingMode ||
      !validateLocationTargeting(srcPayload, tasteCluster.tc, tasteCluster.tc_run_id, hasCustomAudience).valid ||
      isLocationTargetingEmpty(currentTcTargeting)) {
      const tcPayload = { tc: tasteCluster.tc, tc_run_id: tasteCluster.tc_run_id, targetingMode }
      if (targetingMode === TARGETING_MODES.postalCodes && countries) {
        tcPayload.postalCodes = { countries }
      } else if (targetingMode === TARGETING_MODES.rangeLocations && (included || excluded)) {
        tcPayload.rangeLocations = { included, excluded }
      }

      resultPayload.push(tcPayload)
    } else {
      resultPayload.push(currentTcTargeting)
    }
  }

  return resultPayload
}

export default function Targeting({
  tc,
  tc_run_id,
  setup,
  disabled,
  autoSave,
  onChange,
  showValidationErrors = false,
}) {
  const { t } = useTranslation()

  const setupDispatch = useSetupDispatch()
  const { value: targeting, error, success, loading } = useTargeting()
  const targetingDispatch = useTargetingDispatch()

  const hasCustomAudience = useMemo(() => {
    const tcCreative = setup.creatives.find((x) => x.tc === tc)
    return !!tcCreative?.audience_id
  }, [setup, tc])

  const onChangeInternal = useCallback(
    (tcTargeting) => {
      const { targetingMode, postalCodes, rangeLocations } = tcTargeting
      if (!targetingMode) {
        return
      }

      const { countries } = postalCodes ?? {}
      const { included, excluded } = rangeLocations ?? {}
      const tcPayload = {
        ...targeting.find((x) => x.tc === tc),
        tc,
        tc_run_id,
        targetingMode,
        postalCodes: { countries },
        rangeLocations: { included, excluded },
      }

      const payload = buildTargetingPayload(setup, targetingMode, tcPayload, targeting, hasCustomAudience)


      // if any tc is in the payload other than the incoming tc, we need to overwrite the state
      if (payload.length > 1) {
        targetingDispatch({ type: actions.overwriteTargetingValue, payload })
      }

      setupDispatch({
        type: OVERWRITE,
        payload: {
          ...setup,
          targeting: payload,
        },
      })
      if (autoSave) {
        targetingDispatch({ type: actions.saveAsync, payload: { setup, targeting: payload, callback: onChange } })
      } else {
        onChange(payload)
      }
    },
    [setup, setupDispatch, targetingDispatch, autoSave, tc, tc_run_id, targeting, onChange, hasCustomAudience],
  )

  const showSuccess = useMemo(() => {
    const tcTargeting = targeting.find((x) => x.tc === tc)
    if (!tcTargeting) {
      return false
    }

    return (
      !loading &&
      success &&
      validateLocationTargeting(targeting, tc, tc_run_id, hasCustomAudience).valid &&
      !isLocationTargetingEmpty(tcTargeting) &&
      tcTargeting.targetingMode !== TARGETING_MODES.fdTargeting
    )
  }, [loading, success, targeting, tc, tc_run_id, hasCustomAudience])

  return (
    <>
      <div className="targeting creative-composer-section-wrapper w-100 gap-2">
        <GeoTargetingField
          tc={tc}
          tc_run_id={tc_run_id}
          disabled={disabled}
          onChange={onChangeInternal}
          showValidationErrors={showValidationErrors}
        />
        {showSuccess && <img src={greenCheck} alt="valid" className="targeting-success" />}
        {loading && <LoadingSpinner className="targeting-loading" size={SpinnerSize.SMALL} showText={false} />}
      </div>
      {error && <p style={{ color: '#FA2B37' }}>{t(error)}</p>}
    </>
  )
}

Targeting.propTypes = {
  tc: PropTypes.string.isRequired,
  tc_run_id: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  setup: PropTypes.object,
  disabled: PropTypes.bool,
  autoSave: PropTypes.bool,
  showValidationErrors: PropTypes.bool,
}

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { SecondaryButton } from 'components/Shared/Button'
import { useRangeLocations, useRangeLocationsDispatch } from './RangeLocationsContextProvider'

export default function RangeLocationsActions({ onSave }) {
  const { t } = useTranslation()
  const { locations } = useRangeLocations()
  const dispatch = useRangeLocationsDispatch()

  const clearDisabled = useMemo(() => locations.length === 0, [locations])
  const saveDisabled = useMemo(() => locations.some(x => x.loading), [locations], [locations])

  return (
    <div className="range-locations_actions">
      <SecondaryButton
        text={t('common.clearAll')}
        color="navy"
        onClick={() => dispatch({ type: 'clearAll' })}
        type="button"
        disabled={clearDisabled}
      />
      <SecondaryButton
        text={t('common.save')}
        color="orange"
        disabled={saveDisabled}
        type="button"
        onClick={onSave}
      />
    </div>
  )
}

RangeLocationsActions.propTypes = {
  onSave: PropTypes.func.isRequired,
}

import { eventApi } from 'api/events'
import {
  CampaignOptimizationStatus,
  EventCampaignStatus,
  getEventMarketingCampaignState,
} from 'api/models'
import moment from 'moment'

export const TaskState = Object.freeze({
  progress: 'PROGRESS',
  pending: 'PENDING',
  started: 'STARTED',
  success: 'SUCCESS',
  failure: 'FAILURE',
  retry: 'RETRY',
  revoked: 'REVOKED',
})

export const actions = Object.freeze({
  toggleCampaignStatusAsync: 'TOGGLE_CAMPAIGN_STATUS_ASYNC',
  toggleCampaignOptimizationAsync: 'CHANGE_CAMPAIGN_OPTIMIZATION_ASYNC',
  fetchStatusTaskStateAsync: 'FETCH_STATUS_ASYNC',
  setChangeCampaignStatusTaskState: 'SET_CHANGE_CAMPAIGN_STATUS_TASK_STATE',
  setCampaignOptimizationStatus: 'SET_CAMPAIGN_OPTIMIZATION_CHANGED',
  setError: 'SET_ERROR',
  setLoading: 'SET_LOADING',
  resetMarketingCampaignStatus: 'RESET_MARKETING_CAMPAIGN_STATUS',
})

const _initialState = {
  loading: false,
  error: null,
  isPastEvent: false,
  hasMarketingCampaign: false,
  hasActiveCampaign: false,
  hasEndedCampaign: false,
  isCampaignOptimizationOn: false,
  changeCampaignStatusTaskState: null,
  mappedChangeCampaignStatusTaskState: null,
  success: false,
}

export const initialState = (event) => {
  const { hasMarketingCampaign, hasActiveCampaign, hasEndedCampaign } =
    getEventMarketingCampaignState(event)
  return {
    ..._initialState,
    hasMarketingCampaign,
    hasActiveCampaign,
    hasEndedCampaign,
    isCampaignOptimizationOn:
    event?.campaign_optimisation_status !== CampaignOptimizationStatus.paused,
    isPastEvent: moment(event?.date) < moment(),
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.setLoading:
      return { ...state, loading: action.payload }
    case actions.setChangeCampaignStatusTaskState: {
      const changeCampaignStatusTaskState = action.payload
      const mappedState = mapTaskState(changeCampaignStatusTaskState)
      return {
        ...state,
        changeCampaignStatusTaskState,
        mappedChangeCampaignStatusTaskState: mappedState,
        success: true,
        loading: false,
      }
    }
    case actions.setError:
      return { ...state, error: action.payload, loading: false }
    case actions.setCampaignOptimizationStatus:
      return { ...state, isCampaignOptimizationOn: !state.isCampaignOptimizationOn }
    case actions.resetMarketingCampaignStatus: {
      const { event } = action.payload
      const { hasMarketingCampaign, hasActiveCampaign, hasEndedCampaign } = getEventMarketingCampaignState(event)
      return  {
        ...state,
        hasMarketingCampaign,
        hasActiveCampaign,
        hasEndedCampaign,
      }
    }
    default:
      return state
  }
}

export const asyncActionHandlers = {
  [actions.fetchStatusTaskStateAsync]:
    ({ dispatch }) =>
      async (action) => {
        dispatch({ type: actions.setLoading, payload: true })
        const { eventId } = action.payload
        await fetchStatusTaskStateAsync(eventId, dispatch)
      },
  [actions.toggleCampaignStatusAsync]:
    ({ dispatch }) =>
      async (action) => {
        dispatch({ type: actions.setLoading, payload: true })
        const {
          event: { id, campaign_status },
        } = action.payload
        await updateCampaignStatusAsync(id, campaign_status, dispatch)
        await fetchStatusTaskStateAsync(id, dispatch)
      },
  [actions.toggleCampaignOptimizationAsync]:
    ({ dispatch }) =>
      async (action) => {
        dispatch({ type: actions.setLoading, payload: true })
        const {
          event: { id, campaign_optimisation_status },
        } = action.payload
        const newStatus =
        campaign_optimisation_status === CampaignOptimizationStatus.paused
          ? CampaignOptimizationStatus.active
          : CampaignOptimizationStatus.paused

        const { error, status } = await eventApi.changeCampaignOptimizationStatus(id, newStatus)
        // eslint-disable-next-line eqeqeq
        if (error || status != 204) {
          dispatch({ type: actions.setError, payload: error ?? 'common.errors.inServerResponse' })
          return
        }

        dispatch({
          type: actions.setCampaignOptimizationStatus,
          payload: { isCampaignOptimizationOn: newStatus === CampaignOptimizationStatus.active },
        })
      },
}

export const mapTaskState = (taskStatus) => {
  if (!taskStatus) return 'not_found'
  if ([TaskState.revoked, TaskState.failure, TaskState.retry].includes(taskStatus.status))
    return 'failed'
  if ([TaskState.pending, TaskState.progress, TaskState.started].includes(taskStatus.status))
    return 'running'

  return taskStatus.status // 'success'
}

export const isChangeCampaignStatusRunning = (state) =>
  state.mappedChangeCampaignStatusTaskState === 'running'

async function fetchStatusTaskStateAsync(eventId, dispatch) {
  const { data, error, status } = await eventApi.getCampaignStatusTaskState(eventId)
  // eslint-disable-next-line eqeqeq
  if (status == 404) {
    dispatch({
      type: actions.setChangeCampaignStatusTaskState,
      payload: { status: 'not_found' },
    })
    return
  }

  // eslint-disable-next-line eqeqeq
  if (error || status != 200) {
    dispatch({ type: actions.setError, payload: error ?? 'common.errors.inServerResponse' })
    return
  }

  dispatch({ type: actions.setChangeCampaignStatusTaskState, payload: { status: data.state } })
}

async function updateCampaignStatusAsync(eventId, campaignStatus, dispatch) {
  const newStatus =
    campaignStatus === EventCampaignStatus.active
      ? EventCampaignStatus.paused
      : EventCampaignStatus.active

  const { error, status } = await eventApi.changeClientCampaignStatus(eventId, newStatus)
  // eslint-disable-next-line eqeqeq
  if (error || status != 204) {
    dispatch({ type: actions.setError, payload: error ?? 'common.errors.inServerResponse' })
    return
  }

  dispatch({
    type: actions.setChangeCampaignStatusTaskState,
    payload: { status: newStatus },
  })
}

import { buildSetupPayload } from 'api/models'
import setupProcessApi from 'api/setupProcesses'
import { sortBy } from 'lodash'

import store from '../../../../../../store'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'
import { isObjectEmpty } from 'utils/helpers'

export const TARGETING_MODES = {
  fdTargeting: 'fd_targeting',
  postalCodes: 'postal_codes',
  rangeLocations: 'range_locations',
}

export const actions = {
  setLocationTargetingMode: 'setLocationTargetingMode',
  setLocationPostalCodes: 'setLocationPostalCodes',
  setRangeLocations: 'setRangeLocations',
  overwriteTargetingValue: 'overwriteTargeting',
  resetLocation: 'resetLocation',
  setSuccess: 'setSuccess',
  setError: 'setError',
  setLoading: 'setLoading',
  reset: 'reset',

  saveAsync: 'saveAsync',
}

const _initialState = {
  value: [],
  loading: false,
  error: null,
  success: null,
}

export const initialState = (targeting = []) => {
  return {
    ..._initialState,
    value: [...targeting.filter((x) => !!x.tc)],
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.overwriteTargetingValue:
      return { ...state, value: action.payload }
    case actions.setLocationTargetingMode: {
      const { tc, tc_run_id, targetingMode } = action.payload
      console.trace('setting_mode', targetingMode)
      const tcTargetingValue = state.value.find(
        (targeting) => targeting.tc === tc && targeting.tc_run_id === tc_run_id,
      ) || { tc, tc_run_id }
      return {
        ...state,
        value: [
          ...state.value.filter((targeting) => targeting.tc !== tc),
          { ...tcTargetingValue, ...tcTargetingValue.location, targetingMode },
        ],
      }
    }
    case actions.setLocationPostalCodes: {
      const { tc, tc_run_id, postalCodes } = action.payload
      const tcTargetingValue = state.value.find(
        (targeting) => targeting.tc === tc && targeting.tc_run_id === tc_run_id,
      ) || { tc, tc_run_id }
      return {
        ...state,
        value: [
          ...state.value.filter((targeting) => targeting.tc !== tc),
          { ...tcTargetingValue, ...tcTargetingValue.location, postalCodes },
        ],
      }
    }
    case actions.setRangeLocations: {
      const { tc, tc_run_id, rangeLocations } = action.payload
      const tcTargetingValue = state.value.find(
        (targeting) => targeting.tc === tc && targeting.tc_run_id === tc_run_id,
      ) || { tc, tc_run_id }
      return {
        ...state,
        value: [
          ...state.value.filter((targeting) => targeting.tc !== tc),
          { ...tcTargetingValue, ...tcTargetingValue.location, rangeLocations },
        ],
      }
    }
    case actions.resetLocation: {
      const { tc, tc_run_id } = action.payload
      const tcTargetingValue = state.value.find(
        (targeting) => targeting.tc === tc && targeting.tc_run_id === tc_run_id,
      ) || { tc, tc_run_id }
      return {
        ...state,
        value: [...state.value.filter((targeting) => targeting.tc !== tc), { ...tcTargetingValue }],
      }
    }
    case actions.setSuccess:
      return { ...state, success: true, error: null, loading: false }
    case actions.setError:
      return { ...state, error: action.payload, success: null, loading: false }
    case actions.setLoading:
      return { ...state, loading: true }
    case actions.reset:
      return { ..._initialState }
    default:
      return state
  }
}

export const asyncActionHandlers = {
  [actions.saveAsync]:
    ({ dispatch }) =>
      async (action) => {
        const { setup, targeting, callback = () => {} } = action.payload
        dispatch({ type: actions.setLoading })
        try {
          const payload = buildSetupPayload({
            ...setup,
            targeting,
          })

          const { error, status } = await setupProcessApi.updateAsync(setup.eid, payload)

          if (!error && status === 200) {
            dispatch({ type: actions.setSuccess })
            callback(targeting)
          } else {
            console.error('Failed to save targeting', error)
            dispatch({
              type: actions.setError,
              payload: 'common.errors.inServerResponse',
            })
          }
        } catch (error) {
          console.error(error)
          dispatch({ type: actions.setError, payload: error.message })
        }
      },
}

export const getLocationTargetingModeOptions = (hasPrismaAccess, hasCustomAudience) => {
  let options = []
  if (hasPrismaAccess) {
    options = [
      { value: TARGETING_MODES.postalCodes, text: 'Recommendations.geoTargeting.options.postal_codes', order: 0 },
      { value: TARGETING_MODES.rangeLocations, text: 'Recommendations.geoTargeting.options.range_locations', order: 1 },
    ]
  } else {
    options = [
      { value: TARGETING_MODES.fdTargeting, text: 'Recommendations.geoTargeting.options.fd_targeting', order: 0 },
      { value: TARGETING_MODES.postalCodes, text: 'Recommendations.geoTargeting.options.postal_codes', order: 1 },
      { value: TARGETING_MODES.rangeLocations, text: 'Recommendations.geoTargeting.options.range_locations', order: 2 },
    ]
  }

  if (hasCustomAudience) {
    options = options.filter((x) => x.value !== TARGETING_MODES.fdTargeting)
  }

  return sortBy(options, 'order')
}

export const getDefaultTargetingMode = (hasPrismaAccess, hasCustomAudience) => {
  return hasPrismaAccess || hasCustomAudience ? TARGETING_MODES.postalCodes : TARGETING_MODES.fdTargeting
}

export function isLocationTargetingEmpty(tcTargeting) {
  if (!tcTargeting) return false

  const { targetingMode, postalCodes, rangeLocations } = tcTargeting
  if (targetingMode === TARGETING_MODES.fdTargeting) return false

  if (targetingMode === TARGETING_MODES.postalCodes && isObjectEmpty(postalCodes?.countries)) return true

  if (targetingMode === TARGETING_MODES.rangeLocations && !(rangeLocations?.included || rangeLocations?.excluded))
    return true

  return false
}

export function validateLocationTargeting(targeting, tc, tc_run_id, hasCustomAudience) {
  const hasPrismaAccess = hasPrismaAccessTier(store.getState().user?.accessLevel)
  const tcTargeting = targeting?.find((x) => x.tc === tc && x.tc_run_id === tc_run_id)

  if (!tcTargeting && hasPrismaAccess)
    return {
      valid: false,
      code: 'NO_TC_TARGETING',
    }

  const { targetingMode, postalCodes, rangeLocations } = tcTargeting || {}

  if (
    [TARGETING_MODES.fdTargeting, TARGETING_MODES.postalCodes, TARGETING_MODES.rangeLocations].includes(
      targetingMode,
    ) &&
    hasCustomAudience
  ) {
    return { valid: true }
  }

  if (!targetingMode && hasPrismaAccess)
    return {
      valid: false,
      code: 'NO_TARGETING_MODE',
    }

  if (targetingMode === TARGETING_MODES.fdTargeting) return { valid: true }

  if (targetingMode === TARGETING_MODES.postalCodes && postalCodes && postalCodes.countries) {
    return {
      valid: Object.values(postalCodes.countries).some((x) => x.length > 0),
      code: 'NO_POSTAL_CODES',
    }
  }

  if (targetingMode === TARGETING_MODES.rangeLocations && rangeLocations) {
    return {
      valid: rangeLocations.included?.length > 0 || rangeLocations.excluded?.length > 0,
      code: 'NO_RANGE_LOCATIONS',
    }
  }

  return {
    valid: false,
    code: 'UNKNOWN',
  }
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import CopyModalContent from './CopyModalContent'

import { SecondaryButton, TransparentBgButton } from '../../Shared/Button'
import { useTranslation } from 'react-i18next'

import PortalModal from '../../Shared/PortalModal'
import { CancelIcon } from 'images'
import { formatEventSubheadlineLocation } from 'utils/helpers'
import LoadingSpinner from 'components/Shared/LoadingSpinner'

const CheckRow = ({ title, changeHandler, value, checked }) => {
  return (
    <div className="event-editor_select-lineups-setlists_checkbox_row">
      <label className="event-editor_select-lineups-setlists_custom-checkbox">
        <input onChange={() => changeHandler(value, checked)} type="checkbox" checked={checked} />
        <span className="label">{title}</span>
      </label>
    </div>
  )
}

CheckRow.propTypes = {
  title: PropTypes.string,
  changeHandler: PropTypes.func,
  value: PropTypes.object,
  checked: PropTypes.bool,
}

const SelectLineupsSetlists = ({
  onClickBack,
  crEventLineups = [],
  currentLineups,
  setLineups,
  crEventSetlists = [],
  currentSetlists,
  setSetlists,
  crEvent,
  closeModalAndShowNotification,
  closeModal,
}) => {
  const { t } = useTranslation()
  const [lineupsSelection, setLineupsSelection] = useState([
    ...crEventLineups.map(({ client_role_mapped: role, artist_name: name }) => {
      const value = {
        artist_type: role ?? '',
        artist_name: name ?? '',
      }
      return value
    }),
  ])
  const [setlistsSelection, setSetlistsSelection] = useState([
    ...crEventSetlists.map(({ title, composer, track, artist_name }) => {
      const value = {
        track: title ? title : track ? track : '',
        artist_name: composer ? composer : artist_name ? artist_name : '',
      }
      return value
    }),
  ])
  const [showCopyModal, setShowCopyModal] = useState(false)

  const handleLineupsSelect = (newLineup, checked) => {
    if (checked) {
      setLineupsSelection(
        lineupsSelection.filter((lineup) => {
          if (lineup.artist_name === newLineup.artist_name && lineup.artist_type === newLineup.artist_type) {
            return false
          } else {
            return true
          }
        }),
      )
    } else {
      setLineupsSelection([...lineupsSelection, newLineup])
    }
  }
  const handleSetlistSelect = (newSetlist, checked) => {
    if (checked) {
      setSetlistsSelection(
        setlistsSelection.filter((setlist) => {
          if (setlist.artist_name === newSetlist.artist_name && setlist.track === newSetlist.track) {
            return false
          } else {
            return true
          }
        }),
      )
    } else {
      setSetlistsSelection([...setlistsSelection, newSetlist])
    }
  }

  const handleSelectAndDeselectAll = () => {
    const allSelected =
      crEventLineups.length === lineupsSelection.length && crEventSetlists.length === setlistsSelection.length

    if (!allSelected) {
      const setlistsFormatted = [
        ...crEventSetlists.map(({ title, composer, track, artist_name }) => {
          const value = {
            track: title ? title : track ? track : '',
            artist_name: composer ? composer : artist_name ? artist_name : '',
          }
          return value
        }),
      ]
      const lineupsFormatted = [
        ...crEventLineups.map(({ artist_type: type, artist_name: name }) => {
          const value = {
            artist_type: type ?? '',
            artist_name: name ?? '',
          }
          return value
        }),
      ]

      setSetlistsSelection(setlistsFormatted)
      setLineupsSelection(lineupsFormatted)
    } else {
      setSetlistsSelection([])
      setLineupsSelection([])
    }
  }

  const resetAndGoBack = () => {
    setSetlistsSelection([])
    setLineupsSelection([])
    onClickBack()
  }

  if (crEvent.loading) return <LoadingSpinner className="mt-4 p-3" />

  return (
    <div className="event-editor_select-lineups-setlists_wrapper">
      <div className="d-flex justify-content-between gap-4">
        <h3 className="event-editor_search-view_title m-0">{t('EventEditor.selectArtists')}</h3>
        <button type="button" onClick={() => closeModal(false)} className="event-editor_modal-content_btn_wrapper">
          <CancelIcon width={16} />
        </button>
      </div>
      <article>
        <div className="event-editor_select-lineups-setlists_section top">
          <label className="event-editor_select-lineups-setlists_custom-checkbox">
            <input
              onChange={() => handleSelectAndDeselectAll()}
              type="checkbox"
              checked={
                crEventLineups.length === lineupsSelection.length && crEventSetlists.length === setlistsSelection.length
              }
            />
            <span></span>
          </label>
          <span>
            <h5 className="event-editor_search-view_title">{crEvent.title}</h5>
            <div className="event-editor_event-row_title-and-date_date_wrapper">
              <div className="event-editor_event-row_title-and-date_date_location navy">
                <i className="icon-location" style={{ marginRight: 4 }} />
                {formatEventSubheadlineLocation({
                  city: crEvent.city,
                  venue: crEvent.venue,
                  hall: crEvent.hall,
                })}
              </div>
              <time
                className="event-editor_event-row_title-and-date_date_time navy"
                dateTime={`${crEvent.date} ${crEvent.time}`}
              >
                <i className="icon-clock" style={{ marginRight: 4 }} />
                {crEvent.date} {crEvent.start}
              </time>
            </div>
          </span>
        </div>
        <div className="event-editor_select-lineups-setlists_section bottom">
          <h5 className="event-editor_search-view_title fs-16">Artist</h5>

          {crEventLineups &&
            crEventLineups.length > 0 &&
            crEventLineups.map(({ artist_type: type, artist_name: name }) => {
              const value = {
                artist_type: type ?? '',
                artist_name: name ?? '',
              }

              return (
                <CheckRow
                  key={`${name ?? ''} - ${type ?? ''}`}
                  title={`${name ?? ''} - ${type ?? ''}`}
                  value={value}
                  changeHandler={handleLineupsSelect}
                  checked={lineupsSelection.some(
                    (lineup) => lineup.artist_type === value.artist_type && lineup.artist_name === value.artist_name,
                  )}
                />
              )
            })}

          <hr className="event-editor_select-lineups-setlists_divider" />

          <h5 className="event-editor_search-view_title fs-16 mt-2">Program</h5>
          {crEventSetlists &&
            crEventSetlists.length > 0 &&
            crEventSetlists.map(({ title, composer, track, artist_name }) => {
              const value = {
                track: title ? title : track ? track : '',
                artist_name: composer ? composer : artist_name ? artist_name : '',
              }
              return (
                <CheckRow
                  key={`${value.artist_name} - ${value.track}`}
                  title={`${value.artist_name} - ${value.track}`}
                  value={value}
                  changeHandler={handleSetlistSelect}
                  checked={setlistsSelection.some(
                    (setlist) => setlist.track === value.track && setlist.artist_name === value.artist_name,
                  )}
                />
              )
            })}
        </div>

        <div className="event-editor_select-lineups-setlists_button-section">
          <TransparentBgButton color="navy" text={t('common.back')} onClick={resetAndGoBack} />
          <SecondaryButton
            onClick={() => setShowCopyModal(true)}
            text={t('common.copy')}
            showIcon={false}
            disabled={lineupsSelection.length === 0 && setlistsSelection.length === 0}
            fullWidth={false}
          />
        </div>
      </article>

      {showCopyModal && (
        <PortalModal
          wrapperClassName="event-editor_copy-dialog"
          className="event-editor_copy-dialog_content"
          isOpen={showCopyModal}
          onClickOutside={() => setShowCopyModal(false)}
        >
          <div className="position-relative p-4">
            <CopyModalContent
              setSetlists={setSetlists}
              setLineups={setLineups}
              currentSetlists={currentSetlists}
              currentLineups={currentLineups}
              closeModalAndShowNotification={closeModalAndShowNotification}
              newLineups={lineupsSelection}
              newSetlists={setlistsSelection}
            />
          </div>
        </PortalModal>
      )}
    </div>
  )
}

SelectLineupsSetlists.propTypes = {
  onClickBack: PropTypes.func,
  crEventLineups: PropTypes.array,
  currentLineups: PropTypes.array,
  setLineups: PropTypes.func,
  crEventSetlists: PropTypes.array,
  currentSetlists: PropTypes.array,
  setSetlists: PropTypes.func,
  crEvent: PropTypes.object,
  closeModalAndShowNotification: PropTypes.func,
  closeModal: PropTypes.func,
}

export default SelectLineupsSetlists

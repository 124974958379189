import PropTypes from 'prop-types'
import React, { useEffect, useState, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import LoadingSpinner from '../../Shared/LoadingSpinner'
import ErrorMessage from '../../Shared/ErrorMessage'

import { initStateArray, arrayReducer } from '../../../reducers/default'
import { fetchTicketCategoriesPlot } from '../../../api/charts'
import LineChart from '../../Shared/LineChart'
import InfoBox from '../../Shared/InfoBox'

const TICKET_CATEGORY_COLORS = [
  '#5A84A0',
  '#1F5274',
  '#5FA2CE',
  '#94BCD7',
  '#2b6b94',
  '#162d3c',
  '#47728e',
  '#5A84A0',
  '#1F5274',
  '#5FA2CE',
  '#94BCD7',
  '#2b6b94',
  '#162d3c',
  '#47728e',
  '#5A84A0',
  '#1F5274',
  '#5FA2CE',
  '#94BCD7',
  '#2b6b94',
  '#162d3c',
  '#47728e',
]

/**
 * Events tickets categories pie chart.
 * @Tags( event, api )
 * @Endpoints( /events/[eid]/tickets )
 * @NpmPackages( highcharts )
 * @ApiLogic( ../../api/charts )
 */
export default function TicketCategories({ eventId, title, setShowCategories = () => {} }) {
  const { t } = useTranslation()
  var [series, dispatch] = useReducer(arrayReducer, initStateArray)
  const [formattedData, setFormattedData] = useState([])

  useEffect(() => {
    fetchTicketCategoriesPlot(dispatch, eventId)
  }, [eventId])

  useEffect(() => {
    if (!series.success) return

    setFormattedData(
      series.items.map((item, index) => {
        const sortbgColor =
          index >= TICKET_CATEGORY_COLORS.length
            ? TICKET_CATEGORY_COLORS[Math.floor(Math.random() * 6)]
            : TICKET_CATEGORY_COLORS[index]

        return {
          bgColor: sortbgColor,
          ...item,
        }
      }),
    )
    setShowCategories(!!series.items.length)
  }, [series, setShowCategories])

  if (series.success && !series.items.length) return null

  return (
    <div
      className={`card card-ticket-type events-insights-page_dash_small_card  ${series.items.length > 0 ? '' : 'no-results'}`}
    >
      <div>
        <InfoBox content="Tips.Events.TicketCategories">
          <h2 className="card-title-default d-inline-block m-0">{title}</h2>
        </InfoBox>
      </div>
      {series.loading && (
        <div className="card-body">
          <LoadingSpinner />
        </div>
      )}
      {series.error && (
        <div className="card-body">
          <ErrorMessage danger>{t(series.message)}</ErrorMessage>
        </div>
      )}
      <div>
        {!series.loading && formattedData && formattedData.length > 0 ? (
          <LineChart data={formattedData} order="desc" />
        ) : null}
      </div>
    </div>
  )
}

TicketCategories.propTypes = {
  title: PropTypes.string.isRequired,
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setShowCategories: PropTypes.func,
}

import React from 'react'
import PropTypes, { string } from 'prop-types'

import Topic from '../../Shared/Topic'

import { useTranslation } from 'react-i18next'

const EventRow = ({ title }) => {
  return (
    <div className="package-builder-page_variations-section_card_events_row">
      <p>{title}</p>
    </div>
  )
}

EventRow.propTypes = {
  title: PropTypes.string,
}

export const Topics = ({ keywords, keyPrefix, limit = 4 }) => {
  return (
    <ul className="mt-2">
      {keywords.slice(0, limit).map((keyword, index) => {
        // catches bad keywords
        const trimmedKeyword = keyword.trim()
        if (trimmedKeyword === '' || !trimmedKeyword) return null

        return <Topic key={`${keyPrefix}-${keyword}-${index}`} keyword={keyword} index={index} charLimit={30} />
      })}
    </ul>
  )
}

Topics.propTypes = {
  keywords: PropTypes.arrayOf(string),
  keyPrefix: PropTypes.string,
  limit: PropTypes.number,
}

const VariationsCard = ({ result, setActiveContent, setDrawerOpen, cypressId }) => {
  const { t } = useTranslation()

  return (
    <div
      data-cy={cypressId}
      className="package-builder-page_variations-section_card_container"
      onClick={() => {
        setActiveContent(result)
        setDrawerOpen(true, result.title.toLowerCase().replaceAll(' ', '-'), result)
      }}
    >
      <div className="package-builder-page_variations-section_card_title-row">
        <h3 className="package-builder-page_variations-section_card_title-row_headline">{result.title}</h3>
      </div>
      <div className={'package-builder-page_variations-section_card_topics campaign-form__tips mt-2'}>
        <h6 className="package-builder-page_variations-section_card_events_title">
          {t('PackageBuilder.packageBuilderResults.topics')}
        </h6>
        <Topics keywords={result.campaign.keywords} keyPrefix={result.title} />
      </div>
      {/* _events */}
      <div className="package-builder-page_variations-section_card_events_container">
        <h6 className="package-builder-page_variations-section_card_events_title">
          {t('PackageBuilder.packageBuilderResults.importantEvents')}
        </h6>
        <EventRow title={result.top_event_1.title} rank={1} />
        {result.top_event_2 && result.top_event_2.title ? <EventRow title={result.top_event_2.title} rank={2} /> : null}
        {result.top_event_3 && result.top_event_3.title ? <EventRow title={result.top_event_3.title} rank={3} /> : null}
      </div>
    </div>
  )
}

VariationsCard.propTypes = {
  result: PropTypes.object.isRequired,
  setActiveContent: PropTypes.func,
  setDrawerOpen: PropTypes.func,
  cypressId: PropTypes.string,
}

export default VariationsCard

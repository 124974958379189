import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import TipDialogueBox from '../../Shared/TipDialogueBox'
import { useHistory } from 'react-router-dom'

export default function CapacityPrompt({ eventId }) {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 310,
      }}
    >
      <TipDialogueBox
        title={t('Event.salesTrend.capacityPrompt.title')}
        text={t('Event.salesTrend.capacityPrompt.content')}
        variant="danger"
        showBorder
        compact
        active
        onClick={() => history.replace(`/events/edit/${eventId}`)}
      />
    </div>
  )
}

CapacityPrompt.propTypes = {
  eventId: PropTypes.string.isRequired,
}

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { actions, NotificationContext, NotificationDispatchContext } from './notifications'
import TopicsFilter from './TopicsFilter'

import { SecondaryButton } from 'components/Shared/Button'
import Switch from 'components/Shared/Controls/Switch'

function ActionBar({ children }) {
  const { t } = useTranslation()
  const state = useContext(NotificationContext)
  const dispatch = useContext(NotificationDispatchContext)

  const isEnabled = state.notifications.items.length > 0

  return (
    <div className="action-bar">
      <div className="action-bar_actions">
        {!state.selectionMode.enabled && (
          <SecondaryButton
            onClick={() => dispatch({ type: actions.toggleSelectionMode })}
            text={t('Notifications.actionBar.select')}
            size="small"
            disabled={!isEnabled || state.notifications.items.every((item) => item.is_read)}
          />
        )}

        {state.selectionMode.enabled && (
          <SecondaryButton
            onClick={() => dispatch({ type: actions.selectAll })}
            text={t('Notifications.actionBar.selectAll')}
            size="small"
            disabled={!isEnabled || state.selectionMode.selectedIds.length === state.notifications.items.length}
          />
        )}

        <SecondaryButton
          onClick={() => dispatch({ type: actions.patchNotificationsAsync })}
          text={t('Notifications.actionBar.markAllAsRead')}
          size="small"
          disabled={!isEnabled || state.notifications.items.every((item) => item.is_read)}
        />

        {state.selectionMode.enabled && (
          <SecondaryButton
            onClick={() => dispatch({ type: actions.toggleSelectionMode })}
            text={t('Notifications.actionBar.cancel')}
            size="small"
            color="navy"
            disabled={!isEnabled}
          />
        )}
        {children}
      </div>

      <div className="action-bar_filters">
        <TopicsFilter />
        <Switch
          label={t('Notifications.filters.unreadOnly')}
          checked={state.filters.unreadOnly}
          onClick={() =>
            dispatch({
              type: actions.fetchNotificationsPageAsync,
              payload: {
                filters: {
                  unreadOnly: !state.filters.unreadOnly,
                },
                page: 1,
              },
            })
          }
        />
      </div>
    </div>
  )
}

ActionBar.propTypes = {
  children: PropTypes.node,
}

export default ActionBar

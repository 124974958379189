import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { updateUser } from '../../api/auth'

import { checkIfPasswordIsSecureEnough } from '../../utils/helpers'

const initialState = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
}

/**
 * Registration form connected to Cognito AWS in the Backend.
 * @Tags( cognito, api )
 * @Inputs( name, email, password, confirmPassword, confirmationCode )
 * @Endpoints( /auth/partners/partnerId/users, /auth/partners/users/confirm )
 * @ApiLogic( ../../api/auth )
 */
const UpdateDetailsForm = ({ partnerId }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [state, setState] = useState(initialState)
  const [error, setError] = useState(null)

  const handleChange = (event) => {
    setError(null)
    setState({ ...state, [event.target.id]: event.target.value })
  }

  const validateForm = () => {
    if (
      !(
        state.name.length > 0 &&
        state.email.length > 0 &&
        state.password.length > 0 &&
        state.confirmPassword.length > 0
      )
    ) {
      const { password, confirmPassword, name, email } = state
      if (!name || !email) {
        setError('Missing information for name and/or email')
        setLoading(false)
        return
      }
      if (password !== confirmPassword) {
        setSuccess(false)
        setLoading(false)
        window.alert('passwords do not match')
        return
      }
    }
    return true
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    if (!validateForm()) {
      console.error('Fields missing')
      return
    }

    try {
      const { email, password, name } = state

      if (checkIfPasswordIsSecureEnough(password).status === false) {
        window.alert(`
                Your Password isn't strong enough. Ihr Passwort ist nicht stark genug.

                It must have at least / Es muss mindestens haben: 

                    - One Uppercase letter / Ein Großbuchstabe (ABC)

                    - One Lowercase letter / Ein kleiner Buchstabe (abc)

                    - Numbers / Zahlen (0-9)

                    - Special Character / Sonderzeichen (!, @, £, etc.)

                    - 7 letters in length / Buchstaben lang
                `)
        return
      }
      const createUser = await updateUser({
        email,
        password,
        name,
        partner_id: partnerId,
      })

      if (createUser.success) {
        setSuccess(true)
        window.location.replace('/login')
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const renderForm = () => {
    const { name, email, password, confirmPassword } = state
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            className="form-control s-50"
            id="name"
            placeholder={t('common.name')}
            value={name}
            required
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            className="form-control s-50"
            id="email"
            placeholder={t('common.email')}
            value={email}
            required
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control s-50"
            id="password"
            placeholder={t('common.password')}
            value={password}
            required
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control s-50"
            id="confirmPassword"
            placeholder={t('common.confirmPassword')}
            value={confirmPassword}
            required
            onChange={(e) => handleChange(e)}
          />
        </div>

        {error && <p className="mt-2" style={{ color: '#FA2B37' }}>{error}</p>}

        <button style={{ width: '100%' }} type="submit" className="bt bt-navy s-50 w-100">
          {loading ? 'Updating' : 'Update'}
        </button>
      </form>
    )
  }

  const renderSuccess = () => {
    return (
      <div className="text-center">
        <p data-testid="successForm" className="alert alert-primary text-center">
          {t('UpdateDetails.form.success')}
        </p>
        <Redirect to="/login" className="bt bt-navy s-50 w-100">
          {t('common.logIn')}
        </Redirect>
      </div>
    )
  }

  return (
    <div className="signup-form">
      {!loading.success && renderForm()}
      {loading && success && renderSuccess()}
    </div>
  )
}

UpdateDetailsForm.propTypes = {
  partnerId: PropTypes.string.isRequired,
}

export default UpdateDetailsForm

import React, { useState, useEffect, useContext, useLayoutEffect } from 'react'
import Helmet from 'react-helmet'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import Layout from '../components/Layout'
import { Tab, Nav } from 'react-bootstrap'

import MobileTopBarSelect from '../components/Dashboard/MobileTopBarSelect'
import UpcomingEvents from '../components/Dashboard/UpcomingEvents'
import ActiveCampaigns from '../components/Dashboard/ActiveCampaigns'
import { LayoutContext } from '../App'

/**
 * Default page for logged in users.
 * It displays active campaigns statistics, and list of events (we can filter them by time).
 * @Tags( page, dashboard, layout, logged-users )
 * @SuggestedTags( dashboard )
 * @Routes( / )
 */
export default function Dashboard() {
  const { t } = useTranslation()
  const reduxState = JSON.parse(localStorage.getItem('reduxState'))
  const [filter, setFilter] = useState(moment().add(1, 'year').format('DD-MM-YYYY'))
  const [activeTab, setActiveTab] = useState('list')
  const { sidebarExpanded } = useContext(LayoutContext)
  const [width, setWindowWidth] = useState(0)

  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }

  useLayoutEffect(() => {
    updateDimensions()

    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  useEffect(() => {}, [filter])

  if (width === 0) return null

  return (
    <Layout
      loggedIn
      title={t('Dashboard.PageTitle')}
      mobileTopBar={{ center: <MobileTopBarSelect handleChange={setFilter} /> }}
    >
      <Helmet bodyAttributes={{ class: `dashboard-page ${sidebarExpanded ? 'dashboard-page--compact' : ''}` }} />
      <main data-cy="dashboard-page-cy">
        <div className="container-fluid hide-scroll-bar py-3">
          {width >= 1400 && (
            <div className="row dashboard-columns-view">
              <div className="col-12 col-lg left-col pd-desktop-left">
                <div className="col-intro hide-mobile">
                  <h1 className="main-title hide-mobile">
                    {t('Dashboard.MainTitle')}, {reduxState.user.name}
                  </h1>
                </div>
                <UpcomingEvents filter={filter} />
              </div>
              <div className="col-12 col-lg-auto right-col pd-desktop-right hide-mobile">
                <div className="col-intro col-width-fix"></div>
                <ActiveCampaigns />
              </div>
            </div>
          )}
          {width < 1400 && (
            <div className="dashboard-tab-view">
              <div className="col-intro hide-mobile">
                <h1 className="main-title hide-mobile">
                  {t('Dashboard.MainTitle')}, {reduxState.user.name}
                </h1>
              </div>
              <Nav
                defaultActiveKey={'list'}
                activeKey={activeTab}
                className="card-header nav nav-tabs col-lg pb-1 pt-2"
                style={{ borderRadius: 6 }}
              >
                <Nav.Item onClick={() => setActiveTab('list')}>
                  <Nav.Link style={{ fontWeight: '600' }} eventKey="list">
                    {'Events'}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => setActiveTab('stats')}>
                  <Nav.Link style={{ fontWeight: '600' }} eventKey="stats">
                    {'Campaign stats'}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Container defaultActiveKey={'list'} activeKey={activeTab}>
                <Tab.Content className="mt-4 ">
                  <Tab.Pane eventKey="list">
                    <UpcomingEvents filter={filter} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="stats">
                    <ActiveCampaigns />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          )}
        </div>
      </main>
    </Layout>
  )
}

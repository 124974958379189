import PropTypes from 'prop-types'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { ErrorBoundary } from 'react-error-boundary'
import { ButtonGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import { useReducerAsync } from 'hooks/useReducerAsync'

import { SecondaryButton } from 'components/Shared/Button'
import ErrorFallback from 'components/Shared/ErrorFallback'

import plusIcon from 'images/icons/plus-circle.svg'
import removeIcon from 'images/icons/remove-icon.svg'
import xlsxIcon from 'images/icons/xlsx.svg'

import { getExportedSalesData } from 'api/events'
import { useToast } from 'components/Shared/AppToast/ToastProvider'
import InfoBox, { ControlledInfoBox } from 'components/Shared/InfoBox'
import Modal from 'components/Shared/Modal'
import { ExportIcon, ImportIcon } from 'images'
import { actions, asyncActionHandlers, initialState, reducer } from './manualAdjustments'
import i18n from '../../../i18n'
import { EventFilterContext } from '../Filters'
import { getEventFetchFilters } from 'api/models'
import PortalModal from 'components/Shared/PortalModal'
import FallingDots from 'components/Shared/FallingDots'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'

function SalesDocumentUploadContent({ onClose }) {
  const { t } = useTranslation()
  const [state, dispatch] = useReducerAsync(reducer, initialState, asyncActionHandlers)
  const { addNotification } = useToast()

  useEffect(() => {
    if (state.uploaded) {
      addNotification({
        message: t('Toasts.Sales.manualAdjustments.message'),
        description: t('Toasts.Sales.manualAdjustments.description'),
      })
      onClose()
    }
  }, [state.uploaded, addNotification, t, onClose])

  return (
    <div className="sales-upload p-0">
      <p className="mt-3">{t('Event.salesEdit.manualAdjustments.importSubtitle')}</p>

      <div>
        <Dropzone onDrop={(files) => dispatch({ type: actions.addOrReplaceFile, payload: files[0] })} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <section className="upload-box" style={{ background: 'white' }}>
              <div {...getRootProps()}>
                <input accept=".xlsx, .xls" {...getInputProps()} />
                {state.file ? (
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div className="accepted-file">
                        <div
                          style={{ display: state.loading ? 'none' : 'inline-block' }}
                          className="accepted-file_remove-icon_bg"
                        />
                        <img
                          onClick={(e) => {
                            e.stopPropagation()
                            dispatch({ type: actions.removeFile })
                          }}
                          className="accepted-file_remove-icon"
                          src={removeIcon}
                          alt={t('shared.fileUploader.removeFile')}
                          style={{ display: state.loading ? 'none' : 'inline-block' }}
                        />
                        <img className="accepted-file_icon" src={xlsxIcon} alt={state.file.name || state.file.path} />
                      </div>
                      <p className="mr-2 mt-3">{state.file.name || state.file.path}</p>
                    </div>
                    {state.loading && (
                      <div className="accepted-file_status">
                        <div className="circular-progress d-flex flex-column align-items-center">
                          <CircularProgressbar
                            value={state.file.progress}
                            strokeWidth={10}
                            styles={buildStyles({
                              pathColor: '#818181',
                              trailColor: '#e8e9ee',
                            })}
                          />
                          <span>{state.file.progress}%</span>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="upload-box_add">
                    <img src={plusIcon} alt="" />
                    <span className="add-file">{t('common.add')}</span>
                  </div>
                )}
              </div>
            </section>
          )}
        </Dropzone>
      </div>

      {state.valid === false && (
        <p className="warning-red">
          {state.errors.map((error) => (
            <span key={error} className="d-block">
              {t([
                `Event.salesEdit.manualAdjustments.errors.${error}`,
                'Event.salesEdit.manualAdjustments.errors.FILE_INVALID',
              ])}
            </span>
          ))}
        </p>
      )}
      <SecondaryButton
        classNames="mt-3 ml-auto"
        text={state.loading ? t('common.uploading') : t('common.uploadFile')}
        fullWidth={false}
        onClick={() => dispatch({ type: actions.upload })}
        disabledTitle={t('common.uploadFile')}
        disabled={!state.valid || !state.file || state.loading}
      />
    </div>
  )
}

SalesDocumentUploadContent.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export function SalesDocumentUpload(props) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <SalesDocumentUploadContent {...props} />
    </ErrorBoundary>
  )
}

SalesDocumentUpload.propTypes = { ...SalesDocumentUploadContent.propTypes }

const ExportSalesData = () => {
  const filters = useContext(EventFilterContext)
  const [exportedData, setExportedData] = useState(null)
  const [loading, setLoading] = useState(false)
  const { addNotification } = useToast()
  const { t } = useTranslation()

  const onExport = useCallback(() => {
    const getAndSetExportedData = async () => {
      try {
        setLoading(true)
        const currency = localStorage.getItem('userCurrency') || 'EUR'
        const locale = i18n.language
        const { data, success, error } = await getExportedSalesData(
          getEventFetchFilters(filters, 1), // page is irrelevant
          currency,
          locale,
        )
        if (!success) {
          addNotification({
            message: 'Error',
            description: error,
            variant: 'error',
          })
        } else {
          setExportedData(data)
        }
      } catch (error) {
        console.error(error)
        addNotification({
          message: 'Error',
          description: 'There was an error exporting sales data',
        })
      } finally {
        setLoading(false)
      }
    }

    getAndSetExportedData()
  }, [addNotification, filters])

  useEffect(() => {
    if (!exportedData) return

    // create a blob from the exported data
    const blob = new Blob([exportedData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url

    // download the file
    a.download = 'exported-data.xlsx'
    a.click()
    window.URL.revokeObjectURL(url)
  }, [exportedData])
  return (
    <>
      <InfoBox
        content="Tips.Events.ExportSales"
        withAnchor={false}
        renderContent={(c) => <span className="d-inline-block">{c}</span>}
      >
        <SecondaryButton color="navy" classNames="px-3" disabled={loading} onClick={onExport}>
          <ExportIcon />
        </SecondaryButton>
      </InfoBox>
      <PortalModal isOpen={loading}>
        <div className="content compact pt-0 text-center" style={{ maxWidth: '400px' }}>
          <>
            <FallingDots classNames="manual-adjustments_falling-dots" />
            <div className="text-center">
              <p className="body-txt light larger mb-1">{t('Recommendations.campaign.setup.autofill.loadingTitle')}</p>
              <p className="body-txt light larger mb-2">{t('Recommendations.campaign.setup.autofill.loadingMsg')}</p>
            </div>
          </>
        </div>
      </PortalModal>
    </>
  )
}

const ImportSalesData = ({ onClick }) => {
  // A workaround to prevent the tooltip from showing after the button is clicked
  const [showInfoBox, setShowInfoBox] = useState(false)
  return (
    <>
      <ControlledInfoBox
        content="Tips.Events.ImportSales"
        withAnchor={false}
        renderContent={(c) => <span className="d-inline-block">{c}</span>}
        show={showInfoBox}
      >
        <SecondaryButton
          color="navy"
          fullWidth={false}
          classNames="px-3"
          onClick={onClick}
          onHover={(hovering) => setShowInfoBox(hovering)}
        >
          <ImportIcon />
        </SecondaryButton>
      </ControlledInfoBox>
    </>
  )
}

ImportSalesData.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const UploadModal = ({ open, onClose }) => {
  const { t } = useTranslation()

  if (!open) {
    return null
  }

  return (
    <Modal
      title={t('Event.salesEdit.manualAdjustments.title')}
      fullWidth
      offWhiteBg
      contentClasses="w-md-60"
      closeCallback={() => onClose()}
      onClickOutside={() => onClose()}
      mainContent={<SalesDocumentUpload onClose={onClose} />}
    />
  )
}

UploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default function ManualAdjustments() {
  const [salesUploadOpen, setSalesUploadOpen] = useState(false)
  const hasPrismaAccess = hasPrismaAccessTier()

  if (hasPrismaAccess) {
    return null
  }

  return (
    <div className="manual-adjustments">
      <ButtonGroup>
        <ExportSalesData />
        <ImportSalesData
          onClick={() => {
            setSalesUploadOpen(true)
            window.document.body.click()
          }}
        />
      </ButtonGroup>
      <UploadModal open={salesUploadOpen} onClose={() => setSalesUploadOpen(false)} />
    </div>
  )
}

import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'

/**
 * Error message.
 * @Tags( shared, presentational )
 */
const ErrorMessage = ({ children, danger, classNames = undefined }) => {
  return (
    <div
      className={classnames({
        'error-message alert': true,
        [`alert-${danger ? 'danger' : 'warning'}`]: true,
        [classNames]: true,
      })}
    >
      {typeof children === 'string' ? <p>{children}</p> : children}
    </div>
  )
}

ErrorMessage.propTypes = {
  /**
   * HTML lub string content
   */
  children: PropTypes.node.isRequired,
  danger: PropTypes.bool,
  classNames: PropTypes.string,
}

export default ErrorMessage

import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { throttle } from 'lodash'
import classnames from 'classnames'

import { useOutsideCheckClick } from '../../hooks/UseOnClickOutside'
import Input from './Input'

// Takes an array of values with a value and translation

// [
//     {
//         value: 'the value to set state with',
//         text: 'the text to use'
//     }
// ]

export default function DropDown({
  name,
  value,
  options,
  onChange,
  placeholder,
  renderItem,
  disabled = false,
  required = true,
  onToggle,
  controlledOpen,
  disabledText,
  classNames = '',
  placementType = 'fixed',
  inputWhenDisabled = false,
}) {
  const { t } = useTranslation()
  const menuRef = useRef(null)
  const [placement, setPlacement] = useState('bottom')

  const [_open, setOpen] = useState(false)
  const isOpenControlled = controlledOpen !== undefined

  let defaultText = placeholder
  if (!placeholder) {
    defaultText = t('shared.selectMenu.defaultText')
  }

  const renderItemInternal = (item, isSelected) => {
    if (renderItem) {
      if (isSelected && !value) return defaultText
      return renderItem(item, isSelected)
    }

    if (isSelected) return value ? t(item?.text) : defaultText
    return t(item?.text)
  }

  const updatePlacement = useCallback(() => {
    if (placementType === 'fixed' || !menuRef.current) return

    const distanceToBottom = window.innerHeight - menuRef.current.getBoundingClientRect().bottom
    if (distanceToBottom < 120) {
      setPlacement('top')
    } else {
      setPlacement('bottom')
    }
  }, [placementType])

  useEffect(() => {
    updatePlacement()
    const throttledScroll = throttle(updatePlacement, 1000)
    window.addEventListener('scroll', throttledScroll)
    return () => window.removeEventListener('scroll', throttledScroll)
  }, [updatePlacement])

  const open = isOpenControlled ? controlledOpen : _open

  const handleToggle = useCallback((target) => {
    if (isOpenControlled) {
      onToggle(!open, target)
    } else {
      setOpen(!open)
    }
  }, [isOpenControlled, onToggle, open])

  useOutsideCheckClick(menuRef, (_, target) => handleToggle(target), open, () => null)

  if (inputWhenDisabled && disabled) {
    return (
      <Input
        id={name}
        placeholder={placeholder}
        name={name}
        disabled
        value={disabledText}
        classNames={classNames}
        required={required}
      />
    )
  }

  return (
    <div
      ref={menuRef}
      className={classnames('dropdown_container', classNames, {
        open: open,
      })}
    >
      <div
        onClick={() => {
          if (!disabled) {
            updatePlacement()
            handleToggle()
          }
        }}
        className={classnames({
          dropdown_input: true,
          empty: !value,
          selected: value,
          disabled,
        })}
      >
        <div className={classnames({ title: true, required })}>
          {renderItemInternal(
            options.find((x) => x.value === value),
            true,
          )}
        </div>
        {!disabled && (
          <div
            style={{
              transitionDuration: '0.2s',
              marginLeft: 'auto',
              transform: open ? 'rotate(180deg)' : 'rotate(0)',
            }}
            className={`events-insights-page__status_menu_arrow ${open ? 'open' : ''}`}
          />
        )}
      </div>
      <div className={`dropdown_menu_container ${open ? 'open' : ''} ${placement}`}>
        {options && options.length > 0
          ? options.map((eachOption, index) => {
            return (
              <div
                onClick={() => {
                  onChange(eachOption.value)
                  handleToggle()
                }}
                key={`${eachOption.value}-${index}`}
                className={`dropdown_menu_row ${eachOption.value === value ? 'selected' : ''}`}
              >
                {renderItemInternal(eachOption)}
              </div>
            )
          })
          : null}
      </div>
    </div>
  )
}

DropDown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  classNames: PropTypes.string,
  renderItem: PropTypes.func,
  placementType: PropTypes.oneOf(['fixed', 'dynamic']),
  onToggle: PropTypes.func,
  controlledOpen: PropTypes.bool,
  inputWhenDisabled: PropTypes.bool,
  disabledText: PropTypes.string,
}

import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'

import { useTranslation } from 'react-i18next'

import leftArrow from '../../images/icons/leftArrow.svg'
import rightArrow from '../../images/icons/rightArrow.svg'

const DateInput = ({ dateShown = '12-04-19', setDate, type = 'from', onSetDate }) => {
  const { t } = useTranslation()

  const dateTitleString = `Events.searchFilters.DropDownMenu.${type === 'from' ? 'Starting' : 'Ending'}`

  const [dateValidError, setDateValidError] = useState(false)
  const [originalDate, setOriginalDate] = useState(dateShown)
  const handleInputChange = (e) => {
    setDate(e.target.value.replaceAll('.', '-'))
  }

  const handleBlur = (e) => {
    const newD = moment(e.target.value.replaceAll('.', '-'), 'DD-MM-YYYY')
    const isValidDate = newD.isValid()

    if (isValidDate) {
      setDateValidError(false)
      onSetDate(moment(e.target.value.replaceAll('.', '-'), 'DD-MM-YYYY'))
      setOriginalDate(e.target.value.replaceAll('.', '-'))
    } else {
      setDate(originalDate)
      setDateValidError(true)
    }
  }

  return (
    <div>
      <h6>
        {' '}
        {t(dateTitleString)}
        <>{dateValidError && <span className="warning-red ml-4">Invalid date</span>}</>
      </h6>
      <label className={`event-control-panel_dropdown-menu_dates_input ${dateValidError ? 'warning-red' : ''}`}>
        <input
          onChange={(e) => handleInputChange(e)}
          type="text"
          pattern="[0-9]{2}.[0-9]{2}.[0-9]{4}"
          value={dateShown.replaceAll('-', '.')}
          onBlur={(e) => handleBlur(e)}
        />
      </label>
    </div>
  )
}

export default function Calender({
  handleDateChange,
  maxDate,
  minDate,
  isPast,
  isFuture,
  defaultDate = null,
  fromDate,
  toDate,
  type = 'from',
}) {
  const weekdayshort = moment.weekdaysShort()
  const minimumDate = minDate ? moment(minDate, 'DD-MM-YYYY') : null
  const maximumDate = maxDate ? moment(maxDate, 'DD-MM-YYYY') : null

  const [state, setState] = useState({
    dateObject: defaultDate ?? moment(),
    allmonths: moment.months(),
    selectedDay: null,
  })

  const [rows, setRows] = useState([])
  const [cells, setCells] = useState([])
  const [hasRan, setHasRan] = useState(false)

  const dInMonth = useCallback(() => {
    return state.dateObject.daysInMonth()
  }, [state.dateObject])
  const year = useCallback(() => {
    return state.dateObject.format('Y')
  }, [state.dateObject])

  const currentD = useCallback(() => {
    return state.dateObject.format('D')
  }, [state.dateObject])

  const firstDayOfMonth = useCallback(() => {
    const dateObject = state.dateObject
    const firstDay = moment(dateObject).startOf('month').format('d')
    return firstDay
  }, [state.dateObject])

  const month = useCallback(() => {
    return state.dateObject.format('MMMM')
  }, [state.dateObject])

  const handleDateSelection = useCallback(
    (selectedDay) => {
      handleDateChange(selectedDay)
    },
    [handleDateChange],
  )

  const onDayClick = useCallback(
    (e, d) => {
      setState({
        selectedDay: d,
        ...state,
      })
      handleDateSelection(e)
    },
    [state, handleDateSelection],
  )

  const onSetDate = useCallback(
    (newDateObject) => {
      setState({
        dateObject: newDateObject,
        allmonths: moment.months(),
        selectedDay: null,
      })
      setHasRan(false)
    },
    [setState],
  )

  const onPrev = () => {
    // if FUTURE calendar and next previous month < the current Date, do nothing
    if (isFuture && moment(state.dateObject).subtract(1, 'month').format() <= minimumDate.format()) {
      return
    } else {
      setState({
        dateObject: state.dateObject.subtract(1, 'month'),
        ...state,
      })
    }

    setHasRan(false)
  }

  const onNext = () => {
    // if PAST calendar and next upcoming month > the current Date, do nothing
    if (isPast && moment(state.dateObject).format() >= maximumDate.format()) {
      return
    } else {
      setState({
        dateObject: state.dateObject.add(1, 'month'),
        ...state,
      })
    }
    setHasRan(false)
  }

  const weekDayShortNames = weekdayshort.map((day) => {
    return (
      <th key={`${day}-dsms-${month()}-${year()}-${isPast ? 'past' : 'future'}`} style={{ fontWeight: '600' }}>
        {day.slice(0, 1)}
      </th>
    )
  })

  const dsInMs = (r) =>
    r.map((d, i) => {
      return <tr key={`${d}-${i}-ds-in-ms-${month()}-${year()}-${isPast ? 'past' : 'future'}`}>{d}</tr>
    })

  const getSelectedClass = useCallback(
    (date) => {
      setHasRan(false)
      if (
        moment(date, 'DD-MM-YYYY').isBetween(
          moment(fromDate, 'DD-MM-YYYY').subtract(1, 'day'),
          moment(toDate, 'DD-MM-YYYY').add(1, 'day'),
        )
      ) {
        return true
      } else {
        return false
      }
    },
    [toDate, fromDate],
  )

  useEffect(() => {
    if (hasRan) return

    let blanks = []
    for (let i = 0; i < firstDayOfMonth(); i++) {
      blanks.push(
        <td key={`${i}-blanks-${month()}-${year()}-${isPast ? 'past' : 'future'}`} className="calendar-day empty">
          {''}
        </td>,
      )
    }
    let daysInMonth = []
    for (let d = 1; d <= dInMonth(); d++) {
      const date = `${d > 9 ? d : `0${d}`}-${state.dateObject.format('MM')}-${year()}`
      daysInMonth.push(
        <td
          key={`${Number(d) > 9 ? d : `0${d}`}-${month()}-${year()}-${isPast ? 'past' : 'future'}`}
          className={`${getSelectedClass(date) ? 'highlighted' : ''} ${moment(fromDate, 'DD-MM-YYYY').isSame(moment(date, 'DD-MM-YYYY')) ? 'start' : ''} ${moment(toDate, 'DD-MM-YYYY').isSame(moment(date, 'DD-MM-YYYY')) ? 'end' : ''}`}
          onClick={() => {
            getSelectedClass()
            onDayClick(date, d, `id-${Number(d) > 9 ? d : `0${d}`}-${month()}-${year()}-${isPast ? 'past' : 'future'}`)
          }}
          value={`${d > 10 ? d : `0${d}`}-${month()}-${year()}`}
        >
          <span
            className={`${moment(fromDate, 'DD-MM-YYYY').isSame(moment(date, 'DD-MM-YYYY')) ? 'start' : ''} ${moment(toDate, 'DD-MM-YYYY').isSame(moment(date, 'DD-MM-YYYY')) ? 'end' : ''}`}
          >
            {d}
          </span>
        </td>,
      )
    }
    const slots = [...blanks, ...daysInMonth]
    let currentRows = []
    let currentCells = []

    slots.forEach((row, i) => {
      // each day of week
      if (i % 7 !== 0) {
        currentCells.push(row)
      } else {
        // each new week row
        currentRows.push(currentCells)
        currentCells = []
        currentCells.push(row)
      }
      // left over days
      if (i === slots.length - 1) {
        currentRows.push(currentCells)
      }
    })

    setRows(currentRows)
    setCells(currentCells)
    // stop over rendering component with flag
    setHasRan(true)
  }, [
    rows,
    cells,
    currentD,
    dInMonth,
    firstDayOfMonth,
    onDayClick,
    hasRan,
    setHasRan,
    isPast,
    month,
    state.dateObject,
    year,
    fromDate,
    toDate,
    getSelectedClass,
    defaultDate,
    onSetDate,
  ])

  return (
    <>
      <DateInput
        dateShown={type === 'from' ? fromDate : toDate}
        type={type}
        setDate={handleDateChange}
        onSetDate={onSetDate}
      />
      <div className="tail-datetime-calendar mt-4">
        <div className="calendar-navi">
          <span onClick={(e) => onPrev()}>
            <img
              alt={'previous-month-button'}
              style={{
                height: 14,
                paddingTop: 2,
                paddingLeft: 8,
                opacity: isPast
                  ? 1
                  : isFuture && moment(state.dateObject).subtract(1, 'month').format() >= minimumDate.format()
                    ? 1
                    : 0,
              }}
              src={leftArrow}
            />
          </span>

          <span style={{ fontSize: 20, width: '100px' }} className="calendar-label">
            {month()}
          </span>

          <span style={{ fontSize: 20 }} className="calendar-label">
            {year()}
          </span>

          <span onClick={onNext}>
            <img
              alt={'next-month-button'}
              style={{
                height: 14,
                marginLeft: 'auto',
                paddingRight: 8,
                paddingTop: 2,
                opacity: isFuture ? 1 : isPast && moment(state.dateObject).format() <= maximumDate.format() ? 1 : 0,
              }}
              src={rightArrow}
            />
          </span>
        </div>

        {/* creates calendar for each month dynamically */}
        <div className="calendar-date">
          <table className="calendar-day">
            <thead>
              <tr>{weekDayShortNames}</tr>
            </thead>
            <tbody>{rows.length > 0 && dsInMs(rows)}</tbody>
          </table>
        </div>
      </div>
    </>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { RemoveIcon, ErrorIcon, CloudUpload, SearchSyncNavyIcon } from 'images'
import { fileStatus } from './media'
import InfoBox from 'components/Shared/InfoBox'
import { filesize } from 'filesize'
import { useTranslation } from 'react-i18next'

const fileInfo = (file) => {
  return (
    <div>
      <div>{file.name || file.filename}</div>
      <div>{filesize(file.size || 0)}</div>
    </div>
  )
}

const AcceptedFile = ({ file, readonly, canRemove, canReplace, onRemove, onReplaceStarted, onRetry, onClick }) => {
  const { t } = useTranslation()

  const displayFile = () => {
    switch (file.status) {
      case fileStatus.new:
      case fileStatus.uploading:
        return (
          <>
            <CloudUpload width={30} />
            <div>{file.progress}%</div>
            <span className="icons_remove-icon">
              <RemoveIcon onClick={onRemove} width={14} />
            </span>
          </>
        )
      case fileStatus.success: {
        if (!readonly && canReplace)
          return (
            <>
              <div className="accepted-file__thumb" onClick={() => onClick(file)}>
                {file.type && file.type.startsWith('video') ? (
                  <video controls={false} src={file.url} title={file.name}></video>
                ) : (
                  <img src={file.preview || file.url} alt={file.name} />
                )}
              </div>
              <span className="icons_replace-icon">
                <SearchSyncNavyIcon
                  width={12}
                  title={t('Recommendations.campaign.setup.media.replace')}
                  onClick={() => {
                    onReplaceStarted(file.index)
                  }}
                />
              </span>
            </>
          )
        else
          return (
            <>
              <InfoBox body={fileInfo(file)} withAnchor={false} tooltipClassNames="topic-tooltip">
                <div className="accepted-file__thumb" onClick={() => onClick(file)}>
                  {file.type && file.type.startsWith('video') ? (
                    <video controls={false} src={file.url} title={file.name}></video>
                  ) : (
                    <img src={file.preview || file.url} alt={file.name} />
                  )}
                </div>
              </InfoBox>
              {!readonly && canRemove && (
                <span className="icons_remove-icon">
                  <RemoveIcon onClick={onRemove} width={14} />
                </span>
              )}
            </>
          )
      }
      case fileStatus.invalid:
      case fileStatus.uploadFailed:
        return (
          <>
            <span className="icons_error-icon">
              <ErrorIcon
                title={file.error}
                width={24}
                onClick={() => file.status === fileStatus.uploadFailed && onRetry(file)}
              />
            </span>
            {!readonly && canRemove && (
              <span className="icons_remove-icon">
                <RemoveIcon onClick={onRemove} width={14} />
              </span>
            )}
          </>
        )
      default:
        return null
    }
  }
  return (
    <div key={file.key} className="accepted-file__itemBox">
      {displayFile()}
    </div>
  )
}

AcceptedFile.propTypes = {
  file: PropTypes.object,
  onRemove: PropTypes.func,
  onReplaceStarted: PropTypes.func,
  onRetry: PropTypes.func,
  readonly: PropTypes.bool,
  canRemove: PropTypes.bool,
  canReplace: PropTypes.bool,
  onClick: PropTypes.func,
}

const MediaList = ({ media, onRemove, isMobileSize, ...otherProps }) => {
  const cn = classnames({
    'media-files': true,
    'mobile-view': isMobileSize,
  })

  if (media.length) {
    return (
      <div className={cn}>
        {media.map((file) => (
          <AcceptedFile
            key={file.id}
            file={file}
            onRemove={() => onRemove(file.id)}
            onClick={otherProps.setExpandedImage}
            {...otherProps}
          />
        ))}
      </div>
    )
  }
  return null
}

MediaList.propTypes = {
  media: PropTypes.array,
  onRemove: PropTypes.func,
  onReplaceStarted: PropTypes.func,
  onRetry: PropTypes.func,
  readonly: PropTypes.bool,
  canRemove: PropTypes.bool,
  canReplace: PropTypes.bool,
  isMobileSize: PropTypes.bool,
}

export default MediaList

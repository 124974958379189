import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import ErrorFallback from 'components/Shared/ErrorFallback'
import Connect from './Connect.jsx'
import Assets from './Assets'
import { getConfigurationByAssetCategoryKey } from './util'
import LoadingSpinner, { SpinnerSize } from 'components/Shared/LoadingSpinner'
import { ConnectContext, dispatchToggleAsset } from './connect'
import InfoBox from 'components/Shared/InfoBox'

const Props = {
  tokenType: PropTypes.oneOf(['user', 'system']).isRequired,
  onFacebookLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
}

function TitleBar({ tokenType, loading, connected, onFacebookLogin, onLogout }) {
  const { t } = useTranslation()
  const [viewWidth, setViewWidth] = React.useState(window.innerWidth)
  const handleResize = () => setViewWidth(window.innerWidth)

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isMobile = viewWidth <= 975

  const tooltipKey = `Tips.Settings.${tokenType.replace(/\b\w/g, (l) => l.toUpperCase())}MetaAccess`

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="settings-page__section title-bar">
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="d-flex align-items-center gap-1">
            <InfoBox
              title={t(`${tooltipKey}.title`)}
              body={
                <div>
                  <p>{t(`${tooltipKey}.body1`)}</p>
                  <br />
                  <p>{t(`${tooltipKey}.body2`)}</p>
                </div>
              }
              classNames="title-bar__title"
              // Ensure the tooltip for the user token isn't cut off by the top of the screen
              placement={tokenType === 'user' ? 'bottom' : 'top'}
            >
              <h3>{t(`Settings.MetaAccess.${tokenType}AccessToken`)}</h3>
            </InfoBox>
            {loading && <LoadingSpinner size={SpinnerSize.SMALL} showText={false} />}
          </div>
          {(!isMobile || connected) && (
            <Connect tokenType={tokenType} onFacebookLogin={onFacebookLogin} onLogout={onLogout} />
          )}
        </div>
        <p className="title-bar__description mt-lg-3">{t('Settings.MetaAccess.assetsSubtext')}</p>
        {isMobile && !connected && (
          <Connect tokenType={tokenType} onFacebookLogin={onFacebookLogin} onLogout={onLogout} />
        )}
      </div>
    </ErrorBoundary>
  )
}

TitleBar.propTypes = {
  ...Props,
  loading: PropTypes.bool,
  connected: PropTypes.bool,
}

export function Connection({ tokenType, onFacebookLogin, onLogout }) {
  const { state, dispatch } = useContext(ConnectContext)
  const { status } = state

  const onAddAsset = useCallback(
    (assetCategoryKey) => {
      const configuration = getConfigurationByAssetCategoryKey(status, assetCategoryKey)
      onFacebookLogin(configuration, 'userstate', true)
    },
    [onFacebookLogin, status],
  )

  const connected = tokenType === 'system' ? status.has_valid_system_user_token : status.has_valid_user_token

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="meta_connection card card-default settings-page__card">
        <TitleBar
          tokenType={tokenType}
          onFacebookLogin={onFacebookLogin}
          onLogout={onLogout}
          loading={false}
          connected={connected}
        />
        <Assets
          tokenType={tokenType}
          onAddAsset={onAddAsset}
          onToggleAsset={(id, enabled) => dispatchToggleAsset(dispatch, id, enabled)}
        />
      </div>
    </ErrorBoundary>
  )
}

Connection.propTypes = Props

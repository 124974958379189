import React, { useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { useOutsideCheckClick } from '../../../hooks/UseOnClickOutside'
import Calendar from '../../Shared/Calendar'

function DateSelectDropDown({ handleChange, options, menuOpen, setMenuOpen }) {
  return (
    <div
      className={classnames({
        'event-control-panel_dropdown-menu_dates_select-menu-wrapper': true,
        open: menuOpen,
      })}
    >
      {options.map((option) => (
        <div
          className="event-control-panel_dropdown-menu_dates_select-option"
          key={option.key}
          onClick={(e) => {
            handleChange(option.key, option.value, e)
            setMenuOpen(false)
          }}
        >
          {option.name}
        </div>
      ))}
    </div>
  )
}

DateSelectDropDown.propTypes = {
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  menuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func.isRequired,
}

function DateSelectMenu({ handleChange, currentSelectOption, options }) {
  const [menuOpen, setMenuOpen] = useState(false)

  const menuRef = useRef(null)
  useOutsideCheckClick(menuRef, setMenuOpen, menuOpen, () => null)

  return (
    <div
      ref={menuRef}
      className="event-control-panel_dropdown-menu_dates_select-menu_outer"
      onClick={() => setMenuOpen(!menuOpen)}
    >
      <div className="event-control-panel_dropdown-menu_dates_select_input_wrapper">
        <div>{options.find((el) => el.key === currentSelectOption).name}</div>
        <div
          style={{
            transitionDuration: '0.2s',
            marginLeft: 'auto',
            transform: menuOpen ? 'rotate(180deg)' : 'rotate(0)',
          }}
          className={`events-insights-page__status_menu_arrow ${menuOpen ? 'open' : ''}`}
        />
      </div>
      {menuOpen && (
        <DateSelectDropDown
          handleChange={handleChange}
          options={options}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
        />
      )}
    </div>
  )
}

DateSelectMenu.propTypes = {
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  currentSelectOption: PropTypes.string,
}

export default function Date({ state, dispatch, submit }) {
  const { t } = useTranslation()
  const { from: fromDate, to: toDate, option: menuOption } = state.dates

  const options = [
    // custom default
    { name: t('common.range.time.custom'), value: null, key: 'date-option-1' },
    // 1 week
    { name: t('common.range.time.week'), value: moment().add(1, 'week').format('DD-MM-YYYY'), key: 'date-option-2' },
    // 2 week
    {
      name: t('common.range.time.twoWeeks'),
      value: moment().add(2, 'weeks').format('DD-MM-YYYY'),
      key: 'date-option-3',
    },
    // 1 month
    { name: t('common.range.time.month'), value: moment().add(1, 'month').format('DD-MM-YYYY'), key: 'date-option-4' },
    // 3 months
    {
      name: t('common.range.time.quarter'),
      value: moment().add(3, 'months').format('DD-MM-YYYY'),
      key: 'date-option-5',
    },
    // 1 year
    { name: t('common.range.time.year'), value: moment().add(1, 'year').format('DD-MM-YYYY'), key: 'date-option-6' },
  ]
  const [currentOption, setCurrentOption] = useState(menuOption ?? 'date-option-1')
  const [isCustom, setIsCustom] = useState(['date-option-1', undefined, null].includes(menuOption))
  const [dates, setDates] = useState({ to: toDate, from: fromDate })

  const handleToChange = useCallback(
    (to) => {
      setIsCustom(true)
      setDates((prevDates) => {
        submit({ ...state, dates: { ...prevDates, to } })
        return { ...prevDates, to }
      })

      dispatch({ type: 'DATES', payload: { to } })
    },
    [setIsCustom, dispatch, state, submit],
  )

  const handleFromChange = useCallback(
    (from) => {
      setIsCustom(true)
      setDates((prevDates) => {
        submit({ ...state, dates: { ...prevDates, from } })
        return { ...prevDates, from }
      })

      dispatch({ type: 'DATES', payload: { from } })
    },
    [setIsCustom, dispatch, state, submit],
  )

  const handleSelectMenuChange = (optionKey, newValue, e) => {
    e.stopPropagation()
    if (optionKey === currentOption) return
    let newDates = { from: moment().format('DD-MM-YYYY'), to: newValue, option: optionKey }

    setCurrentOption(optionKey)
    if (optionKey === 'date-option-1') {
      setIsCustom(true)
      // Skip from/to dates update
    } else {
      setIsCustom(false)
      dispatch({ type: 'DATES', payload: newDates })
      submit({ ...state, dates: newDates })
    }
  }

  return (
    <div className="event-control-panel_dropdown-menu_dates">
      <h6>{t('Dashboard.rangeSwitchTitle')}</h6>
      <DateSelectMenu options={options} currentSelectOption={currentOption} handleChange={handleSelectMenuChange} />
      <div className={`event-control-panel_dropdown-menu_dates_row ${isCustom ? 'mt-4' : ''}`}>
        {isCustom && (
          <>
            <div className="event-control-panel_dropdown-menu_dates_row_calendar_left">
              <Calendar
                handleDateChange={handleFromChange}
                maxDate={moment().format('DD-MM-YYYY')}
                minDate={null}
                isPast={true}
                isFuture={false}
                fromDate={dates.from}
                toDate={dates.to}
                defaultDate={moment(dates.from, 'DD-MM-YYYY')}
              />
            </div>
            <div className="event-control-panel_dropdown-menu_dates_row_calendar_right">
              <Calendar
                type="to"
                handleDateChange={handleToChange}
                maxDate={null}
                minDate={moment().format('DD-MM-YYYY')}
                isPast={false}
                isFuture={true}
                fromDate={dates.from}
                toDate={dates.to}
                defaultDate={moment(dates.to, 'DD-MM-YYYY')}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

Date.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
}

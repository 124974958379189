import React from 'react'
import { useTranslation } from 'react-i18next'

import { DashedBorderedButton } from 'components/Shared/Button'
import { useHistory } from 'react-router-dom'
import useHasPermission, { Permissions } from 'hooks/useHasPermission'

const AddEvent = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const hasSimulationAccess = useHasPermission(Permissions.simulation)

  return (
    <div className="d-flex flex-row gap-2 mt-4 w-100">
      <DashedBorderedButton
        disabled={!hasSimulationAccess}
        text={t('Simulation.addOneEvent')}
        color="navy"
        onClick={() => history.replace('/simulation')}
        showIcon={false}
        className="w-100"
      />
      <DashedBorderedButton
        disabled={!hasSimulationAccess}
        text={t('AddMultipleEvents.addEventsBtn')}
        color="navy"
        onClick={() => history.replace('/add-multi-events')}
        showIcon={false}
        className="w-100"
      />
    </div>
  )
}

export default AddEvent

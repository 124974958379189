import React from 'react'
import PropTypes from 'prop-types'

import SplashScreen from 'components/Shared/SplashScreen'

export default function LoginSuccess({ state, showSplash, setShowSplash }) {
  return <div>{state.isLogged && <SplashScreen callback={setShowSplash} mounted={showSplash} />}</div>
}

LoginSuccess.propTypes = {
  state: PropTypes.object,
  showSplash: PropTypes.bool,
  setShowSplash: PropTypes.func,
}

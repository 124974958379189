import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { CustomerSegmentationContext } from './CustomerSegmentation/CustomerSegmentationContext'
import { SecondaryButton } from 'components/Shared/Button'

import { localizeDateObj } from 'utils/helpers'

function PreviewRow({ children }) {
  return <div className="package-builder_preview_row">{children}</div>
}

PreviewRow.propTypes = {
  children: PropTypes.node,
}

PreviewRow.Title = ({ children }) => <div className="title">{children}:</div>
PreviewRow.Title.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}
PreviewRow.Title.displayName = 'PreviewRowTitle'

PreviewRow.Qualifier = ({ children, className = '' }) => <div className={`qualifier ${className}`}>{children}</div>
PreviewRow.Qualifier.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
}
PreviewRow.Qualifier.displayName = 'PreviewRowQualifier'

PreviewRow.Value = ({ children }) => (
  <div className="value">
    {typeof children === 'string' ? <span className="value-text">{children}</span> : children}
  </div>
)
PreviewRow.Value.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}
PreviewRow.Value.displayName = 'PreviewRowValue'

export default function CustomerSegmentationSection({ setCustomerModalOpen }) {
  const { t } = useTranslation()
  const state = useContext(CustomerSegmentationContext)
  const currency = useMemo(() => localStorage.getItem('userCurrency') || 'EUR', [])

  const CustomerListItem =
    state.customerIds.qualifier && state.customerIds.value.length > 0 ? (
      <PreviewRow>
        <PreviewRow.Title>{t('PackageBuilder.customerSegmentation.customerList')}</PreviewRow.Title>
        <PreviewRow.Qualifier className="mr-3">
          {t(`PackageBuilder.customerSegmentation.${state.customerIds.qualifier}`)}
        </PreviewRow.Qualifier>
        <PreviewRow.Value>
          <SecondaryButton
            text={t('PackageBuilder.customerSegmentation.customerIds')}
            color="navy"
            size="small"
            fullWidth={false}
            type="button"
            disabled={!state.customerIds.qualifier}
            onClick={(e) => {
              e.preventDefault()
              setCustomerModalOpen(true)
            }}
          />
        </PreviewRow.Value>
      </PreviewRow>
    ) : null

  const PurchaseDate = state.excludedPurchaseDate.value ? (
    <PreviewRow>
      <PreviewRow.Title>{t('PackageBuilder.customerSegmentation.purchaseDateExclusion')}</PreviewRow.Title>
      <PreviewRow.Qualifier>
        {t(`PackageBuilder.customerSegmentation.${state.excludedPurchaseDate.qualifier}`)}
      </PreviewRow.Qualifier>
      {/* format value as  */}
      <PreviewRow.Value>{localizeDateObj(state.excludedPurchaseDate.value, 'de')}</PreviewRow.Value>
    </PreviewRow>
  ) : null

  const PriceSensitivity = state.excludedPriceRange.value ? (
    <PreviewRow>
      <PreviewRow.Title>{t('PackageBuilder.customerSegmentation.priceSensitivityExclusion')}</PreviewRow.Title>
      <PreviewRow.Qualifier>
        {t(`PackageBuilder.customerSegmentation.${state.excludedPriceRange.qualifier}`)}
      </PreviewRow.Qualifier>
      <PreviewRow.Value>
        {state.excludedPriceRange.value}&nbsp;{currency}
      </PreviewRow.Value>
    </PreviewRow>
  ) : null

  const TicketCategories =
    state.excludedTicketCategories.length > 0 ? (
      <PreviewRow>
        <PreviewRow.Title>{t('PackageBuilder.customerSegmentation.ticketCategoryExclusion')}</PreviewRow.Title>
        <PreviewRow.Value>
          {state.excludedTicketCategories.map((x) => t(`Event.ticketType.${x}`)).join('; ')}
        </PreviewRow.Value>
      </PreviewRow>
    ) : null

  if (!CustomerListItem && !PurchaseDate && !PriceSensitivity && !TicketCategories) {
    return <p>{t('PackageBuilder.customerSegmentation.noSegmentation')}</p>
  }

  return (
    <div className="d-flex flex-column gap-4">
      {CustomerListItem}
      {PurchaseDate}
      {PriceSensitivity}
      {TicketCategories}
    </div>
  )
}

CustomerSegmentationSection.propTypes = {
  setCustomerModalOpen: PropTypes.func,
}

import React from 'react'
import propTypes from 'prop-types'
import classnames from 'classnames'

const FallingDots = ({ classNames = '' }) => {
  return (
    <div className={classnames({ 'falling-dots snippet': true, [classNames]: !!classNames })} data-title=".dot-shuttle">
      <div className="falling-dots stage filter-contrast">
        <div className="falling-dots dot-falling"></div>
      </div>
    </div>
  )
}

export default FallingDots

FallingDots.propTypes = {
  classNames: propTypes.string,
}

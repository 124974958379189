import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Card } from 'react-bootstrap'
import Input from '../../Shared/Input'
import Artist from './Artist'
import SetList from './Setlist'
import { registerLocale } from 'react-datepicker'
import de from 'date-fns/locale/de'
import KeyFactsForm from './KeyFactsFormDefault'
import { SecondaryButton } from '../../Shared/Button'
import DropDown from '../../Shared/DropDown'

import CopyPasteContent from '../../EventEditor/CopyPasteLineupSetlist/Content'

import InfoBox from '../../Shared/InfoBox'
import PortalModal from 'components/Shared/PortalModal'
import Textarea from 'components/Shared/Textarea'
import Switch from 'components/Shared/Controls/Switch'
import { eventCategories } from '../constants'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'

registerLocale('de', de)

/**
 * Set simulation form with datapicker.
 * @Tags( simulation, api )
 * @Inputs( eventName, Date, Time, Venue, Hall, Capacity, Price, Artists, Tracks, Categories )
 * @NpmPackages( react-datepicker, date-fns )
 */
const SimulationFormDefault = ({
  form,
  fields,
  onAddField,
  onRemoveField,
  onUpdateInput,
  onUpdateDate,
  onCategoryChanged,
  onSetlistsChanged,
  onLineupsChanged,
}) => {
  const { t } = useTranslation()
  const [copyModalOpen, setCopyModalOpen] = useState(false)
  const [showCopiedNotification, setShowCopiedNotification] = useState(false)

  const closeModalAndShowNotification = () => {
    setCopyModalOpen()
    setShowCopiedNotification(true)

    setTimeout(() => setShowCopiedNotification(false), 3000)
  }

  return (
    <>
      <div className="simulation-form__section simulation-form__key-facts">
        <Card className="card-default mb-0">
          <div className="card-header">
            <h2 className="card-title-default">{t('Simulation.nameInputTitle')}</h2>
          </div>
          <Card.Body>
            <div className="simulation-form_header-wrap">
              <div className="requiredInputBox simulation-form_sim-name-wrap">
                <Input
                  placeholder={t('Simulation.form.eventName')}
                  separatePlaceholder={true}
                  value={form.simName}
                  name="name"
                  onChange={(e) => onUpdateInput(e)}
                  required
                />
              </div>
              <div className="requiredInputBox simulation-form_sim-category-wrap">
                <div className="d-flex align-items-center justify-content-between gap-4">
                  {hasPrismaAccessTier() && (
                    <div className="d-flex align-items-center">
                      <Switch
                        label={t('common.events')}
                        checked
                        onClick={() => onCategoryChanged('product')}
                      />
                    </div>
                  )}
                  <DropDown
                    onChange={onCategoryChanged}
                    value={form.category}
                    options={eventCategories}
                    placeholder={t('Simulation.form.eventCategory')}
                    classNames="simulation-form_sim-category-dropdown"
                  />
                </div>
              </div>
            </div>
            <Textarea
              classNames="mb-0 mt-3 mt-md-0"
              placeholder={t('Simulation.form.description')}
              separatePlaceholder
              value={form.description ?? ''}
              name="description"
              onChange={(e) => onUpdateInput(e)}
              maxLength={2500}
              showMax
              rows={5}
            />
          </Card.Body>
        </Card>
      </div>

      {fields.map((f, idx) => {
        return (
          <KeyFactsForm
            key={`f-${idx}`}
            startDate={form.startDate}
            updateDate={onUpdateDate}
            fields={f}
            updateInput={onUpdateInput}
            index={idx}
            removeField={onRemoveField}
          />
        )
      })}
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <button
          type="button"
          className="lineup__add"
          onClick={onAddField}
          aria-label={t('Simulation.form.addDatesAndVenues')}
        >
          <span>+</span>
          {t('Simulation.form.addDatesAndVenues')}
        </button>
      </div>

      <div className="simulation-form__section simulation-form__programme">
        <Card className="card-default mb-4">
          <div
            className="card-header"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              paddingBottom: '1em',
              flexWrap: 'wrap',
            }}
          >
            <h2 className="card-title-default">{t('Simulation.artistFormTitle')}</h2>

            <div className="display-flex">
              <SecondaryButton
                classNames="simulation-btn"
                text={t('EventEditor.copyFromEvent')}
                onClick={() => setCopyModalOpen(true)}
                showIcon={false}
                isBackArrow={false}
                color={'navy'}
                size={'small'}
              />
              <div>
                <InfoBox content={'Tips.EventEdit.copySetlistLineupToolTip'} />
              </div>
            </div>
          </div>
          <Card.Body className="d-flex justify-content-center flex-column gap-5">
            <div className=" d-flex lineup__header">
              <h5 className="lineup__title">{t('Simulation.artists')}</h5>
            </div>
            {form.lineups.map((lineup, index) => (
              <Artist
                key={index}
                removeArtist={() => {
                  onLineupsChanged(form.lineups.filter((item, i) => index !== i))
                }}
                setFields={onLineupsChanged}
                artistIndex={index}
                lineup={lineup}
                lineups={form.lineups}
              />
            ))}

            <button
              type="button"
              className="lineup__add"
              onClick={() =>
                onLineupsChanged([
                  ...form.lineups,
                  {
                    artist_name: '',
                    artist_type: '',
                  },
                ])
              }
              aria-label={t('Simulation.form.addArtist')}
            >
              <span>+</span>
              {t('Simulation.form.addArtist')}
            </button>
          </Card.Body>
          <Card.Body className="d-flex justify-content-center flex-column gap-5">
            <div className=" d-flex lineup__header">
              <h5 className="lineup__title">{t('Simulation.programme')}</h5>
            </div>
            {form.setlists.map((setlist, index) => (
              <SetList
                key={index}
                removeSetlist={() => {
                  onSetlistsChanged(form.setlists.filter((item, i) => index !== i))
                }}
                setFields={onSetlistsChanged}
                setlists={form.setlists}
                setlist={setlist}
                setlistIndex={index}
              />
            ))}

            <button
              type="button"
              className="lineup__add"
              onClick={() =>
                onSetlistsChanged([
                  ...form.setlists,
                  {
                    artist_name: '',
                    track: '',
                  },
                ])
              }
              aria-label={t('Simulation.form.addWork')}
            >
              <span>+</span>
              {t('Simulation.form.addWork')}
            </button>
          </Card.Body>
        </Card>
      </div>
      {!!copyModalOpen && (
        <PortalModal
          isOpen={copyModalOpen}
          closeCallback={() => setCopyModalOpen(false)}
          onClickOutside={() => setCopyModalOpen(false)}
        >
          <CopyPasteContent
            currentSetlists={form.setlists.filter((x) => x && (!!x.artist_name || !!x.track))}
            currentLineups={form.lineups.filter((x) => x && (!!x.artist_name || !!x.artist_type))}
            setSetlists={onSetlistsChanged}
            setLineups={onLineupsChanged}
            eventTitle={form.simName}
            closeModalAndShowNotification={closeModalAndShowNotification}
            closeModal={setCopyModalOpen}
          />
        </PortalModal>
      )}
      {showCopiedNotification && (
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            textAlign: 'center',
          }}
        >
          <h1 className="main-title hide-mobile ml-4">{t('common.copied')}</h1>
        </div>
      )}
    </>
  )
}

SimulationFormDefault.propTypes = {
  form: PropTypes.object,
  fields: PropTypes.array,
  onAddField: PropTypes.func,
  onRemoveField: PropTypes.func,
  onUpdateInput: PropTypes.func,
  onUpdateDate: PropTypes.func,
  onCategoryChanged: PropTypes.func.isRequired,
  categoryOptions: PropTypes.array,
  onSetlistsChanged: PropTypes.func,
  onLineupsChanged: PropTypes.func,
}

export default SimulationFormDefault

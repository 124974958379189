import React, { useContext, useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'

import { initStateObject, objectReducer } from '../../../reducers/default'

import ErrorFallback from '../../Shared/ErrorFallback'
import CampaignStepWrapper from './CampaignStepWrapper'
import Tabs from 'components/Shared/Tabs'
import EventStatistics from 'components/Event/Details/EventStatistics'
import { getEventSeries } from 'api/events'
import { CampaignStatsContext } from 'components/Event/Details/EventStatsProvider'
import { useFetchCampaigns } from 'hooks/useFetchCampaigns'

export function EventCampaigns({ eventId }) {
  const { t } = useTranslation()
  const [event, dispatchEventAction] = useReducer(objectReducer, initStateObject)
  const [eventSeries, dispatchEventSeries] = useReducer(objectReducer, initStateObject)
  const [currentTab, setCurrentTab] = useState(0)
  const [key, setKey] = useState(0)
  const [compMounted, setCompMounted] = useState(false)
  // listens for breakpoints desktop => mobile && vice versa
  const [viewWidth, setViewWidth] = useState(window.innerWidth)
  const handleResize = () => setViewWidth(window.innerWidth)
  const hasCampaignStats = useContext(CampaignStatsContext)

  const { list: campaigns, refetch: refetchCampaigns, eventSeriesCampaigns } = useFetchCampaigns(event.content)

  // looks at the url search params to see if tab is included
  // uses it if it is else ignore and use the default tab
  useEffect(() => {
    if (!compMounted) {
      const urlParams = new URL(window.location.href)
      const tab = urlParams.searchParams.get('tab') || 0

      setCurrentTab(tab)
      setCompMounted(true)
    }
  }, [setCurrentTab, setCompMounted, compMounted])

  useEffect(() => {
    if (eventId) {
      getEventSeries(dispatchEventSeries, eventId)

      if (hasCampaignStats === false && currentTab === '1') setCurrentTab(0)
    }
  }, [eventId, event, currentTab, hasCampaignStats])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleTabChange = (idx) => {
    if (!hasCampaignStats && idx === 1) return

    setCurrentTab(idx)
    if (window.innerWidth >= 991) {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('tab', idx)
      window.history.pushState(null, '', window.location.pathname + '?' + searchParams.toString())
    } else {
      const newUrl = `${window.location.origin}/campaigns/${eventId}/?tab=${idx}`
      window.history.pushState({ path: newUrl }, '', newUrl)
    }
  }

  // if mobile, redirect user to full screen views
  if (viewWidth < 991) {
    const urlParams = new URL(window.location.href)
    if (urlParams.searchParams.get('drawer-open') === 'true') return <Redirect to={`/campaigns/${eventId}`} />
  }

  const tabsData = [
    {
      title: t('Recommendations.tabs.1'),
      component: (
        <div className="mt-4">
          <CampaignStepWrapper
            eventId={eventId}
            eventState={[event, dispatchEventAction]}
            fetchedCampaigns={{ campaigns, refetchCampaigns }}
            resetKey={key}
          />
        </div>
      ),
    },
    {
      title: t('Recommendations.tabs.2'),
      disableTab: !hasCampaignStats,
      component: (
        <div className="mt-4">
          <EventStatistics event={event.content} eventSeries={eventSeries.content} campaigns={eventSeriesCampaigns} />
        </div>
      ),
    },
  ]

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        setKey((k) => k + 1)
      }}
      resetKeys={[key]}
    >
      <div id="campaign-container" className="campaign-drawer">
        <Tabs tabsData={tabsData} currentTab={currentTab} handleTabChange={handleTabChange} />
      </div>
    </ErrorBoundary>
  )
}

EventCampaigns.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

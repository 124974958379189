import {
  buildSetupPayload,
  getDisabledVariationsFromCreatives,
  setVariationHashCache,
} from 'api/models'
import setupProcessApi from 'api/setupProcesses'
import { strReplaceAt } from 'utils/helpers'

export const actions = {
  overwrite: 'OVERWRITE',
  syncVariations: 'SYNC_VARIATIONS',
  setLoading: 'SET_LOADING',
  setError: 'SET_ERROR',
  syncSetupProcessVariationsAsync: 'SYNC_SETUP_PROCESS_VARIATIONS_ASYNC',
}

export const initialState = ({ eid, creatives }) => {
  const disabledVariations = getDisabledVariationsFromCreatives(creatives)

  return {
    disabledVariations,
    loading: false,
    error: null,
    eid: eid,
  }
}

export function reducer(state, action) {
  switch (action.type) {
    case actions.overwrite: {
      const { disabledVariations } = action.payload
      return {
        ...state,
        disabledVariations,
        loading: false,
      }
    }
    case actions.syncVariations: {
      const disabledVariations = action.payload
      return {
        ...state,
        disabledVariations,
        loading: false,
      }
    }
    case actions.setLoading:
      return {
        ...state,
        loading: true,
      }
    case actions.setError: {
      const { error } = action.payload
      return {
        ...state,
        error: error,
        loading: false,
      }
    }
    default:
      throw new Error(`Unknown action type ${action.type}`)
  }
}

const updateDisabledVariationsAsync = async (setupProcess, disabledVariations) => {
  if (!setupProcess) {
    throw new Error('No setup process provided')
  }

  try {
    const payload = {
      ...setupProcess,
      creatives: setupProcess.creatives.map((c) => ({
        ...c,
        excluded_variations: disabledVariations[c.tc],
      })),
    }
    return await setupProcessApi.updateAsync(setupProcess.eid, buildSetupPayload(payload))
  } catch (error) {
    console.error(error)
    return { error: error.message }
  }
}

export const asyncActionHandlers = {
  [actions.syncSetupProcessVariationsAsync]:
    ({ dispatch }) =>
      async (action) => {
        const { setupProcess, disabledVariations, callback } = action.payload
        const expandedDisabledVariations = expandReelVariations(disabledVariations, setupProcess.creatives)

        dispatch({ type: actions.setLoading })

        try {
          const { error, status } = await updateDisabledVariationsAsync(setupProcess, expandedDisabledVariations)

          if (!error && status === 200) {
            setVariationHashCache(setupProcess.eid, setupProcess.creatives, expandedDisabledVariations)
            if (callback) callback(expandedDisabledVariations)

            dispatch({ type: actions.syncVariations, payload: expandedDisabledVariations })
          } else {
            console.error('Failed to sync variations', error)
            dispatch({
              type: actions.setError,
              payload: {
                error: 'Failed to sync variations',
                expandedDisabledVariations,
              },
            })
          }
        } catch (error) {
          console.error(error)
          dispatch({ type: actions.setError, payload: error.message })
        }
      },
}

/**
 * Expands the disabled variations of a reel by generating new variations based on the setup process.
 *
 * @param {Object} disabledVariations - The disabled variations object.
 * @param {Object} creatives - The creatives object.
 * @returns {Object} - The expanded reel variations.
 */
function expandReelVariations(disabledVariations, creatives) {
  return Object.keys(disabledVariations).reduce((acc, tc) => {
    acc[tc] =
      disabledVariations[tc]?.reduce((acc, v) => {
        if (v.startsWith('x')) {
          const reelId = v.slice(1)
          const totalHeadlines = creatives.find((c) => c.tc === tc)?.headline?.filter(Boolean)?.length || 0
          if (!totalHeadlines) return acc
          return acc.concat(Array.from({ length: totalHeadlines }, (_, i) => `${i + 1}${reelId}`))
        }

        acc.push(v)
        return acc
      }, []) || []
    return acc
  }, {})
}

/**
 * Expands a variation ID into an array of IDs based on certain conditions.
 *
 * @param {string} variationId - The variation ID to expand.
 * @param {Array} creatives - The array of creatives.
 * @param {string} tc - The tc value.
 * @returns {Array} - The expanded array of variation IDs.
 */
export function expandVariationId(variationId, creatives, tc) {
  if (variationId.startsWith('x')) {
    const reelId = variationId.slice(1)
    const totalHeadlines = creatives.find((c) => c.tc === tc)?.headline?.filter(Boolean)?.length || 0
    return Array.from({ length: totalHeadlines }, (_, i) => `${i + 1}${reelId}`)
  }

  return [variationId]
}

export function isVariationDisabled(disabledVariations, tc, variationId) {
  if (!disabledVariations[tc]) return false

  if (variationId.startsWith('x')) {
    const reelId = variationId.slice(1)
    return disabledVariations[tc].some((v) => v.slice(1) === reelId)
  }

  return disabledVariations[tc].includes(variationId)
}

export function hasEnabledVariations(creatives, disabledVariations = null) {
  disabledVariations = disabledVariations || getDisabledVariationsFromCreatives(creatives)
  const totalVariations = getTotalVariations(creatives)
  const totalDisabledVariations = Object.values(disabledVariations).reduce((sum, v) => sum + v.length, 0)

  return totalVariations - totalDisabledVariations > 0
}

/**
 * Offsets the variations to handle removed creative field.
 *
 * @param {Object} removedField - The missing fields object of the shape { type: string, index: number }.
 * @param {Array} creatives - The array of creatives.
 * @param {string} tc - The tc value.
 */
export function offsetVariationsToHandleRemovedCreativeField(removedField, tcDisabledVariations) {
  if (!removedField) return tcDisabledVariations

  let newVariations = [...tcDisabledVariations]

  if (tcDisabledVariations) {
    newVariations = newVariations.filter(
      (v) => getVariationItemIndexByType(v, removedField.type) != removedField.index + 1, // eslint-disable-line eqeqeq
    )

    const variationsToOffset = newVariations.filter(
      (v) => getVariationItemIndexByType(v, removedField.type) > removedField.index + 1,
    )

    for (const variation of variationsToOffset) {
      const indexInVariation = getIndexInVariationByType(variation, removedField.type)
      const index = getVariationItemIndexByType(variation, removedField.type)
      newVariations[newVariations.indexOf(variation)] = strReplaceAt(
        variation,
        indexInVariation,
        (index - 1).toString(),
        index.length,
      )
    }
  }

  return newVariations
}

export function getTotalDisabledVariations(creatives, reels) {
  const dv = getDisabledVariationsFromCreatives(creatives)
  let totalDisabledVariations = 0

  // consider all variations with the same reel image as one
  for (const tc of Object.keys(dv)) {
    const reelImageIndexes = reels
      .filter((x) => x.tc === tc)
      .map((r) => getVariationItemIndexByType(r.variationId, 'media'))
    totalDisabledVariations += dv[tc]
      .filter((v) => !reelImageIndexes.includes(getVariationItemIndexByType(v, 'media')))
      .reduce((sum, v) => {
        return reelImageIndexes.includes(getVariationItemIndexByType(v, 'media')) ? sum : sum + 1
      }, 0)
  }

  for (const reel of reels) {
    const reelDisabledVariations = dv[reel.tc].filter(
      (v) => v.slice(1, v.length) === reel.variationId.slice(1, reel.variationId.length), // ignoring headlines
    )
    totalDisabledVariations += new Set(
      reelDisabledVariations.map(
        (v) => `${getVariationItemIndexByType(v, 'body')}${getVariationItemIndexByType(v, 'destination_url')}`,
      ),
    ).size
  }

  return totalDisabledVariations
}

export function offsetVariationsToHandleRemovedCreativeFields(removedFields, creatives, tc) {
  const dv = getDisabledVariationsFromCreatives(creatives)
  if (!removedFields) return dv

  let tcDisabledVariations = dv[tc]

  for (const missingField of removedFields) {
    tcDisabledVariations = offsetVariationsToHandleRemovedCreativeField(missingField, tcDisabledVariations)
  }

  return tcDisabledVariations
}

function getTotalVariations(creatives) {
  return creatives.reduce((sum, c) => {
    return (
      sum + (
        c.headline.filter(Boolean).length *
          c.body.filter(Boolean).length *
          c.media.filter(Boolean).length *
          c.destination_url.filter(Boolean).length
      )
    )
  }, 0)
}

export function getVariationItemIndexByType(variation, type) {
  if (![4, 5].includes(variation.length)) {
    throw new Error('Invalid variation format')
  }

  switch (type) {
    case 'headline':
      return variation[0]
    case 'body':
      return variation[1]
    case 'media':
      return variation.length === 4 ? variation[2] : variation[2] + variation[3]
    case 'destination_url':
      return variation.length === 4 ? variation[3] : variation[4]
    default:
      throw new Error('Invalid type')
  }
}

export function getIndexInVariationByType(variation, type) {
  if (![4, 5].includes(variation.length)) {
    throw new Error('Invalid variation format')
  }

  switch (type) {
    case 'headline':
      return 0
    case 'body':
      return 1
    case 'media':
      return 2
    case 'destination_url':
      return variation.length === 4 ? 3 : 4
    default:
      throw new Error('Invalid type')
  }
}

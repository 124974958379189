import { api } from './_init'
import { LOADING, UPDATE, FAIL } from '../reducers/default'

export const fetchStatistics = async (dispatch) => {
  dispatch({ type: LOADING })
  try {
    const response = await api.get('/campaigns/statistics')
    dispatch({
      type: UPDATE,
      payload: {
        total: response.data.total,
        open: response.data.open,
        done: response.data.closed,
      },
    })
  } catch (error) {
    dispatch({ type: FAIL })
  }
}

export const initActiveCampaigns = {
  active_events: 0,
  active_campaigns: 0,
  total_spent: {
    amount: 0,
    currency: '',
  },
  total_spent_currency: '',
  total_purchases: 0,
  total_revenue: {
    amount: 0,
    currency: '',
  },
  roas: 0,
}

export const fetchActiveCampaigns = async (dispatch) => {
  dispatch({ type: LOADING })
  try {
    const responseStats = await api.get('/campaigns/fb/stats')

    const { total_active_adsets, currency, total_active_campaigns, sum_total_spend, purchases, revenue, roas } =
      responseStats.data

    dispatch({
      type: UPDATE,
      payload: {
        active_events: total_active_campaigns ? total_active_campaigns : 0,
        active_campaigns: total_active_adsets ? total_active_adsets : 0,
        total_spent: {
          amount: sum_total_spend ? sum_total_spend : 0,
          currency: currency ? currency : 'EUR',
        },
        total_purchases: purchases ? purchases : 0,
        total_revenue: {
          amount: revenue ? revenue : 0,
          currency: currency ? currency : 'EUR',
        },
        roas: roas,
      },
    })
  } catch (error) {
    dispatch({ type: FAIL })
  }
}

import { useCallback, useEffect, useReducer, useRef, useState } from 'react'

import { fetchCampaigns } from 'api/campaigns'
import { arrayReducer, initStateArray } from 'reducers/default'
import { usePagedList } from './usePagedList'
import { isEqual } from 'lodash'

export function useFetchCampaigns(event) {
  const [seriesCampaigns, dispatchSeriesCampaigns] = useReducer(arrayReducer, initStateArray)
  const [allSeriesCampaigns, setAllSeriesCampaigns] = useState([])
  const ref = useRef(null)

  const fetchCampaignsPage = useCallback((dispatch, eventId, page, controller) => {
    fetchCampaigns(dispatch, eventId, page, null, controller)
  }, [])

  useEffect(() => {
    setAllSeriesCampaigns([])
  }, [event.id])

  useEffect(() => {
    const controller = new AbortController()
    const seriesCampaignEventIds = event?.campaign_event_series ?? [event.id]
    const isUpdated = seriesCampaignEventIds.indexOf(event.id) > -1

    const getEventSeriesCampaigns = async () => {
      if (isUpdated && !isEqual(ref.current, seriesCampaignEventIds))
        await Promise.all(
          seriesCampaignEventIds.map((id) => {
            fetchCampaignsPage(dispatchSeriesCampaigns, id, 1, controller)
            return null
          }),
        )
      ref.current = seriesCampaignEventIds
    }

    getEventSeriesCampaigns()

    return () => controller.abort()
  }, [event, fetchCampaignsPage])

  useEffect(() => {
    if (seriesCampaigns.items?.length) {
      setAllSeriesCampaigns((prev) => {
        let uniqueCIDs = new Set([])
        let allCampaigns = new Set(prev)

        for (let i = 0; i < prev.length; i++) {
          uniqueCIDs.add(prev[i].cid)
        }

        for (let i = 0; i < seriesCampaigns.items?.length; i++) {
          const item = seriesCampaigns.items[i]
          if (!uniqueCIDs.has(item.cid)) allCampaigns.add(item)
        }
        return [...allCampaigns]
      })
    }
  }, [seriesCampaigns])

  const { list, refetch, page } = usePagedList(fetchCampaignsPage, {
    params: event.id,
    containerSelector: '#campaigns-container',
  })

  return { list, refetch, page, eventSeriesCampaigns: allSeriesCampaigns }
}

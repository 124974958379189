import React, { useEffect, useRef, useState } from 'react'
import PropTypes, { object } from 'prop-types'
import { Card } from 'react-bootstrap'
import classNames from 'classnames'
import { escapeRegExp, throttle } from 'lodash'

import Searchbar from 'components/Shared/Searchbar'
import { SecondaryButton } from 'components/Shared/Button'
import { useTranslation } from 'react-i18next'
import { RemoveIcon } from 'images'
import useOnClickOutside from 'hooks/UseOnClickOutside'
import LoadingSpinner from 'components/Shared/LoadingSpinner'

const Row = ({ item, onChange }) => {
  return (
    <div key={item.id} className="search-dropdown_dropdown_results_item" onClick={() => onChange(item)}>
      <span>{item.name}</span>
    </div>
  )
}

Row.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
}

const TryAgain = ({ error, loading, onClick, t }) => {
  return (
    <div className="search-dropdown_try-again">
      {loading ? (
        <LoadingSpinner title={t('Recommendations.campaign.metaFetchLoading')} />
      ) : (
        <span className="error">{t(error)}</span>
      )}
      <SecondaryButton
        onClick={(e) => {
          e.preventDefault()
          onClick()
        }}
        size="small"
        text={t('common.tryAgain')}
        color="navy"
        disabled={loading}
        fullWidth={false}
      />
    </div>
  )
}

TryAgain.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  t: PropTypes.func,
  onClick: PropTypes.func,
}

const SearchDropdown = ({
  defaultValue = '',
  value = null,
  onChange = () => {},
  placeholder = 'Search',
  options = [],
  disabled = false,
  removeSelectedItem = () => {},
  onErrorBtnClick = () => {},
  loading = false,
  error = null,
  dropdownClasses = '',
}) => {
  const dropdownRef = useRef()
  const accordionRef = useRef()
  const dropdownListRef = useRef(null)
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const { t } = useTranslation()

  let filteredItems = options.filter((option) => {
    const matchSearchTerm = searchTerm
      ? option.name.toLowerCase().search(escapeRegExp(searchTerm?.toLowerCase())) > -1
      : true
    const matchSelectedItem = value?.name
      ? option.name.toLowerCase().search(escapeRegExp(value?.name.toLowerCase())) === -1
      : true

    return matchSearchTerm && matchSelectedItem
  })

  const showDefaultValue = defaultValue && value?.name && value?.name !== defaultValue

  useOnClickOutside(dropdownRef, () => setDropdownOpen(false))

  useEffect(() => {
    const sideDrawerComponent = document.getElementById('side-drawer-content')

    const handleClick = () => {
      const expectedHeight =
        sideDrawerComponent.offsetHeight - accordionRef.current?.getBoundingClientRect().bottom - 100

      if (dropdownListRef.current) {
        dropdownListRef.current.style.maxHeight = expectedHeight < 160 ? '160px' : expectedHeight + 'px'
        dropdownListRef.current.style.top = accordionRef.current?.offsetHeight
          ? accordionRef.current?.offsetHeight + 'px'
          : '40px'
      }
    }

    const throttledClick = throttle(handleClick, 200)

    if (sideDrawerComponent) {
      throttledClick()
      sideDrawerComponent.addEventListener('click', throttledClick)
    }

    return () => {
      if (sideDrawerComponent) sideDrawerComponent.removeEventListener('click', throttledClick)
    }
  }, [isDropdownOpen, dropdownListRef, accordionRef])

  const handleSelect = (item) => {
    onChange(item)
    setDropdownOpen(false)
  }

  const handleRemoveSelectedItem = (e) => {
    e.stopPropagation()
    removeSelectedItem(e, value?.id)
    onChange({})
  }

  const optionsAvailable = options.length || error || loading
  const showResults = filteredItems.length || error || loading

  return (
    <div className="search-dropdown" ref={dropdownRef}>
      <div ref={accordionRef} className="accordion">
        <Card
          className={classNames({
            disabled_single: disabled,
          })}
        >
          <Card.Header as={Card.Header} onClick={() => setDropdownOpen((prev) => !prev)} className="text-left">
            <div className="search-dropdown_title">
              {showDefaultValue ? (
                <div className="search-dropdown_title_selected">
                  <div className="search-dropdown_title_selected_text">
                    <span className="audience-name">{value.name}</span>
                  </div>
                </div>
              ) : (
                defaultValue
              )}
              <div className="d-flex gap-3">
                {!disabled && showDefaultValue && (
                  <span className="remove">
                    <RemoveIcon width={14} onClick={(e) => handleRemoveSelectedItem(e)} />
                  </span>
                )}
                {!disabled && (
                  <div className="align-self-center">
                    <div className={`accordion-arrow ${isDropdownOpen ? 'open' : ''}`} />
                  </div>
                )}
              </div>
            </div>
          </Card.Header>
        </Card>
      </div>
      {isDropdownOpen && !disabled && (
        <div className={`search-dropdown_dropdown ${dropdownClasses}`} ref={dropdownListRef}>
          {optionsAvailable ? (
            <div>
              <div className="search-dropdown_dropdown_searchbar">
                <Searchbar
                  handleChange={setSearchTerm}
                  placeholder={placeholder}
                  ariaLabel={placeholder}
                  postfixView={<i className="icon fas fa-search" style={{ color: '#ccc' }}></i>}
                  defaultValue={searchTerm}
                />
              </div>
              <div className="search-dropdown_dropdown_results">
                {showDefaultValue && (
                  <div
                    className="search-dropdown_dropdown_results_item"
                    onClick={() => handleSelect({ name: defaultValue })}
                  >
                    <span className="search-dropdown_dropdown_results_item_recommended">{defaultValue}</span>
                  </div>
                )}
                {(loading || error) && <TryAgain loading={loading} error={error} t={t} onClick={onErrorBtnClick} />}
                {showResults ? (
                  filteredItems.map((item) => {
                    return <Row key={item.id} item={item} onChange={handleSelect} />
                  })
                ) : (
                  <div className="select-ad-platform_select-menu_drop-menu_row_wrapper">
                    <div style={{ color: '#5C5C5C', width: '100%', textAlign: 'center' }}>
                      {t('common.noSearchResults')}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="select-ad-platform_select-menu_drop-menu_row_wrapper">
              <div style={{ color: '#5C5C5C', width: '100%', textAlign: 'center' }}>{t('common.noMoreFields')}</div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

SearchDropdown.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.object,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(object),
  onChange: PropTypes.func,
  onErrorBtnClick: PropTypes.func,
  removeSelectedItem: PropTypes.func,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  dropdownClasses: PropTypes.string,
}

export default SearchDropdown

import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cln from 'classnames'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'

import Notice from 'components/Shared/Notice'

/**
 * Campaign budget input.
 * @Tags( recommendations, campaigns )
 */
export default function CampaignBudget({
  selectedBudget,
  onChangeQueued,
  onBudgetChanged,
  recommendedMinimumBudget = 400,
  minimumBudget = 400,
  maximumBudget = 10000,
  addressedTasteClusters = 0,
  currency,
  children,
}) {
  const { t } = useTranslation()
  const [currentBudget, setCurrentBudget] = useState(selectedBudget)
  const [lowerBoundAnimated, setLowerBoundAnimated] = useState(false)

  const userCurrency = localStorage.getItem('userCurrency') || currency || ''
  useEffect(() => {
    setCurrentBudget(selectedBudget)
  }, [selectedBudget])

  // numberOfIntervals must be at least 3
  const arrayOfBudgetIntervals = (numberOfIntervals) => {
    const delta = maximumBudget - recommendedMinimumBudget
    const intervalDelta = Math.round(delta / (numberOfIntervals - 1))

    const intervals = [recommendedMinimumBudget]

    for (let i = 0; i < numberOfIntervals - 2; i++) {
      intervals.push(intervals[i] + intervalDelta)
    }

    intervals.push(maximumBudget)

    return intervals
  }

  const debouncedOnBudgetChanged = useCallback(
    debounce((newBudget) => {
      if (newBudget < minimumBudget) {
        setCurrentBudget(minimumBudget)
        onBudgetChanged(minimumBudget)
      } else {
        setCurrentBudget(newBudget)
        onBudgetChanged(newBudget)
      }
    }, 1500),
    [onBudgetChanged, minimumBudget],
  )
  
  useEffect(() => {
    if (currentBudget < minimumBudget)
      debouncedOnBudgetChanged(Math.max(minimumBudget, currentBudget))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumBudget])

  useEffect(() => {
    if (currentBudget < recommendedMinimumBudget && currentBudget >= minimumBudget) {
      setLowerBoundAnimated(true)
    }

    const timeoutId = setTimeout(() => {
      setLowerBoundAnimated(false)
    }, 1500)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [currentBudget, recommendedMinimumBudget, minimumBudget])

  const updateBudget = (value) => {
    const budget = value || ''
    const sanitizedValue = budget.replace(/[^\d]/g, '')
    const number = sanitizedValue ? parseInt(sanitizedValue) : ''
    setCurrentBudget(number)
    onChangeQueued()
    debouncedOnBudgetChanged(number)
  }

  const leftStyle =
    currentBudget >= recommendedMinimumBudget && currentBudget <= maximumBudget
      ? ((currentBudget - recommendedMinimumBudget) / (maximumBudget - recommendedMinimumBudget)) * 100
      : currentBudget >= maximumBudget
        ? 100
        : 0

  const locale = localStorage.getItem('locale')
  const numberFormat = new Intl.NumberFormat(locale)
  const showText = currentBudget || currentBudget

  return (
    <div className="h-100 d-flex flex-column">
      {children}
      <div
        style={{ background: 'white', border: '1px solid rgba(0, 0, 0, 0.08)' }}
        className="budget rounded px-4 py-2 event-header-card h-100"
      >
        {/* TODO: move into its own UI component */}
        <div className="budget_slider d-flex flex-column align-items-center justify-content-between mt-3">
          <div className="budget_slider_text-track">
            <div
              className={cln('budget_slider_text', {
                'budget_slider_text--fixed-left': currentBudget < recommendedMinimumBudget,
                'budget_slider_text--fixed-right': currentBudget > maximumBudget,
              })}
              style={{ left: `calc(${leftStyle}% - 65px)` }}
            >
              {/* FIXME: this is a terrible user experience */}
              {/* We should make the numbers an input field so that users dont have to use the slider if they don't want to*/}
              {showText && (
                <>
                  <span className="budget_slider_text__value">{numberFormat.format(currentBudget)}</span>
                  &nbsp;{userCurrency}
                </>
              )}
            </div>
          </div>
          <div className="budget_slider__inner">
            <div
              className={cln('budget_slider__bound', 'budget_slider__bound--lower', {
                active: currentBudget < recommendedMinimumBudget,
                animated: lowerBoundAnimated,
              })}
            />
            <input
              className={cln('budget_slider__input', {
                'budget_slider__input--out-of-bounds': currentBudget < recommendedMinimumBudget || currentBudget > maximumBudget,
                'budget_slider__input--filled': currentBudget > maximumBudget,
              })}
              type="range"
              min={recommendedMinimumBudget}
              max={maximumBudget}
              style={{
                background:
                  'linear-gradient(to right, #F58562 0%, #F58562 ' +
                  ((currentBudget - recommendedMinimumBudget) / (maximumBudget - recommendedMinimumBudget)) * 100 +
                  '%, #fff ' +
                  ((currentBudget - recommendedMinimumBudget) / (maximumBudget - recommendedMinimumBudget)) * 100 +
                  '%, white 100%)',
              }}
              onChange={(e) => updateBudget(e.target.value)}
              value={currentBudget}
            />
            <div
              className={cln('budget_slider__bound', 'budget_slider__bound--upper', {
                active: currentBudget > maximumBudget,
              })}
            />
          </div>
          {/* The `calc(100% + 35px)` width is in function of the `width: 50px` width of the individual interval ticks */}
          <div className="budget_slider__ticks">
            {arrayOfBudgetIntervals(5).map((intervalBudget, index) => {
              return (
                <div key={index} className="d-flex flex-column align-items-center" style={{ width: '45px' }}>
                  <div style={{ height: '10px', borderRight: '1px solid #C1C5D3' }}></div>
                  <div style={{ fontWeight: '500' }} className="mt-2">
                    {numberFormat.format(intervalBudget)}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="budget_textual">
          <div style={{ minWidth: '55%' }}>
            <div className="budget_textual__input">
              <span>Budget:</span>
              <input
                type="text"
                inputMode="numeric"
                value={currentBudget ? numberFormat.format(currentBudget) : ''}
                onChange={(e) => updateBudget(e.target.value)}
                className="budget_input"
              />
              <div>{userCurrency}</div>
            </div>
            <div className="mt-3">
              <span style={{ color: '#544141' }} className="mr-1">
                {t('Recommendations.campaignBudget.addressedTasteClustersLabel.firstPart')}
              </span>
              <strong style={{ fontSize: '1rem', lineHeight: '1.7rem', fontWeight: '700' }}>
                {addressedTasteClusters}
              </strong>{' '}
              <span style={{ color: '#544141' }} className="mr-1">
                {t('Recommendations.campaignBudget.addressedTasteClustersLabel.secondPart')}
              </span>
            </div>
          </div>
          <Notice
            message={t('Recommendations.campaignBudget.rangeNotice')}
            isVisible={currentBudget < recommendedMinimumBudget || currentBudget > maximumBudget}
            className="budget_slider__notice"
          />
        </div>
      </div>
    </div>
  )
}

CampaignBudget.propTypes = {
  selectedBudget: PropTypes.number,
  onChangeQueued: PropTypes.func.isRequired,
  onBudgetChanged: PropTypes.func.isRequired,
  recommendedMinimumBudget: PropTypes.number,
  minimumBudget: PropTypes.number,
  maximumBudget: PropTypes.number,
  addressedTasteClusters: PropTypes.number,
  currency: PropTypes.string,
  children: PropTypes.node,
}

import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'

import Layout from '../components/Layout'
import Header from '../components/Authentication/Header'
import FormHeader from '../components/Authentication/FormHeader'
import Footer from '../components/Authentication/Footer'

import ResetPasswordForm from '../components/Cognito/ResetPasswordForm'

import bgImg from '../images/forgot-desktop-bg.png'

/**
 * "Forgot your password?" - page template.
 * It displays form to reset the recovery the password.
 * @Tags( page, layout, unlogged-users )
 * @SuggestedTags( cognito )
 * @Routes( /forgot-password )
 */
const ForgotPassword = () => {
  const { t } = useTranslation()
  const [subtitle, setSubtitle] = useState(t('ForgotPassword.form.subtitle'))

  useEffect(() => {
    setSubtitle(t('ForgotPassword.form.subtitle'))
  }, [t])

  return (
    <Layout title={t('ForgotPassword.PageTitle')}>
      <Helmet bodyAttributes={{ class: 'authentication-page forgot-password-page' }} />
      <div>
        <Header backTo="/login" />
        <main data-cy="forgot-password-form-cy">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg">
                <div className="forgot-password-content">
                  <FormHeader title={t('ForgotPassword.form.title')} subtitle={subtitle} />
                  <ResetPasswordForm setSubtitle={setSubtitle} />
                  <Footer />
                </div>
              </div>
              <div
                className="col-lg-auto d-none d-lg-block px-lg-0 image-col"
                style={{ backgroundImage: `url('${bgImg}')` }}
              ></div>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  )
}

export default ForgotPassword

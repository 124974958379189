export const ticketTypes = Object.freeze([
  'subscription',
  'regular',
  'regular_discount',
  'discount',
  'cancelled',
  'free',
  'reserved',
])

export const ticketTypesDisplayNames = Object.freeze([
  'Subscription',
  'Regular',
  'Regular discount',
  'Discount',
  'Cancelled',
  'Free',
  'Reserved',
])

export const ticketTypesOptions = ticketTypes.map((value) => ({
  value,
  text: `Event.ticketType.${value}`
}))

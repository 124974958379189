import React, { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory } from 'react-router-dom'

import Tabs from '../../Shared/Tabs'
import EventInsights from './EventInsights'
import EventStatistics from './EventStatistics'
import EventHeader from './EventHeader'
import EventHeaderMenuWrapper from './EventHeader/EventHeaderMenuWrapper'
import { SalesEditor, salesEditMode as SalesEditMode } from '../SalesEdit'

import { useToast } from 'components/Shared/AppToast/ToastProvider'
import { getEventSeries, updateEventStatus } from 'api/events'
import useIsFirstRender from 'hooks/useFirstRender'
import { CampaignStatsContext } from './EventStatsProvider'
import { useFetchCampaigns } from 'hooks/useFetchCampaigns'
import PortalModal from 'components/Shared/PortalModal'
import ArchivePrompt from 'components/Recommendations/CampaignStatusModals/ArchivePrompt'

export default function EventDetails({ event, eventSeries = {}, dispatchEventSeries = () => {} }) {
  const { t } = useTranslation()
  const history = useHistory()
  const { addNotification } = useToast()

  // handles tabs for Demand Prediction side drawer
  const [currentTab, setCurrentTab] = useState(0)
  const [compMounted, setCompMounted] = useState(false)
  const [salesEditMode, setSalesEditMode] = useState(null)
  const hasCampaignStats = useContext(CampaignStatsContext)
  const firstRender = useIsFirstRender()
  const [frontendStatus, setFrontendStatus] = useState(event.frontend_status)
  const [isArchivePromptOpen, setIsArchivePromptOpen] = useState(false)

  const { eventSeriesCampaigns } = useFetchCampaigns(event)

  // looks at the url search params to see if tab is included
  // uses it if it is else ignore and use the default tab
  useEffect(() => {
    if (!compMounted) {
      const urlParams = new URL(window.location.href)
      const tab = urlParams.searchParams.get('tab') || 0

      setCurrentTab(tab)
      setCompMounted(true)
    }
  }, [setCurrentTab, setCompMounted, compMounted])

  useEffect(() => {
    if (event && !frontendStatus && event.frontend_status !== frontendStatus) {
      setFrontendStatus(event.frontend_status)
    }
  }, [event, frontendStatus])

  const handleTabChange = (idx) => {
    if (!hasCampaignStats && idx === 1) return

    setCurrentTab(idx)
    if (window.innerWidth >= 991) {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('tab', idx)
      window.history.pushState(null, '', window.location.pathname + '?' + searchParams.toString())
    } else {
      const newUrl = `${window.location.origin}/events/${event.id}/?tab=${idx}`
      window.history.pushState({ path: newUrl }, '', newUrl)
    }
  }

  // listens for breakpoints desktop => mobile && vice versa
  const [viewWidth, setViewWidth] = useState(window.innerWidth)
  const handleResize = () => setViewWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (event.id) {
      getEventSeries(dispatchEventSeries, event.id)

      if (hasCampaignStats === false && currentTab === '1') setCurrentTab(0)
    }
  }, [event.id, currentTab, hasCampaignStats, dispatchEventSeries])

  useEffect(() => {
    if (!firstRender) return

    const urlParams = new URL(window.location.href)
    const updateSales = urlParams.searchParams.get('update-sales') || ''

    switch (updateSales.toUpperCase()) {
      case SalesEditMode.salesSummary:
        setSalesEditMode(SalesEditMode.salesSummary)
        break
      case SalesEditMode.documentUpload:
        setSalesEditMode(SalesEditMode.documentUpload)
        break
      default:
        setSalesEditMode(null)
    }
  }, [firstRender])

  const onClose = useCallback(() => {
    setSalesEditMode(null)
  }, [setSalesEditMode])

  const archiveEvent = useCallback(() => {
    updateEventStatus(event.id, 'HIDDEN')
      .then((result) => {
        if (result.success) {
          setFrontendStatus('HIDDEN')
          setIsArchivePromptOpen(false)
          history.push(window.location.pathname)
        } else {
          console.error('Failed to update frontent status')
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [event.id, history])

  // if mobile, redirect user to full screen views
  if (viewWidth < 991) {
    const urlParams = new URL(window.location.href)
    const drawerOpen = urlParams.searchParams.get('drawer-open')
    const updateSales = urlParams.searchParams.get('update-sales')

    if (!event?.id) return <Redirect to="/events" />

    if (drawerOpen === 'true' && updateSales) return <Redirect to={`/events/${event.id}/update-sales/${updateSales}`} />
    if (drawerOpen === 'true') return <Redirect to={`/events/${event.id}`} />
  }

  // for lookout side drawer we need 2 operational tabs
  // the Tabs HOC takes the below array of values that manages its own state

  // for editing sales / event editing we switch between
  let menuOptions = [
    {
      title: t('Event.edit'),
      clickHandler: () => history.replace(`/events/edit/${event.id}`),
      optionType: 'button',
    },
    {
      title: t('Event.dotsMenu.addSales'),
      optionType: 'expandableItem',
      entries: [
        {
          title: t('Event.dotsMenu.manualUpload'),
          onClick: () => {
            setSalesEditMode(SalesEditMode.salesSummary)
            history.push(`/events?event-id=${event.id}&drawer-open=true&update-sales=${SalesEditMode.salesSummary}`)
          },
        },
        {
          title: t('Event.dotsMenu.fileUpload'),
          onClick: () => {
            setSalesEditMode(SalesEditMode.documentUpload)
            history.push(`/events?event-id=${event.id}&drawer-open=true&update-sales=${SalesEditMode.documentUpload}`)
          },
        },
      ],
    },
  ]

  if (event.frontend_status !== 'HIDDEN') {
    menuOptions = [
      ...menuOptions,
      {
        title: t('Recommendations.campaignOptions.eventFlagged'),
        onClick: () => {
          const newStatus = frontendStatus === 'FLAGGED' ? 'DEFAULT' : 'FLAGGED'
          updateEventStatus(event.id, newStatus)
            .then((result) => {
              if (result.success) {
                setFrontendStatus(newStatus)
              } else {
                console.error('Failed to update frontent status')
              }
            })
            .catch((error) => {
              console.error(error)
            })
        },
        optionType: 'switch',
        checked: frontendStatus === 'FLAGGED',
      },
      { optionType: 'separator' },
      {
        title: t('Recommendations.campaignOptions.eventArchive'),
        clickHandler: () => setIsArchivePromptOpen(true),
        optionType: 'button',
        variant: 'danger',
      },
    ]
  }

  const tabsData = [
    {
      // Insights tab
      title: t('Event.mobileTabs.details'),
      component: <EventInsights event={event} />,
    },
    {
      // Event Facebook Campaign Stats tab
      title: t('Event.mobileTabs.campaigns'),
      disableTab: !hasCampaignStats,
      component: (
        <div>
          <EventStatistics event={event} eventSeries={eventSeries} campaigns={eventSeriesCampaigns} />
        </div>
      ),
    },
  ]

  return (
    <>
      <EventHeader
        classes="event-header_recommendation-drawer-padding"
        event={event}
        renderMenu={() => {
          if (salesEditMode) return null
          if (menuOptions.length === 0) return null

          return <EventHeaderMenuWrapper options={menuOptions} />
        }}
      />
      {salesEditMode ? (
        <SalesEditor
          eventId={event.id}
          eventDate={event.date}
          onSuccess={() => {
            setSalesEditMode(null)
            addNotification({
              message: t('Toasts.Sales.salesUploaded.message'),
              description: t('Toasts.Sales.salesUploaded.description'),
            })
          }}
          onClose={onClose}
          mode={salesEditMode}
        />
      ) : (
        <Tabs tabsData={tabsData} currentTab={currentTab} handleTabChange={handleTabChange} />
      )}
      <PortalModal
        isOpen={isArchivePromptOpen}
        onClickOutside={() => setIsArchivePromptOpen(false)}
      >
        <ArchivePrompt
          eventTitle={event.title}
          onCancel={() => setIsArchivePromptOpen(false)}
          onConfirm={archiveEvent}
        />
      </PortalModal>
    </>
  )
}

EventDetails.propTypes = {
  event: PropTypes.object.isRequired,
  dispatchEventSeries: PropTypes.func,
  eventSeries: PropTypes.object,
}

import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Layout from '../components/Layout'

import FormWrapper from '../components/EventEditor/EventFormWrapper'

const Editor = ({ match }) => {
  const { eid } = match.params
  const { t } = useTranslation()

  return (
    <Layout
      loggedIn
      title={t('EventEditor.PageTitle')}
      mobileTopBar={{ back: true, centerTitle: t('EventEditor.MobileTitle') }}
    >
      <Helmet
        bodyAttributes={{
          class: 'simulation-page',
        }}
      />
      <main>
        <div className="container-fluid hide-scroll-bar">
          <div>
            <h1 className="main-title hide-mobile">{t('EventEditor.MainTitle')}</h1>

            <div>
              <FormWrapper eid={eid} />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

Editor.propTypes = {
  match: PropTypes.object.isRequired,
}

export default Editor

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { SecondaryButton, TransparentBgButton } from 'components/Shared/Button'
import PortalModal from 'components/Shared/PortalModal'

export function Prompt({
  isOpen,
  forUnmatched,
  forUnmatchedWithNoExistingPostalCodes,
  forDeleted,
  forClearing,
  onYes,
  onNo,
}) {
  const { t } = useTranslation()

  const title = useMemo(() => {
    if (forUnmatched) {
      return t('Recommendations.geoTargeting.postalCodes.matching.unsavedChangesPrompt.unmatchedTitle')
    }

    if (forDeleted) {
      return t('Recommendations.geoTargeting.postalCodes.matching.unsavedChangesPrompt.deletedTitle')
    }

    if (forClearing) {
      return t('Recommendations.geoTargeting.postalCodes.matching.unsavedChangesPrompt.clearedTitle')
    }

    return ''
  }, [forUnmatched, forDeleted, forClearing, t])

  const description = useMemo(() => {
    if (forUnmatchedWithNoExistingPostalCodes) {
      return t(
        'Recommendations.geoTargeting.postalCodes.matching.unsavedChangesPrompt.unmatchedDescriptionWithNoExistingZips',
      )
    } else if (forUnmatched) {
      return t('Recommendations.geoTargeting.postalCodes.matching.unsavedChangesPrompt.unmatchedDescription')
    }

    if (forDeleted) {
      return t('Recommendations.geoTargeting.postalCodes.matching.unsavedChangesPrompt.deletedDescription')
    }

    if (forClearing) {
      return t('Recommendations.geoTargeting.postalCodes.matching.unsavedChangesPrompt.clearedDescription')
    }
  }, [forUnmatched, forDeleted, forClearing, t, forUnmatchedWithNoExistingPostalCodes])

  const cancel = useMemo(() => {
    if (forUnmatchedWithNoExistingPostalCodes) {
      return t('Recommendations.geoTargeting.postalCodes.matching.unsavedChangesPrompt.cancelRemove')
    } else if (forUnmatched) {
      return t('common.cancel')
    }

    if (forDeleted || forClearing) {
      return t('Recommendations.geoTargeting.postalCodes.matching.unsavedChangesPrompt.cancelDelete')
    }
  }, [t, forUnmatched, forUnmatchedWithNoExistingPostalCodes, forDeleted, forClearing])

  const proceed = useMemo(() => {
    if (forUnmatchedWithNoExistingPostalCodes) {
      return t('Recommendations.geoTargeting.postalCodes.matching.unsavedChangesPrompt.proceedRemove')
    } else if (forUnmatched) {
      return t('common.yesProceed')
    }

    if (forDeleted || forClearing) {
      return t('Recommendations.geoTargeting.postalCodes.matching.unsavedChangesPrompt.proceedDelete')
    }
  }, [t, forUnmatched, forUnmatchedWithNoExistingPostalCodes, forDeleted, forClearing])

  return (
    <PortalModal isOpen={isOpen} onClickOutside={onNo}>
      <div className="content compact text-center" style={{ maxWidth: '420px' }}>
        <h1 className="autofill_warning-header">{title}</h1>
        <p className="autofill_warning-msg">{description}</p>
        <div className="footer">
          <TransparentBgButton text={cancel} onClick={onNo} color="navy" />
          <SecondaryButton color="orange" text={proceed} onClick={onYes} type="button" disabledTitle="" />
        </div>
      </div>
    </PortalModal>
  )
}
Prompt.propTypes = {
  isOpen: PropTypes.bool,
  forUnmatched: PropTypes.bool,
  forUnmatchedWithNoExistingPostalCodes: PropTypes.bool,
  forDeleted: PropTypes.bool,
  forClearing: PropTypes.bool,
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
}

import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'

import Layout from 'components/Layout'

import NotificationDetails from 'components/Notifications/NotificationDetails'

/**
 * Default page for notification details.
 * @Tags( page, notifications, layout, logged-users )
 * @SuggestedTags( notification-details )
 * @Routes( /notifications/:id )
 */
export default function Notification() {
  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams()

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991) {
        history.push(`/notifications/?drawer-open=true&notification-id=${id}`)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [history, id])

  return (
    <Layout
      loggedIn
      title={t('Notification.PageTitle')}
      mobileTopBar={{
        back: true,
        centerTitle: t('Notifications.MainTitle'),
        hideRight: true,
        addHistoryRoute: '/notifications/',
        historyCallback: () => {},
      }}
    >
      <Helmet
        bodyAttributes={{
          class: 'notification-page',
        }}
      />
      <main>
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col-12 col-lg-10">
              <NotificationDetails notificationId={id} />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

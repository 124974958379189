import { uniqBy } from 'utils/helpers'

export const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
  }),
  input: (provided) => ({
    ...provided,
    width: '100%',
    backgroundColor: 'transparent',
  }),
}

export const createSelectOption = (label) => ({
  label,
  value: label,
})

export const optionToValue = (option) => (typeof option === 'object' && option.value ? option.value : option)

export const dedupIds = (ids) => uniqBy(ids, 'value')

import PropTypes from 'prop-types'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'

import useHasPermission, { hasPrismaAccessTier } from 'hooks/useHasPermission'

import i18n from '../../i18n'

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route)

/**
 * Wrapper for default React route because pages - dashboard, recommendations and others are available only for logged in users here we decide if the have access or should be redirected to login page.
 * @Tags( presentional )
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((store) => store.user)
  const { path, permissionName, needsUpdate, partnerId } = rest
  const hasPermission = useHasPermission(permissionName)
  const hasPrismaAccess = hasPrismaAccessTier()

  if (needsUpdate) {
    return <Redirect to={`/update-user/${partnerId}`} />
  }

  if (!user.isLogged || !user.accessLevel || !user.partnerId)
    return <Redirect to={{ pathname: '/login' }} />

  if (user.isLogged && !hasPermission) {
    return (
      <SentryRoute
        render={(props) => (
          <Redirect
            to={{ pathname: path === '/' ? '/login' : '/', state: { from: props.location } }}
          />
        )}
      />
    )
  }

  // add account emails for features in './utils/flage-features' to white list emails to features in PRD
  if (hasPermission) {
    return (
      <I18nextProvider i18n={i18n} defaultNS={hasPrismaAccess ? 'translation.prisma' : 'translation'}>
        <SentryRoute
          {...rest}
          render={(props) =>
            user.isLogged ? (
              <Component {...props} {...rest} />
            ) : (
              <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
          }
        />
      </I18nextProvider>
    )
  }

  return (
    <SentryRoute
      render={(props) => <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
    />
  )
}

PrivateRoute.propTypes = {
  /**
   * PageComponent
   * */
  component: PropTypes.elementType.isRequired,
  location: PropTypes.object,
}

export default PrivateRoute

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import EventBenchmarks from './EventBenchmarks'
import SalesTrendChart from './SalesTrendChart'
import CapacityPrompt from './CapacityPrompt'
import TicketCategories from './TicketCategories'
import TicketTypeChart from './TicketTypeChart'
import { TransactionsChart } from './TransactionsChart'
import InfoBox from 'components/Shared/InfoBox'

export default function EventInsights({ event }) {
  const { t } = useTranslation()
  const showCapacityPrompt = event.capacity === 0
  const [showTicketCategories, setShowTicketCategories] = useState(true)
  const [showTicketType, setShowTicketType] = useState(true)
  const [showTransactionGraph, setShowTransactionGraph] = useState(false)

  if (!event.id) return null

  return (
    <>
      <div className="events-insights-page_cta_row gap-2">
        <Link
          to={`/campaigns/?drawer-open=true&event-id=${event.id}`}
          style={{ minWidth: '255px' }}
          className="bt bt-orange s-38 mt-2 w-100 w-md-100"
        >
          <span>{t('Event.btnCampaignRecommendations')}</span>
        </Link>
      </div>
      <div className="tabs-component_main-content_section">
        <InfoBox content="Tips.Events.SalesTrend">
          <h2 className="card-title-default d-inline-block m-0">{t('Tips.Events.SalesTrend.title')}</h2>
        </InfoBox>
        <div>
          <EventBenchmarks eventId={event.id} />
        </div>
        <div className="position-relative">
          <div className="mt-4" style={{ opacity: showCapacityPrompt ? 0.5 : 1 }}>
            <SalesTrendChart
              title={event.title || ''}
              eventId={event.id}
              hideTooltip
              isAppDrawer
              chartTooltipEnabled={!showCapacityPrompt}
            />
          </div>
          {showCapacityPrompt && <CapacityPrompt eventId={event.id} />}
        </div>
      </div>
      <div
        className={`${showTicketCategories || showTicketType ? 'tabs-component_main-content_section' : ''} events-insights-page_page_stats`}
      >
        <TicketCategories
          className="card card-default card-ticket-type events-insights-page_dash_small_card"
          isAppDrawer
          eventId={event.id}
          title={t('Event.cards.ticketCategories')}
          setShowCategories={setShowTicketCategories}
        />
        <TicketTypeChart
          className="card card-default card-ticket-type events-insights-page_dash_small_card"
          isAppDrawer
          eventId={event.id}
          title={t('Event.cards.ticketType')}
          setShowCategories={setShowTicketType}
        />
      </div>

      <div className={`${showTransactionGraph ? 'tabs-component_main-content_section' : ''}`}>
        <TransactionsChart eventId={event.id} setShowGraph={setShowTransactionGraph} />
      </div>
    </>
  )
}

EventInsights.propTypes = {
  event: PropTypes.object.isRequired,
}

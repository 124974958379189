import React, { useState, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'

import { FAIL, initStateObject, objectReducer } from '../../reducers/default'
import { authenticateUser, fetchFeatures, fetchPermissions, fetchUserProfile } from '../../api/auth'

import Modal from '../Shared/Modal'
import { checkIfPasswordIsSecureEnough } from 'utils/helpers'
import LoginSuccess from './LoginSuccess'
import AccountDisabled from './AccountDisabled'

const initialState = {
  email: '',
  password: '',
  newPassword: '',
  confirmPassword: '',
  isLogged: false,
}

/**
 * Login form for the application (connected to Cognito AWS in the Backend).
 * @Tags( cognito, api )
 * @Inputs( email, password )
 * @Endpoints( /auth, /features )
 * @ApiLogic( ../../api/auth )
 */
const Form = ({ state, formStatus, handleSubmit, t, handleChange }) => {
  const { newPassword, confirmPassword } = state
  const [errorModalOpen, setErrorModalOpen] = useState(formStatus.error && formStatus.message === 'error.auth.inactive')

  useEffect(() => {
    if (formStatus.error && formStatus.message === 'error.auth.inactive' && !errorModalOpen) {
      setErrorModalOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStatus])

  return (
    <>
      <form onSubmit={handleSubmit}>
        {formStatus.error && formStatus.message && formStatus.message !== 'error.auth.inactive' && (
          <p data-testid="errorForm" className="alert alert-danger">
            {t(formStatus.message)}
          </p>
        )}
        <div className="form-group">
          <input
            data-cy="password-input-cy"
            type="password"
            className="form-control s-50"
            id="newPassword"
            value={newPassword}
            onChange={(e) => handleChange(e)}
            placeholder={t('common.password')}
          />
        </div>
        <div className="form-group">
          <input
            data-cy="confirm-password-input-cy"
            type="password"
            className="form-control s-50"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => handleChange(e)}
            placeholder={t('common.confirmPassword')}
          />
        </div>
        <button
          data-cy="set-password-submit-button-cy"
          type="submit"
          className="bt bt-navy s-50"
          style={{ width: '100%' }}
          disabled={formStatus.loading}
        >
          {formStatus.loading ? t('SetPassword.form.btnLoading') : t('SetPassword.form.btn')}
        </button>
      </form>
      {errorModalOpen && (
        <Modal
          closeCallback={() => setErrorModalOpen(false)}
          hideCloseBtn
          title={''}
          mainContent={<AccountDisabled closeModal={() => setErrorModalOpen(false)} />}
          fullWidth={false}
          timeoutCloseCallback={() => {}}
        />
      )}
    </>
  )
}

Form.propTypes = {
  state: PropTypes.object,
  formStatus: PropTypes.object,
  handleSubmit: PropTypes.func,
  t: PropTypes.func,
  handleChange: PropTypes.func,
}

const SetPasswordForm = ({ username, temporaryPassword, onSuccess }) => {
  const { t, i18n } = useTranslation()
  const [state, setState] = useState(initialState)
  const [showSplash, setShowSplash] = useState(false)
  const user = useSelector((state) => state.user)
  const [formStatus, setFormStatus] = useReducer(objectReducer, initStateObject)
  const dispatch = useDispatch()
  const { newPassword, confirmPassword } = state

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value })
  }

  const validateForm = () => {
    if (newPassword.length === 0) {
      setFormStatus({ type: FAIL, payload: t('common.errors.fillRequired') })
      return false
    } else if (newPassword !== confirmPassword) {
      setFormStatus({ type: FAIL, payload: t('common.errors.wrongConfirmPassword') })
      return false
    }

    const isPasswordSecureEnough = checkIfPasswordIsSecureEnough(newPassword)
    if (!isPasswordSecureEnough.status) {
      setFormStatus({ type: FAIL, payload: t(isPasswordSecureEnough.message) })
      return false
    }

    return true
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!validateForm()) {
      return
    }

    const sendFormAsync = async () => {
      try {
        authenticateUser(dispatch, { username, password: temporaryPassword, newPassword }, setFormStatus)
      } catch (error) {
        console.log('Login error.')
      }
    }
    sendFormAsync()
  }

  useEffect(() => {
    const fetchFeaturesAndRedirect = async () => {
      await fetchFeatures(dispatch)
      await fetchPermissions(dispatch)
      await fetchUserProfile(dispatch, i18n)

      setTimeout(() => {
        setState((prevState) => {
          return {
            ...prevState,
            isLogged: true,
            accessLevel: user.accessLevel,
          }
        })
      }, 750)
    }

    if (user.isLogged) {
      onSuccess()
      setShowSplash(true)
      fetchFeaturesAndRedirect()
    }
  }, [user.isLogged, user.accessLevel, dispatch, i18n, showSplash, onSuccess])

  return user.isLogged ? (
    <LoginSuccess state={state} showSplash={showSplash} setShowSplash={setShowSplash} />
  ) : (
    <Form state={state} formStatus={formStatus} handleSubmit={handleSubmit} t={t} handleChange={handleChange} />
  )
}

SetPasswordForm.propTypes = {
  username: PropTypes.string.isRequired,
  temporaryPassword: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default SetPasswordForm

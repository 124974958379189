import React, { createContext } from 'react'
import PropTypes from 'prop-types'

import { asyncActionHandlers, initialState, reducer } from './customerSegmentation'
import { useReducerAsync } from 'hooks/useReducerAsync'

export const CustomerSegmentationContext = createContext(initialState())
export const CustomerSegmentationDispatchContext = createContext(null)

export function CustomerSegmentationProvider({ children }) {
  const cachedState = JSON.parse(localStorage.getItem('packageBuilderState'))?.customerSegmentation
  const [state, dispatch] = useReducerAsync(reducer, initialState(cachedState), asyncActionHandlers)

  return (
    <CustomerSegmentationContext.Provider value={state}>
      <CustomerSegmentationDispatchContext.Provider value={dispatch}>
        {children}
      </CustomerSegmentationDispatchContext.Provider>
    </CustomerSegmentationContext.Provider>
  )
}

CustomerSegmentationProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import cn from 'classnames'
import { isEqual } from 'lodash'

import { CustomerSegmentationContext, CustomerSegmentationDispatchContext } from '../CustomerSegmentationContext'
import { Prompt } from './Prompt'
import { actions } from '../customerSegmentation'
import { optionToValue, dedupIds, createSelectOption, customSelectStyles } from './util'
import { FdCreatableSelect } from 'components/Shared/ReactSelect'
import { SecondaryButton } from 'components/Shared/Button'
import Portal from 'components/Shared/Portal'
import Modal from 'components/Shared/Modal'
import LoadingSpinner, { SpinnerSize } from 'components/Shared/LoadingSpinner'

import { GreenTickIcon } from 'images'

export default function CustomerIds({ open, setOpen, onDone, readOnly }) {
  const { t } = useTranslation()
  const selectRef = useRef(null)

  const state = useContext(CustomerSegmentationContext)
  const dispatch = useContext(CustomerSegmentationDispatchContext)
  const [idOptions, setIdOptions] = useState(state.customerIds.value.map(createSelectOption))
  const [inputValue, setInputValue] = useState('')
  const [showPrompt, setShowPrompt] = useState(false)
  const {
    loading: matchingLoading,
    success: matchingSuccess,
    serverError: matchingServerError,
    unmatchedIds,
  } = state.customerIds

  const successfulMatch = useMemo(
    () => matchingSuccess === true && !unmatchedIds.length,
    [matchingSuccess, unmatchedIds.length],
  )
  const partiallyMatched = useMemo(
    () => matchingSuccess === false && unmatchedIds.length > 0,
    [matchingSuccess, unmatchedIds.length],
  )
  const hasMatched = successfulMatch || partiallyMatched
  const isReadonly = readOnly || partiallyMatched

  const resetAndClose = useCallback(
    (matchedIdsOptions, { updateContext } = {}) => {
      setOpen(false)
      setIdOptions(matchedIdsOptions)
      dispatch({
        type: actions.partiallyResetCustomerIds,
        payload: { [updateContext ? 'value' : '']: matchedIdsOptions.map(optionToValue) },
      })
    },
    [setOpen, dispatch],
  )

  const finishSuccessfully = useCallback(() => {
    const matchedIds = idOptions.filter((id) => !unmatchedIds.includes(id.value))
    onDone(matchedIds.map(optionToValue))
    resetAndClose(matchedIds)
  }, [idOptions, unmatchedIds, onDone, resetAndClose])

  const onClose = useCallback(() => {
    if (partiallyMatched) {
      // Force the user to click on the finish button
      return
    }

    const hasUnsavedChanges =
      idOptions.length !== state.customerIds.value.length + state.customerIds.unmatchedIds.length
    if (hasUnsavedChanges) {
      setShowPrompt(true)
      return
    }

    setOpen(false)
  }, [idOptions, state.customerIds, setOpen, partiallyMatched])

  // focus on the input on open
  useEffect(() => {
    const focusInput = () => {
      if (isReadonly) return

      if (selectRef.current) selectRef.current.focus()
      else {
        const input = document.querySelector('#CustomerIds .fd-select__input')
        if (input) input.focus()
      }
    }
    window.addEventListener('focus', focusInput)

    const t = setTimeout(focusInput, 100)

    return () => {
      window.removeEventListener('focus', focusInput)
      clearTimeout(t)
    }
  }, [isReadonly])

  // after a fully successful match, close the modal after 3 second
  useEffect(() => {
    let timeoutId
    if (successfulMatch && !timeoutId) {
      timeoutId = setTimeout(() => {
        finishSuccessfully()
      }, 3000)
    }

    return () => clearTimeout(timeoutId)
  }, [successfulMatch, finishSuccessfully])

  const handleContainerClick = () => {
    if (selectRef.current) selectRef.current.focus()
  }

  const handleKeyDown = (event) => {
    if (isReadonly) return

    /* eslint-disable quotes */
    if (!!inputValue && [',', 'Enter', ' ', ';'].includes(event.key)) {
      setIdOptions((prev) => dedupIds([...prev, createSelectOption(inputValue)]))
      setInputValue('')
      event.preventDefault()
    }
  }

  const handlePaste = (event) => {
    if (isReadonly) return

    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('Text')
    const pastedValues = pastedData.split(/[\s,\n;]+/).filter(Boolean)

    setIdOptions((prev) => dedupIds([...prev, ...pastedValues.map(createSelectOption)]))

    event.preventDefault()
  }

  const handleDownloadUnmatched = (e) => {
    const csv = ['id', ...unmatchedIds].join('\n')
    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
    hiddenElement.target = '_blank'
    hiddenElement.download = 'unmatched_ids.csv'
    hiddenElement.click()
    e.preventDefault()
  }

  const handleClearAll = (e) => {
    setIdOptions([])
    e.preventDefault()
  }

  const handleMatchIds = (e) => {
    dispatch({
      type: actions.matchAsync,
      payload: { ids: idOptions.map(optionToValue) },
    })
    e.preventDefault()
  }

  const handleFinish = (e) => {
    finishSuccessfully()
    e.preventDefault()
  }

  const title = useMemo(() => {
    if (!hasMatched || !partiallyMatched) {
      return t('PackageBuilder.customerSegmentation.customerIds')
    }

    const countMatched = idOptions.length - unmatchedIds.length
    if (countMatched === 0) {
      return t('PackageBuilder.customerSegmentation.customerMatching.nonMatchedTitle')
    }

    return t('PackageBuilder.customerSegmentation.customerMatching.partialSuccessTitle', {
      countMatched,
    })
  }, [partiallyMatched, t, hasMatched, unmatchedIds.length, idOptions.length])

  const description = useMemo(() => {
    if (readOnly) {
      return t('PackageBuilder.customerSegmentation.reviewCustomerIds')
    }

    if (hasMatched && partiallyMatched) {
      const countMatched = idOptions.length - unmatchedIds.length
      return t('PackageBuilder.customerSegmentation.customerMatching.successWithErrorsDescription', {
        countMatched,
      })
    }

    return t('PackageBuilder.customerSegmentation.customerIdsDescription')
  }, [hasMatched, partiallyMatched, t, unmatchedIds.length, readOnly, idOptions.length])

  const hasAddedOptionsThatAreNotMatchedYet = idOptions.some(
    (option) => !state.customerIds.value.includes(option.value),
  )
  const hasClearedAllOptionsAfterMatch = !idOptions.length && state.customerIds.value.length > 0
  const hasDeletedSomeOptionsAfterMatch =
    !hasClearedAllOptionsAfterMatch &&
    idOptions.length < state.customerIds.value.length + state.customerIds.unmatchedIds.length

  return (
    <div className="customer-ids_outer">
      {open && !showPrompt && (
        <Portal wrapperId="app-portal">
          <Modal
            closeCallback={onClose}
            closeDisabled={matchingLoading}
            hideCloseBtn={partiallyMatched}
            title={title}
            headerRenderer={({ title, className }) => <h4 className={cn(className, 'mt-3 px-md-2 mr-3')}>{title}</h4>}
            offWhiteBg
            size="lg"
            fullWidth={false}
            onClickOutside={onClose}
            mainContent={
              <div className="customer-ids">
                <div className="customer-ids_content">
                  <p className="my-3 fs-16">{description}</p>
                  {partiallyMatched && (
                    <>
                      <p className="error-msg">
                        {t('PackageBuilder.customerSegmentation.customerMatching.partialSuccessErrorMsg', {
                          countTotal: idOptions.length,
                          countUnmatched: unmatchedIds.length,
                        })}
                      </p>
                      <p className="fs-16 txt-header">
                        {t('PackageBuilder.customerSegmentation.customerMatching.unmatchedCustomerIds')}
                      </p>
                    </>
                  )}
                  <div className="customer-ids_select-container" onClick={handleContainerClick} onPaste={handlePaste}>
                    <FdCreatableSelect
                      ref={selectRef}
                      name="customer-ids"
                      id="CustomerIds"
                      className="customer-ids_select"
                      isDisabled={isReadonly || hasMatched || matchingLoading}
                      isReadOnly={isReadonly}
                      isMulti
                      isClearable={false}
                      inputValue={inputValue}
                      value={partiallyMatched ? unmatchedIds.map(createSelectOption) : idOptions}
                      onChange={(newValue) => setIdOptions(dedupIds(newValue))}
                      onInputChange={(newValue) => !isReadonly && setInputValue(newValue)}
                      onPaste={handlePaste}
                      onKeyDown={handleKeyDown}
                      placeholder={
                        hasMatched ? null : t('PackageBuilder.customerSegmentation.customerMatching.example')
                      }
                      menuIsOpen={false}
                      blurInputOnSelect={false}
                      components={{
                        DropdownIndicator: null,
                      }}
                      styles={customSelectStyles}
                    />
                  </div>
                  {!readOnly && (!hasMatched || successfulMatch) && (
                    <div className="d-flex justify-content-between mt-3 gap-3">
                      {matchingLoading ? (
                        <div className="loading-container">
                          <LoadingSpinner showText={false} size={SpinnerSize.MINI} grayscale />
                          <Trans i18nKey="PackageBuilder.customerSegmentation.customerMatching.loadingMsg">
                            Loading...
                            <br />
                            We are currently in the process of cross-referencing the customer ID(s) you provided with
                            those in our records. Please wait while we are processing.
                          </Trans>
                        </div>
                      ) : successfulMatch ? (
                        <div className="success-container">
                          <GreenTickIcon width={18} />
                          <p>{t('PackageBuilder.customerSegmentation.customerMatching.successMsg')}</p>
                        </div>
                      ) : matchingServerError ? (
                        <p className="error-msg error-msg--small">
                          {t('PackageBuilder.customerSegmentation.customerMatching.serverError', {
                            countTotal: idOptions.length,
                            countUnmatched: unmatchedIds.length,
                          })}
                        </p>
                      ) : (
                        <SecondaryButton
                          text={t('common.clearAll')}
                          color="navy"
                          onClick={handleClearAll}
                          type="button"
                          disabled={!idOptions.length}
                        />
                      )}
                      <SecondaryButton
                        text={t('PackageBuilder.customerSegmentation.customerMatching.matchIds')}
                        classNames="align-self-baseline"
                        color="orange"
                        disabled={
                          !idOptions.length ||
                          matchingLoading ||
                          successfulMatch ||
                          isEqual(idOptions.map(optionToValue), state.customerIds.value)
                        }
                        type="button"
                        onClick={handleMatchIds}
                      />
                    </div>
                  )}
                  {!readOnly && partiallyMatched && (
                    <div className="d-flex justify-content-between flex-wrap gap-1 mt-3">
                      <SecondaryButton
                        text={t('PackageBuilder.customerSegmentation.customerMatching.downloadUnmatched')}
                        color="orange"
                        onClick={handleDownloadUnmatched}
                        type="button"
                        disabled={!idOptions.length}
                      />
                      <SecondaryButton
                        text={t('PackageBuilder.customerSegmentation.customerMatching.finish')}
                        color="orange"
                        disabled={!idOptions.length || matchingLoading}
                        type="button"
                        onClick={handleFinish}
                      />
                    </div>
                  )}
                </div>
              </div>
            }
          />
        </Portal>
      )}
      <Prompt
        isOpen={showPrompt}
        forClearing={hasClearedAllOptionsAfterMatch}
        forUnmatchedWithNoExistingCustomerIds={hasAddedOptionsThatAreNotMatchedYet && !state.customerIds.value.length}
        forUnmatched={hasAddedOptionsThatAreNotMatchedYet}
        forDeleted={hasDeletedSomeOptionsAfterMatch}
        onYes={() => {
          setShowPrompt(false)
          const removedItems = state.customerIds.value.filter((id) => !idOptions.map(optionToValue).includes(id))
          const newIds = state.customerIds.value.filter((id) => !removedItems.includes(id))

          resetAndClose(newIds.map(createSelectOption), { updateContext: true })
        }}
        onNo={() => setShowPrompt(false)}
      />
    </div>
  )
}

CustomerIds.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  onDone: PropTypes.func,
  readOnly: PropTypes.bool,
}

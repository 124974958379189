const { useEffect } = require('react')

// Hook
function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  )
}

export const useOutsideCheckClick = (itemRef, callback, open, scrollToTop = () => {}) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (open && itemRef.current && itemRef.current.contains(event.target)) {
        // should do nothing
      } else if (open && itemRef?.current && !itemRef.current.contains(event.target)) {
        // should close the accordion
        callback(false, event.target)
        scrollToTop()
      }
    }

    // Bind the event listener
    window.addEventListener('click', handleClickOutside)
    window.addEventListener('mousedown', handleClickOutside)

    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener('click', handleClickOutside)
      window.removeEventListener('mousedown', handleClickOutside)
    }
  }, [itemRef, open, callback, scrollToTop])
}

export default useOnClickOutside

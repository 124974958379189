import React, { useReducer, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'

import CampaignForm from './CampaignForm'
import VariationIndicator from '../VariationIndicator'
import { isCampaignRunning } from '../../../../api/models'
import { SetupContext } from './CampaignSetupProvider'

function updateReducer(state, action) {
  return {
    ...state,
    totalHeadlines: action.payload.headline.filter(Boolean).length,
    totalBodies: action.payload.body.filter(Boolean).length,
    totalMediaFiles: action.payload.media.filter(Boolean).length,
  }
}

export function CampaignEditor({ eventId, eventDate, campaign, goal, onCancel, onChange, onAfterSave, ...rest }) {
  const context = useContext(SetupContext)
  const creative = context.creatives.find((x) => x.tc === campaign.tc) || { tc: campaign.tc }
  const initialCounts = {
    totalHeadlines: creative.headline?.filter(Boolean).length ?? 0,
    totalBodies: creative.body?.filter(Boolean).length ?? 0,
    totalMediaFiles: creative.media?.filter(Boolean).length ?? 0,
  }
  const [creativeCounts, dispatchCreativeCountsAction] = useReducer(updateReducer, initialCounts)

  const internalOnAfterSave = useCallback(
    (creativeForm, defaults) => {
      dispatchCreativeCountsAction({ payload: creativeForm })
      if (onAfterSave) onAfterSave(campaign.tc, creativeForm, defaults)
    },
    [dispatchCreativeCountsAction, campaign, onAfterSave],
  )

  return (
    <>
      {!isCampaignRunning(campaign) && <VariationIndicator {...creativeCounts} />}
      <CampaignForm
        eventId={eventId}
        eventDate={eventDate}
        campaign={campaign}
        goal={goal}
        onAfterSave={internalOnAfterSave}
        onCancel={onCancel}
        onChange={onChange}
        {...rest}
      />
    </>
  )
}

CampaignEditor.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  eventDate: PropTypes.string.isRequired,
  campaign: PropTypes.object.isRequired,
  goal: PropTypes.string,
  onAfterSave: PropTypes.func,
  onCancel: PropTypes.func,
  setMediaLoading: PropTypes.func,
  onChange: PropTypes.func,
}

import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import VideoSVGAnimationWrapper from 'react-lottie'
import Fade from './Wrappers/Fade'

import splashAnimationData from '../../images/video/splashScreenAnimation.json'

const SplashScreen = () => {
  const [ready, setReady] = useState(false)
  useEffect(() => {
    const animateTimeout = setTimeout(() => {}, 300)
    const redirectTimeout = setTimeout(() => setReady(true), 1500)
    return () => {
      clearTimeout(animateTimeout)
      clearTimeout(redirectTimeout)
    }
  })

  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData: splashAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div className={'splash-screen-window'}>
      <Fade still={true} delay={300}>
        <VideoSVGAnimationWrapper options={animationOptions} height={130} width={130} />
      </Fade>
      {ready ? <Redirect to="/" /> : null}
    </div>
  )
}

export default SplashScreen

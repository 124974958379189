import React, { useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import PostalCodes from './PostalCodes'
import { TARGETING_MODES, getDefaultTargetingMode } from '../targeting'
import { useTargeting } from '../TargetingContext'

import { SecondaryButton } from 'components/Shared/Button'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'
import { SetupContext } from '../../../CampaignSetupProvider'

export default function PostalCodeList({ tc, onChange }) {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const { value: targeting } = useTargeting()
  const { targetingMode } = useMemo(() => targeting.find((x) => x.tc === tc) ?? { tc }, [targeting, tc])
  const onPostalCodesChange = useCallback(
    ({ countries }) => {
      onChange({ countries })
    },
    [onChange],
  )

  const setup = useContext(SetupContext)
  const hasCustomAudience = useMemo(() => {
    const tcCreative = setup.creatives.find((x) => x.tc === tc)
    return !!tcCreative?.audience_id
  }, [setup, tc])

  const hasPrismaAccess = hasPrismaAccessTier()
  const defaultTargetingMode = getDefaultTargetingMode(hasPrismaAccess, hasCustomAudience)
  if (
    (!targetingMode && (defaultTargetingMode !== TARGETING_MODES.postalCodes)) ||
    (targetingMode && targetingMode !== TARGETING_MODES.postalCodes)) {
    return null
  }

  return (
    <>
      <div className="postal-codes">
        {isOpen && <PostalCodes tc={tc} isOpen={isOpen} onDone={onPostalCodesChange} setOpen={(open) => setIsOpen(open)} />}
        <SecondaryButton
          text={t('common.edit')}
          color="navy"
          size="small"
          fullWidth={false}
          type="button"
          classNames="geo-targeting__button"
          onClick={(e) => {
            e.preventDefault()
            setIsOpen(!isOpen)
          }}
        />
      </div>
    </>
  )
}

PostalCodeList.propTypes = {
  tc: PropTypes.string.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onChange: PropTypes.func,
}

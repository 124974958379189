import React, { useEffect, useState } from 'react'

import { getUrlMetaTags } from '../../../api/campaigns'
import LoadingSpinner, { SpinnerSize } from '../../Shared/LoadingSpinner'

import { TransparentBgButton, ButtonWithExternalHref } from '../../Shared/Button'

// const redirectEndpoint = 'https://h5fqner5cl.execute-api.eu-central-1.amazonaws.com/api/hello?redirect_url='

const formatShareUrl = (socialMedium, headline, description, eventUrl, setShareUrl, setShareText, eventId) => {
  // forms the social link href for sharing
  if (socialMedium === 'twitter') {
    setShareText(headline)
    setShareUrl(
      `https://twitter.com/intent/tweet?text=${headline}&url=${encodeURIComponent(eventUrl + '&utm_source=twitter&utm_campaign=' + eventId)}`,
    )
    return
  }
  if (socialMedium === 'facebook') {
    setShareText(description)
    setShareUrl(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`${eventUrl}?&utm_source=facebook&utm_campaign=${eventId}`)}&quote=${description}`,
    )
    return
  }
  if (socialMedium === 'whatsapp') {
    setShareText(headline)
    setShareUrl(
      `https://wa.me/?text=${headline + '  ' + encodeURIComponent(eventUrl + '&utm_source=whatsapp&utm_campaign=' + eventId)}`,
    )
    return
  }
  if (socialMedium === 'telegram') {
    setShareText(headline)
    setShareUrl(
      `https://t.me/share/url?url=${encodeURIComponent(eventUrl + '&utm_source=telegram&utm_campaign=' + eventId)}&text=${headline}`,
    )
    return
  }
  if (socialMedium === 'linkedin') {
    setShareText(null)
    setShareUrl(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(`${eventUrl}?&utm_source=linkedin&utm_campaign=${eventId}`)}`,
    )
    return
  }
}

const formatCampaignPreviews = async (urls) => {
  const formattedResults = []

  //  for loops are syncronous - forEach / map loops weren't reliable
  for (let i = 0; i < urls.length; i++) {
    const response = await getUrlMetaTags(urls[i])
    if (response.success) {
      if (!response.tags.url) {
        response.tags.url = urls[i]
      }
      formattedResults.push(response.tags)
    } else {
      // fail safe for when we cannot crawl a website
      formattedResults.push({
        // a beautiful piece of Regex that removes the www && .[country-code => zB .de in Germany]
        title: urls[0].replace(/.+\/\/|www.|\..+/g, ''),
        image: null,
        description: urls[0],
        url: urls[0],
      })
    }
  }

  return formattedResults
}

const ShareTextComponent = ({ description }) => {
  return (
    <>
      {!!description && (
        <div className="campaign-social-share_preview-text_wrapper">
          <strong className="ellipsis">Share Text</strong>
          <div className="campaign-social-share_preview-text_content">
            <p className="ellipsis"> {description}</p>
          </div>
        </div>
      )}
    </>
  )
}

const CampaignPreview = ({ pageImg, pageTitle, pageDescription = 'No descrption available', pageUrl }) => (
  <div className="campaign-preview_wrapper">
    {!!pageImg && (
      <div className="campaign-preview_img_wrapper">
        <img src={pageImg} alt={pageTitle} />
      </div>
    )}

    <div className="campaign-preview_description">
      <div className="campaign-preview_description_title">
        <strong>{pageTitle || pageUrl}</strong>
      </div>

      <div className="campaign-preview_description_description">
        <p>{pageDescription}</p>
      </div>
    </div>
  </div>
)

const Pill = ({ setActiveTab, index, isActive, title }) => {
  return (
    <div onClick={() => setActiveTab(index)} className={`tabs-component_pills_each ${!!isActive && 'active'}`}>
      {title}
    </div>
  )
}

const Tabs = ({ currentTab, setCurrentTab, previews }) => {
  return (
    <div className="tabs-component_pills_wrapper">
      {previews.map((eachTab, index) => {
        return (
          <Pill
            title={`Url ${index + 1}`}
            setActiveTab={setCurrentTab}
            isActive={currentTab === index}
            index={index}
            key={`social-viewing-tabs-${index}`}
          />
        )
      })}
    </div>
  )
}

const ShareCampaignUrlPreview = ({
  previousScreen,
  socialMedium,
  eventId,
  description,
  headline,
  shareUrls,
  closeModal,
}) => {
  const [loading, setLoading] = useState(false)
  const [campaignPreviews, setCampaignPreviews] = useState(null)

  // the tabs are used for switching between the different URLs the client gave in the Creative Composer
  const [currentTab, setCurrentTab] = useState(0)
  const [shareUrl, setShareUrl] = useState(null)
  const [shareText, setShareText] = useState(null)

  const getAndFormatCampaigns = async (sharedUrls) => {
    setLoading(true)
    try {
      const formattedCampaignPreviews = await formatCampaignPreviews(sharedUrls)
      setCampaignPreviews(formattedCampaignPreviews)
    } catch (error) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (shareUrls) {
      getAndFormatCampaigns(shareUrls)
    }
  }, [shareUrls])

  useEffect(() => {
    formatShareUrl(socialMedium, headline, description, shareUrls[currentTab], setShareUrl, setShareText, eventId)
  }, [socialMedium, currentTab, description, eventId, headline, shareUrls])

  return (
    <>
      {!loading && !!campaignPreviews && campaignPreviews.length > 0 && (
        <>
          <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} previews={campaignPreviews} />

          <ShareTextComponent description={shareText} />

          <CampaignPreview
            pageTitle={campaignPreviews[currentTab].title}
            pageImg={campaignPreviews[currentTab].image}
            pageDescription={campaignPreviews[currentTab].description}
            pageUrl={shareUrls[currentTab]}
          />

          {/* buttons footer */}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2em' }}>
            <TransparentBgButton text={'Back'} onClick={previousScreen} color={'navy'} />

            <ButtonWithExternalHref
              text={`Share to ${socialMedium}`}
              externalUrl={shareUrl}
              // upon clicking share we close the modal
              // onClick={setTimeout(() => closeModal(), 2000)}
            />
          </div>
        </>
      )}
      {!!loading && (
        <div>
          <LoadingSpinner showText={true} portalBackground={false} size={SpinnerSize.SMALL} title={''} />
          <p className="mt-4">Loading Meta data... this may take a few seconds</p>
        </div>
      )}
    </>
  )
}

export default ShareCampaignUrlPreview

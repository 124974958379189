import { v4 } from 'uuid'

export function parseGroupedLocations(data, { selectedCities = [], selectedVenuesAndHalls = [] }) {
  const cities = []
  for (const cityName in data) {
    if (cities.every((x) => x.name !== cityName)) {
      cities.push({
        // maintain a stable id
        id: selectedCities.find((c) => c.title === cityName)?.id ?? v4(),
        title: cityName,
      })
    }

    const cityVenues = data[cityName]
    const city = cities.find((x) => x.title === cityName)
    let venuesAndHalls = []
    for (const venueName in cityVenues) {
      venuesAndHalls.push({ id: v4(), title: venueName, venue: venueName })
      venuesAndHalls = [
        ...venuesAndHalls,
        ...cityVenues[venueName].map((hall) => {
          const venueHallTitle = hall !== 'None' ? `${venueName}, ${hall}` : venueName

          return {
            // maintain a stable id
            id: selectedVenuesAndHalls.find((c) => c.title === venueHallTitle)?.id ?? v4(),
            title: venueName ? venueHallTitle : hall,
            venue: venueName,
            hall,
          }
        }),
      ]

      city.venuesAndHalls = venuesAndHalls
    }
  }

  return cities
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import UploadModal from 'components/Uploads/UploadModal'

const SelectedModal = ({ item, setShowModal }) => {
  switch (item.key) {
    case 'fileUpload':
      return <UploadModal setShowModal={setShowModal} />
    default:
      return null
  }
}

SelectedModal.propTypes = {
  item: PropTypes.object,
  setShowModal: PropTypes.func,
}

const MainNavModalItem = ({ item, layout }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <li
        onClick={() => setShowModal(true)}
        key={item.key}
        id={`main-nav-item-${item.key}`}
        className="main-nav__item white-icon mb-0"
      >
        <div className="main-nav__link c-hover">
          {layout.sidebarExpanded ? (
            <div
              className={`d-flex flex justify-content-start ${item.flexAlignClass ? item.flexAlignClass : 'align-items-center'}`}
            >
              <span style={{ ...item.style }} title={t(`Menu.${item.key}`)}>
                {item.icon}
              </span>
              <h5 className="main-nav__link__text">{t(`Menu.${item.key}`)}</h5>
            </div>
          ) : (
            <span className={'d-flex justify-content-center w-100'} title={t(`Menu.${item.key}`)}>
              {item.icon}
            </span>
          )}
        </div>
      </li>
      {showModal && <SelectedModal item={item} setShowModal={setShowModal} />}
    </>
  )
}

MainNavModalItem.propTypes = {
  item: PropTypes.object,
  layout: PropTypes.object,
}

export default MainNavModalItem

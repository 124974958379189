import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import InfoBox from 'components/Shared/InfoBox'

import ReportableTopic from './ReportableTopic'

export function Keywords({
  eventId,
  setupProcessId,
  campaign,
  reportedKeywords = [],
  keywords = [],
  className,
  reportsEnabled = true,
}) {
  const { t } = useTranslation()
  const topics = useMemo(() => {
    return keywords.map((keyword) => {
      return {
        is_reported: reportedKeywords.some((reportedKeyword) => reportedKeyword === keyword),
        keyword,
      }
    })
  }, [reportedKeywords, keywords])

  if (!keywords.length) return null

  return (
    <>
      <div className={cn('campaign-form__tips col-12 d-xl-none', className)}>
        <div className="display-flex">
          <InfoBox content="Tips.Recommendations.topicRecommendationCommonTooltip" classNames="d-flex">
            <label>{t('Recommendations.campaign.setup.TopicRecommendations')}</label>
          </InfoBox>
        </div>
        <ul className="d-flex flex-wrap mt-1">
          {topics.map((topic, index) => (
            <ReportableTopic
              eventId={eventId}
              setupProcessId={setupProcessId}
              campaign={campaign}
              key={index}
              topic={topic}
              index={index}
              charLimit={30}
              reportsEnabled={reportsEnabled}
            />
          ))}
        </ul>
      </div>
      <div className={`campaign-form__tips col-5 d-none d-xl-block ${className ? className : ''}`}>
        <div className="display-flex">
          <InfoBox content="Tips.Recommendations.topicRecommendationCommonTooltip" classNames="d-flex">
            <label>{t('Recommendations.campaign.setup.TopicRecommendations')}</label>
          </InfoBox>
        </div>
        <ul className="d-flex flex-wrap mt-1">
          {topics.map((topic, index) => {
            const trimmedKeyword = topic.keyword?.trim()
            if (trimmedKeyword === '' || !trimmedKeyword) return null
            return (
              <ReportableTopic
                eventId={eventId}
                setupProcessId={setupProcessId}
                campaign={campaign}
                key={index}
                topic={topic}
                index={index}
                charLimit={40}
                reportsEnabled={reportsEnabled}
              />
            )
          })}
        </ul>
      </div>
    </>
  )
}
Keywords.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  keywords: PropTypes.array,
  reportedKeywords: PropTypes.array,
  className: PropTypes.string,
  setupProcessId: PropTypes.number,
  campaign: PropTypes.object,
  reportsEnabled: PropTypes.bool,
}

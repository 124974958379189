import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from './Button'

export default function ErrorFallback({ error, resetErrorBoundary }) {
  const { t } = useTranslation()

  return (
    <div role="alert" className="error-message alert alert-danger">
      <p className="mb-2">{t('common.genericError')}</p>
      <div className="d-flex justify-content-between align-items-center mb-1">
        <pre className="mb-0">{t(error.userFriendlyMessage ?? 'common.genericError')}</pre>
        <SecondaryButton
          text={t('common.retry')}
          onClick={resetErrorBoundary}
          fullWidth={false}
          color="orange"
          size="small"
        />
      </div>
    </div>
  )
}

ErrorFallback.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func,
}

import moment from 'moment'

export const TICKET_TYPES = ['subscription', 'regular', 'regular_discount', 'discount', 'cancelled', 'free', 'reserved']

export const MIN_TICKETS_SOLD = 1
export const MIN_REVENUE = 0

export const BULK_UPLOAD_MIN_TICKETS_SOLD = 0
export const BULK_UPLOAD_MIN_REVENUE = 0

export const formatData = (dates, tickets, eventId) => {
  const ticket_types = {
    subscription: {
      count: 0,
      revenue: 0,
    },
    regular: {
      count: 0,
      revenue: 0,
    },
    'regular discount': {
      count: 0,
      revenue: 0,
    },
    discount: {
      count: 0,
      revenue: 0,
    },
    cancelled: {
      count: 0,
      revenue: 0,
    },
    free: {
      count: 0,
      revenue: 0,
    },
    reserved: {
      count: 0,
      revenue: 0,
    },
  }

  tickets.forEach((ticketCategory) => {
    const formattedTicketType = ticketCategory.type.toLowerCase().replaceAll('_', ' ')

    // fail safe
    ticket_types[formattedTicketType] = {
      count: ticket_types[formattedTicketType].count + Number(ticketCategory.sold),
      revenue: ticket_types[formattedTicketType].revenue + Number(ticketCategory.revenue),
    }
  })

  return {
    event_id: eventId,
    start_date: moment(dates.from).format('YYYY-MM-DD'),
    end_date: moment(dates.to).format('YYYY-MM-DD'),
    ticket_types,
  }
}

import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useReducerAsync } from 'hooks/useReducerAsync'
import useInterval from 'hooks/useInterval'
import { useSelector } from 'react-redux'

import constants from '../../constants'

import {
  NotificationContext,
  reducer,
  initialState,
  asyncActionHandlers,
  NotificationDispatchContext,
  actions,
} from './notifications'

export function NotificationContextProvider({ children }) {
  const user = useSelector((state) => state.user)
  const [state, dispatch] = useReducerAsync(reducer, initialState, asyncActionHandlers)

  useEffect(() => {
    if (user.isLogged) dispatch({ type: actions.fetchStatsAsync })
  }, [dispatch, user.isLogged])

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch],
  )

  useInterval(() => {
    if (user.isLogged) dispatch({ type: actions.fetchStatsAsync })
  }, constants.NOTIFICATION_PULL_INTERVAL)

  return (
    <NotificationContext.Provider value={value.state}>
      <NotificationDispatchContext.Provider value={value.dispatch}>{children}</NotificationDispatchContext.Provider>
    </NotificationContext.Provider>
  )
}

NotificationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { SecondaryButton, TransparentBgButton } from 'components/Shared/Button'
import { actions, ConnectContext } from './connect.js'
import { configurations } from './util'
import { ConnectedIcon } from 'images/index.jsx'
import PortalModal from 'components/Shared/PortalModal.jsx'

const SelectedBox = () => {
  const { t } = useTranslation()

  return (
    <span className="pl-2 card-settings-account__value__verified">
      <ConnectedIcon />
      {t('Settings.MetaAccess.selected')}
    </span>
  )
}

function MetaAccessOptionLabel({ label, connected }) {
  const { t } = useTranslation()

  return (
    <div className="d-flex align-items-center">
      <span className="mr-2">{t(label)}</span>
      {connected && <SelectedBox />}
    </div>
  )
}

MetaAccessOptionLabel.propTypes = {
  label: PropTypes.string.isRequired,
  connected: PropTypes.bool,
}

function ConnectByTokenType({ status, tokenType, onFacebookLogin, showLogout, onLogout }) {
  const { t } = useTranslation()
  const connected = tokenType === 'system' ? status.has_valid_system_user_token : status.has_valid_user_token

  if (showLogout && connected) {
    return (
      <TransparentBgButton
        classNames="bt bt-disconnect"
        onClick={() => onLogout(tokenType)}
        text={t('common.logOut')}
        size="small"
        fullWidth={false}
        color="navy"
      />
    )
  }

  return (
    <SecondaryButton
      classNames="bt bt-connect"
      onClick={() =>
        onFacebookLogin(
          configurations.find((x) => x.type === tokenType && !x.assetTypes.length),
          `${tokenType}state`,
        )
      }
      text={t('Settings.MetaAccess.btnGiveAccess')}
      size="small"
      fullWidth={false}
    />
  )
}

ConnectByTokenType.propTypes = {
  tokenType: PropTypes.oneOf(['user', 'system']).isRequired,
  status: PropTypes.object.isRequired,
  onFacebookLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  showLogout: PropTypes.bool,
}

function ConfirmDisconnect({ open, setOpen, onLogout }) {
  const { t } = useTranslation()

  return (
    <PortalModal isOpen={open} onClickOutside={() => setOpen(false)}>
      <div className="meta meta_dialog">
        <h3 className="d-inline-block mb-3">{t('Settings.MetaAccess.disconnect.title')}</h3>
        <p>{t('Settings.MetaAccess.disconnect.body')}</p>
        <div className="d-flex justify-content-center gap-3 mt-4">
          <SecondaryButton onClick={() => setOpen(false)} text={t('common.cancel')} color="navy" />
          <SecondaryButton onClick={onLogout} text={t('common.logOut')} color="orange" />
        </div>
      </div>
    </PortalModal>
  )
}

ConfirmDisconnect.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onLogout: PropTypes.func,
}

export default function Connect({ onFacebookLogin, tokenType, showLogout = true, onLogout }) {
  const { state, dispatch } = useContext(ConnectContext)
  const [isDisconnectConfirmDialogOpen, setIsDisconnectConfirmDialogOpen] = React.useState(false)
  const { status } = state
  const connected = tokenType === 'system' ? status.has_valid_system_user_token : status.has_valid_user_token

  useEffect(() => {
    dispatch({ type: actions.GET_STATUS })
  }, [dispatch])

  if (!showLogout && connected) {
    return null
  }

  return (
    <>
      <ConnectByTokenType
        tokenType={tokenType}
        status={state.status}
        onFacebookLogin={onFacebookLogin}
        showLogout={showLogout}
        onLogout={() => setIsDisconnectConfirmDialogOpen(true)}
      />
      <ConfirmDisconnect
        open={isDisconnectConfirmDialogOpen}
        setOpen={setIsDisconnectConfirmDialogOpen}
        onLogout={() => {
          setIsDisconnectConfirmDialogOpen(false)
          onLogout(tokenType)
        }}
      />
    </>
  )
}

Connect.propTypes = {
  onFacebookLogin: PropTypes.func.isRequired,
  tokenType: PropTypes.oneOf(['user', 'system']).isRequired,
  showLogout: PropTypes.bool,
  onLogout: PropTypes.func,
}

import React, { useEffect, useState } from 'react'

import ShareCampaignUrlPreview from './CampaignShareSocialModal'

import facebook from '../../../images/icons/facebook.svg'
import twitter from '../../../images/icons/twitter.svg'
import telegram from '../../../images/icons/telegram.svg'
import linkedin from '../../../images/icons/linkedin.svg'
import whatsapp from '../../../images/icons/whatsapp.svg'
import PortalModal from 'components/Shared/PortalModal'
// import instagram from '../../../images/icons/instagram.svg'
// import tiktok from '../../../images/icons/tiktok.svg'
// import slack from '../../../images/icons/slack.svg'

export const socialButtonsMapping = {
  facebook: {
    name: 'facebook',
    image: facebook,
  },
  twitter: {
    name: 'twitter',
    image: twitter,
  },
  linkedin: {
    name: 'linkedin',
    image: linkedin,
  },
  telegram: {
    name: 'telegram',
    image: telegram,
  },
  whatsapp: {
    name: 'whatsapp',
    image: whatsapp,
  },
}

const SecondScreen = ({
  previousScreen,
  socialMedium,
  eventId,
  description,
  headline,
  otherData,
  shareUrls,
  closeModal,
}) => {
  return (
    <div className="campaign-social-share_second-screen">
      <ShareCampaignUrlPreview
        eventId={eventId}
        description={description}
        headline={headline}
        previousScreen={previousScreen}
        socialMedium={socialMedium}
        shareUrls={shareUrls}
        closeModal={closeModal}
      />
    </div>
  )
}

const FirstScreen = ({ nextScreen, socialMedium, setSocialMedium }) => {
  const [icons, setIcons] = useState(null)

  const generateSocialButtons = () => {
    const arrayOfIcons = []
    for (const eachIcon in socialButtonsMapping) {
      arrayOfIcons.push({
        name: socialButtonsMapping[eachIcon].name,
        image: socialButtonsMapping[eachIcon].image,
      })
    }
    setIcons(arrayOfIcons)
  }

  useEffect(() => {
    generateSocialButtons()
  }, [])

  const handleIconClick = (socialTypeClicked) => {
    setSocialMedium(socialTypeClicked)
    nextScreen()
  }

  return (
    <article className="campaign-social-share_first-screen">
      <div className="campaign-social-share_first-screen_icons_wrapper">
        {!!icons &&
          icons.map((icon) => {
            return (
              <div
                key={icon.name}
                onClick={() => handleIconClick(icon.name)}
                className={`campaign-social-share_image_card-wrapper ${icon.name === socialMedium ? 'selected' : ''}`}
              >
                <img className={'campaign-social-share_image_large_wrapper'} src={icon.image} alt={icon.name} />
                <p className="campaign-social-share_image_title cap">{icon.name}</p>
              </div>
            )
          })}
      </div>
    </article>
  )
}

const TitleWithIcon = ({ socialMedium, socialButtonsMapping }) => (
  <div className="d-flex flex-wrap gap-2 align-items-center">
    <span>Share with {socialMedium.replace(socialMedium[0], socialMedium[0].toUpperCase())}</span>
    <span>
      <img style={{ width: 24 }} src={socialButtonsMapping[socialMedium].image} alt={`share with ${socialMedium}`} />
    </span>
  </div>
)

const Wrapper = ({ closeModal, shareUrls, eventId, description, headline, allData, isModalOpen }) => {
  // init the modal view on the first slide
  const [modalView, setModalView] = useState(0)
  const [socialMedium, setSocialMedium] = useState('facebook')

  return (
    <PortalModal
      isOpen={isModalOpen}
      title={
        modalView === 0 ? (
          'Select social platform to share with'
        ) : (
          <TitleWithIcon socialMedium={socialMedium} socialButtonsMapping={socialButtonsMapping} />
        )
      }
      closeCallback={closeModal}
      onClickOutside={closeModal}
      fullWidth={true}
      style={{ minWidth: window.innerWidth > 600 ? 500 : 300 }}
    >
      <div>
        {modalView === 0 ? (
          <FirstScreen
            nextScreen={() => setModalView(1)}
            socialMedium={socialMedium}
            setSocialMedium={setSocialMedium}
          />
        ) : (
          <SecondScreen
            previousScreen={() => setModalView(0)}
            socialMedium={socialMedium}
            eventId={eventId}
            description={description}
            headline={headline}
            shareUrls={shareUrls}
            closeModal={closeModal}
          />
        )}
      </div>
    </PortalModal>
  )
}

export default Wrapper

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { SecondaryButton, TransparentBgButton } from 'components/Shared/Button'
import PortalModal from 'components/Shared/PortalModal'

export function Prompt({
  isOpen,
  forDeleted,
  forUnsavedChangesWithExistingData,
  onYes,
  onNo,
}) {
  const { t } = useTranslation()

  const title = useMemo(() => {
    if (forDeleted) {
      return t('Recommendations.geoTargeting.rangeLocations.unsavedChangesPrompt.deletedTitle')
    }

    return t('Recommendations.geoTargeting.rangeLocations.unsavedChangesPrompt.unsavedChangesTitle')
  }, [forDeleted, t])

  const description = useMemo(() => {
    if (forDeleted) {
      return t('Recommendations.geoTargeting.rangeLocations.unsavedChangesPrompt.deletedDescription')
    } else if (forUnsavedChangesWithExistingData) {
      return t('Recommendations.geoTargeting.rangeLocations.unsavedChangesPrompt.unsavedChangesWithExistingDataDescription')
    }

    return t('Recommendations.geoTargeting.rangeLocations.unsavedChangesPrompt.unsavedChangesDescription')
  }, [forDeleted, forUnsavedChangesWithExistingData, t])

  const cancel = useMemo(() => {
    if (forDeleted) {
      return t('Recommendations.geoTargeting.rangeLocations.unsavedChangesPrompt.cancelDelete')
    }

    return t('common.cancel')
  }, [t, forDeleted])

  const proceed = useMemo(() => {
    if (forDeleted) {
      return t('Recommendations.geoTargeting.rangeLocations.unsavedChangesPrompt.proceedDelete')
    }

    return t('common.yesProceed')
  }, [t, forDeleted])

  return (
    <PortalModal isOpen={isOpen} onClickOutside={onNo}>
      <div className="content compact text-center" style={{ maxWidth: '420px' }}>
        <h1 className="autofill_warning-header">{title}</h1>
        <p className="autofill_warning-msg">{description}</p>
        <div className="footer">
          <TransparentBgButton text={cancel} onClick={onNo} color="navy" />
          <SecondaryButton color="orange" text={proceed} onClick={onYes} type="button" disabledTitle="" />
        </div>
      </div>
    </PortalModal>
  )
}
Prompt.propTypes = {
  isOpen: PropTypes.bool,
  forUnsavedChangesWithExistingData: PropTypes.bool,
  forDeleted: PropTypes.bool,
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
}

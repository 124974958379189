import PropTypes from 'prop-types'
import React, { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { changeLanguage } from '../../utils/helpers'
import { updateUserProfile } from '../../api/auth'
import { initStateObject, objectReducer } from '../../reducers/default'
import { WorldIcon } from 'images'
import DropDown from './DropDown'

/**
 * Toggle between languages and update user profile.
 * @Endpoints( /profile )
 * @ApiLogic( ../../api/auth )
 * @Tags( shared, translation )
 * @NpmPackages( react-i18next )
 */
const languages = [
  { text: 'common.language.en', value: 'en' },
  { text: 'common.language.de', value: 'de' },
  { text: 'common.language.fr', value: 'fr' },
]

const LanguageSwitcher = ({ list = false, settings = false }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [formStatus, setFormStatus] = useReducer(objectReducer, initStateObject)

  const changeLanguageProfile = (lang) => {
    changeLanguage(i18n, lang)
    updateUserProfile(dispatch, { ...user.profile, language: lang }, setFormStatus)
  }

  if (list) {
    return (
      <div>
        {formStatus.error && formStatus.message && <p className="alert alert-danger">{t(formStatus.message)}</p>}
        {formStatus.success && <p className="alert alert-info">{t(formStatus.message)}</p>}
        <ul className="language-switcher language-switcher--list">
          {languages.map((lang) => (
            <li
              key={lang}
              className={`language-switcher__option ${
                i18n.language === lang ? 'language-switcher__option--active' : ''
              }`}
            >
              <button
                type="button"
                onClick={() => changeLanguageProfile(lang)}
                className={`language-switcher__button ${
                  i18n.language === lang ? 'language-switcher__button--active' : ''
                }`}
              >
                {t(`common.language.${lang}`)}
              </button>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  if (settings) {
    return (
      <>
        {formStatus.error && formStatus.message && <p className="alert alert-danger">{t(formStatus.message)}</p>}
        {/* {formStatus.success && <p className="alert alert-info">{t(formStatus.message)}</p>} */}
        <div className="settings-page__sectionBody">
          <div className="settings-page__sectionColumn w-100">
            <div className="settings-page__sectionIcon">
              <WorldIcon width={17} />
            </div>
            <div className="settings-page__sectionWrapper">
              <div className="settings-page__sectionState flex-wrap">
                <h3 className="settings-page__sectionTitle">{t('Settings.language.title')}</h3>
              </div>
              <div className="settings-page__sectionDropdown">
                <DropDown
                  value={i18n.language}
                  options={languages}
                  onChange={(value) => changeLanguageProfile(value)}
                  placeholder={t(`common.language.${i18n.language}`)}
                  placementType="dynamic"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return null
}

LanguageSwitcher.propTypes = {
  /**
   * If true will show list of languages with active one.
   */
  list: PropTypes.bool,
  settings: PropTypes.bool,
}

LanguageSwitcher.defaultProps = {}

export default LanguageSwitcher

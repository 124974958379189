import * as Sentry from '@sentry/react'

import { importSalesData } from 'api/events'

export const VALID_MIME_TYPES = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

const errors = Object.freeze({
  fileRequired: 'FILE_REQUIRED',
  invalidFileFormat: 'INVALID_FILE_FORMAT',
  fileInvalid: 'FILE_INVALID',
})

async function validateAsync(file) {
  try {
    if (!file) return { error: errors.fileRequired }

    if (VALID_MIME_TYPES.every((x) => x !== file.type)) {
      return { error: errors.invalidFileFormat }
    }

    return {}
  } catch (e) {
    Sentry.captureException(e)
    return { error: errors.invalidFileFormat }
  }
}

export const initialState = {
  valid: null,
  loading: false,
  file: null,
  errors: null,
  uploaded: false,
}

export const actions = Object.freeze({
  addOrReplaceFile: 'ADD_OR_REPLACE_FILE',
  upload: 'UPLOAD',

  fileAdded: 'FILE_ADDED',
  fileUploaded: 'FILE_UPLOADED',
  failedToUpload: 'FAILED_TO_UPLOAD',
  updateFileProgress: 'UPDATE_FILE_PROGRESS',
  removeFile: 'REMOVE_FILE',
  loading: 'LOADING',
})

export function reducer(state, action) {
  switch (action.type) {
    case actions.fileAdded:
      return { ...state, ...action.payload }
    case actions.removeFile:
      return { ...state, file: null, valid: null, errors: [] }
    case actions.loading:
      return { ...state, loading: true }
    case actions.fileUploaded:
      return { ...state, ...action.payload, uploaded: true, loading: false }
    case actions.failedToUpload:
      return { ...state, ...action.payload, uploaded: false, loading: false }
    case actions.updateFileProgress:
      return { ...state, file: { ...state.file, progress: action.payload } }
    default:
      throw new Error(`Unknown action type ${action.type}`)
  }
}

export const asyncActionHandlers = {
  [actions.addOrReplaceFile]:
    ({ dispatch, getState }) =>
    async (action) => {
      const file = action.payload
      const { error } = await validateAsync(file)
      const state = getState()

      const payload = {
        ...state,
        file,
        error,
        valid: !error,
      }

      dispatch({ type: actions.fileAdded, payload })
    },
  [actions.upload]:
    ({ dispatch, getState }) =>
    async () => {
      const state = getState()
      const file = state.file

      if (!file) throw new Error('Missing file')

      dispatch({ type: actions.loading })
      let { success, error_codes } = await importSalesData(file, (progress) => {
        dispatch({ type: actions.updateFileProgress, payload: progress })
      })

      const unique_error_codes = error_codes.filter((value, index, self) => self.indexOf(value) === index)

      const payload = {
        errors: unique_error_codes,
        valid: success,
      }

      if (success) dispatch({ type: actions.fileUploaded, payload })
      else dispatch({ type: actions.failedToUpload, payload })
    },
}

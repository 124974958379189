import React, { useEffect } from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/Layout'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import { paramsToObject } from 'utils/helpers'
import useIsFirstRender from 'hooks/useFirstRender'

export function FacebookLoginCallback() {
  const firstRender = useIsFirstRender()

  useEffect(() => {
    if (!firstRender) return

    const timeoutId = init()
    return () => {
      clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const init = () => {
    try {
      const cachedAuthSession = sessionStorage.getItem('authSession')
      const authSession = cachedAuthSession ? JSON.parse(cachedAuthSession) : null
      if (!authSession) return

      const { state } = authSession

      const {
        code,
        error,
        error_reason,
        error_description,
        state: incomingState,
      } = paramsToObject(window.location.search)

      const redirected = incomingState === state && (code || error)
      let postMessage = { type: 'fb_callback' }
      if (redirected) {
        if (code) {
          postMessage['code'] = code
        } else {
          postMessage['error'] = error ?? 'unknown_error'
          postMessage['error_reason'] = error_reason
          postMessage['error_description'] = error_description
        }
      } else {
        postMessage['error'] = 'invalid_state'
      }

      sessionStorage.removeItem('authSession')

      return setTimeout(() => {
        if (window.opener) window.opener.postMessage(postMessage, '*')
        else window.location.href = '/'
      }, 2000)
    } catch {
      sessionStorage.removeItem('authSession')

      return setTimeout(() => {
        if (window.opener)
          window.opener.postMessage(
            {
              type: 'fb_callback',
              error: 'unknown_error',
            },
            '*',
          )
        else window.location.href = '/'
      }, 2000)
    }
  }

  return (
    <Layout title="Facebook Login">
      <Helmet bodyAttributes={{ class: 'vh-100 overflow-hidden' }} />
      <div>
        <main className="fb-callback vh-100">
          <div className="container-fluid h-100">
            <div className="d-flex flex-column align-items-center justify-content-center h-100">
              <LoadingSpinner showText={false} />
            </div>
          </div>
        </main>
      </div>
    </Layout>
  )
}

import { createStore } from 'redux'

import rootReducer from './reducers/rootReducer'

const localState = localStorage.getItem('reduxState') ? JSON.parse(localStorage.getItem('reduxState')) : undefined
let store

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  store = createStore(
    rootReducer,
    localState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  )
} else {
  store = createStore(rootReducer, localState)
}

export default store

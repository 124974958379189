import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { throttle } from 'lodash'

import { actions, NotificationContext, NotificationDispatchContext } from './notifications'
import NotificationDetails from './NotificationDetails'
import ActionBar from './ActionBar'
import NotificationItem from './NotificationItem'

import SideDrawer from 'components/Shared/SideDrawer'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import ErrorMessage from 'components/Shared/ErrorMessage'

import useIsFirstRender from 'hooks/useFirstRender'

export default function NotificationList() {
  const { t } = useTranslation()
  const state = useContext(NotificationContext)
  const dispatch = useContext(NotificationDispatchContext)
  const location = useLocation()
  const firstRender = useIsFirstRender()

  useEffect(() => {
    if (firstRender) dispatch({ type: actions.fetchNotificationsPageAsync })
  }, [firstRender, dispatch])

  useEffect(() => {
    const controller = new AbortController()

    const container = document.querySelector('#notification-list')
    const handleScroll = () => {
      if (state.notifications.maxPage > state.notifications.page && !state.loading) {
        const bottomEdgeOfWrapper = container.getBoundingClientRect().bottom
        const scrolled = window.innerHeight + window.scrollY
        const pageHeight = document.body.scrollHeight

        if (scrolled >= pageHeight || scrolled >= bottomEdgeOfWrapper) {
          dispatch({ type: actions.fetchNotificationsPageAsync })
        }
      }
    }
    handleScroll()
    const throttledScroll = throttle(handleScroll, 1000)

    window.addEventListener('scroll', throttledScroll)
    return () => {
      window.removeEventListener('scroll', throttledScroll)
      controller.abort()
    }
  }, [state.notifications.maxPage, state.notifications.page, dispatch, state.loading])

  useEffect(() => {
    const urlParams = new URL(window.location.href)
    const drawerOpen = urlParams.searchParams.get('drawer-open')

    if (!drawerOpen && state.activeNotificationId) {
      dispatch({ type: actions.setActiveNotificationId, payload: null })
      return
    }

    const notificationId = urlParams.searchParams.get('notification-id')
    // eslint-disable-next-line eqeqeq
    if (!notificationId || state.activeNotificationId == notificationId) return

    dispatch({ type: actions.setActiveNotificationId, payload: notificationId })
  }, [location, dispatch, state.activeNotificationId, state.notifications.items])

  return (
    <div id="notification-list" className="notification-list">
      <ActionBar>{state.selectionMode.loading && <LoadingSpinner />}</ActionBar>

      {state.notifications.items.length === 0 && !state.notifications.loading && !state.notifications.error && (
        <div className="card card-default pt-0 mt-5">
          <div className="card-body text-center">{t('Notifications.emptyMessageList')}</div>
        </div>
      )}
      {state.selectionMode.error && (
        <ErrorMessage danger>{t([state.selectionMode.error, 'common.errors.unknownError'])}</ErrorMessage>
      )}
      {state.notifications.items.map((n) => (
        // eslint-disable-next-line eqeqeq
        <NotificationItem key={n.id} notification={n} isActive={state.activeNotificationId == n.id} />
      ))}
      {state.notifications.loading && <LoadingSpinner />}
      {state.notifications.error && (
        <ErrorMessage danger>{t([state.notifications.error, 'common.errors.unknownError'])}</ErrorMessage>
      )}
      <SideDrawer drawerOpen={!!state.activeNotificationId} toggle={() => {}}>
        <NotificationDetails notificationId={state.activeNotificationId} />
      </SideDrawer>
    </div>
  )
}

import React from 'react'
import {
  BackhaulIcon,
  HomeIcon,
  LookoutIcon,
  WaveIcon,
  UploadFilesIcon,
  NotificationsIcon,
  SettingsIcon,
  LogoutIcon,
} from '../images'
import { Permissions } from 'hooks/useHasPermission'

const MAIN_NAV_ICON_SMALLER = '18px'
const MAIN_NAV_ICON_WIDTH = '20px'
const MAIN_NAV_ICON_WIDTH_LARGER = '22px'
const MOBILE_NAV_ICON_WIDTH = '16'

export const MAIN_NAV = [
  {
    key: 'dashboard',
    path: '/',
    icon: <HomeIcon width={MAIN_NAV_ICON_WIDTH} />,
    permissionName: Permissions.dashboard,
    flexAlignClass: 'align-items-end',
    style: { marginRight: 24 },
  },
  {
    key: 'demandPrediction',
    path: '/events',
    icon: <LookoutIcon width={MAIN_NAV_ICON_WIDTH} />,
    permissionName: Permissions.lookout,
    style: { marginRight: 24 },
  },
  {
    key: 'recommendations',
    path: '/campaigns',
    icon: <WaveIcon width={MAIN_NAV_ICON_WIDTH} />,
    permissionName: Permissions.wave,
    style: { marginRight: 24 },
  },
  {
    key: 'package-builder',
    path: '/package-builder',
    icon: <BackhaulIcon width={MAIN_NAV_ICON_WIDTH} />,
    permissionName: Permissions.backhaul,
    style: { marginRight: 24 },
  },
]

export const MAIN_NAV_MIDDLE = [
  {
    key: 'fileUpload',
    icon: <UploadFilesIcon width={MAIN_NAV_ICON_WIDTH} />,
    style: { marginRight: 24 },
    navType: 'modal',
  },
  {
    key: 'notifications',
    path: '/notifications',
    style: { marginRight: 24, marginLeft: -2 },
    icon: <NotificationsIcon width={MAIN_NAV_ICON_WIDTH_LARGER + 3} height={27} />,
  },
]

export const MAIN_NAV_BOTTOM = [
  {
    key: 'settings',
    path: '/settings',
    icon: <SettingsIcon width={MAIN_NAV_ICON_WIDTH_LARGER} />,
    style: { marginRight: 24 },
  },
  {
    key: 'logout',
    path: null,
    style: { marginRight: 24 },
    icon: <LogoutIcon width={MAIN_NAV_ICON_SMALLER} />,
  },
]

export const MOBILE_NAV = [
  {
    key: 'dashboard',
    path: '/',
    icon: <HomeIcon width={MOBILE_NAV_ICON_WIDTH} />,
    permissionName: Permissions.dashboard,
  },
  {
    key: 'demandPrediction',
    path: '/events',
    icon: <LookoutIcon width={MOBILE_NAV_ICON_WIDTH} />,
    permissionName: Permissions.lookout,
    style: {
      width: 24,
      marginRight: -3,
      marginLeft: -4,
    },
  },
  {
    key: 'salesBoost',
    path: '/campaigns',
    icon: <WaveIcon width={MOBILE_NAV_ICON_WIDTH} />,
    permissionName: Permissions.wave,
    style: {
      width: 24,
      marginRight: -3,
      marginLeft: -4,
    },
  },
  {
    key: 'package-builder',
    path: '/package-builder',
    icon: <BackhaulIcon width={MOBILE_NAV_ICON_WIDTH} />,
    isPreview: false,
    permissionName: Permissions.backhaul,
    style: {
      width: 24,
      marginRight: -3,
      marginLeft: -4,
    },
  },
]

export const MOBILE_NAVE_MIDDLE = [
  {
    key: 'fileUpload',
    path: '/fileUpload',
    icon: <UploadFilesIcon width={MOBILE_NAV_ICON_WIDTH} />,
    permissionName: 'fileUpload',
    style: {
      width: 24,
      marginRight: -3,
      marginLeft: -4,
    },
  },
  {
    key: 'notifications',
    path: '/notifications',
    permissionName: 'notifications',
    icon: <NotificationsIcon width={MOBILE_NAV_ICON_WIDTH + 3} />,
    style: {
      width: 26,
      marginRight: -3,
      marginLeft: -4,
    },
  },
]

export const MOBILE_NAVE_BOTTOM = [
  {
    key: 'settings',
    path: '/settings',
    icon: <SettingsIcon width={MAIN_NAV_ICON_WIDTH_LARGER} />,
    style: {
      width: 22,
      marginRight: 0,
      marginLeft: 1,
    },
  },
  {
    key: 'logout',
    path: null,
    icon: <LogoutIcon width={MAIN_NAV_ICON_SMALLER} />,
    style: {
      width: 26,
      marginRight: 0,
      marginLeft: 3,
    },
  },
]

export const DEFAULT_EVENT_CATEGORY = 'all'

import React from 'react'
import PropTypes from 'prop-types'
import cln from 'classnames'

import { WarningTriangleIcon } from 'images'

const Notice = ({ message, isVisible, className }) => {
  if (!isVisible) return null

  return (
    <div className={cln('notice', className)}>
      <div>
        <WarningTriangleIcon width={25} height={34} />
      </div>
      <div>{message}</div>
    </div>
  )
}

Notice.propTypes = {
  message: PropTypes.string,
  isVisible: PropTypes.bool,
  className: PropTypes.string,
}

export default Notice

import React from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'

import Layout from 'components/Layout'
import NotificationList from 'components/Notifications/NotificationList'

/**
 * Default page for notifications.
 * @Tags( page, notifications, layout, logged-users )
 * @SuggestedTags( notifications )
 * @Routes( /notifications )
 */
export default function Notifications() {
  const { t } = useTranslation()

  return (
    <Layout
      loggedIn
      title={t('Notifications.PageTitle')}
      mobileTopBar={{ centerTitle: t('Notifications.MobileTitle'), backToDash: true }}
    >
      <Helmet
        bodyAttributes={{
          class: 'notifications-page',
        }}
      />
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1 className="main-title hide-mobile">{t('Notifications.MainTitle')}</h1>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12">
              <NotificationList />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

import { Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Trans } from 'react-i18next'
import Button from 'components/Shared/Button'

export default function LoadingScreen({ title }) {
  const { t } = useTranslation()
  return (
    <Card className="package-builder-page_loading-screen_wrapper card card-default w-100">
      <div className="package-builder-page_loading-screen_inner">
        <h5>
          <Trans i18nKey="PackageBuilder.building">
            Your Package <strong>{{ title }}</strong> is currently being built
          </Trans>
        </h5>
        <div className="package-builder-page_loading-screen snippet" data-title=".dot-shuttle">
          <div className="package-builder-page_loading-screen stage filter-contrast">
            <div className="package-builder-page_loading-screen dot-falling"></div>
          </div>
        </div>
        <p>
          <Trans i18nKey="PackageBuilder.buildingDuration">
            This could take <strong>10-15 minutes to create.</strong>
          </Trans>
        </p>
        <p></p>
        <br />
        <p>{t('PackageBuilder.waitingOptions')}</p>
        <br />
        <Button text={'Dashboard'} linkTo="/package-builder" className="w-100" />
        <br />
        <br />
      </div>
    </Card>
  )
}

LoadingScreen.propTypes = {
  title: PropTypes.string.isRequired,
}

import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { filesize } from 'filesize'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import { UploadFilesIcon, RemoveIcon, GreenTickIcon, WarningIcon } from 'images'
import DropDown from 'components/Shared/DropDown'
import { actions, fileStatus } from './files'

const DATA_SOURCES = [
  {
    value: 'ticketing',
    text: 'Uploads.dropdownOptions.1',
  },
  {
    value: 'program',
    text: 'Uploads.dropdownOptions.2',
  },
  {
    value: 'other',
    text: 'Uploads.dropdownOptions.3',
  },
]

const displayStatus = (status, errorMessage, percentage = null) => {
  switch (status) {
    case fileStatus.uploading:
      return (
        <div className="accepted-file_status">
          <div className="circular-progress d-flex flex-column align-items-center">
            <CircularProgressbar
              value={percentage}
              strokeWidth={10}
              styles={buildStyles({
                pathColor: '#818181',
                trailColor: '#e8e9ee',
              })}
            />
            <span>{percentage}%</span>
          </div>
        </div>
      )
    case fileStatus.success:
      return (
        <div className="accepted-file_status">
          <div>
            <GreenTickIcon width={24} />
          </div>
        </div>
      )
    case fileStatus.uploadFailed:
      return (
        <div className="accepted-file_status">
          <div className="warning">
            <WarningIcon title={errorMessage} width={24} />
          </div>
        </div>
      )
    default:
      return null
  }
}

const splitFileName = (fileName) => {
  let [newName, ...extension] = fileName.split('.')
  extension = extension.join('.')
  return [newName, extension]
}

const TruncateText = ({ filename, extension }) => {
  const maxLength = 125
  const [truncatedText, setTruncatedText] = useState(filename)
  const textRef = useRef()

  useEffect(() => {
    if (textRef.current.offsetWidth < textRef.current.scrollWidth) {
      // Text is too long, need to truncate it
      let truncated = filename.slice(0, maxLength)
      textRef.current.style.wordWrap = 'break-word'

      while (textRef.current.offsetWidth < textRef.current.scrollWidth) {
        // Remove characters until text fits
        truncated = truncated.slice(0, -1)
        textRef.current.textContent = truncated + '...' + extension
      }
      setTruncatedText(truncated + '...' + extension)
    } else setTruncatedText((prevText) => prevText + '.' + extension)
  }, [filename, maxLength, extension])

  return (
    <div ref={textRef} title={filename}>
      {truncatedText}
    </div>
  )
}

TruncateText.propTypes = {
  filename: PropTypes.string,
  extension: PropTypes.string,
}

const AcceptedFile = ({ file, clearFiles, dispatch }) => {
  const { t } = useTranslation()
  const [fileName, extension] = splitFileName(file.name)

  const handleOnChange = (source) => {
    dispatch({ type: actions.editFileInfo, payload: { name: file.name, category: source } })
  }

  return (
    <div className="accepted-file px-4 py-4" key={file.name}>
      <div className="accepted-file_wrapper row">
        <div className="accepted-file_preview">
          <div className="icons text-left">
            <UploadFilesIcon width={25} />
            <span className="icons_remove-icon">
              <RemoveIcon onClick={clearFiles} width={14} />
            </span>
          </div>
          <div className="text">
            <TruncateText filename={fileName} extension={extension} />
            <div>{filesize(file.size)}</div>
          </div>
        </div>
        <div className="requiredInputBox simulation-form_sim-category-wrap accepted-file_dropdown m-0">
          <DropDown
            onChange={handleOnChange}
            value={file.category}
            options={DATA_SOURCES}
            placeholder={t('Uploads.dropdownSelect')}
            disabled={file.status === fileStatus.uploading}
          />
          {displayStatus(file.status, file.errorMessage, file.progress)}
        </div>
      </div>
    </div>
  )
}

AcceptedFile.propTypes = {
  file: PropTypes.object,
  clearFiles: PropTypes.func,
  dispatch: PropTypes.func,
}

const FilesList = ({ files, clearFiles, dispatch }) => {
  if (files.length) {
    return (
      <div className="files">
        {files.map((file, index) => (
          <AcceptedFile key={file.name} file={file} clearFiles={() => clearFiles(index)} dispatch={dispatch} />
        ))}
      </div>
    )
  }
  return null
}

FilesList.propTypes = {
  files: PropTypes.array,
  clearFiles: PropTypes.func,
  dispatch: PropTypes.func,
}

export default FilesList

import targetingApi from 'api/integration/targeting'

export const actions = {
  setPostalCodes: 'setPostalCodes',
  setMatched: 'setMatched',
  setMatching: 'setMatchedLoading',
  resetPostalCodes: 'resetPostalCodes',
  reset: 'reset',
  clearForm: 'clearForm',

  matchAsync: 'matchAsync',
}

const _initialState = {
  countries: {},
  unmatchedPostalCodes: [],
  loading: false,
  success: null,
  serverError: false,
}

export const initialState = (targeting = _initialState) => {
  return {
    ..._initialState,
    countries: targeting?.postalCodes?.countries ?? {},
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.setPostalCodes:
      return { ...state, ...action.payload }
    case actions.resetPostalCodes:
      return {
        ...state,
        ...initialState(),
        ...(action.payload ?? {}),
      }
    case actions.reset: {
      const { targeting, countries } = action.payload
      
      let newState = {
        ..._initialState,
        countries: state.countries,
      }

      if (targeting) {
        newState = {
          ...newState,
          ...initialState(targeting),
        }
      } else {
        if (countries) {
          newState.countries = countries
        }
      }

      // delete 0 length countries
      Object.keys(newState.countries).forEach((key) => {
        if (!newState.countries[key].length) {
          delete newState.countries[key]
        }
      })

      return newState
    }
    case actions.setMatched: {
      const { matchedPostalCodes, unmatchedPostalCodes, serverError, success } = action.payload
      let countries = matchedPostalCodes.reduce((acc, matchPostalCode) => {
        const countryKey = matchPostalCode.key.split(':')[0]
        const country = acc[countryKey]
        if (country) {
          country.push(matchPostalCode)
        } else {
          acc[countryKey] = [matchPostalCode]
        }

        return acc
      }, {})

      // merge countries with existing countries
      countries = {
        ...state.countries,
        ...countries,
      }

      return {
        ...state,
        countries,
        unmatchedPostalCodes: unmatchedPostalCodes.map((z) => z.replace(/^.*:/, '')),
        success,
        serverError,
        loading: false,
      }
    }
    case actions.setMatching:
      return { ...state, loading: true }
    case actions.clearForm:
      return { ..._initialState, countries: state.countries }
    default:
      return state
  }
}

export const asyncActionHandlers = {
  [actions.matchAsync]:
    ({ dispatch }) =>
      async (action) => {
        const { countryKey, postalCodes } = action.payload
        dispatch({ type: actions.setMatching })

        const { data, error } = await targetingApi.matchPostalCodesAsync(countryKey, postalCodes)
        if (error) {
          console.error('Error matching customers', error)

          dispatch({
            type: actions.setMatched,
            payload: {
              success: false,
              matchedPostalCodes: [],
              unmatchedPostalCodes: [],
              serverError: true,
            },
          })

          return
        }
          
        const { unmatched_zips } = data
        const matchedZips = data.matched_zips.map((z) => (
          {
            key: z.key,
            name: z.name,
            type: z.type,
          }
        ))
        const payload = {
          success: (!unmatched_zips || unmatched_zips.length === 0) && !error,
          matchedPostalCodes: matchedZips ?? [],
          unmatchedPostalCodes: unmatched_zips ?? [],
          serverError: false,
        }

        dispatch({ type: actions.setMatched, payload })
      },
}

export function getCountryPostalCodes(countries, countryCode) {
  if (!countryCode) return []

  return countries[countryCode] ?? []
}

export function getCountryPostalCodeNames(countries, countryCode) {
  return getCountryPostalCodes(countries, countryCode).map((z) => z.name)
}

export function hasValidState(state) {
  return Object.values(state.countries).some(c => c.length)
}

export function totalCountries(countries) {
  return Object.keys(countries).length
}

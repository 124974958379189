import { api } from './_init'
import { LOADING, UPDATE, FAIL, EMPTY } from '../reducers/default'
import { maxBy, minBy, orderBy } from 'lodash'
import { v4 } from 'uuid'

export const fetchSalesPlot = async (dispatch, event_id, translation) => {
  dispatch({ type: LOADING })
  if (event_id) {
    try {
      const result = await api.get(`/events/${event_id}/sales-graph`)

      const series = {
        ticketsSold: [],
        fdPrediction: [],
      }

      const ticketsSold = []

      result.data.sales.forEach((point) => {
        // const x = point.percentile_bin
        const date = new Date(point.mapped_date)
        const x = Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
          date.getUTCMinutes(),
          date.getUTCSeconds(),
        )

        if (point.norm_tickets_sold_current >= 0.0) {
          const y = Number((point.norm_tickets_sold_current * 100).toFixed(3))
          ticketsSold.push([x, y])
        }

        if (point.norm_tickets_sold_avg >= 0) {
          const y = Number((point.norm_tickets_sold_avg * 100).toFixed(3))
          series.fdPrediction.push([x, y])
        }
      })

      const reversedTicketsSold = ticketsSold.reverse()
      const firstValidTicketIndex = reversedTicketsSold.findIndex((t) => t[1] > 0)
      if (firstValidTicketIndex > 0) {
        reversedTicketsSold.splice(0, firstValidTicketIndex)
      }

      series.ticketsSold = reversedTicketsSold.reverse()

      // Tickets sold shouldn't appear if no sales were made.
      if (series.ticketsSold.every((t) => t[1] === 0)) {
        series.ticketsSold = []
      }

      if (series.fdPrediction.length > 0 || series.ticketsSold.length > 0) {
        // data: Expecting [X, Y] where y is %
        const chartData = [
          {
            name: translation.ticketsSold,
            color: '#1F5274',
            data: series.ticketsSold,
          },
          {
            name: translation.fdPrediction,
            color: '#F58562',
            data: series.fdPrediction,
          },
        ]
        dispatch({ type: UPDATE, payload: chartData })
      } else {
        dispatch({ type: EMPTY, payload: 'Event.salesTrend.emptySalesTrends' })
      }
    } catch (error) {
      dispatch({ type: FAIL })
    }
  }
}

const replaceLabel = (number) => {
  let numeral = 'I'

  if (number === 2) {
    numeral = 'II'
  }
  if (number === 3) {
    numeral = 'III'
  }
  if (number === 4) {
    numeral = 'IV'
  }
  if (number === 5) {
    numeral = 'V'
  }
  if (number === 6) {
    numeral = 'VI'
  }
  if (number === 7) {
    numeral = 'VII'
  }
  if (number === 8) {
    numeral = 'VIII'
  }
  if (number === 9) {
    numeral = 'IX'
  }
  if (number === 10) {
    numeral = 'X'
  }
  if (number === 11) {
    numeral = 'XI'
  }
  if (number === 12) {
    numeral = 'XII'
  }
  if (number === 13) {
    numeral = 'XIII'
  }
  if (number === 14) {
    numeral = 'XIV'
  }
  if (number === 15) {
    numeral = 'XV'
  }

  return `Kategorie ${numeral}`
}

export const fetchTicketCategoriesPlot = async (dispatch, event_id) => {
  dispatch({ type: LOADING })
  if (event_id) {
    try {
      const result = await api.get(`/events/${event_id}/tickets`)
      const chartData = []
      const priceCategories = result.data.price_categories

      if (!priceCategories) {
        dispatch({ type: EMPTY, payload: 'Event.salesTrend.emptySalesTrends' })
        return
      }

      Object.keys(priceCategories).filter((key) => {
        if (key !== 'eid' && priceCategories[key] > 0) {
          const name = key.replace(/_/g, ' ')
          let nameCapitalized
          if (!isNaN(Number(name))) {
            nameCapitalized = replaceLabel(Number(name))
          } else {
            nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1)
          }

          chartData.push({ key: key, name: nameCapitalized, y: priceCategories[key] * 100 })
        }
        return null
      })

      dispatch({ type: UPDATE, payload: chartData })
    } catch (error) {
      dispatch({ type: FAIL })
    }
  }
}

export const fetchTicketsTypesPlot = async (dispatch, event_id, translation) => {
  dispatch({ type: LOADING })
  if (event_id) {
    try {
      const result = await api.get(`/events/${event_id}/tickets`)

      const rawData = result.data.tickets_per_category

      const chartData = []

      Object.keys(rawData).map((key) => {
        if (rawData[key] > 0) {
          let name
          let colorClass

          switch (key) {
            case 'regular':
              name = translation.fullPrice
              colorClass = 'bg-navy'
              break
            case 'regular_discount':
              name = translation.regularDiscount
              colorClass = 'bg-orange'
              break
            case 'discount':
              name = translation.discount
              colorClass = 'bg-red'
              break
            case 'subscription':
              name = translation.subscription
              colorClass = 'bg-green'
              break
            default:
              name = key.replace('share_', '').replace('_tickets', '').replace(/_/g, ' ')
              name = name.charAt(0).toUpperCase() + name.slice(1) // Capitalization
              colorClass = 'bg-navy-75'
          }
          chartData.push({ key: key, name: name, y: rawData[key] * 100, colorClass: colorClass })
        }
        return null
      })

      chartData.sort((a, b) => (a.y < b.y ? 1 : -1))

      if (chartData.length > 0) {
        dispatch({ type: UPDATE, payload: chartData })
      } else {
        dispatch({ type: EMPTY, payload: 'Event.salesTrend.emptySalesTrends' })
      }
    } catch (error) {
      dispatch({ type: FAIL })
    }
  }
}

export const fetchTopClusters = async (dispatch, event_id) => {
  dispatch({ type: LOADING })
  if (event_id) {
    try {
      const result = await api.get(`/events/${event_id}/clusters`)

      const rawData = result.data.clusters

      const chartData = []
      rawData.forEach((point, index) => {
        /* value is 0 - 5 and we are transfering it to % */
        const value = point.metric_pred
        const valueInPer = (value / 5) * 100
        chartData.push({ key: `c-${index}`, name: point.tc, y: valueInPer })
      })

      chartData.sort((a, b) => (a.y < b.y ? 1 : -1))

      if (chartData.length > 0) {
        dispatch({ type: UPDATE, payload: chartData })
      } else if (result && result.error) {
        dispatch({ type: FAIL })
      } else {
        dispatch({ type: EMPTY, payload: 'Event.salesTrend.emptySalesTrends' })
      }
    } catch (error) {
      dispatch({ type: FAIL })
    }
  }
}

const mapToSeries = (data) => {
  let series = { revenue: [], tickets: [] }
  for (let i = 0; i < data.length; i++) {
    const el = data[i]
    const date = new Date(el.date)
    const x = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    )

    series.revenue = [...series.revenue, { x: x, y: el.revenue?.total, parts: el.revenue?.parts, id: v4() }]
    series.tickets = [
      ...series.tickets,
      { x: x, y: el.number_of_tickets?.total, parts: el.number_of_tickets?.parts, id: v4() },
    ]
  }
  return series
}

export const fetchDailyRevenue = async (dispatch, event_id, userCurrency) => {
  dispatch({ type: LOADING })

  if (event_id) {
    try {
      const result = await api.get('/daily_revenue_summary/', {
        params: {
          currency: userCurrency,
          start_date: '1900-01-01',
          end_date: '2100-01-01',
          event_id,
        },
      })

      if (result.status === 200) {
        let dailySummary = mapToSeries(result?.data?.summaries ?? [])
        let manualAdjustments = { revenue: [], tickets: [] }

        if (result.data.manual_adjustments?.length > 0) {
          const paddingDays = 2 * 24 * 60 * 60 * 1000 // 2 days
          manualAdjustments = mapToSeries(result?.data?.manual_adjustments ?? [])
          addManualAdjustmentsEdgePadding(manualAdjustments, dailySummary, paddingDays)

          manualAdjustments = {
            revenue: orderBy(manualAdjustments.revenue, ['x']),
            tickets: orderBy(manualAdjustments.tickets, ['x']),
          }
        }

        dailySummary = {
          revenue: orderBy(dailySummary.revenue, ['x']),
          tickets: orderBy(dailySummary.tickets, ['x']),
        }

        const hasData =
          (dailySummary.tickets.length > 0 && dailySummary.revenue.length > 0) ||
          (manualAdjustments.tickets.length > 0 && manualAdjustments.revenue.length > 0)
        dispatch({
          type: UPDATE,
          payload: {
            dailySummary,
            manualAdjustments,
            hasData,
          },
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

/**
 * Adds padding to the edge of the daily summary data based on the manual adjustments data.
 * This is likely not needed anymore, since we're not adjusting plot-lines x position anymore.
 *
 * @param {Object} manualAdjustments - The manual adjustments data.
 * @param {Object} dailySummary - The daily summary data.
 * @param {number} paddingDays - The number of padding days to add.
 */
/**
 * Adds manual adjustments edge padding to the daily summary.
 *
 * @param {Object} manualAdjustments - The manual adjustments object.
 * @param {Object} dailySummary - The daily summary object.
 * @param {number} paddingDays - The number of padding days to add.
 */
function addManualAdjustmentsEdgePadding(manualAdjustments, dailySummary, paddingDays) {
  if (!manualAdjustments) {
    return
  }

  const minAdjustmentDate = Math.min(minBy(manualAdjustments.tickets, 'x').x, minBy(manualAdjustments.revenue, 'x').x)
  const minDailySummaryDate = Math.min(
    minBy(dailySummary.tickets, 'x')?.x ?? Infinity,
    minBy(dailySummary.revenue, 'x').x ?? Infinity,
  )

  if (minAdjustmentDate < minDailySummaryDate) {
    // add a fake entry to daily summary
    const padding = {
      x: minAdjustmentDate - paddingDays,
      y: null,
      parts: {},
      id: v4(),
      fake: true,
    }
    dailySummary.tickets.push(padding)
    dailySummary.revenue.push(padding)
  }

  const maxAdjustment = Math.max(maxBy(manualAdjustments.tickets, 'x').x, maxBy(manualAdjustments.revenue, 'x').x)
  const maxDailySummaryDate = Math.max(
    maxBy(dailySummary.tickets, 'x')?.x ?? Infinity,
    maxBy(dailySummary.revenue, 'x')?.x ?? Infinity,
  )
  if (maxAdjustment >= maxDailySummaryDate) {
    // add a fake entry to daily summary
    const padding = {
      x: maxAdjustment + paddingDays,
      y: null,
      parts: {},
      id: v4(),
      fake: true,
    }
    dailySummary.tickets.push(padding)
    dailySummary.revenue.push(padding)
  }
}

import React, { useState, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import Input from 'components/Shared/Input'
import { updateUserProfile } from 'api/auth'
import { initStateObject, objectReducer } from 'reducers/default'
import { UserNavyIcon } from 'images'
/**
 * Name settings
 * @Tags( settings, name )
 * @NpmPackages( react-i18next )
 */
const LastName = ({ userProfile, handleChange }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [editMode, setEditMode] = useState(false)
  const [formStatus, setFormStatus] = useReducer(objectReducer, initStateObject)

  const handleSubmit = async (e) => {
    e.preventDefault()
    updateUserProfile(dispatch, userProfile, setFormStatus)
    setEditMode(false)
  }

  const handleEdit = () => {
    setEditMode(true)
  }

  return (
    <>
      {formStatus.error && formStatus.message && <p className="alert alert-danger">{t(formStatus.message)}</p>}
      {/* {formStatus.success && <p className="alert alert-info">{t(formStatus.message)}</p>} */}
      <form onSubmit={handleSubmit} className="settings-page__sectionBody">
        <div className="settings-page__sectionColumn w-100">
          <div className="settings-page__sectionIcon">
            <UserNavyIcon width={17} />
          </div>
          <div className="settings-page__sectionWrapper">
            <div className="settings-page__sectionState">
              <h3 className="settings-page__sectionTitle">{t('Settings.lastName.title')}</h3>
              {editMode ? (
                <Input
                  value={userProfile?.family_name || ''}
                  name="lastName"
                  required={true}
                  onChange={handleChange}
                  inputClass="settings-page__sectionInput"
                />
              ) : (
                <p className="settings-page__sectionValue">{userProfile?.family_name}</p>
              )}
            </div>
            <div className="settings-page__sectionColumn">
              <div className="settings-page__sectionEdit">
                {editMode ? (
                  <button style={{ minWidth: 105 }} type="submit" className="btn">
                    {t('Settings.btnActiveMode')}
                  </button>
                ) : (
                  <div onClick={handleEdit} className="btn">
                    {t('Settings.btn')}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

LastName.propTypes = {
  userProfile: PropTypes.object,
  handleChange: PropTypes.func,
}

LastName.defaultProps = {}

export default LastName

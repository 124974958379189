import moment from 'moment'
import * as Sentry from '@sentry/react'

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const UPDATE_TOKEN = 'UPDATE_TOKEN'
export const UPDATE_FEATURES = 'UPDATE_FEATURES'
export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const TOGGLE_FEATURE = 'TOGGLE_FEATURE'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const UPDATE_PARTNER = 'UPDATE_PARTNER'
// export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION'

const initialState = {
  isLogged: false,
  name: '',
  given_name: '',
  family_name: '',
  email: '',
  features: {},
  permissions: [],
  newNotifications: 0,
}

const calcExpirationDate = (expiresInSec) => {
  const tokenExpirationDate = moment().add(expiresInSec, 'seconds').toISOString()
  return tokenExpirationDate
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      Sentry.setUser({ partnerId: action.payload.partnerId, id: action.payload.id })
      const tokenExpirationDate = calcExpirationDate(action.payload.expiresInSec)
      return { ...state, ...action.payload, tokenExpirationDate: tokenExpirationDate, isLogged: true }
    }
    case LOGOUT: {
      localStorage.clear()
      Sentry.setUser(null)
      return { ...initialState }
    }
    case UPDATE_TOKEN: {
      const tokenExpirationDate = calcExpirationDate(state.expiresInSec)
      return { ...state, ...action.payload, tokenExpirationDate: tokenExpirationDate }
    }
    case UPDATE_FEATURES: {
      return { ...state, features: action.payload }
    }
    case UPDATE_PERMISSIONS: {
      return { ...state, permissions: action.payload }
    }
    case UPDATE_PROFILE: {
      return {
        ...state,
        name: action.payload.name,
        given_name: action.payload.given_name,
        family_name: action.payload.family_name,
        profile: action.payload,
      }
    }
    case UPDATE_NOTIFICATION: {
      return { ...state, newNotifications: action.payload }
    }
    case UPDATE_PARTNER: {
      return { ...state, partnerId: action.payload }
    }
    case TOGGLE_FEATURE: {
      console.warn('Featured was toggled.')
      return { ...state, features: { ...state.features, [action.payload]: !state.features[action.payload] } }
    }
    default: {
      return state
    }
  }
}

export default userReducer

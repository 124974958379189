import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const Circle = ({ title, fill = false, step, setCurrentStep, currentCompletedStep, last = false, center = false }) => {
  const handleStepChange = () => {
    if (currentCompletedStep < step) {
      return
    }
    setCurrentStep(step)
  }

  return (
    <div
      id={`package-builder-create-progress-bar-circle-${step}`}
      onClick={handleStepChange}
      className={`package-builder-page_progress-bar_circle_wrapper ${fill ? 'fill' : ''}`}
    >
      <div className={`package-builder-page_progress-bar_circle_inner ${fill ? 'fill' : ''}`} />
      <strong
        className={`package-builder-page_progress-bar_circle_title ${last ? 'last' : ''} ${center ? 'center' : ''}`}
      >
        {title}
      </strong>
    </div>
  )
}

Circle.propTypes = {
  title: PropTypes.string,
  fill: PropTypes.bool,
  step: PropTypes.number,
  setCurrentStep: PropTypes.func,
  currentCompletedStep: PropTypes.number,
  last: PropTypes.bool,
  center: PropTypes.bool,
}

const Line = ({ fill = false, width }) => {
  return (
    <div style={width ? { width } : {}} className="package-builder-page_progress-bar_line_wrapper">
      <div className={`package-builder-page_progress-bar_line_inner ${fill ? 'fill' : ''}`} />
    </div>
  )
}

Line.propTypes = {
  fill: PropTypes.bool,
  width: PropTypes.string,
}

export default function ProgressBar({ currentStep, setCurrentStep, currentCompletedStep }) {
  const { t } = useTranslation()
  return (
    <div
      data-cy="progress-bar"
      data-testid="package-builder-create-progress-bar"
      className="package-builder-page_progress-bar_outer"
    >
      <div className="package-builder-page_progress-bar_wrapper">
        <Circle
          data-testid=""
          fill={true}
          title={t('PackageBuilder.progressBar.events')}
          setCurrentStep={setCurrentStep}
          step={1}
          currentCompletedStep={currentCompletedStep}
        />
        <Line fill={currentStep >= 2} />
        <Circle
          data-testid=""
          fill={currentStep >= 2}
          title={t('PackageBuilder.progressBar.settings')}
          setCurrentStep={setCurrentStep}
          step={2}
          currentCompletedStep={currentCompletedStep}
          center
        />
        <Line fill={currentStep === 3} />
        <Circle
          data-testid=""
          fill={currentStep === 3}
          title={t('PackageBuilder.progressBar.overview')}
          setCurrentStep={setCurrentStep}
          step={3}
          currentCompletedStep={currentCompletedStep}
          last
        />
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  currentStep: PropTypes.number,
  setCurrentStep: PropTypes.func,
  currentCompletedStep: PropTypes.number,
}

import React from 'react'
import PropTypes from 'prop-types'

const labelPlacmentLeft = (percent) => {
  if (percent < 4) {
    return `${20}%`
  }
  if (percent < 8) {
    return `${22}%`
  }
  if (percent < 20) {
    return '28%'
  }
  if (percent < 30) {
    return '31%'
  }
  if (percent < 60) {
    return `${percent}%`
  } else {
    return `${percent}%`
  }
}

const barWidthCalculator = (dataRange, thisPoint) => {
  // this function returns an appropriate width for the bar line
  // and scaling from this

  // sort lowest to highest into 1d array
  const yValues = dataRange.map((point) => point.y).sort((a, b) => a - b)
  // 100% width === the highest value (yValues[yValues.length - 1]) in the array of points

  // if we only have one point, then 100 must be the max value, else use the arrays highest value
  const maxValue = yValues.length > 1 ? yValues[yValues.length - 1] : 100
  const widthPercentage = Math.round((thisPoint * 100) / maxValue)

  // + 5 is for accommodating that even the minimum value needs text space
  // value over 90 do not need extra padding, so return widthPercentage unchanged
  return widthPercentage < 90 ? widthPercentage + 5 : widthPercentage
}
export default function LineChart({ data, order = null }) {
  const _data = order === 'desc'
    ? data.sort((a, b) => b.y - a.y)
    : order === 'asc'
      ? data.sort((a, b) => a.y - b.y)
      : data
  return (
    <ul className="ticket-categories-chart">
      {_data.map((el, index) => (
        <li key={el.key + index} className="ticket-categories-chart__bar">
          <span
            className={`ticket-categories-chart__line ${el.colorClass}`}
            style={
              el.bgColor
                ? { width: `${barWidthCalculator(_data, el.y)}%`, background: el.bgColor }
                : { width: `${barWidthCalculator(_data, el.y)}%` }
            }
          >
            {el.y.toFixed(0)}%
          </span>
          <strong
            className={`ticket-categories-chart__label ${barWidthCalculator(_data, el.y) > 70 ? 'ticket-categories-chart__label--overflow' : ''}`}
            style={{
              textAlign: 'right',
              left: labelPlacmentLeft(el.y) + '5px',
              color: el.y > 95 ? 'white' : '',
            }}
          >
            {el.name}
          </strong>
        </li>
      ))}
    </ul>
  )
}

LineChart.propTypes = {
  data: PropTypes.array,
  order: PropTypes.oneOf(['asc', 'desc']),
}

import React, { useState, useEffect, useReducer, useRef, useMemo } from 'react'
import moment from 'moment'

import { hasPrismaAccessTier } from 'hooks/useHasPermission'
import SimulationFormPrisma from './SimulationFormPrisma'
import SimulationFormDefault from './SimulationFormDefault'
import SessionTimer from 'components/SessionTimer/SessionTimer'
import SimulationFormHeader from './SimulationFormHeader'
import { useToast } from 'components/Shared/AppToast/ToastProvider'
import { useTranslation } from 'react-i18next'
import { initStateObject, objectReducer } from 'reducers/default'
import { useHistory } from 'react-router-dom'
import de from 'date-fns/locale/de'
import { registerLocale } from 'react-datepicker'
import { sendSimulationForm } from 'api/simulation'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import PrimaryButton from 'components/Shared/Button'
import ErrorMessage from 'components/Shared/ErrorMessage'
import { eventCategories } from '../constants'

registerLocale('de', de) // ??

const REQUIRED_FIELDS = {
  PRODUCT: ['date', 'category'],
  DEFAULT: ['date', 'time', 'category', 'city', 'venue', 'hall', 'capacity', 'price'],
}

const formatData = (name, category, description, fields, tz, lineups = [], setlists = []) => {
  let hasError = false
  let errorMessage = 'common.errors.fillRequired'

  // Helper function to check if all required fields are present
  const validateRequiredFields = (items, requiredFields) => {
    return items.every((item) => requiredFields.every((field) => !!item[field]))
  }

  // Validation for lineups and setlists when not using Prisma
  if (category !== 'product') {
    if (
      !validateRequiredFields(lineups, ['artist_name', 'artist_type']) ||
      !validateRequiredFields(setlists, ['artist_name', 'track'])
    ) {
      hasError = true
    }
  }

  const requiredFields = category === 'product' ? REQUIRED_FIELDS.PRODUCT : REQUIRED_FIELDS.DEFAULT

  if (!name) hasError = true

  const formattedFields = fields.map((field) => {
    let { venue, capacity, date, time, hall, price, category, city } = field

    // Validate required fields
    for (const key of requiredFields) {
      if (!field[key]) hasError = true
    }

    // Additional validation for non-product
    if (category !== 'product') {
      if (
        (capacity && (capacity < 0 || !Number.isInteger(Number(capacity)))) ||
        (price && price < 0)
      ) {
        hasError = true
        errorMessage = 'Simulation.form.errors.invalidFields'
      }
      // Convert capacity to an integer if it's a string
      if (typeof capacity === 'string') capacity = parseInt(capacity, 10)
    }

    return {
      name,
      description,
      venue,
      capacity,
      date,
      time,
      city,
      category,
      hall,
      price,
      lineups,
      setlists,
      tz,
      status: 'RUNNING',
    }
  })

  if (hasError) {
    return { error: true, errMsg: errorMessage, resp: null }
  }

  return { error: false, resp: formattedFields }
}

export default function SimulationForm() {
  const hasPrismaAccess = hasPrismaAccessTier()

  const { addNotification } = useToast()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [formStatus, updateFormStatus] = useReducer(objectReducer, initStateObject)
  const [error, setError] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [tz, setTz] = useState('')

  const [simName, setSimName] = useState('')
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState(hasPrismaAccess ? 'product' : eventCategories[0].value)

  const [lineups, setLineups] = useState(
    hasPrismaAccess
      ? []
      : [
        {
          artist_name: '',
          artist_type: '',
        },
        {
          artist_name: '',
          artist_type: '',
        },
      ],
  )

  const [setlists, setSetlists] = useState(
    hasPrismaAccess
      ? []
      : [
        {
          artist_name: '',
          track: '',
        },
        {
          artist_name: '',
          track: '',
        },
      ],
  )

  const firstRender = useRef(true)
  const history = useHistory()

  const [fields, setFields] = useState([
    {
      date: '',
    },
  ])

  const addField = () => {
    setFields([
      ...fields,
      {
        date: '',
      },
    ])
  }

  const removeField = (index) => {
    setFields(fields.filter((item, i) => index !== i))
  }

  useEffect(() => {
    if (formStatus.error && formStatus.message) {
      setError(formStatus.message)
    }
  }, [formStatus])

  const showProductForm = useMemo(() => category === 'product', [category])

  const updateInput = (e, idx) => {
    const { name, value } = e.target

    if (name === 'name') {
      setSimName(value)
    } else if (name === 'description') {
      setDescription(value)
    } else {
      const currentFields = [...fields]
      currentFields[idx][name] = value
      setFields(currentFields)
    }
  }

  const updateDate = (date, idx) => {
    const formattedDate = date ? moment(date).format('DD.MM.YYYY') : ''
    const formattedTime = date ? moment(date).format('HH:mm') : ''
    const formattedTimezone = date ? Intl.DateTimeFormat().resolvedOptions().timeZone : ''

    const updatedFields = [...fields]
    updatedFields[idx].time = formattedTime
    updatedFields[idx].date = formattedDate

    setFields(updatedFields)
    setTz(formattedTimezone)
    setStartDate(date)
  }

  const submitForm = (e) => {
    e.preventDefault()
    setLoading(true)
    setError('')

    const fieldsWithCategory = fields.map((eachField) => {
      return {
        category,
        ...eachField,
      }
    })

    const {
      error,
      errMsg,
      resp: formDataArray,
    } = formatData(simName, category, description, fieldsWithCategory, tz, lineups, setlists)
    if (error) {
      setError(errMsg)
      setLoading(false)
      return
    }
    const sendFormAsync = async () => {
      for (let i = 0; i < formDataArray.length; i++) {
        try {
          await sendSimulationForm(
            updateFormStatus,
            formDataArray[i],
            hasPrismaAccess ? 'prisma' : 'fdlive',
          )
        } catch (error) {
          setError(error.message)
        }
      }
    }
    sendFormAsync()
    setLoading(false)
  }

  useEffect(() => {
    // timeout used to allow user time to see that submission is successful
    let timeoutId
    if (formStatus.success) {
      addNotification({
        message: t('Toasts.Simulation.eventAdded.message'),
        description: t('Toasts.Simulation.eventAdded.description'),
      })
      timeoutId = setTimeout(() => {
        history.push('/')
      }, 2000)
    }
    return () => clearTimeout(timeoutId)
  }, [formStatus.success, addNotification, history, t])

  useEffect(() => {
    // Determine if fields were changed in any way while skipping the page's first render.
    if (firstRender.current) {
      firstRender.current = false
      return
    }
  }, [fields, category, simName])

  return (
    <form className="simulation-form my-4" onSubmit={(e) => e.preventDefault()} noValidate>
      <SessionTimer classes="mx-lg-0" />
      <SimulationFormHeader eventCategory={category} />
      <>
        {showProductForm ? (
          <SimulationFormPrisma
            form={{ simName, description, category, startDate }}
            fields={fields}
            onAddField={addField}
            onRemoveField={removeField}
            onUpdateInput={updateInput}
            onUpdateDate={updateDate}
            onCategoryChanged={setCategory}
          />
        ) : (
          <SimulationFormDefault
            form={{ simName, description, category, startDate, lineups, setlists, tz }}
            onSetlistsChanged={setSetlists}
            onLineupsChanged={setLineups}
            fields={fields}
            onAddField={addField}
            onRemoveField={removeField}
            onUpdateInput={updateInput}
            onUpdateDate={updateDate}
            onCategoryChanged={setCategory}
          />
        )}
        <div className="row mb-md-5 mb-lg-0">
          <div className="col-12 mb-md-5">
            <div className="d-flex flex-row justify-content-end mb-md-4">
              <div>
                {loading ? (
                  <LoadingSpinner title={t('common.sending')} portalBackground={true} />
                ) : (
                  <PrimaryButton
                    type="button"
                    onClick={submitForm}
                    text={t('Simulation.form.btn')}
                    disabled={loading || formStatus.success}
                    classNames="simulation-btn"
                  />
                )}
              </div>
            </div>
            {error && <ErrorMessage danger>{t(error)}</ErrorMessage>}
          </div>
        </div>
      </>
    </form>
  )
}

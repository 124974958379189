import React, { useState, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { newUser, newUserConfirm } from '../../api/auth'
import { initStateObject, objectReducer, FAIL } from '../../reducers/default'

import { checkIfPasswordIsSecureEnough } from '../../utils/helpers'

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  confirmCode: '',
}

/**
 * Registration form connected to Cognito AWS in the Backend.
 * @Tags( cognito, api )
 * @Inputs( name, email, password, confirmPassword, confirmationCode )
 * @Endpoints( /auth/partners/partnerId/users, /auth/partners/users/confirm )
 * @ApiLogic( ../../api/auth )
 */
const RegistrationForm = ({ partnerId }) => {
  const { t } = useTranslation()
  const [formStatus, setFormStatus] = useReducer(objectReducer, initStateObject)
  const [state, setState] = useState(initialState)

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value })
  }

  const validateForm = () => {
    if (
      !(
        state.firstName.length > 0 &&
        state.lastName.length > 0 &&
        state.email.length > 0 &&
        state.password.length > 0 &&
        state.confirmPassword.length > 0
      )
    ) {
      setFormStatus({ type: FAIL, payload: t('common.errors.fillRequired') })
      return false
    } else if (state.password !== state.confirmPassword) {
      setFormStatus({ type: FAIL, payload: t('common.errors.wrongConfirmPassword') })
      return false
    }
    return true
  }

  const validateConfirmationForm = () => {
    return state.confirmCode.length > 0
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!validateForm()) {
      return
    }

    try {
      const { email, password, firstName, lastName } = state

      if (checkIfPasswordIsSecureEnough(password).status === false) {
        setFormStatus({ type: FAIL, payload: t(checkIfPasswordIsSecureEnough(password).message) })
        return
      }
      await newUser(setFormStatus, {
        email,
        password,
        given_name: firstName,
        family_name: lastName,
        partner_id: partnerId,
      })
    } catch (error) {
      console.error(error)
      setFormStatus({ type: FAIL, payload: t('common.errors.onUpload') })
    }
  }

  const handleConfirmationSubmit = async (event) => {
    event.preventDefault()
    try {
      const { email, confirmCode } = state

      await newUserConfirm(setFormStatus, {
        email,
        conf_code: confirmCode,
      })
    } catch (error) {
      console.error(error)
      setFormStatus({ type: FAIL, payload: t('common.errors.onUpload') })
    }
  }

  const renderForm = () => {
    const { firstName, lastName, email, password, confirmPassword } = state

    return (
      <form onSubmit={handleSubmit}>
        {formStatus.error && formStatus.message && (
          <p data-testid="errorForm" className="alert alert-danger">
            {t(formStatus.message)}
          </p>
        )}
        <div className="form-group">
          <input
            type="text"
            className="form-control s-50"
            id="firstName"
            placeholder={t('common.firstName')}
            value={firstName}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control s-50"
            id="lastName"
            placeholder={t('common.lastName')}
            value={lastName}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            className="form-control s-50"
            id="email"
            placeholder={t('common.email')}
            value={email}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control s-50"
            id="password"
            placeholder={t('common.password')}
            value={password}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control s-50"
            id="confirmPassword"
            placeholder={t('common.confirmPassword')}
            value={confirmPassword}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <button type="submit" className="bt bt-navy s-50 w-100">
          {formStatus.loading ? t('Registration.form.btnLoading') : t('Registration.form.btn')}
        </button>
      </form>
    )
  }

  const renderConfirmationForm = () => {
    const { confirmCode } = state
    return (
      <form onSubmit={handleConfirmationSubmit} autoComplete="off">
        <p className="alert alert-primary text-center">{t('Registration.form.emailTip')}</p>
        {formStatus.error && formStatus.message && (
          <p data-testid="errorForm" className="alert alert-danger">
            {t(formStatus.message)}
          </p>
        )}
        {console.log('t( formStatus.message ): ', t(formStatus.message))}
        <div className="form-group">
          <input
            type="tel"
            className="form-control s-50"
            id="confirmCode"
            placeholder={t('Registration.form.confirmCode')}
            autoComplete="confirm-code"
            value={confirmCode}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <button
          type="submit"
          className="bt bt-navy s-50 w-100"
          disabled={!validateConfirmationForm()}
          data-testid="btnForm"
        >
          {formStatus.loading ? t('Registration.form.btnVerifying') : t('Registration.form.btnVerify')}
        </button>
      </form>
    )
  }

  const renderSuccess = () => {
    return (
      <div className="text-center">
        <p data-testid="successForm" className="alert alert-primary text-center">
          {t('Registration.form.success')}
        </p>
        <Link to="/login" className="bt bt-navy s-50 w-100">
          {t('common.logIn')}
        </Link>
      </div>
    )
  }

  return (
    <div className="signup-form">
      {!formStatus.success && !formStatus.content.emailSent && renderForm()}
      {formStatus.content.emailSent && !formStatus.content.confirmed && renderConfirmationForm()}
      {formStatus.content.confirmed && renderSuccess()}
    </div>
  )
}

RegistrationForm.propTypes = {
  partnerId: PropTypes.string.isRequired,
}

export default RegistrationForm

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import FirstName from './FirstName'
import LastName from './LastName'

const Name = () => {
  const user = useSelector((state) => state.user)
  const [userProfile, setUserProfile] = useState(user.profile)

  const changeGivenName = (e) => {
    const given_name = e.target.value
    const full_name = userProfile.family_name ? given_name + ' ' + userProfile.family_name : given_name

    setUserProfile((prevState) => {
      return { ...prevState, given_name, name: full_name }
    })
  }

  const changeFamilyName = (e) => {
    const family_name = e.target.value
    const full_name = userProfile.given_name ? userProfile.given_name + ' ' + family_name : family_name

    setUserProfile((prevState) => {
      return { ...prevState, family_name, name: full_name }
    })
  }

  return (
    <>
      <div className="settings-page__section">
        <FirstName userProfile={userProfile} handleChange={changeGivenName} />
      </div>
      <div className="settings-page__section">
        <LastName userProfile={userProfile} handleChange={changeFamilyName} />
      </div>
    </>
  )
}

export default Name

import React from 'react'
import { useSelector } from 'react-redux'

import Email from './Email'
import Name from './Name'
import LanguageSwitcher from 'components/Shared/LanguageSwitcher'
import MultiLoginSwitcher from './MultiClientLogin'

export default function PersonalSettings() {
  const user = useSelector((state) => state.user)

  return (
    <>
      <div className="settings-page__section">
        <Email />
      </div>
      <Name />
      <div className="settings-page__section" style={{ overflow: 'visible' }}>
        <LanguageSwitcher settings />
      </div>
      {
        //Only show MultiLoginSwitcher when there are multiple partners available for user
        user.cognitoGroups && user.cognitoGroups.length > 1 ? (
          <div className="settings-page__section">
            <MultiLoginSwitcher />
          </div>
        ) : null
      }
    </>
  )
}

import React from 'react'

import { SecondaryButton } from '../../Shared/Button'

import failureLogo from '../../../images/icons/failure_logo.svg'
import { useTranslation } from 'react-i18next'

const FailureScreen = ({ closeModal }) => {
  const { t } = useTranslation()

  return (
    <div style={{ minWidth: 500, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
        <h1 className="events-page main-title">EVENTS UPLOAD</h1>
      </div>

      <div style={{ marginTop: '3em' }}>
        <img src={failureLogo} style={{ width: 80, height: 80 }} alt={'success tick'} />
      </div>

      <p className="mt-4 ta-c">{t('Simulation.bulkUpload.errors.uploadFailed')}</p>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2em', width: '100%' }}>
        <SecondaryButton text={'Close'} onClick={closeModal} fullWidth={false} />
      </div>
    </div>
  )
}

export default FailureScreen

import React from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'

import Layout from '../components/Layout'

import { useLocation } from 'react-router-dom'

/**
 * Default 404 page.
 * @Tags( page, layout )
 * @Routes( /404, /random-string )
 */
const Error404 = () => {
  const { t } = useTranslation()
  let location = useLocation()

  const title = location.state && location.state.title ? location.state.title : t('Error404.MainTitle')
  const message =
    location.state && location.state.message ? location.state.message : t('Error404.PageTitleReason.default')

  return (
    <Layout
      loggedIn
      title={`${t('Error404.PageTitle')} - ${message}`}
      mobileTopBar={{ back: true, centerTitle: title, hideRight: true }}
    >
      <Helmet bodyAttributes={{ class: 'events-insights-page mobile-bg-white' }} />
      <main>
        <div className="container-fluid pt-5 pb-5">
          <div className="row">
            <div className="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-4">
              <div className="card card-default text-center">
                <h2 className="card-title-default">{title}</h2>
                <div className="card-body">{message}</div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Error404

import React, { useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Trans, useTranslation } from 'react-i18next'
import classNames from 'classnames'
import PropTypes, { object } from 'prop-types'

import InfoBox from 'components/Shared/InfoBox'
import { numberWithThousandsSeparators } from 'utils/helpers'
import { Accordion, Card } from 'react-bootstrap'
import moment from 'moment'
import EventStatsPDF from '../EventStatsPDF'
import {
  EventCampaignResultsContext,
  EventCampaignResultsDispatchContext,
  EventFbInsightsContext,
  EventFbInsightsDispatchContext,
} from './EventStatsProvider'
import TipDialogueBox from 'components/Shared/TipDialogueBox'
import EventStatsOverview from './EventStatsOverview'
import { isCampaignRunning } from 'api/models'
import { initStateObject, objectReducer } from 'reducers/default'
import { fetchCampaignResults, fetchCustomConversionById, fetchEventFbInsights } from 'api/integrations'
import { useAttributionModel } from 'domain/events/attributionModel/EventAttributionModelContextProvider'
import { ATTRIBUTION_MODELS } from 'domain/events/attributionModel/eventAttributionModelReducer'
import EventCard from './EventCard'

const EventStatistics = ({ event, eventSeries, campaigns = [], isSideDrawer = false }) => {
  const { t } = useTranslation()
  const [activeKey, setActiveKey] = useState(null)
  const user = useSelector((store) => store.user)
  const [userCurrency, setUserCurrency] = useState('')
  const [adCreatives, setAdCreatives] = useState({ totalAds: 0, adSuggestions: { regular: [], reels: [] } })
  const campaignResults = useContext(EventCampaignResultsContext)
  const fbInsights = useContext(EventFbInsightsContext)
  const runningCampaigns = campaigns.filter((campaign) => isCampaignRunning(campaign))
  const includedEventsRef = useRef(null)
  const openIncludedEvents = new URL(window.location.href).searchParams.get('open_included_events')
  const { attributionModel } = useAttributionModel()
  const dispatchCampaignResultsAction = useContext(EventCampaignResultsDispatchContext)
  const dispatchFbInsightsAction = useContext(EventFbInsightsDispatchContext)

  const [{ content: campaignConversion }, dispatchCampaignConversionAction] = useReducer(objectReducer, {
    ...initStateObject,
  })

  const hasExternalAttributionModel = useMemo(
    () => attributionModel === ATTRIBUTION_MODELS.external,
    [attributionModel],
  )

  useEffect(() => {
    // refetch results and insights when attribution model changes
    fetchCampaignResults(dispatchCampaignResultsAction, event.id)
    fetchEventFbInsights(dispatchFbInsightsAction, event.id)
  }, [event.id, attributionModel, dispatchCampaignResultsAction, dispatchFbInsightsAction])

  useEffect(() => {
    const currency = localStorage.getItem('userCurrency')
    if (currency) {
      setUserCurrency(currency)
    }
  }, [])

  const isShortCampaign = useMemo(() => {
    return (
      attributionModel !== ATTRIBUTION_MODELS.meta &&
      campaignResults.error &&
      campaignResults.message === 'Campaign timeframe too low to evaluate!'
    )
  }, [campaignResults, attributionModel])

  const {
    event_results: eventStats,
    series_results: eventSeriesStats,
    eval_name: conversion_id,
    ad_account_id,
  } = campaignResults.content

  useEffect(() => {
    if (attributionModel === ATTRIBUTION_MODELS.meta && ad_account_id && conversion_id) {
      fetchCustomConversionById(dispatchCampaignConversionAction, ad_account_id, conversion_id)
    }
  }, [ad_account_id, conversion_id, dispatchCampaignConversionAction, attributionModel])

  const ticketsTitle =
    attributionModel !== ATTRIBUTION_MODELS.meta
      ? 'Dashboard.eventSeriesStats.tickets.title'
      : campaignConversion?.name
        ? 'Dashboard.eventSeriesStats.conversion'
        : 'Dashboard.eventSeriesStats.purchases.title'

  const attributionModelBasedEventSeriesStats = useMemo(
    () => (attributionModel === ATTRIBUTION_MODELS.fd ? eventSeriesStats : {}),
    [attributionModel, eventSeriesStats],
  )
  const attributionModelBasedEventStats = useMemo(
    () => (attributionModel === ATTRIBUTION_MODELS.external ? {} : eventStats),
    [attributionModel, eventStats],
  )

  const currency = userCurrency || 'CHF'
  const filteredEventSeries =
    attributionModel === ATTRIBUTION_MODELS.meta ? [] : (eventSeries?.series || []).filter((el) => el.id !== event.id)
  const numOfTCs = runningCampaigns.length

  openIncludedEvents && includedEventsRef.current && includedEventsRef.current.scrollIntoView(true)

  return (
    <div className="d-flex flex-column gap-4 mt-4">
      <EventStatsOverview
        isSideDrawer={isSideDrawer}
        fbInsights={fbInsights}
        currency={currency}
        runningCampaigns={runningCampaigns}
        setAdCreatives={setAdCreatives}
      />

      <div className="event-series-stats">
        <div className={`${isShortCampaign || hasExternalAttributionModel ? 'event-series-stats_disabled-card' : ''}`}>
          <div className="row py-md-4">
            <div className="col-md-6 col-12">
              <InfoBox
                title={t(ticketsTitle)}
                body={
                  <Trans
                    i18nKey="Dashboard.eventSeriesStats.purchases.description"
                    components={{
                      a: (
                        <a
                          href="https://support.future-demand.com/event-series"
                          className="infobox-tooltip__link"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Find Answers
                        </a>
                      ),
                      p: <p>Evaluation for event series will only be possible for FD attribution model</p>,
                    }}
                  />
                }
                delay={300}
              >
                <h2 className="card-title-default d-inline-block mb-0">{t(ticketsTitle)}</h2>
              </InfoBox>

              <div className="event-series-stats_data">
                <div className="event event_single">
                  <span className="bold">
                    {numberWithThousandsSeparators(attributionModelBasedEventStats?.total_transactions)}
                  </span>
                  <span className="ellipsis">
                    {attributionModel === ATTRIBUTION_MODELS.meta && !!campaignConversion?.name
                      ? campaignConversion.name
                      : t('Dashboard.eventSeriesStats.singleEvent')}
                  </span>
                </div>
                <div className="event event_series">
                  <span className="bold">
                    {numberWithThousandsSeparators(attributionModelBasedEventSeriesStats?.total_transactions)}
                  </span>
                  <span>{t('Dashboard.eventSeriesStats.eventSeries')}</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <InfoBox
                content="Dashboard.eventSeriesStats.adSpendPerTicket"
                body={
                  <Trans
                    i18nKey="Dashboard.eventSeriesStats.adSpendPerTicket.description"
                    components={{
                      a: (
                        <a
                          href="https://support.future-demand.com/event-series"
                          className="infobox-tooltip__link"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Find Answers
                        </a>
                      ),
                      p: <p>Evaluation for event series will only be possible for FD attribution model</p>,
                    }}
                  />
                }
                delay={300}
              >
                <h2 className="card-title-default d-inline-block mb-0">
                  {t('Dashboard.eventSeriesStats.adSpendPerTicket.title')}
                </h2>
              </InfoBox>
              <div className="event-series-stats_data">
                <div className="event event_single">
                  <span className="bold">
                    {numberWithThousandsSeparators(attributionModelBasedEventStats?.cpo, {
                      currency,
                      maxFractionDigits: 0,
                    })}
                  </span>
                  <span>{t('Dashboard.eventSeriesStats.singleEvent')}</span>
                </div>
                <div className="event event_series">
                  <span className="bold">
                    {numberWithThousandsSeparators(attributionModelBasedEventSeriesStats?.cpo, {
                      currency,
                      maxFractionDigits: 0,
                    })}
                  </span>
                  <span>{t('Dashboard.eventSeriesStats.eventSeries')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <InfoBox
                title={t('Dashboard.eventSeriesStats.revenue.title')}
                body={
                  <Trans
                    i18nKey="Dashboard.eventSeriesStats.revenue.description"
                    components={{
                      a: (
                        <a
                          href="https://support.future-demand.com/event-series"
                          className="infobox-tooltip__link"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Find Answers
                        </a>
                      ),
                      p: <p>Evaluation for event series will only be possible for FD attribution model</p>,
                    }}
                  />
                }
                delay={300}
              >
                <h2 className="card-title-default d-inline-block mb-0">
                  {t('Dashboard.eventSeriesStats.revenue.title')}
                </h2>
              </InfoBox>
              <div className="event-series-stats_data">
                <div className="event event_single">
                  <span className="bold">
                    {numberWithThousandsSeparators(attributionModelBasedEventStats?.total_revenue, {
                      currency,
                      maxFractionDigits: 0,
                    })}
                  </span>
                  <span>{t('Dashboard.eventSeriesStats.singleEvent')}</span>
                </div>
                <div className="event event_series">
                  <span className="bold">
                    {numberWithThousandsSeparators(attributionModelBasedEventSeriesStats?.total_revenue, {
                      currency,
                      maxFractionDigits: 0,
                    })}
                  </span>
                  <span>{t('Dashboard.eventSeriesStats.eventSeries')}</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <InfoBox
                content="Dashboard.eventSeriesStats.roi"
                body={
                  <Trans
                    i18nKey="Dashboard.eventSeriesStats.roi.description"
                    components={{
                      a: (
                        <a
                          href="https://support.future-demand.com/event-series"
                          className="infobox-tooltip__link"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Find Answers
                        </a>
                      ),
                      p: <p>Evaluation for event series will only be possible for FD attribution model</p>,
                    }}
                  />
                }
                delay={300}
              >
                <h2 className="card-title-default d-inline-block mb-0">{t('Dashboard.eventSeriesStats.roi.title')}</h2>
              </InfoBox>
              <div className="event-series-stats_data">
                <div className="event event_single">
                  <span className="bold">
                    {numberWithThousandsSeparators(attributionModelBasedEventStats?.roas, {
                      maxFractionDigits: 0,
                      suffix: '%',
                    })}
                  </span>
                  <span>{t('Dashboard.eventSeriesStats.singleEvent')}</span>
                </div>
                <div className="event event_series">
                  <span className="bold">
                    {numberWithThousandsSeparators(attributionModelBasedEventSeriesStats?.roas, {
                      maxFractionDigits: 0,
                      suffix: '%',
                    })}
                  </span>
                  <span>{t('Dashboard.eventSeriesStats.eventSeries')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {(isShortCampaign || hasExternalAttributionModel) && (
          <div className="event-series-stats disabled-overlay">
            {hasExternalAttributionModel && (
              <TipDialogueBox
                title={t('common.note')}
                text={t('Dashboard.eventSeriesStats.externalAttributionModelNotice')}
                variant="danger"
                showBorder
                compact
              />
            )}
            {isShortCampaign && !hasExternalAttributionModel && (
              <TipDialogueBox
                title={t('Dashboard.eventSeriesStats.runtimeAlert.title')}
                text={t('Dashboard.eventSeriesStats.runtimeAlert.description')}
                variant="danger"
                showBorder
                compact
              />
            )}
          </div>
        )}
      </div>

      <div
        className={`event-series-stats_event-list ${isShortCampaign ? 'event-series-stats_disabled-card' : ''}`}
        id="campaigns-container"
      >
        {!!filteredEventSeries.length && (
          <Accordion ref={includedEventsRef} defaultActiveKey={openIncludedEvents ? '0' : null}>
            <Card className="goals-card">
              <Accordion.Toggle eventKey="0">
                <Card.Header
                  as={Card.Header}
                  className={classNames({
                    'goals-card_header pt-3': true,
                    open: activeKey === '0' || openIncludedEvents,
                  })}
                  onClick={() => setActiveKey((activeKey && activeKey === '0') || openIncludedEvents ? null : '0')}
                >
                  <InfoBox withAnchor={false}>{t('Dashboard.eventSeriesStats.includedEvents')}</InfoBox>
                  <div className="align-self-center ml-auto">
                    <div className={`accordion-arrow ${activeKey === '0' || openIncludedEvents ? 'open' : ''}`} />
                  </div>
                </Card.Header>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <div className="d-flex flex-column p-0">
                    {filteredEventSeries.map((el) => {
                      return <EventCard event={el} key={el.id} />
                    })}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}

        {event.title && (
          <PDFDownloadLink
            document={
              <EventStatsPDF
                fbInsights={fbInsights}
                eventStats={eventStats}
                eventSeriesStats={eventSeriesStats}
                eventSeries={filteredEventSeries}
                eventInfo={event}
                user={user}
                numOfTCs={numOfTCs}
                adCreatives={adCreatives}
              />
            }
            fileName={`${event.title}_campaign-stats_${moment().format('DD-MM-yyyy')}.pdf`}
          >
            {({ loading }) =>
              loading ? (
                t('Event.statistics.generatingDocument')
              ) : (
                <button style={{ width: '100%' }} className="bt s-38 bt-orange">
                  {t('Dashboard.eventSeriesStats.save')}
                </button>
              )
            }
          </PDFDownloadLink>
        )}
      </div>
    </div>
  )
}

EventStatistics.propTypes = {
  event: PropTypes.object.isRequired,
  eventSeries: PropTypes.object.isRequired,
  campaigns: PropTypes.arrayOf(object),
  isSideDrawer: PropTypes.bool,
}

export default EventStatistics

import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { SecondaryButton, TransparentBgButton } from '../../Shared/Button'

export default function CancelSetupModalContent({ onCancel, onConfirm }) {
  const { t } = useTranslation()

  return (
    <div className="yes-no-prompt">
      <div className="yes-no-prompt_content">
        <h1>{t('Recommendations.cancelSetup.cta')}</h1>
        <p>{t('Recommendations.cancelSetup.body')}</p>
      </div>
      <div className="yes-no-prompt_footer">
        <TransparentBgButton text={t('Recommendations.cancelSetup.btnCancel')} onClick={onCancel} color="navy" />
        <SecondaryButton
          className="bt"
          text={t('Recommendations.cancelSetup.btnConfirm')}
          fullWidth={false}
          onClick={onConfirm}
        />
      </div>
    </div>
  )
}

CancelSetupModalContent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Header from './Shared/Header'

import '../styles/app.scss'

import {
  isFeatureFlagOn,
  toggleFeatureFlag,
  TOGGLE_FEATURES_IN_CONSOLE,
  SHOW_SIMULATION,
  EDIT_CREATIVE,
  SHOW_NOTIFICATION,
} from '../utils/flag-feature'
import { useContext } from 'react'
import { LayoutContext } from '../App'
import AppToastContainer from './Shared/AppToast/AppToastContainer'

/**
 * Layout - used in all pages, it updates the title of the page.
 * @Tags( layout, shared, presentational )
 */

const Content = ({ sidebarExpanded, children }) => {
  return (
    <div className={`layout__wrapper layout__wrapper-${sidebarExpanded ? 'expanded' : 'collapsed'}`}>
      {children}
      <AppToastContainer />
    </div>
  )
}

Content.propTypes = {
  sidebarExpanded: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

const Layout = ({ children, title, loggedIn, mobileTopBar }) => {
  const features = useSelector((store) => store.user.features)
  const dispatch = useDispatch()
  const layout = useContext(LayoutContext)

  useEffect(() => {
    if (title) {
      document.title = `${title} - future demand`
    }
    return () => {
      document.title = 'future demand'
    }
  })

  if (loggedIn === true) {
    const bodyAttributes = {
      'data-logged-in': 'true',
    }

    if (isFeatureFlagOn(features, TOGGLE_FEATURES_IN_CONSOLE)) {
      window.toggleFeatureSimulation = () => {
        toggleFeatureFlag(dispatch, SHOW_SIMULATION)
      }
      window.toggleFeatureEditCreative = () => {
        toggleFeatureFlag(dispatch, EDIT_CREATIVE)
      }
      window.toggleFeatureNotification = () => {
        toggleFeatureFlag(dispatch, SHOW_NOTIFICATION)
      }
    }

    if (isFeatureFlagOn(features, SHOW_SIMULATION)) {
      bodyAttributes['data-show-simulation'] = true
    }

    if (isFeatureFlagOn(features, SHOW_NOTIFICATION)) {
      bodyAttributes['data-show-notification'] = true
    }

    return (
      <>
        <Helmet bodyAttributes={bodyAttributes} />
        <Header mobileTopBar={mobileTopBar} />
        <Content sidebarExpanded={layout.sidebarExpanded}>{children}</Content>
      </>
    )
  } else {
    return <>{children}</>
  }
}

Layout.propTypes = {
  /**
   * Used in browser bookmark
   */
  title: PropTypes.string.isRequired,
  /**
   * Content of the page
   */
  children: PropTypes.node.isRequired,
  /**
   * If user is logged in
   */
  loggedIn: PropTypes.bool,
  /**
   * Optional set up for MobileTopBar component
   */
  mobileTopBar: PropTypes.object,
}

export default Layout

import { api } from './_init'
import { LOADING, UPDATE, FAIL } from '../reducers/default'
import { returnErrorMessage } from 'utils/helpers'

export const fetchPartnerDetails = async (dispatch) => {
  dispatch({ type: LOADING })
  try {
    const result = await api.get('/partners/')
    if (result.status === 200) {
      localStorage.setItem(
        'partnerDetails',
        JSON.stringify({
          clientId: result.data.id,
          domain: result.data.domain,
          campaignRecommendationsModel: result.data.campaign_recommendations,
          demandPredictionModel: result.data.demand_prediction,
          vertical: result.data.vertical,
          attributionModel: result.data.attribution_model_default,
          product: result.data.product?.split('.')[1],
        }),
      )
      if (result.data.currency) localStorage.setItem('userCurrency', result.data.currency)
      if (result.data.attribution_model_default) {
        localStorage.setItem('attributionModel', result.data.attribution_model_default?.split('.')[1])
      }
    }
    dispatch({ type: UPDATE, payload: result.data })
  } catch (error) {
    dispatch({ type: FAIL })
  }
}

export const updateAttributionModel = async (dispatch, attributionModel) => {
  dispatch({ type: LOADING })
  try {
    const result = await api.put(`/partners/attribution_model?model=${attributionModel}`)
    if (result.status === 204) {
      localStorage.setItem('attributionModel', attributionModel)
      dispatch({ type: UPDATE, payload: { attributionModel } })
    } else {
      dispatch({ type: FAIL, payload: returnErrorMessage({ result })})
    }
  } catch (error) {
    dispatch({ type: FAIL })
  }
}

import React from 'react'
import PropTypes, { number, object, string } from 'prop-types'
import { useTranslation } from 'react-i18next'

// Dynamically create tabs and organizes content according to tabsData prop
// const tabsData = [
//     {
//         title: 'tab one title',
//         component: <ComponentOne {...props} />
//     },
//     {
//         title: 'tab two title',
//         component: <ComponentTwo {...props} />
//     },
// ]

const Pill = ({ handleTabChange, index, isActive, title, disabled }) => {
  const { t } = useTranslation()
  return (
    <div
      onClick={() => {
        if (!disabled) {
          handleTabChange(index)
        }
      }}
      className={`tabs-component_pills_each ${!!isActive && 'active'} ${disabled ? 'disabled' : ''}`}
    >
      {t(title)}
    </div>
  )
}

Pill.propTypes = {
  handleTabChange: PropTypes.func,
  index: PropTypes.number,
  isActive: PropTypes.bool,
  title: PropTypes.string,
  disabled: PropTypes.bool,
}

const Content = ({ contentComponent, props }) => {
  return <div className="tabs-component_main-content">{React.cloneElement(contentComponent, props)}</div>
}

Content.propTypes = {
  contentComponent: PropTypes.element,
  props: PropTypes.any,
}

const Tabs = ({ tabsData, currentTab = null, handleTabChange, classNames, ...props }) => {
  return (
    <div className={`tabs-component ${classNames || ''}`}>
      <div className="tabs-component_pills_wrapper">
        {
          tabsData.length > 0 && tabsData.map((eachTab, index) => {
            return (
              <Pill
                title={eachTab.title}
                handleTabChange={handleTabChange}
                isActive={Number(currentTab) === index}
                disabled={eachTab.disableTab}
                index={index}
                key={eachTab.title}
              />
            )
          })
        }
      </div>

      {!tabsData[currentTab]?.disableTab ? (
        <Content contentComponent={tabsData[currentTab].component} props={props} />
      ) : null}
    </div>
  )
}

Tabs.propTypes = {
  tabsData: PropTypes.arrayOf(object),
  defaultTab: PropTypes.number,
  currentTab: PropTypes.oneOfType(string, number),
  handleTabChange: PropTypes.func,
  classNames: PropTypes.string,
}

export default Tabs

import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import moment from 'moment'

/**
 * Time range select for mobile
 * @Tags( dashboard )
 */
const MobileTopBarSelect = ({ handleChange }) => {
  const { t } = useTranslation()
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: 140,
      margin: '0 auto',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#FFF' : '#031c41',
      background: state.isSelected ? '#031c41' : '#FFF',
      padding: 10,
    }),
    control: (provided) => ({
      ...provided,
      background: 'transparent',
      color: '#fff',
      border: 'none',
    }),
    singleValue: (provided) => ({
      ...provided,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
  }

  const options = [
    // 1 year
    { label: t('common.range.time.year'), value: moment().add(1, 'year').format('DD-MM-YYYY'), key: 'date-option-6' },
    // 3 months
    {
      label: t('common.range.time.quarter'),
      value: moment().add(3, 'months').format('DD-MM-YYYY'),
      key: 'date-option-5',
    },
    // 1 month
    { label: t('common.range.time.month'), value: moment().add(1, 'month').format('DD-MM-YYYY'), key: 'date-option-4' },
    // 2 week
    {
      label: t('common.range.time.twoWeeks'),
      value: moment().add(2, 'weeks').format('DD-MM-YYYY'),
      key: 'date-option-3',
    },
    // 1 week
    { label: t('common.range.time.week'), value: moment().add(1, 'week').format('DD-MM-YYYY'), key: 'date-option-2' },
  ]

  const onChange = (e) => {
    handleChange(e.value)
  }

  return (
    <Select
      className="custom-select-styles"
      isSearchable={false}
      options={options}
      // menuIsOpen={true}
      styles={customStyles}
      defaultValue={options[0]}
      onChange={onChange}
    />
  )
}

MobileTopBarSelect.propTypes = {
  /**
   * Changes time range on dashboard page
   */
  handleChange: PropTypes.func,
}

export default MobileTopBarSelect

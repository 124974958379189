import PropTypes from 'prop-types'
import React, { useEffect, useReducer, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Highcharts from 'highcharts'
import moment from 'moment'

import LoadingSpinner from '../../Shared/LoadingSpinner'
import ErrorMessage from '../../Shared/ErrorMessage'
import EmptyView from '../../Shared/EmptyView'

import { initStateArray, arrayReducer } from '../../../reducers/default'
import { fetchSalesPlot } from '../../../api/charts'

/**
 * Highcharts charts for sales
 * @Tags( event, api )
 * @Endpoints( /events/[eid]/sales-graph )
 * @NpmPackages( highcharts )
 * @ApiLogic( ../../api/charts )
 */
const initLineChart = (chartSeries, options = { tooltip: { enabled: true } }) => {
  let maxValue = 100

  chartSeries[0].data.forEach((value) => {
    if (value[1] > 100) {
      maxValue = value[1]
    }
  })
  chartSeries[1].data.forEach((value) => {
    if (value[1] > 100) {
      maxValue = value[1]
    }
  })

  new Highcharts.chart('sales-trend-line-chart', {
    chart: {
      type: 'spline',
      height: 300,
      marginBottom: 90,
      styledMode: false,
      alignTicks: false,
      style: {
        fontFamily: 'TTCommons',
      },
    },
    plotOptions: {
      pointPlacement: 'between',
      series: {
        lineWidth: 10,
      },
      spline: {
        marker: {
          enabled: false,
        },
      },
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      alignTicks: false,
      labels: {
        maxStaggerLines: 1,
      },
      dateTimeLabelFormats: {
        millisecond: '%H:%M:%S.%L',
        second: '%H:%M:%S',
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%e.%m',
        week: '%e.%m',
        month: '%m.%Y',
        year: '%Y',
      },
    },
    yAxis: {
      align: 'center',
      title: {
        text: '',
      },
      min: 0,
      max: maxValue,
      tickAmount: 5,
      labels: {
        format: '{value}%',
      },
    },
    legend: {
      layout: 'horizontal',
      align: 'bottom',
      useHTML: true,
      labelFormatter: function () {
        return `<span style='display: flex; align-items: baseline; font-size: 16px;' class="highcharts-legend-item-text">${this.name}</span>`
      },
    },
    tooltip: {
      enabled: !!options.tooltip.enabled,
      useHTML: true,
      formatter: function () {
        return `<div class="highcharts-popup-content"><h5>${this.series.name} - <strong class="value">${Number(this.y).toFixed(1)}%</strong></h5><p><span class="date">${moment(this.x).format('DD.MM.YYYY')}</span></p></div>`
      },
    },
    series: chartSeries,
  })
  return
}

const HighchartGraph = ({ series, isAppDrawer, tooltipEnabled = true }) => {
  const [redrawn, setRedrawn] = useState(false)
  const [isIPad, setIsIPad] = useState(false)

  // create a ref like instance for redrawing the chart @ bigger vws
  const redraw = useCallback(() => {
    if (!redrawn) {
      initLineChart(series.items, { tooltip: { enabled: tooltipEnabled } })
      setRedrawn(true)
    }
  }, [redrawn, series.items, tooltipEnabled])

  useEffect(() => {
    //https://stackoverflow.com/questions/56578799/tell-ipados-from-macos-on-the-web
    if ((navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad') {
      setIsIPad(true)
    }
  }, [])

  useEffect(() => {
    // Sometimes Highcharts ignores its container's boundaries
    // redraw the chart to make sure that this it renders its
    // x-axis correctly

    // rendering problem only occurs @ vw > 990
    // because the user will usually click on a event row
    // this will render the chart immediately and only once
    // but also keeping in mind when a user opens the chart in another window

    if (!isAppDrawer && series.items.length > 0 && window.innerWidth > 990 && !redrawn) {
      // prevents loading too quickly
      setTimeout(() => redraw(), 300)
    }

    // smaller devices should render chart on first load
    if (window.innerWidth <= 990 || isIPad || isAppDrawer) {
      setRedrawn(true)
      initLineChart(series.items, { tooltip: { enabled: tooltipEnabled } })
      window.removeEventListener('load', redraw)
    }
    return () => {
      // window.removeEventListener('load', redraw)
      redraw()
    }
  }, [series.items, redrawn, redraw, isIPad, isAppDrawer, tooltipEnabled])

  return (
    <>
      <div data-testid="sales-trend-line-chart" id="sales-trend-line-chart" className="sales-trend-chart"></div>
      {!redrawn ? <LoadingSpinner /> : null}
    </>
  )
}

HighchartGraph.propTypes = {
  series: PropTypes.object,
  isAppDrawer: PropTypes.bool,
  tooltipEnabled: PropTypes.bool,
}

const SalesTrendChart = ({ eventId, chartTooltipEnabled = true, isAppDrawer = false }) => {
  const { t } = useTranslation()
  const [series, dispatch] = useReducer(arrayReducer, initStateArray)
  const [translation] = useState({
    ticketsSold: t('Event.salesTrend.ticketsSold'),
    fdPrediction: t('Event.salesTrend.fdPrediction'),
  })

  useEffect(() => {
    fetchSalesPlot(dispatch, eventId, translation)
  }, [eventId, translation])

  return (
    <div
      data-testid="sales-trend-line-chart-wrapper"
      className={`card card-default card-sales-trend mb-0 ${series.items.length > 0 ? '' : 'no-results'}`}
    >
      <div id="chart-wrapper" className="card-body sales-trend-chart-wrapper">
        {series.loading && <LoadingSpinner />}
        {series.error && <ErrorMessage danger>{t(series.message)}</ErrorMessage>}
        {series.empty && <EmptyView danger>{t(series.message)}</EmptyView>}
        <div style={{ position: 'relative' }}>
          {series.items.length ? (
            <HighchartGraph series={series} isAppDrawer={isAppDrawer} tooltipEnabled={chartTooltipEnabled} />
          ) : null}
        </div>
      </div>
    </div>
  )
}

SalesTrendChart.propTypes = {
  chartTooltipEnabled: PropTypes.bool,
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isAppDrawer: PropTypes.bool,
}

SalesTrendChart.defaultProps = {
  chartTooltipEnabled: true,
}

export default SalesTrendChart

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { useDebounce } from '../../hooks'
import { useTranslation } from 'react-i18next'
import useIsFirstRender from '../../hooks/useFirstRender'

export default function Searchbar({
  handleChange,
  defaultValue = undefined,
  placeholder,
  ariaLabel = 'search-bar',
  autoFocus = false,
  debounceWait = 500,
  inputTestId = 'search_input',
  cypressId = 'search_input',
  prefixView = null,
  postfixView = null,
  clearSearchQuery = false,
  setClearSearchQuery = () => {},
  placeholderSticky = false,
  inputRef,
}) {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState(false)
  const [query, setQuery] = useState(defaultValue)
  const debouncedQuery = useDebounce(query, debounceWait)
  const firstRender = useIsFirstRender()

  placeholder = placeholder || t('common.search')

  useEffect(
    () => {
      if (firstRender || debouncedQuery === undefined) return

      handleChange(debouncedQuery)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedQuery], // FIXME: Adding handleChange to the deps array causes infinite render loop.
  )

  useEffect(() => {
    if (clearSearchQuery) {
      setQuery('')
      setClearSearchQuery(false)
    }
  }, [clearSearchQuery, setClearSearchQuery])

  return (
    <div
      className={classnames('searchbar', 'searchbar-comp_wrapper', {
        focused: isFocused,
        'searchbar-comp_wrapper--left-padded': !!prefixView,
        'searchbar-comp_wrapper--right-padded': !!postfixView,
      })}
    >
      {prefixView}
      <input
        className={classnames('searchbar-comp_inner-input', { 'sticky-placeholder': placeholderSticky })}
        data-cy={cypressId}
        data-testid={inputTestId}
        placeholder={placeholder}
        aria-label={ariaLabel}
        onChange={(e) => setQuery(e.target.value)}
        value={query}
        autoFocus={autoFocus}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        title={placeholder}
        ref={inputRef}
      />
      {postfixView}
    </div>
  )
}

Searchbar.propTypes = {
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  autoFocus: PropTypes.bool,
  debounceWait: PropTypes.number,
  inputTestId: PropTypes.string,
  cypressId: PropTypes.string,
  prefixView: PropTypes.node,
  postfixView: PropTypes.node,
  clearSearchQuery: PropTypes.bool,
  setClearSearchQuery: PropTypes.func,
  inputRef: PropTypes.object,
  placeholderSticky: PropTypes.bool,
}

import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import PropTypes from 'prop-types'
import i18n from 'i18n'

import CampaignBudget from 'components/Recommendations/CampaignBudget'
import CampaignGoals from 'components/Recommendations/CampaignGoals'
import TipDialogueBox from 'components/Shared/TipDialogueBox'
import { SecondaryButton } from 'components/Shared/Button'
import Fade from 'components/Shared/Wrappers/Fade'
import {
  buildSetupPayload,
  hasRunningCampaigns,
  isCampaignRunning,
  uniqueTasteClusterCount,
  getCampaignRuntime,
} from 'api/models'
import OpenCampaigns from './OpenCampaigns'
import { fetchEventBudgetMinMax, fetchEventSuggestedTasteClusters } from 'api/events'
import { FAIL, LOADING, OVERWRITE, initStateObject, objectReducer } from 'reducers/default'
import { createSetupProcess, updateSetupProcess } from 'api/setupProcesses'
import CampaignRuntime from './CampaignRuntime'
import InfoBox from 'components/Shared/InfoBox'
import { SetupContext } from 'components/Recommendations/Campaign/Edit/CampaignSetupProvider'
import useIsFirstRender from 'hooks/useFirstRender'
import { useItcStatus } from 'components/Recommendations/Itc/ItcStatusContextProvider'
import useHasPermission, { Permissions } from 'hooks/useHasPermission'

const calculateTotalRuntime = (startDate, endDate) => {
  return Math.round(new moment.duration(endDate - startDate).asDays())
}

const CampaignSetup = ({
  event,
  setupProcess,
  campaigns,
  suggestedTcs,
  selectedTcs,
  setSelectedTcs,
  initialBudget,
  initialGoal,
  dispatchSuggestedTcsAction,
  dispatchSetupAction,
  openCampaigns,
  onSetupStarted,
  onNext,
  refetchCampaigns,
  isCampaignSetupInProgress = false,
  setSuggestedTcsChanged = () => {},
}) => {
  const { t } = useTranslation()
  const isFirstRender = useIsFirstRender()
  const hasWaveAccess = useHasPermission(Permissions.wave)
  const [budgetRange, dispatchBudgetRangeAction] = useReducer(objectReducer, initStateObject)
  const budgetLoadingRef = React.useRef(false)
  const currentSetup = useContext(SetupContext)
  const { mappedStatus: itcMappedStatus, loading: itcStatusLoading, error: itcStatusError } = useItcStatus()
  const [eventBudget, setEventBudget] = useState(currentSetup.total_budget || initialBudget)
  const [eventGoal, setEventGoal] = useState(() => {
    if (!currentSetup?.goal) return initialGoal

    const goalParts = currentSetup.goal.split('.')
    return goalParts[goalParts.length - 1]
  })

  const eventStartDate = moment(event.date)
  const showShortNoticeWarning = eventStartDate.diff(moment(), 'days') <= 7
  const totalUniqueTcs = uniqueTasteClusterCount(suggestedTcs.items)
  const [totalRuntime, setTotalRuntime] = useState(null)
  const [dates, setDates] = useState({
    from: null,
    to: null,
  })

  useEffect(() => {
    if (typeof totalRuntime === 'number') {
      fetchEventBudgetMinMax(dispatchBudgetRangeAction, event.id, totalRuntime)
    }
  }, [event.id, totalRuntime])

  useEffect(() => {
    fetchEventSuggestedTasteClusters(dispatchSuggestedTcsAction, event.id, eventBudget, eventGoal)
  }, [event.id, eventBudget, eventGoal, dispatchSuggestedTcsAction])

  useEffect(() => {
    if (!isFirstRender) setSuggestedTcsChanged(true)
  }, [event.id, eventBudget, eventGoal, setSuggestedTcsChanged, isFirstRender])

  useEffect(() => {
    const [runtimeStartDate, runtimeEndDate] = getCampaignRuntime(event)
    const todayDate = new Date()
    const startDate =
      setupProcess.start_date && new Date(setupProcess.start_date) > todayDate
        ? new Date(setupProcess.start_date)
        : runtimeStartDate.toDate()
    const endDate = setupProcess.end_date ? new Date(setupProcess.end_date) : runtimeEndDate.toDate()

    setDates({
      from: startDate,
      to: endDate,
    })
    setTotalRuntime(calculateTotalRuntime(startDate, endDate))
  }, [setupProcess, event])

  const updateDate = (date, dateRangeString) => {
    const formattedDate = date ? moment(date).toDate() : ''

    const currentDateTime = { ...dates }
    currentDateTime[dateRangeString] = formattedDate
    const runtimeDuration = calculateTotalRuntime(currentDateTime.from, currentDateTime.to)

    setDates(currentDateTime)
    setTotalRuntime(runtimeDuration)

    if (!currentSetup?.empty) {
      // Autosave changes to start, end dates on goals page.
      const payload = buildSetupPayload({
        ...currentSetup,
        start_date: currentDateTime.from?.toISOString(),
        end_date: currentDateTime.to?.toISOString(),
      })
      updateSetupProcess(dispatchSetupAction, event.id, payload)
    }
  }

  const startSetup = async () => {
    if (itcStatusError || itcStatusLoading || ['running', 'failed'].includes(itcMappedStatus)) {
      return
    }

    const selectedCampaigns = campaigns.items.filter((x) => selectedTcs.includes(x.tc))
    let creativesMap = {}
    currentSetup.creatives &&
      currentSetup.creatives.forEach((creative) => {
        creativesMap[`${creative.tc}-${creative.tc_run_id}`] = creative
      })

    const payload = {
      total_budget: eventBudget,
      goal: eventGoal,
      start_date: dates.from?.toISOString(),
      end_date: dates.to?.toISOString(),
      integration_details: currentSetup.integration_details,
      creatives: selectedCampaigns.map((x) => {
        const creative = creativesMap[`${x.tc}-${x.tc_run_id}`]
        if (creative) return creative
        else
          return {
            tc: x.tc,
            tc_run_id: x.tc_run_id,
          }
      }),
      targeting: currentSetup.targeting,
    }

    if (isCampaignSetupInProgress) {
      await updateSetupProcess(
        dispatchSetupAction,
        event.id,
        buildSetupPayload(payload, {
          setupOnly: true,
          setDefaultTargeting: true,
          defaultCreatives: { language: i18n.language },
        }),
      )
      onNext()
      return
    }

    dispatchSetupAction({ type: LOADING })
    const result = await createSetupProcess(event.id, buildSetupPayload(payload))

    if (result.error) {
      dispatchSetupAction({ type: FAIL, payload: result.error })
    } else {
      dispatchSetupAction({ type: OVERWRITE, payload: result.setup })
      onSetupStarted(result.setup)
    }
  }

  const hasActiveCampaigns = useMemo(() => {
    return campaigns.items.filter((campaign) => isCampaignRunning(campaign)).length > 0
  }, [campaigns.items])

  return (
    <div className="d-flex flex-column gap-5 p-0">
      <Fade transitionDuration={500}>
        <div className="d-flex flex-column">
          {showShortNoticeWarning && (
            <TipDialogueBox
              title={t('Recommendations.shortNoticeWarning.title')}
              text={t('Recommendations.shortNoticeWarning.content')}
              variant="warning-light"
            />
          )}

          <div className="d-flex flex-column flex-lg-row my-1 order-2 order-lg-1">
            <div className="flex-1 col-12 col-lg-5 px-0 mb-3 mb-lg-0">
              <CampaignGoals
                budget={eventBudget}
                eventId={event.id}
                onGoalSelected={setEventGoal}
                selectedGoal={eventGoal}
                disabled={hasRunningCampaigns(campaigns.items)}
              />
            </div>
            <div className="flex-1 col-12 col-lg-7 px-0 pl-lg-3">
              <CampaignBudget
                selectedBudget={eventBudget}
                minimumBudget={budgetRange.content.budget_lower_limit || eventBudget || 1000}
                maximumBudget={budgetRange.content.budget_upper_limit || 10000}
                onChangeQueued={() => (budgetLoadingRef.current = true)}
                onBudgetChanged={(b) => {
                  setEventBudget(b)
                  budgetLoadingRef.current = false
                }}
                addressedTasteClusters={uniqueTasteClusterCount(suggestedTcs.items)}
                currency={campaigns.items?.length > 0 ? campaigns.items[0].currency : ''}
              >
                <InfoBox
                  title={t('Tips.Recommendations.CampaignBudget.title')}
                  body={
                    <div>
                      <p>{t('Tips.Recommendations.CampaignBudget.body1')}</p>
                      <br />
                      <p>{t('Tips.Recommendations.CampaignBudget.body2')}</p>
                    </div>
                  }
                  placement="bottom"
                >
                  <h2 className="card-title-default d-inline-block event-header-title">
                    {t(
                      `Recommendations.campaignBudget.${hasActiveCampaigns ? 'selectBudgetForNewAudiences' : 'selectLabel'}`,
                    )}
                  </h2>
                </InfoBox>
              </CampaignBudget>
            </div>
          </div>
        </div>
      </Fade>
      <CampaignRuntime event={event} updateDate={updateDate} dates={dates} totalRuntime={totalRuntime} />
      <OpenCampaigns
        eventId={event.id}
        campaigns={openCampaigns}
        selectedTcs={selectedTcs}
        setSelectedTcs={setSelectedTcs}
        totalUniqueTcs={totalUniqueTcs}
        setupProcessId={isCampaignSetupInProgress ? setupProcess.id : null}
        eventHasActiveCampaigns={hasActiveCampaigns}
        refetchCampaigns={refetchCampaigns}
      />
      <div className="recommendations-page_cta-btns_row order-1 order-lg-3">
        <SecondaryButton
          disabled={
            !selectedTcs.length ||
            !hasWaveAccess ||
            budgetLoadingRef.current === true ||
            itcStatusError ||
            itcStatusLoading ||
            ['running', 'failed'].includes(itcMappedStatus)
          }
          disabledTitle={!selectedTcs.length ? '' : null}
          className="bt"
          onClick={startSetup}
          text={isCampaignSetupInProgress ? t('Recommendations.btnContinueSetup') : t('Recommendations.btnStartSetup')}
        />
      </div>
    </div>
  )
}

CampaignSetup.propTypes = {
  event: PropTypes.object,
  setupProcess: PropTypes.object,
  campaigns: PropTypes.object,
  suggestedTcs: PropTypes.object,
  selectedTcs: PropTypes.arrayOf(Object),
  setSelectedTcs: PropTypes.func,
  initialBudget: PropTypes.number,
  initialGoal: PropTypes.string,
  dispatchSuggestedTcsAction: PropTypes.func,
  dispatchSetupAction: PropTypes.func,
  openCampaigns: PropTypes.arrayOf(Object),
  onSetupStarted: PropTypes.func.isRequired,
  isCampaignSetupInProgress: PropTypes.bool,
  onNext: PropTypes.func,
  setSuggestedTcsChanged: PropTypes.func,
  refetchCampaigns: PropTypes.func.isRequired,
}

export default CampaignSetup

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Selector } from './Selector'
import LoadingSpinner from '../../../Shared/LoadingSpinner'
import useIsFirstRender from '../../../../hooks/useFirstRender'

export function CityFilter({ locationsState, cityState, setRendered }) {
  const { t } = useTranslation()

  const firstRender = useIsFirstRender()

  useEffect(() => {
    if (firstRender) setRendered()
  }, [firstRender, setRendered])

  const [selectedCities, setSelectedCities] = cityState

  const items = locationsState.items.map((x) => ({
    id: x.id,
    title: x.title,
  }))

  const uniqueItems = items
    .reduce((currentResult, currentItem) => {
      if (currentResult.some((x) => x.title === currentItem.title)) return currentResult
      return [...currentResult, currentItem]
    }, [])
    .filter(Boolean)

  const localizedItems = uniqueItems.map((x) => ({ ...x, title: t([`Locations.${x.title}`, x.title]) }))

  return (
    <>
      {locationsState.loading && <LoadingSpinner size="small" className="m-3" />}
      {!locationsState.loading && (
        <Selector
          items={localizedItems}
          selectedIds={selectedCities.map((x) => x.id)}
          searchLabel={t('Events.searchFilters.location.searchCities')}
          onChange={(id) => {
            selectedCities.some((x) => x.id === id)
              ? setSelectedCities(selectedCities.filter((x) => x.id !== id))
              : setSelectedCities([...selectedCities, items.find((i) => i.id === id)])
          }}
        />
      )}
    </>
  )
}

CityFilter.propTypes = {
  locationsState: PropTypes.object.isRequired,
  cityState: PropTypes.array.isRequired,
  setRendered: PropTypes.func.isRequired,
}

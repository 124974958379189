import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { clampRadius, getDefaultRadiusValue } from './rangeLocationsReducer'

const uintDisplayNames = Object.freeze({
  miles: 'mi',
  kilometers: 'km',
})

export default function RadiusInput({ defaultValue, unit, onChange }) {
  const [distance, setDistance] = useState(defaultValue)
  const displayUnit = useMemo(() => uintDisplayNames[unit], [unit])
  const timeoutRef = useRef()

  useEffect(() => {
    setDistance(defaultValue)
  }, [defaultValue])

  const queueChange = useCallback((value) => {
    if (!value) {
      value = getDefaultRadiusValue(unit)
    }
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      const clamped = clampRadius(value, unit)
      setDistance(clamped)
      onChange(clamped)
    }, 500)
  }, [unit, onChange])

  const handleChange = (e) => {
    const value = e.target.value
    setDistance(value)
    queueChange(value)
  }

  return (
    <div className="range-locations_summary__location-radius">
      <span className="range-locations_summary__location-radius__prefix">+</span>
      <input
        className="form-control s-40"
        type="number"
        value={distance}
        onChange={handleChange}
      />
      <span
        className="range-locations_summary__location-radius__suffix"
        style={{
          left: Math.max(44, 12 + (distance?.toString().length * 10)),
        }}
      >
        {displayUnit}
      </span>
    </div>
  )
}

RadiusInput.propTypes = {
  defaultValue: PropTypes.number,
  unit: PropTypes.oneOf(['miles', 'kilometers']).isRequired,
  onChange: PropTypes.func.isRequired,
}

import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Image, Path, StyleSheet, Svg, Text, View } from '@react-pdf/renderer'

import { numberWithThousandsSeparators } from 'utils/helpers'

import visibility from 'images/icons/visibility.png'
import touch from 'images/icons/touch.png'

const pdfStyles = StyleSheet.create({
  overview: {
    margin: '0px 32px',
    border: '1px solid #E8E9EE',
    borderRadius: '8px',
  },

  overviewHeadline: {
    padding: '16px',
    borderBottom: '1px solid #E8E9EE',
  },

  overviewTitle: {
    fontSize: '12px',
    textTransform: 'capitalize',
  },

  runtime: {
    marginTop: '6px',
    fontSize: '10px',
    color: '#818181',
  },

  subtitle: {
    marginTop: '6px',
    fontSize: '10px',
    color: '#818181',
  },

  value: {
    fontSize: '12px',
  },

  svgWrapper: {
    backgroundColor: 'rgba(168, 191, 203, 0.2)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    marginRight: '8px',
  },

  overviewPng: {
    width: '15px',
    heigh: '15px',
  },

  overviewValueWrapper: {
    margin: '0px 16px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  overviewValueCol: {
    display: 'flex',
    flexDirection: 'col',
    justifyContent: 'space-between',
  },

  overviewValue: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '16px',
    alignItems: 'center',
  },
})

const EventStatsPDFOverview = ({ fbInsights, currency, numOfTCs, adCreatives }) => {
  const { t } = useTranslation()

  const impressions = fbInsights.impressions
  const clicks = fbInsights.clicks
  const adSpend = fbInsights.ad_spend
  const startDate = fbInsights?.start ? moment.utc(fbInsights?.start).format('DD.MM.YYYY') : null
  const endDate = fbInsights?.end ? moment.utc(fbInsights?.end).format('DD.MM.YYYY') : null

  return (
    <View style={pdfStyles.overview}>
      <View style={pdfStyles.overviewHeadline}>
        <Text style={pdfStyles.overviewTitle}>{t('Dashboard.eventSeriesStats.title')}</Text>
        <View style={pdfStyles.runtime}>
          <Text>
            {startDate} - {endDate}
          </Text>
        </View>
      </View>
      <View style={pdfStyles.overviewValueWrapper}>
        <View style={pdfStyles.overviewValueCol}>
          <View style={pdfStyles.overviewValue}>
            <View style={pdfStyles.svgWrapper}>
              <Svg xmlns="http://www.w3.org/2000/svg" height="15" width="15" viewBox="0 -960 960 960">
                <Path
                  d="M240-160q-66 0-113-47T80-320v-320q0-66 47-113t113-47h480q66 0 113 47t47 113v320q0 66-47 113t-113 47H240Zm0-470h480q29 0 54.5 9t45.5 26v-45q0-42-29-71t-71-29H240q-42 0-71 29t-29 71v45q20-17 45.5-26t54.5-9Zm-97 136 477 115q7 2 14.5.5T647-385l160-134q-13-23-36-37t-51-14H240q-35 0-62 21.5T143-494Z"
                  fill="#031c41"
                />
              </Svg>
            </View>
            <View>
              <Text style={pdfStyles.value}>
                {numberWithThousandsSeparators(adSpend, { currency, maxFractionDigits: 0 })}
              </Text>
              <Text style={pdfStyles.subtitle}>{t('Dashboard.eventSeriesStats.adSpend')}</Text>
            </View>
          </View>
          <View style={pdfStyles.overviewValue}>
            <View style={pdfStyles.svgWrapper}>
              <Svg viewBox="0 0 16 20" fill="none" width={15} height={15} xmlns="http://www.w3.org/2000/svg">
                <Path d="M10 0H0V20H16V6L10 0ZM12 16H4V14H12V16ZM12 12H4V10H12V12ZM9 7V1.5L14.5 7H9Z" fill="#031C41" />
              </Svg>
            </View>
            <View>
              <Text style={pdfStyles.value}>{numberWithThousandsSeparators(numOfTCs)}</Text>
              <Text style={pdfStyles.subtitle}>{t('Dashboard.eventSeriesStats.totalTasteClusters')}</Text>
            </View>
          </View>
        </View>
        <View style={pdfStyles.overviewValueCol}>
          <View style={pdfStyles.overviewValue}>
            <View style={pdfStyles.svgWrapper}>
              <Image src={visibility} style={pdfStyles.overviewPng} />
            </View>
            <View>
              <Text style={pdfStyles.value}>{numberWithThousandsSeparators(impressions)}</Text>
              <Text style={pdfStyles.subtitle}>{t('Dashboard.eventSeriesStats.impressions')}</Text>
            </View>
          </View>
          <View style={pdfStyles.overviewValue}>
            <View style={pdfStyles.svgWrapper}>
              <Svg viewBox="0 0 81 81" fill="none" width={15} height={15} xmlns="http://www.w3.org/2000/svg">
                <Path
                  d="M69.1973 6.26531H12.1347V2.66638C12.1347 1.59868 13.067 0.666382 14.1347 0.666382H67.0667C68.1344 0.666382 69.0667 1.59868 69.0667 2.66638V6.26531H69.1973ZM74.9317 12.2653C74.9317 11.1976 73.9994 10.2653 72.9317 10.2653H8.40107C7.33337 10.2653 6.40107 11.1976 6.40107 12.2653V18.6664H75.0677V12.2653H74.9317ZM80.6661 24.6664V78.6664C80.6661 79.7341 79.7339 80.6664 78.6661 80.6664H2.66614C1.59843 80.6664 0.666138 79.7341 0.666138 78.6664V24.6664C0.666138 23.5987 1.59843 22.6664 2.66614 22.6664H78.6661C79.7339 22.6664 80.6661 23.5987 80.6661 24.6664ZM58.4008 51.7344C58.4008 50.9323 57.9998 50.2656 57.3331 50L31.4691 36.6666C30.8024 36.401 30.1358 36.401 29.4691 36.6666C28.8024 37.0677 28.5368 37.7344 28.5368 38.4011V65.0677C28.5368 65.7344 28.9378 66.401 29.4691 66.8021C29.7347 66.9375 30.1358 67.0677 30.5368 67.0677C30.8024 67.0677 31.2035 66.9323 31.4691 66.8021L57.3331 53.4688C57.9998 53.0677 58.4008 52.4011 58.4008 51.7344Z"
                  fill="#031C41"
                />
              </Svg>
            </View>
            <View>
              <Text style={pdfStyles.value}>{numberWithThousandsSeparators(adCreatives?.totalAds)}</Text>
              <Text style={pdfStyles.subtitle}>{t('Dashboard.eventSeriesStats.totalAds')}</Text>
            </View>
          </View>
        </View>

        <View style={pdfStyles.overviewValueCol}>
          <View style={pdfStyles.overviewValue}>
            <View style={pdfStyles.svgWrapper}>
              <Image src={touch} style={pdfStyles.overviewPng} />
            </View>
            <View>
              <Text style={pdfStyles.value}>{numberWithThousandsSeparators(clicks)}</Text>
              <Text style={pdfStyles.subtitle}>{t('Dashboard.eventSeriesStats.clicks')}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

EventStatsPDFOverview.propTypes = {
  fbInsights: PropTypes.object,
  currency: PropTypes.string,
  numOfTCs: PropTypes.number,
  adCreatives: PropTypes.object,
}

export default EventStatsPDFOverview

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes, { object } from 'prop-types'
import { Redirect } from 'react-router-dom'
import { useErrorHandler } from 'react-error-boundary'
import moment from 'moment'

import StepCompletionBar from 'components/Recommendations/Campaign/StepCompletionBar'
import { Goals } from './Steps'
import { DEFAULT_CAMPAIGN_BUDGET, DEFAULT_CAMPAIGN_GOAL, isCampaignRunning } from 'api/models'
import {
  CreativesDispatchContext,
  SetupContext,
  SetupDispatchContext,
  actions as autosaveActions,
  CurrentStepDispatchContext,
  CurrentStepContext,
} from '../Campaign/Edit/CampaignSetupProvider'
import { fetchEvent, fetchEventDefaultCampaignParameters } from 'api/events'
import { fetchSetupProcess } from 'api/setupProcesses'
import SetupWrapper from './Steps/Setup/SetupWrapper'
import { UPDATE } from 'reducers/default'
import useIsFirstRender from 'hooks/useFirstRender'
import { useToast } from 'components/Shared/AppToast/ToastProvider'

const Step = (props) => {
  const firstRender = useIsFirstRender()
  const setupProcess = useContext(SetupContext)
  const { setCurrentStep } = props

  useEffect(() => {
    if (!firstRender) return

    if (setupProcess && !setupProcess?.empty) setCurrentStep(2, 2)
    else setCurrentStep(1, 1)
  }, [firstRender, setupProcess, setCurrentStep])

  switch (props.step) {
    case 1:
      return <Goals {...props} />
    case 2:
      return <SetupWrapper {...props} child={'Accounts'} />
    case 3:
      return <SetupWrapper {...props} child={'Ads'} />
    case 4:
      return <SetupWrapper {...props} child={'Summary'} />
    default:
      return null
  }
}

Step.propTypes = {
  step: PropTypes.number.isRequired,
  event: PropTypes.object,
  initialGoal: PropTypes.string,
  initialBudget: PropTypes.number,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onSetupComplete: PropTypes.func,
  onSetupCancelled: PropTypes.func,
  onSetupStarted: PropTypes.func,
  setCurrentStep: PropTypes.func,
  isFutureEvent: PropTypes.bool,
}

const CampaignStepWrapper = ({ eventId, eventState, fetchedCampaigns, resetKey }) => {
  const { t } = useTranslation()
  const [eventGoal, setEventGoal] = useState(DEFAULT_CAMPAIGN_GOAL)
  const [eventBudget, setEventBudget] = useState(DEFAULT_CAMPAIGN_BUDGET)
  const [setupComplete, setSetupComplete] = useState(false)
  const [campaigns, setCampaigns] = useState([])
  // listens for breakpoints desktop => mobile && vice versa
  const [viewWidth, setViewWidth] = useState(window.innerWidth)
  const { addNotification } = useToast()

  const allCampaignsActive = useMemo(() => {
    return campaigns.every((campaign) => isCampaignRunning(campaign))
  }, [campaigns])

  const [event, dispatchEventAction] = eventState
  const setupProcess = useContext(SetupContext)
  const dispatchAutosaveAction = useContext(CreativesDispatchContext)
  const dispatchSetupAction = useContext(SetupDispatchContext)
  const dispatchCurrentStep = useContext(CurrentStepDispatchContext)
  const { currentStep, currentCompletedStep } = useContext(CurrentStepContext)

  const isFutureEvent = useMemo(() => {
    return moment(event.content?.date) > moment()
  }, [event])

  useErrorHandler(event.error ? { userFriendlyMessage: event.message } : null)

  const handleResize = () => setViewWidth(window.innerWidth)

  const setCurrentStep = useCallback(
    (step, completedStep = null) => {
      const payload = completedStep ? { currentCompletedStep: completedStep } : {}
      dispatchCurrentStep({ type: UPDATE, payload: { ...payload, currentStep: step } })
    },
    [dispatchCurrentStep],
  )

  const onSetupCancelled = useCallback(async () => {
    setCurrentStep(1, 1)
    dispatchAutosaveAction({ type: autosaveActions.resetSaved })
    await fetchSetupProcess(dispatchSetupAction, eventId)
  }, [setCurrentStep, dispatchAutosaveAction, dispatchSetupAction, eventId])

  const onSetupStarted = useCallback(async () => {
    await fetchSetupProcess(dispatchSetupAction, eventId)
    setCurrentStep(2, 2)
  }, [setCurrentStep, dispatchSetupAction, eventId])

  useEffect(() => {
    if (!eventId) return

    const updateDefaultCampaignParameters = async (newDefaultCampaignParameters) => {
      if (!newDefaultCampaignParameters.error && newDefaultCampaignParameters.payload) {
        const goal = newDefaultCampaignParameters.payload.goal
        const budget = newDefaultCampaignParameters.payload.total_budget

        await setEventGoal(goal) // FIXME: Why??
        await setEventBudget(budget) // FIXME: Why??
      }
    }

    fetchEvent(dispatchEventAction, eventId)
    fetchEventDefaultCampaignParameters(updateDefaultCampaignParameters, eventId)
  }, [eventId, dispatchEventAction, resetKey])

  useEffect(() => {
    if (!setupComplete) return

    dispatchAutosaveAction({ type: autosaveActions.resetSaved })
    fetchEvent(dispatchEventAction, eventId)
    fetchSetupProcess(dispatchSetupAction, eventId)
    fetchEventDefaultCampaignParameters((newDefaultCampaignParameters) => {
      if (!newDefaultCampaignParameters.error && newDefaultCampaignParameters.payload) {
        const goal = newDefaultCampaignParameters.payload.goal
        const budget = newDefaultCampaignParameters.payload.total_budget

        setEventGoal(goal)
        setEventBudget(budget)
      }
    }, eventId)
    addNotification({
      message: t('Toasts.Campaigns.campaignPublished.message'),
      description: t('Toasts.Campaigns.campaignPublished.description'),
    })
    const id = setTimeout(() => setSetupComplete(false), 2000)

    return () => clearTimeout(id)
  }, [eventId, dispatchEventAction, dispatchSetupAction, setupComplete, dispatchAutosaveAction, addNotification, t])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const onNext = () => {
    setCurrentStep(currentStep + 1, currentStep + 1)
  }

  const onBack = () => {
    setCurrentStep(currentStep - 1)
  }

  // if mobile, redirect user to full screen views
  if (viewWidth < 991) {
    const urlParams = new URL(window.location.href)
    if (urlParams.searchParams.get('drawer-open') === 'true') return <Redirect to={`/campaigns/${eventId}`} />
  }

  if (!eventId) return null

  if (!event.success || !setupProcess) return null // FIXME: Loading state

  if (setupComplete) {
    return <p className="alert alert-primary text-center mt-6">Your event has been boosted!</p>
  }

  return (
    <div>
      {isFutureEvent && !allCampaignsActive && (
        <StepCompletionBar
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          isSetupPending={setupProcess && !setupProcess.empty}
          currentCompletedStep={currentCompletedStep}
        />
      )}
      <Step
        event={event.content}
        step={currentStep}
        initialGoal={eventGoal}
        initialBudget={eventBudget}
        setCurrentStep={setCurrentStep}
        onNext={onNext}
        onBack={onBack}
        onSetupComplete={() => setSetupComplete(true)}
        onSetupCancelled={onSetupCancelled}
        onSetupStarted={onSetupStarted}
        setCampaigns={setCampaigns}
        isFutureEvent={isFutureEvent}
        fetchedCampaigns={fetchedCampaigns}
      />
    </div>
  )
}

CampaignStepWrapper.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  eventState: PropTypes.array.isRequired,
  resetKey: PropTypes.number,
  campaigns: PropTypes.arrayOf(object),
  setCampaigns: PropTypes.func,
  fetchedCampaigns: PropTypes.object,
}

export default CampaignStepWrapper

import qs from 'qs'

import { getEnumMemberName } from 'utils/helpers'
import { api } from './_init'

const notificationSettingsApi = {
  getAll: async () => {
    const response = await api.get('/notifications/settings')

    return response.data.map((x) => ({ ...x, notification_topic: getEnumMemberName(x.notification_topic) }))
  },
  update: async (setting) => {
    await api.put('/notifications/settings', setting)
  },
  updateAll: async ({ is_email_enabled }) => {
    await api.patch('/notifications/settings', {
      is_email_enabled,
    })
  },
}

const notificationsApi = {
  getAll: async ({ page = 1, limit = 10, unreadOnly = null, notificationTopics = [] } = {}) => {
    const query = {
      page,
      limit,
    }

    if (unreadOnly) {
      query.is_read = false
    }
    if (notificationTopics?.length > 0) {
      query.notification_topics = notificationTopics
    }

    const response = await api.get('/notifications', {
      params: query,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return {
      total: response.data.total,
      limit: response.data.limit,
      page: response.data.page,
      pages: response.data.pages,
      maxPage: Math.ceil(response.data.total / limit),
      nextPage: response.data.nextPage,
      prevPage: response.data.prevPage,
      items: response.data.items.map((notification) => ({
        ...notification,
        title: notification.title?.replace(/\\n/g, '\n'),
        action_url: notification.action_url?.replace(/\\n/g, '\n'),
        notification_topic: getEnumMemberName(notification.notification_topic),
        template_data: notification.template_data,
      })),
    }
  },
  get: async ({ notificationId }) => {
    try {
      const response = await api.get(`/notifications/${notificationId}`)
      return {
        ...response.data,
        body: response.data.body?.replace(/\\n/g, '\n').replace(/(-\s*\**\n)+/, ''), // remove empty list items
        title: response.data.title?.replace(/\\n/g, '\n'),
        action_url: response.data.action_url?.replace(/\\n/g, '\n'),
        notification_topic: getEnumMemberName(response.data.notification_topic),
      }
    } catch (error) {
      throw error.response?.data ? error : new Error('common.errors.inServerResponse')
    }
  },
  getStats: async () => {
    try {
      const response = await api.get('/notifications/stats')
      return response.data
    } catch (error) {
      throw error.response?.data ? error : new Error('common.errors.inServerResponse')
    }
  },
  markAsRead: async ({ notificationId, isRead = true }) => {
    await api.put(`/notifications/${notificationId}`, {
      is_read: isRead,
    })
  },
  updateAll: async ({ notificationIds = null, isRead = true }) => {
    await api.patch('/notifications', { is_read: isRead, ids: notificationIds })
  },
  getTopics: async () => {
    const response = await api.get('/notifications/topics')

    return response.data
  },
}

export { notificationSettingsApi, notificationsApi }

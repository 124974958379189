import React, { useReducer, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Card } from 'react-bootstrap'

import { initStateObject, objectReducer } from 'reducers/default'
import { updateEventInDomainModel } from 'api/simulation'
import { fetchCREvent } from 'api/events'

import EventEditorForm from './EventEditorForm'
import LoadingSpinner, { SpinnerSize } from 'components/Shared/LoadingSpinner'
import SessionTimer from 'components/SessionTimer/SessionTimer'
import { useToast } from 'components/Shared/AppToast/ToastProvider'
import useHasPermission, { hasPrismaAccessTier, Permissions } from 'hooks/useHasPermission'

const EventFormWrapper = ({ eid, setLoading, showHowItWorks = true, showSessionTimer = true, ...props }) => {
  const { t } = useTranslation()
  const [event, eventDispatch] = useReducer(objectReducer, initStateObject)
  const hasPrismaAccess = hasPrismaAccessTier()
  const { addNotification } = useToast()
  const history = useHistory()
  const hasLookoutAccess = useHasPermission(Permissions.lookout)

  const mounted = useRef(false)

  const getEvent = useCallback(async (eventId) => {
    if (!eventId) {
      return
    }
    try {
      await fetchCREvent(eventDispatch, eventId)
    } catch (error) {
      console.error({ error })
    }
  }, [])

  const postUpdatedEvent = useCallback(
    async (fields) => {
      try {
        console.log(fields)
        const data = await updateEventInDomainModel(fields, eid, hasPrismaAccess ? 'prisma' : 'fdlive')

        if (data.error) {
          window.alert('Update failed ' + data.error)
        }
        if (data.success) {
          addNotification({
            message: t('Toasts.Simulation.eventEdited.message'),
            description: t('Toasts.Simulation.eventEdited.description'),
          })
          await fetchCREvent(eventDispatch, eid)
          setTimeout(() => {
            const redirectUrl = `/${hasLookoutAccess ? 'events' : 'campaigns'}?drawer-open=true&event-id=${eid}&tab=0`
            history.replace(redirectUrl)
          }, 1000)
        }
      } catch (error) {
        console.log({ error })
      }
    },
    [eid, addNotification, history, t, hasLookoutAccess, hasPrismaAccess],
  )

  useEffect(() => {
    if (eid && !mounted) {
      mounted.current = true
    }
    getEvent(eid)

    return () => {
      mounted.current = false
    }
  }, [eid, getEvent])

  useEffect(() => {
    setLoading && setLoading(event.loading)
  }, [event.loading, setLoading])

  return (
    <div className="event-form-wrapper d-flex flex-column gap-3 mt-4">
      {/* <InputBar onChange={e => setEventId(String(e.target.value))} initFetch={getEvent} value={eventId} /> */}
      {showSessionTimer && <SessionTimer />}

      {showHowItWorks && (
        <article>
          <Card className="card card-default m-0">
            <div className="card-header mb-md-4">
              <h2 className="card-title-default">{t('Simulation.howItWorks')}</h2>
            </div>
            <ol className="simulation-form__list">
              <li style={{ display: 'list-item' }} className="simulation-form__headerQuestion">
                {t('EventEditor.steps.edit')}
              </li>
              <li style={{ display: 'list-item' }} className="simulation-form__headerQuestion">
                {t('EventEditor.steps.submit')}
              </li>
              <div className="simulation-form__headerInfo">
                <Trans i18nKey="Simulation.description.4">
                  Fill out as many fields as possible. The more information you can provide, the better the accuracy of
                  our prediction can be. Fields with <span style={{ color: 'red' }}>*</span> are mandatory.
                </Trans>
              </div>
            </ol>
          </Card>
        </article>
      )}

      <div className="event-editor_form">
        {event.content && event.content.id && !event.loading && (
          <EventEditorForm event={event.content} postUpdatedEvent={postUpdatedEvent} {...props} />
        )}

        {event.loading && <LoadingSpinner size={SpinnerSize.LARGE} />}
      </div>
    </div>
  )
}

EventFormWrapper.propTypes = {
  eid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showHowItWorks: PropTypes.bool,
  showSessionTimer: PropTypes.bool,
  setLoading: PropTypes.func,
}

export default EventFormWrapper

export const eventCategories = Object.freeze([
  {
    value: 'ballet',
    text: 'Ballet',
  },
  {
    value: 'chamber',
    text: 'Chamber',
  },
  {
    value: 'comedy',
    text: 'Comedy',
  },
  {
    value: 'dance',
    text: 'Dance',
  },
  {
    value: 'education',
    text: 'Education',
  },
  {
    value: 'opera',
    text: 'Opera',
  },
  {
    value: 'operetta_musical',
    text: 'Operetta musical',
  },
  {
    value: 'other',
    text: 'Other',
  },
  {
    value: 'rock_pop',
    text: 'Rock pop',
  },
  {
    value: 'spoken',
    text: 'Spoken',
  },
  {
    value: 'symphony',
    text: 'Symphony',
  },
  {
    value: 'theatre',
    text: 'Theatre',
  },
  {
    value: 'vocal',
    text: 'Vocal',
  },
])

import React, { useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { WorldIcon } from 'images'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserProfile } from 'api/auth'
import { initStateObject, objectReducer } from 'reducers/default'

/**
 * Toggle between languages and update user profile.
 * @Endpoints( /profile )
 * @ApiLogic( ../../api/auth )
 * @Tags( shared, translation )
 * @NpmPackages( react-i18next )
 */
const MultiLoginSwitcher = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [formStatus, setFormStatus] = useReducer(objectReducer, initStateObject)
  const [selectedOption, setSelectedOption] = useState(user.profile?.multi_partner_view)

  const options = ['id', 'name']

  const changeLoginDisplay = (displayOption) => {
    updateUserProfile(dispatch, { ...user.profile, multi_partner_view: displayOption }, setFormStatus)
    setSelectedOption(displayOption)
  }

  return (
    <>
      {formStatus.error && formStatus.message && <p className="alert alert-danger">{t(formStatus.message)}</p>}
      <div className="settings-page__sectionBody">
        <div className="settings-page__sectionColumn w-100">
          <div className="settings-page__sectionIcon">
            <WorldIcon width={17} />
          </div>
          <div className="settings-page__sectionWrapper">
            <div className="settings-page__sectionState position-relative">
              <button
                type="button"
                className="w-100 h-100 position-absolute hide-desktop"
                onClick={() => changeLoginDisplay('id')}
              ></button>
              <h3 className="settings-page__sectionTitle">{t('Settings.multiPartnerDisplay.title')}</h3>
              <p className="settings-page__sectionValue">{t('Settings.multiPartnerDisplay.description')}</p>
            </div>
            <div className="settings-page__sectionColumn">
              <div className="settings-page__sectionEdit settings-page__sectionEdit--doubleButton">
                {options.map((option) => (
                  <button
                    key={option}
                    type="button"
                    onClick={() => changeLoginDisplay(option)}
                    className={`btn language-switcher__button ${selectedOption === option ? 'language-switcher__button--active' : ''}`}
                  >
                    {t(`common.multiPartnerDisplay.${option}`)}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MultiLoginSwitcher

import React, { useState } from 'react'
import PropTypes, { string } from 'prop-types'
import { Card } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import { Accordion } from 'react-bootstrap'

export default function HowItWorks({ instructionsList = [], disclaimer = null }) {
  const { t } = useTranslation()
  const [activeKey, setActiveKey] = useState()

  return (
    <article className="how-it-works">
      <Card className="card card-default">
        <div className="card-header">
          <h2 className="card-title-default">{t('Simulation.howItWorks')}</h2>
        </div>

        <div className="card-body">
          <ol type="1">
            {instructionsList.map((instruction, index) => {
              return (
                <li key={index}>
                  {index + 1}. {instruction}
                </li>
              )
            })}
          </ol>

          <li className="d-none d-md-block how-it-works_notice">
            <Trans i18nKey={`${disclaimer ?? 'Simulation.description.4'}`}>
              Fill out as many fields as possible. The more information you can provide, the better the accuracy of our
              prediction can be. Fields with <span style={{ color: 'red' }}>*</span> are mandatory.
            </Trans>
          </li>

          <div className="d-block d-md-none how-it-works_readMore">
            <Accordion defaultActiveKey="1">
              <Accordion.Collapse eventKey="0">
                <div className="pt-3">
                  <Trans i18nKey={`${disclaimer ?? 'Simulation.description.4'}`}>
                    Fill out as many fields as possible. The more information you can provide, the better the accuracy
                    of our prediction can be. Fields with <span style={{ color: 'red' }}>*</span> are mandatory.
                  </Trans>
                </div>
              </Accordion.Collapse>
              <Accordion.Toggle
                eventKey="0"
                className="pt-3"
                onClick={() => setActiveKey((prev) => (prev === '0' ? '1' : '0'))}
              >
                {activeKey === '0' ? t('Simulation.readLess') : t('Simulation.readMore')}
              </Accordion.Toggle>
            </Accordion>
          </div>
        </div>
      </Card>
    </article>
  )
}

HowItWorks.propTypes = {
  instructionsList: PropTypes.arrayOf(string),
  disclaimer: PropTypes.string,
}

import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import DropDown from '../DropDown'
import Searchbar from 'components/Shared/Searchbar'

export default function SearchableDropDown({
  searchPlaceholder = 'Search...',
  ...props
}) {
  const [search, setSearch] = React.useState('')
  const [clearSearchQuery, setClearSearchQuery] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const searchRef = React.useRef(null)
  const { options } = props
  const filteredOptions = useMemo(() => {
    return search ? options.filter((option) =>
      option.text.toLowerCase().includes(search.toLowerCase())
    ) : options
  }, [options, search])

  useEffect(() => {
    if (open) {
      searchRef.current.focus()
    }
  }, [open])

  return (
    <div className={classnames('searchable-dropdown', { 'searchable-dropdown--open': open })}>
      <div className="searchable-dropdown__searchbar-container">
        <Searchbar
          handleChange={setSearch}
          placeholder={searchPlaceholder}
          ariaLabel={searchPlaceholder}
          postfixView={<i className="icon fas fa-search" style={{ color: '#ccc' }}></i>}
          defaultValue={search}
          inputRef={searchRef}
          placeholderSticky
          clearSearchQuery={clearSearchQuery}
          setClearSearchQuery={setClearSearchQuery}
        />
      </div>
      <DropDown
        {...props}
        options={filteredOptions}
        onToggle={(open, target) => {
          if (!open && searchRef.current?.contains(target)) {
            return
          }

          if (!open) {
            setSearch('')
            setClearSearchQuery(true)
          }
          setOpen(open)
        }}
        controlledOpen={open}
      />
    </div>
  )
}

SearchableDropDown.propTypes = {
  ...DropDown.propTypes,
  searchPlaceholder: PropTypes.string,
}

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary } from 'react-error-boundary'

import { actions, asyncActionHandlers, initialState, reducer } from './salesDocumentUpload'

import { useReducerAsync } from 'hooks/useReducerAsync'

import { SecondaryButton } from 'components/Shared/Button'
import PortalModal from 'components/Shared/PortalModal'
import CancelSalesEditModalContent from '../CancelSalesEditModalContent'
import HowItWorks from 'components/Shared/HowItWorks'
import ErrorFallback from 'components/Shared/ErrorFallback'

import plusIcon from 'images/icons/plus-circle.svg'
import xlsxIcon from 'images/icons/xlsx.svg'
import removeIcon from 'images/icons/remove-icon.svg'
import { TransparentBgButton } from '../../../Shared/Button'
import { useToast } from 'components/Shared/AppToast/ToastProvider'

const TEMPLATE_PATH = '/downloads/sales_template.xlsx'

function SalesDocumentUploadContent({ eventId, onClose }) {
  const { t } = useTranslation()

  const [state, dispatch] = useReducerAsync(reducer, initialState, asyncActionHandlers)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const { addNotification } = useToast()

  useEffect(() => {
    if (state.uploaded) {
      addNotification({
        message: t('Toasts.Sales.salesUploaded.message'),
        description: t('Toasts.Sales.salesUploaded.description'),
      })
      onClose()
    }
  }, [state.uploaded, addNotification, t, onClose])

  const instructionsList = [
    t('AddMultipleEvents.howItWorksDetails.downloadDocument'),
    t('AddMultipleEvents.howItWorksDetails.fillData'),
    t('AddMultipleEvents.howItWorksDetails.upload'),
  ]

  return (
    <div className="sales-upload">
      <HowItWorks instructionsList={instructionsList} />

      <div>
        <a href={TEMPLATE_PATH} className="bt bt-orange s-38">
          <span style={{ whiteSpace: 'nowrap' }}>{t('common.downloadTemplate')}</span>
        </a>
      </div>

      <div>
        <p>{t('common.uploadFile')}</p>
        <Dropzone onDrop={(files) => dispatch({ type: actions.addOrReplaceFile, payload: files[0] })} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <section className="upload-box" style={{ background: 'white' }}>
              <div {...getRootProps()}>
                <input accept=".xlsx, .xls" {...getInputProps()} />
                {state.file ? (
                  <>
                    <div className="accepted-file">
                      <div
                        style={{ display: state.loading ? 'none' : 'inline-block' }}
                        className="accepted-file_remove-icon_bg"
                      />
                      <img
                        onClick={(e) => {
                          e.stopPropagation()
                          dispatch({ type: actions.removeFile })
                        }}
                        className="accepted-file_remove-icon"
                        src={removeIcon}
                        alt={t('shared.fileUploader.removeFile')}
                        style={{ display: state.loading ? 'none' : 'inline-block' }}
                      />
                      <img className="accepted-file_icon" src={xlsxIcon} alt={state.file.name} />
                    </div>
                    <p className="mr-2 mt-3">{state.file.name}</p>
                  </>
                ) : (
                  <div className="upload-box_add">
                    <img src={plusIcon} alt="" />
                    <span className="add-file">{t('common.add')}</span>
                  </div>
                )}
              </div>
            </section>
          )}
        </Dropzone>
      </div>

      {state.valid === false && (
        <p className="warning-red">
          {t([
            `Event.salesEdit.documentUpload.errors.${state.error}`,
            'Event.salesEdit.documentUpload.errors.FILE_NOT_UNIQUE_OR_INVALID',
          ])}
        </p>
      )}
      <div>
        <hr className="divider mb-4" />

        <div className="d-flex justify-content-between">
          <TransparentBgButton
            text={t('common.cancel')}
            onClick={(e) => {
              e.stopPropagation()
              state.file ? setShowCancelModal(true) : onClose()
            }}
            color="navy"
          />
          <SecondaryButton
            text={state.loading ? t('common.uploading') : t('common.uploadFile')}
            fullWidth={false}
            onClick={() => dispatch({ type: actions.upload, payload: { eventId } })}
            disabledTitle={t('common.uploadFile')}
            disabled={!state.valid || !state.file || state.loading}
          />
        </div>
      </div>
      <PortalModal isOpen={showCancelModal} onClickOutside={() => setShowCancelModal(false)}>
        <CancelSalesEditModalContent onCancel={() => setShowCancelModal(false)} onConfirm={onClose} />
      </PortalModal>
    </div>
  )
}

SalesDocumentUploadContent.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClose: PropTypes.func.isRequired,
}

export function SalesDocumentUpload(props) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <SalesDocumentUploadContent {...props} />
    </ErrorBoundary>
  )
}

SalesDocumentUpload.propTypes = { ...SalesDocumentUploadContent.propTypes }

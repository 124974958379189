import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Selector } from './Selector'
import LoadingSpinner from '../../../Shared/LoadingSpinner'
import useIsFirstRender from '../../../../hooks/useFirstRender'

export function VenueFilter({ locationsState, venuesAndHallsState, setRendered }) {
  const { t } = useTranslation()

  const firstRender = useIsFirstRender()

  useEffect(() => {
    if (firstRender) setRendered()
  }, [firstRender, setRendered])

  const [selectedVenuesAndHalls, setSelectedVenuesAndHalls] = venuesAndHallsState

  const items = locationsState.items.map((x) => x.venuesAndHalls).flat()

  const uniqueItems = items
    .reduce((currentResult, currentItem) => {
      if (currentResult.some((x) => x.title === currentItem.title)) return currentResult
      if (!currentItem.hall && items.some((x) => x.venue === currentItem.venue && !!x.hall)) return currentResult
      return [...currentResult, currentItem]
    }, [])
    .filter(Boolean)

  return (
    <>
      {locationsState.loading && <LoadingSpinner size="small" className="m-3" />}
      {!locationsState.loading && (
        <Selector
          items={uniqueItems}
          selectedIds={selectedVenuesAndHalls.map((x) => x.id)}
          searchLabel={t('Events.searchFilters.location.searchVenue')}
          onChange={(id) => {
            selectedVenuesAndHalls.some((x) => x.id === id)
              ? setSelectedVenuesAndHalls(selectedVenuesAndHalls.filter((x) => x.id !== id))
              : setSelectedVenuesAndHalls([...selectedVenuesAndHalls, items.find((i) => i.id === id)])
          }}
        />
      )}
    </>
  )
}

VenueFilter.propTypes = {
  locationsState: PropTypes.object.isRequired,
  venuesAndHallsState: PropTypes.array.isRequired,
  setRendered: PropTypes.func.isRequired,
}

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { SecondaryButton } from 'components/Shared/Button'
import { useTranslation } from 'react-i18next'

import allCountries from './countries.json'

const Country = ({ countryName, countryKey, totalPostalCodes, onEditClicked }) => {
  const { t } = useTranslation()

  return (
    <div className="postal-codes-summary__country">
      <div className="postal-codes-summary__country__container">
        <div className="postal-codes-summary__country__name">{countryName}</div>
        <div className="postal-codes-summary__postal-codes__count">
          {totalPostalCodes} {t('Recommendations.geoTargeting.postalCodes.matching.success.postalCodes')}
        </div>
      </div>
      <SecondaryButton
        text={t('common.edit')}
        color="navy"
        size="small"
        fullWidth={false}
        type="button"
        classNames="geo-targeting__button"
        onClick={() => onEditClicked(countryKey)}
      />
    </div>
  )
}

Country.propTypes = {
  countryName: PropTypes.string.isRequired,
  countryKey: PropTypes.string.isRequired,
  totalPostalCodes: PropTypes.number.isRequired,
  onEditClicked: PropTypes.func.isRequired,
}

export default function PostalCodesSummary({ countries, onEditClicked }) {
  const { t } = useTranslation()
  const mappedCountries = useMemo(() => {
    return Object.keys(countries).map((countryKey) => {
      const country = allCountries[countryKey]
      if (!country || !countries[countryKey].length) return null

      return {
        name: country.name,
        key: countryKey,
        totalPostalCodes: countries[countryKey].length,
      }
    })
  }, [countries]).filter(Boolean)

  return (
    <div className="postal-codes-summary">
      {mappedCountries.length === 0 && (
        <div className="postal-codes-summary__country">
          <div className="postal-codes-summary__country__container">
            {t('Recommendations.geoTargeting.postalCodes.matching.success.noPostalCodes')}
          </div>
        </div>
      )}
      {mappedCountries.length > 0 &&
        mappedCountries.map((country) => (
          <Country
            key={country.key}
            countryName={country.name}
            countryKey={country.key}
            totalPostalCodes={country.totalPostalCodes}
            onEditClicked={() => onEditClicked(country.key)}
          />
        ))}
    </div>
  )
}

PostalCodesSummary.propTypes = {
  countries: PropTypes.object.isRequired,
  onEditClicked: PropTypes.func.isRequired,
}

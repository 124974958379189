import React from 'react'
import { useTranslation } from 'react-i18next'

import ActiveCampaignsBody from './ActiveCampaignsBody'
import InfoBox from '../Shared/InfoBox'

/**
 * Active Campaigns static wrapper in dashboard (desktop)
 * @Tags( dashboard, presentational )
 */
const ActiveCampaigns = () => {
  const { t } = useTranslation()
  return (
    <div className="card card-default active-campaigns-wrapper">
      <div className="card-header">
        <InfoBox content="Tips.Dashboard.ActiveCampaigns">
          <h2 className="card-title-default d-inline-block">{t('Dashboard.statsIcons.title')}</h2>
        </InfoBox>
      </div>
      <div className="card-body">
        <ActiveCampaignsBody />
      </div>
    </div>
  )
}

export default ActiveCampaigns

import React from 'react'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import BulkUploadContent from 'components/Events/BulkUploadUX/BulkUploadContent'
import Layout from 'components/Layout'
import { isFeatureFlagOn, SHOW_SIMULATION } from 'utils/flag-feature'

const AddMultipleEvents = ({ show }) => {
  const { t } = useTranslation()
  const features = useSelector((store) => store.user.features)

  if (!show && !isFeatureFlagOn(features, SHOW_SIMULATION)) {
    return <Redirect to="/" />
  }

  return (
    <Layout
      loggedIn
      title={t('AddMultipleEvents.MainTitle')}
      mobileTopBar={{ centerTitle: t('AddMultipleEvents.MainTitle') }}
    >
      <Helmet
        bodyAttributes={{
          class: 'simulation-page',
        }}
      />
      <main>
        <div className="container-fluid">
          <BulkUploadContent />
        </div>
      </main>
    </Layout>
  )
}
AddMultipleEvents.propTypes = {
  /**
   * Force visbility for tests.
   */
  show: PropTypes.bool,
}

export default AddMultipleEvents

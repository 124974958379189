import React from 'react'
import PropTypes, { object } from 'prop-types'

import VariationsCard from './VariationsCard'

import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

const VariationsList = ({ variationResults, setActiveContent, setDrawerOpen }) => {
  return (
    <div data-cy="results-table" className="package-builder-page_variations-section_grid">
      {variationResults && variationResults.length > 0
        ? variationResults.map((result, index) => {
          return (
            <VariationsCard
              cypressId={`variation-card-${index + 1}`}
              result={result}
              key={result.title}
              setActiveContent={setActiveContent}
              setDrawerOpen={setDrawerOpen}
            />
          )
        })
        : null}
    </div>
  )
}

VariationsList.propTypes = {
  variationResults: PropTypes.arrayOf(object),
  setActiveContent: PropTypes.func,
  setDrawerOpen: PropTypes.func,
}

export default VariationsList

import { api, makeApiRequest } from 'api/_init'

const targetingApi = {
  matchPostalCodesAsync: async (countryKey, postalCodes) => {
    return await makeApiRequest(api, 'post', '/fb_targeting/zip_code_matching', {
      country_code: countryKey,
      zips: postalCodes.map((x) => x.trim()).join(','),
    })
  },
  searchAsync: async (query) => {
    return await makeApiRequest(api, 'post', '/fb_targeting/search', {
      q: query,
      location_types: ['city'],
    })
  },
  getSuggestedRadiusAsync: async (location, distanceUnit) => {
    const unitMap = {
      miles: 'mile',
      mile: 'mile',
      kilometers: 'kilometer',
      km: 'kilometer',
    }
    distanceUnit = unitMap[distanceUnit] || 'mile'
    return await makeApiRequest(
      api,
      'get',
      `/fb_targeting/suggested_radius?location=${location}&distance_unit=${distanceUnit}`,
    )
  },
}

export default targetingApi

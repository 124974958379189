import React, { useReducer, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import LoadingSpinner from '../Shared/LoadingSpinner'

import { currencyFormat, numberWithThousandsSeparators } from '../../utils/helpers'

import { initStateObject, objectReducer } from '../../reducers/default'
import { fetchActiveCampaigns, initActiveCampaigns } from '../../api/dashboard'

/**
 * Active Campaigns content used in wrappers
 * @Tags( dashboard, api )
 * @Endpoints( /events/statistics, /campaigns/statistics )
 * @ApiLogic( ../../api/dashboard )
 */
const ActiveCampaignsBody = () => {
  const { t } = useTranslation()
  const [statistics, dispatch] = useReducer(objectReducer, { ...initStateObject, content: initActiveCampaigns })

  useEffect(() => {
    fetchActiveCampaigns(dispatch)
  }, [])

  useEffect(() => {
    if (statistics.content && statistics.content.total_revenue.currency) {
      localStorage.setItem('userCurrency', statistics.content.total_revenue.currency)
    }
  }, [statistics])

  if (statistics.loading) {
    return <LoadingSpinner />
  }

  return (
    <ul className="active-campaigns">
      <li className="active-campaigns__item active-campaigns__item">
        <div className="active-campaigns__item__icon">
          <i className="icon-drawer" />
        </div>
        <p className="active-campaigns__item__content">
          <strong>{statistics.content.active_events}</strong>
          <span>{t('common.promotedEvents', { count: statistics.content.active_events })}</span>
        </p>
      </li>
      <li className="active-campaigns__item active-campaigns__item">
        <div className="active-campaigns__item__icon">
          <i className="icon-debitcard" />
        </div>
        <p className="active-campaigns__item__content">
          <strong>
            {currencyFormat(statistics.content.total_spent.amount, statistics.content.total_spent.currency)}
          </strong>
          <span>{t('Dashboard.statsIcons.adSpend')}</span>
        </p>
      </li>
      <li className="active-campaigns__item">
        <div className="active-campaigns__item__icon">
          <i className="icon-cash" />
        </div>
        <p className="active-campaigns__item__content">
          <strong>{Math.max(statistics.content.total_purchases, 0)}</strong>
          <span>{t('Dashboard.statsIcons.purchases', { count: Math.max(statistics.content.total_purchases, 0) })}</span>
        </p>
      </li>
      <li className="active-campaigns__item active-campaigns__item">
        <div className="active-campaigns__item__icon">
          <i className="icon-coin" />
        </div>
        <p className="active-campaigns__item__content">
          <strong>
            {currencyFormat(
              Math.max(statistics.content.total_revenue.amount, 0),
              statistics.content.total_revenue.currency,
            )}
          </strong>
          <span>{t('Dashboard.statsIcons.revenue')}</span>
        </p>
      </li>
      <li className="active-campaigns__item">
        <div className="active-campaigns__item__icon">
          <i className="icon-bar-chart" />
        </div>
        <p className="active-campaigns__item__content">
          <strong>
            {statistics.content.roas
              ? numberWithThousandsSeparators(statistics.content?.roas, { maxFractionDigits: 0, suffix: '%' })
              : '0%'}
          </strong>
          <span>{t('Dashboard.statsIcons.ROI')}</span>
        </p>
      </li>
    </ul>
  )
}

export default ActiveCampaignsBody

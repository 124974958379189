import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

export default function Row({ children, ...props }) {
  return <div className={cn('customer-segmentation__row', props.className)}>{children}</div>
}

Row.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

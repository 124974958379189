import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import Layout from 'components/Layout'
import UploadDropzone from 'components/Uploads/UploadDropzone'
import { SecondaryButton } from 'components/Shared/Button'
import FilesList from 'components/Uploads/FilesList'

import { actions } from 'components/Uploads/files'
import { UploadFilesContext, UploadFilesDispatchContext } from 'components/Uploads/UploadFilesProvider'

const Uploads = ({ view }) => {
  const { t } = useTranslation()
  const state = useContext(UploadFilesContext)
  const dispatch = useContext(UploadFilesDispatchContext)

  const onDropFile = (uploadFiles) => {
    uploadFiles = uploadFiles.sort((a, b) => b.size - a.size)
    uploadFiles.forEach((uploadFile) => {
      dispatch({ type: actions.addFileAsync, payload: { file: uploadFile } })
    })
  }

  const onInitUpload = async () => {
    dispatch({ type: actions.uploadFilesAsync })
  }

  const removeItems = (index) => {
    if (index === undefined) {
      dispatch({ type: actions.resetFilesAsync })
    } else {
      const removedFile = state.files[index]
      dispatch({ type: actions.removeFileAsync, payload: removedFile })
    }
  }

  const isUploadDisabled = () => {
    const isCategoryProvided = state.files.every((file) => !!file.category)

    return !isCategoryProvided || !state.files.length || state.success || state.isLoading
  }

  const UploadFileContent = () => {
    return (
      <div className="uploads">
        <UploadDropzone files={state.files} onDropFile={onDropFile} />
        <FilesList clearFiles={removeItems} files={state.files} dispatch={dispatch} />
        {!!state.errorMessage && <p className="warning-red mt-4 text-left">{state.errorMessage}</p>}
        <div className="d-flex justify-content-end mt-4 p-0">
          <SecondaryButton text={t('Uploads.callToAction')} onClick={onInitUpload} disabled={isUploadDisabled()} />
        </div>
      </div>
    )
  }

  if (!(view === 'modal'))
    return (
      <Layout loggedIn title={t('Uploads.title')} mobileTopBar={{ centerTitle: t('Uploads.title') }}>
        <Helmet
          bodyAttributes={{
            class: 'simulation-page',
          }}
        />
        <main>
          <div className="container-fluid text-center mb-md-5">
            <UploadFileContent />
          </div>
        </main>
      </Layout>
    )
  else
    return (
      <div className="event-editor_search-view_wrapper">
        <h3 className="event-editor_search-view_title">{t('Uploads.title')}</h3>
        <UploadFileContent />
      </div>
    )
}

export default Uploads

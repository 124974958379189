import React, { useState } from 'react'
import PropTypes from 'prop-types'
import InfoBox from '../Shared/InfoBox'
import { useTranslation } from 'react-i18next'

import CampaignGoalRadioButton from './CampaignGoalRadioButton'
import { DEFAULT_CAMPAIGN_GOAL, CampaignGoal, enumValues } from '../../api/models'

/**
 * Campaign goals input.
 * @Tags( recommendations, campaigns )
 */
export default function CampaignGoals({
  eventId,
  selectedGoal = DEFAULT_CAMPAIGN_GOAL,
  budget,
  onGoalSelected,
  disabled,
}) {
  const { t } = useTranslation()
  const [displayDisabledOverlay, setDisplayDisabledOverlay] = useState(false)

  return (
    <div
      onMouseEnter={() => setDisplayDisabledOverlay(disabled && true)}
      onMouseLeave={() => setDisplayDisabledOverlay(false)}
      className="d-flex flex-column justify-content-between h-100"
    >
      <InfoBox content="Tips.Recommendations.CampaignGoal" placement="bottom">
        <h2 className="card-title-default d-inline-block event-header-title">
          {t('Recommendations.campaignGoal.selectLabel')}
        </h2>
      </InfoBox>
      <div style={{ position: 'relative', height: '100%' }}>
        <div
          className={`rounded d-flex flex-column gap-3 py-4 px-3 event-header-card h-100 campaign-goals ${disabled && 'disabled'}`}
        >
          {enumValues(CampaignGoal).map((goal, index) => {
            return (
              <CampaignGoalRadioButton
                key={index}
                eventId={eventId}
                checked={goal === selectedGoal}
                goal={goal}
                budget={budget}
                goalTitle={t(`Recommendations.campaignGoal.goals.${goal}`)}
                onGoalSelected={onGoalSelected}
                disabled={disabled}
              />
            )
          })}
        </div>
        {displayDisabledOverlay && (
          <div className="disabled-overlay">{t('Recommendations.campaignGoal.notEditable')}</div>
        )}
      </div>
    </div>
  )
}

CampaignGoals.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  budget: PropTypes.number,
  selectedGoal: PropTypes.string,
  onGoalSelected: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

import { useCallback, useLayoutEffect, useState } from 'react'

/**
 * Custom hook that returns a boolean value indicating whether the specified media query matches the current viewport.
 * 
 * Example usage:
 * 
 * ```jsx
 *
 * export default function Component() {
 *   const matches = useMediaQuery('(min-width: 768px)')
 *
 *   return (
 *     <div>
 *       {`The view port is ${matches ? 'at least' : 'less than'} 768 pixels wide`}
 *     </div>
 *   )
 * }
 * ```
 *
 * @param {string} query - The media query to match against the viewport.
 * @param {object} options - Optional configuration options.
 * @param {boolean} options.defaultValue - The default value to return when the media query is not supported or an error occurs. Defaults to `false`.
 * @param {boolean} options.initializeWithValue - Determines whether to initialize the hook with the current value of the media query. Defaults to `true`.
 * @returns {boolean} - A boolean value indicating whether the media query matches the current viewport.
 */
export function useMediaQuery(
  query,
  {
    defaultValue = false,
    initializeWithValue = true,
  } = {},
) {
  const getMatches = (query) => {
    return window.matchMedia(query).matches
  }

  const [matches, setMatches] = useState(() => {
    if (initializeWithValue) {
      return getMatches(query)
    }
    return defaultValue
  })

  // Handles the change event of the media query.
  const handleChange = useCallback(() => {
    setMatches(getMatches(query))
  }, [query])

  useLayoutEffect(() => {
    const matchMedia = window.matchMedia(query)

    handleChange()

    // Use deprecated `addListener` and `removeListener` to support Safari < 14 (#135)
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange)
    } else {
      matchMedia.addEventListener('change', handleChange)
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange)
      } else {
        matchMedia.removeEventListener('change', handleChange)
      }
    }
  }, [query, handleChange])

  return matches
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { TransparentBgButton } from 'components/Shared/Button'

export default function AccountDisabled({ closeModal }) {
  const { t } = useTranslation()

  return (
    <div className="disabled-account-modal">
      <h1 className="main-title">{t('common.serverErrors.error.auth.inactive')}</h1>
      <p
        className="description"
        dangerouslySetInnerHTML={{
          __html: t('common.serverErrors.error.auth.inactiveDescription', { interpolation: { escapeValue: false } }),
        }}
      />
      <div className="disabled-account-modal_footer">
        <TransparentBgButton color="navy" onClick={closeModal} text={t('common.cancel')} />
      </div>
    </div>
  )
}

AccountDisabled.propTypes = {
  closeModal: PropTypes.func,
}

import React, {  } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import Input from 'components/Shared/Input'
import InfoBox from 'components/Shared/InfoBox'

export default function Field({ fieldName, as = Input, onChange, tooltip, isRequired, footer, errorMessage, ...rest }) {
  const { t } = useTranslation()

  const Component = as

  return (
    <div className="creative-composer-section-wrapper">
      <InfoBox {...tooltip}>
        <label className="fs-18 d-inline-block">
          <>
            {t(`Recommendations.campaign.setup.${fieldName}.title`)}
            {isRequired && <span style={{ color: 'red' }}>*</span>}
          </>
        </label>
      </InfoBox>
      {errorMessage && <div className="mt-2 warning-red">{errorMessage}</div>}
      <Component
        id={fieldName}
        placeholder={t(`Recommendations.campaign.setup.${fieldName}.placeholder`)}
        name={fieldName}
        onChange={(e) => onChange(e?.target?.value ?? e)}
        showMax={true}
        {...rest}
      />
      {footer}
    </div>
  )
}

Field.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  footer: PropTypes.node,
  as: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.object,
  errorMessage: PropTypes.string,
}

export function ArrayField({
  fieldName,
  as = Input,
  inputChildren,
  form,
  onChange,
  children,
  isAddingEnabled = false,
  isRemovingEnabled = true,
  removeBtnClassNames = '',
  minCount = 3,
  maxCount = 5,
  tooltip = null,
  restFn,
  ...rest
}) {
  const { t } = useTranslation()

  const field = form[fieldName]
  if (!Array.isArray(field)) return null

  if (field.filter(Boolean).length < minCount) {
    const fieldsToAdd = minCount - field.length
    for (let index = 0; index < fieldsToAdd; index++) {
      field.push('')
    }
  }

  const Component = as

  const addTitle = isAddingEnabled ? (field.length >= maxCount ? '' : t('Recommendations.campaign.setup.add')) : ''

  return (
    <div className="creative-composer-section-wrapper">
      {tooltip ? (
        <InfoBox title={tooltip.title} body={tooltip.body}>
          <label className="fs-18 d-inline-block"> {t(`Recommendations.campaign.setup.${fieldName}.title`)}</label>
        </InfoBox>
      ) : (
        <label className="fs-18">{t(`Recommendations.campaign.setup.${fieldName}.title`)}</label>
      )}
      {children}
      {field.map((value, index) => (
        <Component
          id={`${fieldName}-${index}`}
          key={index}
          placeholder={t(`Recommendations.campaign.setup.${fieldName}.placeholder`) + ` ${index + 1}`}
          value={value}
          name={`${fieldName}[]`}
          onChange={(e) => onChange(e.target.value, index)}
          showMax={true}
          {...(restFn ? restFn(index) : {})}
          {...rest}
        >
          {inputChildren && inputChildren(index)}
          {isRemovingEnabled && index > minCount - 1 && (
            <button
              className={classnames({ 'input__remove icon icon-bin': true, [removeBtnClassNames]: true })}
              type="button"
              onClick={() => onChange(null, index)}
              aria-label={t(`Recommendations.campaign.setup.${fieldName}.remove`)}
            ></button>
          )}
        </Component>
      ))}
      {!isAddingEnabled ? (
        <div className="pb-4" />
      ) : (
        <div className="add-button-alignment-wrapper">
          <div className="limited-field-wrapper">
            {isRemovingEnabled && (
              <button
                type="button"
                onClick={() => onChange('', field.length)}
                disabled={field.length >= maxCount}
                className="campaign-form__add"
                aria-label={t(`Recommendations.campaign.setup.${fieldName}.add`)}
                title={addTitle}
              >
                <span>+</span>
                {t('Recommendations.campaign.setup.add')}
              </button>
            )}
            {isAddingEnabled && field.length >= maxCount && (
              <div className="limit-tooltip">
                <h5 className="font-weight-bold">{t('Tips.Recommendations.Limit.title')}</h5>
                {t(`Tips.Recommendations.Limit.${fieldName}`)}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

ArrayField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isAddingEnabled: PropTypes.bool,
  isRemovingEnabled: PropTypes.bool,
  removeBtnClassNames: PropTypes.string,
  children: PropTypes.node,
  inputChildren: PropTypes.func,
  as: PropTypes.func,
  minCount: PropTypes.number,
  maxCount: PropTypes.number,
  tooltip: PropTypes.object,
  restFn: PropTypes.func,
}

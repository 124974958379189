import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import LoadingSpinner, { SpinnerSize } from './LoadingSpinner'

import disabledGrey from '../../images/icons/check-grey.svg'
import greenCheck from '../../images/icons/check-green1.svg'

/**
 * Text input.
 * @Tags( shared, presentational )
 */
const Input = ({
  id = null,
  name,
  wrapperClass = '',
  inputClass = '',
  value,
  defaultValue,
  min = null,
  separatePlaceholder = false,
  max = null,
  type = 'text',
  required = false,
  onChange,
  placeholder = '',
  maxLength,
  maxLengthClassNamesFn = () => '',
  showMax,
  children,
  inputRef = null,
  loading = false,
  showLoadingSpinner = false,
  showPositiveFeedback = false,
  validator = null,
  validateOnBlur = false,
  errorBelow = false,
  setError = null,
  ...rest
}) => {
  const valueLength = value?.length || 0
  const testInputValue = useCallback(
    (item) => {
      if (validator && item) return validator(item)
      return { isValid: true }
    },
    [validator],
  )

  const [validationResult, setValidationResult] = useState(() => testInputValue(value))
  const showError = !setError // if setError is passed, the error will be shown below the input

  const handleChange = (e) => {
    const result = testInputValue(e.target.value)

    setValidationResult(result)
    if (setError) {
      if (result?.isValid)
        setError(null)
      else
        setError(result?.errorMsg)
    }
    onChange(e)
  }

  const wrapperClassNames = classNames(wrapperClass, 'form-group', 'input-cell', 'input-cell--input', {
    separatePlaceholderBox: separatePlaceholder,
    'input-cell--showMax': showMax,
    'input-cell--showSpinner': showLoadingSpinner,
  })

  const maxLengthClassNames = classNames('input-cell_info', {
    'input-cell__max--warning': maxLength - valueLength < 6,
    [`input-cell__max--${maxLengthClassNamesFn(valueLength)}`]: !!maxLengthClassNamesFn,
  })

  return (
    <div className={wrapperClassNames}>
      {showError && !validationResult?.isValid && !errorBelow && (
        <div className="mt-2 warning-red">{validationResult?.errorMsg}</div>
      )}

      <input
        id={id}
        data-testid={id}
        className={`form-control s-40 ${inputClass}`}
        type={type}
        min={min ? min : undefined}
        max={max ? max : undefined}
        name={name ? name : ''}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        maxLength={maxLength ? maxLength : 280}
        required={required}
        ref={inputRef}
        autoComplete="off"
        style={!validationResult?.isValid ? { border: '1px solid red' } : {}}
        onBlur={(e) => {
          if (validateOnBlur) setValidationResult(testInputValue(e.target.value))
        }}
        {...rest}
      />
      {separatePlaceholder && (
        <label className={`separatePlaceholder ${required ? 'required' : ''}`}>{placeholder}</label>
      )}

      <div className={maxLengthClassNames}>
        {(!loading || !showLoadingSpinner) && showMax && (
          <span className="pt-1">
            {valueLength}/{maxLength}
          </span>
        )}
        {showPositiveFeedback && (
          <>
            <img
              src={disabledGrey}
              alt="invalid"
              className={classNames({
                'input-cell_info__invalid': true,
                visible: !(validationResult?.isValid && value),
              })}
            />
            <img
              src={greenCheck}
              alt="valid"
              className={classNames({ 'input-cell_info__valid': true, visible: validationResult?.isValid && value })}
            />
          </>
        )}
      </div>

      {loading && showLoadingSpinner && (
        <LoadingSpinner className="input-cell__spinner" showText={false} size={SpinnerSize.small} />
      )}
      {showError && !validationResult?.isValid && errorBelow && <div className="mt-2 warning-red">{validationResult?.errorMsg}</div>}
      {children}
    </div>
  )
}

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string.isRequired || PropTypes.number.isRequired,
  defaultValue: PropTypes.string || PropTypes.number,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  maxLengthClassNamesFn: PropTypes.func,
  showMax: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node,
  separatePlaceholder: PropTypes.bool,
  wrapperClass: PropTypes.string,
  inputClass: PropTypes.string,
  loading: PropTypes.bool,
  showLoadingSpinner: PropTypes.bool,
  showPositiveFeedback: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) })]),
  validator: PropTypes.func,
  validateOnBlur: PropTypes.bool,
  errorBelow: PropTypes.bool,
  setError: PropTypes.func,
}

export default Input

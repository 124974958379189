import { isArray } from 'lodash'
import moment from 'moment'

import { augmentIncomingMedia } from 'api/models'

import { creativeActions } from './creativeFormReducer'
import i18n from 'i18n'

/**
 * Returns the removed fields based on the diff of two versions of form contents.
 * This is used to offset the disabled variations when a field is removed.
 *
 * @param {Object} a - The first version of the form contents.
 * @param {Object} b - The second version of the form contents.
 * @param {Object} action - The action object.
 * @returns {Object|null} - The removed fields or null if no removed fields are found.
 */
export const getRemovedField = (a, b, action) => {
  if (typeof action.index === 'undefined' || action.index === null) {
    return null
  }

  const typeMap = {
    [creativeActions.bodyUpdate]: 'body',
    [creativeActions.headlineUpdate]: 'headline',
    [creativeActions.mediaUpdate]: 'media',
    [creativeActions.destinationUrlUpdate]: 'destination_url',
  }
  for (const key in a) {
    try {
      if (
        isArray(a[key]) &&
        b[key].filter(Boolean).length < a[key].filter(Boolean).length &&
        ['body', 'headline', 'media', 'destination_url'].includes(key)
      ) {
        return { type: typeMap[action.type], index: action.index }
      }
    } catch (e) {
      console.error('diff error', key, a[key], b[key])
    }
  }

  return null
}
export function parseCreatives(setup, tc, eventDate, hasPrismaAccess) {
  const creative = setup?.creatives?.find((x) => x.tc === tc) ?? {}
  const [descriptionDate, descriptionTime] = moment
    .utc(eventDate)
    .format('DD.MM.YYYY HH:mm')
    .split(' ')

  return {
    ...creative,
    headline: creative.headline ?? [],
    body: creative.body ?? [],
    media: augmentIncomingMedia(creative.media),
    destination_url: creative.destination_url ?? [],
    call_to_action: creative.call_to_action,
    audience_id: creative.audience_id,
    description:
      creative.description ||
      (hasPrismaAccess
        ? ''
        : i18n.t('Recommendations.campaign.setup.description.default', {
          date: descriptionDate,
          time: descriptionTime,
        })),
  }
}

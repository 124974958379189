import PropTypes from 'prop-types'
import React, { useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import LoadingSpinner from '../../Shared/LoadingSpinner'
import ErrorMessage from '../../Shared/ErrorMessage'

import { initStateArray, arrayReducer } from '../../../reducers/default'
import { fetchTicketsTypesPlot } from '../../../api/charts'
import LineChart from '../../Shared/LineChart'
import InfoBox from '../../Shared/InfoBox'

/**
 * Ticket types bar. They are divided in Full price, discounted etc.
 * @Tags( event, api )
 * @Endpoints( /events/[eid]/tickets )
 * @NpmPackages( highcharts )
 * @ApiLogic( ../../api/charts )
 */
export default function TicketTypeChart({ eventId, title, setShowCategories = () => {} }) {
  const { t } = useTranslation()
  const [series, dispatch] = useReducer(arrayReducer, initStateArray)
  const [translation] = useState({
    fullPrice: t('Event.ticketType.fullPrice'),
    regularDiscount: t('Event.ticketType.regularDiscount'),
    discount: t('Event.ticketType.discount'),
    subscription: t('Event.ticketType.subscription'),
  })

  useEffect(() => {
    fetchTicketsTypesPlot(dispatch, eventId, translation)
  }, [eventId, translation])

  useEffect(() => {
    setShowCategories(!!series.items.length)
  }, [series, setShowCategories])

  if (!series.loading && !series.error && series.empty) return null

  return (
    <div
      className={`card events-insights-page_dash_small_card card-ticket-categories ${series.items.length > 0 ? '' : 'no-results'}`}
    >
      <div>
        <InfoBox content="Tips.Events.TicketType">
          <h2 className="card-title-default d-inline-block m-0">{title}</h2>
        </InfoBox>
      </div>
      <div>
        {series.loading && <LoadingSpinner />}
        {series.error && <ErrorMessage danger>{t(series.message)}</ErrorMessage>}
        {series.items && series.items.length > 0 ? <LineChart data={series.items} /> : null}
      </div>
    </div>
  )
}

TicketTypeChart.propTypes = {
  title: PropTypes.string.isRequired,
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setShowCategories: PropTypes.func,
}

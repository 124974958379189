import React, { createContext, useContext, useReducer, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'

const ToastContext = createContext(null)

export const actions = Object.freeze({
  addNotification: 'ADD_NOTIFICATION',
  removeNotification: 'REMOVE_NOTIFICATION',
})

const initialState = {
  notifications: [],
}

function notificationsReducer(state, action) {
  switch (action.type) {
    case actions.addNotification: {
      return {
        ...state,
        notifications: [{ id: v4(), ...action.payload }, ...state.notifications],
      }
    }
    case actions.removeNotification: {
      return {
        ...state,
        notifications: [...state.notifications.filter((x) => x.id !== action.payload)],
      }
    }
    default: {
      return state
    }
  }
}

export function ToastProvider({ children }) {
  const [state, dispatch] = useReducer(notificationsReducer, initialState)

  const addNotification = useCallback((notification) => {
    dispatch({ type: actions.addNotification, payload: notification })
  }, [])

  const removeNotification = useCallback((id) => {
    dispatch({ type: actions.removeNotification, payload: id })
  }, [])

  const value = useMemo(
    () => ({
      notifications: state.notifications,
      addNotification,
      removeNotification,
    }),
    [state.notifications, addNotification, removeNotification],
  )

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
}

ToastProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export function useToast() {
  const context = useContext(ToastContext)

  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider')
  }

  return context
}

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function StatusRow({ checked, title, setStatus, displayTitle }) {
  return (
    <div className="event-control-panel_dropdown-menu_campaigns_row">
      <label className="event-control-panel_dropdown-menu_custom-checkbox">
        <input onChange={() => setStatus(title)} type="checkbox" checked={checked} />
        <span>{displayTitle}</span>
      </label>
    </div>
  )
}

StatusRow.propTypes = {
  checked: PropTypes.bool,
  title: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
  displayTitle: PropTypes.string.isRequired,
}

export default function CampaignStatusFilter({ state, dispatch, submit }) {
  const { t } = useTranslation()
  const selectedCampaigns = state.campaigns

  const handleSelectedCampaigns = useCallback(
    (newCampaignStatus) => {
      let campaigns = []
      if (selectedCampaigns.includes(newCampaignStatus))
        campaigns = selectedCampaigns.filter((s) => s !== newCampaignStatus)
      else campaigns = [...selectedCampaigns, newCampaignStatus]

      if (campaigns.length === 0) dispatch({ type: 'CAMPAIGNS_CANCEL' })
      else dispatch({ type: 'CAMPAIGNS', payload: campaigns })

      submit({ ...state, campaigns: campaigns })
    },
    [selectedCampaigns, state, dispatch, submit],
  )

  return (
    <div className="event-control-panel_dropdown-menu_checkbox-wrapper">
      <StatusRow
        setStatus={handleSelectedCampaigns}
        checked={selectedCampaigns.includes('active')}
        title={'active'}
        displayTitle={t('common.active')}
      />
      <StatusRow
        setStatus={handleSelectedCampaigns}
        checked={selectedCampaigns.includes('inactive')}
        title={'inactive'}
        displayTitle={t('common.inactive')}
      />
    </div>
  )
}

CampaignStatusFilter.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'

export default function Vignette({ visible, fullScreen }) {
  const history = useHistory()
  const location = useLocation()

  if (!visible) return null

  return (
    <div
      onClick={() => {
        history.push(location.pathname)
      }}
      style={{
        position: fullScreen ? 'fixed' : 'relative',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(1,1,1, 0.15)',
        zIndex: 999998,
      }}
    />
  )
}

Vignette.propTypes = {
  visible: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
}
